import { EndPoint } from "../../utils/endpoints";
import ApiUtils from "../APICallServices/APIUtils";

export default class ManagerProvider {
  constructor() {
    this.policyUtils = new ApiUtils();
  }
  getPolicy = () => {
    return this.policyUtils.init().get(EndPoint.getPolicy);
  };

  uploadPolicy = (data) => {
    return this.policyUtils.init().post(EndPoint.uploadPolicy, data);
  };

  addPolicy = (data) => {
    return this.policyUtils.init().post(EndPoint.addPolicy, data);
  };
  deletePolicy = (data) => {
    return this.policyUtils.init().post(EndPoint.deletePolicy, data);
  };

  updatePolicy = (data) => {
    return this.policyUtils.init().post(EndPoint.editPolicy, data);
  }
}
