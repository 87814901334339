import { Icon } from "@iconify/react";
import React from "react";
import { FlexContainer } from "../../../../style/asset.style";
import { TypoGraphy } from "../../../../style/common.style";
import {
  IconContainerEmpList,
  LeaveStatusAbsentContainer,
} from "../../../../style/dashboard.style";
import { Theme } from "../../../../style/theme/color";
import { FontSizes } from "../../../../style/theme/font";
import CommonColor from "../../../../utils/common.colors";

export default function EmployeeLeaveReasoning(props) {
  return (
    <LeaveStatusAbsentContainer>
      {/* <FlexContainer width="100%">
        <FlexContainer> */}
      <TypoGraphy
        width="max-content"
        fontSize={FontSizes.Subtitle}
        weight="700"
      >
        Reason :&nbsp;
      </TypoGraphy>
      <TypoGraphy fontSize={FontSizes.Title} weight="500" width="100%">
        {props.data.leave.reason !== ""
          ? props.data.leave.reason
          : "(No reason given)"}
      </TypoGraphy>
      {/* </FlexContainer>
      </FlexContainer> */}
    </LeaveStatusAbsentContainer>
  );
}
