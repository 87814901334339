import { createSlice } from "@reduxjs/toolkit";

export const attendanceSlice = createSlice({
  name: "appeal",
  initialState: {
    appeal_employees_data: [],
    appeal_employees_pending_data : [],
    all_employees_pending_data : []
  },
  reducers: {
    
    setEmployeePendingAppeal: (state, action) => {
      state.appeal_employees_pending_data = action.payload;
    },

    setEmployeeAppeal: (state, action) => {
      state.appeal_employees_data = action.payload;
    },
   
    deleteEmployeeAppeal: (state, action) => {
      // Filter out the employee appaeal data with the given ID
      state.appeal_employees_data = state.appeal_employees_data.filter(
        employee => employee.id !== action.payload
      );
    },

  },
});

// Action creators are generated for each case reducer function
export const { setEmployeeAppeal, 
               deleteEmployeeAppeal,
               setEmployeePendingAppeal
              } =
  attendanceSlice.actions;

export default attendanceSlice.reducer;
