import React from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

export default function () {
  return (
    <div className="w-full h-full flex flex-wrap justify-around">
      {[...Array(8)].map(() => {
        return (
          <React.Fragment>
            <div className="w-5/12 mt-10">
              <ShimmerThumbnail height={116} width="100%" rounded />
            </div>
            <div className="w-5/12 mt-10">
              <ShimmerThumbnail height={116} width="100%" rounded />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
