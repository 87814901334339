import React from "react";
import { useState } from "react";
import { FlexContainer } from "../../../style/asset.style";
import { IconContainerRefresh } from "../../../style/dashboard.style";
import { Tooltip } from "@mui/material";
import { RotationAnimation, TypoGraphy } from "../../../style/common.style";
import { Icon } from "@iconify/react";
import { useWindowSize } from "../hooks/getWindowDimension";
import CommonColor from "../../../utils/common.colors";

export default function RefreshComponent(props) {
  const windowWidth = useWindowSize().width;
  const [rotationAngle, setRotationAngle] = useState(0);

  const rotateComponent = () => {
    setRotationAngle(rotationAngle + 360);
    props.refreshList();
  };
  return (
    <FlexContainer jc="center">
      <IconContainerRefresh cursor="pointer">
        <Tooltip
          title="Refresh"
          arrow={true}
          placement="top"
          enterDelay={windowWidth < 600 ? 500 : 0}
          leaveDelay={windowWidth < 600 ? 200 : 0}
        >
          <RotationAnimation
            onClick={() => rotateComponent()}
            rotationangle={rotationAngle}
          >
            <FlexContainer jc="center">
              <Icon
                icon="material-symbols:refresh"
                color={CommonColor.text_gray}
              />
            </FlexContainer>
          </RotationAnimation>
        </Tooltip>
      </IconContainerRefresh>
      &nbsp;
      <TypoGraphy color={CommonColor.text_gray}>
        {" "}
        Last updated {props.DateDiff} minutes ago
      </TypoGraphy>
    </FlexContainer>
  );
}
