import ApiUtils from "../APICallServices/APIUtils";

export default class ManagerProvider {
  constructor() {
    this.authUtils = new ApiUtils();
  }
  login = (data) => {
    return this.authUtils.init().post("auth/login", data);
  };
}
