import styled from "styled-components";
import { DatePickerCalendar } from "./common.style";

const DatePickerDiv = styled.div`
  width: 100%;
  align-items: center;
  padding: 0.4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 2px solid #e2e5ea; /* Corrected border property */
  background-color: #fff;
  .rmdp-input {
    border: none !important;
  }
`;

const DatePickerStyle = styled(DatePickerCalendar)`

`;


export { DatePickerDiv , DatePickerStyle};
