import { Icon } from "@iconify/react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import transition from "react-element-popper/animations/transition";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import EventController from "../../controller/eventController";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import {
  getCurrentDayEvents,
  setFullEvent,
  setLoadingStatus,
} from "../../reducer/eventreduxstore";
import CustomInput from "../../screen/Profile/CustomInput";
import { Div } from "../../style/common.style";
import {
  CalendarContainer,
  FormContainerEvent,
  InputText,
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  PopupNavBarContainer,
} from "../../style/form.style";
import { DatePickerCalendarProfile } from "../../style/profile.style";
import { CommonImages } from "../../utils/common.images";
import { EVENTTYPE, WING } from "../../utils/constant";
import ShowLoader from "../Loader/ShowLoader";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import BottomButtons from "./components/BottomButtons";
import { eventTypes } from "../../utils/constant.array";
import Input from "../../componentsv2/common_components/Inputs/input";
import TailwindDropdownMembers from "../commonComponents/inputs/TailWindDropdownMembers";
import DropDownList from "../../componentsv2/common_components/DropDown/Dropdown";

export default function AddEvent(props) {
  const dispatch = useDispatch();
  const employee_list = useSelector((state) => state.employee.employee_list);

  const [conductingPerson, setConductingPerson] = useState();
  const [department, setDepartment] = useState("ALL");
  const [eventType, setEventType] = useState("technology training");
  const [eventName, setEventName] = useState("");
  const [eventTime, setEventTime] = useState(dayjs("2014-08-18T21:11:54Z"));
  const [eventDesc, setEventDesc] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState(-1);
  const todayDate = new DateObject();
  const [eventDate, setEventDate] = useState(todayDate);
  const { enqueueSnackbar } = useSnackbar();
  const eventController = new EventController();
  const event = useSelector((state) => state.event);

  useEffect(() => {
    if (props.data !== null) {
      onPreFillData();
    }
  }, [props.data]);

  function onPreFillData() {
    if (props.data === null) return;
    const newEventList = employee_list.filter(
      (user) => user._id === props.data.conducted_by
    );
    setConductingPerson(newEventList[0]);
    setEventName(props.data.event_name);
    setEventType(props.data.event_type);
    setEventDesc(props.data.description);
    setEventTime(dayjs(props.data.event_time));
    setDepartment(WING.filter((name) => name === props.data.department)[0]);
    setEventDate(new DateObject(props.data.event_date));
    // setEventName(props.data.event_name)
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  function addNewEvent(eventIncoming) {
    if (
      event.active_month !== parseInt(moment(eventIncoming.date).format("MM"))
    )
      return;

    const newEventObject = {
      data: {
        ...eventIncoming.data,
        recruiter: conductingPerson,
      },
      type: eventIncoming.type,
      date: eventIncoming.date,
    };
    let i = 0;
    for (; i < event.all_events.length; i++) {
      if (i === event.all_events.length - 1) break;
      if (
        parseInt(moment(event.all_events[i].date).format("DD")) <=
          parseInt(moment(eventIncoming.date).format("DD")) &&
        parseInt(moment(eventIncoming.date).format("DD")) <=
          parseInt(moment(event.all_events[i + 1].date).format("DD"))
      )
        break;
    }
    const newEventList = [
      ...event.all_events.slice(0, i + 1),
      newEventObject,
      ...event.all_events.slice(i + 1, event.all_events.length),
    ];
    dispatch(setFullEvent(newEventList));
  }

  async function editEvent(data) {
    // const updatedList = event.all_events.map((event) => {
    //   if (
    //     event.data.conducted_by !== data.conducted_by &&
    //     event.data.event_type !== event.event_type
    //   )
    //     return event;
    //   const newDataStructure = {
    //     data: data,
    //     type: eventTypes[0],
    //     date: data.event_date,
    //   };

    //   return newDataStructure;
    // });
    // let updatedList = await eventController.getMonthlyEventList(
    //   dayjs().month() + 1
    // );
    const updatedList = event.all_events.map((event) => {
      if (event.data._id !== props.data._id) {
        return event;
      }
      const newDataStructure = {
        data: { ...data, recruiter: conductingPerson, _id: props.data._id },
        type: eventTypes[0],
        date: data.event_date,
      };

      return newDataStructure;
    });
    dispatch(setFullEvent(updatedList));
  }

  async function onSave() {
    if (eventName === "") {
      setError(0);
      return;
    }
    if (eventTime === "") {
      setError(1);
      return;
    }
    if (department === "") {
      setError(2);
      return;
    }
    if (eventType === "") {
      setError(3);
      return;
    }
    if (eventDate === "") {
      setError(4);
      return;
    }
    if (eventDesc === "") {
      setError(5);
      return;
    }

    if (
      eventName === "" ||
      eventTime === "" ||
      department === "" ||
      eventType === "" ||
      eventDate === "" ||
      eventDesc === ""
    ) {
      return;
    }
    setIsLoader(true);
    setError(-1);
    const event_time = dayjs(eventTime).toISOString();
    const event_date = moment
      .tz(new DateObject(eventDate).format(), "UTC")
      .format();

    const data = {
      event_name: eventName,
      conducted_by: conductingPerson._id,
      department: department,
      event_type: eventType,
      event_date: event_date,
      description: eventDesc,
      event_time: event_time,
    };
    if (props.data !== null) {
      const updateData = { _id: props.data._id, ...data };
      const resp = await eventController.updateEvent(updateData);
      const sucessMsg = (
        <div>
          <div>success</div>
          <div>event edited sucessfully</div>
        </div>
      );
      const erroMsg = (
        <div>
          <div>error</div>
          <div>try again after some time</div>
        </div>
      );
      setTimeout(() => {
        setIsLoader(false);
        if (resp) {
          editEvent(data);
          dispatch(setIsModalOpen(false));
          handleAlert(sucessMsg, "success");
          return;
        }
        dispatch(setIsModalOpen(false));
        handleAlert(erroMsg, "error");
      }, 500);
      props.closePopup();
    } else {
      const resp = await eventController.addEvent(data);
      if (typeof resp === "object") {
        addNewEvent(resp);
      }
      const sucessMsg = (
        <div>
          <div>Success</div>
          <div>event added sucessfully</div>
        </div>
      );
      const erroMsg = (
        <div>
          <div>Error</div>
          <div>try again after some time</div>
        </div>
      );
      setTimeout(() => {
        setIsLoader(false);
        if (resp) {
          dispatch(setIsModalOpen(false));
          handleAlert(sucessMsg, "success");
          return;
        }
        dispatch(setIsModalOpen(false));
        handleAlert(erroMsg, "error");
      }, 500);
    }

    const weekEventList = await eventController.getEventListByDate();
    dispatch(getCurrentDayEvents(weekEventList));
    dispatch(setLoadingStatus(1));
    setTimeout(() => {
      dispatch(setLoadingStatus(2));
    }, 2000);
    setTimeout(() => {
      dispatch(setLoadingStatus(0));
    }, 2000);
    dispatch(setIsModalOpen(false));
  }

  return (
    <React.Fragment>
      {isLoader ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Scheduling Event</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainer>
              <PopupNavBar
                onHandleClose={props.onHandleClose}
                componentType="form"
                title={props.data === null ? "add event" : "update Event"}
              />
            </PopupNavBarContainer>
          </DialogTitle>
          <DialogContent dividers={true}>
            <FormContainerEvent>
              <Div className="p-4 pt-0">
                <Div className="grid grid-cols-1 gap-x-6 gap-y-0 sm:grid-cols-4 w-full">
                  <Div className="col-span-2">
                    <Input
                      error={error === 0}
                      title={"Event Name"}
                      placeholder={"Event Name"}
                      icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={eventName}
                      handleChange={(event) => setEventName(event.target.value)}
                    />
                  </Div>
                  <Div className="col-span-2">
                    <TailwindDropdownMembers
                      dataList={employee_list}
                      title={"By"}
                      value={conductingPerson}
                      handleChange={(event) => {
                        setConductingPerson(event);
                      }}
                      textTransform={"capitalize"}
                      marginTopC="0.5rem"
                      marginBottomC="0"
                      plb="0.5rem"
                      prb="0.5rem"
                    />
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-4 mt-2">
                  <Div className="col-span-2">
                    <DropDownList
                      error={error === 2}
                      dataList={WING}
                      title={"Department(s)"}
                      value={department}
                      handleChange={(event) =>
                        setDepartment(event.target.value)
                      }
                      multiple
                    />
                  </Div>
                  <Div className="col-span-2">
                    <DropDownList
                      error={error === 3}
                      dataList={EVENTTYPE}
                      title={"Event Type"}
                      value={eventType}
                      onhandleChange={(event) => setEventType(event)}
                    />
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-4 mt-2">
                  <Div className="col-span-2">
                    <CalendarContainer isvisible={1}>
                      <InputText>Event Date</InputText>
                      <DatePickerCalendarProfile
                        value={eventDate}
                        onChange={(e) => {
                          setEventDate(e);
                        }}
                        format="MMM, D YYYY"
                        className="custom-calendar"
                        mapDays={({ date }) => {
                          let isWeekend = [0, 6].includes(date.weekDay.index);

                          if (isWeekend)
                            return {
                              disabled: true,
                              style: { color: "#ccc" },
                              onClick: () => {},
                            };
                        }}
                        // title={(date) =>
                        //   date.toLocaleDateString("en-US", {
                        //     month: "short",
                        //     day: "numeric",
                        //     year: "numeric",
                        //   })
                        // }
                        animations={[transition()]}
                        // onlyMonthPicker
                        // ref={calendarRef}
                        // calendarPosition="bottom-center "
                        // minDate={Date.now()}
                        // maxDate={
                        //   new Date(
                        //     new Date().getDay(),
                        //     new Date().getMonth(),
                        //     parseInt(new Date().getFullYear()) + 1
                        //   )
                        // }
                        showOtherDays
                        render={<CustomInput value={eventDate} />}
                      />
                    </CalendarContainer>
                  </Div>
                  <Div className="col-span-2">
                    <CalendarContainer isvisible={1}>
                      <InputText>Event Time</InputText>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          // type="time"
                          // defaultValue="07:30"
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          // inputProps={{
                          //   step: 300, // 5 min
                          // }}
                          toolbarTitle="Time in est"
                          PopperProps={{
                            sx: {
                              zIndex: 1000000004,
                            },
                          }}
                          onChange={setEventTime}
                          value={eventTime}
                          renderInput={(params) => (
                            <Input
                              error={error === 5}
                              id="time"
                              title="Event Time"
                              placeholder="Event Date"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </CalendarContainer>
                  </Div>
                </Div>

                <Input
                  error={error === 6}
                  title={"Description"}
                  placeholder={"Description"}
                  icon={<Icon icon="ooui:user-avatar-outline" />}
                  handleChange={(event) => setEventDesc(event.target.value)}
                  value={eventDesc}
                  multiline
                />
              </Div>
            </FormContainerEvent>
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => onSave()}
                buttonName={props.data == null ? "Add Event" : "Update Event"}
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
