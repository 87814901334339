import React, { useCallback, useState } from "react";
import { Span, TypoGraphy } from "../../style/common.style";
import {
  CloseButton,
  Img,
  PopupNavbar,
  BigFormContainer,
  BigPopupHeading,
  DropAndFileZone,
  FileZone,
  FileZoneContainer,
  AddPdfFormContainer,
  BigVerticalScrollableArea,
  PdfViewerContainer,
  ChangeFileContainer,
  PopupNavBarContainer,
  MsgContainer,
  MsgHeading,
} from "../../style/form.style";
import CloseIcon from "../../assets/images/closeIcon.svg";
import { Theme } from "../../style/theme/color";
import { Button, DialogContent, DialogTitle, Divider } from "@mui/material";
import {
  ChangeFileButton,
  ChangeFileButtonContainer,
  DropInput,
  DropzoneComponentContainer,
  DropzoneMainContainer,
  LoadingBgContainer,
  PdfDataContainer,
  PdfPreview,
  PdfUpadteFormContainer,
} from "../../style/training.style";
import { Icon } from "@iconify/react";

import { useRef } from "react";

import MyDropzone from "../commonComponents/dropzoneComponent/dropzone";
import AddPdfData from "./AddPdfData";
import { useDropzone } from "react-dropzone";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import { useDispatch, useSelector } from "react-redux";
import MaterialController from "../../controller/materialController";
import { MaterialBaseUrl } from "../../utils/constant";
import { useSnackbar } from "notistack";
import { setPdfMaterial } from "../../reducer/materialreduxstore";
import { CommonImages } from "../../utils/common.images";
import ShowLoader from "../Loader/ShowLoader";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";

export default function AddTrainingMaterial(props) {
  const [activeFile, setActiveFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const materialController = new MaterialController();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    fileUpdater(file);
  }, []);

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: ".pdf",
    maxFiles: 1,
    onDrop,
  });

  function fileUpdater(data) {
    setActiveFile(data);
  }

  async function onSave(data) {
    if (data.pdfTitle === "") return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("material", activeFile);
    const resFileUpload = await materialController.uploadMaterial(formData);

    if (resFileUpload) {
      const sendData = {
        type: data.eventType,
        user_id: auth.employee_data.user._id,
        department: data.wing,
        file_url: MaterialBaseUrl + resFileUpload.url,
        title: data.pdfTitle,
        added_by: auth.employee_data.user.name,
      };
      const respAddData = await materialController.addMaterial(sendData);
      if (respAddData) {
        //success
        const sucessMsg = (
          <MsgContainer>
            <MsgHeading>success</MsgHeading>
            <sucessMsg>training material updated sucessfully</sucessMsg>
          </MsgContainer>
        );
        const trainingData = await materialController.getEmployeePdf();
        if (trainingData.length !== 0) {
          dispatch(setPdfMaterial(trainingData));
        }
        setTimeout(() => {
          handleAlert(sucessMsg, "success");
          setIsLoading(false);
          closePopup();
        }, 2000);
        return;
      }
      //error
      const errorMsg = (
        <MsgContainer>
          <MsgHeading>error</MsgHeading>
          <sucessMsg>error while updating training data</sucessMsg>
          <sucessMsg>Please try again later</sucessMsg>
        </MsgContainer>
      );
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        setIsLoading(false);
        closePopup();
      }, 2000);
      return;
    }
    //error
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>error while uploading training material</sucessMsg>
        <sucessMsg>Please try again later</sucessMsg>
      </MsgContainer>
    );
    setTimeout(() => {
      handleAlert(errorMsg, "error");
      setIsLoading(false);
      closePopup();
    }, 2000);
    return;
  }

  function getComponent() {
    if (activeFile === null) {
      return (
        <FileZoneContainer>
          <DropAndFileZone>
            <DropzoneComponentContainer>
              <MyDropzone fileUpdater={fileUpdater} files={activeFile} />
            </DropzoneComponentContainer>
          </DropAndFileZone>
        </FileZoneContainer>
      );
    } else {
      return (
        <PdfUpadteFormContainer>
          {isLoading ? (
            <LoadingBgContainer>
              {" "}
              <ShowLoader
                loaderData={CommonImages.circularLoader}
                height="100px"
                width="164px"
              />
              <TypoGraphy
                fontSize={FontSizes.Mulish}
                fontWeight="500"
                color={CommonColor.primary_color}
              >
                Uploading Pdf...
              </TypoGraphy>
            </LoadingBgContainer>
          ) : (
            ""
          )}

          <PdfDataContainer>
            <ChangeFileButtonContainer>
              <DropzoneMainContainer {...getRootProps()}>
                <DropInput {...getInputProps()} />
                <ChangeFileButton variant="contained" onClick={open}>
                  Change File
                </ChangeFileButton>
              </DropzoneMainContainer>
            </ChangeFileButtonContainer>
            <PdfPreview src={URL.createObjectURL(activeFile)} />
          </PdfDataContainer>
          <AddPdfFormContainer>
            <AddPdfData onSave={(data) => onSave(data)} />
          </AddPdfFormContainer>
        </PdfUpadteFormContainer>
        // <FileZone>
        //   <PdfViewerContainer>

        //     {/* <DropzoneMainContainer {...getRootProps()}>
        //       <DropInput {...getInputProps()} />
        //       <ChangeFileContainer onClick={open}>
        //         change file
        //       </ChangeFileContainer>
        //     </DropzoneMainContainer> */}
        //   </PdfViewerContainer>

        // </FileZone>
      );
    }
  }

  function closePopup() {
    setActiveFile(null);
    props.PdfCloser();
  }

  return (
    <React.Fragment>
      <DialogTitle>
        <PopupNavBarContainer>
          <PopupNavBar
            onHandleClose={() => closePopup()}
            componentType="form"
            title="add training material"
          />
        </PopupNavBarContainer>
      </DialogTitle>
      <DialogContent>
        <BigFormContainer>{getComponent()}</BigFormContainer>
      </DialogContent>
    </React.Fragment>
  );
}
