import React from "react";
import { useHistory } from "react-router-dom";
import {
  PageNotFoundContainer,
  TextContainer,
  ImageContainer,
  CodeContainer,
  PageNotFoundImg,
  TextHeader,
  TextSubDescription,
  TextDescription,
  TextHomeButton,
  Code,
} from "../../style/pagenotfound.style";
export default function PageNotFound() {
  const history = useHistory();
  return (
    <React.Fragment>
      <PageNotFoundContainer>
        <TextContainer>
          <TextHeader>Ooops...</TextHeader>
          <TextSubDescription>Page not found</TextSubDescription>
          <TextDescription>
            The page you are looking for doesn’t exist or an other error
            occurred, go back to home page.
          </TextDescription>
          <TextHomeButton
            variant="contained"
            disableElevation
            onClick={() => history.goBack()}
          >
            Go Back
          </TextHomeButton>
        </TextContainer>
        <ImageContainer>
          <PageNotFoundImg />
        </ImageContainer>
        <CodeContainer>
          <Code>4</Code>
          <Code>0</Code>
          <Code>4</Code>
        </CodeContainer>
      </PageNotFoundContainer>
    </React.Fragment>
  );
}
