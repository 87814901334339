import AttendanceProvider from "../services/provider/attendanceProvider";

export default class AttendanceController {
  constructor(props) {
    this.attendanceProvider = new AttendanceProvider();
  }

  addAttendance = async (data) => {
    const response = await this.attendanceProvider.login(data);
    if (response.status === 200) {
      return { success: true, id: response.data.id };
    }
    return { success: false, id: "" };
  };
  checkAttendance = async (data) => {
    const response = await this.attendanceProvider.checkAttendance(data);
    if (response.status === 200) {
      if (response.data.data.length === 0) return null;
      return response.data.data;
    }
    return "error : " + response.error;
  };
  updateOutTime = async (data) => {
    const response = await this.attendanceProvider.updateOutTime(data);
    if (response.data.status === 200) {
      return true;
    }
    return false;
  };
  getMonthAttendance = async (data) => {
    const response = await this.attendanceProvider.getMonthAttendance(data);
    if (response.status === 200) {
      return response.data.data;
    }
    return "error : " + response.error;
  };
  getAllAttendance = async (data) => {
    const response = await this.attendanceProvider.getAllAttendance(data);
    if (response.status === 200) {
      return response.data.data;
    }
    return "error : " + response.error;
  };
  removeEmployeeLate = async (data) => {
    const response = await this.attendanceProvider.removeEmployeeLate(data);
    if (response.data.status === 200) {
      // return true;
    }
    // return false;
  };
  changeAttendanceToLate = async (data) => {
    const response = await this.attendanceProvider.changeAttendanceToLate(data);
    if (response.data.status === 200) {
      // return true;
    }
    // return false;
  };
  markPresent = async (data) => {
    const response = await this.attendanceProvider.markPresent(data);
    if (response.data.status === 200) {
      return true;
    }
    return false;
  };
  markLate = async (data) => {
    const response = await this.attendanceProvider.markLate(data);
    if (response.data.status === 200) return true;
    return false;
  };
  getDailyAttendance = async () => {
    const response = await this.attendanceProvider.getDailyAttendance();
    if (response.status === 200) return response.data;
    return false;
  };
  deleteAttendance = async (data) => {
    const response = await this.attendanceProvider.deleteAttendance(data);
    if (response.status === 200) {
      return true;
    }
    return false;
  };
  addShift = async (data) => {
    const response = await this.attendanceProvider.addShift(data);
    if (response.status === 200) {
      return true;
    }
    return false;
  };
  getShift = async () => {
    const response = await this.attendanceProvider.getShift();
    if (response.status === 200) {
      return response.data.data;
    }
    return false;
  };
  updateShift = async (data) => {
    const response = await this.attendanceProvider.updateShift(data);
    if (response.status === 200) {
      return true;
    }
    return false;
  };

  getAllAttenByDay = async (data) => {
     const response = await this.attendanceProvider.getAllAttendanceByDay(data);
     if(response.status === 200 ){
      return response.data;
     }
     return false;
  }
}
