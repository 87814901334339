import React, { useRef } from "react";
import transition from "react-element-popper/animations/transition";
import DropdownWithChip from "../../component/commonComponents/DropdownModified/DropdownWithChip";
import { useWindowSize } from "../../component/commonComponents/hooks/getWindowDimension";
import {
  DatePickerCalendarProfile,
  NavBarPageTitleContainer,
  ProfileActiveMarker,
  ProfileDetailNavBarContainer,
  ProfileDetailNavbarTabContainer,
  ProfileNavBarButton,
  ProfileTabTitleContainer,
} from "../../style/profile.style";
import { getNextMonthDate } from "../../utils/utils";
import CustomInput from "./CustomInput";
import { monthShort } from "../../utils/constant.array";
import { useSelector } from "react-redux";

export default function ProfileDetailNavBar(props) {
  const windowWidth = useWindowSize().width;
  const calendarRef = useRef(0);
  const filteredTitle = props.filteredProfileTabTitle.map(
    (element) => element.name
  );

  // const attendanceData = useSelector(
  //   (state) => state.attendance.active_user_month_attendance
  // );

  // function getAbsent() {
  //   const ans = attendanceData.filter(
  //     (data) => data.in_time === "00:00"
  //   ).length;
  //   return ans;
  // }

  // function getLate() {
  //   const ans = attendanceData.filter(
  //     (data) => data.is_late == "1"
  //   ).length;
  //   return ans;
  // }


  //employee.employee_acess.leave_details

  function FindIsActive(titleName) {
    return props.activeTab === titleName;
  }
  return (
    <ProfileDetailNavBarContainer>
      <ProfileDetailNavbarTabContainer width="calc(100% - 22px)">
        {windowWidth > 600 ? (
          <NavBarPageTitleContainer
            width={`${20 * props.filteredProfileTabTitle.length}%`}
          >
            {props.filteredProfileTabTitle.map((title, index) => {
              if (!title.isvisible) return;
              return (
                <ProfileTabTitleContainer key={index}>
                  <ProfileNavBarButton
                    variant="text"
                    isactive={FindIsActive(title.name)}
                    onClick={() => props.setActiveTab(title.name)}
                  >
                    {title.name}
                  </ProfileNavBarButton>
                  <ProfileActiveMarker
                    isvisible={props.activeTab === title.name ? 1 : 0}
                  />
                </ProfileTabTitleContainer>
              );
            })}
          </NavBarPageTitleContainer>
        ) : (
          <DropdownWithChip
            selectedOption={props.activeTab}
            handleClick={(e) => props.setActiveTab(e)}
            list={filteredTitle}
          />
        )}
      </ProfileDetailNavbarTabContainer>
      <ProfileDetailNavbarTabContainer>
        {/* {props.activeTab === "attendance" ? (
          <div className="flex w-max p-[0.32rem] px-2 border border-slate-400  rounded-md mr-2">
            Total Absent :{getAbsent()}{" "}
          </div>
        ) : (
          ""
        )} */}
      </ProfileDetailNavbarTabContainer>
      <ProfileDetailNavbarTabContainer>
        {/* {props.activeTab === "attendance" ? (
          <div className="flex w-max p-[0.32rem] px-2 border border-slate-400 rounded-md mr-2">
            Total Late : {getLate()}
          </div>
        ) : (
          ""
        )} */}
      </ProfileDetailNavbarTabContainer>
      <ProfileDetailNavbarTabContainer>
        {props.activeTab === "attendance" ? (
          <DatePickerCalendarProfile
            value={props.monthAndYear}
            months={monthShort}
            onChange={(e) => {
              props.setMonthAndYear(e);
            }}
            format="MMM YYYY"
            buttons={false}
            className="custom-calendar"
            maxDate={getNextMonthDate()}
            title={(date) =>
              date.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })
            }
            animations={[transition()]}
            onlyMonthPicker
            ref={calendarRef}
            calendarPosition="bottom"
            // minDate={new Date().getFullYear()}
            // maxDate={new Date().getFullYear()}
            showOtherDays
            render={<CustomInput value={props.monthAndYear} />}
          />
        ) : (
          ""
        )}
      </ProfileDetailNavbarTabContainer>
    </ProfileDetailNavBarContainer>
  );
}
