import { Switch } from "@mui/material";
import React from "react";
import {
  SwitchButtonContainer,
  SwitchContainer,
} from "../../style/common.component.style";
import { InputText } from "../../style/form.style";

export default function CustomSwitch(props) {
  return (
    <SwitchContainer jc={props.jc}>
      <InputText component="small-input-text" style={{ marginRight: 15 }}>
        {props.title}
      </InputText>
      <SwitchButtonContainer>
        <Switch label="hello" checked={props.value} onChange={props.onChange} />
      </SwitchButtonContainer>
    </SwitchContainer>
  );
}
