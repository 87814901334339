import { Icon } from "@iconify/react";
import { Avatar, Button, Checkbox, Grid } from "@mui/material";
import styled, { keyframes } from "styled-components";
import CommonColor from "../utils/common.colors";
import { DeviceSizes, dashboardCardLoadAnimation } from "./common.style";
import { PlaceholderSearchPosition, SearchBar } from "./employee.style";
import { FontSizes } from "./theme/font";
import { CommonImages } from "../utils/common.images";

const AccessContainer = styled.div`
  width: 100%;
  padding: 0%;
  margin: 0;
  height: 100%;
`;
const AccessManagementNavbarWrapper = styled.div`
  width: 100%;
  margin-bottom: 1%;
`;
const AccessContainerNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AcessMgmtTitle = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Title};
  font-weight: 500;
  color: ${CommonColor.primary_color};
`;
const NavigationButtonWrapper = styled.div``;

const NavigationButton = styled(Button)`
  && {
    color: ${(props) =>
      props.variant === "outlined"
        ? CommonColor.primary_color
        : CommonColor.white};
    background-color: ${(props) =>
      props.variant === "outlined"
        ? CommonColor.transparent
        : CommonColor.primary_color};
    font-family: "Mulish";
    font-size: ${FontSizes.Para};
    font-weight: 600;
    text-transform: capitalize;
    border-color: ${CommonColor.primary_color};
    border-radius: 10px;

    &:hover {
      background-color: ${CommonColor.primary_color};
      border-color: ${CommonColor.primary_color};
      color: ${CommonColor.white};
    }
    @media ${DeviceSizes.mobile} {
      padding-left: 6%;
      padding-right: 6%;
    }
  }
`;
const AccessMgmtTableWrapper = styled.div`
  width: 100%;
  padding: 0%;
  margin: 0;
  height: 100%;
`;
const heightChangeAnimationSpecific = keyframes`
0%{
  height: 50%;
}
100%{
  height: 100%;
}
`;
const AccessTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 4px 4px 77px -31px rgba(0, 0, 0, 0.25);
  height: 93%;
  animation-name: ${heightChangeAnimationSpecific};
  animation-duration: 0s;
  animation-timing-function: ease-in-out;
  & table {
    width: 100%;
  }
  @media ${DeviceSizes.mobile} {
    display: ${(props) => (props.isvisible === 1 ? "none" : "")};
    height: 94%;
  }
`;

const AccessTableLeave = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 4px 4px 77px -31px rgba(0, 0, 0, 0.25);
  height: 85%;
  animation-name: ${heightChangeAnimationSpecific};
  animation-duration: 0s;
  animation-timing-function: ease-in-out;
  & table {
    width: 100%;
  }
  @media ${DeviceSizes.mobile} {
    display: ${(props) => (props.isvisible === 1 ? "none" : "")};
    height: 94%;
  }
`;

const AccessTableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${CommonColor.button_gray};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 40px;
  padding-left: 1%;
  padding-right: 1%;
  @media ${DeviceSizes.mobile} {
    padding-left: 2%;
  }
`;
const AccessTableHead = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.SubPara};
  font-weight: 600;
  color: ${CommonColor.primary_color};
  text-align: ${(props) => props.horizontalAlign ?? "center"};
  width: ${(props) => props.width ?? "15%"};
  text-transform: ${(props) => props.textTransform};
  display: flex;
  justify-content: ${(props) => props.horizontalAlign ?? "center"};
  align-items: center;
  @media ${DeviceSizes.belowtablet} {
    width: ${(props) => props.tabWidth};
  }
  @media ${DeviceSizes.mobile} {
    width: ${(props) => props.mobileWidth ?? props.width ?? "100%"};
  }
`;
const AccessTableBody = styled.div`
  width: 100%;
  height: ${(props) => props.height ?? "100%"};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0px;
  }
  @media ${DeviceSizes.mobile} {
  }
`;
const virtualTableAnimation = keyframes`
0%{
  margin-bottom: 2%;
}
100%{
  margin-bottom: 0;
}
`;
const AccessTableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  border-color: ${CommonColor.divider_gray};
  height: 40px;
  padding-left: 1%;
  padding-right: 1%;
  filter: ${(props) => (props.isView ? "brightness(0%)" : "brightness(100%)")};
  cursor: ${(props) => props.cursor};
`;

const AccessTableData = styled.div`
  display: flex;
  justify-content: ${(props) => props.horizontalAlign ?? "center"};
  align-items: center;
  font-size: ${FontSizes.SubPara};
  font-family: "Mulish";
  font-weight: 600;
  color: ${CommonColor.primary_color};
  width: ${(props) => props.width ?? "15%"};
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  height: 40px;
  cursor: ${(props) => props.cursor};
  text-transform: ${(props) => props.textTransform};
  background-color: ${(props) =>
    props.ishighlight ? CommonColor.declined_status_background : ""};
  border-radius: 20px;
  @media ${DeviceSizes.belowtablet} {
    width: ${(props) => props.tabWidth};
    display: ${(props) => (props.ishidden !== 1 ? "flex" : "none")};
  }

  @media ${DeviceSizes.mobile} {
    width: ${(props) => props.mobileWidth ?? props.width ?? "100%"};
  }
`;
const IconAccessAllowedStatus = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.iconType === "success" ? CommonColor.green : CommonColor.red};
  background-color: ${(props) =>
    props.iconType === "success"
      ? CommonColor.success_button_background
      : CommonColor.declined_status_background};
  font-size: 20px;
  padding: 2%;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${(props) =>
    props.iconType === "success" ? CommonColor.green : CommonColor.red};
  opacity: ${(props) => props.opacity};
  /* background-color: ${(props) =>
    props.bgColor ?? CommonColor.success_button_background}; */
`;
const CheckboxAccessEditor = styled(Checkbox)`
  color: ${CommonColor.primary_color};
  & svg {
    color: ${CommonColor.primary_color};
  }
`;
const CheckboxIcon = styled(IconAccessAllowedStatus)`
  border: none;
`;
const AcessMgmtMobile = styled(Grid)`
  && {
    padding: 2%;
    display: none;
    background-color: ${CommonColor.theme_background_color};
    @media ${DeviceSizes.mobile} {
      display: block;
    }
  }
`;
const AccessListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  height: 75vh;
  overflow: auto;
`;
const AccessPermissionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const AccessPermissionTitle = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 600;
  color: ${CommonColor.primary_color};
`;
const PermissionStatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
`;
const MobileAccessStatusSave = styled(NavigationButton)`
  @media ${DeviceSizes.mobile} {
    background-color: ${CommonColor.primary_color};
    color: ${CommonColor.white};
    border-radius: 4px;
  }
`;
const AccessMobileButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 2%;
  height: 46px;
`;
const AccessLoader = styled.div`
  position: absolute;
  top: 8vh;
  left: 0;
  height: 92%;
  width: 100%;
  background-color: ${CommonColor.loader_background};
  z-index: 1202;
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.isvisible === 1 ? "" : "none")};
`;
const AccessNavBarContainer = styled.div`
  height: 40px;
  margin-bottom: 1%;
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;
const AccessSearchContainer = styled.div`
  height: 100%;
  position: relative;
  width: 30%;
`;
const AccessSearchInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 40px;
  position: relative;
`;
const AccessSearchIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  flex: 1.2;
  height: 100%;
`;
const AccessSearchIconInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${CommonColor.primary_color};
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;
const SearchBarAccess = styled(SearchBar)`
  padding: 0;
  height: 100%;
  width: auto;
  flex: 9.5;
  position: static;
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-left: 1%;
  &:focus {
    border: 2px;
    outline: 0px;
    border-left: 0px;
    border-color: ${CommonColor.primary_color};
    border-style: solid;
  }
`;
const CircularContainer = styled(Avatar)`
  && {
    width: 30px;
    height: 30px;
    background-color: ${(props) =>
      props.ishighlight ? CommonColor.red : CommonColor.transparent};
    text-align: center;
    & p {
      font-family: "Mulish";
      font-weight: 500;
      font-size: ${FontSizes.SmallText};
      color: ${(props) =>
        props.ishighlight ? CommonColor.white : CommonColor.primary_color};
    }
  }
`;
const TextContainer = styled.p``;
const PlaceholderSearchPositionAccess = styled(PlaceholderSearchPosition)`
  top: 20%;
  right: 1%;
  height: 50%;
  bottom: 80%;
`;
const SelectUserIllustration = styled(CommonImages.SelectUser)`
  && {
    height: 261px;
  }
`;

const AccessWrapper = styled.div`
  width: 100%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
  padding: 10px;
`;

export {
  AccessTableLeave,
  AccessWrapper,
  SelectUserIllustration,
  PlaceholderSearchPositionAccess,
  TextContainer,
  CircularContainer,
  SearchBarAccess,
  AccessSearchIconInnerContainer,
  AccessSearchIconContainer,
  AccessSearchInnerContainer,
  AccessSearchContainer,
  AccessNavBarContainer,
  AccessLoader,
  AccessMobileButtonWrapper,
  MobileAccessStatusSave,
  PermissionStatusContainer,
  AccessPermissionTitle,
  AccessPermissionContainer,
  AccessListWrapper,
  AcessMgmtMobile,
  CheckboxIcon,
  CheckboxAccessEditor,
  IconAccessAllowedStatus,
  AccessTableData,
  AccessTableRow,
  AccessTableBody,
  AccessTableHead,
  AccessTableHeader,
  AccessTable,
  AccessMgmtTableWrapper,
  NavigationButton,
  NavigationButtonWrapper,
  AcessMgmtTitle,
  AccessContainer,
  AccessContainerNavbar,
  AccessManagementNavbarWrapper,
};
