import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexContainer } from "../../style/asset.style";
import { Div, TypoGraphy } from "../../style/common.style";
import {
  AttendanceOptions,
  AttendanceRow,
  AttendanceRowContainer,
  ChipAttendanceStatus,
  OptionIcon,
  OptionList,
} from "../../style/profile.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { delay, formatLoginTime } from "../../utils/utils";
import ConfirmationPopup from "../../component/Popup/ConfirmationPopup";
import { Menu } from "@mui/material";
import { CommonString } from "../../utils/common.string";
import { MsgContainer, MsgHeading } from "../../style/form.style";
import { setActiveUserMonthAttendance } from "../../reducer/attendancereduxstore";
import { Month } from "../../utils/constant";
import AttendanceController from "../../controller/attendanceController";
import { useSnackbar } from "notistack";

export default function Attendance(props) {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeAttendance, setActiveAttendance] = useState(null);
  const [open, setOpen] = useState(null);
  const attendanceData = useSelector(
    (state) => state.attendance.active_user_month_attendance
  );
  
  const leaveList = useSelector(
    (state) => state.leave.active_user_leave_list_profile
  );

  const leave = useSelector(
    (state) => state.leave
  )

  const employee = useSelector((state) => state.employee);

  function isLate(Dayattendance) {
    // if (!Dayattendance || Dayattendance === null) {
    //   return "";
    // }
    if (Dayattendance.in_time === "00:00" && Dayattendance.out_time === "00:00")
      return "Absent";
    if (Dayattendance.in_time !== "00:00") return "Present";
  }
  function getFirstBoxColor(attendance, list) {
    if (!attendance || attendance === null) {
      return { color: CommonColor.red, status: "Data not defined" };
    }
    const isOnLeave = isLeaveApplied(attendance._created_at, list);
    if (isLate(attendance) === "Present") {
      if (attendance.is_late === "1") {
        if (!isOnLeave) {
          return { color: CommonColor.secondary_color, status: "late" };
        } else return { color: CommonColor.green, status: "on leave and late" };
      } else return { color: CommonColor.green, status: "on time" };
    } else {
      if (isOnLeave) {
        return { color: CommonColor.green, status: "on leave" };
      } else {
        return { color: CommonColor.red, status: "uninformed leave" };
      }
    }
  }
  function isLeaveApplied(date, list) {
    const data = list.filter((dataSingle) => {
    
      if (dataSingle.leave.leave_type.toUpperCase() === "LATE") return false;
      if (dataSingle.leave.leave_type?.toUpperCase() === "MULTIPLE") {
        if (
          parseInt(moment(dataSingle.leave?.from_date).format("MMDD")) <=
            parseInt(moment(date).format("MMDD")) &&
          parseInt(moment(date).format("MMDD")) <=
            parseInt(moment(dataSingle.leave.to_date).format("MMDD"))
        ) {
          if (dataSingle.leave.status.toUpperCase() !== "APPROVED")
            return false;

          return true;
        }
        return false;
      } else {
        if (
          parseInt(moment(dataSingle.leave?.from_date).format("MMDD")) ===
          parseInt(moment(date).format("MMDD"))
        ) {
          if (dataSingle.leave.status.toUpperCase() !== "APPROVED")
            return false;

          return true;
        }

        return false;
      }
    });
    if (data.length === 0) return false;
    return true;
  }
  function getLeaveStatus(data, list) {
    if (!data) {
      return {
        status: "error",
        color: "",
      };
    }
    let in_time;
    if (data.in_time.indexOf("T") === -1) {
      in_time = data.in_time.replace(" ", "T");
      in_time = in_time + "Z";
    } else {
      in_time = data.in_time;
    }
    let out_time;
    if (data.out_time.indexOf("T") === -1 && data.out_time !== "00:00") {
      out_time = data.out_time.replace(" ", "T");
      out_time = out_time + "Z";
    } else {
      out_time = data.out_time;
    }
    const status = getFirstBoxColor(data, list).status;
    if (status === "on time") {
      return {
        status: formatLoginTime(in_time) + " - " + formatLoginTime(out_time),
        color: "success",
      };
    } else if (status === "late") {
      return {
        status: formatLoginTime(in_time) + " - " + formatLoginTime(out_time),
        color: "secondary",
      };
    } else if (status === "on leave and late") {
      return {
        status: formatLoginTime(in_time) + " - " + formatLoginTime(out_time),
        color: "success",
      };
    } else if (status === "on leave")
      return { status: "Member is on leave", color: "success" };
    else if (status === "uninformed leave")
      return {
        status: "Uninformed Leave",
        color: "error",
      };
  }
  function handleClick(event, Dayattendance, index) {
    setAnchorEl(event.currentTarget);
    setActiveAttendance(Dayattendance);
    setOpen(index);
  }
  function getOptions(Dayattendance) {
    const status = isLate(Dayattendance);
    if (status === "Absent") {
      const statusArray = ["Mark Present"];
      return statusArray;
    }
    if (status === "Present") {
      const statusArray = ["Mark Absent", "Mark Late"];
      return statusArray;
    }
    if (status === "Late") {
      const statusArray = ["Remove Late", "Mark Absent"];
      return statusArray;
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
    setActiveAttendance(null);
    setOpen(null);
  };
  return (
    <Div className="h-max overflow-auto">
      <FlexContainer flexDirection="column" jc="flex-start" height="100%">
        {/* <TypoGraphy fontSize={FontSizes.Title} weight="600">
          {getMonthAndYear(attendanceData[0]?._created_at)}
        </TypoGraphy> */}
        <AttendanceRowContainer>
          {[...attendanceData].reverse().map((data, index) => {
            return (
              <AttendanceRow key={index}>
                {/* <Div> */}
                <TypoGraphy fontSize={FontSizes.Subtitle} weight="700">
                  {moment(data._created_at).format("DD")}
                </TypoGraphy>
                <TypoGraphy fontSize={FontSizes.SubPara} weight="500">
                  {moment(data._created_at).format("dddd")}
                </TypoGraphy>
                {/* </Div> */}
                <Div className="flex justify-between h-full w-max items-center flex-col">
                  {attendanceData.length !== 0 ? (
                    <ChipAttendanceStatus
                      // className="h-max w-max text-xs pl-1 pr-1 rounded-md text-white break-words"
                      // style={{
                      //   backgroundColor: getLeaveStatus(data).color,
                      // }}
                      iscolornotexist={1}
                      isvisible={1}
                      variant="outlined"
                      label={getLeaveStatus(data, leaveList).status}
                      size="small"
                      chipcolor={getLeaveStatus(data, leaveList).color}
                    />
                  ) : (
                    ""
                  )}
                  {/* <Div
                    className="flex h-max w-max rounded-md pl-1 pr-1 flex-col-reverse text-white items-center text-xs"
                    style={{
                      backgroundColor:
                        isLate(data) === "Present"
                          ? CommonColor.green
                          : CommonColor.red,
                    }}
                  >
                    {isLate(data)}
                  </Div> */}
                </Div>
                {employee.employee_acess.attendance ? (
                  <AttendanceOptions>
                    <OptionIcon
                      icon="mi:options-vertical"
                      onClick={(e) => handleClick(e, data, index)}
                      height={15}
                    />
                    <MenuOption
                      options={getOptions(data)}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      open={open}
                      index={index}
                      attendanceData={data}
                      employee={props.employee}
                      monthAndYear={props.monthAndYear}
                    />
                  </AttendanceOptions>
                ) : (
                  <></>
                )}
              </AttendanceRow>
            );
          })}
        </AttendanceRowContainer>
      </FlexContainer>
    </Div>
  );
}

const MenuOption = (props) => {
  const dispatch = useDispatch();
  const [openMarkPresentConfirmation, setOpenPresentMarkConfirmation] =
    useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openMarkLateConfirmation, setOpenMarkLateConfirmation] =
    useState(false);
  const [openMarkAbsentConfirmation, setMarkAbsentConfirmation] =
    useState(false);
  const [openRemoveLateConfirmation, setOpenRemoveLateConfirmation] =
    useState(false);
  const [activeOption, setActiveOption] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const attendanceController = new AttendanceController();
  const attendance = useSelector((state) => state.attendance);
  const auth = useSelector((state) => state.auth);

  function getLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  }

  function action(option) {
    setActiveOption(option);
    if (option === "Mark Present") {
      setOpenPresentMarkConfirmation(true);
    }
    if (option === "Delete") {
      setOpenDeleteConfirmation(false);
    }
    if (option === "Mark Late") {
      setOpenMarkLateConfirmation(true);
    }
    if (option === "Mark Absent") {
      setMarkAbsentConfirmation(true);
    }
    if (option === "Remove Late") {
      setOpenRemoveLateConfirmation(true);
    }
  }

  async function MarkPresent() {
    setLoading(true);

    var date = new Date(props.attendanceData._created_at).getDate();
    var month = new Date(props.attendanceData._created_at).getMonth();
    var year = new Date(props.attendanceData._created_at).getFullYear();
    let out_time = new Date(year, month, date, 13, 30, 0).toISOString();

    const position = await getLocation().catch((error) => error);
    const sendData = {
      _id: props.attendanceData.id,
      user_id: props.attendanceData.user_id,
      attendance_date: props.attendanceData.attendance_date,
      in_time: out_time,
      out_time: out_time,
      lat: position.coords.latitude,
      is_late: "0",
      lon: position.coords.longitude,
      _created_at: out_time,
      _updated_at: moment().format(),
      approved_user_id: auth.employee_data.user._id,
    };

    const isMarkedPresent = await attendanceController.markPresent(sendData);
    await delay(1000);
    setLoading(false);
    if (isMarkedPresent) {
      const sucessMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>attendace status updated sucessfully</sucessMsg>
        </MsgContainer>
      );
      const currentMonthIndex = new Date(props.monthAndYear).getMonth();
      const year = new Date().getFullYear().toString();
      const dataMonthOne = {
        user_id: props.employee._id,
        month: Month[currentMonthIndex].toLowerCase(),
        year: year,
      };
      const monthAttendance = await attendanceController.getMonthAttendance(
        dataMonthOne
      );
      dispatch(setActiveUserMonthAttendance(monthAttendance));
      handleAlert(sucessMsg, "success");
      handlerClosePopup();
      return;
    }
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>please try again later</sucessMsg>
      </MsgContainer>
    );
    handleAlert(errorMsg, "error");
    handlerClosePopup();
  }
  function handlerClosePopup() {
    if (activeOption === "Mark Present") {
      setOpenPresentMarkConfirmation(false);
    }
    if (activeOption === "Delete") {
      setOpenDeleteConfirmation(false);
    }
    if (activeOption === "Mark Late") {
      setOpenMarkLateConfirmation(false);
    }
    if (activeOption === "Mark Absent") {
      setMarkAbsentConfirmation(false);
    }
    if (activeOption === "Remove Late") {
      setOpenRemoveLateConfirmation(false);
    }
  }
  function getConfirmationMessage(string) {
    const replacingString = props.employee.name + " ?";

    return string.replace("?", replacingString);
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  async function lateStatusChange(status) {
    const sendData = {
      is_late: status,
      id: props.attendanceData.id,
    };
    //api call
    const res = await attendanceController.markLate(sendData);

    if (res) {
      // var data = { ...props.attendanceData, is_late: status };
      const attendanceData = attendance.active_user_month_attendance.map(
        (attendanceData, index) => {
          if (attendanceData.id === props.attendanceData.id) {
            return {
              ...props.attendanceData,
              is_late: status,
            };
          }
          return attendanceData;
        }
      );
      dispatch(setActiveUserMonthAttendance(attendanceData));
      const successMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>late status updated sucessfully</sucessMsg>
        </MsgContainer>
      );
      handleAlert(successMsg, "success");

      handlerClosePopup();
      return;
    }
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>please try again later</sucessMsg>
      </MsgContainer>
    );
    handleAlert(errorMsg, "error");
    handlerClosePopup();
  }
  async function markAbsent() {
    setLoading(true);
    const sendData = {
      id: props.attendanceData.id,
    };
    //api call
    const res = await attendanceController.deleteAttendance(sendData);
    if (res) {
      const attendanceData = attendance.active_user_month_attendance.filter(
        (attendanceData) => {
          if (attendanceData.id === props.attendanceData.id) {
            return false;
          }
          return true;
        }
      );
      dispatch(setActiveUserMonthAttendance(attendanceData));
      const successMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>late status updated sucessfully</sucessMsg>
        </MsgContainer>
      );
      handleAlert(successMsg, "success");

      handlerClosePopup();
      setLoading(false);

      return;
    }
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>please try again later</sucessMsg>
      </MsgContainer>
    );
    handleAlert(errorMsg, "error");
    handlerClosePopup();
    setLoading(false);
  }
  function getModal() {
    if (activeOption === "Mark Present") {
      return (
        <ConfirmationPopup
          onHandleClose={() => setOpenPresentMarkConfirmation(false)}
          isShow={loading}
          open={openMarkPresentConfirmation}
          title={CommonString.markPresentPopupTitle}
          confirmationMessage={getConfirmationMessage(
            CommonString.markPresentPopupText
          )}
          yesButtonName={CommonString.yes}
          Confirmed={() => MarkPresent()}
          noButtonName={CommonString.no}
          Att={() => handlerClosePopup()}
          color={CommonColor.primary_color}
        />
      );
    }
    if (activeOption === "Delete") {
      return (
        <ConfirmationPopup
          onHandleClose={() => setOpenDeleteConfirmation(false)}
          isShow={loading}
          open={openDeleteConfirmation}
          title={CommonString.deleteAttendanceTitle}
          confirmationMessage={getConfirmationMessage(
            CommonString.deleteAttendanceText
          )}
          yesButtonName={CommonString.yes}
          Confirmed={() => setOpenDeleteConfirmation(false)}
          noButtonName={CommonString.no}
          Att={() => handlerClosePopup()}
          color={CommonColor.primary_color}
        />
      );
    }
    if (activeOption === "Mark Late") {
      return (
        <ConfirmationPopup
          onHandleClose={() => setOpenMarkLateConfirmation(false)}
          isShow={loading}
          open={openMarkLateConfirmation}
          title={CommonString.markLateConfirmationTitle}
          confirmationMessage={getConfirmationMessage(
            CommonString.markLateConfirmationText
          )}
          yesButtonName={CommonString.yes}
          Confirmed={() => lateStatusChange("1")}
          noButtonName={CommonString.no}
          Att={() => handlerClosePopup()}
          color={CommonColor.primary_color}
        />
      );
    }
    if (activeOption === "Mark Absent") {
      return (
        <ConfirmationPopup
          onHandleClose={() => setMarkAbsentConfirmation(false)}
          isShow={loading}
          open={openMarkAbsentConfirmation}
          title={CommonString.markAbsentConfirmationTitle}
          confirmationMessage={getConfirmationMessage(
            CommonString.markAbsentConfirmationText
          )}
          yesButtonName={CommonString.yes}
          Confirmed={() => markAbsent()}
          noButtonName={CommonString.no}
          Att={() => handlerClosePopup()}
          color={CommonColor.primary_color}
        />
      );
    }
    if (activeOption === "Remove Late") {
      return (
        <ConfirmationPopup
          onHandleClose={() => setOpenRemoveLateConfirmation(false)}
          isShow={loading}
          open={openRemoveLateConfirmation}
          title={CommonString.removeLateConfirmationTitle}
          confirmationMessage={getConfirmationMessage(
            CommonString.removeLateConfirmationText
          )}
          yesButtonName={CommonString.yes}
          Confirmed={() => lateStatusChange("0")}
          noButtonName={CommonString.no}
          Att={() => handlerClosePopup()}
          color={CommonColor.primary_color}
        />
      );
    }
  }
  return (
    <React.Fragment>
      {getModal()}
      <Menu
        anchorEl={props.anchorEl}
        id="account-menu"
        open={props.open === props.index}
        onClose={props.handleClose}
        onClick={props.handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {props.options
          ? props.options.map((option, index) => {
              return (
                <OptionList
                  isvisible={1}
                  key={index}
                  onClick={() => action(option)}
                >
                  {option}
                </OptionList>
              );
            })
          : ""}
      </Menu>
    </React.Fragment>
  );
};
