import React from "react";
import {
  GraphDetailContainer,
  LeaveIndicator,
  LeaveInfoTextContainer,
} from "../../style/leave.style";
import {
  LeaveInfoCardDataContainer,
  LeaveInfoData,
  LeaveInfoTitle,
} from "../../style/profile.style";
import CircularLeaveGraph from "../commonComponents/CircularLeaveGraph";

export default function LeaveBalanceGraph(props) {
  return (
    <GraphDetailContainer component={props.screen}>
      <LeaveInfoTextContainer mb="30%">
        <LeaveIndicator component="1"></LeaveIndicator>
        <LeaveInfoCardDataContainer>
          <LeaveInfoData component="1" horizintalFlex="flex-start">
            {props.total_leave}
          </LeaveInfoData>
          <LeaveInfoTitle component="1" parentComponent={props.screen}>
            Total  { props.isWfh ?  "WFHs" : "Leaves"   }
          </LeaveInfoTitle>
        </LeaveInfoCardDataContainer>
      </LeaveInfoTextContainer>
      <CircularLeaveGraph
        isWfh = {true}
        used_leave={props.used_leave}
        total_leave={props.total_leave}
        cicleWidth={props.cicleWidth}
      />
      <LeaveInfoTextContainer mb="30%">
        <LeaveInfoCardDataContainer component="2">
          <LeaveInfoData component="2" horizintalFlex="flex-end">
            {props.used_leave}
          </LeaveInfoData>
          <LeaveInfoTitle component="2" parentComponent={props.screen}>
            { props.isWfh ? "WFHs" : "Leaves"} Used
          </LeaveInfoTitle>
        </LeaveInfoCardDataContainer>
        <LeaveIndicator component="2"></LeaveIndicator>
      </LeaveInfoTextContainer>
    </GraphDetailContainer>
  );
}
