import { Icon } from "@iconify/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EventCard from "../../screen/EventList/EventCard";
import { FlexContainer } from "../../style/asset.style";
import {
  Div,
  TypoGraphy,
  VerticalScrollableArea,
} from "../../style/common.style";
import {
  LeaveCardNoDataContainer,
  LoaderContainer,
  NoDataComponentContainer,
} from "../../style/dashboard.style";
import {
  CalendarDayContainer,
  DailyEventContainer,
  EventCalendarWrapper,
  EventCardContainer,
  EventDot,
  EventListContainerMobile,
  EventListScrollerContainer,
  EventListToolbarContainer,
  EventListToolbarWrapper,
  EventToolbarSlider,
  LocalEventCalendarContainer,
  LocalEventListContainer,
} from "../../style/event.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { Month } from "../../utils/constant";
import EventBirthday from "../EventBirthday/EventBirthday";
import CelebrationNav from "../holiday/CelebrationNav";
import { setActiveDate } from "../../reducer/eventreduxstore";
import { eventTypes } from "../../utils/constant.array";
import {
  AddButton,
  AddSpeedyPrexelitesContainer,
  NoDataContainerCelebration,
} from "../../style/holiday.style";
import NoData from "../commonComponents/NoData/NoData";
import { CommonString } from "../../utils/common.string";
import { Box, Menu } from "@mui/material";
import MenuOptionListNavbarMobile from "../holiday/MenuOptionListNavbarMobile";
import PageRefreshLoader from "../Loader/PageRefreshLoader";
import { Calendar, DateObject } from "react-multi-date-picker";

export default function EventCalendarMobile(props) {
  const [eventsToday, setEventsToday] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const event = useSelector((state) => state.event);
  const [anchorEl, setAnchorEl] = useState(Month[0]);
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  function handleClose() {
    // setAnchorEl(undefined);
    setIsMonthOpen(false);
  }
  async function handleOptionClick(element, event) {
    setAnchorEl(null);
    setIsMonthOpen(false);
    let index = 0;
    Month.some((month) => {
      if (month === element) return month === element;
      index++;
    });
    await props.handleMonthChange(index + 1);
  }
  function popOverOpener(event) {
    setAnchorEl(event.currentTarget);
    setIsMonthOpen(true);
  }

  function serverDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    let i = 0;

    for (let j = 0; j < event.all_events.length; j++) {
      if (
        dayjs(day).format("MM-DD") === dayjs(event.event_date).format("MM-DD")
      ) {
        ++i;
        return i > 2;
      }
    }
    return (
      <React.Fragment>
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
        {[...Array(i)].map(() => (
          <EventDot isdisabled={outsideCurrentMonth} />
        ))}
      </React.Fragment>
    );
  }
  function getEventNum(date) {
    let numEvents = 0;
    for (let i = 0; i < event.all_events.length; i++) {
      if (
        moment
          .tz(new DateObject(date).format("MM/DD/YYYY"), "MM/DD/YYYY", "UTC")
          .format("MM-DD") ===
        moment.tz(event.all_events[i].date, "UTC").format("MM-DD")
      ) {
        ++numEvents;
        break;
      }
    }
    return (
      <FlexContainer jc="center">
        {[...Array(numEvents)].map(() => (
          <EventDot isdisabled={0} />
        ))}
      </FlexContainer>
    );
  }
  return (
    <EventCalendarWrapper
      style={{ backgroundColor: activeTab === 0 ? CommonColor.white : "" }}
      open={activeTab}
    >
      <LocalEventCalendarContainer>
        <Calendar
          className="multi-locale-days"
          maxDate={dayjs().endOf("year").format("YYYY-MM-DD HH:mm:ss")}
          mapDays={({ date }) => {
            return {
              children: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0",
                    fontSize: "11px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>{date.format("DD")}</div>
                  <div style={{ textAlign: "end" }}>{getEventNum(date)}</div>
                </div>
              ),
            };
          }}
          // onMonthChange={(newMonth) => {
          //   setMonthIndex(parseInt(dayjs(newMonth).format("MM")));
          // }}
          onChange={(day) =>
            props.getDateData(day.toUTC().format("YYYY-MM-DDTHH:MM:ss.SSSZ"))
          }
        />
      </LocalEventCalendarContainer>
      <EventListContainerMobile open={activeTab === 0}>
        <EventListToolbarWrapper>
          <EventListToolbarContainer
            onClick={() => setActiveTab(0)}
            active={activeTab === 0}
            className="allEvents"
          >
            All Events
          </EventListToolbarContainer>
          <EventListToolbarContainer
            onClick={() => setActiveTab(1)}
            active={activeTab === 1}
            className={activeTab === 1 ? "dailyEvents slider" : "dailyEvents"}
          >
            Daily Events
          </EventListToolbarContainer>
          <EventToolbarSlider open={activeTab === 0} className="slider" />
        </EventListToolbarWrapper>
        <DailyEventContainer
          active={activeTab === 1}
          className="dailyEventsList"
        >
          <VerticalScrollableArea height="-webkit-fill-available" unit="">
            {event.daily_events.map((event, index) => {
              if (event.type === eventTypes[0]) {
                return (
                  <LocalEventListContainer key={event}>
                    <EventCard index={index} element={event.data} />
                  </LocalEventListContainer>
                );
              } else {
                return (
                  <LocalEventListContainer key={event}>
                    <EventBirthday
                      event={event.data}
                      index={index}
                      eventType={event.type}
                    />
                  </LocalEventListContainer>
                );
              }
            })}

            {dayjs(props.selectedDate).day() === 0 ||
            dayjs(props.selectedDate).day() === 6 ? (
              <LeaveCardNoDataContainer>
                <NoDataComponentContainer>
                  <Icon
                    icon="healthicons:happy-outline"
                    fontSize={FontSizes.xxl}
                    color={CommonColor.secondary_color}
                  />
                  &nbsp;Its Weekend!
                </NoDataComponentContainer>
              </LeaveCardNoDataContainer>
            ) : (
              ""
            )}

            {eventsToday.length === 0 &&
            (dayjs(props.selectedDate).day() !== 0 ||
              dayjs(props.selectedDate).day() !== 6) ? (
              <LeaveCardNoDataContainer>
                <NoDataComponentContainer>
                  <Icon
                    icon="quill:user-sad"
                    fontSize={FontSizes.xxl}
                    color={CommonColor.secondary_color}
                  />
                  &nbsp;No events scheduled today
                </NoDataComponentContainer>
              </LeaveCardNoDataContainer>
            ) : (
              ""
            )}
          </VerticalScrollableArea>
        </DailyEventContainer>
        <DailyEventContainer active={activeTab === 0} className="allEventsList">
          <EventListScrollerContainer>
            <Menu
              id="menu-event-month"
              anchorEl={anchorEl}
              open={isMonthOpen}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "event-month-button",
              }}
            >
              <MenuOptionListNavbarMobile
                handleOptionClick={handleOptionClick}
                list={Month}
              />
            </Menu>
            <AddSpeedyPrexelitesContainer isvisible={1}>
              <AddButton
                variant="outlined"
                onClick={(event) => popOverOpener(event)}
                endIcon={
                  <Icon
                    icon="material-symbols:arrow-drop-down"
                    color={CommonColor.primary_color}
                    fontSize={24}
                  />
                }
              >
                <TypoGraphy
                  width="max-content"
                  color="inherit"
                  fontSize="inherit"
                >
                  {Month[event.active_month - 1]}
                </TypoGraphy>
              </AddButton>
            </AddSpeedyPrexelitesContainer>
            {!props.loading ? (
              <FlexContainer
                flexWrap="wrap"
                verticalAllign="start"
                jc="space-between"
              >
                {event.all_events.length !== 0 ? (
                  event.all_events.map((event, index) => {
                    if (event.type === eventTypes[0]) {
                      return (
                        <React.Fragment key={index}>
                          <EventCardContainer>
                            <EventCard index={index} element={event.data} />
                          </EventCardContainer>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={index}>
                          <EventCardContainer>
                            <EventBirthday
                              event={event.data}
                              index={index}
                              eventType={event.type}
                            />
                          </EventCardContainer>
                        </React.Fragment>
                      );
                    }
                  })
                ) : (
                  <NoDataContainerCelebration>
                    <NoData noDataText={CommonString.NoBirthday} />
                  </NoDataContainerCelebration>
                )}
              </FlexContainer>
            ) : (
              <LoaderContainer>
                <PageRefreshLoader />
              </LoaderContainer>
            )}
          </EventListScrollerContainer>
        </DailyEventContainer>
      </EventListContainerMobile>
    </EventCalendarWrapper>
  );
}
