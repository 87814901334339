import styled from "styled-components";
import { DeviceSizes } from "./common.style";
import { Theme } from "./theme/color";
import { FontFamilies, FontSizes } from "./theme/font";

export const LoginViewContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

export const LoginViewImage = styled.img`
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  @media ${DeviceSizes.mobile} {
    object-fit: cover;
  }
`;

export const FormContainer = styled.div`
  position: absolute;

  top: 0px;
  width: 100%;
  left: 0px;
  bottom: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const FormContainerHalf = styled.div`
  background: rgba(255, 255, 255, 0.25);
  padding-left: 5%;

  position: absolute;
  top: 14%;
  bottom: 0px;
  left: 13%;
  width: 34%;
  height: 72%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: blur(12px);
  border-radius: 4px;
  @media ${DeviceSizes.belowtablet} {
    width: 57%;
  }
  @media ${DeviceSizes.mobile} {
    left: 0%;
    width: 100%;
    padding-left: 0%;
    height: 100%;
    top: 0%;
    border-radius: 0px;
    padding: 5%;
    box-sizing: border-box;
  }
`;

export const TitleText = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.LargeText};
  font-weight: 700;
  color: ${Theme.primary_color.primary_color};
  margin-left: 0.5%;
`;

export const SubtitleText = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  font-weight: 400;
  color: ${Theme.primary_color.light_text};
  margin-top: 1%;
  margin-bottom: ${(props) => (props.iserrorexist ? "1%" : "4%")};
  margin-left: 0.5%;
`;

export const TextImp = styled.span`
  color: ${Theme.primary_color.secondary_color};
`;

export const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Subtitle};
  font-weight: 700;
  color: ${Theme.primary_color.red};
  margin-left: 0.5%;
  margin-bottom: 3%;
`;
