import {
  deleteLocalStorageItem,
  setLocalStorage,
} from "../services/LocalStorage/LocalStorage";
import AuthProvider from "../services/provider/authProvider";
import {
  api_token_key,
  attendance_login_status,
  userdata,
} from "../utils/common.key";
import { ismanager, pageIndexTitle } from "../utils/constant";
import { EmployeeLevelManagerList } from "../utils/mockData";
import { emailValidator } from "../utils/validation";
class LoginController {
  constructor(props) {
    this.authProvider = new AuthProvider();
  }

  onLogin = async (data) => {
    if (emailValidator(data.email)) {
      let res = await this.authProvider.login(data);
      if (res.data.status === 200) {
        setLocalStorage(api_token_key, res.data.token);
        setLocalStorage(userdata, {
          level: res.data.level,
          user: res.data.user,
        });
        const isManager = EmployeeLevelManagerList.includes(
          res.data.level.name
        );
        setLocalStorage(ismanager, isManager);
        deleteLocalStorageItem(pageIndexTitle);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  attendanceLogin = async (data) => {
    // setLocalStorage(attendance_login_status, data);
  };
}

export default LoginController;
