import React from "react";
import { InputStyle } from "../../../style/common.style";
import {
  CustomTextFeild,
  InputText,
  InputWrapper,
} from "../../../style/form.style";
import { FontSizes } from "../../../style/theme/font";

export default function TextInput(props) {
  return (
    <InputWrapper nomarginbottom={props.NoMarginBottom} {...props.stylingVar}>
      <InputText component={props.placeComponent}>{props.title}</InputText>
      <CustomTextFeild
        required
        id="outlined-required"
        InputProps={{
          inputProps: { min: 0 },
          style: { fontSize: FontSizes.Para },
        }}
        {...props}
        size="small"
        InputLabelProps={InputStyle}
        fullWidth
      />

      {/* <InputField
      min="0"
      variant="standard"
        {...props}

        /> */}
      {/* <InputFieldError>{props.helperText}</InputFieldError> */}
    </InputWrapper>
  );
}

/*
 <InputField
        id="input-with-icon-textfield"
        label="Default label"
        type="text"
        size="12"
        spellcheck="false"
        InputProps={{
          startAdornment: (
            <StartIconTextField position="start" >
              {props.icon ?? (
                <Icon icon="ant-design:user-outlined" color="transparent" />
              )}
            </StartIconTextField>
          ),
        }}
        variant="standard"
        {...props}
        />
*/
