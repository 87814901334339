import { Icon } from "@iconify/react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import {
  DatePickerCalendar,
  DeviceSizes,
  ErrorMarker,
  VerticalScrollableArea,
} from "./common.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CheckBox from "@mui/material/Checkbox";
import CommonColor from "../utils/common.colors";
import { Devices } from "@mui/icons-material";

export const FormContainer = styled.div`
  align-items: "center";
  justify-content: center;
  overflow-x: hidden;
  display: "flex";
  width: 100%;
  flex-direction: "column";

  @media ${DeviceSizes.belowtablet} {
    width: 100%;
    padding-left: 1%;
  }

  @media ${DeviceSizes.mobile} {
    align-items: flex-start;
    padding-right: 2%;
  }
`;
export const BigFormContainer = styled.div`
  align-items: "flex-end";
  justify-content: "flex-end";
  overflow-x: hidden;
  display: "flex";
  width: 60vw;
  height: 50vh;
  flex-direction: "column";

  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin-bottom: 5%;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: ${(props) => (props.nomarginbottom ? "0px" : "5px")};
  display: ${(props) => (props?.display === "nodisplay" ? "none" : "")};

  & input {
    display: ${(props) => (props?.display === "nodisplay" ? "none" : "flex")};
    align-items: center;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.zIndex};
  position: ${(props) => props.position};
`;
export const FormLoaderContainer = styled.div`
  position: relative;
`;
export const ButtonWrapper = styled.div`
  display: flex;
`;

export const InputField = styled.input`
  width: 90%;
  height: 30px;
  padding-left: 2%;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${Theme.primary_color.divider_gray};
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    &::placeholder {
      color: ${Theme.primary_color.primary_color};
    }
  }
`;
export const PopupHeading = styled.div`
  color: ${Theme.primary_color.primary_color};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "Mulish";

  font-weight: ${(props) => (props.componentsize === "big" ? "700" : "500")};
  font-size: ${FontSizes.SubPara};
`;
export const BigPopupHeading = styled.div`
  font-family: "Mulish";

  font-weight: 500;
  font-size: ${FontSizes.Para};
  padding: 2%;
  padding-left: 4%;
  color: ${Theme.primary_color.primary_color};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StartIconTextField = styled(InputAdornment)``;
export const PopupButton = styled(Button)`
  && {
    margin-right: 2%;
    background-color: ${(props) => props.bgcolor};
    color: ${Theme.primary_color.white};
    font-family: "Mulish";

    font-weight: 400;
    font-size: ${FontSizes.SubPara};
    /* width: ${(props) =>
      props.componenttype === "popup" ? "25%" : "30%"}; */
    /* padding-top: ${(props) =>
      props.componenttype === "popup" ? "0%" : "1.5%"};
    padding-bottom: ${(props) =>
      props.componenttype === "popup" ? "0%" : "1.5%"}; */
    text-transform: capitalize;
    border-radius: 5px;
    width: max-content;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 1%;
    padding-bottom: 1%;
    &:hover {
      background-color: ${(props) => props.bgcolor};
    }
    @media ${DeviceSizes.mobile} {
      width: max-content;
      padding-top: ${(props) =>
        props.componenttype === "popup" ? "0%" : "2.5%"};
      padding-bottom: ${(props) =>
        props.componenttype === "popup" ? "0%" : "2.5%"};
    }
  }
`;
export const PopupNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  background-color: ${Theme.primary_color.transparent};
  min-width: 20px;
  & :hover {
    background-color: ${Theme.primary_color.transparent};
  }
`;
export const Img = styled.img``;
export const VerticalScrollableAreaPopup = styled(VerticalScrollableArea)``;

export const BigVerticalScrollableArea = styled(VerticalScrollableAreaPopup)`
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.2em;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${Theme.primary_color.primary_color};
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
`;
export const InputText = styled.div`
  font-family: "Mulish";
  font-weight: 600;
  font-size: ${FontSizes.SubPara};
  color: ${Theme.primary_color.text_gray};
  text-transform: capitalize;
  margin-bottom: 1.5%;
  width: max-content;

  & + div input {
    font-size: ${(props) =>
      props.component === "small-input-text"
        ? FontSizes.SubPara
        : FontSizes.Para};
    font-weight: 500;
    font-family: "Mulish";
  }
`;
export const InputFieldError = styled(ErrorMarker)`
  margin-top: 1%;
`;
export const SelectField = styled(Select)`
  && {
    width: 93%;
    height: 30px;
    border-radius: 10px;
    border-width: 1px;
    border-color: ${Theme.primary_color.primary_color};
    padding-right: 30px;
  }
`;

export const CustomTextFeild = styled(TextField)`
  && {
    width: 100%;
    background-color: ${(props) => props.bg};
    text-transform: capitalize;
    & .MuiFormHelperText-root {
      margin-left: 0;
    }
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.error
          ? Theme.primary_color.red
          : Theme.primary_color.input_border};
      border-radius: 6px;
    }
    & .Mui-focused fieldset {
      border-color: ${Theme.primary_color.primary_color};
      border-width: 2px;
    }
    -moz-appearance: textfield;
    & ::-webkit-outer-spin-button,
    & ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & input::placeholder {
      font-size: ${FontSizes.Para};
    }
    & textarea::placeholder {
      font-size: ${FontSizes.Para};
    }
    & .Mui-focused input::placeholder {
      color: ${Theme.primary_color.primary_color};
      opacity: 1;
    }
    @media ${DeviceSizes.mobile} {
      width: 99%;
    }
  }
`;
export const CustomAutoCompelete = styled(Autocomplete)`
  && {
    width: 100%;

    text-transform: capitalize;
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${Theme.primary_color.input_border};
      border-radius: 10px;
    }
    & .Mui-focused fieldset {
      border-color: ${Theme.primary_color.primary_color};
      border-width: 1px;
    }
    -moz-appearance: textfield;
    & ::-webkit-outer-spin-button,
    & ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & input::placeholder {
      font-size: ${FontSizes.Para};
    }
    & textarea::placeholder {
      font-size: ${FontSizes.Para};
    }
    & .Mui-focused input::placeholder {
      color: ${Theme.primary_color.primary_color};
      opacity: 1;
    }
    @media ${DeviceSizes.mobile} {
      width: 99%;
    }
  }
`;

export const SelectFieldWrapper = styled.div`
  margin-bottom: ${(props) => (props.nomarginbottom ? "0px" : "10px")};
  width: 100%;
`;

export const MenuItemStyle = (props) => {
  return {
    textTransform: "capitalize",
    fontSize: FontSizes.SubPara,
  };
};

export const FileInputContainer = styled.label`
  border: 1px solid ${Theme.primary_color.input_border};
  display: inline-block;
  padding: 0px;
  cursor: pointer;
  font-family: "Mulish";

  font-weight: 500;
  font-size: ${FontSizes.SubPara};
  color: ${Theme.primary_color.text_gray};
  width: 100%;
  height: 70%;
  border-radius: 10px;
  margin-bottom: ${(props) => (props.nomarginbottom ? "0px" : "10px")};
  margin-left: 1%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 4%;
  box-sizing: border-box;
  &:focus {
    border: 3px solid ${Theme.primary_color.primary_color};
    color: ${Theme.primary_color.primary_color};
  }
`;

export const FileInputMainContainer = styled.div`
  height: 17%;
`;

export const MenuItemStyleInlineDropdown = () => {
  return {
    display: "inlineFlex",
    flexWrap: "wrap",
  };
};

export const OptionBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const MUICheckbox = styled(Checkbox)`
  color: ${Theme.primary_color.primary_color};
`;
export const OptionContainer = styled.div``;

export const ChipButton = styled(Button)`
  && {
    border-radius: 50px;
    color: ${Theme.primary_color.primary_color};
    border-color: ${Theme.primary_color.primary_color};
    &:hover {
      color: ${Theme.primary_color.primary_color};
      border-color: ${Theme.primary_color.primary_color};
      background-color: ${Theme.primary_color.transparent};
    }
  }
`;
export const MuiCheckBoxWrapper = styled(FormControlLabel)``;
export const CheckBoxesContainer = styled(FormGroup)`
  && {
    max-height: ${(props) => (props.open ? "100%" : "0px")};
    overflow: hidden;
  }
`;

export const MuiCheckbox = styled(Checkbox)`
  && {
    &.Mui-checked {
      color: ${Theme.primary_color.primary_color};
    }
  }
`;

export const ChipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 15%;
  margin: 2%;
  margin-left: 0%;
`;
export const MuiChip = styled(Chip)`
  && {
    margin-right: 10%;
  }
`;
export const InputLogin = styled(TextField)`
  && {
    width: 80%;
    /* background-color: ${Theme.primary_color.login_input_background}; */
    border-radius: 3px;
    & input {
      height: 23px;
    }

    & input:-webkit-autofill,
    & input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    & input[data-autocompleted] {
      background-color: transparent !important;
    }

    & .MuiOutlinedInput-notchedOutline {
      border-color: ${Theme.primary_color.text_gray};
      border-radius: 4px;
    }
    &:hover fieldset {
      /* border-color: ${Theme.primary_color.light_text}; */
    }
    & .Mui-focused fieldset {
      border-color: ${Theme.primary_color.primary_color};
      border-width: 1px;
      box-shadow: 0 0 0 2px #15365594;
    }
    -moz-appearance: textfield;
    & ::-webkit-outer-spin-button,
    & ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & input {
      color: ${Theme.primary_color.primary_color};
      font-family: "Mulish";
      font-size: ${FontSizes.Para};
      padding: 2% 5%;
      display: flex;
      align-items: center;
      vertical-align: middle;
    }
    & input::placeholder {
      font-size: ${FontSizes.Para};
      color: ${Theme.primary_color.light_text};
      opacity: 1;
      vertical-align: middle;
    }
    & .Mui-focused input::placeholder {
      color: ${Theme.primary_color.light_text};
      opacity: 0.5;
    }
    @media ${DeviceSizes.belowtablet} {
      width: 100%;
    }
  }
`;

export const IconifyIconInput = styled(Icon)`
  color: ${Theme.primary_color.text_gray};
  font-size: 19px;
  cursor: pointer;
`;
export const FormSubmitButton = styled(Button)`
  && {
    font-family: "Mulish";
    margin-left: 3px;

    font-size: ${FontSizes.Para};
    font-weight: 700;
    text-transform: capitalize;
    width: 80%;
    margin-left: 0.5%;
    margin-top: 1.8%;
    border-radius: 4px;
    color: ${Theme.primary_color.white};
    background-color: ${Theme.primary_color.primary_color};
    &:hover {
      background-color: ${Theme.primary_color.primary_color};
    }
    @media ${DeviceSizes.bigScreen} {
      padding: 1% 0%;
    }
    @media ${DeviceSizes.belowtablet} {
      width: 100%;
    }
  }
`;

export const IconButton = styled(FormSubmitButton)`
  && {
    min-width: fit-content;
    min-height: fit-content;
    width: 5%;
    padding: 0;
    margin: 0;
    background-color: ${Theme.primary_color.transparent};
    &:hover {
      background-color: ${Theme.primary_color.transparent};
    }
  }
`;
export const DropAndFileZone = styled.div`
  width: 100%;
  height: 100%;
`;
export const FileFormZone = styled.div`
  width: 40%;
  margin-left: 10%;
`;
export const FileZoneContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding-bottom: 4%;
`;
export const FileZone = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
  @media ${DeviceSizes.mobile} {
    flex-direction: column-reverse;
  }
`;

export const AddPdfFormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 5%; 
  padding-left: 5%;
  padding-top: 2%;
  /* padding-top: 10%; */
  @media ${DeviceSizes.mobile} {
    width: 93%;
  }
`;
export const PdfViewerContainer = styled.div`
  position: relative;
  width: 73%;
  height: 100%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
export const ChangeFileContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: ${Theme.primary_color.light_primary};
`;

export const MuiDivider = styled(Divider)`
  && {
    margin-top: ${(props) => props.mt ?? "1%"};
    margin-bottom: ${(props) => props.mb ?? "1%"};
    margin-left: ${(props) => props.ml ?? ""};
    margin-right: ${(props) => props.mr ?? ""};
  }
`;
export const PopupContainer = styled.div`
  width: 100%;
`;
export const LeaveMainOptionContainer = styled.div`
  width: 100%;
  display: ${(props) => (props.isvisible === 1 ? "" : "none")};
  position: relative;
  background-color: ${Theme.primary_color.transparent};
  border-radius: 50px;
  border: 1px solid ${Theme.primary_color.primary_color};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2%;
  max-height: ${(props) => (props.isvisible === 1 ? "60px" : "0px")};
  padding: ${(props) => (props.isvisible === 1 ? "" : "0px")};
  border: ${(props) => (props.isvisible === 1 ? "" : "0px")};
  overflow: hidden;
  transition: max-height 0s ease-in-out;
`;
export const LeaveActiveBackground = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => `calc(calc(100%/${props.options})*${props.leaveOptions})`};
  width: ${(props) => `calc(100%/${props.options})`};
  border-radius: 50px;
  height: 100%;
  background-color: ${(props) => props.bg};
  transition: left 0.5s ease-in-out;
  z-index: 1;
`;
export const LeaveLateOption = styled.div`
  position: relative;
  width: 50%;
  text-transform: capitalize;
  font-family: "Mulish";

  font-weight: 500;
  font-size: ${FontSizes.Para};
  color: ${(props) =>
    props.leaveOptions === props.index
      ? Theme.primary_color.white
      : Theme.primary_color.primary_color};
  text-align: center;
  z-index: 2;
  display: flex;
  justify-content: center;
  transition: color 0.5s ease-in-out;
`;
export const LeaveLeaveOption = styled(LeaveLateOption)`
  color: ${(props) =>
    props.leaveOptions !== props.index
      ? Theme.primary_color.primary_color
      : Theme.primary_color.white};
  max-height: ${(props) => (props.isvisible === 1 ? "60px" : "0px")};
  overflow: hidden;
  transition: max-height 0s ease-in-out;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LeaveOptionContainer = styled.div`
  width: 100%;
  margin-bottom: 1%;

  /* @media ${DeviceSizes.mobile} {
    margin-bottom: 100%;
  } */
`;
export const RadioContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-transform: capitalize;
`;

export const RadioButtonContainer = styled(FormControl)`
  && {
  }
`;
export const RadioGroupContainer = styled(RadioGroup)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    & label span {
      font-size: ${FontSizes.Para};
      font-weight: 500;
      font-family: "Mulish";

      text-transform: capitalize;
    }
    & .Mui-checked {
      color: ${Theme.primary_color.primary_color};
    }
  }
`;
export const NumDayContainer = styled.div`
  margin-top: 2%;
  font-size: ${FontSizes.Para};
  font-weight: 500;
  font-family: "Mulish";
`;
export const DatePicker = styled(MobileDatePicker)``;

export const ApplyLeaveFormContainer = styled(FormContainer)`
  padding: 0;
`;

export const DateCalendarContainer = styled.div`
  width: 100%;
  margin-top: 1%;
  margin-bottom: 2%;
  max-height: ${(props) => (props.isvisible === 1 ? "0px" : "100px")};
  transform-origin: 50% 0%;
  transition: all 0s ease-in-out;
  overflow: hidden;
  & .rmdp-container {
    width: 100%;
  }
  & input {
    width: 100%;
    height: 100%;
    padding: 1.7%;
    border-radius: 6px;
    border: 1px solid ${Theme.primary_color.input_border};
    font-family: "Mulish";
    font-weight: 600;
    font-size: ${FontSizes.Para};
    color: ${CommonColor.primary_color};
  }
  & input:focus {
    border-width: 2px;
    border-color: ${CommonColor.primary_color};
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
    padding: 0;
    padding-top: ${(props) => (props.isvisible === 1 ? "0px" : "3%")};
    padding-bottom: ${(props) => (props.isvisible === 1 ? "0px" : "3%")};
    padding-right: ${(props) => (props.isvisible === 1 ? "0px" : "3%")};
    & input {
      padding: 3.2%;
      font-size: ${FontSizes.SubPara};
      font-weight: 700;
    }
  }
`;

export const CalendarTitle = styled.div`
  font-size: ${FontSizes.SubPara};
  font-family: "Mulish";
  font-weight: 500;
  color: ${Theme.primary_color.text_gray};
  margin-bottom: 1%;
`;
export const ConfirmationButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5%;
  margin-top: 0%;
`;
export const ConfirmDate = styled(Button)`
  && {
    background-color: ${Theme.primary_color.primary_color};
    font-family: "Mulish";

    font-weight: 500;
    font-size: ${FontSizes.Para};
    color: ${Theme.primary_color.white};
    border-radius: 3px;
    text-transform: capitalize;
    &:hover {
      background-color: ${Theme.primary_color.primary_color};
    }
  }
`;
export const CancelDate = styled(Button)`
  && {
    font-family: "Mulish";

    font-weight: 500;
    font-size: ${FontSizes.Para};
    border-radius: 3px;
    text-transform: capitalize;
    color: ${Theme.primary_color.primary_color};
    border-color: ${Theme.primary_color.primary_color};
    border-width: 1px;
    &:hover {
      border-color: ${Theme.primary_color.primary_color};
      color: ${Theme.primary_color.primary_color};
    }
  }
`;

export const CalendarDatePicker = styled(MobileDatePicker)``;
export const DatePickerContainer = styled(LocalizationProvider)`
  && {
    /* & input {
      background-color: red;
    } */
  }
`;

export const BirthDatePicker = styled(DatePickerCalendar)`
  & input {
    padding: 6%;
    width: 226%;
  }
`;
export const BirtDateContainer = styled(DateCalendarContainer)`
  height: 15%;
  & input {
    width: 225%;
    padding: 6%;
    font-family: "Mulish";

    font-weight: 500;
    font-size: ${FontSizes.SubPara};
  }
`;

export const MultiDateCalendarContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: ${(props) => (props.isvisible === 1 ? "100px" : "00px")};
  overflow: hidden;
  transition: max-height 1s ease-in-out;
  transform-origin: 50% 0%;
  & input {
    width: 100%;
    height: 36px;
    border-radius: 6px;
    color: ${CommonColor.primary_color};
  }
  & input:focus {
    border-color: ${CommonColor.primary_color};
  }
  & .rmdp-container {
    width: 100%;
  }
  & div:first-child {
    margin-right: 2%;
  }
  @media ${DeviceSizes.mobile} {
    display: block;
    width: 100%;
    max-height: ${(props) => (props.isvisible === 1 ? "200px" : "00px")};
  }
`;
export const MultiDateChild = styled.div`
  width: 100%;
  @media ${DeviceSizes.mobile} {
    width: 95%;
  }
`;
export const HalfDayOptionsWrapper = styled;

export const HalfDayOptionContainer = styled(FormGroup)`
  && {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    max-height: ${(props) => (props.isvisible === 1 ? "100px" : "0px")};
    transform-origin: 50% 0%;
    transition: all 1s ease-in-out;
    overflow: hidden;

    & span {
      font-family: "Mulish";
      font-weight: 500;
      font-size: ${`calc(${FontSizes.SmallText}-10px)`};
      color: ${Theme.primary_color.primary_color};
    }
    & label {
      position: relative;
      margin-right: 0%;
      margin-left: 0.5%;
      margin-top: 9%;
    }
    & label span.MuiTypography-root {
      width: 141%;
    }
    & .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover {
      background: ${Theme.primary_color.transparent};
    }
    & .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
      position: absolute;
      left: 11%;
    }
  }
`;
export const HalfDayOption = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 1fr;
  }

  & .MuiButtonBase-root {
    grid-column: 1/2;
  }
  && .MuiTypography-root {
    font-family: "Mulish";
    font-weight: ${(props) => props.fontWeight};
    font-size: ${FontSizes.SmallText};
    color: ${(props) => props.fontColor};
    grid-column: 2/13;
  }
  & input {
    width: max-content;
    height: max-content;
  }
`;
export const MuiCalendarCheckbox = styled(CheckBox)`
  && {
    color: ${(props) =>
      props.checked
        ? Theme.primary_color.primary_color
        : Theme.primary_color.input_border};
  }
`;
export const FormContainerEvent = styled(FormContainer)``;
export const FormContainerAddPrexelite = styled(FormContainer)`
  @media ${DeviceSizes.mobile} {
    margin-top: 2%;
  }
`;
export const LoaderContainer = styled.div`
  z-index: 3;
  background-color: ${Theme.primary_color.popup_background_color};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  & div {
    height: 100%;
  }
`;
export const LeaveStatusContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  text-transform: capitalize;
`;
export const LeaveStatusTitle = styled.div`
  text-transform: capitalize;
`;
export const LeaveStatusMsg = styled.div`
  font-size: ${FontSizes.SubPara};
  text-transform: capitalize;
`;

export const VerticalScrollableAreaInPopup = styled(
  VerticalScrollableAreaPopup
)`
  padding: 0%;
  height: ${(props) => (props.isfullheight ? "100%" : "")};
  overflow-x: visible;
  max-height: ${(props) => (props.isVisible ? "300px" : "0px")};
  transition: max-height 0s ease-in-out;
`;

export const LeaveStatusMsgContainer = styled.div``;

export const TextContainer = styled.div`
  margin-right: 1%;
  && div {
    font-weight: 500;
  }
`;
export const PopupNavBarContainer = styled.div`
  width: 100%;
`;

export const PopupNavBarContainerLeave = styled(PopupNavBarContainer)``;

export const MsgHeading = styled.div`
  font-size: ${FontSizes.Subtitle};
`;

export const MsgContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 500;
  color: ${Theme.primary_color.white};
  text-transform: capitalize;
`;
export const AssetFormContainer = styled.div`
  padding: 2%;
  padding-bottom: 0;
  background: ${CommonColor.white};
`;

export const FormDataContainer = styled(FormContainer)``;

export const CalendarContainer = styled.div`
  width: 100%;
  max-height: ${(props) => (props.isvisible === 1 ? "100%" : "0px")};
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
  & .MuiFormControl-root {
    width: 100%;
  }
  & .rmdp-container {
    width: 100%;
  }
  & .MuiOutlinedInput-root {
    height: 36px;
    border-radius: 7px;
    margin-bottom: 2%;
    border-width: 1px;
  }
  &
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: ${CommonColor.primary_color};
  }
`;
export const ModalContainer = styled(Dialog)`
  & h2 {
    padding: 10px;
  }
  & .MuiDialogContent-root {
    padding: 10px;
  }
`;
export const LateLeaveContainer = styled.div`
  max-height: ${(props) => (props.isvisible === 1 ? "300px" : "0px")};
  transition: all 0s ease-in-out;
  overflow: hidden;
`;

export const LeaveApplyContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const PopupVerticalSeprator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
`;

export const LoaderContainerCircle = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 17%;
`;

export const LoaderText = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  color: ${CommonColor.primary_color};
  font-weight: 600;
  width: max-content;
`;
