import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import * as React from "react";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import { Div } from "../../style/common.style";
import {
  LogoutConfirmationButtonsContainer,
  LogoutConfirmationContainer,
  LogoutMessage,
  LogoutTitle,
} from "../../style/detailpopup.style";
import CommonColor from "../../utils/common.colors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationPopup(props) {
  return (
    <Dialog
      open={props.open}
      fullScreen={false}
      maxWidth={props.popupSize ?? "sm"}
      fullWidth={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onHandleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <LogoutConfirmationContainer>
        <LogoutTitle>{props.title}</LogoutTitle>
        <LogoutMessage>{props.confirmationMessage}</LogoutMessage>
        <LogoutConfirmationButtonsContainer>
          {props.isShow ? (
            <div style={{ marginRight: 20 }}>
              <div class="loader"></div>
            </div>
          ) : null}
          <Div className="grid grid-cols-2 gap-x-2">
            <Div className="grid col-span-1">
              <ButtonCustomV2
                onClick={() => props.Confirmed()}
                variant="contained"
                customBGColor={props.color}
                customTextColor={CommonColor.white}
                customOutlineColor={props.color}
                buttonText={props.yesButtonName}
              />
            </Div>
            <Div className="grid col-span-1">
              <ButtonCustomV2
                onClick={() => props.Att()}
                variant="outlined"
                customBGColor={CommonColor.transparent}
                hoverBG={CommonColor.primary_color_opacity_05}
                customTextColor={props.color}
                customOutlineColor={props.color}
                buttonText={props.noButtonName}
              />
            </Div>
          </Div>
        </LogoutConfirmationButtonsContainer>
      </LogoutConfirmationContainer>
    </Dialog>
  );
}
