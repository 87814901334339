import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  PointsTableBody,
  PointsTableBodyRow,
  PointsTableCell,
  PointsTableContainer,
  PointsTableHeaderContainer,
  PointsTableHeaderRow,
} from "../../../style/synergy.league.style";
import { Icon } from "@iconify/react/dist/iconify.js";
import CommonColor from "../../../utils/common.colors";

export default function PointsTable(props) {
  const synergyLeaguePointsTable = useSelector(
    (state) => state.synergyleague.points_table
  );
  const synergyLeaguePointsTableHeader = [
    { name: "Game(s)", width: "25%" },
    { name: "Point(s)", width: "13%" },
    { name: "Defenders", width: "13%" },
    { name: "Warriors", width: "13%" },
    { name: "Navigators", width: "13%" },
    { name: "Mavericks", width: "13%" },
    { name: "Menu", width: "10%" },
  ];

  return (
    <React.Fragment>
      <PointsTableContainer>
        <PointsTableHeaderContainer>
          {synergyLeaguePointsTableHeader.map((row, index) => {
            return (
              <PointsTableHeaderRow key={index} width={row.width}>
                {row.name}
              </PointsTableHeaderRow>
            );
          })}
        </PointsTableHeaderContainer>
        <PointsTableBody>
          {synergyLeaguePointsTable.map((row, index) => {
            return (
              <PointsTableBodyRow key={index}>
                <PointsTableCell
                  index={index}
                  len={synergyLeaguePointsTable.length - 1}
                  width={synergyLeaguePointsTableHeader[0].width}
                >
                  {row.game_name}
                </PointsTableCell>
                <PointsTableCell
                  index={index}
                  len={synergyLeaguePointsTable.length - 1}
                  width={synergyLeaguePointsTableHeader[1].width}
                >
                  {row.game_points}
                </PointsTableCell>
                <PointsTableCell
                  index={index}
                  len={synergyLeaguePointsTable.length - 1}
                  width={synergyLeaguePointsTableHeader[2].width}
                >
                  {row.defenders}
                </PointsTableCell>
                <PointsTableCell
                  index={index}
                  len={synergyLeaguePointsTable.length - 1}
                  width={synergyLeaguePointsTableHeader[3].width}
                >
                  {row.warriors}
                </PointsTableCell>
                <PointsTableCell
                  index={index}
                  len={synergyLeaguePointsTable.length - 1}
                  width={synergyLeaguePointsTableHeader[4].width}
                >
                  {row.navigators}
                </PointsTableCell>
                <PointsTableCell
                  index={index}
                  len={synergyLeaguePointsTable.length - 1}
                  width={synergyLeaguePointsTableHeader[5].width}
                >
                  {row.mavericks}
                </PointsTableCell>
                <PointsTableCell
                  index={index}
                  len={synergyLeaguePointsTable.length - 1}
                  width={synergyLeaguePointsTableHeader[6].width}
                >
                  {!row.isUnEditable && (
                    <React.Fragment>
                      <Icon
                        icon="typcn:delete"
                        style={{
                          fontSize: 22,
                          cursor: "pointer",
                          color: CommonColor.red,
                          marginBottom: -3,
                        }}
                        onClick={() => {
                          props.EditOrDel("del", row);
                        }}
                      />{" "}
                      |{" "}
                      <Icon
                        icon="flowbite:edit-solid"
                        style={{ fontSize: 20, cursor: "pointer" }}
                        onClick={() => {
                          props.EditOrDel("edit", row);
                        }}
                      />
                    </React.Fragment>
                  )}
                </PointsTableCell>
              </PointsTableBodyRow>
            );
          })}
        </PointsTableBody>
      </PointsTableContainer>
    </React.Fragment>
  );
}

/*

*/
