import { DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import Profile from "../../../../screen/Profile/Profile";
import { BigPopupTitle } from "../../../../style/dashboard.style";
import { DialogProfileContainer } from "../../../../style/detailpopup.style";
import PopupNavBar from "../PopupNavBar";

export default function ProfileScreenPopup(props) {
  return (
    <React.Fragment>
      <DialogTitle>
        <BigPopupTitle>
          <PopupNavBar
            onHandleClose={() => props.onHandleClose()}
            componentType="navbarmain"
            componentSize="big"
            title="Member Detail"
          />
        </BigPopupTitle>
      </DialogTitle>
      <DialogContent>
        <DialogProfileContainer>
          <Profile
            employeeDetail={props.user}
            LoadingFlag={false}
            isPopupOpen={props.isPopupOpen}
          />
        </DialogProfileContainer>
      </DialogContent>
    </React.Fragment>
  );
}
