import React from "react";
import {
  ShimmerText,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";
import {
  LoginShimmerContainer,
  LoginShimmerText,
} from "../../../style/loader.style";

export default function LoginCardShimmer() {
  return (
    <LoginShimmerContainer>
      <LoginShimmerText>
        <ShimmerTitle line={1} gap={10} variant="primary" />
        <ShimmerText line={5} gap={10} />
      </LoginShimmerText>
      <ShimmerThumbnail width={180} height={180} rounded />
    </LoginShimmerContainer>
  );
}
