import styled from "styled-components";
import CommonColor from "../utils/common.colors";
import { FontSizes } from "./theme/font";

const SwitchContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.jc};
`;
const SwitchButtonContainer = styled.div`
  && span {
    color: ${CommonColor.primary_color};
  }
  && .MuiSwitch-track {
    background-color: ${CommonColor.primary_color};
  }
`;

const DropDownWithChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

const TrapezoidContainer = styled.div`
  background-color: ${(props) => props.color};

  height: 20px;
  width: ${(props) => `${props.length}px`};
  color: ${(props) => props.fontColor};
  display: flex;
  justify-content: center;
  align-items: start;
  font-family: "Mulish";
  font-size: ${FontSizes.SmallText};
  text-transform: capitalize;
  font-weight: 400;
`;
export {
  SwitchContainer,
  SwitchButtonContainer,
  DropDownWithChipContainer,
  TrapezoidContainer,
};
