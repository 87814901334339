import moment from "moment";
import { updateEmployeeLeaveDatas } from "../reducer/leavereduxstore";
import LeaveProvider from "../services/provider/leaveProvider";
export default class LeaveController {
  constructor(props) {
    this.leaveProvider = new LeaveProvider();
  }
  findDateDifference = (from_date, to_date) => {
    const fromDateWoTime = from_date.split(" ")[0].split("_");
    const toDateWoTime = to_date.split(" ")[0].split("_");
    const dateFrom = new Date(
      fromDateWoTime[0],
      fromDateWoTime[1],
      fromDateWoTime[2]
    );
    const dateTo = new Date(toDateWoTime[0], toDateWoTime[1], toDateWoTime[2]);
    return (dateFrom - dateTo) / (1000 * 3600 * 24);
  };
  getDate = (date) => {
    const dateList = date.split(" ")[0].split("-");
    return dateList[2] + "/" + dateList[1] + "/" + dateList[0];
  };
  getEmployeeLeaveData = async (employeeId) => {
    const data = {
      recruiter_id: employeeId,
      month: moment().month() + 1,
      year: moment().year(),
    };
    const response = await this.leaveProvider.getEmployeeLeaveData(data);
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting list in controller");
      return false;
    }
  };

  getEmployeeLeaveDataByMonth = async (data) => {
    const response = await this.leaveProvider.getEmployeeLeaveData(data);
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting list in controller");
      return false;
    }
  };
  getEmployeeLeaveDataForBal = async (employeeId) => {
    const data = {
      recruiter_id: employeeId,
      month: moment().month() + 1,
      year: moment().year(),
    };
    const response = await this.leaveProvider.getEmployeeLeaveData(data);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error("error while getting list in controller");
      return false;
    }
  };
  getLeaveInfoEmployees = async () => {
    let response = await this.leaveProvider.allEmployeeLeaveData();
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error with api call in controller");
      return "error";
    }
  };
  getEmployeesLeaveData = async () => {
    const employeesLeaveList = await this.getLeaveInfoEmployees();

    let pendingEmployeeLeaveData = employeesLeaveList.filter(
      (EmployeeLeaveData) => EmployeeLeaveData.leave.status === "Pending"
    );
    return pendingEmployeeLeaveData;
  };
  getPendingEmployeeLeaveDatas = async (employeeId) => {
    const employeeLeaveData = await this.getEmployeeLeaveData(employeeId);

    if (employeeLeaveData === "error") return [];
    let pendingEmployeeLeaveData = employeeLeaveData.filter(
      (EmployeeLeaveData) => EmployeeLeaveData.leave.status === "Pending"
    );
    return pendingEmployeeLeaveData;
  };
  getPendingEmployeeLeaveData = async () => {
    let employeesPendingLeave = await this.getLeaveInfoEmployees();
    if (employeesPendingLeave === "error") return "error";
    return employeesPendingLeave;
  };
  getLeaveNumbers = async (data) => {
    let response = await this.leaveProvider.getLeaveData(data);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  };
  addLeave = async (data) => {
    let response = await this.leaveProvider.addLeave(data);
    if (response.data.status === 200) return true;
    return false;
  };
  upadateLeaveStatus = async (data) => {
    let response = await this.leaveProvider.updateLeaveStatus(data);
    if (response.data.status === 200) {
      return true;
    }
    return false;
  };
  updateLeave = async (data) => {
    let response = await this.leaveProvider.updateLeave(data);
    if (response.data.status === 200) {
      return true;
    }
    return false;
  };
  deleteLeave = async (data) => {
    let response = await this.leaveProvider.deleteLeave(data);
    if (response.data.status === 200) {
      return true;
    }
    return false;
  };

  addLeaveCredit = async (data) => {
    let response = await this.leaveProvider.addLeaveCredit(data);
    if (response.data.status === 200) return true;
    return false;
  };
  deleteLeaveCredit = async (data) => {
    let response = await this.leaveProvider.deleteLeaveCredit(data);
    if (response.data.status === 200) return true;
    return false;
  };
  getLeaveHistory = async () => {
    let response = await this.leaveProvider.getLeaveHistory();
    if (response.status === 200) return response.data.data;
    return false;
  };
}
