import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import transition from "react-element-popper/animations/transition";
import { DateObject } from "react-multi-date-picker";
import { useDispatch } from "react-redux";
import BottomButtons from "../../component/Forms/components/BottomButtons";
import ShowLoader from "../../component/Loader/ShowLoader";
import PopupNavBar from "../../component/commonComponents/Popups/PopupNavBar";
import TextInput from "../../component/commonComponents/inputs/TextInput";
import EmployeeController from "../../controller/EmployeeController";
import { setEmployeeBodyMeasurenment } from "../../reducer/employeereduxstore";
import { Div } from "../../style/common.style";
import {
  CalendarContainer,
  FormContainerEvent,
  InputText,
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  PopupNavBarContainer,
} from "../../style/form.style";
import { DatePickerCalendarProfile } from "../../style/profile.style";
import { CommonImages } from "../../utils/common.images";
import CustomInput from "./CustomInput";

export default function ChangeBodyMeasurenment(props) {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState(-1);
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [waist, setWaist] = useState();
  const [chest, setChest] = useState();
  const [shoeSize, setShoeSize] = useState();
  const [acrossShoeSize, setAcrossShoeSize] = useState();
  const [comment, setComments] = useState();
  const [measurennentDate, setMeasurenmentDate] = useState();
  const [shoulder, setShoulder] = useState();
  const employeeController = new EmployeeController();

  useEffect(() => {
    onPrefillData();
  }, [props.openPopup]);

  function onPrefillData() {
    setHeight(props.data.height);
    setWeight(props.data.weight);
    setWaist(props.data.waist);
    setChest(props.data.chest);
    setShoeSize(props.data.shoe_size);
    setShoulder(props.data.shoulder);
    setAcrossShoeSize(props.data.across_shoe_size);
    setComments(props.data.comments);
    setMeasurenmentDate(new DateObject(props.data.measurement_date));
  }

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  async function onSave() {
    const error = checkError();
    if (error) {
      setError(error);
      return;
    }
    const sendData = {
      user_id: props.employee._id,
      height: height,
      weight: weight,
      waist: waist,
      chest: chest,
      shoe_size: shoeSize,
      across_shoe_size: acrossShoeSize,
      shoulder: shoulder,
      measurement_date: moment(measurennentDate).format(),
      comments: comment,
    };

    setIsLoader(true);

    const resp = await employeeController.updateMeasurenment(sendData);
    if (resp) {
      const msg = (
        <div>
          <div>success</div>
          <div>employee details added sucessfully</div>
        </div>
      );
      dispatch(setEmployeeBodyMeasurenment(sendData));

      setIsLoader(false);

      handleAlert(msg, "success");

      props.onHandleClose();
      return;
    }
    const msg = (
      <div>
        <div>error</div>
        <div>please try again later</div>
      </div>
    );

    setTimeout(() => {}, 2000);
    setIsLoader(false);

    handleAlert(msg, "error");

    props.onHandleClose();
  }
  function checkError() {
    if (height === null || height === "") {
      return "height";
    }
    if (weight === null || weight === "") {
      return "weight";
    }
    if (waist === null || waist === "") {
      return "waist";
    }
    if (chest === null || chest === "") {
      return "chest";
    }
    if (shoeSize === null || shoeSize === "") {
      return "shoe_size";
    }
    if (acrossShoeSize === null || acrossShoeSize === "") {
      return "across_shoe_size";
    }
  }
  return (
    <React.Fragment>
      {isLoader ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>updating new physique details</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainer>
              <PopupNavBar
                onHandleClose={props.onHandleClose}
                componentType="form"
                title={"Update Body Measurements"}
              />
            </PopupNavBarContainer>
          </DialogTitle>
          <DialogContent dividers={true}>
            <FormContainerEvent className="px-4">
              <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                <Div className="col-span-4">
                  <CalendarContainer isvisible={1}>
                    <InputText>Measurement Date</InputText>
                    <DatePickerCalendarProfile
                      value={measurennentDate}
                      onChange={(e) => {
                        setMeasurenmentDate(e);
                      }}
                      format="MMM, D YYYY"
                      className="custom-calendar"
                      title={(date) =>
                        date.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })
                      }
                      maxDate={new Date()}
                      animations={[transition()]}
                      showOtherDays
                      render={<CustomInput value={measurennentDate} />}
                    />
                  </CalendarContainer>
                </Div>
              </Div>
              <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                <Div className="col-span-3">
                  <TextInput
                    error={error === "height"}
                    title={"Height(in Cms.)"}
                    placeholder={"Height(in In)"}
                    // icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={height}
                    onChange={(event) => setHeight(event.target.value)}
                    type="Number"
                  />
                </Div>
                <Div className="col-span-3">
                  <TextInput
                    error={error === "weight"}
                    title={"Weight(in Kg)"}
                    placeholder={"Weight(in Kg)"}
                    // icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={weight}
                    onChange={(event) => setWeight(event.target.value)}
                    type="Number"
                  />
                </Div>
              </Div>
              <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                <Div className="col-span-3">
                  <TextInput
                    error={error === "shoe_size"}
                    title={"Shoe Size(UK)"}
                    placeholder={"Shoe Size(UK)"}
                    // icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={shoeSize}
                    onChange={(event) => setShoeSize(event.target.value)}
                    type="Number"
                  />
                </Div>
                <Div className="col-span-3">
                  <TextInput
                    error={error === "across_shoe_size"}
                    title={"Across Shoe Size(UK)"}
                    placeholder={"Across Shoe Size(UK)"}
                    // icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={acrossShoeSize}
                    onChange={(event) => setAcrossShoeSize(event.target.value)}
                    type="Number"
                  />
                </Div>
              </Div>
              <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                <Div className="col-span-3">
                  <TextInput
                    error={error === "chest"}
                    title={"Chest"}
                    placeholder={"Chest"}
                    // icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={chest}
                    onChange={(event) => setChest(event.target.value)}
                    type="Number"
                  />
                </Div>
                <Div className="col-span-3">
                  <TextInput
                    error={error === 0}
                    title={"Shoulder"}
                    placeholder={"Shoulder"}
                    // icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={shoulder}
                    onChange={(event) => setShoulder(event.target.value)}
                    type="Number"
                  />
                </Div>
              </Div>
              <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                <Div className="col-span-2">
                  <TextInput
                    error={error === "waist"}
                    title={"Waist"}
                    placeholder={"Waist"}
                    // icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={waist}
                    onChange={(event) => setWaist(event.target.value)}
                    type="Number"
                  />
                </Div>
              </Div>

              <TextInput
                error={false}
                title={"Comments"}
                placeholder={"Comments"}
                // icon={<Icon icon="ooui:user-avatar-outline" />}
                value={comment}
                onChange={(event) => setComments(event.target.value)}
                multiline
              />
            </FormContainerEvent>
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => onSave()}
                buttonName={"update Measurement"}
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
