import React, { useState } from "react";
import {
  ButtonEditLeave,
  DurationAndStatus,
  EmployeeCardDivider,
  EmployeeLeaveDataContainer,
  EmployeeLeaveDataNavbar,
  EmployeeLeaveDataNavbarContainer,
  EmployeeLeaveDuaration,
  EmployeeLeaveFooter,
  EmployeeLeaveHalfDay,
  EmployeeLeaveInfoContainer,
  EmployeeLeaveNote,
  EmployeeLeaveNoteContainer,
  EmployeeLeaveNoteHeading,
  EmployeeLeaveTime,
  EmployeeLeaveTimeAndTypeContainer,
  EmployeeName,
  EmployeeNameContainer,
  EmployeeOptionsIconContainer,
  EmployeeTimeContainer,
  EmployeeTimeContainerTo,
  EmployeetimingHeader,
  IconNavbar,
  ImageContainerLeaveCard,
  LeaveStatusContainer,
  MenuOptions,
  ReadMoreOption,
} from "../../style/leave.style";
import LeaveDecisionButton from "../commonComponents/buttons/LeaveDecisionButton";
import LeaveStatus from "../commonComponents/buttons/LeaveStatus";

import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { CommonString } from "../../utils/common.string";
import {
  formatDisplayDate,
  getJustDate,
  getPendingLeaves,
  updateLeaveStatus,
} from "../../utils/utils";

import moment from "moment";
import { useSnackbar } from "notistack";
import ImageAvatar from "../../componentsv2/Images/ImageAvatar";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import LeaveController from "../../controller/LeaveController";
import {
  setEmployeeLeave,
  setLeaveHistoryData,
  updateActiveUserLeaveListData,
  updateActiveUserLeaveListDataProfile,
  updateEmployeeLeaveDatas,
  updateLeaveNumbersLeaveInfo,
  updatePendingEmployeeLeaveData,
  updatePendingEmployeeLeaveDatas,
  updateSelectedLeaveDate,
} from "../../reducer/leavereduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { Div, Span } from "../../style/common.style";
import {
  LeaveStatusMsg,
  LeaveStatusMsgContainer,
  LeaveStatusTitle,
} from "../../style/form.style";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import { userdata } from "../../utils/common.key";
import { ProfileBaseUrl } from "../../utils/constant";
import ApplyLeave from "../Forms/ApplyLeave";
import ConfirmationMenu from "../Popup/ConfirmationMenu";
import ModalPopup from "../Popup/ModalPopup";
import { FontSizes } from "../../style/theme/font";
import styled from "styled-components";

export default function LeaveInfoCard(props) {
  const [IsReadMore, setIsReadMore] = useState(false);
  const [isloading, setLoader] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(
    CommonString.pending.toUpperCase()
  );
  const [isOpen, setisOpen] = useState(false);
  const leaveList = useSelector((state) => state.leave.leave_employees_data);
  const leave = useSelector((state) => state.leave);
  const employee = useSelector((state) => state.employee);
  const leaveController = new LeaveController();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const activeEmployeeData = props.data;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const ToggleReadmore = (event, index) => {
    event.stopPropagation();
    setIsReadMore(!IsReadMore);
  };

  function getDate(dateOriginalFormat) {
    if (!dateOriginalFormat) return "";
    if (dateOriginalFormat.length === 10) {
      return formatDisplayDate(dateOriginalFormat, "DD-MM-YYYY").format(
        "Do MMM 'YY"
      );
    }
    return formatDisplayDate(dateOriginalFormat).format("Do MMM 'YY");
  }
  function getReason() {
    const textLen = IsReadMore ? Infinity : 87;
    return props.data.leave.reason.substr(0, textLen);
  }
  function onClickFooterButton(event, status) {
    if (event) {
      event.stopPropagation();
    }
    setselectedStatus(status);
    setisOpen(true);
    handleCloseMenu();
  }
  function getButtons() {
    // if (!ShowButton) return;
    if (!employee.employee_acess.leave_details) return "";
    // if (moment(props.data.leave.from_date).diff(moment()) < 0) return "";
    if (props.data.leave.status === CommonString.pending.toUpperCase()) {
      return (
        <Div className="grid grid-cols-2 gap-x-2 w-full">
          <Div className="grid col-span-1">
            <ButtonCustomV2
              onClick={(event) =>
                onClickFooterButton(event, CommonString.rejected.toUpperCase())
              }
              variant="outlined"
              customBGColor={CommonColor.transparent}
              hoverBG={CommonColor.red + "10"}
              customTextColor={CommonColor.red}
              customOutlineColor={CommonColor.red}
              startIcon={
                <Icon icon="akar-icons:cross" height={FontSizes.SmallText} />
              }
              buttonText="Decline"
            />
          </Div>
          {/* <EmployeeCardDivider
            orientation="vertical"
            flexItem
            isdisplay={true}
            ml="1%"
            mr="1%"
          /> */}
          <Div className="grid col-span-1">
            <ButtonCustomV2
              onClick={(event) =>
                onClickFooterButton(event, CommonString.approved.toUpperCase())
              }
              variant="outlined"
              customBGColor={CommonColor.transparent}
              hoverBG={CommonColor.green + "10"}
              customTextColor={CommonColor.green}
              customOutlineColor={CommonColor.green}
              startIcon={
                <Icon icon="mdi:tick" fontSize={FontSizes.SmallText} />
              }
              buttonText="Approve "
            />
          </Div>
        </Div>
      );
    }
    if (moment(props.data.leave.from_date).diff(moment()) >= 0) {
      return (
        <LeaveDecisionButton
          onClick={(event) =>
            onClickFooterButton(event, CommonString.edit.toUpperCase())
          }
        >
          edit status
        </LeaveDecisionButton>
      );
    }
  }

  function onEditLeave(variable, status) {
    dispatch(updateSelectedLeaveDate(props.data));
    props.setselectedStatus(CommonString.edit_leave.toUpperCase());
    setisOpen(true);
    setselectedStatus(status);
    handleCloseMenu();
  }
  async function onDeleteLeave() {
    setLoader(true);
    const data = {
      id: activeEmployeeData.leave.id,
    };

    let res = await leaveController.deleteLeave(data);
    const successMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>leave deleted sucessfully</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );
    const errorMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>error</LeaveStatusTitle>
        <LeaveStatusMsg>please try after a while</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );
    if (res) {
      const employeeId = getLocalStorage(userdata).user._id;
      const newRespData = await leaveController.getEmployeeLeaveData(
        employeeId
      );
      dispatch(updateEmployeeLeaveDatas(newRespData));
      const pendingNewRespData = getPendingLeaves(newRespData);
      dispatch(updatePendingEmployeeLeaveDatas(pendingNewRespData));
      const employeesLeave = await leaveController.getLeaveInfoEmployees();
      if (employeesLeave !== "error") {
        dispatch(setEmployeeLeave(employeesLeave));
      }
      const leaveInfos = await leaveController.getEmployeeLeaveData(
        props.data.user._id
      );
      dispatch(updateActiveUserLeaveListData(leaveInfos));
      setTimeout(() => {
        setLoader(false);
        setisOpen(false);
        handleAlert(successMsg, "success");
      }, 500);
    } else {
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        setLoader(false);
        setisOpen(false);
      }, 500);
    }
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  async function onChangeStatus(status) {
    setLoader(true);
    const sendData = {
      id: activeEmployeeData.leave.id,
      approved_by_user_id: activeEmployeeData.user._id,
      status: status,
    };
    const resp = await leaveController.upadateLeaveStatus(sendData);
    if (resp === true) {
      const result = leaveList.filter(
        (leave) => leave.leave.id !== activeEmployeeData.leave.id
      );

      const data = await updateLeaveStatus(leaveController);
      const leaveInfoEmployee = await leaveController.getEmployeeLeaveData(
        activeEmployeeData.user._id
      );

      const pendingNewRespData = getPendingLeaves(leaveInfoEmployee);

      const total_leave = parseInt(activeEmployeeData.leave.total_leave);
      const leaveCredits = {
        total_leave: leave.total_leave_number_info,
        used_leave:
          status === CommonString.approved.toUpperCase()
            ? leave.used_leave_number_info + total_leave
            : leave.used_leave_number_info - total_leave,
      };
      setisOpen(false);

      dispatch(setEmployeeLeave(result));

      dispatch(updateActiveUserLeaveListData(leaveInfoEmployee));
      dispatch(updateActiveUserLeaveListDataProfile(leaveInfoEmployee));
      dispatch(updatePendingEmployeeLeaveData(data.pendingEmployeesLeave));
      dispatch(setLeaveHistoryData(data.leaveHistory));
      dispatch(updateEmployeeLeaveDatas(leaveInfoEmployee));
      dispatch(updatePendingEmployeeLeaveDatas(pendingNewRespData));
      dispatch(updateLeaveNumbersLeaveInfo(leaveCredits));
    }
    setLoader(false);
  }

  const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    height: 2.3rem;
    background-color: #ff66001a;
    border-radius: 10px;
  `;

  const IconStyled = styled(Icon)`
    font-size: 30px;
    margin-right: 10px;
  `;

  const Number = styled.span`
    font-size: 12px;
    color: #ff6600;
  `;

  function getLocalChildren() {
    if (selectedStatus === CommonString.approved.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationApproved)}
          yesButtonName="Approve"
          noButtuonName="Cancel"
          onClickYesButton={() =>
            onChangeStatus(CommonString.approved.toUpperCase())
          }
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }

    if (selectedStatus === CommonString.rejected.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationReject)}
          yesButtonName="Reject"
          onClickYesButton={() =>
            onChangeStatus(CommonString.rejected.toUpperCase())
          }
          noButtuonName="Cancel"
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (selectedStatus === CommonString.edit.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationReject)}
          yesButtonName="Approve"
          onClickYesButton={() =>
            onChangeStatus(CommonString.approved.toUpperCase())
          }
          onClicknoButton={() =>
            onChangeStatus(CommonString.rejected.toUpperCase())
          }
          noButtuonName="Reject"
          isCancelButton={true}
          onClickCancelButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }

    if (selectedStatus === CommonString.delete.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationdelete)}
          yesButtonName="Delete"
          onClickYesButton={() => onDeleteLeave()}
          noButtuonName="Cancel"
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (selectedStatus === CommonString.edit_leave.toUpperCase()) {
      return (
        <ApplyLeave
          IsPopupOpen={isOpen}
          isEdit={true}
          onHandleClose={() => setisOpen(false)}
        />
      );
    }
  }
  function getTitle(title) {
    if (props.data.leave.leave_type !== "LATE") {
      return (
        title +
        " leave of " +
        props.data.user.name +
        " for " +
        props.data.leave.total_leave +
        " day(s)?"
      );
    }
    return title + " leave of " + props.data.user.name + " for late coming?";
  }
  function IsDateComing() {
    return props.data.leave.total_leave > 1;
  }
  function getEmployeeHeader() {
    if (props.data.leave.leave_type !== "LATE") {
      return props.data.leave.total_leave + " Day(s) Leave";
    }
    return "Late Coming";
  }
  function getProfileImageUrl() {
    if (props.data.user.profile_image_url !== null) {
      return ProfileBaseUrl + props.data.user.profile_image_url;
    }
    return "";
  }

  return (
    <React.Fragment>
      <ModalPopup open={isOpen} popupSize="xs" issmallScreen={true}>
        {getLocalChildren()}
      </ModalPopup>
      <React.Fragment>
        <EmployeeLeaveInfoContainer
          isActive={props.isActive}
          onClick={() => props.onClick(props.data, props.index)}
          noclick={props.noClick}
          length={props.length}
          index={props.index}
          onContextMenu={
            props.data.leave.status === CommonString.pending && props.isEdit
              ? (event) => handleClick(event)
              : () => {}
          }
        >
          <EmployeeLeaveDataNavbarContainer
            isdisplay={employee.employee_acess.leave_details ? 1 : 0}
          >
            <EmployeeLeaveDataNavbar>
              <EmployeeNameContainer>
                <ImageContainerLeaveCard>
                  <ImageAvatar
                    src={getProfileImageUrl()}
                    alt=""
                    defaultSrc={CommonImages.NoUser}
                  />
                </ImageContainerLeaveCard>
                <EmployeeName
                  onClick={() => props.onNameClick && props.onNameClick()}
                >
                  {props.data.user.name}
                </EmployeeName>
                <IconNavbar
                  icon="mi:options-horizontal"
                  onClick={handleClick}
                />
                <MenuOptions
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <ButtonEditLeave
                    variant="text"
                    onClick={() =>
                      onClickFooterButton(
                        undefined,
                        CommonString.delete.toUpperCase()
                      )
                    }
                    startIcon={
                      <Icon icon="material-symbols:delete-forever-rounded" />
                    }
                  >
                    delete
                  </ButtonEditLeave>
                  <ButtonEditLeave
                    variant="text"
                    onClick={() =>
                      onEditLeave(
                        undefined,
                        CommonString.edit_leave.toUpperCase()
                      )
                    }
                    startIcon={<Icon icon="material-symbols:edit" />}
                  >
                    edit
                  </ButtonEditLeave>
                </MenuOptions>
              </EmployeeNameContainer>
              <LeaveStatus
                leaveStatus={props.data.leave.status}
                display={employee.employee_acess.leave_details}
                leaveStatusText={props.data.leave.status}
                screen={props.screen}
              />
            </EmployeeLeaveDataNavbar>
          </EmployeeLeaveDataNavbarContainer>
          <EmployeeCardDivider
            isdisplay={employee.employee_acess.leave_details ? 1 : 0}
            mt="1%"
            mb="1%"
          />
          <EmployeeLeaveDataContainer>
            <DurationAndStatus
              display={!employee.employee_acess.leave_details ? 1 : 0}
            >
              <EmployeeLeaveDuaration>
                {getEmployeeHeader()}

                <EmployeeOptionsIconContainer
                  isvisible={
                    props.data.leave.status === CommonString.pending &&
                    props.isEdit &&
                    !employee.employee_acess.leave_details
                      ? 1
                      : 0
                  }
                >
                  <IconNavbar
                    icon="mi:options-horizontal"
                    onClick={handleClick}
                  />
                  <MenuOptions
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <ButtonEditLeave
                      disableRipple
                      variant="outlined"
                      onClick={() =>
                        onClickFooterButton(
                          undefined,
                          CommonString.delete.toUpperCase()
                        )
                      }
                      startIcon={
                        <Icon icon="material-symbols:delete-forever-rounded" />
                      }
                    >
                      delete
                    </ButtonEditLeave>
                    <ButtonEditLeave
                      disableRipple
                      variant="outlined"
                      onClick={() =>
                        onEditLeave(
                          undefined,
                          CommonString.edit_leave.toUpperCase()
                        )
                      }
                      startIcon={<Icon icon="material-symbols:edit" />}
                    >
                      edit
                    </ButtonEditLeave>
                  </MenuOptions>
                </EmployeeOptionsIconContainer>
              </EmployeeLeaveDuaration>

              <LeaveStatusContainer
                display={!employee.employee_acess.leave_details ? 1 : 0}
              >
                <LeaveStatus
                  leaveStatus={props.data.leave.status}
                  component={props.type}
                  display={!employee.employee_acess.leave_details}
                  screen={props.screen}
                  leaveComponent={props.leaveComponent}
                />
              </LeaveStatusContainer>
            </DurationAndStatus>

            <EmployeeCardDivider
              isdisplay={!employee.employee_acess.leave_details ? 1 : 0}
              mt="1%"
            />

            <EmployeeTimeContainer
              mt={props.leaveComponent === CommonString.leaveApplication}
            >
              <EmployeetimingHeader>
                {IsDateComing() ? "From : " : "date : "}
              </EmployeetimingHeader>

              <EmployeeLeaveTime>
                &nbsp;{getJustDate(props.data.leave.from_date)}
              </EmployeeLeaveTime>
              {props.data.leave.total_leave === 0.5 ? (
                <EmployeeLeaveHalfDay>(Half day)</EmployeeLeaveHalfDay>
              ) : (
                ""
              )}
            </EmployeeTimeContainer>
            <EmployeeTimeContainerTo
              todate={props.data.leave.to_date}
              isvisible={props.data.leave.total_leave > 1 ? 1 : 0}
            >
              <EmployeetimingHeader>To&nbsp;:&nbsp;</EmployeetimingHeader>
              <EmployeeLeaveTimeAndTypeContainer>
                <EmployeeLeaveTime>
                  {props.data.leave
                    ? getJustDate(props.data.leave.to_date)
                    : ""}
                </EmployeeLeaveTime>
                {props.data.leave.total_leave === 0.5 ? (
                  <EmployeeLeaveHalfDay>(Half day)</EmployeeLeaveHalfDay>
                ) : (
                  ""
                )}
              </EmployeeLeaveTimeAndTypeContainer>
            </EmployeeTimeContainerTo>

            {props.data.leave.reason !== "" &&
              props.data.leave.reason &&
              props.data.leave.reason !== null && (
                <EmployeeLeaveNoteContainer>
                  <EmployeeLeaveNoteHeading>
                    Note&nbsp;:&nbsp;
                  </EmployeeLeaveNoteHeading>
                  <EmployeeLeaveNote
                    ActiveIndex={IsReadMore}
                    index={props.index}
                  >
                    {getReason()}
                  </EmployeeLeaveNote>

                  <ReadMoreOption
                    onClick={(event) => ToggleReadmore(event, props.index)}
                    isdisplay={props.data.leave.reason.length > 100 ? 1 : 0}
                  >
                    {IsReadMore ? " read less" : "...read more"}
                  </ReadMoreOption>
                </EmployeeLeaveNoteContainer>
              )}

            {/* <EmployeeCardDivider
              isdisplay={
                employee.employee_acess.leave_details &&
                moment(props.data.leave.from_date).diff(moment()) >= 0
              }
            /> */}
            <EmployeeLeaveFooter
              leavestatus={props.data.leave ? props.data.leave.status : ""}
              isdisplay={1}
            >
              {getButtons()}
            </EmployeeLeaveFooter>
          </EmployeeLeaveDataContainer>
        </EmployeeLeaveInfoContainer>
      </React.Fragment>
    </React.Fragment>
  );
}

/*

*/
