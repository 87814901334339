// import React from "react";
// import LineChart from "react-linechart";
// import "../../../node_modules/react-linechart/dist/styles.css";

// export default function SynergyLeagueScoreCard(props) {
//   const data = [
//     {
//       color: "steelblue",
//       points: [
//         { x: 1, y: 2 },
//         { x: 3, y: 5 },
//         { x: 7, y: -3 },
//       ],
//     },
//     {
//       color: "red",
//       points: [
//         { x: 3, y: 9 },
//         { x: 5, y: 7 },
//         { x: 8, y: -4 },
//       ],
//     },
//   ];
//   return (
//     <div onClick={() => props.onScreenClose()}>
//       <div>
//         <div className="App">
//           <h1>My First LineChart</h1>
//           <LineChart width={600} height={400} data={data} />
//         </div>
//       </div>
//     </div>
//   );
// }

import { AppBar, Container, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexContainer } from "../../style/asset.style";
import { AppBarStyle } from "../../style/drawer.style";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import { CommonString } from "../../utils/common.string";
import PageRefreshLoader from "../Loader/PageRefreshLoader";
import ShowLoader from "../Loader/ShowLoader";
import ModalPopup from "../Popup/ModalPopup";
import NavBar from "../drawer/NavBar";
import { useWindowSize } from "../commonComponents/hooks/getWindowDimension";
import { ProfileContainer } from "../../style/profile.style";
import {
  LeftScoreCardContainer,
  RightBlueCardContainer,
} from "../../style/synergy.league.style";
import LeftScoreCard from "./components/LeftScoreCard";

export default function SynergyLeagueScoreCard(props) {
  const dashboard = useSelector((state) => state.dashboard);
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    if (!props.loadedPages.includes(CommonString.synergyLeagueScreenKey)) {
      getData();
    }
    if (props.loadedPages.includes(CommonString.synergyLeagueScreenKey)) {
      setPageLoader(false);
    }
  }, []);

  async function getData() {
    setPageLoader(true);
    await props.getSynergyLeagueData();
    setPageLoader(false);
  }

  function getModalChildren() {
    // return (
    //   <AddSynergyLeague onHandleClose={() => dispatch(setIsModalOpen(false))} />
    // );
  }
  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Synergy League"}
            // NavBarIcon={{
            //   icon: "solar:cup-star-bold",
            //   name: "Add Synergy League",
            // }}
          />
        </Toolbar>
      </AppBar>
      {pageLoader ? (
        <Container
          sx={{
            backgroundColor: CommonColor.theme_background_color,
            height: "90vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <PageRefreshLoader />
        </Container>
      ) : (
        <React.Fragment>
          <ProfileContainer ismain={true}>
            <LeftScoreCardContainer>
              <LeftScoreCard />
            </LeftScoreCardContainer>
            <RightBlueCardContainer>
              {/* <RightBlueCard /> */}
            </RightBlueCardContainer>
          </ProfileContainer>
          {/* <FlexContainer jc="center" verticalAlign="center" height="89%">
            <ShowLoader
              loaderData={CommonImages.UnderConstruction}
              height="80vh"
              width={windowWidth < 600 ? "100%" : "60%"}
            />
          </FlexContainer> */}
        </React.Fragment>
      )}
      <ModalPopup open={dashboard.isModalOpen}>{getModalChildren()}</ModalPopup>
    </React.Fragment>
  );
}
