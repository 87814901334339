import React, { useEffect, useRef, useState } from "react";
import transition from "react-element-popper/animations/transition";
import { useSelector } from "react-redux";
import { DatePickerCalendar } from "../../../style/common.style";
import {
  CalendarTitle,
  DateCalendarContainer,
  HalfDayOption,
  HalfDayOptionContainer,
  MuiCalendarCheckbox,
  MultiDateCalendarContainer,
  MultiDateChild,
  TextContainer,
  VerticalScrollableAreaInPopup,
} from "../../../style/form.style";
import TextInput from "../../commonComponents/inputs/TextInput";

import { DateObject } from "react-multi-date-picker";
import CommonColor from "../../../utils/common.colors";

export default function AppealLeave(props) {
  const [value, setValue] = useState();
  const [startDay, setStartDay] = useState(new DateObject());
  // const [endDay, setEndDay] = useState(new DateObject(getNextDay()));
  const [isStartDay, setisStartDay] = useState(false);
  const [isEndDay, setisEndDay] = useState(false);
  const [reason, setReason] = useState();
  const [isErrorExist, setIsErrorExist] = useState(null);
  const calendarRef = useRef();
  const selectedLeave = useSelector((state) => state.leave.selected_leave);
  const appeal = useSelector((state) => state.appeal);
  const employee = useSelector((state) => state.employee);

  function getReason() {
    const data = appeal.appeal_employees_data.filter(
      (data) => data.attendance_date === props.date.attendance_date
    );
    props.setAppealData(data[0]);
    setReason(data[0].reason);
  }
  useEffect(() => {
    props.isEdit === true && getReason();
  }, []);

  // useEffect(() => {
  //   prefillData();
  // }, [dashboard.isModalOpen]);

  useEffect(() => {
    submitData();
  }, [value, startDay, isStartDay, isEndDay, reason]);

  // useEffect(() => {
  //    setEndDay(getNextDay());
  // }, [startDay]);

  //   useEffect(()=>{
  //     setValue(props.date.attendance_date)
  //   } , [])

  //   setValue(props.date ? props.date.attendance_date : null );

  // function prefillData() {
  //   if (props.isEdit) {
  //     if (selectedLeave.leave.leave_type === "LATE") return;

  //     setValue(new DateObject(selectedLeave.leave.from_date));
  //     setStartDay(new DateObject(selectedLeave.leave.from_date));
  //     setEndDay(new DateObject(selectedLeave.leave.to_date));
  //     setReason(selectedLeave.leave.reason);
  //   }
  // }

  function submitData() {
    // let start_date = !startDay ? Date.now() : startDay.toDate();
    // let end_date = !endDay ? Date.now() : endDay.toDate();
    // if (!props.isMultiForm) {
    //   start_date = value?.toDate()?.toString();
    //   end_date = null;
    // }

    let data = {
      date: value,
      reason: reason,
    };
    props.onSubmit(data);
  }

  // function handleChange(event) {
  //   if (event.target.name === "start") {
  //     setisStartDay(event.target.checked);
  //   } else {
  //     setisEndDay(event.target.checked);
  //   }
  // }

  // function getText(isActive, component) {
  //   if (component === "start") {
  //     if (isActive) {
  //       return (
  //         <>
  //           Applied half day on <br />
  //           {startDay ? `${startDay}` : ""}
  //         </>
  //       );
  //     } else {
  //       return (
  //         <>
  //           Apply half day on <br />
  //           {startDay ? `${startDay}` : ""}
  //         </>
  //       );
  //     }
  //   } else {
  //     if (isActive) {
  //       return (
  //         <>
  //           Applied half day on <br />
  //           {endDay ? `${endDay}` : ""}
  //         </>
  //       );
  //     } else {
  //       return (
  //         <>
  //           Apply half day on <br />
  //           {endDay ? `${endDay}` : ""}
  //         </>
  //       );
  //     }
  //   }
  // }

  // function getNextDay() {
  //   return new DateObject(new DateObject(startDay).add(1, "days"));

  // }

  return (
    <React.Fragment>
      <VerticalScrollableAreaInPopup
        height="100"
        unit="%"
        isfullheight={!props.isEdit}
        isVisible={props.isExist}
      >
        <React.Fragment>
          <MultiDateCalendarContainer isvisible={props.isMultiForm ? 1 : 0}>
            <MultiDateChild>
              <CalendarTitle>From</CalendarTitle>
              <DatePickerCalendar
                mapDays={({ date }) => {
                  let isWeekend = [0, 6].includes(date.weekDay.index);

                  if (isWeekend)
                    return {
                      disabled: true,
                      style: { color: "#ccc" },
                      onClick: () => {},
                    };
                }}
                value={startDay}
                onChange={(e) => {
                  setStartDay(e);
                }}
                minDate={!employee.employee_acess.leave_details && Date.now()}
                multiple={false}
                iserror={isErrorExist === 0}
                className="custom-calendar"
                format="MMM, DD, YYYY"
                animations={[transition()]}
                ref={calendarRef}
                showOtherDays
              ></DatePickerCalendar>
            </MultiDateChild>
          </MultiDateCalendarContainer>

          <HalfDayOptionContainer isvisible={props.isMultiForm ? 1 : 0}>
            <HalfDayOption
              checked={isStartDay}
              fontColor={CommonColor.primary_color}
              fontWeight={isStartDay ? "600" : "500"}
              control={
                <MuiCalendarCheckbox
                  size="small"
                  checked={isStartDay}
                  // onChange={(e) => handleChange(e)}
                  name="start"
                />
              }
              // label={getText(isStartDay, "start")}
            />
            <HalfDayOption
              checked={isEndDay}
              isMultiForm={props.isMultiForm}
              fontColor={CommonColor.primary_color}
              fontWeight={isEndDay ? "600" : "500"}
              control={
                <MuiCalendarCheckbox
                  size="small"
                  checked={isEndDay}
                  // onChange={(e) => handleChange(e)}
                  name="end"
                />
              }
              // label={getText(isEndDay, "end")}
            />
          </HalfDayOptionContainer>
        </React.Fragment>

        <TextContainer>
          <TextInput
            title={"Date"}
            value={props.date ? props.date.attendance_date : ""}
          />
        </TextContainer>
        <TextContainer>
          <TextInput
            title={"Reason"}
            placeholder={"Reason"}
            error={props.error === "reason"}
            helperText={props.error === "reason" && "Please enter reason"}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            multiline
          />
        </TextContainer>
      </VerticalScrollableAreaInPopup>
    </React.Fragment>
  );
}
