import { Icon } from "@iconify/react";
import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import React from "react";
import { FlexContainer } from "../../../style/asset.style";
import {
  CardTitleContainer,
  RotationAnimation,
  Titleheading,
  TypoGraphy,
} from "../../../style/common.style";
import {
  CardButtonIconContainer,
  CardTitleButtonsContainer,
  CardTitleTextContainer,
  ImageGroupContainer,
  ImageGroupWrapper,
  MuiAvatarContainer,
} from "../../../style/dashboard.style";
import { FontSizes } from "../../../style/theme/font";
import CommonColor from "../../../utils/common.colors";
import { NoProfilePhoto, ProfileBaseUrl } from "../../../utils/constant";
import CardTitleButton from "../buttons/CardTitleButton";
import { useWindowSize } from "../hooks/getWindowDimension";

export default function CardTitle(props) {
  const windowWidth = useWindowSize().width;
  function getHorizontalAlignment() {
    if (props.title === "") {
      return "center";
    }
    return "space-between";
  }
  function getImageUrl(employee) {
    if (employee.profile_image_url === null) return NoProfilePhoto;
    if (props.isTypingShow)
      return ProfileBaseUrl + employee.user.profile_image_url;
    if (props.title === "Daily Logs")
      return ProfileBaseUrl + employee.recruiter.profile_image_url;
    return ProfileBaseUrl + employee.profile_image_url;
  }
  return (
    <CardTitleContainer
      horizontalAlign={getHorizontalAlignment()}
      onClick={() => props.contentToggler()}
    >
      <CardTitleTextContainer>
        <Titleheading>{props.title}</Titleheading>
        {/* <TitleNotificationNum>{props.notificationNum}</TitleNotificationNum> */}
        <ImageGroupWrapper>
          <ImageGroupContainer>
            {props.data && !props.openContent && windowWidth > 600 ? (
              <MuiAvatarContainer zindex={props.data.length}>
                <AvatarGroup total={props.data.length}>
                  {props.data.map((element, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={
                          <FlexContainer>
                            <FlexContainer flexDirection="column">
                              <TypoGraphy
                                color={CommonColor.white}
                                fontSize={FontSizes.Para}
                                fontWeight={"600"}
                              >
                                {props.title === "Speedy Prexelites" ||
                                props.title === "Daily Logs"
                                  ? element.recruiter
                                    ? element.recruiter.name
                                    : element.user.name
                                  : element.name}
                              </TypoGraphy>
                            </FlexContainer>
                          </FlexContainer>
                        }
                      >
                        <Avatar
                          alt={element.name}
                          src={getImageUrl(element)}
                          key={index}
                          sx={{
                            zIndex: index,
                            "& img": { borderRadius: "50%" },
                          }}
                        />
                      </Tooltip>
                    );
                  })}
                </AvatarGroup>
              </MuiAvatarContainer>
            ) : (
              ""
            )}
          </ImageGroupContainer>
        </ImageGroupWrapper>
      </CardTitleTextContainer>

      {props.buttonChoice ? (
        <CardTitleButtonsContainer>
          <CardTitleButton
            buttonChoice={props.buttonChoice}
            activeIndexUpdater={props.activeIndexUpdater}
            cardLocation={props.cardLocation}
            onAddClick={props.onAddClick}
            viewMoreText={props.viewMoreText}
          />
          {!props.contentTogglerNotVisible ? (
            <CardButtonIconContainer>
              <RotationAnimation rotationangle={props.openContent ? 0 : 180}>
                <Icon
                  icon="ic:baseline-keyboard-arrow-up"
                  color={CommonColor.secondary_color}
                  // fontSize={FontSizes.Title}
                />
              </RotationAnimation>
            </CardButtonIconContainer>
          ) : (
            ""
          )}
        </CardTitleButtonsContainer>
      ) : (
        ""
      )}
    </CardTitleContainer>
  );
}
