import { createSlice } from "@reduxjs/toolkit";

export const synergyleaguestoreSlice = createSlice({
  name: "synergyleaguestore",
  initialState: {
    teams_data: [],
    sports_data: [],
    sports_names_list: [],
    upcoming_matches: [],
    recent_matches: [],
    points_table: [],
    sub_teams_all: [],
    sub_teams_name: [],
  },
  reducers: {
    setTeams: (state, action) => {
      state.teams_data = action.payload;
    },
    setSubTeamsAll: (state, action) => {
      state.sub_teams_all = action.payload;
    },
    setSubTeamsNames: (state, action) => {
      state.sub_teams_name = action.payload;
    },
    setSports: (state, action) => {
      state.sports_data = action.payload;
      state.sports_names_list = action.payload.map((sport) => sport.name);
    },
    setUpcomingSports: (state, action) => {
      state.upcoming_matches = action.payload;
    },
    setRecentMatches: (state, action) => {
      state.recent_matches = action.payload;
    },
    setPointsTable: (state, action) => {
      state.points_table = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTeams,
  setSports,
  setUpcomingSports,
  setRecentMatches,
  setPointsTable,
  setSubTeamsAll,
  setSubTeamsNames,
} = synergyleaguestoreSlice.actions;

export default synergyleaguestoreSlice.reducer;
