import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import CommonColor from "../utils/common.colors";
import { DeviceSizes, TextButton } from "./common.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  @media ${DeviceSizes.belowtablet} {
    justify-content: ${(props) =>
      props.$activeIndex === 4 ? "space-between" : ""};
  }
`;
const SearchButton = styled(TextButton)``;

const UserDetailContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
const NavBarButton = {
  iconName: "akar-icons:search",
  width: "25",
  height: "30",
};

const AccountUserDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 50px;
  height: 100%;
  @media ${DeviceSizes.mobile} {
    margin-left: 5px;
    margin-right: 4%;
  }
`;

const UserImageContainer = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  &:active {
    height: 35px;
    width: 35px;
  }
  @media ${DeviceSizes.mobile} {
    margin-left: 5px;
    /* margin-right: 30px; */
  }
`;

const UserImage = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  padding: 3px;
  border: 2px solid ${CommonColor.button_gray};
  margin-right: 4%;
  transition: all 0.5s ease-in-out;
  &:hover {
    border-color: ${CommonColor.primary_color};
  }
`;

const NotificationIconcontainer = styled.div`
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  @media ${DeviceSizes.mobile} {
    margin-right: 10px;
    margin-left: 5px;
  }
`;
const SearchIconContainer = styled.div`
  box-sizing: border-box;
  padding-top: 3px;

  & svg {
    color: ${Theme.primary_color.light_text};
    font-size: 18px;
  }
`;
const SearchCotainer = () => {
  return {
    p: "0px 5px",
    display: "flex",
    alignItems: "center",
    width: 300,
    borderRadius: "10px",
    paddingLeft: "15px",
    height: "100%",
    "& ::placeholder": {
      // fontFamily: FontFamilies.Mulish,
      fontSize: "1em",
    },
    "& input": {
      fontSize: "1em",
      // fontFamily: FontFamilies.Mulish,
    },
  };
};
const InputBaseField = () => {
  return { ml: 1, flex: 1 };
};
const ProfileHeaderContainer = styled.div`
  text-transform: Capitalize;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: ${Theme.primary_color.primary_color};
  margin-left: 2%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  & div:first-child {
    margin-left: 0;
  }
  @media ${DeviceSizes.belowtablet} {
    width: 80%;
    margin-left: 2%;
  }
`;
const NotificationDetail = styled.div`
  position: absolute;
  left: 70%;
  top: 50%;
  max-height: ${(props) => (props.isvisible ? "30vh" : "0vh")};
  transform: ${(props) => (props.isvisible ? "scaleZ(1)" : "scaleZ(0)")};
  white-space: nowrap;
  transform-origin: center top;
  width: max-content;
  background-color: ${Theme.primary_color.white};
  padding: 2%;
  border-radius: 10px;
  overflow: hidden;
  overflow-y: scroll;
  transition: max-height 0.5s ease-in-out, transform 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;
  overflow-y: scroll;
  -webkit-box-shadow: 10px 10px 79px -36px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 79px -36px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 79px -36px rgba(0, 0, 0, 0.75);
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(79, 79, 79);
    border-radius: 2px;
    display: none;
  }
  @media ${DeviceSizes.belowtablet} {
    left: 59%;
  }
  @media ${DeviceSizes.mobile} {
    left: 35%;
  }
`;
const Notification = styled.div`
  color: ${Theme.primary_color.dark};
  font-family: "Mulish";

  font-weight: 300;
  font-size: ${FontSizes.SubPara};
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
`;
const EmployeeDetails = styled(NotificationDetail)`
  width: max-content;
  font-family: "Mulish";
  font-weight: 300;
  font-size: ${FontSizes.SmallText};
  margin-bottom: 5px;
  @media ${DeviceSizes.mobile} {
    left: 77%;
  }
`;

const PopupNavBarContainer = styled.div`
  width: 100%;
  @media ${DeviceSizes.mobile} {
    padding-left: 1%;
  }
`;
const NotificationIcon = styled(Icon)`
  align-self: center;
  font-size: 30px;
  margin-right: 5%;
`;
const NotificationTextContainer = styled.div``;
const NotificationTitle = styled.div`
  font-size: ${FontSizes.Para};
`;
const NotificationSubTitle = styled.div``;
const FormContainer = styled.div``;
const NavbarIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Theme.primary_color.primary_color};
  background-color: ${Theme.primary_color.transparent};
  transition: background-color 1s ease-in-out;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.6em;
  &:hover {
    background-color: ${Theme.primary_color.primary_color_opacity_05};
  }
`;
const NavBarTextContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const NavBarIconContainer = styled.div`
  height: 26px;
  width: 26px;
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

const IconNameHelper = styled(Tooltip)`
  && {
    text-transform: capitalize;
  }
`;
const ProfileNavBarButtonContainer = styled.div`
  width: 10%;
  @media ${DeviceSizes.belowtablet} {
    width: 23%;
  }
  @media ${DeviceSizes.mobile} {
    width: 36%;
  }
`;

export {
  ProfileNavBarButtonContainer,
  IconNameHelper,
  NavBarTextContainer,
  NavBarIconContainer,
  NavbarContainer,
  SearchButton,
  UserDetailContainer,
  NavBarButton,
  AccountUserDetail,
  UserImageContainer,
  UserImage,
  NotificationIconcontainer,
  SearchCotainer,
  SearchIconContainer,
  InputBaseField,
  ProfileHeaderContainer,
  NotificationDetail,
  Notification,
  EmployeeDetails,
  PopupNavBarContainer,
  NotificationIcon,
  NotificationTextContainer,
  NotificationTitle,
  NotificationSubTitle,
  FormContainer,
  NavbarIcon,
};

//${"2px solid "+Theme.primary_color.drawer_divider}
