import React, { useEffect, useRef, useState } from "react";

import {
  CalendarTitle,
  DateCalendarContainer,
  LateLeaveContainer,
  TextContainer,
} from "../../../style/form.style";

import transition from "react-element-popper/animations/transition";
import { DateObject } from "react-multi-date-picker";
import { useSelector } from "react-redux";
import { DatePickerCalendar } from "../../../style/common.style";
import TextInput from "../../commonComponents/inputs/TextInput";

export default function LateLeave(props) {
  const calendarRef = useRef();
  const selectedLeave = useSelector((state) => state.leave.selected_leave);
  const [value, setValue] = useState(new DateObject());
  const [reason, setReason] = useState("");
  const employee = useSelector((state) => state.employee);
  useEffect(() => {
    onPrefillData();
  }, []);

  function onPrefillData() {
    if (!props.isEdit) return;
    if (selectedLeave.leave.leave_type !== "LATE") return;

    setReason(selectedLeave.leave.reason);
    setValue(new DateObject(selectedLeave.leave.from_date));
  }

  useEffect(() => {
    submitData();
  }, [value, reason]);

  function submitData() {
    let data = {
      from_date: value,
      to_date: null,
      half_day_1: null,
      half_day_2: null,
      reason: reason,
    };
    props.onSubmit(data);
  }

  return (
    <React.Fragment>
      <LateLeaveContainer isvisible={props.isVisible ? 1 : 0}>
        <DateCalendarContainer>
          <CalendarTitle>Date</CalendarTitle>
          <DatePickerCalendar
            mapDays={({ date }) => {
              let isWeekend = [0, 6].includes(date.weekDay.index);

              if (isWeekend)
                return {
                  disabled: true,
                  style: { color: "#ccc" },
                  onClick: () => {},
                };
            }}
            value={value}
            onChange={(e) => {
              setValue(e);
            }}
            format="YYYY/MM/DD"
            className="custom-calendar"
            animations={[transition()]}
            ref={calendarRef}
            calendarPosition="right-center"
            minDate={!employee.employee_acess.leave_details && Date.now()}
            showOtherDays
          ></DatePickerCalendar>
        </DateCalendarContainer>
        <TextContainer>
          <TextInput
            title={"Reason"}
            placeholder={"Reason"}
            error={props.error === "reason"}
            helperText={props.error === "reason" && "Please enter reason"}
            multiline
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </TextContainer>
      </LateLeaveContainer>
    </React.Fragment>
  );
}
