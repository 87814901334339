import { forEach } from "lodash";
import moment from "moment-timezone";
import { DrawerData } from "./mockData";
import { eventTypes } from "./constant.array";
import { getLocalStorage } from "../services/LocalStorage/LocalStorage";
import { userdata } from "./common.key";

const formatDate = (date) => {
  return moment(date).format("MM-DD-yyyy");
};

const pickerFormatDate = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

const formatDisplayDate = (date, format) => {
  if (format) {
    return moment(date, format);
  }
  return moment(date).tz("Asia/Kolkata");
};
const formatBirthdayandHolidayEvent = (date, type) => {
  if (type === eventTypes[1])
    return moment.tz(date, "YYYY-MM-DD HH:mm:ss.SSS", "UTC");
  return moment.tz(date, "YYYY-MM-DDTHH:mm:ss.SSSZ", "UTC");
};

const monthFormattedDate = (date, format) => {
  if (!date?._isValid) return "non-defined date";
  if (date === "Invalid Date") return "hello";
  return moment(date).format("MMMM");
};

const formatDisplayBirthDate = (date) => {
  if (date === "Invalid date") return null;
  return moment.tz(date, "YYYY-MM-DDTHH:mm:ssZ", "UTC").format("Do MMM");
};
const getUTCDate = (date) => {
  if (date === "Invalid date") return null;
  return moment.tz(date, "YYYY-MM-DDTHH:mm:ssZ", "UTC");
};
const formatDate2 = (date) => {
  if (date === "Invalid date") return null;
  return moment(date).format("MMM, Do YYYY");
};

const formatIndianDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

const getMonthAndYear = (date) => {
  if (!date || date === null) return;
  return moment(date).format("MMMM YYYY");
};

const formatLoginTime = (date) => {
  if (date === "00:00") return "00:00";
  return moment(date).format("hh:mm A");
};

const checkWidth = (width) => {
  if (width > 1200) return true;
  return false;
};

const formatTime = (date) => {
  return moment(date).utcOffset("-0500").format("hh:mm");
};
const formatTimeOnly = (date) => {
  if (date === "00:00") return date;
  return moment(date).local().format("hh:mm");
};
const formatTimeNormal = (date) => {
  return moment(date).format("hh:mm");
};
const formatTimeTwelveHour = (date) => {
  return moment(date).format("hh:mm");
};
const TwelveHourFormatAmPm = (date) => {
  var s = new Date(date);

  return moment(date).local().format("hh:mm");
};
const isfullwidth = (width) => {
  if (width > 1409) return true;
  return false;
};
function getMonthBasicDetail(attendanceMonthData) {
  let late = 0;
  let absent = 0;
  let present = 0;
  forEach(attendanceMonthData, (attendanceMonthDataValue) => {
    if (attendanceMonthDataValue.in_time !== "00:00") {
      present = present + 1;
      if (attendanceMonthDataValue.is_late !== "0") {
        late = late + 1;
      }
    }
  });
  absent = attendanceMonthData.length - present;
  return {
    absent: absent,
    late: late,
    present: present,
  };
}

function getShortWord(word) {
  if (word === "ALL") return "Everyone";
  if (word) {
    let newWordArray = word.split(" ");
    if (newWordArray.length === 1) {
      return word;
    }
    let newWord = "";

    forEach(newWordArray, (newWord, i) => {
      if (newWordArray[i][0] === "&") {
        return true;
      }
      newWord = newWord + newWordArray[i][0];
    });
    return newWord;
  }
}
function getPendingLeaves(leaveInfos) {
  const pendingLeaves = leaveInfos.filter(
    (leave) => leave.leave.status === "PENDING"
  );
  return pendingLeaves;
}
const getDayName = (date) => {
  if (date === "Invalid date") return null;
  return moment(date).format("dddd");
};

function sortEmployees(employeeList) {
  let sorted_employeeList = [...employeeList].sort(
    (employeeFirst, employeeSecond) => {
      return employeeFirst.employee_id.localeCompare(
        employeeSecond.employee_id
      );
    }
  );
  return sorted_employeeList;
}
function getNextMonthDate() {
  const todayDate = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const newDate = new Date(year, month, todayDate);
  return newDate;
}
function getProfileIndex(employee) {
  let indexCounter = -1;
  forEach(getActiveDrawerData(employee.employee_acess), function () {
    indexCounter++;
  });
  return `${indexCounter}`;
}
function getActiveDrawerData(employee_acess) {
  return DrawerData(employee_acess).filter((ele, index) => ele.is_visible);
}
function getActiveDrawerContent(employee_acess, index) {
  return getActiveDrawerData(employee_acess)[index];
}
function getScreenLocation(employee, screenKey) {
  const activeScreens = getActiveDrawerData(employee.employee_acess);
  let counter = 0;
  forEach(activeScreens, (screen) => {
    if (screen.key === screenKey) {
      return false;
    }
    counter++;
  });
  return counter;
}

function BooleanValueCheck(value) {
  return value === "1";
}
function BoolToApiValue(value) {
  if (value) {
    return "1";
  }
  return "0";
}
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const isCheckBool = (value) => {
  if (value == null || value == "0" || value == undefined) {
    return false;
  }
  return true;
};

function findKeyPressDetect(event) {
  if (
    (event.keyCode === 70 && event.ctrlKey) ||
    (event.keyCode === 70 && event.key === "Meta")
  )
    return true;
  return false;
}
async function updateLeaveStatus(leaveController) {
  const pendingEmployeesLeave =
    await leaveController.getPendingEmployeeLeaveData();
  const leaveHistory = await leaveController.getLeaveHistory();
  // dispatch(setLeaveHistoryData(leaveHistory));
  return {
    pendingEmployeesLeave: pendingEmployeesLeave,
    leaveHistory: leaveHistory,
  };
}
function seggragateEvents(events) {
  const seggrgatedEvent = [[], [], [], [], [], [], [], [], [], [], [], []];
  events.map((event, _) => {
    const index = moment(event.event_date).month();
    seggrgatedEvent[index].push(event);

    return;
  });
  return seggrgatedEvent;
}
const sortEmployeeListByBirthdayAndMonth = (employeelist) => {
  const employeeList = [...employeelist];
  const sortedList = sortEmployeeListByBirthdayMonth(employeeList);
  const groupedList = groupByMonth(sortedList);
  const sortedGroupedList = sortByDate(groupedList);
  return sortedGroupedList;
};
const sortByDate = (tempList) => {
  return tempList.map((monthBDayList) =>
    monthBDayList.sort(
      (emp1, emp2) =>
        moment(emp1.birthday).unix() - moment(emp2.birthday).unix()
    )
  );
};

const groupByMonth = (employeeList) => {
  const temproryArray = [[], [], [], [], [], [], [], [], [], [], [], []];
  employeeList.map((employee) => {
    const date =
      employee.birthday !== null ? employee.birthday : moment().format();
    temproryArray[moment(date).month()].push(employee);
  });
  return temproryArray;
};

const sortEmployeeListByBirthdayMonth = (employeeList) => {
  const sortedList = [...employeeList].sort((a, b) => {
    const aMonth = moment(a.birthday).month();
    const bMonth = moment(b.birthday).month();
    return aMonth - bMonth;
  });
  return sortedList;
};
const combineEvents = (birthday_list, event_list, holiday_list) => {
  const temproryArray = [[], [], [], [], [], [], [], [], [], [], [], []];
  event_list.map((monthArray, indexM) => {
    monthArray.map((event, index) => {
      temproryArray[indexM].push({
        data: event,
        type: 1,
        date: event.event_date,
      });
    });
  });
  birthday_list.map((monthArray, indexM) => {
    monthArray.map((employee, index) => {
      temproryArray[indexM].push({
        data: employee,
        type: 2,
        date: employee.birthday,
      });
    });
  });
  holiday_list.map((monthArray, indexM) => {
    monthArray.map((holiday, index) => {
      temproryArray[indexM].push({
        data: holiday,
        type: 3,
        date: holiday.date,
      });
    });
  });
  temproryArray.map((monthArray) => {
    return monthArray.sort(
      (event1, event2) =>
        parseInt(moment(event1.date).format("DD")) -
        parseInt(moment(event2.date).format("DD"))
    );
  });
  return temproryArray;
};

function getOrdinal(number) {
  if (number % 100 === 11 || number % 100 === 12 || number % 100 === 13) {
    return "th";
  } else {
    const lastDigit = number % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
}
const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1000);
  };
};

function checkIfExist(data) {
  if (data === null) return false;
  if (!data) return false;
  if (data === "") return false;
  if (typeof data === "object" && Object.keys(data).length === 0) return false;
  if (Array.isArray(data) && data.length === 0) return false;
  return true;
}
function getScreenStatus(activeDrawerContent, link, activeKey) {
  if (link.includes(activeDrawerContent[0]?.path)) {
    if (activeKey === activeDrawerContent[0]?.key) return;
    return { key: activeDrawerContent[0]?.key, index: 0 };
  }
  if (link.includes(activeDrawerContent[1]?.path)) {
    if (activeKey === activeDrawerContent[1]?.key) return;
    return { key: activeDrawerContent[1]?.key, index: 1 };
  }
  if (link.includes(activeDrawerContent[2]?.path)) {
    if (activeKey === activeDrawerContent[2]?.key) return;
    return { key: activeDrawerContent[2]?.key, index: 2 };
  }
  if (link.includes(activeDrawerContent[3]?.path)) {
    if (activeKey === activeDrawerContent[3]?.key) return;
    return { key: activeDrawerContent[3]?.key, index: 3 };
  }
  if (link.includes(activeDrawerContent[4]?.path)) {
    if (activeKey === activeDrawerContent[4]?.key) return;
    return { key: activeDrawerContent[4]?.key, index: 4 };
  }
  if (link.includes(activeDrawerContent[5]?.path)) {
    if (activeKey === activeDrawerContent[5]?.key) return;
    return { key: activeDrawerContent[5]?.key, index: 5 };
  }
  if (link.includes(activeDrawerContent[6]?.path)) {
    if (activeKey === activeDrawerContent[6]?.key) return;
    return { key: activeDrawerContent[6]?.key, index: 6 };
  }
  if (link.includes(activeDrawerContent[7]?.path)) {
    if (activeKey === activeDrawerContent[7]?.key) return;
    return { key: activeDrawerContent[7]?.key, index: 7 };
  }
  if (link.includes(activeDrawerContent[8]?.path)) {
    if (activeKey === activeDrawerContent[8]?.key) return;
    return { key: activeDrawerContent[8]?.key, index: 8 };
  }
  if (link.includes(activeDrawerContent[9]?.path)) {
    if (activeKey === activeDrawerContent[9]?.key) return;
    return { key: activeDrawerContent[9]?.key, index: 9 };
  }
  if (link.includes(activeDrawerContent[10]?.path)) {
    if (activeKey === activeDrawerContent[10]?.key) return;
    return { key: activeDrawerContent[10]?.key, index: 10 };
  }
  if (link.includes(activeDrawerContent[11]?.path)) {
    if (activeKey === activeDrawerContent[11]?.key) return;
    return { key: activeDrawerContent[11]?.key, index: 11 };
  }
  if (link.includes(activeDrawerContent[12]?.path)) {
    if (activeKey === activeDrawerContent[12]?.key) return;
    return { key: activeDrawerContent[12]?.key, index: 12 };
  }
}
function getJustDate(date) {
  let newDate = date;
  if (!date) return "";
  if (newDate.includes("T")) {
    newDate = newDate.split("T")[0];
    newDate = moment(newDate, "YYYY-MM-DD").format("Do MMM 'YY");
    return newDate;
  } else {
    newDate = newDate.split(" ")[0];
    newDate = moment(newDate, "YYYY-MM-DD").format("Do MMM 'YY");
    return newDate;
  }
}
function isLeader() {
  const user_data = getLocalStorage(userdata);
  if (
    user_data.level.value === 100 ||
    user_data.level.designation === "Leader"
  ) {
    return true;
  }
  return false;
}
export {
  isLeader,
  getJustDate,
  getScreenStatus,
  formatLoginTime,
  getMonthAndYear,
  checkIfExist,
  debounce,
  getOrdinal,
  formatBirthdayandHolidayEvent,
  getUTCDate,
  BoolToApiValue,
  BooleanValueCheck,
  TwelveHourFormatAmPm,
  checkWidth,
  combineEvents,
  delay,
  findKeyPressDetect,
  formatDate,
  formatDate2,
  formatDisplayBirthDate,
  formatDisplayDate,
  formatIndianDate,
  formatTime,
  formatTimeNormal,
  formatTimeOnly,
  formatTimeTwelveHour,
  getActiveDrawerContent,
  getActiveDrawerData,
  getDayName,
  getMonthBasicDetail,
  getNextMonthDate,
  getPendingLeaves,
  getProfileIndex,
  getScreenLocation,
  getShortWord,
  isCheckBool,
  isfullwidth,
  monthFormattedDate,
  pickerFormatDate,
  seggragateEvents,
  sortEmployeeListByBirthdayAndMonth,
  sortEmployees,
  updateLeaveStatus,
};
