import { Menu, Tooltip } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApplyAppeal from "../../component/Forms/ApplyAppeal";
import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import ConfirmationMenu from "../../component/Popup/ConfirmationMenu";
import ConfirmationPopup from "../../component/Popup/ConfirmationPopup";
import ModalPopup from "../../component/Popup/ModalPopup";
import AppealController from "../../controller/appealController";
import AttendanceController from "../../controller/attendanceController";
import { setEmployeeAppeal } from "../../reducer/appealreduxstore";
import { setActiveUserMonthAttendance } from "../../reducer/attendancereduxstore";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import { Div, HtmlTooltip, TypoGraphy } from "../../style/common.style";
import {
  LeaveStatusMsg,
  LeaveStatusMsgContainer,
  LeaveStatusTitle,
  MsgContainer,
  MsgHeading,
} from "../../style/form.style";
import {
  AppealAttendanceRowContainer,
  AttendanceOptions,
  AttendanceRow,
  ChipAttendanceStatus,
  OptionIcon,
  OptionList,
} from "../../style/profile.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { CommonString } from "../../utils/common.string";
import { Month } from "../../utils/constant";
import { delay, formatLoginTime } from "../../utils/utils";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function AppealAttendance(props) {
  console.log( "appealattneds" , props)
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const appealController = new AppealController();
  const [isloading, setLoader] = useState(false);
  const [activeAttendance, setActiveAttendance] = useState(null);
  const [open, setOpen] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [IsPopupOpen, setOpenPopup] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const attendanceData = useSelector(
    (state) => state.attendance.active_user_month_attendance
  );

  console.log("attendanceData1" , attendanceData)
  const leaveList = useSelector(
    (state) => state.leave.active_user_leave_list_profile
  );

  console.log("LIST OF LEAVE" , leaveList)
  const employee = useSelector((state) => state.employee);
  const isAdmin = employee.employee_acess.attendance;
  // const employee = useSelector((state) => state.employee);

  const [selectedStatus, setselectedStatus] = useState(
    CommonString.pending.toUpperCase()
  );

  const dashboard = useSelector((state) => state.dashboard);
  const appeal = useSelector((state) => state.appeal);

  function closePopup() {
    dispatch(setIsModalOpen(false));
    setselectedStatus(CommonString.pending.toUpperCase());
    // dispatch(updateSelectedLeaveDate(null));
    // setOpenPopup(false);
    setOpenPopup(false);
  }

  function isLate(Dayattendance) {
    // if (!Dayattendance || Dayattendance === null) {
    //   return "";
    // }
    if (Dayattendance.in_time === "00:00" && Dayattendance.out_time === "00:00")
      return "Absent";
    if (Dayattendance.in_time !== "00:00") return "Present";
  }
  function getFirstBoxColor(attendance, list) {
    if (!attendance || attendance === null) {
      return { color: CommonColor.red, status: "Data not defined" };
    }
    const isOnLeave = isLeaveApplied(attendance._created_at, list);
    if (isLate(attendance) === "Present") {
      if (attendance.is_late === "1") {
        if (!isOnLeave) {
          return { color: CommonColor.secondary_color, status: "late" };
        } else return { color: CommonColor.green, status: "on leave and late" };
      } else return { color: CommonColor.green, status: "on time" };
    } else {
      if (isOnLeave) {
        return { color: CommonColor.green, status: "on leave" };
      } else {
        return { color: CommonColor.red, status: "uninformed leave" };
      }
    }
  }
  function isLeaveApplied(date, list) {
    const data = list.filter((dataSingle) => {
      if (dataSingle.leave.leave_type.toUpperCase() === "LATE") return false;
      if (dataSingle.leave.leave_type?.toUpperCase() === "MULTIPLE") {
        if (
          parseInt(moment(dataSingle.leave?.from_date).format("MMDD")) <=
            parseInt(moment(date).format("MMDD")) &&
          parseInt(moment(date).format("MMDD")) <=
            parseInt(moment(dataSingle.leave.to_date).format("MMDD"))
        ) {
          if (dataSingle.leave.status.toUpperCase() !== "APPROVED")
            return false;

          return true;
        }
        return false;
      } else {
        if (
          parseInt(moment(dataSingle.leave?.from_date).format("MMDD")) ===
          parseInt(moment(date).format("MMDD"))
        ) {
          if (dataSingle.leave.status.toUpperCase() !== "APPROVED")
            return false;

          return true;
        }

        return false;
      }
    });
    if (data.length === 0) return false;
    return true;
  }
  function getLeaveStatus(data, list) {

   
    if (!data) {
      return {
        status: "error",
        color: "",
      };
    }

     let in_time;
    if (data.in_time.indexOf("T") === -1) {
      in_time = data.in_time.replace(" ", "T");
      in_time = in_time + "Z";
    } else {
      in_time = data.in_time;
    }
    let out_time;
    if (data.out_time.indexOf("T") === -1 && data.out_time !== "00:00") {
      out_time = data.out_time.replace(" ", "T");
      out_time = out_time + "Z";
    } else {
      out_time = data.out_time;
    }

     const status = getFirstBoxColor(data, list).status;
    
    if(status === "late"){
      return {
        status: formatLoginTime(in_time) + " - " + formatLoginTime(out_time),
        color: "secondary",
      };
    }
    else if(status === "uninformed leave"){
      return {
        status: "Uninformed Leave",
        color: "error",
      };
    }
    else{
      return {
        status: formatLoginTime(in_time) + " - " + formatLoginTime(out_time),
        color: "success",
      };
    }

   
   
    // if (status === "on time") {
    //   return {
    //     status: formatLoginTime(in_time) + " - " + formatLoginTime(out_time),
    //     color: "success",
    //   };
    // } else if (status === "late") {
    //   return {
    //     status: formatLoginTime(in_time) + " - " + formatLoginTime(out_time),
    //     color: "secondary",
    //   };
    // } else if (status === "on leave and late") {
    //   return {
    //     status: formatLoginTime(in_time) + " - " + formatLoginTime(out_time),
    //     color: "success",
    //   };
    // } else if (status === "on leave")
    //   return { status: "Member is on leave", color: "success" };
    // else if (status === "uninformed leave")
    //   return {
    //     status: "Uninformed Leave",
    //     color: "error",
    //   };
  }
  function handleClick(event, Dayattendance, index) {
    setAnchorEl(event.currentTarget);
    setActiveAttendance(Dayattendance);
    setOpen(index);
  }
  function getOptions(status) {
    if (status === "NotApply") {
      const statusArray = ["Apply"];
      return statusArray;
    }
    if (status === "Apply") {
      const statusArray = ["Edit", "Delete"];
      return statusArray;
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
    setActiveAttendance(null);
    setOpen(null);
  };
//  employee={activeEmployeeData}
  function getChildren(setOpenApplyModel, appealData) {
    if (isEditable) {
      return (
        <ApplyAppeal
          currentMonthIndex = {props.currentMonthIndex}
          appealData={appealData}
          isEdit={isEditable}
          data={props.selectedData}
          IsPopupOpen={IsPopupOpen}
          onHandleClose={() => {
            setIsEditable(false);
            setOpenApplyModel(false);
          }}
          forUser={props.employee}
        />
      );
    }

    return (
      <ApplyAppeal
        currentMonthIndex = {props.currentMonthIndex}
        isEdit={false}
        data={props.selectedData}
        IsPopupOpen={IsPopupOpen}
        onHandleClose={() => setOpenApplyModel(false)}
        forUser={props.employee}
      />
    );
  }

  function getAppealDataFromDate(data) {
    const res = appeal.appeal_employees_data.filter(
      (dat) =>
        dat.attendance_date === data.attendance_date &&
        dat.user_id === data.user_id
    );

    return res;
  }

  function isAppealed(data) {
    const res = appeal.appeal_employees_data.find(
      (dat) =>
        dat.attendance_date === data.attendance_date &&
        dat.user_id === data.user_id
    );

    return res;
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  async function onDelete(_id) {
    props.setLoading(true);

    const response = await appealController.deleteEmployeeAppeal(_id);

    const successMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>leave deleted sucessfully</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );

    const errorMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>error</LeaveStatusTitle>
        <LeaveStatusMsg>please try after a while</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );

    if (response) {
      const employeeId = props.employee._id;

      const newresponse = await appealController.getEmployeeAppealDataById(
        employeeId
      );

      if (newresponse) {
        dispatch(setEmployeeAppeal(newresponse));
      }

      setTimeout(() => {
        props.setLoading(false);
        // setisOpen(false);
        handleAlert(successMsg, "success");
      }, 500);
    } else {
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        props.setLoading(false);
        // setisOpen(false);
      }, 500);
    }

    // handleCloseMenu();

    // dispatch(deleteEmployeeAppeal(_id));

    // if (event) {
    //   event.stopPropagation();
    // }
    // setselectedStatus(status);
    // setisOpen(true);
    // handleCloseMenu();
  }
  function isTodayAppealPending(date) {
    if (isAdmin) return true;
    const appealSingleData = appeal.appeal_employees_data.filter(
      (appealData) => {
        return (
          moment(appealData.attendance_date).format("MMDD") ===
          moment(date).format("MMDD")
        );
      }
    )[0];
    if (!appealSingleData) return false;

    return appealSingleData.status === "PENDING";
  }

  return (
    <Div className="h-max overflow-auto w-6/6">
      {props.loading ? (
        <PageRefreshLoader />
      ) : (
        <AppealAttendanceRowContainer>
          {[...attendanceData].map((data, index) => {
            return (
              <AttendanceRow
                key={index}
                onClick={() => {
                  props.setSelectedData(data);
                }}
              >
                <Div className="flex items-center  w-full justify-between">
                  <div className="flex items-center gap-4  w-full justify-start">
                    <TypoGraphy fontSize={FontSizes.Subtitle} weight="700">
                      {moment(data._created_at).format("DD")}
                    </TypoGraphy>
                    {/* <div>
                      
                      {isAppealed(data) ? (
                        
                       <div className={` bg-[#e7bebe] rounded-md`}>
                         <p className={`text-[0.7rem] p-[0.12rem] px-[0.20rem] `}>Appealed</p>
                       
                       </div>
                      
                      ) : (
                        ""
                      )}
                    </div> */}

                    <div>
                      {isAppealed(data) ? (
                        <div>
                          {getAppealDataFromDate(data)[0].status ===
                          "PENDING" ? (
                            // <ChipAttendanceStatus
                            //   iscolornotexist={1}
                            //   isvisible={1}
                            //   variant="outlined"
                            //   label={"Pending"}
                            //   size="small"
                            //   chipcolor={"#ff7519"}
                            // />
                            <HtmlTooltip
                              title={
                                <TypoGraphy color={CommonColor.secondary_color}>
                                  Appeal Pending
                                </TypoGraphy>
                              }
                              placement="right"
                              arrow
                            >
                              <Icon
                                icon="material-symbols:pending-actions-rounded"
                                style={{
                                  color: CommonColor.secondary_color,
                                  fontSize: 20,
                                }}
                              />
                            </HtmlTooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {(data.is_late === "1" ||
                    data.in_time === "00:00" ||
                    isTodayAppealPending(data.attendance_date)) && (
                    <AttendanceOptions>
                      <OptionIcon
                        icon="mi:options-vertical"
                        onClick={(e) => handleClick(e, data, index)}
                        height={15}
                      />
                      <MenuOption
                        options={getOptions(
                          isAppealed(data) ? "Apply" : "NotApply"
                        )}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        isOpen={IsPopupOpen}
                        setIsOpen={(data) => setOpenPopup(data)}
                        onDelete={onDelete}
                        appealData={isAppealed(data)}
                        open={open}
                        index={index}
                        isEditable={isEditable}
                        setIsEditable={setIsEditable}
                        getChildren={getChildren}
                        IsPopupOpen={IsPopupOpen}
                        attendanceData={data}
                        employee={props.employee}
                        monthAndYear={props.monthAndYear}
                        selectedData={props.selectedData}
                        currentMonthIndex = { props.currentMonthIndex}
                      />
                    </AttendanceOptions>
                  )}
                </Div>
                <TypoGraphy fontSize={FontSizes.SubPara} weight="500">
                  {moment(data._created_at).format("dddd")}
                </TypoGraphy>

                <Div className="flex justify-between h-full w-max items-center flex-col">
                  {attendanceData.length !== 0 ? (
                    <ChipAttendanceStatus
                      // className="h-max w-max text-xs pl-1 pr-1 rounded-md text-white break-words"
                      // style={{
                      //   backgroundColor: getLeaveStatus(data).color,
                      // }}
                      iscolornotexist={1}
                      isvisible={1}
                      variant="outlined"
                      label={getLeaveStatus(data, leaveList).status}
                      size="small"
                      chipcolor={getLeaveStatus(data, leaveList).color}
                    />
                  ) : (
                    ""
                  )}
                </Div>
                {/* {employee.employee_acess.attendance ? (
                <AttendanceOptions>
                  <OptionIcon
                    icon="mi:options-vertical"
                    onClick={(e) => handleClick(e, data, index)}
                    height={15}
                  />
                  <MenuOption
                    options={getOptions()}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    open={open}
                    index={index}
                    attendanceData={data}
                    employee={props.employee}
                    monthAndYear={props.monthAndYear}
                  />
                </AttendanceOptions>
              ) : (
                <></>
              )} */}
              </AttendanceRow>
            );
          })}
        </AppealAttendanceRowContainer>
      )}

      {/* </FlexContainer> */}
    </Div>
  );
}

const MenuOption = (props) => {

  console.log("MENU PROPS" , props);
  const dispatch = useDispatch();

  const [openApplyModel, setOpenApplyModel] = useState(false);
  const [openMarkPresentConfirmation, setOpenPresentMarkConfirmation] =
    useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openMarkLateConfirmation, setOpenMarkLateConfirmation] =
    useState(false);
  const [openMarkAbsentConfirmation, setMarkAbsentConfirmation] =
    useState(false);
  const [openRemoveLateConfirmation, setOpenRemoveLateConfirmation] =
    useState(false);
  const [activeOption, setActiveOption] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const attendanceController = new AttendanceController();
  const attendance = useSelector((state) => state.attendance);
  const auth = useSelector((state) => state.auth);

  function getLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  }

  function action(option) {
    setActiveOption(option);
    if (option === "Apply") {
      setOpenApplyModel(true);
    }
    if (option === "Delete") {
      setOpenDeleteConfirmation(true);
    }
    if (option === "Edit") {
      props.setIsEditable(true);
      setOpenApplyModel(true);
    }
  }

  async function MarkPresent() {
    setLoading(true);

    var date = new Date(props.attendanceData._created_at).getDate();
    var month = new Date(props.attendanceData._created_at).getMonth();
    var year = new Date(props.attendanceData._created_at).getFullYear();
    let out_time = new Date(year, month, date, 13, 30, 0).toISOString();

    const position = await getLocation().catch((error) => error);
    const sendData = {
      _id: props.attendanceData.id,
      user_id: props.attendanceData.user_id,
      attendance_date: props.attendanceData.attendance_date,
      in_time: out_time,
      out_time: out_time,
      lat: position.coords.latitude,
      is_late: "0",
      lon: position.coords.longitude,
      _created_at: out_time,
      _updated_at: moment().format(),
      approved_user_id: auth.employee_data.user._id,
    };

    const isMarkedPresent = await attendanceController.markPresent(sendData);
    await delay(1000);
    setLoading(false);
    if (isMarkedPresent) {
      const sucessMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>appeal status updated sucessfully</sucessMsg>
        </MsgContainer>
      );
      const currentMonthIndex = new Date(props.monthAndYear).getMonth();
      const year = new Date().getFullYear().toString();
      const dataMonthOne = {
        user_id: props.employee._id,
        month: Month[currentMonthIndex].toLowerCase(),
        year: year,
      };
      const monthAttendance = await attendanceController.getMonthAttendance(
        dataMonthOne
      );
      dispatch(setActiveUserMonthAttendance(monthAttendance));
      handleAlert(sucessMsg, "success");
      handlerClosePopup();
      return;
    }
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>please try again later</sucessMsg>
      </MsgContainer>
    );
    handleAlert(errorMsg, "error");
    handlerClosePopup();
  }
  function handlerClosePopup() {
    if (activeOption === "Apply") {
      setOpenApplyModel(false);
    }
    if (activeOption === "Delete") {
      setOpenDeleteConfirmation(false);
    }
    if (activeOption === "Edit") {
      setOpenApplyModel(false);
    }
    if (activeOption === "Mark Absent") {
      setMarkAbsentConfirmation(false);
    }
    if (activeOption === "Remove Late") {
      setOpenRemoveLateConfirmation(false);
    }
  }
  function getConfirmationMessage(string) {
    const replacingString = props.employee.name + " ?";

    return string.replace("?", replacingString);
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  async function lateStatusChange(status) {
    const sendData = {
      is_late: status,
      id: props.attendanceData.id,
    };
    //api call
    const res = await attendanceController.markLate(sendData);

    if (res) {
      // var data = { ...props.attendanceData, is_late: status };
      const attendanceData = attendance.active_user_month_attendance.map(
        (attendanceData, index) => {
          if (attendanceData.id === props.attendanceData.id) {
            return {
              ...props.attendanceData,
              is_late: status,
            };
          }
          return attendanceData;
        }
      );
      dispatch(setActiveUserMonthAttendance(attendanceData));
      const successMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>late status updated sucessfully</sucessMsg>
        </MsgContainer>
      );
      handleAlert(successMsg, "success");

      handlerClosePopup();
      return;
    }
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>please try again later</sucessMsg>
      </MsgContainer>
    );
    handleAlert(errorMsg, "error");
    handlerClosePopup();
  }

  function getModal() {
    if (activeOption === "Apply") {
      return props.getChildren(setOpenApplyModel);
    }
    if (activeOption === "Delete") {
      return (
        <ConfirmationPopup
          onHandleClose={() => setOpenDeleteConfirmation(false)}
          isShow={loading}
          open={openDeleteConfirmation}
          title={"DELETE APPEAL"}
          // title={CommonString.deleteAttendanceTitle}
          confirmationMessage={getConfirmationMessage(
            CommonString.deleteAttendanceText
          )}
          yesButtonName={CommonString.yes}
          Confirmed={() => {
            props.onDelete(props.appealData._id);
            setOpenDeleteConfirmation(false);
          }}
          noButtonName={CommonString.no}
          Att={() => handlerClosePopup()}
          color={CommonColor.primary_color}
        />
      );
    }
    if (activeOption === "Edit") {
      return props.getChildren(setOpenApplyModel);
      // return (
      //   <ModalPopup open={openApplyModel} isEdit={true} popupSize="sm">
      //     {props.getChildren(setOpenApplyModel)}
      //   </ModalPopup>
      // );
    }
  }

  // function getChildren(props) {
  //   if (selectedStatus === CommonString.edit_leave.toUpperCase()) {
  //     return (
  //       <ApplyLeave
  //         IsPopupOpen={dashboard.isModalOpen}
  //         isEdit={true}
  //         onHandleClose={() =>closePopup()}
  //       />
  //     );
  //   }

  //   return (
  //     <ApplyAppeal
  //       IsPopupOpen={IsPopupOpen}
  //       onHandleClose={() =>closePopup()}
  //       forUser={getLocalStorage(userdata)?.data}
  //     />
  //   );
  // }

  function getChildren() {
    if (activeOption === "Apply") {
      return (
        <ApplyAppeal
          currentMonthIndex = { props.currentMonthIndex}
          isEdit={false}
          data={props.selectedData}
          IsPopupOpen={props.IsPopupOpen}
          onHandleClose={() => setOpenApplyModel(false)}
          forUser={props.employee}
        />
      );
      // props.getChildren(setOpenApplyModel);
    }

    if (activeOption === "Edit") {
      return (
        <ApplyAppeal
        currentMonthIndex = { props.currentMonthIndex}
          appealData={props.appealData}
          isEdit={props.isEditable}
          data={props.selectedData}
          IsPopupOpen={props.IsPopupOpen}
          onHandleClose={() => {
            props.setIsEditable(false);
            setOpenApplyModel(false);
          }}
          forUser={props.employee}
        />
      );

      // props.getChildren(setOpenApplyModel, props.appealData);
    }

    if (activeOption === "Delete") {
      return (
        <ConfirmationMenu
          isShow={loading}
          title={getConfirmationMessage(CommonString.deleteAttendanceText)}
          yesButtonName="Yes"
          onClickYesButton={() => {
            props.onDelete(props.appealData._id);
            setOpenDeleteConfirmation(false);
          }}
          noButtuonName="No"
          onClicknoButton={() => handlerClosePopup()}
          color={CommonColor.primary_color}
        />
      );
    }
  }
  return (
    <React.Fragment>
      {/* {getModal()} */}
      <ModalPopup
        open={openApplyModel || openDeleteConfirmation}
        popupSize="sm"
      >
        {getChildren()}
      </ModalPopup>
      <Menu
        anchorEl={props.anchorEl}
        id="account-menu"
        open={props.open === props.index}
        onClose={props.handleClose}
        onClick={props.handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        {props.options
          ? props.options.map((option, index) => {
              return (
                <OptionList
                  isvisible={1}
                  key={index}
                  onClick={() => action(option)}
                >
                  {option}
                </OptionList>
              );
            })
          : ""}
      </Menu>
    </React.Fragment>
  );
};
