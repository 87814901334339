import styled from "styled-components";
import CommonColor from "../utils/common.colors";

const PdfDelIconContainer = styled.div`
  height: 20px;
  width: 20px;
`;

const GetButton = styled.div`
background-color:${CommonColor.primary_color};
padding: 0.4rem;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
`

export { PdfDelIconContainer , GetButton};
