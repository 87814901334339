import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import React from "react";
import {
  DrawerCloseIcon,
  DrawerContentWrapper,
  DrawerHeaderWrapper,
  DrawerImageandCloseWrapper,
  IconWrappperDrawerHeader,
  PrexelLogoHeader,
} from "../../style/drawer.style";

import { default as PrexelLogo } from "../../assets/images/PrexelLogo.svg";

export default function DrawerHeaderContainer(props) {
  return (
    <React.Fragment>
      <DrawerHeaderWrapper
        open={props.open}
        openWidth={"240px"}
        closedWidth="64px"
      >
        <DrawerImageandCloseWrapper open={props.open}>
          <DrawerContentWrapper open={props.open}>
            <PrexelLogoHeader src={PrexelLogo} alt=""></PrexelLogoHeader>
            <IconButton
              className="drawerIcon"
              onClick={() => props.handleDrawerClose()}
            >
              <ChevronLeftIcon sx={DrawerCloseIcon} />
            </IconButton>
          </DrawerContentWrapper>
        </DrawerImageandCloseWrapper>
        <DrawerImageandCloseWrapper
          open={!props.open}
          onClick={props.handleDrawerOpen}
        >
          <DrawerContentWrapper open={!props.open}>
            <IconWrappperDrawerHeader>
              <MenuIcon />
            </IconWrappperDrawerHeader>
          </DrawerContentWrapper>
        </DrawerImageandCloseWrapper>
      </DrawerHeaderWrapper>
    </React.Fragment>
  );
}
