import { forEach } from "lodash";
import HolidayProvider from "../services/provider/HolidayProvider";
import moment from "moment";

class LoginController {
  constructor(props) {
    this.holidayProvider = new HolidayProvider();
  }

  getHolidayList = async (data) => {
    let res = await this.holidayProvider.getHolidayList(data);
    if (res.status === 200) {
      return res.data.data;
    }
    return [];
  };
  getMutatedHolidayList = (data) => {
    data.sort(compareHoliday);
    const newDataList = sortByMonth(data);
    return newDataList;
  };
  getPrexelPhotosList = async () => {
    let res = await this.holidayProvider.getPrexelPhotosList();
    if (res.status === 200) {
      return res.data.data;
    }
    return false;
  };
}

function compareHoliday(holiday1, holiday2) {
  const date1 = moment(holiday1.date).valueOf();
  const date2 = moment(holiday2.date).valueOf();
  if (date1 > date2) {
    return 1;
  }
  if (date2 > date1) {
    return -1;
  }
  return 0;
}
function sortByMonth(dataList) {
  const newDataList = [];

  forEach(Array(12), (value, i) => {
    const monthDataList = dataList.filter(
      (data) => parseInt(moment(data.date).format("MM")) - 1 === i
    );
    newDataList.push(monthDataList);
  });
  return newDataList;
}

export default LoginController;
