import React from "react";

export default function InputWithHalfValue(props) {
  function onChange(event) {
    const newValue = props.halfDefaultInput + event.target.value;
    props.onhandleChange(newValue);
  }
  function getValue() {
    return props.value?.slice(
      props.halfDefaultInput.length,
      props.value.length
    );
  }
  return (
    <div class="sm:col-span-4">
      <label
        for="username"
        class="block text-sm font-medium leading-6 text-gray-900"
      >
        {props.title}
      </label>
      <div class="mt-2">
        <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
          <span class="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
            {props.halfDefaultInput}
          </span>
          <input
            type={props.type ?? "text"}
            autocomplete="username"
            class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={props.placeholder}
            value={getValue()}
            onChange={(event) => onChange(event)}
          />
        </div>
      </div>
    </div>
  );
}
