import { AppBar, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoData from "../../component/commonComponents/NoData/NoData";
import NavBar from "../../component/drawer/NavBar";
import TrainingMaterialHomeShimmer from "../../component/Loader/shimmers/TrainingMaterialHome";
import PolicyPdf from "../../component/policy/PolicyFile";
import ModalPopup from "../../component/Popup/ModalPopup";
import PolicyViewer from "../../component/Popup/PolicyViewer";
import {
  setIsModalOpen,
  SetLocalModalName,
} from "../../reducer/dashboardreduxstore";
import {
  setActivePolicy,
  setPolicyLoaded,
} from "../../reducer/policyreduxstore";
import { AppBarStyle } from "../../style/drawer.style";
import {
  FilterContainerTraining,
  MultipleFilterContainer,
  NoDataComponentContainerTrainingMaterial,
  PdfCardContainer,
  TrainingPageNav,
  TrainingScreenWrapper,
  UploadedFilesContainer,
  VerticalScrollableAreaTraining,
} from "../../style/training.style";
import { CommonString } from "../../utils/common.string";
import { isLeader } from "../../utils/utils";
import AddPolicy from "./AddPolicy";

export default function Policy(props) {
  const dashboard = useSelector((state) => state.dashboard);
  const [isPreview, setisPreview] = useState(false);
  const [files, setFiles] = useState([]);
  const [PdfPolicy, setPdfPolicy] = useState([]);
  const dispatch = useDispatch();
  const [searchText, setsearchText] = useState("");
  const [pageLoading, setPageLoading] = useState();
  // !props.loadedPages.includes(CommonString.trainingMaterialScreenKey)
  const policy = useSelector((state) => state.policy);

  useEffect(() => {
    getData();
  }, []);

  function fileUpdater(preview) {
    setFiles(preview);
  }

  function PdfCloser() {
    dispatch(setIsModalOpen(false));
    dispatch(SetLocalModalName(null));
    setisPreview(false);
  }

  function getChildren(index) {
    if (!isPreview)
      return (
        <AddPolicy
          fileUpdater={fileUpdater}
          files={files}
          PdfCloser={() => PdfCloser()}
          isPolicy={true}
          isEdit={false}
        />
      );
    return (
      <PolicyViewer
        isPolicy={true}
        files={policy.policy}
        index={index}
        file={policy.policy[policy.active_policy]}
        PdfCloser={() => PdfCloser()}
        // activeWing={branchSelected}
        // activeType={eventSelected}
      />
    );
  }

  async function getData() {
    await props.getPolicy();
  }

  function onSearch(text) {
    setsearchText(text);
    if (text === "") {
      setPdfPolicy(policy.policy);
      return;
    }
    let tmpData = policy.policy.filter((data) =>
      data.name.toLowerCase().includes(text.toLowerCase())
    );
    setPdfPolicy(tmpData);
  }

  function getPopupWidth() {
    if (isPreview === true) {
      return "100vw";
    }
    return "fit-content";
  }

  function isDataLoading() {
    return pageLoading;
  }

  function OpenPdf(index) {
    dispatch(setActivePolicy(index));
    dispatch(setIsModalOpen(true));
    setisPreview(true);
    dispatch(setPolicyLoaded(false));
    dispatch(SetLocalModalName("view pdf"));
  }

  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Policy"}
            NavBarIcon={
              // <Icon icon="iconoir:privacy-policy" />
              isLeader()
                ? {
                    icon: "iconoir:privacy-policy",
                    name: "Add Policy",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      <TrainingScreenWrapper>
        <VerticalScrollableAreaTraining height="100" unit="%">
          <TrainingPageNav>
            {/* <SearchContainer>
              <SearchBar
                onSearch={(e) => onSearch(e)}
                searchValue={searchText}
              />
            </SearchContainer> */}
            <MultipleFilterContainer>
              <FilterContainerTraining>
                {/* {Branches.map((branch, index) => (
                  <FilterTraining
                    variant="outlined"
                    label={branch.name}
                    onClick={() => activeWingUpdater(index)}
                    key={index}
                    index={index}
                    activeindex={activeWing}
                  />
                ))} */}
                {/* <DropdownWithChip
                  list={Branches.map((ele) => ele.name)}
                  selectedOption={branchSelected}
                  handleClick={(event) => setSelectedBranch(event)}
                  buttonWidth="146px"
                /> */}
              </FilterContainerTraining>
              <FilterContainerTraining>
                {/* {EventTypes.map((branch, index) => (
                  <FilterTraining
                    variant="outlined"
                    label={branch}
                    onClick={() => activeTypeUpdater(index)}
                    key={index}
                    index={index}
                    activeindex={activeType}
                  />
                ))} */}
                {/* <DropdownWithChip
                  list={EventTypes}
                  selectedOption={eventSelected}
                  handleClick={(event) => setSelectedEvent(event)}
                  buttonWidth={"216px"}
                /> */}
              </FilterContainerTraining>
            </MultipleFilterContainer>
          </TrainingPageNav>
          <UploadedFilesContainer vertical={PdfPolicy.length % 3 === 0}>
            {isDataLoading() ? (
              <TrainingMaterialHomeShimmer />
            ) : policy.policy.length > 0 ? (
              policy.policy.map((uploadedFile, index) => (
                <PdfCardContainer
                  key={index}
                  index={index}
                  length={policy.policy.length}
                >
                  <PolicyPdf
                    isPolicy={true}
                    index={index}
                    file={uploadedFile}
                    OpenPdf={(i) => OpenPdf(i)}
                    showActive={policy.active_policy === index}
                    length={policy.policy.lengthss}
                  />
                </PdfCardContainer>
              ))
            ) : (
              <NoDataComponentContainerTrainingMaterial>
                <NoData noDataText={CommonString.NoPolicy} />
              </NoDataComponentContainerTrainingMaterial>
            )}
          </UploadedFilesContainer>
        </VerticalScrollableAreaTraining>{" "}
      </TrainingScreenWrapper>
      {/* <policy.policy PdfCloser ={PdfCloser}/> */}
      <ModalPopup
        open={dashboard.isModalOpen}
        styleSX={{
          minWidth: getPopupWidth(),
          maxHeight: "100vh",
          margin: 0,
          overflowX: "hidden",
        }}
      >
        {getChildren()}
      </ModalPopup>
    </React.Fragment>
  );
}
