import React from "react";
import ButtonCustomV2 from "../../../componentsv2/common_components/Button/Button";
import { ButtonWrapper } from "../../../style/form.style";

export default function BottomButtons(props) {
  return (
    <ButtonWrapper componenttype={props.componentType}>
      {/*
                schriftfarbe in german means font color
                randfarbe in german means border color
                 */}
      <ButtonCustomV2
        variant="contained"
        onClick={() => props.onSave()}
        bg={props.bgcolor}
        fontWeight="500"
        buttonText={props.buttonName}
      />
    </ButtonWrapper>
  );
}
