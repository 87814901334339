import React from "react";

import {
  TrainingShimmerBlock,
  TrainingShimmerCard,
} from "../../../style/loader.style";
export default function TrainingMaterialHomeShimmer(props) {
  return (
    <React.Fragment>
      {[...Array(15)].map((index) => (
        <TrainingShimmerBlock key={index}>
          <TrainingShimmerCard height={75} width={"100%"} rounded />
        </TrainingShimmerBlock>
      ))}
    </React.Fragment>
  );
}
