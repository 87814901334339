import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  linearProgressClasses,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import Picker from "react-month-picker";
import styled, { keyframes } from "styled-components";
import { ReactComponent as DashboardIcon } from "../assets/images/DashboardIcon.svg";
import CommonColor from "../utils/common.colors";
import { CommonImages } from "../utils/common.images";
import {
  BaseCardDashboard,
  dashboardCardLoadAnimation,
  DeviceSizes,
  opacityChangeAnimation,
  PageHeaderContainer,
  SolidButton,
  VerticalScrollableArea,
} from "./common.style";
import { EmployeeLeaveDataNavbar, EmployeeName } from "./leave.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";
import { PopupNavBarContainerTraining } from "./training.style";

const LeftSideContainer = styled.div`
  padding-top: 8px;
  padding-left: 20px;
  padding-right: 10px;
  overflow: hidden;
`;

const SplitContainer = styled.div`
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${DeviceSizes.mobile} {
    flex-wrap: wrap;
  }
`;

const DivBlock = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 6px;
  display: block;
  @media ${DeviceSizes.mobile} {
    padding: 10px;
  }
`;

const CardTitle = styled.label`
  font-family: "Mulish";

  font-weight: 700;
  letter-spacing: 0.3px;
  padding-right: 10px;
  font-size: ${FontSizes.Title};
  color: ${CommonColor.primary_color};
`;

const NameTitle = styled(CardTitle)`
  letter-spacing: 0.3px;
  color: ${CommonColor.secondary_color};
`;

const CardDescription = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  font-weight: 700;
  max-width: 260px;
  line-height: 18px;
  letter-spacing: 1px;
  word-spacing: 0.3px;
  font-size: ${FontSizes.SmallText};
  font-family: "Mulish";

  color: ${CommonColor.light_text};
`;

const SubtitleText = styled(CardTitle)`
  font-size: ${FontSizes.Para};
  font-weight: 900;
`;
const SubtitleTextExtended = styled(CardTitle)`
  font-size: ${FontSizes.Para};
  color: ${CommonColor.secondary_color};
  font-weight: 900;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  padding-top: 25px;
  & svg {
    max-height: 280px;
    max-width: 200px;
  }
`;

const RightSideContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: hidden;
`;

const SubContainer = styled(RightSideContainer)`
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
`;
const DivFlex = styled.div`
  display: flex;
  align-items: center;
`;

const CustomAvatar = styled(Avatar)`
  margin-right: 10px;
  font-weight: 600 !important;
  font-size: ${FontSizes.Subtitle}!important;
  height: 30px !important;
  width: 30px !important;
  background-color: ${CommonColor.light_orange}!important;
  color: ${CommonColor.secondary_color}!important;
`;

const CardTitleExtended = styled(CardTitle)`
  align-items: center;
  letter-spacing: 0.3px;
  word-spacing: 1px;
  font-size: ${FontSizes.Subtitle};
  font-family: "Mulish" !important;
`;

const AttendanceScore = styled.div`
  padding-bottom: 15px;
  align-items: center;
  line-height: 12px;
`;

const SmallText = styled.p`
  letter-spacing: 0.3px;
  font-size: ${FontSizes.SubPara};
  font-weight: 700;
  padding-right: 8px;
  font-family: "Mulish";

  color: ${CommonColor.primary_color};
`;

const SmallNumber = styled(SmallText)`
  letter-spacing: 0.3px;
  padding-right: 8px;
  font-size: ${FontSizes.SubPara};
  color: ${CommonColor.secondary_color};
  font-family: "Mulish";
`;

const CustomArrow = styled(Icon)`
  margin-top: 8px;
`;

const NormalText = styled(SmallText)`
  font-weight: 100;
  font-family: "Mulish";
`;
const SmallTextExtended = styled.p`
  letter-spacing: 0.3px;
  padding-right: 10px;
  font-size: ${FontSizes.SmallText};
  font-weight: 700;
  text-decoration-line: underline;
  font-family: "Mulish";

  color: ${CommonColor.secondary_color};
`;

const HorizontalDivider = styled.div`
  position: relative;
  border: none;
  height: 1px;
  background-color: ${CommonColor.divider_gray};
  margin: 8px;
`;

const VerticalDivider = styled.div`
  height: 50px;
  border: 1px solid ${CommonColor.divider_gray};
`;

const DivSpaceAround = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: space-around;
`;

const OutlinedButton = styled(SolidButton)`
  background-color: ${CommonColor.white}!important;
  color: ${CommonColor.primary_color}!important;
  padding: 0px !important;
`;

const DateRow = styled.p`
  line-height: 1px;
  font-family: "Mulish";

  font-size: ${FontSizes.Subtitle};
  color: ${CommonColor.primary_color};
  letter-spacing: 0.3px;
  font-weight: 700;
`;

const EventDetailContainer = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 30px;
  min-width: 68%;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 31px -7px rgba(0, 0, 0, 0.15);
`;

const CustomGrid = styled(Grid)`
  padding-left: 240px;
  background-color: ${CommonColor.theme_background_color};
  @media ${DeviceSizes.mobile} {
    padding-left: 0px;
  }
`;

const VerticalBar = styled(VerticalDivider)`
  border: 2px solid ${CommonColor.vertical_bar};
  border-radius: 10px;
  margin-top: 15px;
  margin-right: 20px;
`;

const DarkVerticalBar = styled(VerticalBar)`
  border: 2px solid ${CommonColor.primary_color};
  margin-right: 0px;
  margin-left: 20px;
`;

const LeaveDivBlock = styled(DivBlock)`
  margin-top: 18px;
`;

const LeaveUsedNumber = styled(LeaveDivBlock)`
  text-align: right;
`;

const HorizontalBar = styled(HorizontalDivider)`
  width: 40%;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
`;

const SubCard = styled(EventDetailContainer)`
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-bottom: 10px;
  border-radius: none;
`;

const ContainedButton = styled(SolidButton)`
  background-color: ${CommonColor.contained_button_background}!important;
  color: ${CommonColor.green}!important;
  text-transform: capitalize !important;
  max-width: 35%;
`;

const MediumText = styled(SmallText)`
  font-weight: 600;
  font-size: ${FontSizes.Para};
`;

const LeaveInfoCardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  margin-bottom: 2%;
  border: 1px solid ${CommonColor.divider_gray};
  & div {
    margin-bottom: 0%;
    padding: auto;
    padding-top: 0.5%;
  }
  & div div {
    margin-bottom: auto;
    padding: auto;
    padding-top: auto;
  }
`;

const GraphContainer = styled.div``;

const SvgContainer = styled.svg`
  transform: rotate(270deg);
  & circle {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
    stroke: ${CommonColor.primary_color};
    stroke-width: 0.5em;
    stroke-linecap: round;
  }
  & circle:nth-child(1) {
  }
  & circle:nth-child(2) {
    stroke: ${CommonColor.primary_color};
    stroke-dashoffset: ${(props) => props.$dashoffset};
  }
`;
const NumberContainer = styled.div`
  position: relative;
  top: -55%;
  left: 0%;
  text-align: center;
`;
const LeaveNumber = styled.div`
  font-size: 2.4em;
  font-weight: bolder;
  font-family: "Mulish";

  color: ${CommonColor.primary_color};
`;
const LeavesRemainingSubtitle = styled.div`
  font-size: 0.6em;
  font-weight: 400;
  font-family: "Mulish";
`;

const ScrollDiv = styled.div`
  overflow: scroll;
`;
const BaseCardDashboardContainer = styled(BaseCardDashboard)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: ${(props) =>
      props.isverticalcenter === 1 ? "center" : "flex-end"};
    padding: 0%;
    padding-top: 1%;
    @media ${DeviceSizes.mobile} {
      margin-bottom: 3%;
    }
  }
`;
const HeadingContainer = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Subtitle};
  color: ${Theme.primary_color.primary_color};
  font-weight: 700;
  letter-spacing: 0.01em;
  width: 100%;
  @media ${DeviceSizes.mobile} {
    text-align: center;
    margin-top: 4%;
  }
`;
const NameContainer = styled.div`
  color: ${Theme.primary_color.secondary_color};
  text-transform: capitalize;
  display: inline;
  width: 100%;
  @media ${DeviceSizes.mobile} {
    text-align: center;
  }
`;
const SubHeadingContainer = styled.div`
  font-size: ${FontSizes.SubPara};
  font-weight: 700;
  font-family: "Mulish";

  color: ${Theme.primary_color.light_text};
  margin-top: 4%;
  margin-bottom: 3%;
  letter-spacing: 0.05em;
  width: 90%;
  display: -webkit-box; /* Use the webkit prefix for cross-browser support */
  -webkit-line-clamp: 3; /* Adjust the number to control the number of lines displayed */
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media ${DeviceSizes.mobile} {
    text-align: center;
    margin-top: 5%;
  }
`;

const ContentContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.8%;
    margin-right: 0 0.8%;
    /* width: 100%; */
    /* margin-left: 0%;
    margin-right: 1.5%; */
    @media ${DeviceSizes.mobile} {
      /* display: block;
      width: 100%;
      margin-left: 0%;
      margin-right: 0%; */
    }
  }
`;
const ContentContainerTwo = styled(ContentContainer)`
  display: flex;
  margin-top: 1%;
  @media ${DeviceSizes.mobile} {
    display: block;
    /* width: 100%;
    margin-left: 0%;
    margin-right: 0%; */
    margin-top: 5%;
  }
`;
const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const DataContainer = styled.div`
  padding: 3%;
  padding-top: 2%;
  width: 60%;
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${DeviceSizes.bigScreen} {
    width: 50%;
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
    align-items: center;
  }
`;

const DashboardIconComponent = styled(DashboardIcon)`
  width: 150px;
  height: 100%;
  margin-right: 4%;
  margin-top: 2%;
  margin-bottom: 2%;
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;

const CardDate = styled.div`
  font-size: ${FontSizes.SubTitle};
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.01em;
  font-family: "Mulish";

  margin-top: 1%;
  margin-bottom: 0%;
`;

const BaseCardDashboardContainerSecond = styled(BaseCardDashboard)`
  && {
    @media ${DeviceSizes.mobile} {
      margin-right: 0;
      margin-bottom: 3%;
    }
  }
`;
const EmployeeInfoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 2%;
  padding-bottom: 1%;
  margin-bottom: ${(props) => (props.ismb ? "2%" : "0%")};
  margin-right: 3%;
  width: max-content;

  -webkit-box-shadow: 4px 4px 71px -39px rgba(0, 0, 0, 1);
  -moz-box-shadow: 4px 4px 71px -39px rgba(0, 0, 0, 1);
  box-shadow: 4px 4px 71px -39px rgba(0, 0, 0, 1);
  border-color: ${(props) =>
    props.activeemployeeindex === props.index
      ? Theme.primary_color.transparent
      : "transparent"};
  opacity: ${(props) =>
    props.islastcontainer ? (props.isanimationactive ? "1" : "0") : "1"};
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  transition: border-color 0.5s ease-in-out, opacity 1s ease-in-out;

  opacity: 0;
  animation-name: ${opacityChangeAnimation};
  animation-duration: 0.2s;
  animation-delay: ${(props) => props.delay / props.length}s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  &:hover {
    border-color: ${Theme.primary_color.secondary_color};
  }
  cursor: ${(props) => props.cursor ?? "pointer"};
  @media ${DeviceSizes.mobile} {
    margin-right: 0px;
  }
`;

const EmployeeImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 8px;
`;

const EmployeeNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 700;
  font-family: "Mulish";

  font-size: ${FontSizes.SubPara};
  width: 50%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;

const EmployeeContainer = styled.div`
  height: 100%;
  width: fit-content;
  overflow: auto;
  margin-top: 2%;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
  }
`;
const InnerEmployeeInfoImageAndNameContainer = styled.div`
  display: flex;
  transform: ${(props) =>
    props.isanimationstarted ? "translateX(-120px)" : "translateX(0px)"};
  transition: ${(props) =>
    props.isanimationstarted
      ? `transform ${props.animationtime / 1000}s ease-in-out`
      : "transform 0s"};
  overflow: visible;
`;
const IconifyIcon = styled(Icon)`
  position: relative;
  top: 40px;
  left: 10px;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : "10%")};
`;
const BaseCardDashboardContainerThird = styled(BaseCardDashboard)`
  && {
    /* display: ${(props) => (props.display ? "block" : "none")}; */
    height: ${(props) => props.height};
    padding-bottom: ${(props) => (props.ispb ? "3%" : "0%")};
    @media ${DeviceSizes.mobile} {
      margin-bottom: 4%;
    }
  }
`;
const BaseCardDashboardContainerTyping = styled(
  BaseCardDashboardContainerThird
)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
const BaseCardDashboardContainerFourth = styled(
  BaseCardDashboardContainerThird
)`
  padding: 1%;
`;
const ButtonContainer = styled.div`
  width: 60%;
  margin-top: ${(props) => (props.component === "1" ? "8%" : "5%")};
  cursor: pointer;
  @media ${DeviceSizes.mobile} {
    margin-left: 10%;
    margin-top: 6%;
    display: none;
  }
`;
const PopupMainContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.ispopupopen
      ? Theme.primary_color.popup_background_color
      : Theme.primary_color.transparent};
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.ispopupopen ? "1" : "-1")};
`;
const PopupContainer = styled.div`
  opacity: ${(props) => (props.ispopupopen ? "1" : "0")};
  z-index: ${(props) => (props.ispopupopen ? "1" : "-1")};
  transform: ${(props) =>
    props.ispopupopen ? "scale(1,1)" : "scale(0.7,0.7)"};
  transform-origin: bottom;
  transition: all 0.2s ease-in-out 0.1s;
  overflow: hidden;
  margin-left: 10%;
  margin-top: 5%;
`;
const PopupContentContainer = styled.div`
  width: 20%;
  height: 50%;
  padding: 2%;
  background-color: ${Theme.primary_color.white};
  opacity: 1;
  z-index: 1;
  margin-left: 35%;
  margin-top: 10%;
`;
const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PopupTitle = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Subtitle};
  color: ${Theme.primary_color.secondary_color};
`;
const IconContainer = styled.div`
  padding: 1%;
  /* background-color: ${Theme.primary_color.divider_gray}; */
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: ${(props) => (props.hideDiv ? "none" : "")};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  border-radius: 50%;
  border: 1px solid ${CommonColor.light_orange};
`;

const PopupContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HTMLNormalInput = styled.input`
  margin: 1%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: ${Theme.primary_color.secondary_color};
  color: ${Theme.primary_color.primary_color};
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${Theme.primary_color.primary_color};
  }
`;
const MuiTextField = styled(TextField)`
  && {
    margin: 5%;
    font-family: "Mulish";

    padding-left: 3%;
    & div {
      text-transform: uppercase;
    }
    & .MuiInput-underline::before {
      border-color: ${Theme.primary_color.secondary_color} !important;
      border-width: 2px;
    }
    & .MuiInput-underline::after {
      border-color: ${Theme.primary_color.secondary_color};
    }
    & label {
      padding-left: 5%;
      color: ${Theme.primary_color.primary_color};
    }
  }
`;

const IconifyIconTextfield = styled(IconifyIcon)`
  width: 100%;
`;

const MenuItemMUi = styled(MenuItem)`
  && {
    text-transform: uppercase;
    font-family: "Mulish";
  }
`;
const NameFieldIconifyIcon = styled(IconifyIcon)`
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 100%;
`;
const NameFieldContainer = styled.div`
  && {
    display: flex;
    align-items: flex-end;
  }
`;

const MuiTextFieldText = styled(MuiTextField)`
  width: 75%;
  & input + fieldset {
    border-width: 1px;
  }
`;
const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
`;
const IconifyIconLoaderBackground = styled(IconifyIcon)`
  position: absolute;
  top: 10%;
  left: 30%;
`;
const RotateAnimation = keyframes`
0%{
  transform: rotate(0deg);
}
100%{
  transform: rotate(360deg);
}
`;
const IconifyIconLoader = styled(IconifyIcon)`
  position: absolute;
  top: 7%;
  left: 33%;
`;

const VerticalScrollableAreaDashboard = styled(VerticalScrollableArea)`
  height: ${(props) => props.height};
  height: ${(props) => props.webkitHeight};
  height: ${(props) => props.mozHeight};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2%;
  position: relative;

  @media ${DeviceSizes.mobile} {
    height: 100%;
    /* margin-top: 10%; */
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;
const LeaveInfoContainer = styled.div`
  padding-right: ${(props) => (props.ismanager ? "" : "5%")};
  overflow: auto;
  height: 100%;
  padding: ${(props) => (props.ismanager ? "3%" : "")};
  margin-top: 3%;
  width: 100%;
  position: relative;
  background-color: transparent;
  @media ${DeviceSizes.mobile} {
    height: 100%;
    overflow-x: hidden;
  }
`;
const PageHeaderContainerDashboard = styled(PageHeaderContainer)`
  @media ${DeviceSizes.bigScreen} {
    width: 96%;
    margin-top: 2%;
    margin-left: 1.7%;
  }
  @media ${DeviceSizes.laptop} {
    width: ${(props) => props.lapwidth ?? ""};
    margin-left: ${(props) => props.lappyml ?? ""};
    margin-top: 0%;
    padding-bottom: 2%;
  }
  @media ${DeviceSizes.belowtablet} {
    display: flex;
    justify-content: center;
  }
  @media ${DeviceSizes.mobile} {
    display: flex;
    justify-content: center;
  }
`;
const LogoutImage = styled(CommonImages.LogoutGoodbye)`
  width: 100px;
  height: 100%;
  margin-right: 1%;
  @media ${DeviceSizes.bigScreen} {
    width: 80px;
  }
  @media ${DeviceSizes.belowtablet} {
    width: 13%;
    bottom: 5px;
    right: 9px;
  }
  @media ${DeviceSizes.mobile} {
    width: 58%;
    top: 0.3%;
    left: 48%;
    display: none;
  }
`;
const LogoutText = styled.div`
  width: 60%;
  font-family: "Mulish";

  font-weight: 600;
  font-size: ${FontSizes.Title};
  margin-top: 10%;
`;
const DashboardEmployeeName = styled(EmployeeName)`
  width: 100%;
  font-size: ${FontSizes.Title};
  margin-left: 4%;
  @media ${DeviceSizes.mobile} {
    margin-left: 8%;
  }
`;
const DashboardEmployeeLogo = styled.div`
  height: 40px;
  background-color: red;
  width: 40px;
  padding-left: 0%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
`;
const EmployeeMonthContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: ${CommonColor.primary_color};
`;
const MonthName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  font-weight: 700;
  color: ${Theme.primary_color.primary_color};
`;
const Month = styled.div``;
const Year = styled.div`
  color: ${Theme.primary_color.secondary_color};
`;
const DashboardEmployeeAttendaneNavbar = styled(EmployeeLeaveDataNavbar)`
  width: 100%;
  margin-top: 0%;
  margin-bottom: 1%;
  padding-left: 0%;
  padding-right: 0%;
`;
const AttendanceIconifyIcon = styled(IconifyIcon)`
  position: static;
  margin-left: 5%;
`;
const AttendanceBody = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2.5%;

  @media ${DeviceSizes.mobile} {
    width: 100%;
    justify-content: space-between;
  }
`;
const AttendanceSection = styled.div`
  font-family: "Mulish";

  font-weight: 400;
  font-size: ${FontSizes.SubPara};
  color: ${Theme.primary_color.primary_color};
  width: 16%;
`;
const AttendanceSectionTitle = styled.div`
  text-align: center;
  font-family: "Mulish";
  font-size: ${FontSizes.Subtitle};
`;
const AttendanceSectionValue = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  font-size: ${FontSizes.BigTitle};
  text-align: center;
  margin-top: 5%;
`;
const AttendanceDivider = styled(Divider)`
  && {
    &:hover {
    }
  }
`;
const AttendaceFoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ViewDetailsButton = styled(Button)`
  && {
    text-transform: capitalize;
    color: ${Theme.primary_color.primary_color};
    font-family: "Mulish";

    font-weight: 500;
    font-size: ${FontSizes.Para};
    width: 100%;
    height: 90%;
  }
`;
const InvisibleText = styled.div`
  opacity: 0;
`;

const CalendarIcon = styled(Icon)``;
const HeadingText = styled.div``;
const CalendarMonthPicker = styled(Picker)``;
const CalendarContainer = styled.div`
  padding: 2%;
`;
const CalendarHeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 2%;
  margin-top: 2%;
`;
const PrevButton = styled(Icon)``;
const NextButton = styled(PrevButton)``;
const CalendarBodyContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2%;
`;
const MonthAttendanceCalendar = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  font-weight: 700;
  color: ${Theme.primary_color.primary_color};
`;
const MonthNameValue = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  font-weight: 700;
  color: ${Theme.primary_color.primary_color};
  width: 20%;
  padding: 1%;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: ${Theme.primary_color.primary_color_opacity_05};
  }
`;

const EmployeeImage = styled(Image)``;

const TimeDateContainer = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Title};
  font-weight: 1000;
  color: ${Theme.primary_color.secondary_color};
  /* position: absolute;  */
  bottom: 0;
  left: 0;
  opacity: 0.4;
  @media ${DeviceSizes.mobile} {
    text-align: center;
  }
`;
const InOutTimeContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SepratorIconContainer = styled.div``;
const SeparatorIcon = styled(Icon)`
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
  width: 28px;
  margin-top: 5px;
  color: ${CommonColor.primary_color};
  opacity: 0.5;
`;
const DashboardContainerItem = styled(Grid)`
  && {
    padding: 0;
    padding-right: ${(props) => props.pr};
    padding-left: ${(props) => props.pl};
  }
`;
const DividerHorizontalSeprator = styled(Divider)`
  && {
    margin-top: ${(props) => props.mt};
    margin-left: ${(props) => props.ml};
    margin-right: ${(props) => props.mr};
    margin-bottom: ${(props) => props.mb};
    background-color: ${(props) => props.colorbg} !important;
  }
`;
const NoDataComponentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
  height: 100%;

  & svg {
    margin-right: 2%;
  }
`;
const AvatarContainer = styled(Avatar)`
  && {
    width: 30px;
    height: 30px;
    font-size: 16px;
    color: ${Theme.primary_color.primary_color};
    background-color: ${Theme.primary_color.button_gray};
  }
`;
//width: 32, height: 32, fontSize: 16
const BaseCardDashboardContainerLoader = styled(BaseCardDashboardContainer)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const DashboardEventsContainer = styled.div``;
const BirthdayDateContainer = styled.div`
  font-size: ${FontSizes.SubPara};
  font-weight: 800;
  color: ${CommonColor.primary_color};
  opacity: 0.5;
  margin-bottom: 5px;
`;
const EmployeeListNoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 1%;
`;
const IconContainerEmpList = styled.div`
  background-color: ${(props) => props.bg ?? Theme.primary_color.light_orange};
  width: ${(props) => props.width ?? "40px"};
  height: ${(props) => props.height ?? "40px"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr ?? ""};
`;
const NoDataEmpListTextHighlight = styled.span`
  color: ${Theme.primary_color.secondary_color};
  text-transform: capitalize;
`;
const NoDataEmpListText = styled.span`
  margin-left: 1%;
  font-family: "Mulish";
  font-size: 14px;
`;
const BarGraphContainer = styled.div`
  zoom: 0.7;
  position: relative;
  & svg {
    overflow: visible;
  }
  & rect {
    fill: ${CommonColor.primary_color};
  }
  & .tooltip {
    font-family: "Mulish";
    font-weight: 600;
    font-size: ${FontSizes.xxl};
    color: ${CommonColor.primary_color};
    text-transform: capitalize;
    top: -20% !important;
    left: 40% !important;
    transform: none !important;
    position: absolute !important;
    display: block;
  }
`;
const BarGraphMainContainer = styled.div`
  margin-top: 1%;
  width: 100%;
  height: 200px;
  padding-top: 1%;
  padding-bottom: 0.5%;
  margin-right: 4.5%;
`;
const BarGraphSpeed = styled.div`
  font-family: "Mulish";
  font-weight: 600;
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.secondary_color};
  text-align: center;
`;
const BorderLinearProgress = styledMui(LinearProgress)(({ theme }) => ({
  marginTop: "2%",
  height: 10,
  width: "100%",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    borderRadius: 0,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const TypingListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    height: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  @media ${DeviceSizes.belowonek} {
    width: 400px;
  }
  @media ${DeviceSizes.belowtablet} {
    width: 690px;
  }
  @media ${DeviceSizes.mobile} {
    width: 260px;
  }
`;
const RoundImageWithNameContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 90px;
  position: relative;
`;
const RoundImageContainer = styled.div`
  height: 80px;
  width: 80px;
`;
const RoundImageRankContainer = styled.div`
  background-color: ${CommonColor.primary_color};
  border-radius: 50%;
  height: 15px;
  width: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${FontSizes.VerySmallText};
  font-family: "Mulish";
  font-weight: 800;
  color: ${CommonColor.white};
  position: absolute;
  top: 6%;
  right: 12%;
`;

const RoundImageSpeedContainer = styled.div`
  border-radius: 50%;
  height: 23px;
  width: 40px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: ${FontSizes.s};
  padding-bottom: 3px;
  font-weight: 700;
  color: ${CommonColor.white};
  position: absolute;
  top: 48%;
  /* font-family: monospace; */
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0px 0px 150px 150px;
  /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 63, 80, 0.75)
  );
  @media ${DeviceSizes.mobile} {
    left: 50%;
  }
`;
const TypingListInnerContainer = styled.div`
  transform: rotate(-90deg) translate(-150px, 0px);
  transform-origin: left top;
  overflow-x: scroll;
  width: 150px;
  height: 500px;
  direction: rtl;
`;
const ScrollContainer = styled.div`
  height: 100%;
  display: flex;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* transform: rotate(-90deg); */
  /* &::-webkit-scrollbar {
    display: none;
  } */
`;
const RotateContainer = styled.div`
  transform: rotate(90deg) translateX(30px);
  direction: rtl;
`;
const TypingListSpaceReducer = styled.div`
  height: 460px;
`;
const CarouselComponent = styled.div`
  width: auto;
  height: 100%;
  margin-bottom: 2%;
  width: fit-content;
`;

const EmployeeLoaderContainer = styled.div`
  margin-top: 2%;
`;
const CardContainer = styled.div`
  margin-top: 1%;
`;
const LeaveInfoDetailContainer = styled.div`
  @media ${DeviceSizes.mobile} {
    && svg {
      width: 100%;
    }
  }
`;

const LeaveHistoryCardContainer = styled.div`
  position: relative;
  background-color: ${CommonColor.white};
  box-shadow: 0px 4px 25px -5px #00000026;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
  padding-right: 4%;
  padding-left: 4%;
  width: 100%;
  border-radius: 10px;

  opacity: 0;
  animation-name: ${opacityChangeAnimation};
  animation-duration: 0.5s;
  animation-delay: ${(props) => props.delay / props.length}s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  & .Mui-Avatar {
    height: 60px;
    width: 60px;
  }
  &:hover .absolutelyPositioned {
    opacity: 1;
  }
`;

const LeaveDetailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 3%;
  width: calc(100% - 46px);
`;

const EmployeeLeaveDurationLeaveHistroy = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  & .dateDurationLeave {
    width: unset;
  }
`;
const LeaveDurationTitle = styled.div`
  font-size: ${FontSizes.SubPara};
  font-weight: 500;
  width: max-content;
`;
const LeaveDuration = styled.div`
  font-weight: 700;
  font-size: ${FontSizes.SubPara};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  & .Wrapper {
    width: unset;
    display: flex;
    align-items: center;
  }
  @media ${DeviceSizes.mobile} {
    flex-direction: column;
    align-items: flex-start;
    & .dateDurationLeave {
      font-size: ${FontSizes.SubPara};
      width: max-content;
    }
  }
`;
const LeaveStatusContainer = styled(LeaveDetailContainer)`
  justify-content: space-between;
  align-items: center;
  width: 100px;
  & div {
    margin: 2%;
  }
`;
const LeaveTotalTime = styled(Typography)`
  && {
    font-family: "Mulish";
    font-size: ${FontSizes.SmallText};
    font-weight: 700;
    color: ${CommonColor.primary_color};
    text-overflow: ellipsis;
  }
`;
const LeaveStatus = styled.div`
  height: 20px;
  width: 70px;
  text-align: center;
  background-color: ${(props) =>
    props.status === "APPROVED"
      ? CommonColor.success_button_background
      : props.status === "REJECTED"
      ? CommonColor.declined_status_background
      : CommonColor.light_orange};
  font-family: "Mulish";
  font-size: ${9}px;
  font-weight: 700;
  color: ${(props) =>
    props.status === "APPROVED"
      ? CommonColor.green
      : props.status === "DECLINED"
      ? CommonColor.red
      : CommonColor.secondary_color};
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const EmployeeNameLeaveHistory = styled(Typography)`
  && {
    font-family: "Mulish";
    font-size: ${(props) => props.lettersize ?? FontSizes.SubPara};
    font-weight: ${(props) => props.schriftgewicht ?? "700"};
    color: ${(props) => props.schriftfarbe ?? CommonColor.primary_color};
    /* text-overflow: ellipsis; */
    width: ${(props) => props.width ?? "max-content"};
    text-align: ${(props) => props.textAlign};
    @media ${DeviceSizes.belowtablet} {
      display: flex;
      justify-content: ${(props) => props.jc ?? "center"};
      font-size: ${FontSizes.SmallText};
    }
  }
`;

const LeaveHistoryAlignContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2%;
  width: 100%;
`;

const LeaveHistroyListContainer = styled.div`
  margin-top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: ${(props) => (props.isvisible === 1 ? "" : "none")};
  & div:has(.shimmer) {
    width: 100%;
  }
`;

const LeaveStatusOptionMenu = styled.div`
  position: absolute;
  top: 20px;
  right: 5px;
  cursor: pointer;
  opacity: 0;
  //absolutelyPositioned
`;
const MenuItemTextContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.SubPara};
  font-weight: 600;
  text-transform: capitalize;
  color: ${CommonColor.primary_color};
`;
const EmployeeListStatusWrapper = styled.div`
  width: 100%;
  /* padding-left: 3%;
  padding-right: 3%; */
  & svg {
    margin-right: 0.5%;
  }
  & hr {
    margin-top: 1%;
    margin-bottom: 1%;
  }
`;
const EmployeeListContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 1%;
  gap: 0%;
`;
const EmployeeContainerAttendanceStatus = styled.div`
  margin: 1%;
  margin-bottom: 2%;
  /* width: 50px; */
  width: max-content;
  @media ${DeviceSizes.mobile} {
    width: 14%;
  }
`;
const EmployeeEnterTimeTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Mulish";
  font-size: ${FontSizes.Subtitle};
  font-weight: 500;
  color: ${CommonColor.text_gray};
`;
const EmployeeEnterTimeData = styled(EmployeeEnterTimeTitle)`
  font-size: ${FontSizes.Para};
`;
const LateDetailContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Subtitle};
  font-weight: 500;
  color: ${(props) =>
    props.isabsent
      ? CommonColor.red
      : props.isOnTime
      ? CommonColor.green
      : CommonColor.secondary_color};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const LateText = styled.div``;
const EmployeeLeaveHistoryTextWrapper = styled.div`
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
const LeaveStatusAbsentContainer = styled.div``;
const LeaveCardNoDataContainer = styled.div`
  font-size: ${FontSizes.Para};
  height: 100%;
`;
const DashboardCardContainer = styled(Box)`
  &&&& {
    opacity: 0;
    padding: 3%;
    padding-bottom: ${(props) => (props.ispb === 1 ? "3%" : "0%")};
    background-color: ${CommonColor.white};
    border-radius: 4px;
    margin-bottom: 4%;
    animation-name: ${dashboardCardLoadAnimation};
    animation-duration: 0.5s;
    /* animation-delay: ${(props) => props.delay}s; */
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    display: ${(props) => (props.isvisible === 1 ? "" : "none")};
  }
`;
const IconContainerRefresh = styled.div`
  cursor: ${(props) => props.cursor ?? ""};
  height: 100%;
`;
const BigPopupTitle = styled(PopupNavBarContainerTraining)`
  height: 100%;
`;
const CardTitleButtonsContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CardButtonIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${CommonColor.theme_background_color};
  }
`;
const TitleNotificationNum = styled.div`
  background-color: ${(props) => props.bg ?? CommonColor.secondary_color};
  color: ${CommonColor.white};
  font-family: "Mulish";
  font-weight: 600;
  font-size: ${FontSizes.SubPara};
  border-radius: 50px;
  padding-left: 1%;
  padding-right: 1%;
  margin-left: 0.5%;
  min-width: 21px;
  text-align: center;
`;
const CardTitleTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
`;
const MuiAvatarContainer = styled.div`
  margin-left: 2%;
  opacity: ${(props) => (props.zindex === 0 ? "0" : "1")};
  transition: all 0.5s ease-in-out;
  & .MuiAvatarGroup-root {
    display: flex;
    justify-content: flex-end;
    width: 200px;
  }
  & .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar {
    z-index: ${(props) => props.zindex};
  }
  & .MuiAvatar-root {
    height: 30px;
    width: 30px;
    font-size: ${FontSizes.SubPara};
    font-family: "Mulish";
    color: ${CommonColor.primary_color};
    position: relative;
    background-color: ${CommonColor.white};
    border: 1px solid ${CommonColor.primary_color};
    padding: 2%;
    transition: all 0.5s ease-in-out;
    left: 0px;
  }
  & .MuiAvatar-root:hover {
    left: -5px;
  }
`;
const EmployeeAvatarContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 1%;
  border: 2px solid ${CommonColor.primary_color};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: ${(props) => props.index * 1.5};
  z-index: ${(props) => props.index};
`;
const EmployeeAvatar = styled.img`
  width: 100%;
  height: 100%;
`;
const LeaveCardLeaveInfoContainer = styled.div`
  box-shadow: 0px 0px 56px -47px rgba(0, 0, 0, 1);
  /* top: ${(props) => `calc(${props.index}* 104)%`};
  left: 0; */
  /* position: absolute; */
  /* border: 1px solid ${CommonColor.primary_color};
  border-radius: 10px; */
  margin-bottom: 2%;
  & div {
    box-shadow: none;
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: 0px 4.466200351715088px 27.91375160217285px -5.58275032043457px #00000026;
  }
`;

const DailyLogListContainer = styled.div`
  width: 40px;
  height: 40px;
`;
const ImageGroupWrapper = styled.div`
  position: relative;
  width: max-content;
`;
const ImageGroupContainer = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
`;

export {
  ImageGroupContainer,
  ImageGroupWrapper,
  AttendaceFoot,
  AttendanceBody,
  AttendanceDivider,
  AttendanceIconifyIcon,
  AttendanceScore,
  AttendanceSection,
  AttendanceSectionTitle,
  AttendanceSectionValue,
  AvatarContainer,
  BarGraphContainer,
  BarGraphMainContainer,
  BarGraphSpeed,
  BaseCardDashboardContainer,
  BaseCardDashboardContainerFourth,
  BaseCardDashboardContainerLoader,
  BaseCardDashboardContainerSecond,
  BaseCardDashboardContainerThird,
  BaseCardDashboardContainerTyping,
  BigPopupTitle,
  BirthdayDateContainer,
  BorderLinearProgress,
  ButtonContainer,
  CalendarBodyContainer,
  CalendarContainer,
  CalendarHeaderContainer,
  CalendarIcon,
  CalendarMonthPicker,
  CardButtonIconContainer,
  CardContainer,
  CardDate,
  CardDescription,
  CardTitle,
  CardTitleButtonsContainer,
  CardTitleExtended,
  CardTitleTextContainer,
  CarouselComponent,
  ContainedButton,
  ContentContainer,
  ContentContainerTwo,
  CustomArrow,
  CustomAvatar,
  CustomGrid,
  DailyLogListContainer,
  DarkVerticalBar,
  DashboardCardContainer,
  DashboardContainer,
  DashboardContainerItem,
  DashboardEmployeeAttendaneNavbar,
  DashboardEmployeeLogo,
  DashboardEmployeeName,
  DashboardEventsContainer,
  DashboardIconComponent,
  DataContainer,
  DateRow,
  DivBlock,
  DivFlex,
  DividerHorizontalSeprator,
  DivSpaceAround,
  EmployeeAvatar,
  EmployeeAvatarContainer,
  EmployeeContainer,
  EmployeeContainerAttendanceStatus,
  EmployeeEnterTimeData,
  EmployeeEnterTimeTitle,
  EmployeeImage,
  EmployeeImg,
  EmployeeInfoContainer,
  EmployeeLeaveDurationLeaveHistroy,
  EmployeeLeaveHistoryTextWrapper,
  EmployeeListContainer,
  EmployeeListNoDataContainer,
  EmployeeListStatusWrapper,
  EmployeeLoaderContainer,
  EmployeeMonthContainer,
  EmployeeNameContainer,
  EmployeeNameLeaveHistory,
  EventDetailContainer,
  GraphContainer,
  HeadingContainer,
  HeadingText,
  HorizontalBar,
  HorizontalDivider,
  HTMLNormalInput,
  IconContainer,
  IconContainerEmpList,
  IconContainerRefresh,
  IconifyIcon,
  IconifyIconLoader,
  IconifyIconLoaderBackground,
  IconifyIconTextfield,
  ImageContainer,
  InnerEmployeeInfoImageAndNameContainer,
  InOutTimeContainer,
  InvisibleText,
  LateDetailContainer,
  LateText,
  LeaveCardLeaveInfoContainer,
  LeaveCardNoDataContainer,
  LeaveDetailContainer,
  LeaveDivBlock,
  LeaveDuration,
  LeaveDurationTitle,
  LeaveHistoryAlignContainer,
  LeaveHistoryCardContainer,
  LeaveHistroyListContainer,
  LeaveInfoCardContainer,
  LeaveInfoContainer,
  LeaveInfoDetailContainer,
  LeaveNumber,
  LeavesRemainingSubtitle,
  LeaveStatus,
  LeaveStatusAbsentContainer,
  LeaveStatusContainer,
  LeaveStatusOptionMenu,
  LeaveTotalTime,
  LeaveUsedNumber,
  LeftSideContainer,
  LoaderContainer,
  LogoutImage,
  LogoutText,
  MediumText,
  MenuItemMUi,
  MenuItemTextContainer,
  Month,
  MonthAttendanceCalendar,
  MonthName,
  MonthNameValue,
  MuiAvatarContainer,
  MuiTextField,
  MuiTextFieldText,
  NameContainer,
  NameFieldIconifyIcon,
  NameTitle,
  NextButton,
  NoDataComponentContainer,
  NoDataEmpListText,
  NoDataEmpListTextHighlight,
  NormalText,
  NumberContainer,
  OutlinedButton,
  PageHeaderContainerDashboard,
  PopupContainer,
  PopupContent,
  PopupContentContainer,
  PopupHeader,
  PopupMainContainer,
  PopupTitle,
  PrevButton,
  RightSideContainer,
  RotateContainer,
  RoundImageContainer,
  RoundImageRankContainer,
  RoundImageSpeedContainer,
  RoundImageWithNameContainer,
  ScrollContainer,
  ScrollDiv,
  SeparatorIcon,
  SepratorIconContainer,
  SmallNumber,
  SmallText,
  SmallTextExtended,
  SplitContainer,
  SubCard,
  SubContainer,
  SubHeadingContainer,
  SubtitleText,
  SubtitleTextExtended,
  SvgContainer,
  TimeDateContainer,
  TitleNotificationNum,
  TypingListContainer,
  TypingListInnerContainer,
  TypingListSpaceReducer,
  VerticalBar,
  VerticalDivider,
  VerticalScrollableAreaDashboard,
  ViewDetailsButton,
  Year,
};
