import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployeeAboutForm from "../../component/Forms/EmployeeAboutForm";
import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import ModalPopup from "../../component/Popup/ModalPopup";
import EmployeeController from "../../controller/EmployeeController";
import { setEmployeeBasicDetails } from "../../reducer/employeereduxstore";
import { LoaderWrapper } from "../../style/form.style";
import {
  BankDataContainer,
  BankDatakey,
  ContactDataEditButton,
  ContactWrapper,
  EmployeeContactContainer,
  EmployeeContactField,
  EmployeeContactFieldWrappper,
  TabTitle,
} from "../../style/profile.style";
import CommonColor from "../../utils/common.colors";
import { profileTabNames } from "../../utils/constant";
import { formatDisplayDate } from "../../utils/utils";

export default function EmployeeContact(props) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const employeeController = new EmployeeController();

  // async function getDataFromApi() {
  //   const sendData = {
  //     user_id: props.employee._id,
  //   };
  //   const emoloyeeContact = await employeeController.getPersonalDetails(
  //     sendData
  //   );
  //   dispatch(setEmployeeBasicDetails(emoloyeeContact));
  // }

  function getModalChildren() {
    return (
      <EmployeeAboutForm
        onHandleClose={PopupCloser}
        employee={props.employee}
        popoupStatus={openPopup}
      />
    );
  }
  function PopupOpener() {
    setOpenPopup(true);
  }
  function PopupCloser() {
    setOpenPopup(false);
  }
  return (
    <React.Fragment>
      <ModalPopup open={openPopup} popupSize="sm">
        {getModalChildren()}
      </ModalPopup>
      <EmployeeContactContainer>
        <TabTitle>
          Contact Information&nbsp;
          <ContactDataEditButton
            onClick={() => PopupOpener()}
            variant="outlined"
            isvisible={employee.employee_acess.basic_details ? 1 : 0}
          >
            edit
          </ContactDataEditButton>
        </TabTitle>
        <ContactWrapper>
          {loading ? (
            <LoaderWrapper
              bg={CommonColor.popup_background_color}
              zIndex={1}
              position="absolute"
            >
              <PageRefreshLoader />
            </LoaderWrapper>
          ) : (
            ""
          )}
          <BankDataContainer>
            <BankDatakey>email</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {props.employee.email}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Phone No.</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {props.employee.phone}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Emergency Contact</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.emergency_contact}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Personal Email ID</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.personal_email}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Google Fit Email ID</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.google_fit_email}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Aadhar card No.</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.aadhar_card_number}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Passport No.</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.passport_number}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Nationality</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.nationality}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Current Address</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.current_address}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Permanent Address</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.permanent_address}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Joining Date</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {formatDisplayDate(
                  employee.employee_basic_details.joining_date
                ).format("Do MMM 'YY")}{" "}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Birthday</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {formatDisplayDate(props.employee.birthday).format(
                  "Do MMM 'YY"
                )}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Marital Status</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.marital_status}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          {employee.employee_basic_details.marital_status !== "Single" ? (
            <BankDataContainer>
              <BankDatakey>Anniversary Date</BankDatakey>
              <EmployeeContactFieldWrappper>
                <EmployeeContactField>
                  {formatDisplayDate(
                    employee.employee_basic_details.anniversary_date
                  ).format("Do MMM 'YY")}{" "}
                </EmployeeContactField>
              </EmployeeContactFieldWrappper>
            </BankDataContainer>
          ) : (
            ""
          )}
          <BankDataContainer>
            <BankDatakey>Year/s’ of Experience</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.experience}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          {/* <BankDataContainer>
            <BankDatakey>Hobbies</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.hobbies}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer> */}
          <BankDataContainer>
            <BankDatakey>Gender</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.sex}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>Employee Type</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_basic_details.employee_type}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
        </ContactWrapper>
      </EmployeeContactContainer>
    </React.Fragment>
  );
}
