import { Avatar, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import {
  AssetAvailIcon,
  FlexContainer,
  IconNoUser,
} from "../../style/asset.style";
import {
  AssetDataContainer,
  AssetDetailContainer,
  AssetKey,
  AssetValue,
  AssetNavBarContainer,
} from "../../style/detailpopup.style";
import CommonColor from "../../utils/common.colors";
import { formatDisplayBirthDate } from "../../utils/utils";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import { Icon } from "@iconify/react";
import { AssetsTypes } from "../../utils/mockData";
import {
  AssetTypesImages,
  NoProfilePhoto,
  ProfileBaseUrl,
} from "../../utils/constant";

export default function ViewAssetDetails(props) {
  function getDate(date) {
    return formatDisplayBirthDate(date);
  }
  function getAssetImage() {
    if (props.assetData.asset.type === AssetsTypes[0]) {
      return AssetTypesImages[0];
    }
    if (props.assetData.asset.type === AssetsTypes[1]) {
      return AssetTypesImages[1];
    }
    if (props.assetData.asset.type === AssetsTypes[2]) {
      return AssetTypesImages[2];
    }
    if (props.assetData.asset.type === AssetsTypes[3]) {
      return AssetTypesImages[3];
    }
    if (props.assetData.asset.type === AssetsTypes[4]) {
      return AssetTypesImages[4];
    }
    if (props.assetData.asset.type === AssetsTypes[5]) {
      return AssetTypesImages[5];
    }
    if (props.assetData.asset.type === AssetsTypes[6]) {
      return AssetTypesImages[6];
    }
    if (props.assetData.asset.type === AssetsTypes[7]) {
      return AssetTypesImages[7];
    }
    return AssetTypesImages[8];
  }
  function getEmployeeImage() {
    if (props.assetData.assign_user) {
      if (props.assetData.assign_user.profile_image_url !== null) {
        return ProfileBaseUrl + props.assetData.assign_user.profile_image_url;
      }
      return NoProfilePhoto;
    }
    return <IconNoUser icon="mdi:account" color={CommonColor.primary_color} />;
  }
  return (
    <React.Fragment>
      <DialogTitle>
        <AssetNavBarContainer>
          <PopupNavBar
            onHandleClose={props.onHandleClose}
            componentType="form"
            title={"asset detail"}
          />
        </AssetNavBarContainer>
      </DialogTitle>
      <DialogContent dividers={true}>
        <FlexContainer jc="space-evenly">
          <Avatar
            src={getAssetImage()}
            sx={{ width: 70, height: 70, overflow: "visible" }}
            alt=""
          />
          <Icon icon="ic:twotone-arrow-circle-right" fontSize="35px" />
          <Avatar
            src={getEmployeeImage()}
            sx={{ width: 70, height: 70 }}
            alt=""
          />
        </FlexContainer>
        <AssetDetailContainer>
          <AssetDataContainer>
            <AssetKey>assigned to</AssetKey>
            <AssetValue>
              {props.assetData.assign_user
                ? props.assetData.assign_user.name
                : "not Assigned"}
            </AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>name</AssetKey>
            <AssetValue>{props.assetData.asset.name}</AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>type</AssetKey>
            <AssetValue>{props.assetData.asset.type}</AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>description</AssetKey>
            <AssetValue>{props.assetData.asset.description}</AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>last assigned to</AssetKey>
            <AssetValue>
              {props.assetData.last_assign_user
                ? props.assetData.last_assign_user.name
                : "not Assigned"}
            </AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>Temprory Id</AssetKey>
            <AssetValue>{props.assetData.tempId}</AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>brand + model</AssetKey>
            <AssetValue>
              {props.assetData.asset.brand + " " + props.assetData.asset.model}
            </AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>is exchanged</AssetKey>
            <AssetValue>
              {props.assetData.asset.is_exchanged === "1" ? (
                <AssetAvailIcon
                  icon="mdi:check-circle-outline"
                  color={CommonColor.green}
                />
              ) : (
                <AssetAvailIcon
                  icon="mdi:close-circle-outline"
                  color={CommonColor.red}
                />
              )}
            </AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>is working</AssetKey>
            <AssetValue>
              {props.assetData.asset.is_working === "1" ? (
                <AssetAvailIcon
                  icon="mdi:check-circle-outline"
                  color={CommonColor.green}
                />
              ) : (
                <AssetAvailIcon
                  icon="mdi:close-circle-outline"
                  color={CommonColor.red}
                />
              )}
            </AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>is replaceable</AssetKey>
            <AssetValue>
              {props.assetData.asset.is_replaceable === "1" ? (
                <AssetAvailIcon
                  icon="mdi:check-circle-outline"
                  color={CommonColor.green}
                />
              ) : (
                <AssetAvailIcon
                  icon="mdi:close-circle-outline"
                  color={CommonColor.red}
                />
              )}
            </AssetValue>
          </AssetDataContainer>
          <AssetDataContainer>
            <AssetKey>is repairable</AssetKey>
            <AssetValue>
              {" "}
              {props.assetData.asset.is_repairable === "1" ? (
                <AssetAvailIcon
                  icon="mdi:check-circle-outline"
                  color={CommonColor.green}
                />
              ) : (
                <AssetAvailIcon
                  icon="mdi:close-circle-outline"
                  color={CommonColor.red}
                />
              )}
            </AssetValue>
          </AssetDataContainer>
          <AssetDataContainer noborderbelow={1}>
            <AssetKey>purchase date</AssetKey>
            <AssetValue>
              {getDate(props.assetData.asset.purchase_date)}
            </AssetValue>
          </AssetDataContainer>
        </AssetDetailContainer>
      </DialogContent>
    </React.Fragment>
  );
}

//assetData
