import React, { useEffect, useState } from "react";
import TextInput from "../commonComponents/inputs/TextInput";
import {
  FormContainerAddPrexelite,
  LoaderWrapper,
  VerticalScrollableAreaPopup,
  InputText,
  CalendarDatePicker,
  DatePickerContainer,
  PopupNavBarContainer,
  FormLoaderContainer,
  LoaderContainerCircle,
  LoaderText,
} from "../../style/form.style";
import { Div, Span } from "../../style/common.style";
import BottomButtons from "./components/BottomButtons";
import LottieLoader from "../Loader/LottieLoader";

import { Icon } from "@iconify/react";

import { PrexelBranches, Role, Shift } from "../../utils/mockData";
import CloseIcon from "../../assets/images/closeIcon.svg";
import { Theme } from "../../style/theme/color";
import DropDownList from "../commonComponents/inputs/DropDownList";
import { ROLE, SHIFT, WING } from "../../utils/constant";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import PageRefreshLoader from "../Loader/PageRefreshLoader";
import { CommonImages } from "../../utils/common.images";
import ShowLoader from "../Loader/ShowLoader";
import Input from "../../componentsv2/common_components/Inputs/input";
import DropdownList from "../../componentsv2/common_components/DropDown/Dropdown";
import TailwindDropdownMembers from "../commonComponents/inputs/TailWindDropdownMembers";
import CountryPicker from "../../componentsv2/common_components/DropDown/CountryPicker";
import { timezoneArray } from "../../utils/constant.array";
import InputWithHalfValue from "../../componentsv2/common_components/Inputs/InputWithHalfValue";

export default function AddPrexelite(props) {
  const [isView, setisView] = useState(false);
  const [branches, setBranches] = useState([]);
  const [dataError, setDataError] = useState(false);
  const [name, setName] = useState();
  const [wing, setWing] = useState("ITW");
  const [role, setRole] = useState("Leader");
  const [shift, setShift] = useState(timezoneArray[0]);
  const [lastName, setlastName] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [BirthDate, setBirthDate] = useState("08/13/2022");
  const [PrexeliteId, setPrexeliteId] = useState();
  const [address, setAddress] = useState();
  const [height, setHeight] = useState();
  const [aadharNumber, setAadharNumber] = useState();
  const [panNumber, setPanNumber] = useState();
  const [whatsappNumber, setwhatsappNumber] = useState();
  const [parentNumber, setParentNumber] = useState();

  useEffect(() => {
    const Branches = PrexelBranches.map((branchdetail) =>
      branchdetail.branch.toUpperCase()
    );
    setBranches(Branches);
  }, []);
  async function delay(time) {
    await new Promise((resolver) => setTimeout(resolver, time));
  }

  async function onSave() {
    const data = {
      name: name,
    };
    setisView(true);
    await delay(3000);
    props.onHandleClose();
    setisView(false);
  }
  return (
    <React.Fragment>
      {isView ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Adding new Team Member</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainer>
              <PopupNavBar
                onHandleClose={props.onHandleClose}
                componentType="form"
                title="add prexelite"
              />
            </PopupNavBarContainer>
          </DialogTitle>
          <DialogContent dividers={true}>
            <FormContainerAddPrexelite className="px-4">
              <Div className="grid grid-cols-1 gap-x-6 gap-y-3 sm:gap-y-2 sm:grid-cols-6">
                <Div className="col-span-2">
                  <Input
                    title={"First Name"}
                    placeholder={"First Name"}
                    type="text"
                    icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={name}
                    error={name === ""}
                    handleChange={(newValue) => {
                      setName(newValue.target.value);
                    }}
                  />
                </Div>

                
                <Div className="col-span-2">
                  <Input
                    title={"Last Name"}
                    placeholder={"Last Name"}
                    type="text"
                    icon={<Icon icon="ooui:user-avatar-outline" />}
                    value={lastName}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setlastName(newValue.target.value);
                    }}
                    helperText={
                      dataError === "2" ? "Field Must Not be empty" : ""
                    }
                  />
                </Div>
                <Div className="col-span-2">
                  <InputWithHalfValue
                    title={"Prexelite ID"}
                    placeholder={"ID"}
                    halfDefaultInput="PREXELITE"
                    value={PrexeliteId}
                    icon={<Icon icon="mdi:briefcase-variant-outline" />}
                    error={lastName === ""}
                    onhandleChange={(newValue) => {
                      setPrexeliteId(newValue);
                    }}
                    helperText={
                      dataError === "7" ? "Field Must Not be empty" : ""
                    }
                  />
                </Div>
              </Div>

              <Div className="grid grid-cols-1 gap-x-6 gap-y-3 sm:gap-y-2 sm:mt-2 sm:grid-cols-6 mt-2">
                <Div className="col-span-6 sm:col-span-2">
                  <Input
                    title={"Email"}
                    placeholder={"Email"}
                    type="email"
                    icon={<Icon icon="ic:outline-email" />}
                    value={email}
                    error={name === ""}
                    handleChange={(newValue) => {
                      setEmail(newValue.target.value);
                    }}
                    helperText={
                      dataError === "4"
                        ? "Field Must Not be empty"
                        : dataError === "41"
                        ? "Enter Correct data"
                        : ""
                    }
                  />
                </Div>
                <Div className="col-span-6 sm:col-span-2">
                  <Input
                    title={"Mobile"}
                    placeholder={"Mobile"}
                    type="number"
                    icon={
                      <Icon
                        icon="fluent:call-48-regular"
                        height="22"
                        width="22"
                      />
                    }
                    value={mobileNumber}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setMobileNumber(newValue.target.value);
                    }}
                    helperText={
                      dataError === "3"
                        ? "Field Must Not be empty"
                        : dataError === "31"
                        ? "Enter Correct data"
                        : ""
                    }
                  />
                </Div>
                <Div className="col-span-6 sm:col-span-2">
                  <Input
                    title={"Password"}
                    placeholder={"Password"}
                    type="password"
                    icon={<Icon icon="bx:lock-alt" />}
                    value={password}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setPassword(newValue.target.value);
                    }}
                    helperText={
                      dataError === "5"
                        ? "Field Must Not be empty"
                        : dataError === "51"
                        ? "Password should be atleast 8 character long"
                        : ""
                    }
                  />
                </Div>
              </Div>

              {/* <DatePickerContainer dateAdapter={AdapterDateFns}>
                <InputText component="small-input-text">Date</InputText>
                <CalendarDatePicker
                  disableFuture
                  title="Date"
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={BirthDate}
                  onChange={(newValue) => {
                    setBirthDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextInput placeholder="Birthdate" {...params} />
                  )}
                />
              </DatePickerContainer> */}
              <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:gap-y-1 sm:mt-2 sm:grid-cols-6">
                <Div className="col-span-2">
                  <DropdownList
                    dataList={WING}
                    title={"Wing"}
                    value={wing}
                    onhandleChange={(event) => {
                      setWing(event);
                    }}
                  />
                </Div>
                <Div className="col-span-2">
                  {/* <DropdownList
                    dataList={SHIFT}
                    title={"Shift"}
                    value={shift}
                    onhandleChange={(event) => setShift(event.target.value)}
                  /> */}
                  <CountryPicker
                    dataList={timezoneArray}
                    title={"Shift"}
                    value={shift}
                    onhandleChange={(event) => setShift(event)}
                  />
                </Div>
                <Div className="col-span-2">
                  <DropdownList
                    dataList={ROLE}
                    title={"Role"}
                    value={role}
                    onhandleChange={(event) => setRole(event)}
                  />
                </Div>
              </Div>

              <Div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:gap-y-1 mt-2 sm:grid-cols-6">
                <Div className="col-span-6 sm:col-span-4">
                  <Input
                    title={"Address"}
                    placeholder={"Address"}
                    type="text"
                    icon={<Icon icon="akar-icons:location" />}
                    value={address}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setAddress(newValue.target.value);
                    }}
                    helperText={
                      dataError === "11" ? "Field Must Not be empty" : ""
                    }
                  />
                </Div>
                <Div className="col-span-6 sm:col-span-2 ">
                  <Input
                    title={"Height"}
                    placeholder={"Height in (In)"}
                    type="number"
                    icon={<Icon icon="akar-icons:height" />}
                    value={height}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setHeight(newValue.target.value);
                    }}
                    helperText={
                      dataError === "12" ? "Field Must Not be empty" : ""
                    }
                  />
                </Div>
              </Div>
              <Div className="grid grid-cols-1 gap-x-6 gap-y-3 sm:gap-y-2 sm:mt-2 sm:grid-cols-6 mt-2">
                <Div className="col-span-2">
                  <Input
                    title={"Aadhar Number"}
                    placeholder={"Aadhar Number"}
                    type="number"
                    icon={<Icon icon="bi:briefcase" />}
                    value={aadharNumber}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setAadharNumber(newValue.target.value);
                    }}
                    helperText={
                      dataError === "13" ? "Field Must Not be empty" : ""
                    }
                  />
                </Div>
                <Div className="col-span-2">
                  <Input
                    title={"PAN Number"}
                    placeholder={"PAN Number"}
                    type="number"
                    icon={<Icon icon="bi:briefcase" />}
                    value={panNumber}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setPanNumber(newValue.target.value);
                    }}
                    helperText={
                      dataError === "14" ? "Field Must Not be empty" : ""
                    }
                  />
                </Div>
                <Div className="col-span-2">
                  <Input
                    title={"WhatsApp Number"}
                    placeholder={"WhatsApp Number"}
                    type="number"
                    icon={<Icon icon="akar-icons:whatsapp-fill" />}
                    value={whatsappNumber}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setwhatsappNumber(newValue.target.value);
                    }}
                    helperText={
                      dataError === "15"
                        ? "Field Must Not be empty"
                        : dataError === "151"
                        ? "Enter Correct data"
                        : ""
                    }
                  />
                </Div>
                <Div className="col-span-2">
                  <Input
                    title={"Parents Number"}
                    placeholder={"Parents Number"}
                    type="number"
                    icon={
                      <Icon
                        icon="fluent:call-48-regular"
                        height="22"
                        width="22"
                      />
                    }
                    value={parentNumber}
                    error={lastName === ""}
                    handleChange={(newValue) => {
                      setParentNumber(newValue.target.value);
                    }}
                    helperText={
                      dataError === "16"
                        ? "Field Must Not be empty"
                        : dataError === "161"
                        ? "Enter Correct data"
                        : ""
                    }
                  />
                </Div>
              </Div>
            </FormContainerAddPrexelite>
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => onSave()}
                buttonName={"Add Prexelite"}
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
      {/* </FormLoaderContainer> */}
    </React.Fragment>
  );
}
