import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialController from "../../controller/materialController";
import { setPdfMaterial } from "../../reducer/materialreduxstore";
import { MsgContainer, MsgHeading } from "../../style/form.style";
import {
  CardPdf,
  EventIntro,
  IconOptionContainerPdf,
  MUIDivider,
  PdfBody,
  PdfEvent,
  PdfHeader,
  PdfName,
  TechnicalTrainingIcon,
  WingFor,
  WisdomWednesDayIcon,
} from "../../style/training.style";
import CommonColor from "../../utils/common.colors";
import { getShortWord } from "../../utils/utils";
import ConfirmationMenu from "../Popup/ConfirmationMenu";
import ModalPopup from "../Popup/ModalPopup";

export default function PdfFile(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const materialController = new MaterialController();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);

  function deletePdfConfirmation(event) {
    event.stopPropagation();
    setIsPopupOpen(true);
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  function getConfirmationMessage() {
    return (
      "Are you sure you want to delete training material for " +
      props.file.title
    );
  }
  async function confirmDelete() {
    setIsLoading(true);
    const sendData = {
      _id: props.file._id,
    };
    const deleteRes = await materialController.deleteMaterial(sendData);
    if (deleteRes) {
      //success
      const sucessMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>successfully deleted training material</sucessMsg>
        </MsgContainer>
      );

      setTimeout(() => {
        setIsPopupOpen(false);
        handleAlert(sucessMsg, "success");
      }, 1000);
      const trainingData = await materialController.getEmployeePdf();
      dispatch(setPdfMaterial(trainingData));
    } else {
      //error
      const errorMsgF = (
        <MsgContainer>
          <MsgHeading>error</MsgHeading>
          <sucessMsg>please try again later</sucessMsg>
        </MsgContainer>
      );
      setTimeout(() => {
        setIsPopupOpen(false);
        handleAlert(errorMsgF, "error");
      }, 1000);
    }
  }

  function getModalChildren() {
    return (
      <ConfirmationMenu
        title={getConfirmationMessage()}
        isShow={isLoading}
        yesButtonName={"yes"}
        onClickYesButton={() => confirmDelete()}
        noButtuonName={"no"}
        onClicknoButton={() => setIsPopupOpen(false)}
        color={CommonColor.primary_color}
      />
    );
  }
  return (
    <React.Fragment>
      <ModalPopup open={isPopupOpen}>{getModalChildren()}</ModalPopup>
      <CardPdf
        onClick={() => props.OpenPdf(props.index)}
        popuptype={props.PopupType}
        isactive={props.showActive && props.ActiveIndex === props.index}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        index={props.index}
        length={props.length}
      >
        <PdfHeader>
          <EventIntro>
            {props.file.department === "All" ? (
              <WisdomWednesDayIcon />
            ) : props.file.department === "IT" ? (
              <TechnicalTrainingIcon />
            ) : props.file.department === "RW" ? (
              <WisdomWednesDayIcon />
            ) : (
              <WisdomWednesDayIcon />
            )}
            <PdfEvent>
              {props.file.type === "General"
                ? "Common Training"
                : props.file.type}
            </PdfEvent>
            {employee.employee_acess.training_material ? (
              <IconOptionContainerPdf
                isvisible={isHovered ? 1 : 0}
                onClick={(event) => deletePdfConfirmation(event)}
              >
                <Icon
                  icon="ic:round-delete"
                  color={CommonColor.primary_color}
                  fontSize={10}
                />
              </IconOptionContainerPdf>
            ) : (
              ""
            )}
          </EventIntro>
          <WingFor>{getShortWord(props.file.department)}</WingFor>
        </PdfHeader>
        <MUIDivider />
        <PdfBody>
          <PdfName>{props.file.title}</PdfName>
          {/* <EventCoordinator>by :{props.file.added_by}</EventCoordinator> */}
        </PdfBody>
      </CardPdf>
    </React.Fragment>
  );
}

/*
<ConductingPersonContainer>
          <ConductingPersonTitle>Conducted By:</ConductingPersonTitle>
          <ConductingPersonName>Kartik Rameshan</ConductingPersonName>
        </ConductingPersonContainer>
 */

/*  <PdfHeader>
        <LeaveStatus leaveStatus={"pending"} leaveStatusText={"IT"} />
        <PdfEvent>wisdom wednesday</PdfEvent>
      </PdfHeader>
      <FileIcon  fill={getColor()} FileIcon/>
      <PdfTitle>Decision making skills</PdfTitle>
      <PdfFooter>
        <PdfUploader>
          <PdfUploaderTitle>Uploaded by: </PdfUploaderTitle> Kartik Rameshan
        </PdfUploader>
        <IconContainerButton></IconContainerButton>
      </PdfFooter> */

/*
      
        IconContainerButton,
  PdfDeleteButton,
  PdfUploaderTitle,
  PdfUploader,
    PdfIcon,
  PdfFooter,
  PdfTitle,
  PdfWing,
      */
