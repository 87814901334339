/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  ButtonContainer,
  ConductingPersonContainer,
  ConductingPersonName,
  ConductingPersonTitle,
  DateContainer,
  DeletePdfButton,
  DividinLineContainer,
  EditDeletButtonContainer,
  EventBranch,
  EventContainer,
  EventContainerDivider,
  EventDataContainer,
  EventDate,
  EventTime,
  IsToday,
  TimeAndBranchContainer,
  Title,
  TitleAndConductorContainer,
  TitleEditButtonContainer,
} from "../../style/event.style";

import { Divider } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import AddEvent from "../../component/Forms/AddEvent";
import ConfirmationMenu from "../../component/Popup/ConfirmationMenu";
import ModalPopup from "../../component/Popup/ModalPopup";
import EventController from "../../controller/eventController";
import {
  deleteCurrentDayEvent,
  deleteEvent,
} from "../../reducer/eventreduxstore";
import { IconifyIcon } from "../../style/employee.style";
import { Theme } from "../../style/theme/color";
import { CommonString } from "../../utils/common.string";
import { TwelveHourFormatAmPm, formatDisplayDate } from "../../utils/utils";
import { FlexContainer } from "../../style/asset.style";

export default function EventCardHome(props) {
  const { enqueueSnackbar } = useSnackbar();
  const employee = useSelector((state) => state.employee);
  const [openPopup, setOpenPopup] = useState(false);
  const [isEditPopup, setIsPopupEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [popupSize, setPopupSize] = useState("xs");
  const dispatch = useDispatch();
  const eventController = new EventController();

  function closePopup() {
    setOpenPopup(false);
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  async function onDelete() {
    props.setSelectedId(props.ele._id);
    setOpenPopup(true);
    setIsPopupEdit(false);
    setPopupSize("sm");
  }
  async function onConfirmDelete() {
    setLoader(true);
    const resp = await eventController.onDeleteEvent({ _id: props.selectedId });
    const successMsg = (
      <div>
        <div>success</div>
        <div>event deleted sucessfully</div>
      </div>
    );
    const errorMsg = (
      <div>
        <div>error</div>
        <div>try again after some time</div>
      </div>
    );
    setTimeout(() => {
      setLoader(false);
      if (resp) {
        setOpenPopup(false);
        handleAlert(successMsg, "success");

        return;
      }
      setOpenPopup(false);
      handleAlert(errorMsg, "error");
    }, 500);
    dispatch(deleteCurrentDayEvent(props.selectedId));
    dispatch(deleteEvent(props.selectedId));
    props.setSelectedId("");
    closePopup();
  }

  function onEditEvent() {
    props.setSelectedEvent(props.data);
    setOpenPopup(true);
    setIsPopupEdit(true);
    setPopupSize("xs");
  }

  function getModalChildren() {
    if (isEditPopup) {
      return (
        <AddEvent
          mobileheight="80"
          onHandleClose={closePopup}
          data={props.ele}
          isEdit={true}
          closePopup={closePopup}
        />
      );
    }
    return (
      <ConfirmationMenu
        isShow={loader}
        title={CommonString.confirmEventDeleteTitle}
        yesButtonName="Yes"
        onClickYesButton={() => onConfirmDelete()}
        noButtuonName="No"
        onClicknoButton={() => closePopup()}
      ></ConfirmationMenu>
    );
  }
  function isPreviousDateSame() {
    if (props.index === 0) return;
    if (
      formatDisplayDate(props.ele.event_date).format("Do MMM 'YY") ===
      formatDisplayDate(props.prevEle.event_date).format("Do MMM 'YY")
    ) {
      return true;
    }
    return false;
  }

  return (
    <React.Fragment key={props.index}>
      <ModalPopup
        open={openPopup}
        popupSize={popupSize}
        issmallScreen={popupSize === "xs" ? false : true}
      >
        {getModalChildren()}
      </ModalPopup>
      <EventDataContainer>
        <DateContainer>
          {isPreviousDateSame() ? (
            ""
          ) : (
            <EventDate>
              {formatDisplayDate(props.ele.event_date).format("Do MMM 'YY")}
            </EventDate>
          )}
          <IsToday>{props.ele.isdateToday ? "(Today)" : ""}</IsToday>
        </DateContainer>
        <EventContainer>
          <TimeAndBranchContainer>
            <EventTime>
              {" "}
              {TwelveHourFormatAmPm(
                moment(props.ele.event_time).tz("Asia/Kolkata").format()
              )}
            </EventTime>
            <EventBranch isCard={1}>{props.ele.department}</EventBranch>
          </TimeAndBranchContainer>
          <TitleAndConductorContainer iscard={props.isCard}>
            <FlexContainer flexDirection="column" verticalAlign="start">
              <TitleEditButtonContainer iscard={props.isCard}>
                <Title>{props.ele.event_name}</Title>
                <ButtonContainer>
                  {employee.employee_acess.events === "1" ? (
                    <React.Fragment>
                      <EditDeletButtonContainer>
                        <DeletePdfButton onClick={() => onDelete()}>
                          <IconifyIcon
                            icon="material-symbols:delete-rounded"
                            color={Theme.primary_color.primary_color}
                          />
                        </DeletePdfButton>
                      </EditDeletButtonContainer>
                      <EditDeletButtonContainer>
                        <DeletePdfButton onClick={() => onEditEvent()}>
                          <IconifyIcon
                            icon="material-symbols:edit-rounded"
                            color={Theme.primary_color.primary_color}
                          />
                        </DeletePdfButton>
                      </EditDeletButtonContainer>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </ButtonContainer>
              </TitleEditButtonContainer>
              <ConductingPersonContainer>
                <ConductingPersonTitle>Conducted By:</ConductingPersonTitle>
                <ConductingPersonName>
                  {props.ele.user.name}
                </ConductingPersonName>
              </ConductingPersonContainer>
            </FlexContainer>
          </TitleAndConductorContainer>
        </EventContainer>
        {props.ele.is_show_divider ? (
          <DividinLineContainer>
            <Divider sx={EventContainerDivider} />
          </DividinLineContainer>
        ) : (
          ""
        )}
      </EventDataContainer>
    </React.Fragment>
  );
}
