import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import DropDownList from "../../componentsv2/common_components/DropDown/Dropdown";
import Input from "../../componentsv2/common_components/Inputs/input";
import { Div } from "../../style/common.style";
import { Theme } from "../../style/theme/color";
import { TrainingType, WINGWITHALL } from "../../utils/constant";
import BottomButtons from "./components/BottomButtons";
import Textarea from "../../componentsv2/common_components/Inputs/textArea";

export default function AddPdfData(props) {
  useEffect(() => {
    if (!props.isEdit) return;
    if (props.isEdit) {
      if (props.isEdit) {
        setPdfTitle(props.data.name);
        setPdfDescription(props.data.description);
      }
    }
  }, []);

  const [pdfTitle, setPdfTitle] = useState("");
  const [pdfDescription, setPdfDescription] = useState("");
  const [wing, setWing] = useState("RW");
  const [eventType, setEventType] = useState("Wisdom Wednesday");

  function getEventType() {
    if (eventType === "Everyone") return "ALL";
    return eventType;
  }

  return (
    <React.Fragment>
      <Input
        title={"Pdf Title"}
        placeholder={"Title"}
        icon={<Icon icon="ooui:user-avatar-outline" />}
        value={pdfTitle}
        error={pdfTitle === ""}
        handleChange={(newValue) => {
          setPdfTitle(newValue.target.value);
        }}
      />
      {props.isPolicy && (
        <Textarea
          title={"Pdf Description"}
          placeholder={"Description"}
          icon={<Icon icon="ooui:user-avatar-outline" />}
          value={pdfDescription}
          error={pdfDescription === ""}
          handleChange={(newValue) => {
            setPdfDescription(newValue.target.value);
          }}
        />
      )}
      {!props.isPolicy && (
        <Div className="mt-2">
          <DropDownList
            dataList={WINGWITHALL}
            title={"Wing"}
            value={wing}
            onhandleChange={(event) => setWing(event)}
            textTransform={"capitalize"}
            componentType="form"
          />
        </Div>
      )}
      {!props.isPolicy && (
        <Div className="mt-2">
          <DropDownList
            dataList={TrainingType}
            title={"Type"}
            value={eventType}
            onhandleChange={(event) => setEventType(event)}
            textTransform={"capitalize"}
            componentType="form"
          />
        </Div>
      )}
      <Div className="mt-2">
        <BottomButtons
          onSave={
            props.isPolicy
              ? !props.isEdit
                ? () =>
                    props.onSave({
                      pdfTitle: pdfTitle,
                      pdfDescription: pdfDescription,
                    })
                : () =>
                    props.onUpdate({
                      pdfTitle: pdfTitle,
                      pdfDescription: pdfDescription,
                    })
              : () =>
                  props.onSave({
                    pdfTitle: pdfTitle,
                    wing: wing,
                    eventType: getEventType(),
                  })
          }
          buttonName={props.isEdit ? "Update" : "Submit"}
          componentType="form"
          bgcolor={Theme.primary_color.primary_color}
        />
      </Div>
    </React.Fragment>
  );
}
