import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect } from "react";
import { PopupNavBarContainer } from "../../style/navbar.style";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
// import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Div, TypoGraphy } from "../../style/common.style";
import BottomButtons from "./components/BottomButtons";
// import { LocalizationProvider, TimePicker } from "@mui/lab";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Icon } from "@iconify/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import Input from "../../componentsv2/common_components/Inputs/input";
import AttendanceController from "../../controller/attendanceController";
import { MsgContainer, MsgHeading } from "../../style/form.style";
import { TimePickerContainer } from "../../style/office.timing.style";
import { FontSizes } from "../../style/theme/font";
import { LoadingBgContainer } from "../../style/training.style";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import ShowLoader from "../Loader/ShowLoader";
import { setShift as SetShift } from "../../reducer/attendancereduxstore";

export default function OfficeTimingForm(props) {
  const [shift, setShift] = useState("");
  const [inTime, setInTime] = useState(dayjs());
  const [outTime, setOutTime] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const attendanceController = new AttendanceController();
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (props.data) {
      setShift(props.data.shift_type);
      setInTime(dayjs(props.data.in_time));
      setOutTime(dayjs(props.data.out_time));
    }
  }, [dashboard.isModalOpen, props.localModalOpen]);
  function checkError() {
    if (shift === "") return true;
    return false;
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  function closePopup() {
    setLoading(false);
    setShift("");
    setInTime(dayjs());
    setOutTime(dayjs());
    props.onHandleClose();
  }

  async function onSave() {
    const isError = checkError();
    if (isError) return;
    setLoading(true);
    const in_time = new Date(inTime).toISOString();
    const out_time = new Date(outTime).toISOString();
    const sendData = {
      shift_type: shift,
      in_time: in_time,
      out_time: out_time,
    };
    const resp = await attendanceController.addShift(sendData);
    if (resp) {
      const officeTimings = await attendanceController.getShift();

      dispatch(SetShift(officeTimings));
      setTimeout(() => {
        setLoading(false);
        const sucessMsg = (
          <MsgContainer>
            <MsgHeading>success</MsgHeading>
            <sucessMsg>office timing added sucessfully</sucessMsg>
          </MsgContainer>
        );
        handleAlert(sucessMsg, "success");
      }, 2000);
      closePopup();
      return;
    }
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>error while adding office timing</sucessMsg>
        <sucessMsg>Please try again later</sucessMsg>
      </MsgContainer>
    );
    closePopup();
    handleAlert(errorMsg, "error");
  }
  async function updateData() {
    const isError = checkError();
    if (isError) return;
    setLoading(true);
    const in_time = new Date(inTime).toISOString();
    const out_time = new Date(outTime).toISOString();
    const sendData = {
      _id: props.data._id,
      shift_type: shift,
      in_time: in_time,
      out_time: out_time,
    };
    const resp = await attendanceController.updateShift(sendData);
    if (resp) {
      const officeTimings = await attendanceController.getShift();

      dispatch(SetShift(officeTimings));

      setTimeout(() => {
        setLoading(false);
        const sucessMsg = (
          <MsgContainer>
            <MsgHeading>success</MsgHeading>
            <sucessMsg>office timing updated sucessfully</sucessMsg>
          </MsgContainer>
        );
        handleAlert(sucessMsg, "success");
      }, 2000);
      closePopup();
      return;
    }
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>error while updating office timing</sucessMsg>
        <sucessMsg>Please try again later</sucessMsg>
      </MsgContainer>
    );
    closePopup();
    handleAlert(errorMsg, "error");
  }
  return (
    <React.Fragment>
      <DialogTitle>
        <PopupNavBarContainer>
          <PopupNavBar
            onHandleClose={closePopup}
            componentType="form"
            title={props.data ? "update shift" : "add shift"}
          />
        </PopupNavBarContainer>
      </DialogTitle>
      <DialogContent dividers={true}>
        {loading ? (
          <LoadingBgContainer>
            {" "}
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
          </LoadingBgContainer>
        ) : (
          ""
        )}
        <Div className="px-2">
          <Input
            title={"Shift Type"}
            placeholder={"Shift Type"}
            icon={<Icon icon="ic:outline-access-time" />}
            value={shift}
            handleChange={(event) => setShift(event.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePickerContainer>
              <TypoGraphy
                color={CommonColor.text_gray}
                fontSize={FontSizes.SubPara}
              >
                Select in Time
              </TypoGraphy>
              <TimePicker
                defaultValue={null}
                value={inTime}
                label=""
                onChange={(event) => setInTime(event)}
              />
            </TimePickerContainer>
            <TimePickerContainer>
              <TypoGraphy
                color={CommonColor.text_gray}
                fontSize={FontSizes.SubPara}
              >
                Select Out Time
              </TypoGraphy>
              <TimePicker
                defaultValue={null}
                label=""
                value={outTime}
                onChange={(event) => setOutTime(event)}
              />
            </TimePickerContainer>
          </LocalizationProvider>
        </Div>
      </DialogContent>
      <DialogActions>
        <Div>
          <BottomButtons
            onSave={props.data ? updateData : onSave}
            buttonName={props.data ? "Update Shift" : "Add Shift"}
          />
        </Div>
      </DialogActions>
    </React.Fragment>
  );
}
