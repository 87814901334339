import React from "react";
import { AvatarImage } from "../../style/common.style";

export default function ImageAvatar(props) {
  return (
    <React.Fragment>
      <AvatarImage
        src={props.src}
        alt={props.alt ?? ""}
        onError={(event) => {
          event.currentTarget.onerror = null;
          event.currentTarget.src = props.defaultSrc;
        }}
        padding={props.padding}
        loading="lazy"
        {...props}
      />
    </React.Fragment>
  );
}
