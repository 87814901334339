import { Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import transition from "react-element-popper/animations/transition";
import { useSelector } from "react-redux";
import { ShimmerTable } from "react-shimmer-effects";
import AttendanceController from "../../controller/attendanceController";

import { Icon } from "@iconify/react";
import { DateObject } from "react-multi-date-picker";
import { TableVirtuoso } from "react-virtuoso";
import CustomInput from "../../screen/Profile/CustomInput";
import Profilev2 from "../../screen/Profile/Profilev2";
import { FlexContainer } from "../../style/asset.style";
import { TypoGraphy } from "../../style/common.style";
import { DatePickerCalendarProfile } from "../../style/profile.style";
import {
  CalendarContainer,
  ReportContainer,
  ReportProfileCloseIconContainer,
  ReportProfileContainer,
  TableDataNumber,
} from "../../style/report.style";
import {
  AccessTable,
  AccessTableBody,
  AccessTableData,
  AccessTableHead,
  AccessTableHeader,
  AccessTableRow,
  CircularContainer,
  TextContainer,
} from "../../style/style.access.mgmt.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { monthShort } from "../../utils/constant.array";
import { useWindowSize } from "../commonComponents/hooks/getWindowDimension";
import dayjs from "dayjs";
import xlsx from "json-as-xlsx";

export default function AttendanceReports(props) {
  const windowWidth = useWindowSize().width;
  const [attendanceData, setAttendanceData] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState();
  const [monthAndYear, setMonthAndYear] = useState(new DateObject());
  const attendanceController = new AttendanceController();
  const cachedAttendanceData = useMemo(() => attendanceData);
  const employeeList = useSelector((state) => state.employee.employee_list);
  useEffect(() => {
    getAttendance(monthAndYear);
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error." ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });

    return window.removeEventListener("error", () => {});
  }, []);

  async function getAttendance(date) {
    const sendData = {
      month: date.month.name.toLowerCase(),
      year: date.year,
    };
    const data = await attendanceController.getAllAttendance(sendData);
    data.sort((employeeFirst, employeeSecond) => {
      return employeeFirst.user.employee_id.localeCompare(
        employeeSecond.user.employee_id
      );
    });
    setAttendanceData(data);
  }

  function getAppliedLeaveCount(data) {
    if (data === 0) return "--";
    return data;
  }
  function onClickEmployeeName(employee) {
    ActiveEmployeeSetter(employee);
    setIsFullScreen(true);
  }
  function ActiveEmployeeSetter(employee) {
    const activeEmployee = getActiveEmployee(employee.user._id);
    setActiveEmployee(activeEmployee);
  }
  function getActiveEmployee(employeeId) {
    const activeEmployee = employeeList.filter(
      (employee) => employee._id === employeeId
    )[0];
    return activeEmployee;
  }
  function closeEmployeeDetail() {
    setIsFullScreen(false);
    setActiveEmployee();
  }

  function downloadToXLSX() {
    //attendanceData
    let data = [
      {
        sheet: "Attendance",
        columns: [
          { label: "Serial Number", value: (row) => row.sr_no },
          { label: "Prexelite Id", value: (row) => row.prexelite_id },
          { label: "Name", value: (row) => row.name },
          { label: "Present", value: (row) => row.present },
          { label: "Late", value: (row) => row.late },
          { label: "Absent", value: (row) => row.absent },
          { label: "Remaining Leave", value: (row) => row.remaining_leave },
        ],
        content: attendanceData.map((employee, index) => {
          return {
            sr_no: index + 1,
            prexelite_id: "PREXELITE" + employee.user.employee_id.slice(-6),
            name: employee.username,
            present: employee.present_day,
            late: employee.late_come,
            absent: employee.absent,
            remaining_leave: employee.leave_credit,
          };
        }),
      },
    ];

    let settings = {
      fileName:
        dayjs(monthAndYear).format("MMMM") +
        "" +
        dayjs(monthAndYear).year() +
        " Attendance Report", // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      RTL: false, // Display the columns from right-to-left (the default value is false)
    };
    xlsx(data, settings);
  }

  return (
    <>
      {isFullScreen && !!activeEmployee ? (
        <ReportProfileContainer>
          <Profilev2
            employeeDetail={activeEmployee}
            isLoaded={true}
            isPopupOpen={isFullScreen && !!activeEmployee}
          />
          <Tooltip title="Close" placement="bottom">
            <ReportProfileCloseIconContainer
              onClick={() => closeEmployeeDetail()}
            />
          </Tooltip>
        </ReportProfileContainer>
      ) : (
        <ReportContainer>
          <CalendarContainer>
            <FlexContainer>
              <Tooltip title="Go Back" arrow={true}>
                <Icon
                  icon="ic:round-keyboard-backspace"
                  fontSize={"20px"}
                  color={CommonColor.primary_color}
                  onClick={() => props.onScreenClose()}
                />
              </Tooltip>
              <TypoGraphy fontSize={FontSizes.Title} weight="600">
                Attendance
              </TypoGraphy>
              <Tooltip title="Download" arrow={true}>
                <Icon
                  icon="material-symbols:download"
                  fontSize={"20px"}
                  color={CommonColor.primary_color}
                  onClick={() => downloadToXLSX()}
                />
              </Tooltip>
            </FlexContainer>
            <DatePickerCalendarProfile
              value={monthAndYear}
              months={monthShort}
              onChange={(e) => {
                setMonthAndYear(e);
                getAttendance(e);
              }}
              format="MMM YYYY"
              buttons={false}
              className="custom-calendar"
              maxDate={Date.now()}
              title={(date) =>
                date.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })
              }
              animations={[transition()]}
              onlyMonthPicker
              calendarPosition="bottom-center"
              // minDate={new Date().getFullYear()}
              // maxDate={new Date().getFullYear()}
              showOtherDays
              render={<CustomInput value={monthAndYear} />}
            />
          </CalendarContainer>
          {attendanceData === null ? (
            <ShimmerTable row={5} col={5} />
          ) : (
            <AccessTable isvisible={0}>
              <TableVirtuoso
                style={{ height: "100%", width: "100%" }}
                data={cachedAttendanceData}
                totalCount={attendanceData.length}
                height={"100%"}
                width={"100%"}
                rowHeight={40}
                fixedItemHeight={40}
                fixedHeaderContent={() => (
                  <React.Fragment>
                    <AccessTableHeader>
                      {windowWidth > 600 ? (
                        <React.Fragment>
                          <AccessTableHead width="5%" tabWidth="10%">
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                            >
                              Sr. No.
                            </TypoGraphy>
                          </AccessTableHead>
                          <AccessTableHead
                            width="16%"
                            horizontalAlign="start"
                            tabWidth="20%"
                          >
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                            >
                              Prexelite Id
                            </TypoGraphy>
                          </AccessTableHead>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      <AccessTableHead
                        width="25%"
                        horizontalAlign="left"
                        mobileWidth="52%"
                      >
                        <TypoGraphy
                          variant="body2"
                          weight="bold"
                          color={CommonColor.primary_color}
                        >
                          Name
                        </TypoGraphy>
                      </AccessTableHead>

                      <AccessTableHead width="16%" horizontalAlign="center">
                        <TypoGraphy
                          variant="body2"
                          weight="bold"
                          color={CommonColor.primary_color}
                          textAlign="center"
                        >
                          Present
                        </TypoGraphy>
                      </AccessTableHead>
                      <AccessTableHead width="16%" horizontalAlign="center">
                        <TypoGraphy
                          variant="body2"
                          weight="bold"
                          color={CommonColor.primary_color}
                          textAlign="center"
                        >
                          Late
                        </TypoGraphy>
                      </AccessTableHead>
                      <AccessTableHead width="16%" horizontalAlign="center">
                        <TypoGraphy
                          variant="body2"
                          weight="bold"
                          color={CommonColor.primary_color}
                          textAlign="center"
                        >
                          Absent
                        </TypoGraphy>
                      </AccessTableHead>
                      {windowWidth > 600 ? (
                        <AccessTableHead width="16%">
                          <TypoGraphy
                            variant="body2"
                            weight="bold"
                            color={CommonColor.primary_color}
                            width="100%"
                          >
                            Remaining Leave
                          </TypoGraphy>
                        </AccessTableHead>
                      ) : (
                        ""
                      )}
                    </AccessTableHeader>
                  </React.Fragment>
                )}
                itemContent={(index, employee) => (
                  <AccessTableBody key={index}>
                    <React.Fragment>
                      <AccessTableRow
                        index={index}
                        bg={CommonColor.white}
                        length={12}
                      >
                        <AccessTableData width="5%" tabWidth="10%" ishidden={1}>
                          {index + 1}
                        </AccessTableData>
                        <AccessTableData
                          width="16%"
                          tabWidth="20%"
                          horizontalAlign="flex-start"
                          ishidden={1}
                        >
                          <TableDataNumber>PREXELITE</TableDataNumber>
                          <TableDataNumber color={CommonColor.secondary_color}>
                            {employee.user.employee_id.slice(-6)}
                          </TableDataNumber>
                        </AccessTableData>

                        <AccessTableData
                          width="25%"
                          mobileWidth="52%"
                          cursor="pointer"
                          horizontalAlign="flex-start"
                          textTransform="capitalize"
                          onClick={() => onClickEmployeeName(employee)}
                        >
                          {employee.username.toLowerCase()}
                        </AccessTableData>
                        <AccessTableData width="16%">
                          {getAppliedLeaveCount(employee.present_day)}
                        </AccessTableData>
                        <AccessTableData width="16%">
                          <CircularContainer
                            ishighlight={employee.late_come > 3}
                          >
                            <TextContainer>
                              {getAppliedLeaveCount(employee.late_come)}
                            </TextContainer>
                          </CircularContainer>
                        </AccessTableData>
                        <AccessTableData textAlign="center" pr="4%" width="16%">
                          {getAppliedLeaveCount(employee.absent)}
                        </AccessTableData>
                        <AccessTableData width="16%" ishidden={1}>
                          {employee.leave_credit}
                        </AccessTableData>
                      </AccessTableRow>
                    </React.Fragment>
                  </AccessTableBody>
                )}
              />{" "}
            </AccessTable>
          )}
        </ReportContainer>
      )}
    </>
  );
}
