import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  IconifyIcon,
  PlaceholderSearchPosition,
  TextContainerPlaceholder,
} from "../../../style/employee.style";
import {
  PlaceholderSearchPositionTrain,
  SearchBarTrain,
  SearchContainerNavbar,
  SearchIconContainerTrain,
  SearchIconInnerContainer,
} from "../../../style/training.style";

export default function SearchBar(props) {
  const ref = useRef(0);
  useEffect(() => {
    document.addEventListener("keydown", setFocusOnInput);
    return () => {
      document.removeEventListener("keydown", setFocusOnInput);
    };
  }, [props.searchValue]);

  function setFocusOnInput(event) {
    if (event.keyCode === 70 && event.ctrlKey) {
      //user pressed ctrl+f
      event.preventDefault();
      ref.current.focus();
    }
  }
  return (
    <SearchContainerNavbar>
      <SearchIconContainerTrain>
        <SearchIconInnerContainer>
          <IconifyIcon icon="il:search" color="white" />
        </SearchIconInnerContainer>
      </SearchIconContainerTrain>
      <SearchBarTrain
        value={props.searchValue}
        placeholder="search"
        onChange={(event) => props.onSearch(event.target.value)}
        ref={ref}
      />
      <PlaceholderSearchPositionTrain
        isvisible={props.searchValue.length === 0 ? 1 : 0}
      >
        <TextContainerPlaceholder>Ctrl+F</TextContainerPlaceholder>
      </PlaceholderSearchPositionTrain>
    </SearchContainerNavbar>
  );
}
