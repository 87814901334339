export default class CSSController {
  heightPositonSetter = (
    inputRef,
    ProfileScreenScrollHeightBreakPoint,
    UserProfilePositionSetter
  ) => {
    if (
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.initial
    ) {
      UserProfilePositionSetter("initial");
    } else if (
      inputRef.current.scrollTop >
        ProfileScreenScrollHeightBreakPoint.initial &&
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.second
    ) {
      UserProfilePositionSetter("second");
    } else if (
      inputRef.current.scrollTop > ProfileScreenScrollHeightBreakPoint.second &&
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.third
    ) {
      UserProfilePositionSetter("third");
    } else if (
      inputRef.current.scrollTop > ProfileScreenScrollHeightBreakPoint.third &&
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.middle
    ) {
      UserProfilePositionSetter("middle");
    } else if (
      inputRef.current.scrollTop > ProfileScreenScrollHeightBreakPoint.middle &&
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.fifth
    ) {
      UserProfilePositionSetter("fifth");
    } else if (
      inputRef.current.scrollTop > ProfileScreenScrollHeightBreakPoint.fifth &&
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.sixth
    ) {
      UserProfilePositionSetter("sixth");
    } else if (
      inputRef.current.scrollTop > ProfileScreenScrollHeightBreakPoint.sixth &&
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.seventh
    ) {
      UserProfilePositionSetter("seventh");
    } else if (
      inputRef.current.scrollTop >
        ProfileScreenScrollHeightBreakPoint.seventh &&
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.eight
    ) {
      UserProfilePositionSetter("eigth");
    } else if (
      inputRef.current.scrollTop > ProfileScreenScrollHeightBreakPoint.eight &&
      inputRef.current.scrollTop <= ProfileScreenScrollHeightBreakPoint.ninth
    ) {
      UserProfilePositionSetter("ninth");
    } else if (
      inputRef.current.scrollTop > ProfileScreenScrollHeightBreakPoint.ninth
    ) {
      UserProfilePositionSetter("tenth");
    }
  };
  calculateContainerSize = (length) => {
    if (length === 3) {
      return "100%";
    } else if (length === 2) {
      return "100%";
    } else if (length === 1) {
      return "100%";
    } else if (length > 3) {
      return "73vh";
    } else {
      return "10vh";
    }
  };
  CalculateContainerSize = (
    length,
    LeaveInfoContainerSize,
    updateContainerSize
  ) => {
    if (LeaveInfoContainerSize === undefined) {
      if (length > 3) {
        updateContainerSize("81vh");
      } else if (length === 0) {
        updateContainerSize("81vh");
      } else {
        updateContainerSize("100%");
      }
    }
    return;
  };
}
