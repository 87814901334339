import styled from "styled-components";
import CommonColor from "../utils/common.colors";

const ArrowContainer = styled.div`
  width: 20px;
`;
const ArrowTop = styled.div`
  height: 1.5px;
  width: 9px;
  background-color: ${(props) =>
    props.ishovered === 1 ? "transparent" : CommonColor.secondary_color};
  transform-origin: bottom right;
  rotate: 45deg;
  translate: 4% -8%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-top: 8%;
  &::after {
    content: "";
    display: block;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 100%;
    height: 1.5px;
    width: ${(props) => (props.ishovered === 1 ? "100%" : "0%")};
    background-color: ${CommonColor.secondary_color};
    transition: all 0.2s ease-in;
    transition-delay: ${(props) => (props.ishovered === 1 ? "0s" : "0.2s")};
  }
`;
const ArrowBottom = styled.div`
  height: 1.5px;
  width: 9px;
  transform-origin: center;
  rotate: 141deg;
  translate: 23% 60%;
  background-color: ${(props) =>
    props.ishovered === 1 ? "transparent" : CommonColor.secondary_color};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  &::after {
    content: "";
    display: block;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: ${(props) => (props.ishovered === 1 ? "100%" : "0%")};
    transform-origin: center;
    height: 1.5px;
    background-color: ${CommonColor.secondary_color};
    transition: all 0.2s ease-in;
    transition-delay: ${(props) => (props.ishovered === 1 ? "0.2s" : "0s")};
  }
`;

export { ArrowContainer, ArrowTop, ArrowBottom };
