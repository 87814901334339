import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SliderTab from "../../componentsv2/common_components/SliderTab.js/SliderTab";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { Div } from "../../style/common.style";
import {
  ApplyLeaveFormContainer,
  LeaveOptionContainer,
  LeaveStatusContainer,
  LeaveStatusMsg,
  LeaveStatusTitle,
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  PopupNavBarContainerLeave,
} from "../../style/form.style";
import { CommonImages } from "../../utils/common.images";
import { userdata } from "../../utils/common.key";
import { workFromHomeType } from "../../utils/mockData";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import ShowLoader from "../Loader/ShowLoader";
import BottomButtons from "./components/BottomButtons";

import {
  setWorkFromHomeData,
  setWorkFromHomeDetails,
} from "../../reducer/workfromhomereduxstore";

import WorkFromHomeLeave from "./components/WorkFromHomeLeave";
import { DateObject } from "react-multi-date-picker";
import WorkFromHomeController from "../../controller/workFromHomeController";

export default function ApplyWorkFromHome(props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [Error, setError] = useState(false);
  const [reason, setReason] = useState("");
  const [startDate, setStartDate] = useState(new DateObject());
  const [endDate, setEndDate] = useState(new DateObject());

  const wfhController = new WorkFromHomeController();

  const dashboard = useSelector((state) => state.dashboard);
  const employee = useSelector((state) => state.employee);
  const holiday = useSelector((state) => state.holiday);

  const isAdmin = employee.employee_acess.attendance;

  // useEffect(() => {
  //   if (!props.isEdit) return;

  //   setUpdate();
  // }, [dashboard.isModalOpen]);

  useEffect(() => {
    if (!props.isEdit) return;
    prefillData();
  }, [dashboard.isModalOpen]);

  function prefillData() {
    if (!props.isEdit) return;

    setActiveTab(props.data?.type === "SINGLE" ? 0 : 1);
    setReason(props.selectedWFH.reason);
    if (props.data?.type === "SINGLE") {
      setStartDate(props.selectedWFH.from_date);
    } else {
      setStartDate(
        new DateObject(
          moment(props.selectedWFH.from_date, "YYYY-MM-DD").format()
        )
      );
      setEndDate(
        new DateObject(moment(props.selectedWFH.to_date, "YYYY-MM-DD").format())
      );
    }
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  function checkError() {
    if (reason === "") {
      return "reason";
    }
    return false;
  }
  function getTotalDays(from_date, to_date) {
    const start = moment(from_date);
    const end = moment(to_date);
    let numOfDay = end.diff(start, "days") + 1;

    // Loop from the start date to the end date, incrementing by one day
    for (
      let date = start.clone();
      date.isSameOrBefore(end);
      date.add(1, "day")
    ) {
      // Get the day of the week as a number (0 for Sunday, 6 for Saturday)
      let day = date.day();
      // If the day is 0 or 6, increment the count
      if (day === 0 || day === 6) {
        numOfDay = numOfDay - 1;
      }
    }

    const isHoliday = holiday.holiday_list.filter((ele) => {
      return moment(ele.date).isBetween(from_date, to_date, "days");
    });
    if (isHoliday.length >= 1) {
      numOfDay = numOfDay - isHoliday.length + 1;
    } else {
    }

    return numOfDay;
  }
  const user = props.forUser;
  async function onUpdate() {
    const error = checkError();
    setError(error);

    if (error) {
      return;
    }

    const from_date = moment(startDate);
    let to_date = moment(endDate);
    setIsLoader(true);
    const data = {
      _id: props.selectedWFH._id,
      from_date: from_date.format().split("T")[0],
      to_date: to_date.format().split("T")[0],
      reason: reason,
      type: activeTab ? "MULTIPLE" : "SINGLE",
      status: "PENDING",
    };

    const res = await wfhController.updateWorkFromHome(data);
    const sucessMsg = (
      <LeaveStatusContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>WFH update sucessfully</LeaveStatusMsg>
      </LeaveStatusContainer>
    );
    const erroMsg = (
      <div>
        <div>error</div>
        <div>try again after some time</div>
      </div>
    );

    setTimeout(() => {
      if (res) {
        dispatch(setIsModalOpen(false));
        handleAlert(sucessMsg, "success");
      } else {
        dispatch(setIsModalOpen(false));
        handleAlert(erroMsg, "error");
        return;
      }
    }, 2000);

    if (isAdmin) {
      const newRespData = await wfhController.getWorkFromHomeData();
      if (newRespData) {
        dispatch(setWorkFromHomeData(newRespData));
      }
    } else {
      const employeeId = getLocalStorage(userdata).user._id;
      const newRespData = await wfhController.getWorkFromHomePersonalDetails({
        user_id: employeeId,
      });
      if (newRespData) {
        dispatch(setWorkFromHomeData(newRespData?.wfh_data));
        dispatch(
          setWorkFromHomeDetails({
            message: newRespData.message,
            remaining_wfh_days: newRespData.remaining_wfh_days,
            used_wfh_days: newRespData.used_wfh_days,
            wfh_available_days: newRespData.wfh_available_days,
          })
        );
      }
    }
    setIsLoader(false);
    props.onHandleClose();
  }
  async function onSave() {
    const error = checkError();
    setError(error);

    if (error) {
      return;
    }

    const from_date = moment(startDate);
    let to_date = moment(endDate);
    setIsLoader(true);
    const data = {
      user_id: user._id,
      total_wfh: "10",
      approved_by_user_id: "",
      from_date: from_date.format().split("T")[0],
      to_date: to_date.format().split("T")[0],
      reason: reason,
      type: activeTab ? "MULTIPLE" : "SINGLE",
      status: "PENDING",
    };

    const res = await wfhController.addWorkFromHome(data);
    const sucessMsg = (
      <LeaveStatusContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>WFH applied sucessfully</LeaveStatusMsg>
      </LeaveStatusContainer>
    );
    const erroMsg = (
      <div>
        <div>error</div>
        <div>try again after some time</div>
      </div>
    );

    setTimeout(() => {
      if (res) {
        dispatch(setIsModalOpen(false));
        handleAlert(sucessMsg, "success");
      } else {
        dispatch(setIsModalOpen(false));
        handleAlert(erroMsg, "error");
        return;
      }
    }, 2000);

    if (isAdmin) {
      const newRespData = await wfhController.getWorkFromHomeData();
      if (newRespData) {
        dispatch(setWorkFromHomeData(newRespData));
      }
    } else {
      const employeeId = getLocalStorage(userdata).user._id;
      const newRespData = await wfhController.getWorkFromHomePersonalDetails({
        user_id: employeeId,
      });
      if (newRespData) {
        dispatch(setWorkFromHomeData(newRespData?.wfh_data));
        dispatch(
          setWorkFromHomeDetails({
            message: newRespData.message,
            remaining_wfh_days: newRespData.remaining_wfh_days,
            used_wfh_days: newRespData.used_wfh_days,
            wfh_available_days: newRespData.wfh_available_days,
          })
        );
      }
    }
    setIsLoader(false);
    props.onHandleClose();
  }
  function showNoUserError() {
    const erroMsg = (
      <div>
        <div>error</div>
        <div>Please select a user</div>
      </div>
    );
    dispatch(setIsModalOpen(false));
    handleAlert(erroMsg, "error");
    props.onHandleClose();
  }
  function getStatus() {
    if (activeTab === 0) return "SINGLE";

    if (activeTab === 1) return "MULTIPLE";

    return "SINGLE";
  }
  function getPopupHeaderName() {
    if (props.isEdit) {
      return "update work from home";
    }
    if (activeTab !== 3) {
      return "apply Work From Home";
    }
    return "apply Work From Home";
  }
  function getButtonName() {
    if (props.isEdit) {
      return "Update Work From Home";
    }
    return "Apply Work From Home";
  }
  function updateData(data, type) {
    if (type === "reason") {
      setReason(data);
    } else if (type === "start date") {
      setStartDate(data);
    } else if (type === "end date") {
      setEndDate(data);
    } else if (type === "single date") {
      setStartDate(data);
      setEndDate(data);
    }
  }
  return (
    <React.Fragment>
      {isLoader ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Sending work from home Request</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainerLeave>
              <PopupNavBar
                onHandleClose={props.onHandleClose}
                componentType="form"
                title={getPopupHeaderName()}
              />
            </PopupNavBarContainerLeave>
          </DialogTitle>

          <DialogContent dividers={true}>
            <ApplyLeaveFormContainer>
              <Div className="mx-4 my-3">
                <SliderTab
                  buttonList={workFromHomeType}
                  tab={activeTab}
                  onTabClick={(data) => setActiveTab(data)}
                />

                <LeaveOptionContainer>
                  <WorkFromHomeLeave
                    isLeaveHafDay={activeTab === 0}
                    isMultiForm={activeTab === 1}
                    isEdit={props.isEdit}
                    error={Error}
                    isExist={activeTab !== 3}
                    selectedWFH={props.selectedWFH}
                    reason={reason}
                    startDate={startDate}
                    endDate={endDate}
                    updateData={updateData}
                  />
                </LeaveOptionContainer>
              </Div>
            </ApplyLeaveFormContainer>
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => (props.isEdit ? onUpdate() : onSave())}
                buttonName={getButtonName()}
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
    // </LeaveApplyContainer>
  );
}
