import { createSlice } from "@reduxjs/toolkit";

export const attendanceSlice = createSlice({
  name: "auth",
  initialState: {
    assets_data: [],
  },
  reducers: {
    setAssetData: (state, action) => {
      let assign = [];
      let not_issue = [];
      action.payload.map((e) => {
        if (e.assign_user == null) {
          not_issue.push(e);
        } else {
          assign.push(e);
        }
      });
      state.assets_data = [...not_issue, ...assign];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAssetData } = attendanceSlice.actions;

export default attendanceSlice.reducer;
