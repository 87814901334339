import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SliderTab from "../../componentsv2/common_components/SliderTab.js/SliderTab";
import LeaveController from "../../controller/LeaveController";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import {
  setEmployeeLeave,
  updateEmployeeLeaveDatas,
  updatePendingEmployeeLeaveDatas,
} from "../../reducer/leavereduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { Div } from "../../style/common.style";
import {
  ApplyLeaveFormContainer,
  LeaveOptionContainer,
  LeaveStatusContainer,
  LeaveStatusMsg,
  LeaveStatusTitle,
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  MsgContainer,
  MsgHeading,
  PopupNavBarContainerLeave,
} from "../../style/form.style";
import { CommonImages } from "../../utils/common.images";
import { userdata } from "../../utils/common.key";
import { leaveTypes } from "../../utils/mockData";
import { getPendingLeaves } from "../../utils/utils";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import ShowLoader from "../Loader/ShowLoader";
import BottomButtons from "./components/BottomButtons";
import LateLeave from "./components/LateLeave";
import LeaveLeave from "./components/LeaveLeave";
import { getJustDate } from "../../utils/utils";

export default function ApplyLeave(props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(0);
  const [additionalData, setAdditionalData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [Error, setError] = useState(false);
  const leaveController = new LeaveController();
  const selectedLeave = useSelector((state) => state.leave.selected_leave);
  const dashboard = useSelector((state) => state.dashboard);
  const employee = useSelector((state) => state.employee);
  const holiday = useSelector((state) => state.holiday);

  useEffect(() => {
    if (!props.isEdit) return;
    onPrefillData();
  }, [dashboard.isModalOpen]);

  function onPrefillData() {
    if (props.isEdit) {
      if (selectedLeave.leave.leave_type === "LATE") {
        setActiveTab(3);
      }

      if (selectedLeave.leave.leave_type === "HALF") {
        setActiveTab(0);
      }
      if (selectedLeave.leave.leave_type === "SINGLE") {
        setActiveTab(1);
      }
      if (selectedLeave.leave.leave_type === "MULTIPLE") {
        setActiveTab(2);
      }
    }
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  function checkError() {
    if (additionalData.reason === "") {
      return "reason";
    }
    return false;
  }
  function getTotalDays(from_date, to_date) {
    const start = moment(from_date);
    const end = moment(to_date);
    let numOfDay = end.diff(start, "days") + 1;

    // Loop from the start date to the end date, incrementing by one day
    for (
      let date = start.clone();
      date.isSameOrBefore(end);
      date.add(1, "day")
    ) {
      // Get the day of the week as a number (0 for Sunday, 6 for Saturday)
      let day = date.day();
      // If the day is 0 or 6, increment the count
      if (day === 0 || day === 6) {
        numOfDay = numOfDay - 1;
      }
    }

    const isHoliday = holiday.holiday_list.filter((ele) => {
      return moment(ele.date).isBetween(from_date, to_date, "days");
    });
    if (isHoliday.length >= 1) {
      numOfDay = numOfDay - isHoliday.length + 1;
    } else {
    }

    return numOfDay;
  }
  async function onSave() {
    const error = checkError();
    setError(error);

    if (error) {
      return;
    }
    // setIsLoader(true);
    let is_half_1 = additionalData.half_day_1 === null;
    let is_half_2 = additionalData.half_day_2 === null;
    const from_date = moment(additionalData.from_date);
    let to_date = moment(additionalData.to_date);

    let total_days = 0;
    if (getStatus() === "HALF") {
      to_date = from_date.set({ hour: 23, minute: 59, second: 59 });
      total_days = 0.5;
    }
    if (getStatus() === "SINGLE") {
      to_date = from_date.set({ hour: 23, minute: 59, second: 59 });
      total_days = 1;
    }
    if (getStatus() === "LATE") {
      to_date = from_date.set({ hour: 23, minute: 59, second: 59 });
    }

    if (getStatus() === "MULTIPLE") {
      // total_days = to_date.diff(from_date, "days") + 1;

      total_days = getTotalDays(from_date, to_date);
      if (total_days === 0) {
        props.onHandleClose();
      }

      if (!is_half_1) {
        total_days = total_days - 0.5;
      }
      if (!is_half_2) {
        total_days = total_days - 0.5;
      }
    }

    const tempUserName = auth.employee_data.user.name.toLowerCase().split(" ");
    const word1 = tempUserName[0].replace(
      tempUserName[0][0],
      tempUserName[0][0].toUpperCase()
    );
    const word2 = tempUserName[1].replace(
      tempUserName[1][0],
      tempUserName[1][0].toUpperCase()
    );
    const name = word1 + " " + word2;

    if (props.isEdit) {
      const data = {
        id: selectedLeave.leave.id,
        half_day_1: is_half_1 ? "" : from_date.format(),
        half_day_2: is_half_2 ? "" : to_date.format(),
        leave_type: getStatus(),
        firebase_user_id: null,
        total_days_leave: total_days,
        user_id: auth.employee_data.user._id,
        user_name: name,
        approved_by_user_id: null,
        from_date: from_date.toISOString(),
        to_date: to_date.toISOString(),
        reason: additionalData.reason,
        type: "CASUAL",
        status: "PENDING",
      };

      const res = await leaveController.updateLeave(data);

      const sucessMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>leave updated sucessfully</sucessMsg>
        </MsgContainer>
      );
      const errorMsg = (
        <MsgContainer>
          <MsgHeading>error</MsgHeading>
          <sucessMsg>try again after some time</sucessMsg>
        </MsgContainer>
      );
      setTimeout(() => {
        setIsLoader(false);
        if (res) {
          props.onHandleClose();
          handleAlert(sucessMsg, "success");
          return;
        }
        props.onHandleClose();
        handleAlert(errorMsg, "error");
      }, 2000);
      const employeeId = getLocalStorage(userdata).user._id;
      const newRespData = await leaveController.getEmployeeLeaveData(
        employeeId
      );
      dispatch(updateEmployeeLeaveDatas(newRespData));
      const pendingNewRespData = getPendingLeaves(newRespData);
      dispatch(updatePendingEmployeeLeaveDatas(pendingNewRespData));
      if (employee.employee_acess.leave_details) {
        const employeesLeave = await leaveController.getLeaveInfoEmployees();
        dispatch(setEmployeeLeave(employeesLeave));
      }
      props.onHandleClose();
      return;
    }
    const user = props.forUser;
    if (!props.forUser || props.forUser === null || !props.forUser._id) {
      showNoUserError();
      console.error("no user");
      return;
    }
    const data = {
      half_day_1: is_half_1
        ? ""
        : moment(getJustDate(to_date.format()), "Do MMM 'YY").toISOString(),
      half_day_2: is_half_2
        ? ""
        : moment(getJustDate(to_date.format()), "Do MMM 'YY").toISOString(),
      leave_type: getStatus(),
      firebase_user_id: null,
      total_days_leave: total_days,
      user_id: user._id,
      user_name: user.name,
      approved_by_user_id: null,
      from_date: moment(
        getJustDate(from_date.format()),
        "Do MMM 'YY"
      ).toISOString(),
      to_date: moment(
        getJustDate(to_date.format()),
        "Do MMM 'YY"
      ).toISOString(),
      reason: additionalData.reason,
      type: "CASUAL",
      status: "PENDING",
    };
    const res = await leaveController.addLeave(data);
    const sucessMsg = (
      <LeaveStatusContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>leave applied sucessfully</LeaveStatusMsg>
      </LeaveStatusContainer>
    );
    const erroMsg = (
      <div>
        <div>error</div>
        <div>try again after some time</div>
      </div>
    );
    setTimeout(() => {
      setIsLoader(false);
      if (res) {
        dispatch(setIsModalOpen(false));
        handleAlert(sucessMsg, "success");
        return;
      }
      dispatch(setIsModalOpen(false));
      handleAlert(erroMsg, "error");
    }, 2000);
    const employeeId = getLocalStorage(userdata).user._id;
    const newRespData = await leaveController.getEmployeeLeaveData(employeeId);
    dispatch(updateEmployeeLeaveDatas(newRespData));
    const pendingNewRespData = getPendingLeaves(newRespData);
    dispatch(updatePendingEmployeeLeaveDatas(pendingNewRespData));
    if (employee.employee_acess.leave_details) {
      const employeesLeave = await leaveController.getLeaveInfoEmployees();
      dispatch(setEmployeeLeave(employeesLeave));
    }
    props.onHandleClose();
  }

  function showNoUserError() {
    const erroMsg = (
      <div>
        <div>error</div>
        <div>Please select a user</div>
      </div>
    );
    dispatch(setIsModalOpen(false));
    handleAlert(erroMsg, "error");
    setIsLoader(false);
    props.onHandleClose();
  }
  function getStatus() {
    if (activeTab === 3) return "LATE";
    if (activeTab === 0) return "HALF";
    if (activeTab === 1) return "SINGLE";

    if (activeTab === 2) return "MULTIPLE";

    return "SINGLE";
  }
  function getPopupHeaderName() {
    if (props.isEdit) {
      if (activeTab !== 3) {
        return "update leave";
      }
      return "update late coming";
    }
    if (activeTab !== 3) {
      return "apply leave";
    }
    return "apply late coming";
  }
  function getButtonName() {
    if (props.isEdit) {
      return "Update Leave";
    }
    return "Apply Leave";
  }
  return (
    <React.Fragment>
      {isLoader ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Sending Leave Request</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainerLeave>
              <PopupNavBar
                onHandleClose={props.onHandleClose}
                componentType="form"
                title={getPopupHeaderName()}
              />
            </PopupNavBarContainerLeave>
          </DialogTitle>

          <DialogContent dividers={true}>
            <ApplyLeaveFormContainer>
              <Div className="mx-4 my-3">
                <SliderTab
                  buttonList={leaveTypes}
                  tab={activeTab}
                  onTabClick={(data) => setActiveTab(data)}
                />

                <LeaveOptionContainer>
                  <LateLeave
                    isEdit={props.isEdit}
                    onSubmit={(e) => setAdditionalData(e)}
                    error={Error}
                    isVisible={activeTab === 3}
                  />
                  <LeaveLeave
                    isLeaveHafDay={activeTab === 0}
                    isMultiForm={activeTab === 2}
                    onSubmit={(e) => setAdditionalData(e)}
                    isEdit={props.isEdit}
                    error={Error}
                    isExist={activeTab !== 3}
                  />
                </LeaveOptionContainer>
              </Div>
            </ApplyLeaveFormContainer>
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => onSave()}
                buttonName={getButtonName()}
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
    // </LeaveApplyContainer>
  );
}
