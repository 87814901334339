import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveDrawerkey } from "../../../reducer/dashboardreduxstore";
import {
  BaseCardDashboardContainerFourth,
  LeaveCardLeaveInfoContainer,
  LeaveCardNoDataContainer,
  LeaveInfoContainer,
  LeaveInfoDetailContainer,
  NoDataComponentContainer,
} from "../../../style/dashboard.style";
import { FontSizes } from "../../../style/theme/font";
import CommonColor from "../../../utils/common.colors";
import { CommonString } from "../../../utils/common.string";
import { getActiveDrawerData } from "../../../utils/utils";
import LeaveInfoCard from "../../LeaveInfo/LeaveInfoCard";
import LeaveInfoDetail from "../../LeaveInfo/LeaveInfoDetail";
import LeaveShimmerDashboard from "../../Loader/shimmers/LeaveShimmerDashboard";
import CardTitle from "./CardTitle";

export default function LeaveCard(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ActiveEmployeeData, setActiveEmployeeData] = useState(0);
  const [openContent, setOpenContent] = useState(false);
  const leave = useSelector((state) => state.leave);
  const employee = useSelector((state) => state.employee);
  const title = CommonString.leaveApplication + CommonString.appendMulti;

  function setData(data) {
    setActiveEmployeeData(data);
  }

  function changeDrawerIndex() {
    const LeaveScreen = getActiveDrawerData(employee.employee_acess)[1];
    if (employee.employee_acess.leave_details) {
      dispatch(setActiveDrawerkey({ element: LeaveScreen, index: 1 }));
      history.push("/home/leave-info");
    } else {
      dispatch(setActiveDrawerkey({ element: LeaveScreen, index: 1 }));
      history.push("/home/leave-application");
    }
  }

  function toggleContent() {
    setOpenContent(!openContent);
  }
  return (
    <React.Fragment>
      <BaseCardDashboardContainerFourth isvisible={1} delay={props.serialOrder}>
        <CardTitle
          title={title}
          activeIndexUpdater={changeDrawerIndex}
          cardLocation={1}
          contentToggler={() => toggleContent()}
          openContent={openContent}
          contentTogglerNotVisible={true}
        />
        <LeaveInfoContainer
          heightOption={leave.leave_employees_data.length <= 0}
          ismanager={employee.employee_acess.leave_details}
        >
          {!employee.employee_acess.leave_details ? (
            <LeaveInfoDetailContainer>
              <LeaveInfoDetail leaveShowNumber={-1} isLoading={false} />
            </LeaveInfoDetailContainer>
          ) : props.LoadingFlag ? (
            openContent ? (
              <LeaveShimmerDashboard />
            ) : (
              ""
            )
          ) : leave.leave_employees_data.length > 0 ? (
            leave.leave_employees_data
              .slice(0, 2)
              .map((EmployeeLeaveData, index) => {
                return (
                  <LeaveCardLeaveInfoContainer key={index} index={index}>
                    <LeaveInfoCard
                      data={EmployeeLeaveData}
                      leaveShowNumber={1}
                      onClick={(data) => setData(data)}
                      ActiveEmployeeData={ActiveEmployeeData}
                      setselectedStatus={() => {}}
                    />
                  </LeaveCardLeaveInfoContainer>
                );
              })
          ) : (
            <LeaveCardNoDataContainer>
              <NoDataComponentContainer>
                <Icon
                  icon="healthicons:happy-outline"
                  fontSize={FontSizes.xxl}
                  color={CommonColor.secondary_color}
                />
                &nbsp;No more leaves to check
              </NoDataComponentContainer>
            </LeaveCardNoDataContainer>
          )}
        </LeaveInfoContainer>
      </BaseCardDashboardContainerFourth>
    </React.Fragment>
  );
}
