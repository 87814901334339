import { AppBar, Menu, Toolbar } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerTable } from "react-shimmer-effects";
import { TableVirtuoso } from "react-virtuoso";
import AddAssets from "../../component/Forms/AddAssets";
import ConfirmationMenu from "../../component/Popup/ConfirmationMenu";
import ModalPopup from "../../component/Popup/ModalPopup";
import ViewAssetDetails from "../../component/Popup/ViewAssetDetails";
import NoData from "../../component/commonComponents/NoData/NoData";
import { useWindowSize } from "../../component/commonComponents/hooks/getWindowDimension";
import NavBar from "../../component/drawer/NavBar";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import {
  AssetActionButton,
  AssetAvailIcon,
  AssetBrand,
  AssetContainer,
  AssetHolderData,
  AssetHolderEmail,
  AssetHolderName,
  AssetModel,
  AssetMoreDetailsIcon,
  AssetName,
  AssetOptionsContainer,
  AssetScreenContainer,
  AssetTypeIcon,
  FlexContainer,
} from "../../style/asset.style";
import { AppBarStyle } from "../../style/drawer.style";
import {
  NoDataContainerCelebration,
  PopOverButtonContainer,
} from "../../style/holiday.style";
import {
  AccessTableBody,
  AccessTableData,
  AccessTableHead,
  AccessTableHeader,
  AccessTableRow,
} from "../../style/style.access.mgmt.style";
import CommonColor from "../../utils/common.colors";
import { CommonString } from "../../utils/common.string";
import { DeleteAssetText, no, yes } from "../../utils/constant";
import { formatDisplayBirthDate } from "../../utils/utils";
import { Forms } from "../../utils/mockData";
import { ProfileShimmerContainer } from "../../style/profile.style";

export default function Assets(props) {
  const dashboard = useSelector((state) => state.dashboard);
  const assets = useSelector((state) => state.asset);
  const [action, setAction] = useState("");
  const [activeData, setActiveData] = useState(-1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopOver, SetOpenPopOver] = useState(-1);
  const employee = useSelector((state) => state.employee);
  const id = openPopOver ? "simple-popover" : undefined;
  const windowWidth = useWindowSize().width;
  const tableData = useMemo(() => assets.assets_data);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (!props.loadedPages.includes(CommonString.assetManagementScreen)) {
      getData();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error." ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  async function getData() {
    setPageLoading(true);
    await props.getAssets();
    setPageLoading(false);
  }

  function onRowClick(index) {
    onClickOptions(undefined, index);
    openAction("VIEW");
  }

  function onClickOptions(event, index) {
    if (event) {
      OpenPopOver(event, index);
    }
    if (openPopOver === index) {
      return;
    }
    setActiveData(index);
  }

  const dispatch = useDispatch();
  function DeleteAsset() {
    dispatch(setIsModalOpen(false));
  }
  function getModalChildren() {
    if (dashboard.modal_type === Forms[6].name)
      return <AddAssets onHandleClose={() => onHandleClose()} />;
    if (action === "EXCHANGE")
      return <div onClick={() => onHandleClose()}>Exchange Form</div>;
    if (action === "DELETE")
      return (
        <ConfirmationMenu
          title={DeleteAssetText}
          yesButtonName={yes}
          onClickYesButton={DeleteAsset}
          noButtuonName={no}
          onClicknoButton={onHandleClose}
          color={CommonColor.primary_color}
        />
      );
    if (action === "VIEW")
      return (
        <ViewAssetDetails
          assetData={assets.assets_data[activeData]}
          onHandleClose={onHandleClose}
        />
      );
    if (action === "EDIT")
      return (
        <AddAssets
          onHandleClose={onHandleClose}
          getDate={getDate}
          data={assets.assets_data[activeData]}
        />
      );
    return (
      <AddAssets onHandleClose={onHandleClose} getDate={getDate} data={null} />
    );
  }
  function onHandleClose() {
    dispatch(setIsModalOpen(false));
    setAction("");
    setActiveData(-1);
  }
  function OpenPopOver(event, index) {
    SetOpenPopOver(index);
    setAnchorEl(event.currentTarget);
  }
  function getUserId(prexeiliteId) {
    return "PXL" + prexeiliteId.slice(-3);
  }
  function openAction(actionName) {
    SetOpenPopOver(-1);
    setAction(actionName);
    dispatch(setIsModalOpen(true));
  }
  const handleCloseMenu = () => {
    SetOpenPopOver(-1);
    setAnchorEl(null);
  };
  function getDate(date) {
    return formatDisplayBirthDate(date);
  }

  return (
    <React.Fragment>
      <ModalPopup
        open={dashboard.isModalOpen}
        issmallScreen={action === "DELETE"}
      >
        {getModalChildren()}
      </ModalPopup>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Assets"}
            NavBarIcon={
              employee.employee_acess.assets
                ? {
                    icon: "material-symbols:add-circle-outline",
                    name: "Add Asset",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      <AssetScreenContainer container spacing={2}>
        <AssetContainer item xs={12}>
          {pageLoading ? (
            <ProfileShimmerContainer>
              <ShimmerTable row={10} col={5} />
            </ProfileShimmerContainer>
          ) : (
            <TableVirtuoso
              style={{ height: "100%" }}
              data={tableData}
              totalCount={assets.assets_data.length}
              height={"100%"}
              width={"100%"}
              rowHeight={40}
              fixedItemHeight={40}
              fixedHeaderContent={() => (
                <React.Fragment>
                  <AccessTableHeader>
                    <AccessTableHead horizontalAlign="left" width="17%">
                      Type
                    </AccessTableHead>
                    <AccessTableHead horizontalAlign="left" width="17%">
                      Brand + Model
                    </AccessTableHead>
                    {windowWidth > 600 ? (
                      <React.Fragment>
                        <AccessTableHead horizontalAlign="left" width="17%">
                          Assigned
                        </AccessTableHead>

                        <AccessTableHead horizontalAlign="left" width="17%">
                          Purchase Date
                        </AccessTableHead>
                        <AccessTableHead horizontalAlign="center" width="17%">
                          Working
                        </AccessTableHead>
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    <AccessTableHead horizontalAlign="center" width="17%">
                      Details
                    </AccessTableHead>
                  </AccessTableHeader>
                </React.Fragment>
              )}
              itemContent={(index, asset) => (
                <React.Fragment>
                  <AccessTableRow
                    onClick={() => onRowClick(index)}
                    cursor="pointer"
                    index={index}
                    length={12}
                  >
                    <AccessTableData
                      horizontalAlign="flex-start"
                      width="100%"
                      // ispl={true}
                    >
                      <FlexContainer>
                        <AssetTypeIcon icon="material-symbols:laptop-chromebook-outline-rounded" />
                        <AssetName>&nbsp;{asset.asset.type}</AssetName>
                      </FlexContainer>
                    </AccessTableData>
                    <AccessTableData width="100%">
                      <FlexContainer>
                        <AssetBrand>{asset.asset.brand}</AssetBrand>
                        &nbsp;
                        <AssetModel>{asset.asset.model}</AssetModel>
                      </FlexContainer>
                    </AccessTableData>

                    {/* <AccessTableData>
              <FlexContainer jc="center">
                {asset.asset.is_repairable === "1" ? (
                  <AssetAvailIcon
                    icon="mdi:check-circle-outline"
                    color={CommonColor.green}
                  />
                ) : (
                  <AssetAvailIcon
                    icon="mdi:close-circle-outline"
                    type="error"
                    color={CommonColor.red}
                  />
                )}
              </FlexContainer>
              </AccessTableData> */}
                    {windowWidth > 600 ? (
                      <React.Fragment>
                        <AccessTableData width="100%">
                          {asset.assign_user ? (
                            <FlexContainer jc="flex-start">
                              <AssetHolderData>
                                <AssetHolderName>
                                  {asset.assign_user
                                    ? asset.assign_user.name
                                    : ""}
                                </AssetHolderName>
                                <AssetHolderEmail>
                                  {getUserId(asset.assign_user.employee_id)}
                                </AssetHolderEmail>
                              </AssetHolderData>
                            </FlexContainer>
                          ) : (
                            <FlexContainer
                              style={{ fontWeight: "700" }}
                              jc="flex-start"
                            >
                              <AssetHolderData>
                                <AssetHolderName>Not Assigned</AssetHolderName>
                                <AssetHolderEmail>
                                  {asset.asset.tmp_id ?? "PXL/TMP/-001"}
                                </AssetHolderEmail>
                              </AssetHolderData>
                            </FlexContainer>
                          )}
                        </AccessTableData>
                        <AccessTableData textAlign="left" width="100%">
                          <FlexContainer>
                            <AssetName>
                              {getDate(asset.asset.purchase_date)}
                            </AssetName>
                          </FlexContainer>
                        </AccessTableData>
                        <AccessTableData width="100%">
                          <FlexContainer jc="center">
                            {asset.asset.is_working === "1" ? (
                              <AssetAvailIcon
                                icon="mdi:check-circle-outline"
                                color={CommonColor.green}
                              />
                            ) : (
                              <AssetAvailIcon
                                icon="mdi:close-circle-outline"
                                type="error"
                                color={CommonColor.red}
                              />
                            )}
                          </FlexContainer>
                        </AccessTableData>
                      </React.Fragment>
                    ) : (
                      ""
                    )}

                    <AccessTableData width="100%">
                      <AssetOptionsContainer
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FlexContainer
                          onClick={(e) => onClickOptions(e, index)}
                          jc="center"
                        >
                          <AssetMoreDetailsIcon icon="iwwa:option-horizontal" />
                        </FlexContainer>
                        {/*isvisible={}*/}
                        <Menu
                          id={id}
                          anchorEl={anchorEl}
                          open={openPopOver === index && Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                          onClick={handleCloseMenu}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <PopOverButtonContainer>
                            <AssetActionButton
                              variant="text"
                              onClick={() => openAction("VIEW")}
                            >
                              view details
                            </AssetActionButton>
                            <AssetActionButton
                              variant="text"
                              onClick={() => openAction("EDIT")}
                            >
                              edit
                            </AssetActionButton>
                            <AssetActionButton
                              variant="text"
                              onClick={() => openAction("DELETE")}
                            >
                              delete
                            </AssetActionButton>
                          </PopOverButtonContainer>
                        </Menu>
                      </AssetOptionsContainer>
                    </AccessTableData>
                  </AccessTableRow>
                </React.Fragment>
              )}
            />
          )}
        </AssetContainer>
      </AssetScreenContainer>
    </React.Fragment>
  );
}

function TableBody(props) {
  const assets = useSelector((state) => state.asset);
  const windowWidth = useWindowSize().width;

  useEffect(() => {}, []);

  return (
    <AccessTableBody height="100%">
      {assets.assets_data.length !== 0 ? (
        assets.assets_data.map((asset, index) => {
          return <React.Fragment></React.Fragment>;
        })
      ) : (
        <NoDataContainerCelebration>
          <NoData noDataText={CommonString.NoAssets} />
        </NoDataContainerCelebration>
      )}
    </AccessTableBody>
  );
}

/*
 <AssetTableContainer isvisible={true}>
    
              <Suspense fallback={<>Loading ...</>}>
                <TableBody
                  getDate={getDate}
                  id={id}
                  anchorEl={anchorEl}
                  openPopOver={openPopOver}
                  handleCloseMenu={handleCloseMenu}
                  openAction={openAction}
                  onClickOptions={onClickOptions}
                  getUserId={getUserId}
                  onRowClick={onRowClick}
                />
              </Suspense>
            </AssetTableContainer>
*/
