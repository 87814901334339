import { AppBar, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApplyLeave from "../../component/Forms/ApplyLeave";
import LeaveBalanceGraph from "../../component/LeaveInfo/LeaveBalanceGraph";
import LeaveInfoCard from "../../component/LeaveInfo/LeaveInfoCard";
import LeaveInfoDetail from "../../component/LeaveInfo/LeaveInfoDetail";
import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import ModalPopup from "../../component/Popup/ModalPopup";
import NoData from "../../component/commonComponents/NoData/NoData";
import NavBar from "../../component/drawer/NavBar";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import { updateSelectedLeaveDate } from "../../reducer/leavereduxstore";
import CSSController from "../../services/CSS/CSSServices";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import {
  CustomMarginAndFlexBox,
  MobileOnlyDiv
} from "../../style/common.style";
import { AppBarStyle } from "../../style/drawer.style";
import {
  LeaveApplicationApplyLeaveButton,
  LeaveApplicationContainer,
  LeaveApplicationGrid,
  LeaveBalanceGraphContainer,
  LeaveInfoCardContainer,
  NoDataComponentContainerLeaveApp,
  VerticalScrollableAreaLeaveInfo,
} from "../../style/leave.style";
import { userdata } from "../../utils/common.key";
import { CommonString } from "../../utils/common.string";

export default function LeaveApplication(props) {
  const dispatch = useDispatch();
  const [ActiveEmployeeData, setActiveEmployeeData] = useState();
  const [pageLoader, setPageLoader] = useState(false);
  const [LeaveInfoContainerSize, setLeaveInfoContainerSize] = useState();
  const [selectedStatus, setselectedStatus] = useState(
    CommonString.pending.toUpperCase()
  );
  const [IsPopupOpen, setOpenPopup] = useState(false);
  const employee = useSelector((state) => state.employee);
  const cssservices = new CSSController();
  const dashboard = useSelector((state) => state.dashboard);
  const leave = useSelector((state) => state.leave);

  useEffect(() => {
    if (!props.loadedContent.includes("leave application")) {
      setPageLoader(true);
      props.getDashboardScreenData(getLocalStorage(userdata));
      setPageLoader(false);
    }
  }, []);

  function setindex(data, index) {
    setActiveEmployeeData(data);
  }
  const CalculateContainerSize = (length) => {
    // cssservices.CalculateContainerSize(length, LeaveInfoContainerSize, (data) =>
    //   setLeaveInfoContainerSize(data)
    // );
    setLeaveInfoContainerSize("50vh");
  };
  function openPopup() {
    setOpenPopup(true);
  }

  function getChildren() {
    if (selectedStatus === CommonString.edit_leave.toUpperCase()) {
      return (
        <ApplyLeave
          IsPopupOpen={dashboard.isModalOpen}
          isEdit={true}
          onHandleClose={() => closePopup()}
        />
      );
    }

    return (
      <ApplyLeave
        IsPopupOpen={props.IsPopupOpen}
        onHandleClose={() => closePopup()}
        isEdit={false}
        forUser={getLocalStorage(userdata)?.user}
      />
    );
  }

  function closePopup() {
    dispatch(setIsModalOpen(false));
    setselectedStatus(CommonString.pending.toUpperCase());
    dispatch(updateSelectedLeaveDate(null));
    setOpenPopup(false);
  }
  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Leave Application"}
            NavBarIcon={{
              icon: "carbon:calendar-add",
              name: "Apply Leave",
            }}
          />
        </Toolbar>
      </AppBar>
      <LeaveApplicationContainer container>
        <LeaveApplicationGrid item>
          <VerticalScrollableAreaLeaveInfo
            height={"100%"}
            unit={""}
            mobileheight=""
            display="none"
          >
            {pageLoader ? (
              <PageRefreshLoader />
            ) : leave.pending_employee_leave.length > 0 ? (
              leave.pending_employee_leave.map(
                (EmployeeLeaveData, index, array) => {
                  return (
                    <LeaveInfoCardContainer key={index}>
                      <LeaveInfoCard
                        index={index}
                        data={EmployeeLeaveData}
                        length={leave.pending_employee_leave.length}
                        ActiveEmployeeData={ActiveEmployeeData}
                        isEdit={true}
                        isActive={
                          ActiveEmployeeData
                            ? EmployeeLeaveData.leave.id ===
                              ActiveEmployeeData.leave.id
                            : ""
                        }
                        onClick={setindex}
                        setselectedStatus={setselectedStatus}
                      />
                    </LeaveInfoCardContainer>
                  );
                }
              )
            ) : leave.pending_employee_leave.length === 0 ? (
              <React.Fragment>
                <NoDataComponentContainerLeaveApp>
                  <NoData noDataText={CommonString.NoPendingLeaveFound} />
                  <CustomMarginAndFlexBox mt="2%">
                    {leave.total_leave - leave.used_leave >= 0 ? (
                      <LeaveApplicationApplyLeaveButton>
                        <ButtonCustomV2
                          variant="contained"
                          buttonText={"Apply Leave"}
                          onClick={() => openPopup()}
                        />
                      </LeaveApplicationApplyLeaveButton>
                    ) : (
                      ""
                    )}
                  </CustomMarginAndFlexBox>
                </NoDataComponentContainerLeaveApp>
              </React.Fragment>
            ) : (
              ""
            )}
          </VerticalScrollableAreaLeaveInfo>
        </LeaveApplicationGrid>
    
        <LeaveApplicationGrid className="grid2" item>
          <VerticalScrollableAreaLeaveInfo
            height={"100%"}
            unit={""}
            mobileheight=""
            display="none"
          >
            <LeaveInfoDetail
              leaveShowNumber={10000}
              height="77"
              circleWidth={"70%"}
            />
          </VerticalScrollableAreaLeaveInfo>
        </LeaveApplicationGrid>
        <MobileOnlyDiv>
          <LeaveBalanceGraphContainer>
            <LeaveBalanceGraph
              isWfh={false}
              total_leave={leave.total_leave}
              used_leave={leave.used_leave}
            ></LeaveBalanceGraph>
          </LeaveBalanceGraphContainer>
          {leave.leave_list.map((leave, index) => {
            return (
            
              <LeaveInfoCardContainer key={index}>
                <LeaveInfoCard
                  index={index}
                  length={leave.leave_list?.length}
                  data={leave}
                  ActiveEmployeeData={ActiveEmployeeData}
                  isEdit={true}
                  isActive={
                    ActiveEmployeeData
                      ? leave.leave.id === ActiveEmployeeData.leave.id
                      : ""
                  }
                  onClick={setindex}
                  setselectedStatus={setselectedStatus}
                />
              </LeaveInfoCardContainer>
            );
          })}
        </MobileOnlyDiv>
      </LeaveApplicationContainer>
      <ModalPopup open={dashboard.isModalOpen || IsPopupOpen} popupSize="sm">
        {getChildren()}
      </ModalPopup>
    </React.Fragment>
  );
}
