import WorkFromHomeProvider from "../services/provider/workFromHomeProvider";

export default class WorkFromHomeController {
  constructor(props) {
    this.workFromHomeProvider = new WorkFromHomeProvider();
  }

  getWorkFromHomeData = async () => {
    let response = await this.workFromHomeProvider.getWorkFromHomeData();

    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting workfro home data");
      return false;
    }
  };

  getWorkFromHomePersonalDetails = async (data) => {
    let response =
      await this.workFromHomeProvider.getworkFromHomePersonalDetails(data);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("error while personal detail workfro home data");
      return response;
    }
  };

  delectWorkFromHome = async (data) => {
    let response = await this.workFromHomeProvider.deleteWorkFromHome(data);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("error while delete workfro home data");
      return false;
    }
  };

  updateWorkFromHome = async (data) => {
    let response = await this.workFromHomeProvider.updateWorkFromHome(data);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("error while update workfro home data");
      return false;
    }
  };

  addWorkFromHome = async (data) => {
    let response = await this.workFromHomeProvider.addWorkFromHome(data);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("error while add workfro home data");
      return false;
    }
  };
}
