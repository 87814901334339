const CommonString = {
  work_from_home: "Work From Home",
  welcome: "Welcome,",
  ankur: "Ankur",
  inactive_text:
    "Manage your attendance, leave management, keep up to date with office news and events, all from here.",
  prexelites: "PREXELITES'",
  dashboard: "DASHBOARD",
  events: "Events",
  view_all: "View All",
  log_in: "LOG IN",
  view_details: "View Details",
  ankur_patel: "Ankur Patel",
  october: "October",
  year: "21",
  leave_info: "Leave Info",
  approved: "Approved",
  leaveApplication: "leave Application",
  appendMulti: "s",
  status: ["Pending", "Approved"],
  pending: "PENDING",
  // PENDING
  rejected: "REJECTED",
  edit: "edit",
  edit_leave: "Edit Leave",
  edit_apile: "Edit Apile",
  delete: "delete",
  confirmationApproved: "Are you sure you want to approve",
  confirmationReject: "Are you sure you want to reject",
  confirmationEdit: "Are you sure want to edit this leave?",
  confirmationdelete: "Are you sure want to delete",
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
  eventSucess: "Event Added successfully",
  eventSucessTitle: "Event Added.",
  eventErrorTitle: "Error in Add Event.",
  noEventFound: "Oops... No Events Found.",
  noUpcomingFound: "No events scheduled this week.",
  NoPendingLeaveFound: "Pending leaves not found.",
  NoPendingWfhFound: "Pending WFH not found.",
  NoLeaveFound: "Leaves not found.",
  NoTrainingMaterial: "Training material isn't there for this branch",
  NoPolicy: "No Policy",
  NoBirthday: "No birthday(s) are there in this month",
  ErrorLatLong: "Please give location access to log attendance",
  SpeedyPrexelites: "Speedy Prexelites",
  SelectPrexeliteError: "Please Select Prexelite...",
  EnterValue: "Please Enter the value",
  logoutConfirmation: "Are you sure you want to Log Out?",
  loginConfirmation: "Are you sure you want to login?",
  yes: "Yes",
  no: "No",
  login: "log  in",
  logout: "log out",
  noEventScheduled: "No Event Scheduled This Month",
  noAttendance: "No attendance found for this month",
  deleteSpeedyPrexelite: "Are you sure you want to delete ",
  markPresentPopupTitle: "Mark Present",
  markPresentPopupText: "Are you sure you want to mark present for ?",
  deleteAttendanceTitle: "Delete Attendance",
  deleteAttendanceText: "Are you sure you want to delete attendance for ?",
  markLateConfirmationTitle: "Mark Late",
  markLateConfirmationText: "Are you sure you want to mark late for ?",
  markAbsentConfirmationTitle: "Mark Absent",
  markAbsentConfirmationText: "Are you sure you want to mark absent for ?",
  removeLateConfirmationTitle: "Remove Late",
  removeLateConfirmationText: "Are you sure you want to remove late for ?",
  NoAssets: "No Assets Assigned",
  dashboardScreenKey: "dashboard",
  leaveInfoScreenKey: "leave info",
  leaveApplicationScreenKey: "leave application",
  eventsScreenKey: "event screen",
  employeeScreenKey: "employee screen",
  synergyLeagueScreenKey: "synergy league",
  trainingMaterialScreenKey: "training material",
  prexelLifeScreen: "life @ Prexel",
  officeTimingScreen: "Office Timings",
  reportScreen: "Attendance Report",
  assetManagementScreen: "Asset Management",
  accessManagement: "Access Management",
  payrollScreen: "PayRoll",
  profileScreen: "Profile",
  appeal: "Appeal",
  policy: "Policy",
  selectDate :"Please Select Dates",

  ConfirmDeleteLeaveCredit: "Delete Leave Credit for",
  deleteLeaveCreditTitle: "Delete Leave Credit",
  noUpcomingTitle: "No Events",
  confirmEventDeleteTitle: "Are you Sure you want to Delete this event ?",
  approveButtonName: "Approve",
  rejectButtonName: "Reject",
  leaveTypeMultiple: "MULTIPLE",
  leaveTypeSingle: "SINGLE",
  leaveTypeHalf: "HALF",
  leaveTypeLate: "LATE",
  noApiResponse: "Oops! ran into an internal problem",
  noInternet: "Connectivity seems to be the problem :(",
  gameDeleteConfirmation: "Are you sure you want to delete",
  memberDeleteConfirmation: "Are you sure you want to remove",
  deleteMatchData: "Are you sure you want to delete match data",
  NoAppeal: "No Appeal found!",
};
export { CommonString };
