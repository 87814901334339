import ApiUtils from "../APICallServices/APIUtils";

export default class LeaveReportProvider {

    constructor(){
        this.leaveApiUtils = new ApiUtils();
    }

    getLeaveReport = (data)=>{
        return this.leaveApiUtils.init().post("v2/leaves/get-leave-report" , data)
    }
}