import { fontFamily, fontWeight } from "@mui/system";
import styled, { keyframes } from "styled-components";
import CommonColor from "../utils/common.colors";
import { DeviceSizes } from "./common.style";
import { DatePickerCalendarProfile } from "./profile.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";
import { CommonImages } from "../utils/common.images";
import { CloseButtonIcon, ProfileViewContainer } from "./employee.style";
import { Grid } from "@mui/material";

const ReportContainer = styled.div`
  margin: 0;
  padding: 0.5%;
  height: 100%;
  width: 100%;
  @media ${DeviceSizes.mobile} {
    padding: 2%;
  }
`;

const LeaveReportContainer = styled.div`
  margin: 0;
  padding: 0.5%;
  height: 100%;
  width: 100%;
  @media ${DeviceSizes.mobile} {
    padding: 2%;
  }
`;
const TimingsTable = styled.div`
  width: 50%;
  font-family: ${fontFamily.Mulish};
  font-weight: 500;
  font-size: ${FontSizes.Para};
  text-transform: capitalize;
  border-collapse: collapse;
  height: 100%;
`;
const TimingTableBody = styled.div``;
const TimingTableData = styled.div`
  font-size: ${FontSizes.SubPara};

  font-weight: ${(props) => (props.islate ? "700" : "")};
  color: ${(props) => (props.islate ? Theme.primary_color.red : "")};
  background-color: ${(props) =>
    props.islate ? Theme.primary_color.declined_status_background : ""};
  width: ${(props) => props.width ?? "30%"};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.jc ?? "center"};
  cursor: ${(props) => props.cursor};
  border-radius: 10px;

  @media ${DeviceSizes.mobile} {
    display: ${(props) => props.mobileDeviceDisplay};
    width: ${(props) => props.mobileWidth ?? "20%"};
  }
`;
const TimingTableHeader = styled.div`
  vertical-align: middle;
  position: ${(props) => props.position ?? ""};
  top: 0vh;
  width: ${(props) => props.width ?? "30%"};
  display: flex;
  justify-content: ${(props) => props.jc ?? "center"};
  align-items: center;

  @media ${DeviceSizes.mobile} {
    display: ${(props) => props.mobileDeviceDisplay};
    width: ${(props) => props.mobileWidth ?? "20%"};
  }
`;
const TimingTableRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: ${(props) => props.position};
  top: 0;
  background-color: ${(props) => props.bg};
  z-index: ${(props) => props.zIndex};
  height: 30px;
  border-bottom: 1px solid ${CommonColor.divider_gray};
  border-radius: initial;
  /* padding-left: 1%; */
  padding-right: 1%;

  @media ${DeviceSizes.mobile} {
    background-color: ${(props) =>
      props.islate ? CommonColor.declined_status_background : ""};
    padding-left: 2%;
    height: 40px;
  }
`;
const TableDataNumber = styled.span`
  color: ${(props) => props.color ?? Theme.primary_color.primary_color};
  text-transform: uppercase;
`;
const TableDataContainer = styled.div`
  position: ${(props) => props.position};
`;

const CalendarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1%;
  & fieldset {
    border-radius: 4px;
  }
  & .rmdp-container {
    width: 15%;
    cursor: pointer;
  }
  & svg:first-child {
    cursor: pointer;
    margin-right: 1%;
  }
  svg:nth-child(3) {
    margin-left: 1%;
    margin-right: 1%;
    cursor: pointer;
  }
  @media ${DeviceSizes.mobile} {
    justify-content: center;
    & .rmdp-container {
      width: 100%;
    }
  }
`;
const ReportsScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: ${(props) => (props.isvisible === 1 ? "94%" : "0%")};
  position: absolute;
  z-index: ${(props) => props.zIndex};
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;
  width: 98.5%;
  border-radius: 20px;

  background-color: ${CommonColor.theme_background_color};
  /* animation-name: ${(props) =>
    props.isvisible ? animateEntry : animateExit};
    animation-duration: ${(props) => props.animationTime ?? "0s"};
    animation-iteration-count: 1;
    animation-fill-mode: forwards; */
  opacity: ${(props) => (props.isvisible === 1 ? "1" : "0")};
  pointer-events: ${(props) => (props.isvisible === 1 ? "" : "none")};
  transition: ${(props) => props.animationTime} ease-in-out;
  @media ${DeviceSizes.mobile} {
    height: 100%;
  }
`;
const animateEntry = keyframes`
  0% { opacity: 0; }
  100%{opacity: 1; pointer-events: none;}
  `;
const animateExit = keyframes`
  0%{opacity: 1; pointer-events: none;}
  100% { opacity: 0; }
  `;
const ReportScreenCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${CommonColor.primary_color};
  border-radius: 10px;
  color: ${CommonColor.white};
  width: 200px;
  height: 150px;
  margin: 1%;
  padding: 1%;
  cursor: pointer;
  transform: scale(1);
  /* &:focus {
      transform: scale(5);
      opacity: 0;
      transition: all 1s ease-in-out;
    } */
`;
const ReportScreenCardIconContainer = styled.div``;
const ReportScreenWrapper = styled.div`
  width: 100%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
  padding: 10px;
  margin: 0;
  position: relative;
  @media ${DeviceSizes.mobile} {
    width: 96%;
  }
`;
const SynergyLeagueTeamImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;
const UpcomingLogoMatchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
`;
const UpcomingGameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
  width: 100%;
`;
const DateAndTimeContainerUpcoming = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 3%;
`;
const SynergyLeagueTeamImageContainer = styled.div`
  width: ${(props) => props.width ?? "40px"};
  height: ${(props) => props.height ?? "40px"};
`;
const UpcomingTournamentContainer = styled.div`
  width: 100%;
`;
const UpcomingTournamentsList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;
  margin-top: 5%;
  &&::-webkit-scrollbar {
    height: 0.3em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
  }
  @media ${DeviceSizes.belowLaptop} {
    overflow-x: hidden;
    overflow-y: auto;
    height: 250px;
    display: block;
  }
`;
const SynergyLeagueCupContainer = styled.div`
  /* height: 150px;
  width: 150px; */
  /* flex: 0 0 150px; */
  width: auto;
  border-radius: 10px;
  border: 1px solid ${CommonColor.white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1%;
  padding: 3%;
`;
const LeftScoreCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  & .MuiGrid-container {
    width: 100%;
    height: 100%;
  }
`;
const TeamListCardContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  overflow: auto hidden;
  padding-bottom: ${(props) => (props.isscroller ? "" : "0.6em")};
`;
const TeamLogoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const TeamLogoContainerOne = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
`;
const TeamLogoContainerTwo = styled(TeamLogoContainerOne)``;
const ReportProfileContainer = styled(ProfileViewContainer)`
  height: 100%;
  width: 100%;
`;
const ReportProfileCloseIconContainer = styled(CloseButtonIcon)`
  && {
    top: 0px;
    font-size: 38px;
    background: ${CommonColor.transparent};
    color: ${CommonColor.white};
  }
`;
const TeamListWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  /* height: 100%; */
  background-color: ${CommonColor.white};
  padding: 2%;
  height: max-content;
  @media ${DeviceSizes.belowLaptop} {
    height: 100%;
  }
`;
const TeamImageContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 1%;
  margin-left: 2%;
  margin-right: 2%;
  flex-shrink: 0;
  cursor: pointer;
  position: ${(props) => props.position};
`;
const TeamImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.borderRadius};
`;
const CardTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const GamesListWrapper = styled.div`
  height: 94%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;
const GamesListContainer = styled.div``;
const GamesNameTitle = styled.div`
  padding: 1%;
  background-color: ${CommonColor.primary_color};
  border-radius: 12px;

  position: sticky;
  top: 0;
  z-index: ${(props) => props.index};
`;
const GameScoreWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const GameScoreContainer = styled.div`
  padding: 1%;
  display: flex;
`;
const GameTeamScoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 220px;
  @media ${DeviceSizes.belowLaptop} {
    width: 100%;
  }
`;
const GameSingleTeamScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const GameTeamScoreCard = styled.div`
  padding: 2%;
  box-shadow: 4px 4px 77px 4px rgba(0, 0, 0, 0.07);
  margin: 1%;
  border-radius: 12px;
  width: max-content;
  height: max-content;
  @media ${DeviceSizes.belowtablet} {
    width: 100%;
    margin: 2%;
  }
`;
const GameScoreWrapperContainer = styled.div`
  width: 100%; /* Adjust the width as needed */
  overflow-x: auto;
  height: 100%;
`;

export {
  LeaveReportContainer,
  GameScoreWrapperContainer,
  GameTeamScoreCard,
  GameSingleTeamScoreContainer,
  GameTeamScoreContainer,
  GameScoreContainer,
  GameScoreWrapper,
  GamesNameTitle,
  GamesListContainer,
  GamesListWrapper,
  CardTitleContainer,
  TeamImage,
  TeamImageContainer,
  TeamListWrapper,
  ReportProfileCloseIconContainer,
  ReportProfileContainer,
  TeamLogoContainerTwo,
  TeamLogoContainerOne,
  TeamLogoContainer,
  TeamListCardContainer,
  LeftScoreCardWrapper,
  SynergyLeagueCupContainer,
  UpcomingTournamentsList,
  UpcomingTournamentContainer,
  SynergyLeagueTeamImageContainer,
  SynergyLeagueTeamImage,
  DateAndTimeContainerUpcoming,
  UpcomingGameContainer,
  UpcomingLogoMatchContainer,
  ReportScreenWrapper,
  ReportScreenCardIconContainer,
  ReportScreenCard,
  ReportsScreenContainer,
  CalendarContainer,
  TableDataContainer,
  TableDataNumber,
  ReportContainer,
  TimingsTable,
  TimingTableBody,
  TimingTableData,
  TimingTableHeader,
  TimingTableRow,
};
