import React, { useEffect, useState } from "react";
import {
  DropdownContainer,
  DropdownLaveCreditContainer,
  DropdownWrapper,
  LeaveBalanceGraphContainer,
  LeaveDetailsNavbarContainer,
  LeaveInfoCircleWrapper,
  LeaveInfoContainer,
  LeaveInfoEmployee,
  LeaveInfoMobileContainer,
  LeaveInfoPending,
  NoDataComponentContainerLeave,
  NoDataContainerRecentLeave,
} from "../../style/leave.style";

import { Icon } from "@iconify/react";
import { AppBar, Container, Toolbar } from "@mui/material";
import { forEach } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ApplyLeave from "../../component/Forms/ApplyLeave";
import LeaveInfoCard from "../../component/LeaveInfo/LeaveInfoCard";
import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import LeaveInfoDetailPopup from "../../component/Popup/LeaveInfoDetailPopup";
import ModalPopup from "../../component/Popup/ModalPopup";
import CircularLeaveGraph from "../../component/commonComponents/CircularLeaveGraph";
import NoData from "../../component/commonComponents/NoData/NoData";
import { useWindowSize } from "../../component/commonComponents/hooks/getWindowDimension";
import TailwindDropdownMembers from "../../component/commonComponents/inputs/TailWindDropdownMembers";
import NavBar from "../../component/drawer/NavBar";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import LeaveController from "../../controller/LeaveController";
import { setSelectedUser } from "../../reducer/employeereduxstore";
import {
  updateActiveUserLeaveListData,
  updateLeaveNumbersLeaveInfo,
} from "../../reducer/leavereduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { FlexContainer } from "../../style/asset.style";
import { Div, TypoGraphy } from "../../style/common.style";
import { AppBarStyle } from "../../style/drawer.style";
import { MuiDivider } from "../../style/form.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { userdata } from "../../utils/common.key";
import { CommonString } from "../../utils/common.string";

export default function LeaveInfoScreen(props) {
  const leaveList = useSelector((state) => state.leave.leave_employees_data);
  const employee_list = useSelector((state) => state.employee.employee_list);

  const employee = useSelector((state) => state.employee);
  const leaveStore = useSelector((state) => state.leave);
  const windoWidth = useWindowSize().width;
  const [pageLoading, setPageLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ActiveEmployeeIndex, setActiveEmployeeIndex] = useState("");
  const [activeLeave, setactiveLeave] = useState();
  const [selectedUser, setselectedUser] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [modalType, setModalType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const leaveController = new LeaveController();
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (!props.loadedContent.includes("pending leave info")) {
      setPageLoader(true);
      props.getDashboardScreenData(getLocalStorage(userdata));
      setPageLoader(false);
    }
  }, []);

  function showItems() {
    if (!employee.employee_acess.leave_details) return;

    let leaveDatas = [];
    if (leaveList.length === 0) {
      return (
        <NoDataComponentContainerLeave ml="0%" horizontalAlign="center">
          <NoData noDataText="Pending leaves not found" />
        </NoDataComponentContainerLeave>
      );
    }
    forEach(leaveList, (value, i) => {
      leaveDatas.push(
        <LeaveInfoCard
          key={i}
          data={value}
          length={leaveList.length}
          onClick={() => loaderLeaveEditor(value, i)}
          isActive={value.leave.id === activeLeave?.leave.id}
          index={i}
          ActiveEmployeeData={activeLeave}
          setselectedStatus={() => {}}
          onNameClick={() => updateLeaveInfo(value, i)}
        />
      );
    });
    return leaveDatas;
  }
  async function updateLeaveInfo(leave, index) {
    if (windoWidth > 600) return;
    setModalType("leaveinfodetail");
    await activeLeaveIdUpdater(leave, index);
    setOpenPopup(true);
  }

  async function loaderLeaveEditor(leave, index) {
    setLoading(true);
    const sendData = {
      user: { _id: leave.user._id },
    };
    await activeLeaveIdUpdater(leave, index);
    setLoading(false);
  }
  async function activeLeaveIdUpdater(udata, index) {
    udata.leave ? setactiveLeave(udata) : setactiveLeave(null);
    if (udata.user._id === selectedUser?._id) return;

    const Employee = employee_list.filter(
      (employee) => employee._id === udata.user._id
    )[0];
    setselectedUser(Employee);
    dispatch(setSelectedUser(Employee));
    setActiveEmployeeIndex(index);
    const sendData = {
      recruiter_id: udata.user._id,
      month: moment().month() + 1,
    };
    const leaveNumbers = await leaveController.getLeaveNumbers(sendData);
    dispatch(
      updateLeaveNumbersLeaveInfo({
        total_leave: leaveNumbers.total_leave,
        leave_bal: leaveNumbers.leave_balance,
        used_leave: leaveNumbers.used_leave,
      })
    );
    const leaveData = await leaveController.getEmployeeLeaveData(
      udata.user._id
    );
    dispatch(updateActiveUserLeaveListData(leaveData));
    if (windoWidth > 600) return;
    setModalType("leaveinfodetail");
    setOpenPopup(true);
  }
  function onClickFooterButton(event, status) {
    if (event) {
      event.stopPropagation();
    }
    // setselectedStatus(status);
    // setisOpen(true);
    // handleCloseMenu();
  }
  function getButtons() {
    // if (!ShowButton) return;
    if (!employee.employee_acess.leave_details) return "";
    // if (moment(props.data.leave.from_date).diff(moment()) < 0) return "";
    if (props.data.status === CommonString.pending.toUpperCase()) {
      return (
        <Div className="grid grid-cols-2 gap-x-2 w-full">
          <Div className="grid col-span-1">
            <ButtonCustomV2
              onClick={(event) =>
                onClickFooterButton(event, CommonString.rejected.toUpperCase())
              }
              variant="outlined"
              customBGColor={CommonColor.transparent}
              hoverBG={CommonColor.red + "10"}
              customTextColor={CommonColor.red}
              customOutlineColor={CommonColor.red}
              startIcon={
                <Icon icon="akar-icons:cross" height={FontSizes.SmallText} />
              }
              buttonText="Decline"
            />
          </Div>
          {/* <EmployeeCardDivider
            orientation="vertical"
            flexItem
            isdisplay={true}
            ml="1%"
            mr="1%"
          /> */}
          <Div className="grid col-span-1">
            <ButtonCustomV2
              onClick={(event) =>
                onClickFooterButton(event, CommonString.approved.toUpperCase())
              }
              variant="outlined"
              customBGColor={CommonColor.transparent}
              hoverBG={CommonColor.green + "10"}
              customTextColor={CommonColor.green}
              customOutlineColor={CommonColor.green}
              startIcon={
                <Icon icon="mdi:tick" fontSize={FontSizes.SmallText} />
              }
              buttonText="Approve "
            />
          </Div>
        </Div>
      );
    }
  }

  function getModalChildren() {
    if (modalType === "") return;
    if (modalType === "leaveinfodetail") {
      return (
        <LeaveInfoDetailPopup
          onHandleClose={onHandleClose}
          openAddLeavePopup={openLeaveApplicationPopup}
        />
      );
    }
    if (modalType === "leaveApplication") {
      if (
        employee.selected_user_leave_info === null ||
        !employee.selected_user_leave_info
      )
        return (
          <Div
            className="bg-white rounded-sm"
            onClick={() => setOpenPopup(false)}
          >
            Please Select a user
          </Div>
        );
      return (
        <ApplyLeave
          onHandleClose={() => setOpenPopup(false)}
          isEdit={false}
          forUser={employee.selected_user_leave_info}
        />
      );
    }
  }
  function onHandleClose() {
    setOpenPopup(false);
    // dispatch(setSelectedUser(null));
  }
  const closePopOver = () => {
    setAnchorEl(null);
  };
  const openPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function setActiveEmployee(option) {
    activeLeaveIdUpdater({
      user: {
        _id: option._id,
      },
    });
    closePopOver();
  }

  function openLeaveApplicationPopup() {
    const activeEmployee = employee.selected_user_leave_info;
    if (activeEmployee === null) return;
    setModalType("leaveApplication");
    setOpenPopup(true);
  }
  function getEmployeeList() {
    return employee_list;
  }
  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Leave Info"}
            NavBarIcon={
              employee.employee_acess.leave_details
                ? {
                    icon: "",
                    name: "",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      <ModalPopup
        open={openPopup}
        popupSize={modalType === "leaveApplication" ? "sm" : "xs"}
      >
        {getModalChildren()}
      </ModalPopup>
      {!pageLoading ? (
        <LeaveInfoContainer container>
          <LeaveInfoPending item xs={12} sm={6}>
            <DropdownWrapper>
              <DropdownContainer tabVisiblity="none" mobileVisibility="visible">
                <TailwindDropdownMembers
                  dataList={getEmployeeList()}
                  title={"View leaves of : "}
                  value={employee.selected_user_leave_info}
                  handleChange={(event) => {
                    activeLeaveIdUpdater({
                      user: {
                        _id: event._id,
                      },
                    });
                  }}
                  textTransform={"capitalize"}
                  placeholder={"Search Prexelite"}
                />
              </DropdownContainer>
            </DropdownWrapper>
            <LeaveInfoMobileContainer>
              {showItems(leaveList)}
            </LeaveInfoMobileContainer>
          </LeaveInfoPending>

          <LeaveInfoEmployee item xs={12} sm={6}>
            <DropdownLaveCreditContainer
              isfullheight={leaveStore.active_user_leave_list.length === 0}
            >
              {/* <DropdownContainer
                  tabVisiblity="visible"
                  mobileVisibility="none"
                >
                  <DropdownEmployeeButton
                    dataList={employee_list}
                    title={"View leaves of : "}
                    value={employee.selected_user_leave_info}
                    handleChange={(event) => {
                      activeLeaveIdUpdater({
                        user: {
                          _id: event._id,
                        },
                      });
                    }}
                    textTransform={"capitalize"}
                    placeholder={"Search Prexelite"}
                  />
                </DropdownContainer> */}
              <LeaveDetailsNavbarContainer>
                <TailwindDropdownMembers
                  // title="Assigned To"
                  dataList={getEmployeeList()}
                  title={"View leaves details of : "}
                  value={employee.selected_user_leave_info}
                  handleChange={(event) => {
                    activeLeaveIdUpdater({
                      user: {
                        _id: event._id,
                      },
                    });
                  }}
                  textTransform={"capitalize"}
                  placeholder={"Select Prexelite"}
                />
                <FlexContainer verticalAlign="center">
                  <LeaveInfoCircleWrapper>
                    <LeaveBalanceGraphContainer>
                      <CircularLeaveGraph
                        total_leave={leaveStore.total_leave_number_info}
                        used_leave={leaveStore.used_leave_number_info}
                        leave_bal={leaveStore.leave_balance}
                        cicleWidth={"110px"}
                        fontSizeNum="1.2em"
                        fontSizeText={FontSizes.xs}
                        fontWeightText={"600"}
                      ></CircularLeaveGraph>
                    </LeaveBalanceGraphContainer>
                  </LeaveInfoCircleWrapper>
                  <FlexContainer
                    flexDirection="column"
                    verticalAlign="start"
                    jc="center"
                  >
                    <FlexContainer flexDirection="column" jc="center">
                      <FlexContainer>
                        <TypoGraphy>
                          Total Leaves Credited :{" "}
                          {leaveStore.total_leave_number_info}
                        </TypoGraphy>
                      </FlexContainer>
                      <FlexContainer verticalAlign="flex-start" jc="flex-start">
                        <TypoGraphy>Used Leaves&nbsp;:&nbsp;</TypoGraphy>
                        <TypoGraphy>
                          {leaveStore.used_leave_number_info}
                        </TypoGraphy>
                      </FlexContainer>
                    </FlexContainer>

                    {employee.selected_user_leave_info !== null ? (
                      <Div className="grid grid-cols-1 mt-1">
                        <Div className="grid col-span-1">
                          <ButtonCustomV2
                            customTextColor={CommonColor.primary_color}
                            customBGColor={CommonColor.white}
                            hoverBG={CommonColor.primary_color_opacity_05}
                            variant="outlined"
                            fontWeight="500"
                            onClick={() => openLeaveApplicationPopup()}
                            startIcon={
                              <Icon icon="system-uicons:calendar-add" />
                            }
                            buttonText={"Add Leave"}
                          />
                        </Div>
                      </Div>
                    ) : (
                      ""
                    )}
                  </FlexContainer>
                </FlexContainer>
              </LeaveDetailsNavbarContainer>

              {leaveStore.active_user_leave_list.length === 0 ? (
                <React.Fragment>
                  <MuiDivider light={true} ml="15%" mr="15%" />
                  <NoDataContainerRecentLeave mt="13%">
                    <TypoGraphy textAlign="center" width="100%">
                      No Leaves Found
                    </TypoGraphy>
                  </NoDataContainerRecentLeave>
                </React.Fragment>
              ) : (
                ""
              )}
            </DropdownLaveCreditContainer>
            {!loading ? (
              leaveStore.active_user_leave_list.length !== 0 ? (
                leaveStore.active_user_leave_list.map((leave, index) => {
                  // if (leave.leave.status === "PENDING") return;
                  return (
                    <LeaveInfoCard
                      key={index}
                      data={leave}
                      length={leaveStore.active_user_leave_list.length}
                      onClick={() => activeLeaveIdUpdater(leave)}
                      isActive={activeLeave === leave.leave.id}
                      index={index}
                      ActiveEmployeeIndex={activeLeave}
                      setselectedStatus={() => {}}
                      // noClick={true}
                    />
                  );
                })
              ) : (
                <></>
              )
            ) : (
              <PageRefreshLoader />
            )}
          </LeaveInfoEmployee>
        </LeaveInfoContainer>
      ) : (
        <Container
          sx={{
            backgroundColor: CommonColor.theme_background_color,
            height: "90vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <PageRefreshLoader />
        </Container>
      )}
    </React.Fragment>
  );
}
