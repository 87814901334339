import { createSlice } from "@reduxjs/toolkit";
import { isCheckBool } from "../utils/utils";

export const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employee_list: [],
    employee_image_list: [],
    current_week_birthday: [],
    typing_tracker: [],
    employee_access_list_api: {},
    employee_access_list: {},
    employee_acess: {
      training_material: true,
      leave_details: true,
      events: true,
      attendance: true,
      basic_details: true,
      personal_details: true,
      speedy_prexelite: true,
      assets: true,
      reports: true,
      holiday: true,
      salary_details: true,
      users: true,
      wfh: true,
    },
    contact_details: [],

    employee_basic_details: {
      phone_number: "NA",
      emergency_contact: "NA",
      personal_email: "Not Updated Yet",
      google_fit_email: "Not Updated Yet",
      aadhar_card_number: "Not Updated Yet",
      pan_number: "Not Updated Yet",
      passport_number: "Not Updated Yet",
      nationality: "Not Updated Yet",
      current_address: "Not Updated Yet",
      permanent_address: "Not Updated Yet",
      joining_date: "Not Updated Yet",
      marital_status: "Not Updated Yet",
      anniversary_date: "Not Updated Yet",
      sex: "Not Updated Yet",
      employee_type: "Not Updated Yet",
      experience: "Not Updated Yet",
    },
    employee_access_list_all: [],
    employee_body_measurenment: {},
    selected_user_leave_info: {},
    employee_full_birthday_list: [],
    upcoming_birthday: [],
    is_profile_on_member_screen: false,
  },
  reducers: {
    setProfileOnMemberScreen: (state, action) => {
      state.is_profile_on_member_screen = action.payload;
    },
    storeEmployeeList: (state, action) => {
      state.employee_list = action.payload;
    },
    setUpcomingBirthday: (state, action) => {
      state.upcoming_birthday = action.payload;
    },
    setCurrentWeekBirthday: (state, action) => {
      state.current_week_birthday = action.payload;
    },
    storeEmployeeImages: (state, action) => {
      state.employee_image_list = action.payload;
    },
    setTopTypers: (state, action) => {
      state.typing_tracker = action.payload;
    },
    setEmployeeAccessList: (state, action) => {
      state.employee_acess = {
        training_material: action.payload.training_material === "1",
        leave_details: action.payload.leave_details === "1",
        events: action.payload.events === "1",
        attendance: action.payload.attendance === "1",
        basic_details: action.payload.basic_details === "1",
        personal_details: action.payload.personal_details === "1",
        speedy_prexelite: action.payload.speedy_prexelite === "1",
        assets: action.payload.assets === "1",
        reports: action.payload.reports === "1",
        holiday: action.payload.holiday === "1",
        salary_details: action.payload.salary_details === "1",
        wfh: isCheckBool(action.payload.wfh),
        users: action.payload.users === "1",
        wfh_screen : action.payload.wfh_screen
      };
    },
    setEmployeeAccessListAll: (state, action) => {
      state.employee_access_list_all = action.payload;
    },
    setEmployeeContact: (state, action) => {
      state.contact_details = action.payload;
    },
    setEmployeeBasicDetails: (state, action) => {
      state.employee_basic_details = action.payload;
    },
    setEmployeeBodyMeasurenment: (state, action) => {
      state.employee_body_measurenment = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selected_user_leave_info = action.payload;
    },
    setEmployeeBirthdayList: (state, action) => {
      state.employee_full_birthday_list = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProfileOnMemberScreen,
  setSelectedUser,
  setEmployeeBodyMeasurenment,
  setEmployeeContact,
  setEmployeeAccessListAll,
  setEmployeeAccessList,
  storeEmployeeList,
  storeEmployeeImages,
  setUpcomingBirthday,
  setCurrentWeekBirthday,
  setEmployeeBasicDetails,
  setTopTypers,
  setEmployeeBirthdayList,
} = employeeSlice.actions;

export default employeeSlice.reducer;
