import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddTrainingMaterial from "../../component/Forms/AddTrainingMaterial";
import TrainingMaterialHomeShimmer from "../../component/Loader/shimmers/TrainingMaterialHome";
import PdfViewer from "../../component/Popup/PdfViewer";
import PdfFile from "../../component/TrainingMaterial/PdfFile";
import NoData from "../../component/commonComponents/NoData/NoData";
import Searchbar from "../../component/commonComponents/Search/SearchBar";
import {
  SetLocalModalName,
  setIsModalOpen,
} from "../../reducer/dashboardreduxstore";
import {
  setActiveMaterial,
  setPdfLoaded,
} from "../../reducer/materialreduxstore";
import {
  FilterContainerTraining,
  MultipleFilterContainer,
  NoDataComponentContainerTrainingMaterial,
  PdfCardContainer,
  SearchContainer,
  TrainingPageNav,
  TrainingScreenWrapper,
  UploadedFilesContainer,
  VerticalScrollableAreaTraining,
} from "../../style/training.style";
import { CommonString } from "../../utils/common.string";
import { Branches, EventTypes } from "../../utils/mockData";

import { AppBar, Toolbar } from "@mui/material";
import ModalPopup from "../../component/Popup/ModalPopup";
import DropdownWithChip from "../../component/commonComponents/DropdownModified/DropdownWithChip";
import NavBar from "../../component/drawer/NavBar";
import { AppBarStyle } from "../../style/drawer.style";
export default function TrainingMaterial(props) {
  const dispatch = useDispatch();
  const [PdfMaterial, setPdfMaterial] = useState([]);
  const [files, setFiles] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [isPreview, setisPreview] = useState(false);
  const [branchSelected, setSelectedBranch] = useState("All Wings");
  const [eventSelected, setSelectedEvent] = useState("General");
  const [pageLoading, setPageLoading] = useState(
    !props.loadedPages.includes(CommonString.trainingMaterialScreenKey)
  );

  const pdfMaterialList = useSelector(
    (state) => state.material.event_pdf_material
  );
  const dashboard = useSelector((state) => state.dashboard);
  const employee = useSelector((state) => state.employee);
  useEffect(() => {
    if (!props.loadedPages.includes(CommonString.trainingMaterialScreenKey)) {
      getData();
    }
    if (props.loadedPages.includes(CommonString.trainingMaterialScreenKey)) {
      setPageLoading(false);
    }
  }, []);

  async function getData() {
    setPageLoading(true);
    await props.getTrainingMaterial();
    setPageLoading(false);
  }
  useEffect(() => {
    getPdfList();
  }, [pdfMaterialList]);

  useEffect(() => {
    filterBranchData();
  }, [branchSelected, eventSelected]);

  function filterBranchData() {
    if (branchSelected.toUpperCase() === "ALL WINGS") {
      if (eventSelected === "General") {
        const general = pdfMaterialList.filter((pdfMaterial) => {
          return pdfMaterial.type === "General";
        });
        setPdfMaterial(general);
      }
      if (eventSelected === "Technology Training") {
        const general = pdfMaterialList.filter((pdfMaterial) => {
          return pdfMaterial.type === "technology training";
        });
        setPdfMaterial(general);
      }
      if (eventSelected === "Wisdom Wednesday") {
        const general = pdfMaterialList.filter((pdfMaterial) => {
          return pdfMaterial.type === "Wisdom Wednesday";
        });
        setPdfMaterial(general);
      }
    }
    if (branchSelected.toUpperCase() === "RW") {
      if (eventSelected === "General") {
        const general = pdfMaterialList.filter((pdfMaterial) => {
          return (
            pdfMaterial.type === "General" &&
            (pdfMaterial.department === "RW" ||
              pdfMaterial.department === "ALL")
          );
        });
        setPdfMaterial(general);
      }
      if (eventSelected === "Technology Training") {
        const tech = pdfMaterialList.filter((pdfMaterial) => {
          return (
            pdfMaterial.type === "technology training" &&
            pdfMaterial.department === "RW"
          );
        });
        setPdfMaterial(tech);
      }
      if (eventSelected === "Wisdom Wednesday") {
        const tech = pdfMaterialList.filter((pdfMaterial) => {
          return (
            pdfMaterial.type === "Wisdom Wednesday" &&
            (pdfMaterial.department === "RW" ||
              pdfMaterial.department === "ALL")
          );
        });
        setPdfMaterial(tech);
      }
    }
    if (branchSelected.toUpperCase() === "IT") {
      if (eventSelected === "General") {
        const general = pdfMaterialList.filter((pdfMaterial) => {
          return (
            pdfMaterial.type === "General" &&
            (pdfMaterial.department === "IT" ||
              pdfMaterial.department === "ALL")
          );
        });
        setPdfMaterial(general);
      }
      if (eventSelected === "Technology Training") {
        const tech = pdfMaterialList.filter(() => {
          return false;
        });
        setPdfMaterial(tech);
      }
      if (eventSelected === "Wisdom Wednesday") {
        const tech = pdfMaterialList.filter((pdfMaterial) => {
          return (
            pdfMaterial.type === "Wisdom Wednesday" &&
            (pdfMaterial.department === "IT" ||
              pdfMaterial.department === "ALL")
          );
        });
        setPdfMaterial(tech);
      }
    }
    if (branchSelected.toUpperCase() === "BAE") {
      if (eventSelected === "General") {
        const general = pdfMaterialList.filter((pdfMaterial) => {
          return (
            pdfMaterial.type === "General" &&
            (pdfMaterial.department === "BAE" ||
              pdfMaterial.department === "ALL")
          );
        });
        setPdfMaterial(general);
      }
      if (eventSelected === "Technology Training") {
        const tech = pdfMaterialList.filter((pdfMaterial) => {
          return false;
        });
        setPdfMaterial(tech);
      }
      if (eventSelected === "Wisdom Wednesday") {
        const tech = pdfMaterialList.filter((pdfMaterial) => {
          return (
            pdfMaterial.type === "Wisdom Wednesday" &&
            (pdfMaterial.department === "BAE" ||
              pdfMaterial.department === "ALL")
          );
        });
        setPdfMaterial(tech);
      }
    }
  }

  async function getPdfList() {
    setPdfMaterial(pdfMaterialList);
  }

  function fileUpdater(preview) {
    setFiles(preview);
  }

  function PdfCloser() {
    dispatch(setIsModalOpen(false));
    dispatch(SetLocalModalName(null));
    setisPreview(false);
  }

  function OpenPdf(index) {
    dispatch(setActiveMaterial(index));
    dispatch(setIsModalOpen(true));
    setisPreview(true);
    dispatch(setPdfLoaded(false));
    dispatch(SetLocalModalName("view pdf"));
  }

  function getChildren(index) {
    if (isPreview === true)
      return (
        <PdfViewer
          files={files}
          index={index}
          file={PdfMaterial}
          PdfCloser={() => PdfCloser()}
          activeWing={branchSelected}
          activeType={eventSelected}
        />
      );
    else
      return (
        <AddTrainingMaterial
          fileUpdater={fileUpdater}
          files={files}
          PdfCloser={() => PdfCloser()}
        />
      );
  }

  function isDataLoading() {
    return pageLoading;
  }
  function onSearch(text) {
    setsearchText(text);
    if (text === "") {
      setPdfMaterial(pdfMaterialList);
      return;
    }
    let tmpData = pdfMaterialList.filter((data) =>
      data.title.toLowerCase().includes(text.toLowerCase())
    );
    setPdfMaterial(tmpData);
  }
  function getPopupWidth() {
    if (isPreview === true) {
      return "100vw";
    }
    return "fit-content";
  }
  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Training materials"}
            NavBarIcon={
              employee.employee_acess.training_material
                ? {
                    icon: "iconamoon:file-add-thin",
                    name: "Add Training",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      <TrainingScreenWrapper>
        <VerticalScrollableAreaTraining height="100" unit="%">
          <TrainingPageNav>
            <SearchContainer>
              <Searchbar
                onSearch={(e) => onSearch(e)}
                searchValue={searchText}
              />
            </SearchContainer>
            <MultipleFilterContainer>
              <FilterContainerTraining>
                {/* {Branches.map((branch, index) => (
                  <FilterTraining
                    variant="outlined"
                    label={branch.name}
                    onClick={() => activeWingUpdater(index)}
                    key={index}
                    index={index}
                    activeindex={activeWing}
                  />
                ))} */}
                <DropdownWithChip
                  list={Branches.map((ele) => ele.name)}
                  selectedOption={branchSelected}
                  handleClick={(event) => setSelectedBranch(event)}
                  buttonWidth="146px"
                />
              </FilterContainerTraining>
              <FilterContainerTraining>
                {/* {EventTypes.map((branch, index) => (
                  <FilterTraining
                    variant="outlined"
                    label={branch}
                    onClick={() => activeTypeUpdater(index)}
                    key={index}
                    index={index}
                    activeindex={activeType}
                  />
                ))} */}
                <DropdownWithChip
                  list={EventTypes}
                  selectedOption={eventSelected}
                  handleClick={(event) => setSelectedEvent(event)}
                  buttonWidth={"216px"}
                />
              </FilterContainerTraining>
            </MultipleFilterContainer>
          </TrainingPageNav>
          <UploadedFilesContainer vertical={PdfMaterial.length % 3 === 0}>
            {isDataLoading() ? (
              <TrainingMaterialHomeShimmer />
            ) : PdfMaterial.length > 0 ? (
              PdfMaterial.map((uploadedFile, index) => (
                <PdfCardContainer
                  key={index}
                  index={index}
                  length={PdfMaterial.length}
                >
                  <PdfFile
                    index={index}
                    file={uploadedFile}
                    OpenPdf={(i) => OpenPdf(i)}
                    showActive={false}
                    length={PdfMaterial.lengthss}
                  />
                </PdfCardContainer>
              ))
            ) : (
              <NoDataComponentContainerTrainingMaterial>
                <NoData noDataText={CommonString.NoTrainingMaterial} />
              </NoDataComponentContainerTrainingMaterial>
            )}
          </UploadedFilesContainer>
        </VerticalScrollableAreaTraining>{" "}
      </TrainingScreenWrapper>
      <ModalPopup
        open={dashboard.isModalOpen}
        styleSX={{
          minWidth: getPopupWidth(),
          maxHeight: "100vh",
          margin: 0,
          overflowX: "hidden",
        }}
      >
        {getChildren(isPreview)}
      </ModalPopup>
    </React.Fragment>
  );
}
