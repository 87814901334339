import EmployeeProvider from "../services/provider/EmployeeProvider";

export default class EmployeeController {
  constructor(props) {
    this.employeeProvider = new EmployeeProvider();
  }
  getEmployeeList = async (color) => {
    let response = await this.employeeProvider.getEmployeeList();
    if (response.status === 200) {
      return response.data.data;
    }
    return [];
  };
  getUpcomingBirthdayEmployeeList = async (color) => {
    let response = await this.employeeProvider.getUpcomingEmployeeList();
    if (response.status === 200) {
      return response.data.data;
    }
    return [];
  };
  addEmployee = async (newEmployeeData) => {
    let EmployeeList = await this.employeeProvider.addEmployee(newEmployeeData);
    // setLocalStorage(employee_list, )
  };
  updateEmployeeDetails = async (updatedDetails) => {
    let EmployeeUpdateResponse =
      await this.employeeProvider.updateEmployeeDetails(updatedDetails);
    // setLocalStorage(employee_list, )
  };
  removeEmployee = async (employeeId) => {
    let DeletedEmployeeResponse = await this.employeeProvider.removeEmployee(
      employeeId
    );
  };
  getPersonalDetails = async (data) => {
    let response = await this.employeeProvider.getPersonalDetails(data);
    if (response.status === 200) {
      return response.data.data;
    } else {
      // console.error("error while loading body measurenment");
      return [];
    }
  };
  updateEmployeePersonalDetails = async (data) => {
    let response = await this.employeeProvider.updateEmployeePersonalDetails(
      data
    );
    if (response.data.status === 200) {
      return true;
    } else {
      console.error("error while updating contact details");
      return false;
    }
  };
  updateMeasurenment = async (data) => {
    let response = await this.employeeProvider.updateMeasurenment(data);
    if (response.status === 200) {
      return true;
    }
    return false;
  };
  getMeasurenment = async (data) => {
    let response = await this.employeeProvider.getMeasurenment(data);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return [];
    }
  };

  addToken = async (data) => {
    let response = await this.employeeProvider.addToken(data);

    if (response.status === 200) {
      return true;
    }
    return false;
  };
}
