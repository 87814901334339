import React from "react";
import { TrapezoidContainer } from "../../../style/common.component.style";

export default function Trapezoid(props) {
  return (
    <TrapezoidContainer
      length={props.length}
      width={props.width}
      color={props.color}
      fontColor={props.fontColor}
    >
      {props.text}
    </TrapezoidContainer>
  );
}
