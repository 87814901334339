import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import { getLocalStorage } from "../services/LocalStorage/LocalStorage";
import { userdata } from "./common.key";
import { CommonString } from "./common.string";
import { level100 } from "./constant";

const DrawerData = (access) => {

  const isAdmin =
    getLocalStorage(userdata) !== null
      ? getLocalStorage(userdata).level.name === level100
      : false;
  return [
    {
      icon: "material-symbols:dashboard-outline",
      iconText: "Dashboard",
      is_show_divider: false,
      for_manager: true,
      is_visible: true,
      key: CommonString.dashboardScreenKey,
      path: "/home/dashboard",
    },
    {
      icon: "fluent:door-arrow-right-28-regular",
      iconText: "Leave Info",
      is_show_divider: false,
      for_manager: true,
      is_visible: access.leave_details,
      key: CommonString.leaveInfoScreenKey,
      path: "/home/leave-info",
    },
    {
      icon: "bx:calendar-exclamation",
      iconText: "Leave Applications",
      is_show_divider: false,
      for_manager: false,
      is_visible: true,
      key: CommonString.leaveApplicationScreenKey,
      path: "/home/leave-application",
    },
// leave report
   
    {
      icon: "mdi:credit-card-add",
      iconText: "Appeal Attendance",
      is_show_divider: false,
      for_manager: true,
      is_visible: true,
      key: CommonString.appeal,
      path: "/home/appeal-attendance",
    },
    // lets-icons:home-light

    {
      icon: "lets-icons:home-light",
      iconText: "Work From Home",
      is_show_divider: false,
      for_manager: true,
      is_visible: isAdmin || access.wfh_screen,
      key: CommonString.work_from_home,
      path: "/home/work-from-home",
    },
    {
      icon: "icon-park-outline:table-report",
      iconText: "Reports",
      is_show_divider: access.reports,
      is_visible: access.reports,
      key: CommonString.reportScreen,
      path: "/home/reports",
    },
    {
      icon: "bx:calendar-star",
      iconText: "Events",
      is_show_divider: access.reports ? false : true,
      for_manager: true,
      is_visible: true,
      key: CommonString.eventsScreenKey,
      path: "/home/events",
    },

    {
      icon: "fluent:document-pdf-20-regular",
      iconText: "Training Materials",
      is_show_divider: false,
      for_manager: true,
      is_visible: true,
      key: CommonString.trainingMaterialScreenKey,
      path: "/home/training-material",
    },
    {
      icon: "material-symbols:celebration-outline",
      iconText: "Life at Prexel",
      is_show_divider: false,
      for_manager: true,
      is_visible: true,
      key: CommonString.prexelLifeScreen,
      path: "/home/life-at-prexel",
    },
    {
      icon: "material-symbols:nest-clock-farsight-analog-outline-rounded",
      iconText: "Office Timings",
      is_show_divider:
        getLocalStorage(userdata) !== null
          ? getLocalStorage(userdata).level.name === level100
          : false,
      for_manager: true,
      is_visible:
        getLocalStorage(userdata) !== null
          ? getLocalStorage(userdata).level.name === level100
          : false,
      key: CommonString.officeTimingScreen,
      path: "/home/office-timings",
    },
    {
      icon: "bx:user",
      iconText: "My Profile",
      is_show_divider: false,
      is_visible: true,
      key: CommonString.profileScreen,
      path: "/home/profile",
    },
    {
      icon: "fluent:people-team-16-regular",
      iconText: "Team Members",
      is_show_divider: false,
      is_visible: true,
      key: CommonString.employeeScreenKey,
      path: "/home/memebers",
    },
    {
      icon: "solar:cup-star-outline",
      iconText: "Synergy League",
      is_show_divider: false,
      is_visible: true,
      key: CommonString.synergyLeagueScreenKey,
      path: "/home/synergy-league",
    },

    {
      icon: "fluent-mdl2:fixed-asset-management",
      iconText: "Assets Management",
      is_show_divider: false,
      is_visible: access.assets,
      key: CommonString.assetManagementScreen,
      path: "/home/assets",
    },
    {
      icon: "tabler:lock-access",
      iconText: "Access Management",
      is_show_divider: false,
      is_visible:
        getLocalStorage(userdata) !== null
          ? getLocalStorage(userdata).level.name === level100
          : false,
      key: CommonString.accessManagement,
      path: "/home/access",
    },
    {
      icon: "hugeicons:policy",
      iconText: "Policy",
      is_show_divider: true,
      is_visible: true,
      key: CommonString.policy,
      path: "/home/policy",
    },
  ];
};

const MonthData = [
  { id: 0, name: "January" },
  { id: 1, name: "February" },
  { id: 2, name: "March" },
  { id: 3, name: "April" },
  { id: 4, name: "May" },
  { id: 5, name: "June" },
  { id: 6, name: "July" },
  { id: 7, name: "August" },
  { id: 8, name: "September" },
  { id: 9, name: "October" },
  { id: 10, name: "November" },
  { id: 11, name: "December" },
];

const Branches = [
  {
    name: "All Wings",
  },
  {
    name: "IT",
  },
  {
    name: "RW",
  },
  {
    name: "BAE",
  },
];

const EventTypes = ["General", "Technology Training", "Wisdom Wednesday"];

const PrexelBranches = [
  {
    id: 1,
    branch: "it",
  },
  {
    id: 2,
    branch: "rw",
  },
  {
    id: 3,
    branch: "bae",
  },
];

const EmployeeLevelManagerList = ["LEVEL-1", "LEVEL-2"];

const ManagerPageTitles = [
  "dashboard",
  "leave info",
  "leave application",
  "events",
  "employees",
  "training materials",
  "Life @ Prexel",
  "Attendance Report",
  "Assets Management",
  "Access Management",
  "Payroll",
  "profile",
];
const EmployeePageTitles = [
  "dashboard",
  "leave application",
  "events",
  "team members",
  "training materials",
  "Life @ Prexel",
  "profile",
];
const Forms = [
  {
    name: "Add Prexelite",
    visibility: true,
  },
  {
    name: "Apply Leave",
    visibility: true,
  },
  {
    name: "Add Event",
    visibility: true,
  },

  {
    name: "Add Training",
    visibility: true,
  },
  {
    name: "default",
    visibility: false,
  },
  {
    name: "Add Office Timings",
    visibility: false,
  },
  {
    name: "Add Assets",
    visibility: false,
  },
  {
    icon: "",
    visibility: false,
  },
];

const workFromHomeType = [
  {
    name: "Single Day",
    icon: Cog6ToothIcon,
    desc: "this is full day",
  },
  {
    name: "Multiple Day",
    icon: Cog6ToothIcon,
    desc: "this is multiple day",
  },
];
const leaveTypes = [
  {
    name: "Half Day",
    icon: Cog6ToothIcon,
    desc: "this is half day",
  },
  {
    name: "Single Day",
    icon: Cog6ToothIcon,
    desc: "this is full day",
  },
  {
    name: "Multiple Day",
    icon: Cog6ToothIcon,
    desc: "this is multiple day",
  },
  {
    name: "Late",
    icon: Cog6ToothIcon,
    desc: "this is late day",
  },
];

const apileTypes = [
  {
    name: "Late",
    icon: Cog6ToothIcon,
    desc: "late apile",
  },
  {
    name: "Absent",
    icon: Cog6ToothIcon,
    desc: "leave apile",
  },
];

const ProfileScreenScrollHeightBreakPoint = {
  initial: 2,
  second: 20,
  third: 40,
  middle: 60,
  fifth: 80,
  sixth: 100,
  seventh: 120,
  eigth: 180,
  ninth: 240,
  tenth: 300,
};

const AssetsTypes = [
  "laptop",
  "charger",
  "mouse",
  "headPhones",
  "external screen",
  "mobile",
  "car",
  "bike",
  "other",
];

// const screenVisiblityManager = {
//   dashboard: true,
//   leave_info: true,
//   leave_application: true,
//   event_list: true,
//   team_members: true,
//   training_material: true,
//   celebration: true,
//   attendance_report: true,
//   asset_management: true,
//   access_management: true,
//   payroll: true,
//   profile: true,
// };
const ProfileContactInfo = {
  email: "hitenp@prexelllc.com",
  phnNum: "7990347348",
  emergencyContact: "Not updated",
  personalEmailID: "hitenpatel0811@gmail.com",
  googleFitEmailID: "hitenpatel0811@gmail.com",
  aadharCardNum: "7990347348",
  passportNum: "Not updated",
  nationality: "Indian",
  currentAddress:
    "C-30, Uma Nagar Society, Tarsali, VadodaraC-30, Uma Nagar Society, Tarsali, Vadodara",
  permanentAddress:
    "C-30, Uma Nagar Society, Tarsali, VadodaraC-30, Uma Nagar Society, Tarsali, Vadodara",
  "Joining Date": "26/05/2020",
  birthday: "08/11/2001",
  "Marital Status": "Single",
  anniversary: "Date",
  "Year/s’ of Experience": "1 Year",
  hobbies: "Cricket, Guitar, Football",
  sex: "Male",
  employeeType: "Prexelite/Contractual/Hourly Based/Intern",
};
export {
  AssetsTypes,
  Branches,
  DrawerData,
  EmployeeLevelManagerList,
  EmployeePageTitles,
  EventTypes,
  Forms,
  ManagerPageTitles,
  PrexelBranches,
  ProfileScreenScrollHeightBreakPoint,
  leaveTypes,
  apileTypes,
  MonthData,
  workFromHomeType,
};
