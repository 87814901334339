import { Icon } from "@iconify/react";
import { AppBar, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OfficeTimingForm from "../../component/Forms/OfficeTimingForm";
import ModalPopup from "../../component/Popup/ModalPopup";
import NavBar from "../../component/drawer/NavBar";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { FlexContainer } from "../../style/asset.style";
import { TypoGraphy } from "../../style/common.style";
import { AppBarStyle } from "../../style/drawer.style";
import { HolidayStatus } from "../../style/holiday.style";
import {
  CardContainer,
  OfficeTimingCard,
  ShiftContainer,
} from "../../style/office.timing.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { userdata } from "../../utils/common.key";
import { CommonString } from "../../utils/common.string";
import { level100 } from "../../utils/constant";
import { formatTimeTwelveHour } from "../../utils/utils";

export default function OfficeTimings(props) {
  const [localModalOpen, setLocalModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const dashboard = useSelector((state) => state.dashboard);
  const attendance = useSelector((state) => state.attendance);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.loadedPages.includes(CommonString.officeTimingScreen)) {
        getData();
    }
  }, []);

  async function getData() {
    setPageLoading(true);
    await props.getOfficeTimings();
    setPageLoading(false);
  }

  function getModalChildren() {
    return (
      <OfficeTimingForm
        onHandleClose={() => ClosePopup()}
        data={modalData}
        localModalOpen={localModalOpen}
      />
    );
  }
  function ClosePopup() {
    setLocalModalOpen(false);
    dispatch(setIsModalOpen(false));
    setModalData(null);
  }
  function updateData(data) {
    setModalData(data);
    setLocalModalOpen(true);
  }
  return (
    <React.Fragment>
      <ModalPopup open={dashboard.isModalOpen || localModalOpen}>
        {getModalChildren()}
      </ModalPopup>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Office Timings"}
            NavBarIcon={
              getLocalStorage(userdata).level.name === level100
                ? {
                    icon: "mdi:clock-plus-outline",
                    name: "Add Office Timing",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      <CardContainer>
        {attendance?.shift.map((shiftData) => {
          return (
            <OfficeTimingCard
              iseditvisible={getLocalStorage(userdata).level.name === level100}
              key={shiftData._id}
            >
              <FlexContainer jc="center">
                <TypoGraphy
                  fontSize={FontSizes.Title}
                  weight="700"
                  color={CommonColor.secondary_color}
                >
                  {formatTimeTwelveHour(shiftData.in_time)}
                </TypoGraphy>
                &nbsp;
                <TypoGraphy
                  fontSize={FontSizes.Title}
                  weight="700"
                  color={CommonColor.secondary_color}
                >
                  -
                </TypoGraphy>
                &nbsp;
                <TypoGraphy
                  fontSize={FontSizes.Title}
                  weight="700"
                  color={CommonColor.secondary_color}
                >
                  {formatTimeTwelveHour(shiftData.out_time)}
                </TypoGraphy>
              </FlexContainer>
              <ShiftContainer>
                <TypoGraphy
                  fontSize={FontSizes.Title}
                  weight="600"
                  color={CommonColor.primary_color}
                  textTransform={"uppercase"}
                >
                  {shiftData.shift_type}
                </TypoGraphy>
                <HolidayStatus className="edit">
                  <Icon
                    icon="ph:note-pencil-thin"
                    color={CommonColor.secondary_color}
                    fontSize={20}
                    onClick={() => updateData(shiftData)}
                  />
                </HolidayStatus>
              </ShiftContainer>
            </OfficeTimingCard>
          );
        })}
      </CardContainer>
    </React.Fragment>
  );
}
