import { AppBar, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import NavBar from "../../component/drawer/NavBar";
import Celebration from "../../component/holiday/Celebration";
import Holiday from "../../component/holiday/Holiday";
import HolidayNavBar from "../../component/holiday/HolidayNavBar";
import SpeedyPrexeliteList from "../../component/holiday/SpeedyPrexeliteList";
import { MobileScroller } from "../../style/common.style";
import { AppBarStyle } from "../../style/drawer.style";
import {
  ActivityScreenContainer,
  HolidayScreenContainer,
  HolidayScreenSection,
  LifeAtPrexelWrapper,
  LoaderContainerLPW,
} from "../../style/holiday.style";
import { SpeedyPrexeliteTitle } from "../../utils/array.screens";
import { CommonString } from "../../utils/common.string";
import { tab } from "../../utils/constant";
import { checkIfExist } from "../../utils/utils";
import PrexelMemories from "../PrexelMemories/PrexelMemories";

export default function HolidayList(props) {
  const screenIndex = checkIfExist(localStorage.getItem(tab))
    ? localStorage.getItem(tab)
    : SpeedyPrexeliteTitle[0].name;
  const [screen, setScreen] = useState(screenIndex);
  const [pageLoading, setPageLoading] = useState(true);
  const employee = useSelector((state) => state.employee);
  const pagetransitionstore = useSelector((state) => state.pagetransition);
  const dashboard = useSelector((state) => state.dashboard);
  const holidayStore = useSelector((state) => state.holiday);

  useEffect(() => {
    if (holidayStore.holiday_list_by_month.length !== 0) {
      setPageLoading(false);
      return;
    }
    if (!props.loadedPages.includes(CommonString.prexelLifeScreen)) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (pagetransitionstore.tab !== null) {
      setScreen(pagetransitionstore.tab);
    }
  }, [pagetransitionstore.tab]);

  async function getData() {
    setPageLoading(true);
    await props.getLifeAtPrexel();
    await props.getTypingData();
    await props.getEmployeeList();
    setPageLoading(false);
  }

  function handleClick(index) {
    setScreen(index);
    localStorage.setItem(tab, index);
  }
  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Life at Prexel"}
            NavBarIcon={
              !employee.employee_acess
                ? {
                    icon: "",
                    name: "",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      <HolidayScreenContainer container>
        <LifeAtPrexelWrapper>
          <HolidayNavBar handleClick={handleClick} screen={screen} />

          {pageLoading ? (
            <LoaderContainerLPW>
              <PageRefreshLoader />
            </LoaderContainerLPW>
          ) : (
            <React.Fragment>
              <MobileScroller mobileHeight="93" height="93" unit="%">
                <ActivityScreenContainer>
                  <HolidayScreenSection
                    isvisible={screen === SpeedyPrexeliteTitle[1].name ? 1 : 0}
                  >
                    <Celebration
                      dataList={employee.employee_birthday_list}
                      isvisible={screen === SpeedyPrexeliteTitle[1].name}
                    />
                  </HolidayScreenSection>
                  <HolidayScreenSection
                    isvisible={screen === SpeedyPrexeliteTitle[0].name ? 1 : 0}
                  >
                    <Holiday
                      isvisible={screen === SpeedyPrexeliteTitle[0].name}
                    />
                  </HolidayScreenSection>
                  <HolidayScreenSection
                    isvisible={screen === SpeedyPrexeliteTitle[2].name ? 1 : 0}
                  >
                    <SpeedyPrexeliteList />
                  </HolidayScreenSection>
                  <HolidayScreenSection
                    isvisible={screen === SpeedyPrexeliteTitle[3].name ? 1 : 0}
                  >
                    <PrexelMemories />
                  </HolidayScreenSection>
                </ActivityScreenContainer>
              </MobileScroller>
            </React.Fragment>
          )}
        </LifeAtPrexelWrapper>
      </HolidayScreenContainer>
    </React.Fragment>
  );
}

/*

*/
