import React, { useState } from "react";

import {
  EmployeeContainer,
  EmployeeLoaderContainer,
  NoDataEmpListTextHighlight,
} from "../../../style/dashboard.style";
import CardTitle from "./CardTitle";

import { Box } from "@mui/material";
import {
  AppearAnimation,
  RelativePosContainer,
} from "../../../style/common.style";
import CommonColor from "../../../utils/common.colors";
import EmployeeDailyLogsShimmer from "../../Loader/shimmers/EmployeeDailyLogsShimmer";
import EmployeeListAttendanceStatus from "../../Popup/EmployeeListAttendanceStatus";
import ModalPopup from "../../Popup/ModalPopup";
import NoDataDashboard from "../NoData/NoDataDashboard";
import EmployeeList from "./EmployeeListStatus/EmployeeList";

export default function EmployeeListStatus(props) {
  
  const [scrollPos, setScrollPos] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const [openContent, setOpenContent] = useState(false);

  function changeDrawerIndex() {
    setOpenPopup(true);
  }

  function setterScrollPos(event) {
    const posInPercent = (event.currentTarget.scrollLeft / 169) * 100;
    setScrollPos(posInPercent);
  }

  function getModalChildren() {
    return (
      <EmployeeListAttendanceStatus onHandleClose={() => setOpenPopup(false)} />
    );
  }

  function toggleContent() {
    setOpenContent(!openContent);
  }

  return (
    <React.Fragment>
      <ModalPopup
        open={openPopup}
        styleSX={{
          minWidth: "100vw",
          maxHeight: "100vh",
          margin: 0,
          overflowX: "hidden",
        }}
      >
        {getModalChildren()}
      </ModalPopup>
      <AppearAnimation
        delay={props.serialOrder}
        duration={props.serialOrder}
        timingvalue="ease-in-out"
      >
        <Box
          sx={{
            width: "100%",
            padding: "3%",
            paddingBottom: openContent ? "0%" : "",
            backgroundColor: CommonColor.white,
            borderRadius: "4px",
            marginBottom: "4%",
            display: props.isVisible ? "" : "none",
          }}
        >
          <CardTitle
            title={props.title}
            buttonChoice="2"
            activeIndexUpdater={() => changeDrawerIndex(false)}
            contentToggler={() => toggleContent()}
            openContent={openContent}
            cardLocation={props.cardLocation}
            data={props.presentList}
            viewMoreText="view more"
          />
          {props.LoadingFlag ? (
            openContent ? (
              <EmployeeLoaderContainer>
                <EmployeeDailyLogsShimmer />
              </EmployeeLoaderContainer>
            ) : (
              ""
            )
          ) : props.presentList?.length === 0 ? (
            <NoDataDashboard
              text={
                <>
                  No&nbsp;
                  <NoDataEmpListTextHighlight>
                    prexelites&nbsp;
                  </NoDataEmpListTextHighlight>
                  is in the list currently
                </>
              }
              icon="material-symbols:celebration-outline-rounded"
            />
          ) : (
            <RelativePosContainer
              scroll={scrollPos}
              isvisible={openContent ? 1 : 0}
            >
              <EmployeeContainer onScroll={(event) => setterScrollPos(event)}>
                {openContent ? (
                  <EmployeeList
                    presentList={props.presentList}
                    shownBirthday={props.shownBirthday}
                    cardLocation={props.cardLocation}
                    ScreenOpener={() => {}}
                  />
                ) : (
                  ""
                )}
              </EmployeeContainer>
            </RelativePosContainer>
          )}
        </Box>
      </AppearAnimation>
    </React.Fragment>
  );
}

/*  */
