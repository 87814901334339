import React from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

export default function LeaveShimmerDashboard() {
  return (
    <div>
      <ShimmerThumbnail height={250} rounded />
      <ShimmerThumbnail height={250} rounded />
    </div>
  );
}
