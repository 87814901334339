import { forEach } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveDrawerkey } from "../../../reducer/dashboardreduxstore";
import EventCardHome from "../../../screen/EventList/EventCardHome";
import {
  BaseCardDashboardContainerSecond,
  DashboardEventsContainer,
  NoDataComponentContainer,
} from "../../../style/dashboard.style";
import { CommonString } from "../../../utils/common.string";
import { getActiveDrawerData } from "../../../utils/utils";
import EventShimmer from "../../Loader/shimmers/EventShimmer";
import NoData from "../NoData/NoData";
import CardTitle from "./CardTitle";

export default function EventsCard(props) {
  const dispatch = useDispatch();
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [openContent, setOpenContent] = useState(false);
  const event = useSelector((state) => state.event);
  const employee = useSelector((state) => state.employee);
  const history = useHistory();

  function getEventScreen() {
    return getActiveDrawerData(employee.employee_acess).filter((ele) => {
      return ele.key === CommonString.eventsScreenKey;
    })[0];
  }
  function getEventScreenIndex() {
    let index = 0;
    forEach(getActiveDrawerData(employee.employee_acess), function (ele) {
      ++index;
      if (ele.key === CommonString.eventsScreenKey) return false;
    });
    return index;
  }

  function NavigateEventScreen() {
    const eventScreen = getEventScreen();
    const index = getEventScreenIndex();
    dispatch(setActiveDrawerkey({ element: eventScreen, index: index - 1 }));
    history.push(eventScreen.path);
  }

  function toggleContent() {
    setOpenContent(!openContent);
  }
  function getView() {
    if (!openContent) return;
    if (event.current_day_event.length === 0)
      return (
        <NoDataComponentContainer>
          <NoData noDataText={CommonString.noUpcomingFound} />
        </NoDataComponentContainer>
      );
    return (
      <DashboardEventsContainer>
        {event.current_day_event.map((EventDetail, index, array) => (
          <EventCardHome
            index={index}
            prevEle={array[index - 1]}
            ele={EventDetail}
            setSelectedEvent={setSelectedEvent}
            selectedEvent={selectedEvent}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            key={index}
          />
        ))}
      </DashboardEventsContainer>
    );
  }
  return (
    <React.Fragment>
      <BaseCardDashboardContainerSecond isvisible={1} delay={props.serialOrder}>
        <CardTitle
          title={"Events"}
          buttonChoice="2"
          activeIndexUpdater={() => NavigateEventScreen()}
          cardLocation={() => NavigateEventScreen()}
          contentToggler={() => toggleContent()}
          openContent={openContent}
          viewMoreText={"view more"}
        />
        {props.LoadingFlag ? <EventShimmer /> : getView()}
      </BaseCardDashboardContainerSecond>
    </React.Fragment>
  );
}
