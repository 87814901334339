import Lottie from "react-lottie";
import styled from "styled-components";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { DeviceSizes } from "./common.style";

const LoaderPageRefresh = styled(Lottie)`
  margin-top: ${(props) => props.mt ?? "30vh"};
  margin: ${(props) => props.margin ?? "0vh"};
`;
const EmplyeeShimmerContainer = styled.div`
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 4%;
  width: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TrainingShimmerBlock = styled.div`
  width: 100%;
`;
const TrainingShimmerCard = styled(ShimmerThumbnail)``;
const EmployeeScreenShimmerCard = styled(TrainingShimmerCard)``;
const EmployeeScreenShimmerBlock = styled.div`
  && {
    margin: 1%;
    & .shimmer {
      height: 230px !important;
    }
    @media ${DeviceSizes.mobile} {
      width: 46% !important;
      & .shimmer {
        width: 100% !important;
        height: 130px !important;
      }
    }
  }
`;
const LoginShimmerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1%;
  width: 100%;
`;
const LoginShimmerText = styled.div`
  width: 80%;
  margin-right: 10%;
`;
const EmployeeListShimmerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const EmployeeShimmerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  & .grid {
    width: ${(props) => props.gridSize ?? "90%"};
  }
`;
const EventListShimmerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
  & div:has(.shimmer-title-line) {
    width: 40%;
  }
`;
const EventsShimmerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2%;
  width: 100%;
  & div:first-child:has(.shimmer) {
    width: 20%;
  }
  & div:nth-child(2):has(.shimmer) {
    width: 80%;
  }
  @media ${DeviceSizes.mobile} {
    & div:nth-child(2):has(.shimmer) {
      width: 70%;
    }
  }
`;
const EmployeeAttendanceShimmerContainer = styled.div`
  width: 100%;
`;
const EmplyeeShimmerNav = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  & .shimmer-title {
    width: 90%;
  }
`;
const EmployeeShimmerNavTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const EmployeeShimmerBody = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const EmployeeShimmerBodyText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 10%;
  & .shimmer-text {
    width: 100%;
  }
`;
const RectangleLoader = styled(ShimmerThumbnail)`
  margin-bottom: 0;
`;
const Loader = styled(Lottie)`
  display: flex;
  justify-content: center;
  align-items: center;
  & div {
    margin: ${(props) => props.margin};
  }
`;

const BookMarkAniamtionContainer = styled.div`
  & div {
    margin: ${(props) => props.margin};
  }
`;
export {
  BookMarkAniamtionContainer,
  Loader,
  RectangleLoader,
  EmployeeShimmerBodyText,
  EmployeeShimmerBody,
  EmployeeShimmerNavTitle,
  EmplyeeShimmerNav,
  EmployeeAttendanceShimmerContainer,
  EventsShimmerContainer,
  EventListShimmerContainer,
  EmployeeShimmerContainer,
  EmployeeListShimmerContainer,
  LoginShimmerText,
  LoginShimmerContainer,
  LoaderPageRefresh,
  EmplyeeShimmerContainer,
  TrainingShimmerBlock,
  TrainingShimmerCard,
  EmployeeScreenShimmerCard,
  EmployeeScreenShimmerBlock,
};
