import React, { useEffect, useState } from "react";
///css section
import "react-notifications/lib/notifications.css";
import "./App.css";

import { SnackbarProvider } from "notistack";
import Routes from "./Routes";
import { getLocalStorage } from "./services/LocalStorage/LocalStorage";
import { api_token_key, userdata } from "./utils/common.key";

import { useHistory, useLocation } from "react-router-dom";
import EmployeeController from "./controller/EmployeeController";
import { getTokenfromdevice } from "./services/Firebase/firebase";
import { NoDataContainer, TypoGraphy } from "./style/common.style";
import { NoInterntIllustration } from "./style/style.app";
import { FontSizes } from "./style/theme/font";
import { CommonString } from "./utils/common.string";

export default function App() {
  const location = useLocation();
  const history = useHistory();
  const employeeController = new EmployeeController();
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    var condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      fetch("https://www.google.com/", {
        // Check for internet connectivity
        mode: "no-cors",
      })
        .then(() => {
          setIsOnline(true);
        })
        .catch(() => {
          setIsOnline(false);
        });
    } else {
      setIsOnline(false);
    }
    try {
      getfbToken();
    } catch (e) {
      console.error("error in getting firebase token : =>", e);
    }
  }, []);

  async function getfbToken() {
    let fbtoken = await getTokenfromdevice();
    addfirebaseToken(fbtoken);
    getTokenfromdevice();
  }
  async function addfirebaseToken(token) {
    let user = getLocalStorage(userdata);
    if (user === null || user === "") return;
    let user_id = user.user._id;
    let data = {
      token: token,
      user_id: user_id,
      device_type: "WEB",
    };
    let res = await employeeController.addToken(data);
  }

  useEffect(() => {
    if (location.pathname === "/home") {
      let token = getLocalStorage(api_token_key);
      const userData = getLocalStorage(userdata);
      if (token === null || userData === null) {
        history.push("/");
        return;
      }
    }
  }, []);

  return (
    <React.Fragment>
      <React.Fragment>
        {!isOnline ? (
          <NoDataContainer width="100%" height="100vh" svgHeight="350px">
            <NoInterntIllustration width="100%" height="50%" />
            <TypoGraphy weight="600" fontSize={FontSizes.Subtitle}>
              {CommonString.noInternet}
            </TypoGraphy>
          </NoDataContainer>
        ) : (
          <SnackbarProvider
            maxSnack={10}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Routes />
          </SnackbarProvider>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
