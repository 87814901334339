import { initializeApp } from "firebase/app";
import "firebase/compat/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { vapidKey } from "../../utils/constant";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA3o978joJXkV64hFO6RFAB9yHex9bERQk",
  authDomain: "prexel-attendance.firebaseapp.com",
  projectId: "prexel-attendance",
  storageBucket: "prexel-attendance.appspot.com",
  messagingSenderId: "383041096350",
  appId: "1:383041096350:web:240ac1865166447edd498f",
  measurementId: "G-7PWSGHXQMJ",
};
let firebaseApp;
// Initialize Firebase
try {
  firebaseApp = initializeApp(firebaseConfig);
} catch (e) {
  console.error(e);
}

export { firebaseApp };
const messaging = getMessaging(firebaseApp);

export const getTokenfromdevice = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey: vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
        // setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.error(
          "No registration token available. Request permission to generate one."
        );
        return false;

        // setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
