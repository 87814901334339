import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDrawerkey } from "../../../reducer/dashboardreduxstore";

import {
  DashboardCardContainer,
  EmployeeContainer,
  EmployeeLoaderContainer,
  NoDataEmpListTextHighlight,
} from "../../../style/dashboard.style";
import CarouselEmployee from "../carousel/CarouselEmployee";
import CardTitle from "./CardTitle";

import { forEach } from "lodash";
import { useHistory } from "react-router-dom";
import {
  EmployeePopupOpener,
  OpenTab,
} from "../../../reducer/pagetransitionstore";
import { RelativePosContainer } from "../../../style/common.style";
import { SpeedyPrexeliteTitle } from "../../../utils/array.screens";
import { CommonString } from "../../../utils/common.string";
import { getActiveDrawerData } from "../../../utils/utils";
import EmployeeDailyLogsShimmer from "../../Loader/shimmers/EmployeeDailyLogsShimmer";
import NoDataDashboard from "../NoData/NoDataDashboard";
import { tab } from "../../../utils/constant";

export default function EmployeeListCard(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [scrollPos, _] = useState(0);
  const [openContent, setOpenContent] = useState(false);
  const employee = useSelector((state) => state.employee);

  function getTeamMemberScreen() {
    return getActiveDrawerData(employee.employee_acess).filter((ele) => {
      return ele.key === CommonString.employeeScreenKey;
    })[0];
  }
  function getEmployeeScreenIndex() {
    let index = 0;
    forEach(getActiveDrawerData(employee.employee_acess), function (ele) {
      ++index;
      if (ele.key === CommonString.employeeScreenKey) return false;
    });
    return index;
  }

  function getUpcomingBirthdayScreen() {
    return getActiveDrawerData(employee.employee_acess).filter((ele) => {
      return ele.key === CommonString.prexelLifeScreen;
    })[0];
  }

  function getUpcomingBirthdayIndex() {
    let index = 0;
    forEach(getActiveDrawerData(employee.employee_acess), function (ele) {
      ++index;
      if (ele.key === CommonString.prexelLifeScreen) return false;
    });
    return index;
  }

  function changeDrawerIndex() {
    if (props.title === "Team Members") {
      const teamMemberScreen = getTeamMemberScreen();
      const index = getEmployeeScreenIndex();
      dispatch(
        setActiveDrawerkey({ element: teamMemberScreen, index: index - 1 })
      );
      history.push(teamMemberScreen.path);
    }
    if (props.title === "Upcoming Birthday") {
      const getUpcomingBirthdayScreenIndex = getUpcomingBirthdayScreen();
      const index = getUpcomingBirthdayIndex();
      dispatch(OpenTab(SpeedyPrexeliteTitle[1].name));
      localStorage.setItem(tab, SpeedyPrexeliteTitle[1].name);
      dispatch(
        setActiveDrawerkey({
          element: getUpcomingBirthdayScreenIndex,
          index: index - 1,
        })
      );
      history.push(getUpcomingBirthdayScreenIndex.path);
    }
  }
  function ScreenOpener(employee) {
    if (props.title === "Upcoming Birthday") {
      const getUpcomingBirthdayScreenIndex = getUpcomingBirthdayScreen();
      const index = getUpcomingBirthdayIndex();
      dispatch(OpenTab(CommonString.birthdayTitle));
      dispatch(
        setActiveDrawerkey({
          element: getUpcomingBirthdayScreenIndex,
          index: index,
        })
      );
      return;
    }
    const teamMemeberScreen = getTeamMemberScreen();
    const index = getEmployeeScreenIndex();
    dispatch(EmployeePopupOpener(employee.name));
    dispatch(
      setActiveDrawerkey({
        element: teamMemeberScreen,
        index: index,
      })
    );
  }

  function scrollLeft(event) {
    if (event.deltaY === 0) return;

    const ele = event.currentTarget;
    ele.scrollTo({
      top: 0,
      left: ele.scrollLeft + event.deltaY,
      behavior: "smooth",
    });
  }
  function toggleContent() {
    setOpenContent(!openContent);
  }
  return (
    <React.Fragment>
      <DashboardCardContainer
        isvisible={props.isVisible ? 1 : 0}
        delay={props.serialOrder}
        ispb={!openContent ? 1 : 0}
      >
        <CardTitle
          title={props.title}
          buttonChoice="2"
          activeIndexUpdater={() => changeDrawerIndex()}
          cardLocation={props.cardLocation}
          contentToggler={() => toggleContent()}
          openContent={openContent}
          notificationNum={props.data.length}
          data={props.data}
          viewMoreText={"View More"}
        />
        {props.LoadingFlag ? (
          openContent ? (
            <EmployeeLoaderContainer>
              <EmployeeDailyLogsShimmer />
            </EmployeeLoaderContainer>
          ) : (
            ""
          )
        ) : props.data.length === 0 ? (
          <NoDataDashboard
            text={
              <>
                No&nbsp;
                <NoDataEmpListTextHighlight>
                  prexelites&nbsp;
                </NoDataEmpListTextHighlight>
                were born in this month.
              </>
            }
            icon="material-symbols:celebration-outline-rounded"
          />
        ) : (
          <RelativePosContainer
            scroll={scrollPos}
            mobileWidth="100%"
            isvisible={openContent ? 1 : 0}
          >
            <EmployeeContainer onWheel={(event) => scrollLeft(event)}>
              {openContent ? (
                <CarouselEmployee
                  data={props.data}
                  title={props.title}
                  shownBirthday={props.shownBirthday}
                  cardLocation={props.cardLocation}
                  ScreenOpener={ScreenOpener}
                />
              ) : (
                ""
              )}
            </EmployeeContainer>
          </RelativePosContainer>
        )}
      </DashboardCardContainer>
    </React.Fragment>
  );
}

/*  */
