import { Icon } from "@iconify/react";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AttendanceController from "../../controller/attendanceController";
import AuthController from "../../controller/authController";
import {
  setActiveDrawerkey,
  setAttendanceStatus,
} from "../../reducer/dashboardreduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { SpaceInserter } from "../../style/common.style";
import {
  FormContainer,
  FormSubmitButton,
  IconButton,
  IconifyIconInput,
} from "../../style/form.style";
import { userdata } from "../../utils/common.key";
import { activePath, pageIndexKey, pageIndexTitle } from "../../utils/constant";
import { DrawerData } from "../../utils/mockData";
import GreyTextInput from "../commonComponents/inputs/GreyTextInput";
import PageRefreshLoader from "../Loader/PageRefreshLoader";

export default function LoginForm(props) {
  let history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordVisibile, setPasswordVisibile] = useState(false);
  const attendanceController = new AttendanceController();
  const employee = useSelector((state) => state.employee);
  const dispatch = useDispatch();

  const authController = new AuthController();

  async function LoginAction() {
    try {
      const trimmedMail = email.trim();
      const data = {
        email: trimmedMail,
        password: password,
      };
      const result = await authController.onLogin(data);
      if (result === true) {
        props.loaderStarter();
        const today = moment().utc().subtract(5, "hours").format("YYYY-MM-DD");
        const data = {
          user_id: getLocalStorage(userdata).user._id,
          date: today,
        };
        const attendanceData = await attendanceController.checkAttendance(data);
        dispatch(setAttendanceStatus(attendanceData));
        setTimeout(() => {
          dispatch(
            setActiveDrawerkey({
              element: DrawerData[0],
              index: 0,
            })
          );
          localStorage.setItem(
            activePath,
            DrawerData(employee.employee_acess)[0].path
          );
          localStorage.setItem(
            pageIndexKey,
            DrawerData(employee.employee_acess)[0].key
          );
          localStorage.setItem(pageIndexTitle, 0);
          history.push("/home/dashboard");
        }, 1000);
      } else {
        if (!email && !password) props.errorSet(0);
        else {
          props.errorSet(1);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function togglePasswordVisibility() {
    setPasswordVisibile(!passwordVisibile);
  }

  return (
    <FormContainer>
      <SpaceInserter vertical="0.3vh" horizontal="100%" />
      <GreyTextInput
        title={"Email"}
        type="email"
        placeholder={"community@gmail.com"}
        icon={<Icon icon="ic:outline-email" />}
        onKeyDown={(event) => (event.key === "Enter" ? LoginAction() : "")}
        value={email}
        onChange={(newValue) => {
          props.errorSet(false);
          setEmail(newValue.target.value);
        }}
        EndIcon={<IconifyIconInput icon="ic:bascaeline-email" />}
        error={props.isErrorExist}
        errorExisting={props.isErrorExist === "email" ? "Wrong Email!" : ""}
      />
      <SpaceInserter vertical="0.3vh" horizontal="100%" />
      <GreyTextInput
        title={"Password"}
        placeholder={"•••••••••••••••••"}
        error={props.isErrorExist}
        type={passwordVisibile ? "text" : "password"}
        icon={<Icon icon="bx:lock-alt" />}
        value={password}
        onChange={(newValue) => {
          props.errorSet(false);
          setPassword(newValue.target.value);
        }}
        onKeyDown={(event) => (event.key === "Enter" ? LoginAction() : "")}
        EndIcon={
          <IconButton onClick={togglePasswordVisibility}>
            <IconifyIconInput
              icon={
                passwordVisibile
                  ? "ant-design:eye-invisible-filled"
                  : "ant-design:eye-filled"
              }
            />
          </IconButton>
        }
      />
      <SpaceInserter vertical="0.3vh" horizontal="100%" />
      <FormSubmitButton
        variant="contained"
        onClick={() => LoginAction()}
        disabled={props.isloading}
        size="medium"
      >
        {!props.isloading ? "Login" : <PageRefreshLoader height="22px" />}
      </FormSubmitButton>
    </FormContainer>
  );
}

/*

*/
