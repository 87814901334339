import { Button } from "@mui/material";
import styled, { keyframes } from "styled-components";
import CommonColor from "../utils/common.colors";
import { CommonImages } from "../utils/common.images";
import { DeviceSizes, dashboardCardLoadAnimation } from "./common.style";
import { CelebrationMonthContainer } from "./holiday.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";

const EventListConatainer = styled.div`
  /* background-color: ${Theme.primary_color.white}; */
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: ${CommonColor.white};
  @media ${DeviceSizes.belowtablet} {
    margin-top: 0%;
  }
  @media ${DeviceSizes.mobile} {
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 0%;
    width: 100%;
    padding: 0;
  }
`;
const DateContainer = styled.div`
  width: 100%;
  display: flex;
  margin-left: 0%;
  margin-bottom: ${(props) =>
    props.length < 6 ? "1%" : `calc(33%/${props.length})`};
  margin-bottom: 0%;
`;

const EventDate = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  color: ${Theme.primary_color.primary_color};
  font-size: ${FontSizes.Para};
  margin-top: ${(props) => (props.isfirst ? "0%" : "4%")};
  margin-right: 2%;

  @media ${DeviceSizes.mobile} {
    margin-top: ${(props) => (props.isfirst ? "0%" : "5%")};
  }
`;
const IsToday = styled.div`
  font-family: Mulish;
  font-weight: 400;
  font-size: 0.6em;
  color: ${Theme.primary_color.primary_color};
  margin-left: 1%;
  display: inline;
  margin-top: 0.5%;
  @media ${DeviceSizes.mobile} {
    margin-top: 2%;
    margin-left: 3%;
  }
`;
const EventContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  position: relative;
  @media ${DeviceSizes.mobile} {
    margin-top: 4%;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;

const TimeAndBranchContainer = styled.div`
  padding: 1.5% 0% 2.5% 0%;
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  width: 150px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media ${DeviceSizes.mobile} {
    padding: 5%;
    width: 140px;
  }
`;
const EventTime = styled.div`
  color: ${Theme.primary_color.title_color};
  font-family: "Mulish";

  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: ${(props) => props.lineHeight};
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.SubPara};
  }
`;

const EventBranch = styled.div`
  color: ${Theme.primary_color.primary_color};
  font-family: "Mulish";

  font-weight: 700;
  font-size: ${FontSizes.SubPara};
  letter-spacing: 0.01em;
  text-transform: capitalize;
  line-height: 1em;

  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.SmallText};
  }
`;
const TitleAndConductorContainer = styled.div`
  margin-left: 2%;
  margin-top: 0%;
  box-shadow: 4px 4px 31px -7px rgba(0, 0, 0, 0.15);
  padding: 2%;
  border-radius: 10px;
  padding-right: ${(props) => (props.iscard ? "8%" : "5%")};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & button {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    & button {
      opacity: 1;
    }
  }
  @media ${DeviceSizes.mobile} {
    padding: 5%;
    width: 100%;
    /* margin-left: 5%; */
  }
`;
const Title = styled.div`
  color: ${Theme.primary_color.primary_color};
  font-family: "Mulish";

  font-size: 80%;
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.SmallText};
  }
`;

const ConductingPersonContainer = styled.div`
  display: flex;
  margin-top: 3%;
  font-size: 80%;
  @media ${DeviceSizes.mobile} {
    margin-top: 7%;
  }
`;
const ConductingPersonTitle = styled.div`
  color: ${Theme.primary_color.sub_title};
  font-family: "Mulish";

  font-weight: 500;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.Para};
  }
`;

const ConductingPersonName = styled.div`
  color: ${Theme.primary_color.sub_title};
  font-family: "Mulish";

  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.Para};
  }
`;

const DividinLineContainer = styled.div`
  margin-top: 1%;
  margin-bottom: 1%;
  @media ${DeviceSizes.mobile} {
    margin-top: 5%;
    margin-bottom: 5%;
  }
`;
const EventContainerDivider = () => {
  return {
    backgroundColor: Theme.primary_color.divider_background,
  };
};

const DeletePdfButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0%;
    height: 100%;
    width: 100%;
    && {
      border-radius: 50%;
    }
  }
`;
const TitleEditButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const disAppear = keyframes`
from{
  opacity:1;
  display: flex;
}
to{
  opacity:0;
  display: none;
}
`;
const ButtonContainer = styled.div`
  display: flex;
  opacity: 1;
  align-items: center;
  animation-name: ${disAppear};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: ${(props) =>
    props.isvisible === 1 ? "backwards" : "forwards"};
`;

const EventsContainer = styled.div`
  width: 100%;
  /* padding: 1.5%; */
  padding: 1%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
  @media ${DeviceSizes.mobile} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
const EventDataContainer = styled.div`
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  @media ${DeviceSizes.mobile} {
    margin-bottom: 10%;
  }
`;
const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  & svg {
    width: 80%;
  }
`;
const LoaderContainer = styled.div`
  height: 83vh;
  align-items: center;
  justify-content: center;
  display: flex;
`;
const EditDeletButtonContainer = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 5px;
`;
const EventListScrollerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: auto;

  position: relative;
  & div:has(div hr) {
    flex: 0 1 100%;
  }
  @media ${DeviceSizes.belowtablet} {
    justify-content: flex-end;
    align-items: flex-end;
  }
`;
const EventCardListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const EventCalendarContainer = styled.div`
  height: 100%;
  width: 45%;
  padding: 1%;
  @media ${DeviceSizes.belowtablet} {
    width: 100%;
  }
`;
const EventCalendarWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: ${CommonColor.primary_color};
  padding: 2%;
  position: relative;
  overflow-y: auto;
  /* display: ${(props) => (props.open === 1 ? "flex" : "")}; */
  /* flex-direction: column; */
  /* justify-content: ${(props) =>
    props.open === 1 ? "space-between" : ""}; */

  @media ${DeviceSizes.belowLaptop} {
    transition: background-color 0.5s ease-in-out;
  }
  @media ${DeviceSizes.belowtablet} {
    //  transform: ${(props) => (props.active ? "scaleY(1)" : "scaleY(0)")};
    // max-height: ${(props) => (props.active ? "" : "0")};

    &:not(div .dailyEvents.slider) {
      & .dailyEventsList {
        transform: scaleY(0);
        max-height: 0;
      }
      & .allEventsList {
        transform: scaleY(1);
        height: 84%;
        max-height: initial;
      }
    }
    &:has(div .dailyEvents.slider) {
      & .dailyEventsList {
        transform: scaleY(1);
        height: inherit;
        max-height: unset;
      }
      & .allEventsList {
        transform: scaleY(0);
        max-height: 0;
      }
    }
  }
`;
const LocalEventCalendarContainer = styled.div`
  margin: 3px;
  & .MuiPickersDay-root,
  .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel,
  .MuiPickersCalendarHeader-root,
  .MuiSvgIcon-root {
    color: white;
  }

  & .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
  .MuiPickersDay-today.Mui-selected {
    background-color: white;
    color: ${CommonColor.primary_color};
    font-weight: 600;
  }

  .MuiPickersDay-dayOutsideMonth,
  .MuiPickersDay-dayOutsideMonth:hover {
    color: ${CommonColor.text_gray};
    background-color: transparent;
  }
  .rmdp-wrapper {
    width: 100%;
    border-radius: 4px;
  }
  span.sd div:first-child {
    & div {
      text-align: center;
      padding: 0 !important;
    }
  }
  .sd div:nth-child(2) {
    text-align: center;
  }
  .rmdp-day-picker div:first-child:has(.rmdp-week) {
    width: 100%;
  }
  .rmdp-calendar {
    width: 100%;
  }
  .rmdp-day {
    font-weight: 500;
  }
  .rmdp-today span {
    background-color: #253f50;
  }
  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: #253f5030;
    color: #253f50;
  }
  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #253f5050;
    color: #253f50;
  }
  .rmdp-week-day {
    color: #253f50;
  }
  .rmdp-arrow-container i {
    border: solid #253f50;
    border-width: 0 2px 2px 0;
  }
  .rmdp-arrow-container {
    padding-top: 2.5px;
    margin-top: -2.5px;
  }
  .rmdp-arrow-container:hover {
    background-color: ${CommonColor.light_primary};
    & i {
      border-color: ${CommonColor.white};
    }
  }
  @media ${DeviceSizes.belowLaptop} {
    & .MuiDateCalendar-root {
      width: 100%;
    }
  }
`;
const CalendarDayContainer = styled(Button)`
  && {
    position: relative;
    min-width: 36px;
    min-height: 36px;
    border-radius: 50%;
    padding: 0;
    /* margin: 0px 2px; */
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    background-color: ${(props) =>
      props.isselected ? CommonColor.secondary_color : CommonColor.transparent};
    transition: background-color 0.3s ease-in-out;
    & button {
      width: 26px;
      height: 26px;
      font-size: ${FontSizes.Para};
      &:hover {
        background-color: ${CommonColor.transparent};
      }
    }
    & .Mui-selected {
      background-color: ${CommonColor.transparent};
    }
    & .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
      background-color: ${CommonColor.transparent};
    }
    &:hover {
      background-color: ${(props) =>
        props.isselected
          ? CommonColor.secondary_color
          : CommonColor.lighter_transparent_white};
    }
    & .MuiPickersDay-today {
      border-color: ${CommonColor.transparent};
    }
    & * {
      color: ${CommonColor.white};
      font-family: "Mulish";
      font-size: ${FontSizes.Para};
    }
    & .MuiPickersDay-dayOutsideMonth {
      color: ${CommonColor.text_gray};
    }
  }
`;
const EventDot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isdisabled ? CommonColor.text_gray : CommonColor.primary_color};
  margin: 0 2px;
  display: ${(props) => (props.isdisabled ? "none" : "")};
`;
const EventsListContainer = styled.div`
  border-radius: 4px;
  background-color: ${CommonColor.white};
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  margin: auto;
  height: calc(100% - 300px);
  overflow: auto;
`;
const LocalEventListContainer = styled.div`
  padding: 9%;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media ${DeviceSizes.mobile} {
    padding: 2%;
  }
`;
const EventCardContainer = styled(CelebrationMonthContainer)`
  width: 45%;
  grid-template-columns: 1fr;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin: 1% 8% 1% 8%;
  }
`;
const EventPhotoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const EventPhotoImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

const EventCalendarRightContainer = styled.div`
  position: relative;
  bottom: 0;

  top: 0;
  width: 50px;
  height: 40px;
  border-radius: 50%;
  margin: auto;
`;
const EventListContainerMobile = styled(EventsListContainer)`
  position: ${(props) => (props.open ? "absolute" : "relative")};
  bottom: ${(props) => (props.open ? "0.5%" : "0.5%")};
  left: ${(props) => (props.open ? "1.5%" : "1%")};
  right: ${(props) => (props.open ? "1.5%" : "")};
  height: ${(props) => (props.open ? "99%" : "71.7%")};
  margin: ${(props) => (props.open ? "" : "0")};
  margin-top: ${(props) => (props.open ? "" : "5%")};
  transition: height 0.5s ease-in-out;
  width: ${(props) => (props.open ? " 97.2%" : "98%")};
`;

const EventListToolbarWrapper = styled.div`
  border-radius: inherit;
  width: 100%;
  height: 40px;

  position: relative;
  display: flex;
  z-index: 0;
  margin-top: 0.5%;
`;
const EventListToolbarContainer = styled.div`
  background-color: ${CommonColor.transparent};
  color: ${(props) =>
    props.active ? CommonColor.white : CommonColor.primary_color};
  width: 100%;
  font-family: "Mulish";
  font-weight: 800;
  text-align: center;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EventToolbarSlider = styled.div`
  position: absolute;
  width: 50%;
  border-radius: inherit;
  height: 100%;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.open ? "1%" : "49%")};
  background-color: ${CommonColor.primary_color};
  z-index: 1;
  transition: left 0.5s ease-in-out;
`;
const DailyEventContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
  transform-origin: top;
  @media ${DeviceSizes.mobile} {
    height: 100%;
    margin-top: 2%;
  }
`;
const EventWrapper = styled.div`
  padding: 5%;
  border-radius: 4px;
  box-shadow: 0px 5.444252014160156px 34.02657699584961px -6.8053154945373535px rgba(0, 0, 0, 0.15);
  background-color: ${CommonColor.white};
  width: 100%;
  position: relative;
  margin-top: 46px;
  translate: 0 20px;
  animation-name: ${dashboardCardLoadAnimation};
  animation-duration: ${(props) => props.index / props.length}s;
  /* animation-delay: ${(props) => props.index / props.length}s; */
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;
const EventIconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
const EventIcon = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.borderRadius};
`;

const BirthdayIcon = styled(CommonImages.BirthdayEventIcon)`
  height: 90px;
  width: 90px;
`;
const EventBirthdayImageContainer = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
`;

const EventScreenContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
const EventNavBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${CommonColor.white};
  /* border-radius: 10px; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  && input {
    height: 17px;
    cursor: pointer;
    padding-top: 0.9em;
    padding-bottom: 0.6em;
  }
  & .MuiInputAdornment-positionStart {
    max-height: 100%;
    height: 33px;
  }
  & .MuiOutlinedInput-root {
    border-radius: 15px;
    width: 68%;
    margin-left: 25%;
    cursor: pointer;
  }
  & .MuiInputBase-root {
    border-radius: 40px;
  }
`;
export {
  EventNavBarContainer,
  EventScreenContainer,
  BirthdayIcon,
  ButtonContainer,
  CalendarDayContainer,
  ConductingPersonContainer,
  ConductingPersonName,
  ConductingPersonTitle,
  DailyEventContainer,
  DateContainer,
  DeletePdfButton,
  DividinLineContainer,
  EditDeletButtonContainer,
  EventBirthdayImageContainer,
  EventBranch,
  EventCalendarContainer,
  EventCalendarRightContainer,
  EventCalendarWrapper,
  EventCardContainer,
  EventCardListContainer,
  EventContainer,
  EventContainerDivider,
  EventDataContainer,
  EventDate,
  EventDot,
  EventIcon,
  EventIconContainer,
  EventListConatainer,
  EventListContainerMobile,
  EventListScrollerContainer,
  EventListToolbarContainer,
  EventListToolbarWrapper,
  EventPhotoContainer,
  EventPhotoImage,
  EventTime,
  EventToolbarSlider,
  EventWrapper,
  EventsContainer,
  EventsListContainer,
  IsToday,
  LoaderContainer,
  LocalEventCalendarContainer,
  LocalEventListContainer,
  NoDataContainer,
  TimeAndBranchContainer,
  Title,
  TitleAndConductorContainer,
  TitleEditButtonContainer,
};
