import { AppBar, Toolbar, Tooltip } from "@mui/material";
import { forEach } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddPrexelite from "../../component/Forms/AddPrexelite";
import EditEmployee from "../../component/Forms/EditEmployee";
import EmployeeScreenShimmer from "../../component/Loader/shimmers/EmployeeScreenShimmer";
import ModalPopup from "../../component/Popup/ModalPopup";
import EmployeeCard from "../../component/commonComponents/Employee/EmployeeCard";
import EmployeeHeader from "../../component/commonComponents/Employee/EmployeeHeader";
import NavBar from "../../component/drawer/NavBar";
import EmployeeController from "../../controller/EmployeeController";
import {
  setActiveDrawerkey,
  setIsModalOpen,
} from "../../reducer/dashboardreduxstore";
import {
  setProfileOnMemberScreen,
  storeEmployeeList,
} from "../../reducer/employeereduxstore";
import { updateActiveUserLeaveListData } from "../../reducer/leavereduxstore";
import { EmployeePopupOpener } from "../../reducer/pagetransitionstore";
import { AppBarStyle } from "../../style/drawer.style";
import {
  CloseButtonIcon,
  EmployeeCardContainer,
  EmployeeDataEmptyText,
  ProfileViewContainer,
  VerticalScrollableAreaEmployee,
} from "../../style/employee.style";
import CommonColor from "../../utils/common.colors";
import { CommonString } from "../../utils/common.string";
import { sortEmployees } from "../../utils/utils";
import Profilev2 from "../Profile/Profilev2";
import { Div } from "../../style/common.style";

export default function Employee(props) {
  const dispatch = useDispatch();
  const employeeController = new EmployeeController();
  const [searchValue, setSearchValue] = useState("");
  const [OriginalEmployeeList, setOriginalEmployeeList] = useState();
  const [Employees, setEmployees] = useState([]);
  const [activeEmployeeIndex, setActiveEmployeeIndex] = useState(0);
  const [isAddForm, setIsAddForm] = useState(true);
  const [pageLoader, setPageLoader] = useState(false);

  const isEmployeeDetails = useSelector(
    (state) => state.pagetransition.employeepage.ToBeOpen
  );
  const employeeList = useSelector((state) => state.employee.employee_list);
  const dashboard = useSelector((state) => state.dashboard);
  const employee = useSelector((state) => state.employee);

  useEffect(() => {
    if (employeeList === "init") return;
    getEmployees();
    const sortedList = sortEmployees(employeeList);
    setEmployeeLists(sortedList);
    setPageLoader(false);
    dispatch(setProfileOnMemberScreen(false));
  }, [isEmployeeDetails, employeeList]);

  function setEmployeeLists(sorted_employeeList) {
    setEmployees(sorted_employeeList);
    setOriginalEmployeeList(sorted_employeeList);
    if (isEmployeeDetails) {
      let employeeindex;
      forEach(sorted_employeeList, (sorted_employee, i) => {
        if (sorted_employee.name === isEmployeeDetails) {
          employeeindex = i;
          return false;
        }
      });
      setActiveEmployeeIndex(employeeindex);
      // setIsFullScreen(true);
      dispatch(setProfileOnMemberScreen(true));
    }
  }

  async function getEmployees() {
    if (employeeList.length !== 0) return;
    setPageLoader(true);
    let employee_list = await employeeController.getEmployeeList();
    const newEmployeeList = employee_list.map((employee) => {
      return {
        ...employee,
        id: employee.employee_id,
      };
    });
    dispatch(storeEmployeeList(newEmployeeList));
    setPageLoader(false);
  }

  function FilterEmployeesInSearch(data) {
    if (Employees) {
      setSearchValue(data);
      const newEmployee = OriginalEmployeeList.filter((employee) => {
        return (
          employee.name.toUpperCase().includes(data.toUpperCase()) ||
          employee.employee_id.toUpperCase().includes(data.toUpperCase())
        );
      });
      if (data === "") {
        setEmployees(OriginalEmployeeList);
        return;
      }
      setEmployees(newEmployee);
    }
  }

  function closeEmployeeDetail() {
    // setIsFullScreen(false);
    dispatch(setProfileOnMemberScreen(false));
    dispatch(updateActiveUserLeaveListData([]));
    if (isEmployeeDetails) {
      dispatch(EmployeePopupOpener(null));
      dispatch(setActiveDrawerkey(CommonString.dashboardScreenKey));
    }
  }
  function OpenPopup(value) {
    closeEmployeeDetail();
    dispatch(setIsModalOpen(true));
    setForm(value);
  }
  function setForm(value) {
    if (value === 1) {
      setIsAddForm(true);
      return;
    }
    setIsAddForm(false);
  }
  function isEmployeeLoading() {
    return pageLoader;
  }
  function getPopupForm() {
    if (isAddForm) return <AddPrexelite onHandleClose={() => closePopup()} />;
    return (
      <EditEmployee
        onHandleClose={() => closePopup()}
        activeEmployeeIndex={activeEmployeeIndex}
      />
    );
  }
  function closePopup() {
    dispatch(setIsModalOpen(false));
  }
  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Team Members"}
            NavBarIcon={
              employee.employee_acess.users
                ? {
                    icon: "fluent:people-add-20-regular",
                    name: "Add Prexelite",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      {employee.is_profile_on_member_screen &&
      Employees.length > 0 &&
      employee.employee_acess.users ? (
        <ProfileViewContainer>
          <Profilev2
            employeeDetail={Employees[activeEmployeeIndex]}
            isPopupOpen={
              employee.is_profile_on_member_screen &&
              Employees.length > 0 &&
              employee.employee_acess.users
            }
          />
          <Tooltip title="Close" placement="bottom">
            <CloseButtonIcon
              icon="mdi:close"
              onClick={() => closeEmployeeDetail()}
              color={CommonColor.white}
              fontSize="2.4em"
            />
          </Tooltip>
          {/* <Tooltip title="Edit" placement="bottom">
            <EditButtonIcon
              icon="clarity:note-edit-line"
              onClick={() => OpenPopup(0)}
            />
          </Tooltip> */}
        </ProfileViewContainer>
      ) : (
        <React.Fragment>
          <VerticalScrollableAreaEmployee
            height="100%"
            unit=""
            mobileheight="100%"
          >
            <EmployeeHeader
              title="Search for Prexelites"
              placeholder="search"
              searchValue={searchValue}
              FilterEmployeesInSearch={FilterEmployeesInSearch}
            />
            <EmployeeCardContainer>
              {isEmployeeLoading() ? (
                <EmployeeScreenShimmer />
              ) : (
                Employees.map((employeeDetail, index) => {
                  return (
                    <React.Fragment key={index}>
                      <EmployeeCard
                        employeeDetail={employeeDetail}
                        openEmployeeDetailPopup={() =>
                          dispatch(setProfileOnMemberScreen(true))
                        }
                        length={Employees.length}
                        employeeIndex={index}
                        popupToggler={() => closePopup()}
                        ActiveEmployee={(index) =>
                          setActiveEmployeeIndex(index)
                        }
                      />
                    </React.Fragment>
                  );
                })
              )}
              {props.isLoaded && Employees.length === 0 && (
                <EmployeeDataEmptyText>No Employees</EmployeeDataEmptyText>
              )}
            </EmployeeCardContainer>
          </VerticalScrollableAreaEmployee>
          <ModalPopup open={dashboard.isModalOpen} popupSize="sm">
            {getPopupForm()}
          </ModalPopup>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
