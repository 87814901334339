import { Icon } from "@iconify/react/dist/iconify.js";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SynergyLeagueController from "../../../controller/SynergyLeagueController";
import { setPointsTable } from "../../../reducer/synergyleaguereduxstore";
import {
  CustomMarginAndFlexBox,
  TypoGraphy,
} from "../../../style/common.style";
import {
  CardTitleContainer,
  GamesListWrapper,
  TeamListWrapper,
} from "../../../style/report.style";
import { FontSizes } from "../../../style/theme/font";
import CommonColor from "../../../utils/common.colors";
import { CommonString } from "../../../utils/common.string";
import { isLeader } from "../../../utils/utils";
import AddMatch from "../../Forms/components/form/AddMatch";
import ConfirmationMenu from "../../Popup/ConfirmationMenu";
import ModalPopup from "../../Popup/ModalPopup";
import PointsTable from "./PointsTable";

export default function GameScoreList() {
  const [addMatch, setAddMatch] = useState(false);
  const [del, setDel] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(null);
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const synergyLeagueController = new SynergyLeagueController();
  const dispatch = useDispatch();

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  function getModalChildren() {
    if (isLeader()) {
      return;
    }
    if (del) {
      return (
        <ConfirmationMenu
          isShow={isBeingDeleted}
          title={CommonString.deleteMatchData}
          yesButtonName="Yes"
          noButtuonName="No"
          onClickYesButton={() => DeleteData()}
          onClicknoButton={() => EditOrDel("", null)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (!del && !edit && !addMatch) {
      return;
    }
    return (
      <AddMatch
        onHandleClose={() => setAddMatch(false)}
        data={data}
        close={() => EditOrDel("", null)}
      />
    );
  }

  async function DeleteData() {
    setIsBeingDeleted(true);
    const resp = await synergyLeagueController.deleteMatch(data._id);
    if (resp) {
      const sucessMsg = (
        <div>
          <div>success</div>
          <div>match deleted sucessfully</div>
        </div>
      );
      let data = await synergyLeagueController.getPointsTable();
      if (data) {
        dispatch(setPointsTable(data));
      }
      handleAlert(sucessMsg, "success");
    } else {
      const errrorMsg = (
        <div>
          <div>error</div>
          <div>please try again later</div>
        </div>
      );
      handleAlert(errrorMsg, "error");
    }
    setIsBeingDeleted(false);
    EditOrDel("", null);
  }

  function EditOrDel(option, data) {
    setData(data);
    if (option === "edit") {
      setEdit(true);
    } else if (option === "del") {
      setDel(true);
    } else {
      setEdit(false);
      setDel(false);
      setData(null);
    }
  }
  return (
    <React.Fragment>
      <ModalPopup popupSize="sm" open={addMatch | del | edit}>
        {getModalChildren()}
      </ModalPopup>
      <TeamListWrapper style={{ height: "100%", padding: "1%" }}>
        <CardTitleContainer>
          {/* <CustomMarginAndFlexBox
            width="max-width"
            style={{
              boxShadow: "0px -12px 32px -12px rgba(0,0,0,0.1)",
              cursor: "pointer",
            }}
            onClick={() =>
              page !== pageSynergyLeaguePage[0]
                ? setPage(pageSynergyLeaguePage[0])
                : ""
            }
          >
            <TypoGraphy
              textTransform="capitalize"
              fontSize={FontSizes.Subtitle}
              weight="700"
              color={CommonColor.secondary_color}
            >
              recent matches
            </TypoGraphy>
          </CustomMarginAndFlexBox>
          <Divider orientation="flex" flexItem /> */}
          <CustomMarginAndFlexBox
            ml="1%"
            width="max-width"
            style={{
              boxShadow: "0px -12px 32px -12px rgba(0,0,0,0.1)",
              cursor: "pointer",
            }}
            // onClick={() =>
            //   page !== pageSynergyLeaguePage[1]
            //     ? setPage(pageSynergyLeaguePage[1])
            //     : ""
            // }
          >
            <TypoGraphy
              textTransform="capitalize"
              fontSize={FontSizes.Subtitle}
              weight="700"
              color={CommonColor.secondary_color}
            >
              points table
            </TypoGraphy>
            {isLeader() && (
              <Icon
                icon="ion:add"
                style={{ marginLeft: 2, color: CommonColor.secondary_color }}
                onClick={() => setAddMatch(true)}
              />
            )}
          </CustomMarginAndFlexBox>
        </CardTitleContainer>
        {/* {page === pageSynergyLeaguePage[0] ? (
          <GamesListWrapper>
            {synergyLeagueSportsData.map((game, index, array) => {
              return (
                <GameTeamScoreCard key={index}>
                  <GameTeamScoreContainer>
                    <FlexContainer margin="0 0 4% 0">
                      <TypoGraphy>
                        {game.match_type.toLowerCase() !==
                        "LEAGUE".toLowerCase()
                          ? game.match_type
                          : "Match #" + index + 1}
                      </TypoGraphy>
                      <TypoGraphy>&nbsp;{game.sport.name}</TypoGraphy>
                    </FlexContainer>
                    <FlexContainer jc="space-between">
                      <GameSingleTeamScoreContainer>
                        <TeamImageContainer
                          width="60px"
                          height="60px"
                          position="relative"
                          onClick={() => setShowTeam(game.sl_sport_team_1)}
                        >
                          <TeamImage
                            src={getLogo(game.sl_sport_team_1.team)}
                            alt=""
                          />
                            {game.winner.team_name ===
                            game.sl_sport_team_1.team_name ? (
                              <WinnerIcon
                                src={CommonImages.Medal}
                                alt=""
                                width="20px"
                                height="20px"
                                bottom="0"
                                right="0"
                              />
                            ) : (
                              ""
                            )}
                        </TeamImageContainer>
                        <TypoGraphy>
                          {game.sl_sport_team_1.team_name}
                        </TypoGraphy>
                      </GameSingleTeamScoreContainer>

                      <TypoGraphy
                        fontSize={FontSizes.Title}
                        weight="700"
                        color={CommonColor.secondary_color}
                        textAlign="center"
                      >
                        VS
                      </TypoGraphy>
                      <GameSingleTeamScoreContainer>
                        <TeamImageContainer
                          width="60px"
                          height="60px"
                          position="relative"
                          onClick={() => setShowTeam(game.sl_sport_team_2)}
                        >
                          <TeamImage
                            src={getLogo(game.sl_sport_team_2.team)}
                            alt=""
                          />
                          {game.winner.team_name ===
                          game.sl_sport_team_2.team_name ? (
                            <WinnerIcon
                              src={CommonImages.Medal}
                              alt=""
                              width="20px"
                              height="20px"
                              bottom="0"
                              right="0"
                            />
                          ) : (
                            ""
                          )}
                        </TeamImageContainer>
                        <TypoGraphy>
                          {game.sl_sport_team_2.team_name}
                        </TypoGraphy>
                      </GameSingleTeamScoreContainer>
                    </FlexContainer>
                  </GameTeamScoreContainer>
                </GameTeamScoreCard>
              );
            })}
            <GameTeamScoreCard>
              <GameTeamScoreContainer>
                <AddMatchContainer width="112px" height="112px">
                  <IconifyIcon
                    icon="fluent:add-48-filled"
                    color={CommonColor.primary_color}
                    width="25%"
                    height="25%"
                    cursor="pointer"
                    onClick={() => openAddMatch()}
                  />
                </AddMatchContainer>
              </GameTeamScoreContainer>
            </GameTeamScoreCard>
          </GamesListWrapper>
        ) : page === pageSynergyLeaguePage[1] ? ( */}
        <GamesListWrapper>
          <PointsTable EditOrDel={EditOrDel} />
        </GamesListWrapper>
        {/* ) : (
          "hello"
        )} */}
      </TeamListWrapper>
    </React.Fragment>
  );
}
