import React from "react";
import { ButtonCustom } from "../../../style/common.style";
import { Icon } from "@iconify/react";

export default function ButtonCustomV2(props) {
  return (
    <ButtonCustom
      variant={props.variant ?? "contained"}
      color={props.color}
      bgcolor={props.customBGColor}
      hoverbg={props.hoverBG}
      outlinecolor={props.customOutlineColor}
      textcolor={props.customTextColor}
      texthovercolor={props.textHoverColor}
      fontsize={props.fontsize}
      fontweight={props.fontWeight}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      onClick={props.onClick}
    >
      {props.buttonText ? (
        props.buttonText
      ) : (
        <Icon icon={props.icon} fontsize={props.fontsize} />
      )}
    </ButtonCustom>
  );
}
