import React, { useState } from "react";
import { useSelector } from "react-redux";
import ImageAvatar from "../../../componentsv2/Images/ImageAvatar";
import {
  MainEmployeeContainer,
  TeamMemberContainer,
  UserName,
  UserPrexeliteId,
  UserViewProfileContainer,
} from "../../../style/employee.style";
import { CommonImages } from "../../../utils/common.images";
import { ProfileBaseUrl } from "../../../utils/constant";

export default function EmployeeCard(props) {
  const [isHovered, setIsHovered] = useState(false);
  const employee = useSelector((state) => state.employee);
  function PopupOpener() {
    props.openEmployeeDetailPopup();
    props.popupToggler(false);
    props.ActiveEmployee(props.employeeIndex);
  }
  return (
    <React.Fragment>
      <MainEmployeeContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => PopupOpener()}
        isvisible={employee.employee_acess.users ? 1 : 0}
        index={props.employeeIndex}
        length={props.length}
      >
        <TeamMemberContainer ismargintop={employee.employee_acess.users}>
          <ImageAvatar
            ispadding={employee.employee_acess.users}
            src={
              props.employeeDetail.profile_image_url
                ? ProfileBaseUrl + props.employeeDetail.profile_image_url
                : CommonImages.NoUser
            }
            alt=""
            loading="lazy"
            effect="blur"
            onError={(e) => (e.target.src = CommonImages.NoUser)}
          />
        </TeamMemberContainer>

        <UserName>{props.employeeDetail.name.toLowerCase()}</UserName>
        {/* <UserBranchName
          isvisible={employee.employee_acess.users ? 1 : 0}
          isHovered={isHovered}
        >
          IT
        </UserBranchName> */}
        <UserPrexeliteId ismargin={employee.employee_acess.users}>
          {props.employeeDetail.employee_id}
        </UserPrexeliteId>
        <UserViewProfileContainer
          ishovered={isHovered ? 1 : 0}
          isvisible={employee.employee_acess.users ? 1 : 0}
        >
          view profile
        </UserViewProfileContainer>
      </MainEmployeeContainer>
    </React.Fragment>
  );
}
