import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  CustomTextFeild,
  InputText,
  SelectFieldWrapper,
  MenuItemStyle,
} from "../../../style/form.style";
import { FontSizes } from "../../../style/theme/font";

export default function DropDownList(props) {
  return (
    <SelectFieldWrapper componenttype={props.componentType ?? "popup"}>
      <InputText component="small-input-text">{props.title}</InputText>
      <CustomTextFeild
        select
        fullWidth
        onChange={props.handleChange}
        InputProps={{ style: { fontSize: FontSizes.SubPara } }}
        size="small"
        title={props.title}
        placeholder={props.placeholder}
        {...props}
      >
        {props.dataList.map((option, index, array) => (
          <MenuItem
            key={index}
            divider={array.length - 1 !== index ? true : false}
            value={option}
            sx={MenuItemStyle(props)}
          >
            {option}
          </MenuItem>
        ))}
      </CustomTextFeild>
    </SelectFieldWrapper>
  );
}
