import React from "react";
import {
  NoDataContainer,
  NoDataImage,
  NoDataText,
  NoDataTitle,
} from "../../../style/common.style";

export default function NoData(props) {
  return (
    <NoDataContainer>
      <NoDataImage />
      <NoDataText>{props.noDataText}</NoDataText>
    </NoDataContainer>
  );
}
