import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { updateActiveUserLeaveListData } from "../../../reducer/leavereduxstore";
import {
  BirthdayDateContainer,
  EmployeeInfoContainer,
  RoundImageRankContainer,
  RoundImageSpeedContainer,
  ScrollContainer,
} from "../../../style/dashboard.style";
import { NameContainer } from "../../../style/leave.style";
import { NoProfilePhoto, ProfileBaseUrl } from "../../../utils/constant";
import { formatDisplayBirthDate } from "../../../utils/utils";
import ModalPopup from "../../Popup/ModalPopup";
import ProfileScreenPopup from "../Popups/ProfileScreen/ProfileScreenPopup";
import { useDispatch } from "react-redux";
import ImageAvatar from "../../../componentsv2/Images/ImageAvatar";
import { CommonImages } from "../../../utils/common.images";
import { CarouseEmployeeImageContainer } from "../../../style/common.style";
import LeaveController from "../../../controller/LeaveController";

const CarouselEmployee = (props) => {
  let rank = 1;
  const dispatch = useDispatch();
  const leaveController = new LeaveController();
  const [isPopupOpen, setOpenPopup] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState(null);

  async function EmployeeDetailPopupOpener(employee) {
    if (props.isTypingShow) return;
    if (props.title === "Upcoming Birthday") return;
    setOpenPopup(true);
    setActiveEmployee(employee);
    const leaveData = await leaveController.getEmployeeLeaveData(employee._id);
    dispatch(updateActiveUserLeaveListData(leaveData));
  }

  function getUrl(employee) {
    if (employee.profile_image_url === null) return NoProfilePhoto;
    if (props.isTypingShow)
      return ProfileBaseUrl + employee.user.profile_image_url;
    return ProfileBaseUrl + employee.profile_image_url;
  }
  function getDebugName(employee) {
    if (props.isTypingShow)
      return employee.user.name.split(" ")[0].toLowerCase();
    return employee.name.split(" ")[0].toLowerCase();
  }
  function scrollLeft(event) {
    if (event.deltaY === 0) return;

    const ele = event.currentTarget;
    ele.scrollTo({
      top: 0,
      left: ele.scrollLeft + event.deltaY,
      behavior: "smooth",
    });
  }
  function getModalChildren() {
    if (!isPopupOpen) return <></>;
    return (
      <ProfileScreenPopup
        onHandleClose={() => setOpenPopup(false)}
        user={activeEmployee}
        isPopupOpen={isPopupOpen}
      />
    );
  }
  function getPopupWidth() {
    return "100vw";
  }
  return (
    <React.Fragment>
      <ModalPopup
        open={isPopupOpen}
        styleSX={{
          minWidth: getPopupWidth(),
          maxHeight: "100vh",
          height: "100%",
          margin: 0,
          overflowX: "hidden",
        }}
      >
        {getModalChildren()}
      </ModalPopup>
      <ScrollContainer onWheel={(event) => scrollLeft(event)}>
        {props.data.map((employee, index, array) => {
          if (index > 15) return;

          if (index !== 0 && array[index - 1].speed !== employee.speed) {
            rank = rank + 1;
          }
          return (
            <EmployeeInfoContainer
              key={index}
              onClick={() => EmployeeDetailPopupOpener(employee)}
              ismb={1}
              delay={props.index}
              length={props.data.length}
            >
              <CarouseEmployeeImageContainer>
                <ImageAvatar
                  src={getUrl(employee)}
                  alt=""
                  defaultSrc={CommonImages.NoUser}
                />
              </CarouseEmployeeImageContainer>
              <NameContainer ispaddingbottom={props.shownBirthday ? 1 : 0}>
                {getDebugName(employee)}
                {props.isTypingShow ? (
                  <RoundImageSpeedContainer>
                    {employee.speed}
                  </RoundImageSpeedContainer>
                ) : (
                  <></>
                )}
              </NameContainer>

              {props.isTypingShow ? (
                <RoundImageRankContainer>#{rank}</RoundImageRankContainer>
              ) : (
                <></>
              )}

              {props.shownBirthday ? (
                <BirthdayDateContainer>
                  {formatDisplayBirthDate(employee.birthday)}
                </BirthdayDateContainer>
              ) : (
                <></>
              )}
            </EmployeeInfoContainer>
          );
        })}
      </ScrollContainer>
    </React.Fragment>
  );
};

export default CarouselEmployee;
