import React, { useEffect, useState } from "react";
import { useWindowSize } from "../commonComponents/hooks/getWindowDimension";
import {
  CalendarContainer,
  LeaveReportContainer,
  ReportContainer,
  ReportProfileCloseIconContainer,
  ReportProfileContainer,
  TableDataNumber,
} from "../../style/report.style";
import Profilev2 from "../../screen/Profile/Profilev2";
import { Tooltip } from "@mui/material";
import transition from "react-element-popper/animations/transition";
import CommonColor from "../../utils/common.colors";
import { Icon } from "@iconify/react";
import { FlexContainer } from "../../style/asset.style";
import { TypoGraphy } from "../../style/common.style";
import { FontSizes } from "../../style/theme/font";
import TailwindDropdownMembers from "../commonComponents/inputs/TailWindDropdownMembers";
import { useSelector } from "react-redux";
import { DatePickerCalendarProfile } from "../../style/profile.style";
import CustomInput from "../../screen/Profile/CustomInput";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { monthShort } from "../../utils/constant.array";
import { ShimmerTable } from "react-shimmer-effects";
import {
  AccessTable,
  AccessTableBody,
  AccessTableData,
  AccessTableHead,
  AccessTableHeader,
  AccessTableLeave,
  AccessTableRow,
  CircularContainer,
  TextContainer,
} from "../../style/style.access.mgmt.style";
import { TableVirtuoso } from "react-virtuoso";
import {
  DatePickerDiv,
  DatePickerStyle,
  StyledDatePicker,
} from "../../style/datePicker.style";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import LeaveReportController from "../../controller/leaveReportController";
import { MUIDivider } from "../../style/training.style";
import {
  NoDataComponentContainerLeaveApp,
  NoDataComponentContainerLeaveReport,
  NoDataContainerRecentLeave,
} from "../../style/leave.style";
import NoData from "../commonComponents/NoData/NoData";
import { CommonString } from "../../utils/common.string";
import { GetButton } from "../../style/policy.style";
import moment from "moment";

const LeaveReport = (props) => {
  const windowWidth = useWindowSize().width;
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeEmployee, setActiveEmployee] = useState();
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [selectedEmpRes, setSelectedEmpRes] = useState(null);
  const [startDate, setStartDate] = useState(
    new DateObject().subtract(30, "days")
  );
  const [endDate, setEndDate] = useState(new DateObject());
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTime, setSelectedTime] = useState({ name: "Year", id: 1 });
  const maxDate = new Date();
  const [responseData, setResponseData] = useState([]);
  const currentYear = new Date().getFullYear();
  const [emplist , setEmplist] = useState([]);
  const defaultStartDate = new DateObject({
    year: currentYear,
    month: 1,
    day: 1,
  });
  const defaultEndDate = new DateObject({
    year: currentYear,
    month: 12,
    day: 31,
  });

  let employee_list = useSelector((state) => state.employee.employee_list);
  const leaveReportController = new LeaveReportController();

  function delayExecution(callback) {
    setTimeout(callback, 1000); 
  }

  function closeEmployeeDetail() {
    setIsFullScreen(false);
    setActiveEmployee();
  }

  function getEmployeeList() {
    let newData = [ {about:"NA",
      birthday: "1998-03-19T22:10:20.000Z",
      designation: "Recruiter",
      email:"mansis@prexelllc.com",
      employee_id: "PREXELITE000053",
      id: "PREXELITE000053",
      is_recruiter: 1,
      level_id: "9f34bef0-f87c-11ec-bc89-1f7b88403c8c",
      level_name: "LEVEL-5",
      name: "ALL USER",
      password: "mansi123",
      phone: "9685458967",
      profile_image_url: "photo__prexel_avtar_1718230149573.png",
      shift_id: "2dd8c140-e9f6-11ed-9736-579e62e3d96a",
      username: null,
      _id:null
     } ,
      ...employee_list
    ]
    return newData
  }

  function getDatePicker(id) {
    if (id === 1) {
      return <DatePicker onlyYearPicker />;
    }

    if (id === 2) {
      return <DatePicker onlyMonthPicker />;
    }
    return <DatePicker onlyYearPicker />;
  }

  async function getReport() {
    if (startDate === null || endDate === null) return;

    console.log(":::::::::" , selectedEmp)


    setIsLoading(true);
    let data = {
      user_id: selectedEmp === null ? "123" : selectedEmp._id,
      from_date:
        selectedEmp === null
          ? formatDate(startDate).toString()
          : formatDate(defaultStartDate).toString(),
      to_date:
        selectedEmp === null
          ? formatDate(endDate).toString()
          : formatDate(defaultEndDate).toString(),
    };

    const response = await leaveReportController.getLeaveReportData(data);

    if (response) {
      setResponseData(response.data);
      setSelectedEmpRes(selectedEmp);
    }
    setIsLoading(false);
  }

  function formatDate(date) {
    if (!date) return "";
    const year = date.year;
    const month = String(date.month.number).padStart(2, "0");
    const day = String(date.day).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    const start = new DateObject();
    setEmplist(employee_list);
    getReport();
  }, []);

  useEffect(() => {
    if (selectedEmp) {
      const delayExecution = (callback) => {
        setIsLoading(true);
        setTimeout(callback, 1000); 
        setIsLoading(false);
      };

      delayExecution(() => {
        getReport();
      });
    }
  }, [selectedEmp]);

  function setData(employee){
    const value = getEmployeeList().find((data)=>data._id === employee.recruiter_id);
    setSelectedEmp(value);
  }

  function handleEmployeeClick(employee) {
    if (selectedEmp === null) {
      setIsLoading(true);
      setData(employee);
      setIsLoading(false);
    }
  }



  return (
    <>
      {isFullScreen ? (
        <ReportProfileContainer>
          <Profilev2
            employeeDetail={activeEmployee}
            isLoaded={true}
            isPopupOpen={isFullScreen}
          />
          <Tooltip title="Close" placement="bottom">
            <ReportProfileCloseIconContainer
              onClick={() => closeEmployeeDetail()}
            />
          </Tooltip>
        </ReportProfileContainer>
      ) : (
        <>
          <LeaveReportContainer>
            <CalendarContainer>
              <FlexContainer>
                <Tooltip title="Go Back" arrow={true}>
                  <Icon
                    icon="ic:round-keyboard-backspace"
                    fontSize={"20px"}
                    color={CommonColor.primary_color}
                    onClick={() => props.onScreenClose()}
                  />
                </Tooltip>
                <TypoGraphy fontSize={FontSizes.Title} weight="600">
                  Leave Report
                </TypoGraphy>
                <Tooltip title="Download" arrow={true}>
              
                </Tooltip>
              </FlexContainer>
            </CalendarContainer>
            <div
              className={`flex ${
                windowWidth < 600 && "flex-col"
              } gap-2 items-center m-1`}
            >
              <div
                className={`${
                  windowWidth > 600 ? "w-1/4" : "w-full"
                }  my-auto h-full`}
              >
                <TailwindDropdownMembers
                  marginBottomC="0rem"
                  dataList={getEmployeeList()}
                  title=""
                  value={selectedEmp}
                  handleChange={(event) => {
                    if(event._id === null){
                      setSelectedEmp(null);
                    }else{
                      setSelectedEmp(event);
                    }
                  
                  }}
                  textTransform="capitalize"
                  placeholder="Select Prexelite"
                />
              </div>
              <div className={`${windowWidth > 600 ? "w-1/4" : "w-full"}`}>
                {selectedEmp === null && (
                  <DatePickerDiv>
                    <DatePickerStyle
                      value={startDate}
                      onChange={setStartDate}
                      animations={[transition()]}
                      maxDate={maxDate}
                      placeholder="Select Start date"
                    />
                  </DatePickerDiv>
                )}
              </div>

              <div className={`${windowWidth > 600 ? "w-1/4" : "w-full"}`}>
                {selectedEmp === null && (
                  <DatePickerDiv>
                    <DatePickerStyle
                      style={{ border: "none" }}
                      value={endDate}
                      onChange={setEndDate}
                      animations={[transition()]}
                      minDate={startDate}
                      maxDate={maxDate}
                      placeholder="Select End date"
                      fontSize="16px"
                    />
                  </DatePickerDiv>
                )}
              </div>

             
              <div
                className={`flex ${
                  windowWidth > 600 ? "w-1/4" : "w-full"
                } justify-end`}
              >
                <div
                  className="w-1/2 cursor-pointer"
                  onClick={() => getReport()}
                >
                  <GetButton>
                    <p className="text-white">Get Report</p>
                  </GetButton>
                </div>
              </div>
            </div>

            {isLoading ? (
              <ShimmerTable row={5} col={5} />
            ) : responseData.length ? (
              <AccessTableLeave isvisible={0}>
                <TableVirtuoso
                  style={{ height: "100%", width: "100%" }}
                  data={responseData}
                  totalCount={responseData.length}
                  height={"100%"}
                  width={"100%"}
                  rowHeight={40}
                  fixedItemHeight={40}
                  fixedHeaderContent={() => (
                    <React.Fragment>
                      <AccessTableHeader>
                        <React.Fragment>
                          <AccessTableHead
                            width={windowWidth > 600 ? "7%" : "10%"}
                            tabWidth="10%"
                          >
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              isvisible={false}
                            >
                              Sr. No.
                            </TypoGraphy>
                          </AccessTableHead>
                        </React.Fragment>

                        <AccessTableHead
                          width={windowWidth > 600 ? "15%" : "10%"}
                          horizontalAlign="left"
                          mobileWidth="10%"
                        >
                          <TypoGraphy
                            variant="body2"
                            weight="bold"
                            color={CommonColor.primary_color}
                          >
                            {selectedEmpRes !== null ? "Month" : "Name"}
                          </TypoGraphy>
                        </AccessTableHead>

                        <AccessTableHead
                          width={
                            selectedEmpRes
                              ? "15%"
                              : windowWidth > 600
                              ? "10%"
                              : "10%"
                          }
                          horizontalAlign="center"
                        >
                          <TypoGraphy
                            variant="body2"
                            weight="bold"
                            color={CommonColor.primary_color}
                            textAlign="center"
                          >
                            TOTAL LEAVE
                          </TypoGraphy>
                        </AccessTableHead>

                        {windowWidth > 600 && (
                          <AccessTableHead
                            width={selectedEmpRes ? "15%" : "10%"}
                            horizontalAlign="center"
                          >
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              textAlign="center"
                            >
                              INFORMED
                            </TypoGraphy>
                          </AccessTableHead>
                        )}
                        {windowWidth > 600 && (
                          <AccessTableHead
                            width={selectedEmpRes ? "15%" : "10%"}
                            horizontalAlign="center"
                          >
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              textAlign="center"
                            >
                              UNINFORMED
                            </TypoGraphy>
                          </AccessTableHead>
                        )}

                        

                        {!selectedEmpRes && windowWidth > 600 && (
                          <AccessTableHead width="10%" horizontalAlign="center">
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              textAlign="center"
                            >
                              SINGLE
                            </TypoGraphy>
                          </AccessTableHead>
                        )}
                        {!selectedEmpRes && windowWidth > 600 && (
                          <AccessTableHead width="10%">
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              width="100%"
                            >
                              MULTIPLE
                            </TypoGraphy>
                          </AccessTableHead>
                        )}
                        {windowWidth > 600 ? (
                          <AccessTableHead
                            width={selectedEmpRes ? "15%" : "10%"}
                          >
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              width="100%"
                            >
                              HALF
                            </TypoGraphy>
                          </AccessTableHead>
                        ) : (
                          ""
                        )}
                        {windowWidth > 600 ? (
                          <AccessTableHead
                            width={selectedEmpRes ? "15%" : "10%"}
                          >
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              width="100%"
                            >
                              LATE-FULL-300
                            </TypoGraphy>
                          </AccessTableHead>
                        ) : (
                          ""
                        )}
                        {windowWidth > 600 ? (
                          <AccessTableHead
                            width={selectedEmpRes ? "15%" : "10%"}
                          >
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              width="100%"
                            >
                              LATE-HALF-3
                            </TypoGraphy>
                          </AccessTableHead>
                        ) : (
                          ""
                        )}
                        {windowWidth > 600 ? (
                          <AccessTableHead
                            width={selectedEmpRes ? "15%" : "10%"}
                          >
                            <TypoGraphy
                              variant="body2"
                              weight="bold"
                              color={CommonColor.primary_color}
                              width="100%"
                            >
                              LATE-HALF-60
                            </TypoGraphy>
                          </AccessTableHead>
                        ) : (
                          ""
                        )}
                      </AccessTableHeader>
                    </React.Fragment>
                  )}
                  itemContent={(index, employee) => (
                    <AccessTableBody key={index}>
                      <React.Fragment>
                        <AccessTableRow
                          index={index}
                          bg={CommonColor.white}
                          length={12}
                        >
                          <AccessTableData
                            width={windowWidth > 600 ? "7%" : "10%"}
                            tabWidth="10%"
                          >
                            {index + 1}
                          </AccessTableData>

                          <AccessTableData
                            width={windowWidth > 600 ? "15%" : "20%"}
                            mobileWidth="52%"
                            cursor="pointer"
                            horizontalAlign="flex-start"
                            textTransform="capitalize"
                            onClick={ ()=>{
            
                              handleEmployeeClick(employee) 

                            } }
                          
                          >
                            {selectedEmpRes !== null
                              ? employee.month
                              : employee.name.toLowerCase()}
                          </AccessTableData>
                          <AccessTableData
                            width={selectedEmpRes ? "15%" : "10%"}
                          >
                            <CircularContainer
                              ishighlight={employee.TOTAL_LEAVE > 3}
                            >
                              <TextContainer>
                                {employee.TOTAL_LEAVE}
                              </TextContainer>
                            </CircularContainer>
                          </AccessTableData>
                          {windowWidth > 600 && (
                            <AccessTableData
                              width={selectedEmpRes ? "15%" : "10%"}
                            >
                              {employee.INFORMED}
                            </AccessTableData>
                          )}
                          {windowWidth > 600 && (
                            <AccessTableData
                              width={selectedEmpRes ? "15%" : "10%"}
                            >
                              {employee.UNINFORMED}
                            </AccessTableData>
                          )}

                          
                          {!selectedEmpRes && windowWidth > 600 && (
                            <AccessTableData width="10%">
                              {employee.SINGLE}
                            </AccessTableData>
                          )}
                          {!selectedEmpRes && windowWidth > 600 && (
                            <AccessTableData width="10%">
                              {employee.MULTIPLE}
                            </AccessTableData>
                          )}
                          {windowWidth > 600 && (
                            <AccessTableData
                              width={selectedEmpRes ? "15%" : "10%"}
                            >
                              {employee.HALF}
                            </AccessTableData>
                          )}
                          {windowWidth > 600 && (
                            <AccessTableData
                              width={selectedEmpRes ? "15%" : "10%"}
                            >
                              {employee["LATE-FULL-300"]}
                            </AccessTableData>
                          )}
                          {windowWidth > 600 && (
                            <AccessTableData
                              width={selectedEmpRes ? "15%" : "10%"}
                            >
                              {employee["LATE-HALF-3"]}
                            </AccessTableData>
                          )}
                          {windowWidth > 600 && (
                            <AccessTableData
                              width={selectedEmpRes ? "15%" : "10%"}
                            >
                              {employee["LATE-HALF-60"]}
                            </AccessTableData>
                          )}
                        </AccessTableRow>
                      </React.Fragment>
                    </AccessTableBody>
                  )}
                />{" "}
              </AccessTableLeave>
            ) : (
              <React.Fragment>
                <NoDataComponentContainerLeaveReport>
                  <NoData noDataText={CommonString.selectDate} />
                </NoDataComponentContainerLeaveReport>
              </React.Fragment>
            )}
          </LeaveReportContainer>
        </>
      )}
    </>
  );
};

export default LeaveReport;

// const CustomDatePicker = (props) => {
//   return (
//     <div className="border-2 border-gray-400 rounded-md p-2 w-full">
//       <DatePicker {...props} onlyMonthPicker className="w-full rounded-md" />
//     </div>
//   );
// };
