import { Icon } from "@iconify/react";
import {
  Badge,
  Button,
  Grid,
  OutlinedInput,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import DatePicker from "react-multi-date-picker";
import styled, { keyframes } from "styled-components";
import CommonColor from "../utils/common.colors";
import { CommonImages } from "../utils/common.images";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";
import { Listbox } from "@headlessui/react";

const opacityChangeAnimation = keyframes`
0%{
  opacity: 0;
}
100%{
  opacity: 1;
}
`;
const dashboardCardLoadAnimation = keyframes`
0%{
  opacity: 0;
  translate: 0 20px;
}
100%{
  opacity: 1;
  translate: 0 0;
}
`;
const heightChangeAnimation = keyframes`
0%{
  height: 50%;
}
100%{
  height: 100%;
}
`;
const size = {
  mobile: "600px",
  tablet: "912px",
  onek: "1024px",
  laptop: "1200px",
  bigScreen: "1409px",
};
const DeviceSizes = {
  mobile: `(max-width: ${size.mobile})`,
  belowtablet: `(max-width: ${size.tablet})`,
  abovetablet: `(min-width: ${size.tablet})`,
  belowonek: `(max-width: ${size.onek})`,
  laptop: `(min-width: ${size.laptop})`,
  belowLaptop: `(max-width: ${size.laptop})`,
  belowBigScreen: `(max-width: ${size.bigScreen})`,
  bigScreen: `(min-width: ${size.bigScreen})`,
};
const Container = styled(Grid)``;
const BaseCardDashboard = styled(Container)`
  && {
    font-family: "Mulish";
    letter-spacing: 0.01em;
    border-radius: 4px;
    justify-content: flex-start;
    background-color: ${Theme.primary_color.white};
    padding: 3%;
    margin-bottom: 4%;
    opacity: 0;
    animation-name: ${dashboardCardLoadAnimation};
    animation-duration: 0.3s;
    /* animation-delay: ${(props) => props.delay + "s"}; */
    animation-timing-function: ease-in-out;
    /* animation-direction: reverse; */
    animation-fill-mode: forwards;
    display: ${(props) => (props.isvisible === 1 ? "" : "none")};
    @media ${DeviceSizes.belowtablet} {
      margin-bottom: 4%;
    }
    @media ${DeviceSizes.mobile} {
      margin-bottom: 4%;
    }
  }
`;

const ScreensContainer = styled.div`
  background-color: #f7f8fc;
  height: 100%;
  width: 100%;
  & header {
    box-shadow: 0px 13px 15px -3px rgba(0, 0, 0, 0.1);
  }
`;
const SolidButton = styled(Button)`
  && {
    background-color: ${CommonColor.primary_color};
    margin: auto;
    width: 100%;
    font-weight: 700;
    border-radius: 10px;
    color: ${CommonColor.white};
  }
`;

const CardTitle = styled.div`
  text-transform: Capitalize;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 1.5em;
  color: ${Theme.primary_color.primary_color};
  margin-top: 5%;
  margin-left: 5%;
`;
const EmployeeNavBranchFilter = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 0.8%;
  padding-bottom: 0.8%;
  margin-right: 1%;
  width: 50px;
  background-color: ${(props) =>
    props.$ActiveFilterindex === props.$index
      ? Theme.primary_color.primary_color
      : Theme.primary_color.employee_branch_filter_button_deactive};
  transition: 0.2s background-color ease-in-out;
  font-family: "Mulish";

  font-weight: 500;
  font-size: ${FontSizes.Para};
  color: ${(props) =>
    props.$ActiveFilterindex === props.$index
      ? Theme.primary_color.white
      : Theme.primary_color.primary_color};
  text-transform: uppercase;
  @media ${DeviceSizes.mobile} {
    justify-content: space-around;
    padding: 3% 11%;
    margin-right: 6%;
  }
`;

const LeaveDecisionMakingButton = styled(Button)`
  && {
    width: 100%;
    height: 100%;
    color: ${(props) =>
      props.buttontype === "decline"
        ? Theme.primary_color.red
        : props.buttontype === "approve"
        ? Theme.primary_color.green
        : Theme.primary_color.primary_color};
    background-color: ${CommonColor.transparent};
    font-family: ${Theme.primary_color.Mulish};
    font-size: ${FontSizes.Para};
    text-transform: ${(props) =>
      props.buttontype === "edit text" ? "capitalize" : "uppercase"};
    letter-spacing: 0.01em;
    font-weight: 700;
    border-color: ${(props) =>
      props.buttontype === "decline"
        ? Theme.primary_color.declined_status_background
        : props.buttontype === "approve"
        ? Theme.primary_color.success_button_background
        : Theme.primary_color.primary_color};
    margin: 1%;
    border-radius: 10px;
    &:hover {
      border-color: ${(props) =>
        props.buttontype === "decline"
          ? Theme.primary_color.red
          : props.buttontype === "approve"
          ? Theme.primary_color.green
          : Theme.primary_color.primary_color};
    }
    & svg {
      opacity: 1;
    }
  }
`;
const VerticalScrollableArea = styled.div`
  height: ${(props) => `${props.height}${props.unit}`};
  margin: 0;
  width: 100%;
  overflow-y: auto;
  padding: ${(props) => props.padding};
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: ${Theme.primary_color.divider_gray};
    border-radius: 2px;
    transition: background-color 0.5s ease-in-out;
    -webkit-transition: background-color 0.5s ease-in-out;
    -o-transition: background-color 0.5s ease-in-out;
    -moz-transition: background-color 0.5s ease-in-out;
    &:hover {
      background-color: ${Theme.primary_color.primary_color};
    }
  }
  @media ${DeviceSizes.mobile} {
    height: 100%;
  }
`;
const MobileScroller = styled(VerticalScrollableArea)`
  overflow: auto;
  width: auto;
  overflow-x: hidden;
  width: 100%;
  @media ${DeviceSizes.mobile} {
    height: ${(props) => `${props.mobileHeight}${props.unit}`};
  }
`;

const HeaderIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media ${DeviceSizes.belowtablet} {
    margin-left: ${(props) => (props.component === "Profile" ? "0%" : "1%")};
  }
  @media ${DeviceSizes.mobile} {
    width: ${(props) => (props.title === "" ? "95%" : "95%")};
    margin-left: 3%;
  }
`;

const PageHeadingText = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: ${FontSizes.Title};
  color: ${Theme.primary_color.primary_color};
  text-transform: capitalize;
  letter-spacing: 0.01em;
  /* white-space: nowrap; */
  /* width: ${(props) =>
    props.comoponent === "Training Material" ? "30%" : ""}; */

  @media ${DeviceSizes.mobile} {
    margin: 0px;
  }
`;

const HeadingButton = styled(Button)`
  && {
    text-transform: capitalize;
    border-color: ${Theme.primary_color.primary_color};
    color: ${Theme.primary_color.primary_color};
    width: 100%;
    border-radius: 10px;
    &:hover {
      border-color: ${Theme.primary_color.primary_color};
    }
  }
`;
const AddViewAllButton = styled.div`
  && {
    text-transform: lowercase;

    color: ${Theme.primary_color.secondary_color};
    display: flex;
    width: max-content;
    align-items: center;
    cursor: pointer;
    font-family: "Mulish";
    font-size: ${FontSizes.Para};
    font-weight: 500;
    justify-content: flex-end;
    padding-right: 0;
    &:hover {
      background-color: ${Theme.primary_color.transparent};
      text-decoration: underline;
    }
  }
`;

const CardTitleContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.horizontalAlign};
  align-items: center;
  cursor: pointer;

  @media ${DeviceSizes.mobile} {
    align-items: center;
  }
`;

const Titleheading = styled.div`
  font-family: "Mulish";

  font-weight: 700;
  font-size: ${FontSizes.Subtitle};
  color: ${Theme.primary_color.primary_color};
  text-transform: capitalize;
  letter-spacing: 0.01em;
  width: max-content;
  display: flex;
`;
const UserButton = styled(Button)`
  && {
    background-color: ${(props) => props.bg};
    text-transform: "capitalize";
    letter-spacing: 1.5;
    padding-left: ${(props) => props.pl ?? "15px"};
    padding-right: ${(props) => props.pr ?? "15px"};
    padding-top: ${(props) => props.pt};
    padding-bottom: ${(props) => props.pb};
    text-transform: capitalize;
    border: ${(props) =>
      `${props.borderWidth ?? "1px"} solid ${props.randfarbe}`};
    font-family: "Mulish";
    font-weight: ${(props) => props.fontWeight ?? "700"};
    font-size: ${(props) => props.fontSize};
    border-radius: ${(props) => props.borderRadius ?? "10px"};
    color: ${(props) => props.schriftfarbe};
    margin-top: ${(props) => props.mt};
    width: ${(props) => props.width};
    &:hover {
      background-color: ${(props) => props.bg};
    }
  }
`;
const DatePickerCalendar = styled(DatePicker)`
  font-family: "Mulish";
  font-weight: 600;
  font-size: ${FontSizes.Para};
  padding-bottom: 1%;
  color: ${CommonColor.primary_color};

  & .rmdp-day,
  .rmdp-week-day {
    color: ${CommonColor.primary_color};
  }
  & .rmdp-header-values {
    color: ${CommonColor.primary_color};
  }
  & .rmdp-day.rmdp-deactive,
  .rmdp-day.rmdp-disabled {
    color: #8798ad;
  }

  & .rmdp-range {
    background-color: ${Theme.primary_color.primary_color};
    border-radius: 2px;
  }
  & .rmdp-today:not(.rmdp-day:hover) span {
    background-color: ${Theme.primary_color.transparent};
    color: unset;
    border-radius: 3px;
  }
  &
    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-selected)
    span:hover {
    background-color: ${Theme.primary_color.transparent};
    border-radius: 2px;
    color: ${Theme.primary_color.primary_color};
    border: 1px solid ${CommonColor.primary_color};
    border-color: ${(props) => (props.iserror ? CommonColor.red : "")};
    border-radius: 5px;
  }
  & .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: transparent;
  }
  && {
    & .rmdp-arrow {
      border-color: ${Theme.primary_color.primary_color};
      background-color: ${Theme.primary_color.transparent};
    }
  }
  & .rmdp-arrow-container:hover {
    background-color: ${Theme.primary_color.transparent};
    box-shadow: none;
  }
  &&& .rmdp-selected span {
    background-color: ${Theme.primary_color.primary_color};
    color: ${Theme.primary_color.white};
    border-radius: 5px;
    /* & span.sd {
      background-color: ${Theme.primary_color.transparent};
      box-shadow: none;
    } */
  }
  &&& .rmdp-selected span:hover {
    background-color: ${Theme.primary_color.primary_color};
    color: ${Theme.primary_color.white};
    border-radius: 5px;
  }
  & .rmdp-week-day {
    color: ${Theme.primary_color.primary_color};
  }
  & .rmdp-ep-arrow[direction="top"] {
    margin-top: 0%;
  }
`;

const AddViewAllButtonContainer = styled.div``;
const LogoutListItemContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${CommonColor.primary_color};
`;
const EventContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 4%;
  height: 100%;
  overflow: auto;
  @media ${DeviceSizes.bigScreen} {
    margin-top: 2%;
    justify-content: flex-start;
  }
`;
const TimeAndBranchContainer = styled.div`
  width: 20%;
  padding: 3%;
  padding-left: 0%;
  overflow: hidden;
  float: left;
  @media ${DeviceSizes.bigScreen} {
    width: 15%;
  }
`;
const TimeContainer = styled.div`
  font-size: ${FontSizes.SubPara};
  font-family: "Mulish";

  font-weight: 700;
  color: ${Theme.primary_color.secondary_color};
  text-transform: uppercase;
  width: 130%;
`;
const BranchContainer = styled.div`
  font-size: ${FontSizes.SmallText};
  font-family: "Mulish";

  font-weight: 700;
  color: ${Theme.primary_color.primary_color};
  text-transform: capitalize;
  margin-top: 4%;
`;

const EventDetailsContainer = styled.div`
  box-shadow: 4px 4px 31px -7px #00000026;
  width: 70%;
  height: 50px;
  padding: 3%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 0%;
  border-radius: 10px;
  @media ${DeviceSizes.bigScreen} {
    padding-left: 2%;
    padding-right: 3%;
    padding-bottom: 3%;
    height: 100%;
    width: 80%;
  }
  @media ${DeviceSizes.mobile} {
    margin-left: 5%;
    width: 50%;
  }
`;

const EventName = styled.div`
  font-family: "Mulish";

  font-weight: 600;
  color: ${Theme.primary_color.primary_color};
  text-transform: capitalize;
  font-size: ${FontSizes.SubPara};
`;

const EventConductorContainer = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  color: ${Theme.primary_color.primary_color};
  text-transform: capitalize;
  font-size: ${FontSizes.SmallText};
  opacity: 0.7;
  margin-top: 1%;
`;

const EventConductor = styled.span`
  color: ${Theme.primary_color.primary_color};
  font-weight: 600;
  opacity: 1;
`;

const PopupCoverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Theme.primary_color.popup_background_color};
`;

const Span = styled.span`
  color: ${(props) =>
    props.color ? props.color : Theme.primary_color.primary_color};
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${(props) => props.width ?? ""};
  margin-top: ${(props) => props.mt ?? ""};
  margin-bottom: ${(props) => props.mb ?? ""};
  height: ${(props) => props.height ?? ""};

  & svg {
    width: 100%;
    height: ${(props) => props.svgHeight ?? "200px"};
  }
`;

const NoDataImage = styled(CommonImages.NoDataIcon)``;
const NoDataText = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Subtitle};
  font-weight: 500;
  color: ${Theme.primary_color.primary_color};
  margin-top: 5%;
`;

const CardTitleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Div = styled.div`
  /* width: 100%; */
`;
const TypoGraphy = styled(Typography)`
  && {
    font-family: "Mulish";
    font-weight: ${(props) => props.weight ?? "500"};
    font-size: ${(props) => props.fontSize ?? FontSizes.Para};
    color: ${(props) => props.color ?? CommonColor.primary_color};
    width: ${(props) => props.width ?? "max-content"};
    text-align: ${(props) => props.textAlign ?? ""};
    text-transform: ${(props) => props.textTransform ?? ""};
    word-wrap: ${(props) => props.wordWrap};
    line-height: ${(props) => props.lineHeight};
    transition: all 0.5s ease-in-out;
  }
`;

const RelativePosContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  overscroll-behavior: contain;
  -webkit-mask-image: ${(props) =>
    props.scroll === 0
      ? "linear-gradient(90deg, #0000, #000 0% 80%, #0000)"
      : props.scroll >= 100
      ? "linear-gradient(90deg, #0000, #000 10% 100%, #0000)"
      : !!props.scroll
      ? "linear-gradient(90deg, #0000, #000 10% 80%, #0000)"
      : ""};
  -o-mask-image: ${(props) =>
    props.scroll === 0
      ? "linear-gradient(90deg, #0000, #000 0% 80%, #0000)"
      : props.scroll >= 100
      ? "linear-gradient(90deg, #0000, #000 10% 100%, #0000)"
      : !!props.scroll
      ? "linear-gradient(90deg, #0000, #000 10% 80%, #0000)"
      : ""};
  -moz-mask-image: ${(props) =>
    props.scroll === 0
      ? "linear-gradient(90deg, #0000, #000 0% 80%, #0000)"
      : props.scroll >= 100
      ? "linear-gradient(90deg, #0000, #000 10% 100%, #0000)"
      : !!props.scroll
      ? "linear-gradient(90deg, #0000, #000 10% 80%, #0000)"
      : ""};
  mask-image: ${(props) =>
    props.scroll === 0
      ? "linear-gradient(90deg, #0000, #000 0% 80%, #0000)"
      : props.scroll >= 100
      ? "linear-gradient(90deg, #0000, #000 10% 100%, #0000)"
      : !!props.scroll
      ? "linear-gradient(90deg, #0000, #000 10% 80%, #0000)"
      : ""};
  height: ${(props) => (props.isvisible === 1 ? "" : "0px")};
  /* @media ${DeviceSizes.belowtablet} {
    overscroll-behavior: auto;
  } */
  @media ${DeviceSizes.mobile} {
    width: ${(props) => props.mobileWidth ?? ""};
    overscroll-behavior: auto;
  }
`;
const CircularHalfDayIndicator = styled.div`
  width: ${(props) => props.size ?? "10px"};
  height: ${(props) => props.size ?? "10px"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const HalfCircle = styled.div`
  background-color: ${(props) => props.bg ?? CommonColor.secondary_color};
  width: 50%;
  height: 100%;
`;

const SelectDropDownWrapper = styled.div`
  margin-right: 9%;
`;
const HtmlTooltip = styledMui(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: CommonColor.white,
      maxWidth: 300,
      border: "1px solid #dadde9",
    },
  }),
  ({ theme }) => ({
    [`& .MuiTooltip-arrow`]: {
      color: CommonColor.white,
    },
  }),
  ({ theme }) => ({
    [`& .MuiTooltip-arrow:before`]: {
      border: `1px solid ${CommonColor.button_gray}`,
    },
  })
);

const StyledBadge = styled(Badge)`
  && {
    & .MuiBadge-badge {
      background-color: ${(props) => props.badgecolor ?? "red"};
      color: ${(props) => props.fontColor ?? "white"};
      font-size: ${(props) => props.fontSizes ?? FontSizes.VerySmallText};
    }
  }
`;

const MobileOnlyDiv = styled.div`
  display: none;
  @media ${DeviceSizes.mobile} {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
const CustomMarginAndFlexBox = styled.div`
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  display: ${(props) => props.display ?? "flex"};
  justify-content: ${(props) => props.jc ?? "center"};
  align-items: ${(props) => props.alignItem ?? "center"};
  flex-flow: ${(props) => props.flexFlow ?? "row nowrap"};
  width: ${(props) => props.width};
`;

const RotationAnimation = styled.div`
  transform: ${(props) => `rotate(${props.rotationangle}deg)`};
  transition: transform 0.5s ease-in-out;
`;

const AppearAnimation = styled.div`
  opacity: 0;
  animation-name: ${dashboardCardLoadAnimation};
  animation-duration: ${(props) => props.duration};
  /* animation-delay: ${(props) => props.delay}s; */
  animation-timing-function: ${(props) => props.timingvalue};
  animation-fill-mode: forwards;
`;
const CustomIcon = styled(Icon)`
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
`;
const SpaceInserter = styled.div`
  height: ${(props) => props.vertical ?? "0vh"};
  width: ${(props) => props.horizontal ?? "0vw"};
  background-color: ${Theme.primary_color.transparent};
`;
const InputStyle = {
  style: {
    height: 40,
    width: "100%",
    // fontFamily: FontFamilies.Mulish,
    fontSize: FontSizes.SubPara,
  },
};
const ErrorMarker = styled.div`
  margin: 2%;
  margin-left: 0%;
  color: ${Theme.primary_color.red};
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  opacity: 1;
`;
const PageHeaderContainer = styled.div`
  margin-bottom: 2%;

  @media ${DeviceSizes.bigScreen} {
    margin-top: 1%;
    margin-left: 2%;
    width: 97%;
  }
  @media ${DeviceSizes.laptop} {
    width: 90%;
    margin-left: 3%;
  }
  @media ${DeviceSizes.belowtablet} {
    width: 100%;
    margin-top: 8%;
  }
  @media ${DeviceSizes.mobile} {
    margin-bottom: 10%;
    width: 94%;
  }
`;
const TextButton = styled(Button)``;
const InputFile = styled.input`
  border-radius: 12px;
  border: 1px solid ${CommonColor.input_border};
  padding: 1.5%;
  width: 100%;
`;
const InputFileContainer = styled.div`
  width: calc(100% - 3px);
  margin-left: 3px;
`;

const ChipMultiInput = styled(OutlinedInput)`
  && {
    & fieldset {
      border: 1px solid ${CommonColor.input_border};
      border-radius: 10px;
    }
    &.Mui-focused fieldset {
      border: 1px solid ${CommonColor.primary_color};
    }
    & .MuiSelect-select {
      padding: 2%;
    }
  }
`;
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.borderRadius};
  background-color: ${CommonColor.light_primary};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: ${(props) => props.isvisible};
  pointer-events: ${(props) => (props.isvisible === 1 ? "" : "none")};
  transition: ${(props) =>
    `opacity ${props.transitionTiming ?? "1s"} ease-in-out`};
`;
const LineAbsolute = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  transform: ${(props) => props.transform};
  margin: ${(props) => props.margin};
  background-color: ${(props) => props.bg};
  width: ${(props) => props.width};
  height: 1px;
  z-index: ${(props) => props.zindex};
`;
const Slider = styled.div`
  width: ${(props) => props.width};
  left: ${(props) => props.left};
  background-color: ${CommonColor.primary_color};
  transition: all 0.5s ease-in-out;
`;
const TabsContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridCols};
`;
const ButtonCustom = styled(Button)`
  && {
    background-color: ${(props) => props.bgcolor ?? CommonColor.primary_color};
    border-color: ${(props) => props.outlinecolor ?? CommonColor.primary_color};
    color: ${(props) => props.textcolor ?? CommonColor.white};
    font-family: "Mulish";
    font-size: ${(props) => props.fontsize ?? FontSizes.Para};
    font-weight: ${(props) => props.fontWeight ?? "600"};
    width: 100%;
    cursor: pointer;
    padding: 3px 16px;
    text-transform: none;
    &:hover {
      border-color: ${(props) =>
        props.outlinecolor ?? CommonColor.primary_color};
      background-color: ${(props) =>
        props.hoverbg ?? CommonColor.light_primary};
      & svg {
        color: ${(props) =>
          props.texthovercolor ? props.texthovercolor : props.textcolor};
      }
    }
    & svg {
      opacity: 1;
      color: ${(props) => props.textcolor};
    }
  }
`;
const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: ${(props) => props.padding};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  object-fit: ${(props) => props.objectFit};
`;

const CarouseEmployeeImageContainer = styled.div`
  width: 40px;
  height: 40px;
`;
const InputBox = styled(Listbox.Button)`
  box-shadow: 0 0 0 2px ${CommonColor.button_gray};
  &:focus {
    box-shadow: 0 0 0 2px ${CommonColor.primary_color};
  }
`;
const TailwindStyledInput = styled.input`
  box-shadow: 0 0 0 2px ${CommonColor.button_gray};
  &:focus {
    box-shadow: 0 0 0 2px ${CommonColor.primary_color};
  }
`;

const TailwindStyledTextArea = styled.textarea`
  box-shadow: 0 0 0 2px ${CommonColor.button_gray};
  &:focus {
    box-shadow: 0 0 0 2px ${CommonColor.primary_color};
  }
`;

export {
  TailwindStyledTextArea,
  LogoutListItemContainer,
  TailwindStyledInput,
  InputBox,
  AddViewAllButton,
  AddViewAllButtonContainer,
  AppearAnimation,
  AvatarImage,
  BaseCardDashboard,
  BranchContainer,
  ButtonCustom,
  CardTitle,
  CardTitleButtonContainer,
  CardTitleContainer,
  CarouseEmployeeImageContainer,
  ChipMultiInput,
  CircularHalfDayIndicator,
  Container,
  CustomIcon,
  CustomMarginAndFlexBox,
  dashboardCardLoadAnimation,
  DatePickerCalendar,
  DeviceSizes,
  Div,
  EmployeeNavBranchFilter,
  ErrorMarker,
  EventConductor,
  EventConductorContainer,
  EventContainer,
  EventDetailsContainer,
  EventName,
  HalfCircle,
  HeaderIcon,
  HeadingButton,
  heightChangeAnimation,
  HtmlTooltip,
  InputFile,
  InputFileContainer,
  InputStyle,
  LeaveDecisionMakingButton,
  LineAbsolute,
  MobileOnlyDiv,
  MobileScroller,
  NoDataContainer,
  NoDataImage,
  NoDataText,
  opacityChangeAnimation,
  Overlay,
  PageHeaderContainer,
  PageHeadingText,
  PopupCoverContainer,
  RelativePosContainer,
  RotationAnimation,
  ScreensContainer,
  SelectDropDownWrapper,
  Slider,
  SolidButton,
  SpaceInserter,
  Span,
  StyledBadge,
  TabsContainer,
  TextButton,
  TimeAndBranchContainer,
  TimeContainer,
  Titleheading,
  TypoGraphy,
  UserButton,
  VerticalScrollableArea,
};
