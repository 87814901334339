import { Icon } from "@iconify/react";
import { Box, Button, List, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import StyledComponent, { keyframes } from "styled-components";
import CommonColor from "../utils/common.colors";
import { DeviceSizes } from "./common.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";

function DrawerCloseIcon() {
  return {
    color: "white",
  };
}

function DrawerItem(props) {
  return {
    height: 35,
    justifyContent: props.open ? "initial" : "center",
    borderLeft:
      props.isSelectedIndex === props.index
        ? "2px solid white"
        : "2px solid transparent",
  };
}

function sideMenuIconStyle(open) {
  return {
    minWidth: 0,
    mr: open ? 1.5 : "auto",
    justifyContent: "center",
  };
}

const ListItemStyle = (activeIndex, index) => {
  return {
    borderLeft: "2px solid transparent",
    transitionDuration: "100ms",
    transition: "all 0.5s ease-in-out !important",
    ...(activeIndex === index && {
      borderColor: CommonColor.white,
      backgroundColor: Theme.primary_color.drawer_menu_active,
    }),
  };
};

const PrexelLogoHeader = StyledComponent.img`
  height:40px;
  width:140px;
  `;

const DrawerMenuText = (open, isSelectedIndex, index) => {
  return {
    opacity: open ? (isSelectedIndex === index ? 1 : 0.5) : 0,
    transition: open ? "opacity 0.5s ease-in-out" : "opacity 0s ease-in",
    letterSpacing: "0.01338em",
    fontFamily: "Mulish !important",
    fontSize: FontSizes.SmallText,
    fontWeight: "600 ",
  };
};
const DrawerText = styled(ListItemText)`
  & span {
    letter-spacing: 0.01338em;
    font-family: "Mulish";
    font-size: ${FontSizes.Subtitle};
    font-weight: 600;
    color: ${CommonColor.white};
  }
`;
const DividnLineContainer = styled(Box)`
  text-transform: capitalize;
  margin-top: ${(props) => (props.ism === 1 ? "2%" : "0")};
  margin-bottom: ${(props) => (props.ism === 1 ? "2%" : "0")};
  & hr {
    background-color: ${Theme.primary_color.white};
    opacity: 0.2;
  }
`;
const ComponentList = StyledComponent(List)`
  && {
    width: 100%;
    position: relative;
    padding-top: 0px;
    & .css-f858k8-MuiButtonBase-root-MuiListItemButton-root:hover{
      background-color: ${CommonColor.drawer_menu_active};
    }
    & .MuiListItem-root:hover{
      background-color: ${CommonColor.drawer_menu_active};
    }
    & .css-f858k8-MuiButtonBase-root-MuiListItemButton-root:active{
      background-color: ${CommonColor.drawer_menu_active};
    }
  }
`;
const DrawerWrapper = StyledComponent.div`
  width: ${(props) => (props.open ? props.openWidth : props.closedWidth)};
  transition: all 0.2s ease-in-out;
  background-color: ${CommonColor.primary_color};
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: ${(props) => (props.open ? "" : "hidden")};
  height: 100%;
  && ::-webkit-scrollbar {
  width: 0em;
  height: 0em;
}
  && ::-webkit-scrollbar-thumb {
  cursor: none;
  background-color: transparent;
  width: 0px;
}
  position: relative;
  @media ${DeviceSizes.belowtablet}{
    justify-content: flex-start;
    height: 100%;
    overflow: hidden auto;
  }
`;

const ActiveShower = StyledComponent.div`
  display: block;
  position: absolute;
  top: 0;
  top: ${(props) => props.position};
  left: 0;
  transition: top 2s;
  width: 2px;
  height: 35px;
  border-left: 1px solid ${Theme.primary_color.white};
  background-color: ${Theme.primary_color.white};
  border-radius: 10px;
`;

const DrawerContentContainer = StyledComponent.div`
height: calc(100% - 148px);
overflow: auto;
`;
const AppBarStyle = (_, drawerOpen) => {
  return {
    position: "relative",
    backgroundColor: CommonColor.white,
    filter: {
      sm: "brightness(1)",
      xs: drawerOpen ? "brightness(0.2)" : "brightness(1)",
    },
    paddingLeft: {
      md: 0,
      sm: 4,
      xs: "",
    },
    "& .MuiToolbar-root": {
      paddingLeft: "2%",
      paddingRight: "2%",
    },
  };
};

const DrawerHeaderWrapper = StyledComponent.div`
  width: ${(props) => (props.open ? props.openWidth : props.closedWidth)};
`;

const changeWidth = keyframes`
0%{
  width: 64px;
}
100%{
  width: 240px;
}
`;
const DrawerImageandCloseWrapper = StyledComponent.div`

  animation: ${changeWidth} 0.5s ease-in-out;
  animation-fill-mode: ${(props) => (props.open ? "forwards" : "reverse")}
`;
const DrawerContentWrapper = StyledComponent.div`
width: ${(props) => (!props.open ? "0px" : "")};
height: ${(props) => (!props.open ? "0px" : "65px")};
overflow: hidden;
display: flex; 
justify-content: space-between;
align-items: center;
margin-left: 5%;
transition: all 0s linear;
@media ${DeviceSizes.mobile}{
  height: ${(props) => (!props.open ? "0px" : "55px")};
}
`;

const IconWrappperDrawerHeader = StyledComponent.div`
margin-left: 2%;
margin-top: -5%;
transition: all 0s linear;
cursor: pointer;
color: ${CommonColor.white};
font-size: ${FontSizes.LargeText};
font-family: "Mulish";
  & svg {
    color: white;
  }
`;
const IconifyIcon = StyledComponent(Icon)`
color:${(props) =>
  props.$isActive
    ? Theme.primary_color.white
    : Theme.primary_color.dashboard_inactive};
height:1.3em;
width:1.3em;
transition: all 1s ease-in-out;
`;

export {
  ActiveShower,
  AppBarStyle,
  ComponentList,
  DividnLineContainer,
  DrawerCloseIcon,
  DrawerContentContainer,
  DrawerContentWrapper,
  DrawerHeaderWrapper,
  DrawerImageandCloseWrapper,
  DrawerItem,
  DrawerMenuText,
  DrawerText,
  DrawerWrapper,
  IconWrappperDrawerHeader,
  IconifyIcon,
  ListItemStyle,
  PrexelLogoHeader,
  sideMenuIconStyle,
};
