import TrackerUtils from "../APICallServices/APIUtils";

export default class ManagerProvider {
  constructor() {
    this.trackerUtils = new TrackerUtils();
  }
  addTypingScore = (data) => {
    return this.trackerUtils.init().post("tracker/add-typing-score", data);
  };
  getTypingScore = (data) => {
    return this.trackerUtils.init().get("tracker/get-typing-score");
  };
  deleteSpeedyPrexelite = (data) => {
    return this.trackerUtils.init().post("tracker/delete-typing-score", data);
  };
}
