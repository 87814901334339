import { createSlice } from "@reduxjs/toolkit";
//  
export const leaveSlice = createSlice({
  name: "employee",
  initialState: {
    leave_employees_data: [],
    leave_list: [],
    active_live_list: [],
    pending_employees_leave: [],
    pending_employee_leave: [],
    employee_leave_dates: [],
    employee_late_dates: [],
    selected_leave: null,
    total_leave: 0,
    used_leave: 0,
    active_user_total_leave: 0,
    active_user_used_leave: 0,
    total_leave_number_info: 0,
    used_leave_number_info: 0,
    active_user_leave_list: [],
    active_user_leave_list_profile: [],
    leave_data: [],
    leave_history_data: [],
    leave_balance: 0,
  },
  reducers: {
    setEmployeeLeave: (state, action) => {
      state.leave_employees_data = action.payload;
    },
    updateEmployeeLeaveDatas: (state, action) => {
      state.leave_list = action.payload;
    },
    updatePendingEmployeeLeaveData: (state, action) => {
      state.pending_employees_leave = action.payload;
    },
    updatePendingEmployeeLeaveDatas: (state, action) => {
      state.pending_employee_leave = action.payload;
    },
    updateEmployeeAppliedLeaveDate: (state, action) => {
      state.employee_leave_dates = action.payload;
    },
    updateEmployeeAppliedLateDate: (state, action) => {
      state.employee_late_dates = action.payload;
    },
    updateSelectedLeaveDate: (state, action) => {
      state.selected_leave = action.payload;
    },
    updateActiveUserLeaveListData: (state, action) => {
      state.active_user_leave_list = action.payload;
    },
    updateActiveUserLeaveListDataProfile: (state, action) => {
      state.active_user_leave_list_profile = action.payload;
    },
    updateActiveUserLeaveNumbers: (state, action) => {
      state.active_user_total_leave = action.payload.total_leave;
      state.active_user_used_leave = action.payload.used_leave;
    },
    updateLeaveNumbers: (state, action) => {
      state.total_leave = action.payload.total_leave;
      state.used_leave = action.payload.used_leave;
      state.leave_balance = action.payload.leave_balance;
      state.leave_data = action.payload.data;
    },
    updateLeaveNumbersLeaveInfo: (state, action) => {
      state.total_leave_number_info = action.payload.total_leave;
      state.used_leave_number_info = action.payload.used_leave;
      state.leave_balance = action.payload.leave_bal;
    },
    updateActiveUserLeave: (state, action) => {
      state.active_user_leave_list = action.payload;
    },
    setLeaveHistoryData: (state, action) => {
      state.leave_history_data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLeaveHistoryData,
  updateActiveUserLeave,
  setEmployeeLeave,
  updateActiveUserLeaveNumbers,
  updateActiveUserLeaveListDataProfile,
  updateActiveUserLeaveListData,
  updateEmployeeLeaveDatas,
  updatePendingEmployeeLeaveData,
  updatePendingEmployeeLeaveDatas,
  updateEmployeeAppliedLeaveDate,
  updateEmployeeAppliedLateDate,
  updateLeaveNumbers,
  updateSelectedLeaveDate,
  updateLeaveNumbersLeaveInfo,
} = leaveSlice.actions;

export default leaveSlice.reducer;

/*

*/
