import ApiUtils from "../APICallServices/APIUtils";

export default class AttendanceProvider {
  constructor() {
    this.managerUtils = new ApiUtils();
  }
  login = (data) => {
    return this.managerUtils.init().post("attendance/add-attendance", data);
  };

  checkAttendance = (data) => {
    return this.managerUtils.init().post("attendance/check-attendance", data);
  };
  updateOutTime = (data) => {
    return this.managerUtils.init().post("attendance/update-out-time", data);
  };
  getMonthAttendance = (data) => {
    return this.managerUtils
      .init()
      .post("attendance/get-attendance-by-user-month", data);
  };
  getAllAttendance = (data) => {
    return this.managerUtils
      .init()
      .post("attendance/get-all-user-attendance", data);
  };
  removeEmployeeLate = (data) => {
    return this.managerUtils.init().post("");
  };
  changeAttendanceToLate = (data) => {
    return this.managerUtils.init().post("");
  };
  markPresent = (data) => {
    return this.managerUtils.init().post("attendance/mark-present", data);
  };
  markLate = (data) => {
    return this.managerUtils.init().post("attendance/update-late-come", data);
  };
  getDailyAttendance = () => {
    return this.managerUtils.init().get("v2/attendance/get-daily-attendance");
  };
  deleteAttendance = (data) => {
    return this.managerUtils.init().post("attendance/delete-present", data);
  };
  addShift = (data) => {
    return this.managerUtils.init().post("applicant/add-office-time", data);
  };
  getShift = () => {
    return this.managerUtils.init().get("applicant/get-office-time");
  };
  updateShift = (data) => {
    return this.managerUtils.init().post("applicant/update-office-time", data);
  };
  getAllAttendanceByDay = (data) => {
    return this.managerUtils.init().post("v2/attendance/get_attendance_by_day" , data);
  }
}
