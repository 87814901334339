import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { PageHeadingText } from "../../style/common.style";
import {
  AccountUserDetail,
  IconNameHelper,
  NavBarIconContainer,
  NavBarTextContainer,
  NavbarContainer,
  NavbarIcon,
  UserDetailContainer,
  UserImage,
  UserImageContainer,
} from "../../style/navbar.style";
import { userdata } from "../../utils/common.key";
import { NoProfilePhoto, ProfileBaseUrl } from "../../utils/constant";

import {
  openNotification,
  setActiveDrawerkey,
  setIsModalOpen,
  setModalType,
} from "../../reducer/dashboardreduxstore";
import { CommonString } from "../../utils/common.string";
import { getActiveDrawerData } from "../../utils/utils";

export default function NavBar(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [employeeDetailVisibility, setEmployeeDetailVisibility] =
    useState(false);
  const dashboard = useSelector((state) => state.dashboard);
  const employee = useSelector((state) => state.employee);
  const [openLocalPopup, setLocalOpenPopup] = useState(false);
  const active_drawer_data = getActiveDrawerData(employee.employee_acess);

  const NotificationVisiblityToggle = () => {
    dispatch(openNotification(!dashboard.notificationOpen));
    if (employeeDetailVisibility === true) {
      setEmployeeDetailVisibility(false);
    }
  };
  const ImageClicked = () => {
    let profileindex = profileIndex();
    const profileIndexNum = parseInt(profileindex);
    dispatch(
      setActiveDrawerkey({
        element: active_drawer_data[profileIndexNum],
        index: profileIndexNum,
      })
    );
    history.push("/home/profile");
  };

  function profileIndex() {
    let indexCounter = 0;
    for (
      let i = 0;
      i < getActiveDrawerData(employee.employee_acess).length;
      i++
    ) {
      if (
        getActiveDrawerData(employee.employee_acess)[i].key ===
        CommonString.profileScreen
      ) {
        break;
      }
      indexCounter++;
    }
    return `${indexCounter}`;
  }

  function getProfileUrl() {
    const user = getLocalStorage(userdata)?.user;
    
    if (user?.profile_image_url !== null) {
      return ProfileBaseUrl + user?.profile_image_url;
    }
    return NoProfilePhoto;
  }
  function getTitle() {
    return props.NavBarTitle;
  }

  function openPopup() {
    dispatch(setModalType(props.NavBarIcon.name));
    dispatch(setIsModalOpen(true));
  }

  function getIcon() {
    if (!props.NavBarIcon) return {};
    return props.NavBarIcon;
  }

  return (
    <React.Fragment>
      <NavbarContainer
        $activeIndex={dashboard.drawerActiveIndex}
        ispopupopen={dashboard.isModalOpen}
        open={props.open}
      >
        <NavBarTextContainer isdraweropen={props.DrawerOpen}>
          <PageHeadingText>{getTitle()}</PageHeadingText>
          <IconNameHelper title={getIcon().name} arrow>
            <NavBarIconContainer>
              <NavbarIcon icon={getIcon().icon} onClick={() => openPopup()} />
            </NavBarIconContainer>
          </IconNameHelper>
        </NavBarTextContainer>

        {!props.isProfile ? (
          <UserDetailContainer>
            <AccountUserDetail>
              <UserImageContainer onClick={() => ImageClicked()}>
                <UserImage src={getProfileUrl()} alt="" />
              </UserImageContainer>
            </AccountUserDetail>
          </UserDetailContainer>
        ) : (
          ""
        )}
      </NavbarContainer>
    </React.Fragment>
  );
}
