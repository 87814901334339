const pageSynergyLeaguePage = ["points table"];
const eventTypes = [
  "general_events",
  "holiday",
  "birthday",
  "work_anniversary",
  "marriage_anniversary",
];

const timezoneArray = [
  {
    name: "United States",
    code: "US",
    emoji: "🇺🇸",
    unicode: "U+1F1FA U+1F1F8",
    id: 1,
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
  },
  {
    name: "India",
    code: "IN",
    emoji: "🇮🇳",
    id: 2,
    unicode: "U+1F1EE U+1F1F3",
    image:
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
  },
];

const monthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export { pageSynergyLeaguePage, eventTypes, timezoneArray, monthShort };
