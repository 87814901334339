import React from "react";
import { FlexContainer } from "../../style/asset.style";
import BodyMeasurenment from "./BodyMeasurenment";
import EmployeeContact from "./EmployeeContact";
import { useWindowSize } from "../../component/commonComponents/hooks/getWindowDimension";

export default function AboutTab(props) {
  const windowWidth = useWindowSize().width;
  return (
    <FlexContainer
      verticalAlign="flex-start"
      height="89%"
      flexDirection={windowWidth < 600 ? "column" : "row"}
    >
      <EmployeeContact
        employee={props.employee}
        activeTab={props.activeTab}
        isPopupOpen={props.isPopupOpen}
      />

      <BodyMeasurenment
        employee={props.employee}
        activeTab={props.activeTab}
        isPopupOpen={props.isPopupOpen}
      />
    </FlexContainer>
  );
}
