import { createSlice } from "@reduxjs/toolkit";


export const workFromHomeSlice = createSlice({
    name : "workfromhome",
    initialState:{
        work_from_home_data :[],
        total_days : 10,
        used_days:0,
        remaining_days:10
    },

    reducers :{
        setWorkFromHomeData : (state , action) => {
            state.work_from_home_data = action.payload;
        },

        setWorkFromHomeDetails:(state ,action )=>{
            state.total_days = action.payload.wfh_available_days;
            state.used_days = action.payload.used_wfh_days;
            state.remaining_days = action.payload.remaining_wfh_days
        }
    }
})

export const {setWorkFromHomeData , setWorkFromHomeDetails} = workFromHomeSlice.actions;

export default workFromHomeSlice.reducer;