import { createSlice, current } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export const eventSlice = createSlice({
  name: "employee",
  initialState: {
    event_list: [],
    current_day_event: [],
    uploading_loading_status: 0,
    all_events: [],
    daily_events: [],
    active_date: dayjs(),
    active_month: null,
  },
  reducers: {
    setActiveMonth: (state, action) => {
      state.active_month = action.payload;
    },
    setActiveDate: (state, action) => {
      state.active_date = action.payloadl;
    },
    setDailyEvent: (state, action) => {
      state.daily_events = action.payload;
    },
    setFullEvent: (state, action) => {
      state.all_events = action.payload;
    },
    getAllEvents: (state, action) => {
      state.event_list = action.payload;
    },
    setLoadingStatus: (state, action) => {
      state.uploading_loading_status = action.payload;
    },

    deleteEvent: (state, action) => {
      const newEventList = current(state.all_events).filter(
        (event) => event.data._id !== action.payload._id
      );
      state.event_list = newEventList;

      const newCurrentEventList = current(state.current_day_event).filter(
        (event) => event._id !== action.payload._id
      );
      state.current_day_event = newCurrentEventList;
    },

    getCurrentDayEvents: (state, action) => {
      state.current_day_event = action.payload;
    },
    deleteCurrentDayEvent: (state, action) => {
      const newEventList = state.current_day_event.filter(
        (event) => event._id !== action.payload
      );
      state.current_day_event = newEventList;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAllEvents,
  setLoadingStatus,
  deleteEvent,
  getCurrentDayEvents,
  deleteCurrentDayEvent,
  setFullEvent,
  setDailyEvent,
  setActiveDate,
  setActiveMonth,
} = eventSlice.actions;

export default eventSlice.reducer;
