import LeaveReportProvider from "../services/provider/leaveReportProvider";

export default class LeaveReportController {
    constructor(props){
        this.leaveReportProvider = new LeaveReportProvider();
    }

    getLeaveReportData = async (data) => {
        
        let response = await this.leaveReportProvider.getLeaveReport(data)

        if(response){
            return response.data
        }
        else{
            console.error("error while getting list in leavereport controller");
            return false;
        }
    }
}