import ApiUtils from "../APICallServices/APIUtils";

export default class AccessManagementProvider {
  constructor() {
    this.apiUtils = new ApiUtils();
  }
  getAccess = (data = {}) => {
    return this.apiUtils.init().post("access/get-access", data);
  };
  updateAccess = (data) => {
    return this.apiUtils.init().post("access/update-access", data);
  };
  getAccessById = (data) => {
    return this.apiUtils.init().post("access/get-access-by-id", data);
  };
}
