import React, { useEffect, useState } from "react";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import Attendance from "../../componentsv2/Profile/Attendance";
import AttendanceController from "../../controller/attendanceController";
import {
  attendanceSelectedMonth,
  monthBasicAttendanceDetail,
  setActiveUserMonthAttendance,
} from "../../reducer/attendancereduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { TypoGraphy, VerticalScrollableArea } from "../../style/common.style";
import {
  ProfileDetailContainer,
  ProfileDetailTabs,
  ProfileTabContainer,
} from "../../style/profile.style";
import { userdata } from "../../utils/common.key";
import { Month, ProfileTabTitle } from "../../utils/constant";
import { getMonthBasicDetail } from "../../utils/utils";
import AboutTab from "./AboutTab";
import ProfileDetailNavBar from "./ProfileDetailNavBar";
import ProfileLeaveInfo from "./ProfileLeaveInfo";
import LeaveController from "../../controller/LeaveController";
import { updateActiveUserLeaveListDataProfile } from "../../reducer/leavereduxstore";

export default function ProfileDetails(props) {

  
  const employee = useSelector((state) => state.employee);
  const attendance = useSelector((state) => state.attendance);

  const filteredProfileTabTitle = ProfileTabTitle(
    employee.employee_acess,
    props.component === "main"
  ).filter((title) => title.isvisible);
  const [activeTab, setActiveTab] = useState(filteredProfileTabTitle[0]?.name);
  const [localLoader, setLocalLoading] = useState(false);
  const attendanceController = new AttendanceController();
  const monthFromDash = useSelector(
    (state) => state.pagetransition.profilePage.month
  );
  const leaveController = new LeaveController();
 

  const dispatch = useDispatch();
  useEffect(() => {
    setLocalLoading(true);

    if (monthFromDash !== null) {
      setMonthAndYearName(new DateObject(monthFromDash));
      setTimeout(() => {
        setLocalLoading(false);
      }, 1000);
      return;
    }
    setMonthAndYearName(new DateObject());
    setTimeout(() => {
      setLocalLoading(false);
    }, 1000);
  }, [props.employee, props.isPopupOpen]);

  async function setMonthAndYearName(DateData) {
    if (props.employee._id !== getLocalStorage(userdata).user._id) {
      setLocalLoading(true);
    }
    const year = new Date().getFullYear().toString();
    dispatch(attendanceSelectedMonth(DateData));

    const dataMonthOne = {
      user_id: props.employee._id,
      month: Month[DateData.monthIndex].toLowerCase(),
      year: year,
    };
    const attendanceMonthData = await attendanceController.getMonthAttendance(
      dataMonthOne
    );

    const dataForLeave = {
      recruiter_id : props.employee._id,
      month:DateData.monthIndex + 1 ,
      year : +year
    }

    const leaveMonthData = await leaveController.getEmployeeLeaveDataByMonth(dataForLeave);

    dispatch(updateActiveUserLeaveListDataProfile(leaveMonthData));



    
    dispatch(setActiveUserMonthAttendance(attendanceMonthData));
    const monthBasicDetail = getMonthBasicDetail(attendanceMonthData);

    dispatch(monthBasicAttendanceDetail(monthBasicDetail));
    setLocalLoading(false);
  }

  

  return (
    <ProfileDetailContainer>
      <ProfileDetailNavBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        monthAndYear={new DateObject(attendance.attendance_selected_month)}
        setMonthAndYear={setMonthAndYearName}
        filteredProfileTabTitle={filteredProfileTabTitle}
      />
      {activeTab ? (
        <ProfileDetailTabs>
          <ProfileTabContainer isvisible={activeTab === "attendance" ? 1 : 0}>
            <Attendance
              employee={props.employee}
              loading={localLoader || props.LoadingFlag}
              monthAndYear={
                new DateObject(attendance.attendance_selected_month)
              }
              isPopupOpen={props.isPopupOpen}
            />
          </ProfileTabContainer>
          <ProfileTabContainer isvisible={activeTab === "leave info" ? 1 : 0}>
            <ProfileLeaveInfo
              isPopupOpen={props.isPopupOpen}
              monthAndYear={
                new DateObject(attendance.attendance_selected_month)
              }
              employee={props.employee}
            />
          </ProfileTabContainer>

          <ProfileTabContainer isvisible={activeTab === "About" ? 1 : 0}>
            <AboutTab
              employee={props.employee}
              activeTab={activeTab}
              isPopupOpen={props.isPopupOpen}
            />
          </ProfileTabContainer>
          <ProfileTabContainer isvisible={activeTab === "Description" ? 1 : 0}>
            <VerticalScrollableArea height="100%">
              <TypoGraphy width="100%">
                {props.employee.about === ""
                  ? "Not updated yet"
                  : props.employee.about}
              </TypoGraphy>
            </VerticalScrollableArea>
          </ProfileTabContainer>
        </ProfileDetailTabs>
      ) : (
        ""
      )}
    </ProfileDetailContainer>
  );
}
