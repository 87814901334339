import ApiUtils from "../APICallServices/APIUtils";

export default class WorkFromHomeProvider {
    constructor(){
        this.workFromHomeApiUtils = new ApiUtils();
    }

    getWorkFromHomeData = () => {
        return this.workFromHomeApiUtils.init().get("/v2/wfh/get");
    }

    addWorkFromHome = (data) => {
        return this.workFromHomeApiUtils.init().post("/v2/wfh/add" , data );
    }

    updateWorkFromHome = (data) => {
        return this.workFromHomeApiUtils.init().post("/v2/wfh/update" , data )
    }
    deleteWorkFromHome = (data) => {
        return this.workFromHomeApiUtils.init().post("/v2/wfh/delete" , data )
    }

    getworkFromHomePersonalDetails = (data) => {
        return this.workFromHomeApiUtils.init().post("/v2/personal_details/wfh" , data)
    }


}

