import AppealProvider from "../services/provider/appealProvider";

export default class AppealController {
  constructor(props) {
    this.appealProvider = new AppealProvider();
  }

  getEmployeeAppealData = async () => {
    let response = await this.appealProvider.getAllEmployeeAppealData();
    // getAllEmployeeAppealData

    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting list in appeal controller");
      return false;
    }
  };

  getEmployeeAppealDataById = async (user_id) => {
    const response = await this.appealProvider.getEmployeeAppealData(user_id);

    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting list in appeal controller");
      return false;
    }
  };

  addEmployeeAppeal = async (data) => {
    const response = await this.appealProvider.addEmployeeAppeal(data);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("error while getting list in add appeal controller");
      return false;
    }
  };

  deleteEmployeeAppeal = async (id) => {
    const response = await this.appealProvider.deleteEmployeeAppeal(id);

    if (response.status === 200) {
      return response.data;
    } else {
      return false;
    }
  };

  updateEmployeeAppeal = async (data) => {
    const response = await this.appealProvider.updateEmployeeAppeal(data);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("error while getting update status of emp appeal");
      return false;
    }
  };
}
