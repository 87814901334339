// import { Button, Divider } from "@mui/material";
// import moment from "moment";
// import React, { useEffect, useState } from "react";
// import { GroupedVirtuoso, LogLevel } from "react-virtuoso";
// import EventCard from "../../screen/EventList/EventCard";
// import { FlexContainer } from "../../style/asset.style";
// import { Div, UserButton } from "../../style/common.style";
// import { EventCardContainer } from "../../style/event.style";
// import CommonColor from "../../utils/common.colors";
// import EventBirthday from "../EventBirthday/EventBirthday";
// import CelebrationNav from "../holiday/CelebrationNav";
// import { Month } from "../../utils/constant";
// import styled from "styled-components";
// import PageRefreshLoader from "../Loader/PageRefreshLoader";

// export default function VirtualEventCardList(props) {
//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState([]);
//   const groupCount = props.eventList.map(
//     (eventMonthData) => eventMonthData.length
//   );
//   useEffect(() => {
//     window.addEventListener("error", (e) => {
//       if (
//         e.message === "ResizeObserver loop limit exceeded" ||
//         e.message === "Script error." ||
//         e.message ===
//           "ResizeObserver loop completed with undelivered notifications."
//       ) {
//         const resizeObserverErrDiv = document.getElementById(
//           "webpack-dev-server-client-overlay-div"
//         );
//         const resizeObserverErr = document.getElementById(
//           "webpack-dev-server-client-overlay"
//         );
//         if (resizeObserverErr) {
//           resizeObserverErr.setAttribute("style", "display: none");
//         }
//         if (resizeObserverErrDiv) {
//           resizeObserverErrDiv.setAttribute("style", "display: none");
//         }
//       }
//     });
//   }, []);

//   useEffect(() => {
//     // load some initial data when the component mounts
//     setLoading(true);
//     setTimeout(() => {
//       setData(groupCount.slice(0, 1)); // load only the first group
//       setLoading(false);
//     }, 1000);
//   }, []);

//   const loadMore = () => {
//     // a function to load more data when the user clicks the button or reaches the end of the list
//     setLoading(true);
//     setTimeout(() => {
//       setData(groupCount.slice(0, data.length + 1)); // load one more group
//       setLoading(false);
//     }, 1000);
//   };

//   return (
//     <GroupedVirtuoso
//       style={{ height: "100%", width: "100%" }}
//       ref={props.virtuosoRef}
//       //   totalCount={props.eventList.length}
//       groupCounts={data}
//       logLevel={LogLevel.DEBUG}
//       //   scrollSeekConfiguration={true}
//       endReached={loadMore} // call the load more function when the end is reached
//       //   useWindowScroll={true}
//       //   overscan={"150%"}
//       components={{
//         Item: ItemContainer,
//         List: ListContainer,
//         Footer: () => {
//           return (
//             <div
//               style={{
//                 padding: "2rem",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               {loading ? (
//                 <PageRefreshLoader height={80} width={80} /> // show a loader when loading
//               ) : (
//                 <UserButton
//                   variant="contained"
//                   bg={CommonColor.primary_color}
//                   fontWeight="500"
//                   onClick={loadMore}
//                 >
//                   Click to Load More
//                 </UserButton>
//               )}
//             </div>
//           );
//         },
//       }}
//       //   fixedItemHeight={200}
//       //   groupCounts={props.eventList.map(
//       //     (eventMonthData) => eventMonthData.length
//       //   )}
//       groupContent={renderGroupHeader}
//       itemContent={(index, indexM) => {
//         if (indexM === 0) {
//           return (
//             <FlexContainer
//               key={indexM}
//               flexDirection="column"
//               verticalAlign="flex-start"
//               rowGap="4%"
//               height="max-content"
//               style={{
//                 background: CommonColor.white,
//                 marginBottom: 10,
//                 padding: "1.3%",
//                 borderRadius: 10,
//                 boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1);",
//               }}
//             >
//               <FlexContainer flexWrap="wrap" rowGap="4%">
//                 {props.eventList[indexM][index].type === 1 ? (
//                   <EventCardContainer
//                     key={index}
//                     id={moment(props.eventList[indexM][index].date).format(
//                       "MM-DD"
//                     )}
//                   >
//                     <EventCard
//                       index={index}
//                       element={props.eventList[indexM][index].data}
//                     />
//                   </EventCardContainer>
//                 ) : (
//                   <EventCardContainer
//                     id={moment(props.eventList[indexM][index].date).format(
//                       "MM-DD"
//                     )}
//                     key={index}
//                   >
//                     <EventBirthday
//                       event={props.eventList[indexM][index].data}
//                       index={index}
//                       eventType={props.eventList[indexM][index].type}
//                     />
//                   </EventCardContainer>
//                 )}
//               </FlexContainer>
//             </FlexContainer>
//           );
//         }
//         let arraySize = 0;
//         for (let i = 0; i < indexM; i++) {
//           arraySize += props.eventList[i].length;
//         }

//         return (
//           <FlexContainer
//             key={indexM}
//             flexDirection="column"
//             verticalAlign="flex-start"
//             rowGap="4%"
//             height="max-content"
//             style={{
//               background: CommonColor.white,
//               marginBottom: 10,
//               padding: "1.3%",
//               borderRadius: 10,
//               boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1);",
//             }}
//           >
//             <FlexContainer flexWrap="wrap" rowGap="4%">
//               {props.eventList[indexM][index - arraySize].type === 1 ? (
//                 <EventCardContainer
//                   key={index}
//                   id={moment(
//                     props.eventList[indexM][index - arraySize].date
//                   ).format("MM-DD")}
//                 >
//                   <EventCard
//                     index={index}
//                     element={props.eventList[indexM][index - arraySize].data}
//                   />
//                 </EventCardContainer>
//               ) : (
//                 <EventCardContainer
//                   id={moment(
//                     props.eventList[indexM][index - arraySize].date
//                   ).format("MM-DD")}
//                   key={index}
//                 >
//                   <EventBirthday
//                     event={props.eventList[indexM][index - arraySize].data}
//                     index={index}
//                     eventType={props.eventList[indexM][index - arraySize].type}
//                   />
//                 </EventCardContainer>
//               )}
//             </FlexContainer>
//           </FlexContainer>
//         );
//       }}
//     />
//   );
// }

// const renderGroupHeader = (index) => {
//   return (
//     <Div style={{ background: CommonColor.white }}>
//       <CelebrationNav subtitle={Month[index]} title="" />
//       <Divider variant="fullWidth" sx={{ mb: "1%", mt: "0" }} />
//     </Div>
//   );
// };

// const ItemContainer = styled.div`
//   height: 200px;
//   width: 45%;
// `;

// const ListContainer = styled.div`
//   display: flex; // use flexbox layout for the list element
//   flex-wrap: wrap; // allow wrapping items to the next line
//   align-items: stretch; // stretch items to fill the available space
//   justify-content: space-between; // distribute items evenly along the row
// `;

import { Divider } from "@mui/material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import EventCard from "../../screen/EventList/EventCard";
import { FlexContainer } from "../../style/asset.style";
import { TypoGraphy } from "../../style/common.style";
import { LoaderContainer } from "../../style/dashboard.style";
import {
  EventCardContainer,
  EventNavBarContainer,
  EventScreenContainer,
} from "../../style/event.style";
import { NoDataContainerCelebration } from "../../style/holiday.style";
import CommonColor from "../../utils/common.colors";
import { CommonString } from "../../utils/common.string";
import { Month } from "../../utils/constant";
import { eventTypes } from "../../utils/constant.array";
import EventBirthday from "../EventBirthday/EventBirthday";
import EventlistShimmer from "../Loader/shimmers/EventlistShimmer";
import NoData from "../commonComponents/NoData/NoData";

export default function VirtualEventCardList(props) {
  const event = useSelector((state) => state.event);
  return (
    <EventScreenContainer>
      <EventNavBarContainer>
        <TypoGraphy
          color={CommonColor.secondary_color}
          weight="600"
          ml={2}
          lineHeight="30px"
        >
          {Month[event.active_month - 1]}
        </TypoGraphy>
        <Divider flexItem />
      </EventNavBarContainer>
      {!props.loading ? (
        <FlexContainer
          flexWrap="wrap"
          flex="2 1 auto"
          jc="space-between"
          padding={"0 3% 3% 3%"}
        >
          {event.all_events.length !== 0 ? (
            event.all_events.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  {data.type === eventTypes[0] ? (
                    <EventCardContainer
                      key={index}
                      id={moment(data.date).format("MM-DD")}
                    >
                      <EventCard
                        index={index}
                        element={data.data}
                        length={event.all_events.length}
                      />
                    </EventCardContainer>
                  ) : (
                    <EventCardContainer
                      id={moment(data.date).format("MM-DD")}
                      key={index}
                    >
                      <EventBirthday
                        event={data.data}
                        index={index}
                        length={event.all_events.length}
                        eventType={data.type}
                      />
                    </EventCardContainer>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <NoDataContainerCelebration>
              <NoData noDataText={CommonString.noEventFound} />
            </NoDataContainerCelebration>
          )}
        </FlexContainer>
      ) : (
        <LoaderContainer>
          <EventlistShimmer />
        </LoaderContainer>
      )}
    </EventScreenContainer>
  );
}
