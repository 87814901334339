import { AppBar, Grid, Toolbar } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../component/drawer/NavBar";
import LeaveController from "../../controller/LeaveController";
import {
  updateActiveUserLeaveListDataProfile,
  updateActiveUserLeaveNumbers,
} from "../../reducer/leavereduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { AppBarStyle } from "../../style/drawer.style";
import {
  About,
  AboutEmployeeContainer,
  AboutHeading,
  AboutText,
  DividerColored,
  DividerContainer,
  EmployeeAboutDetailContainer,
  EmployeeBasicText,
  EmployeeBasicsContainer,
  EmployeeEmail,
  EmployeeImage,
  EmployeeImageContainer,
  EmployeeName,
  EmployeeNameContainer,
  PersonalDetailContainer,
  PersonalDetailHeading,
  PersonalDetailInfo,
  PersonalInfoContainer,
  PersonalInfoHeading,
  ProfileContainer,
  ReadMoreText,
} from "../../style/profile.style";
import CommonColor from "../../utils/common.colors";
import { userdata } from "../../utils/common.key";
import { ProfileBaseUrl } from "../../utils/constant";
import { EmployeeLevelManagerList } from "../../utils/mockData";
import ProfileDetails from "./ProfileDetails";
import { TwelveHourFormatAmPm } from "../../utils/utils";
import { FlexContainer } from "../../style/asset.style";
import { useState } from "react";
import { CommonString } from "../../utils/common.string";
import moment from "moment";

export default function Profile(props) {
  const leaveController = new LeaveController();
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);
  const attendance = useSelector((state) => state.attendance);
  const [isReadingMore, setIsReadingMore] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (
      props.component === "main" &&
      !props.loadedPages.includes(CommonString.dashboardScreenKey)
    ) {
      getInitData();
    }
    if (
      props.component === "main" &&
      props.loadedPages.includes(CommonString.dashboardScreenKey)
    ) {
      setPageLoading(false);
    }
    if (props.component !== "main") setPageLoading(false);
  }, []);

  useEffect(() => {
    if (!props.employeeDetail || props.employeeDetail === null) return;
    getData();
  }, [props.employeeDetail]);

  async function getInitData() {
    setPageLoading(true);
    await props.getDashboardScreenData(getLocalStorage(userdata));
    setPageLoading(false);
  }

  function getShift() {
    if (props.employeeDetail === null) return {};
    const shift = attendance.shift.filter(
      (shiftData) => shiftData._id === props.employeeDetail.shift_id
    )[0];
    const shiftName = shift?.shift_type;
    const startTime = TwelveHourFormatAmPm(shift?.in_time);
    const endTime = TwelveHourFormatAmPm(shift?.out_time);
    return {
      name: shiftName,
      endTime: endTime,
      startTime: startTime,
    };
  }
  async function getData() {
    if (EmployeeLevelManagerList.includes(props.employeeDetail.level_name))
      return;

    const leaveInfos = await leaveController.getEmployeeLeaveData(
      props.employeeDetail._id
    );
    dispatch(updateActiveUserLeaveListDataProfile(leaveInfos));
    const sendData = {
      recruiter_id: props.employeeDetail._id,
      month: moment().month() + 1,
      year: moment().year(),
    };
    const leaveNumbersData = await leaveController.getLeaveNumbers(sendData);
    // setTotalLeave(leaveNumbersData.total_leave);
    // setUsedLeave(leaveNumbersData.used_leave);
    dispatch(updateActiveUserLeaveNumbers(leaveNumbersData));
  }
  function getUser() {
    return !props.employeeDetail || props.employeeDetail === null
      ? getLocalStorage(userdata).user
      : props.employeeDetail;
  }
  function getProfileImage() {
    const user = getUser();
    return ProfileBaseUrl + user.profile_image_url;
  }

  function getAboutLen() {
    if (isReadingMore) return getUser().about.length;

    return 62;
  }
  return (
    <React.Fragment>
      {props.component === "main" ? (
        <AppBar
          position="static"
          open={props.open}
          elevation={5}
          sx={AppBarStyle(dashboard.isModalOpen, props.open)}
        >
          <Toolbar>
            <NavBar
              DrawerOpen={props.open}
              LoadingFlag={props.LoadingFlag}
              NavBarTitle={"Profile"}
              NavBarIcon={{
                icon: "",
                name: "",
              }}
              isProfile={true}
            />
          </Toolbar>
        </AppBar>
      ) : (
        ""
      )}
      <ProfileContainer
        container
        spacing={2}
        ismain={props.component === "main"}
      >
        <Grid item sx={{ height: "100%", width: { xs: "100%", sm: "75%" } }}>
          <ProfileDetails
            LoadingFlag={pageLoading}
            employee={getUser()}
            isPopupOpen={props.isPopupOpen}
          />
        </Grid>
        <Grid item sx={{ height: "100%", width: { xs: "100%", sm: "25%" } }}>
          <AboutEmployeeContainer>
            <EmployeeBasicsContainer>
              <EmployeeImageContainer>
                <EmployeeImage src={getProfileImage()} alt="" />
              </EmployeeImageContainer>
              <EmployeeBasicText>
                <EmployeeNameContainer>
                  <EmployeeName>
                    <span style={{ color: CommonColor.secondary_color }}>
                      {getUser().name.split(" ")[0].toLowerCase()}&nbsp;
                    </span>
                    {getUser().name.split(" ")[1]}
                  </EmployeeName>
                </EmployeeNameContainer>
                <EmployeeNameContainer>
                  <EmployeeEmail>{getUser().email.toLowerCase()}</EmployeeEmail>
                </EmployeeNameContainer>
              </EmployeeBasicText>
            </EmployeeBasicsContainer>
            <DividerContainer>
              <DividerColored color={CommonColor.divider_gray} />
            </DividerContainer>
            <EmployeeAboutDetailContainer>
              <AboutHeading>About</AboutHeading>
              <AboutText>
                {!getUser().about || getUser().about === null ? (
                  "Not Updated"
                ) : (
                  <FlexContainer flexDirection="column">
                    <About>
                      {getUser().about.substring(0, getAboutLen())}
                      <ReadMoreText
                        onClick={() => setIsReadingMore(!isReadingMore)}
                      >
                        {isReadingMore ? " ...read less" : " ...read more"}
                      </ReadMoreText>
                    </About>
                  </FlexContainer>
                )}
              </AboutText>
            </EmployeeAboutDetailContainer>
            <PersonalInfoContainer>
              <PersonalInfoHeading>Personal info</PersonalInfoHeading>
              <PersonalDetailContainer>
                <PersonalDetailHeading>Phone No.</PersonalDetailHeading>
                <PersonalDetailInfo>{getUser().phone}</PersonalDetailInfo>
              </PersonalDetailContainer>
              <PersonalDetailContainer>
                <PersonalDetailHeading>Prexelite ID:</PersonalDetailHeading>
                <PersonalDetailInfo>
                  {getUser().employee_id.toUpperCase()}
                </PersonalDetailInfo>
              </PersonalDetailContainer>
              <PersonalDetailContainer>
                <PersonalDetailHeading>
                  {getShift().name} Shift
                </PersonalDetailHeading>
                <PersonalDetailInfo>
                  {getShift().startTime} - {getShift().endTime} IST
                </PersonalDetailInfo>
              </PersonalDetailContainer>
            </PersonalInfoContainer>
          </AboutEmployeeContainer>
        </Grid>
      </ProfileContainer>
    </React.Fragment>
  );
}
