import React, { useEffect, useRef } from "react";
import {
  EventCalendarContainer,
  EventCardListContainer,
  EventListConatainer,
  EventListScrollerContainer,
  EventsContainer,
} from "../../style/event.style";

import { AppBar, Toolbar } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VirtualEventCardList from "../../component/Event/VirtualEventCardList";
import AddEvent from "../../component/Forms/AddEvent";
import ModalPopup from "../../component/Popup/ModalPopup";
import EventCalendar from "../../component/calender/EventCalendar";
import EventCalendarMobile from "../../component/calender/EventCalendarMobile";
import { useWindowSize } from "../../component/commonComponents/hooks/getWindowDimension";
import NavBar from "../../component/drawer/NavBar";
import EventController from "../../controller/eventController";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import { setEmployeeBirthdayList } from "../../reducer/employeereduxstore";
import {
  setActiveDate,
  setActiveMonth,
  setDailyEvent,
  setFullEvent,
} from "../../reducer/eventreduxstore";
import { FlexContainer } from "../../style/asset.style";
import { AppBarStyle } from "../../style/drawer.style";

export default function EventList(props) {
  const dashboard = useSelector((state) => state.dashboard);
  const event = useSelector((state) => state.event);
  const employee = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const windowWidth = useWindowSize().width;
  const virtuosoRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const eventController = new EventController();

  useEffect(() => {
    if (event.all_events?.length > 0) {
      setLoading(false);
      return () => {};
    }

    getInitialData();
  }, []);

  async function getInitialData() {
    dispatch(setActiveDate(dayjs()));
    dispatch(setActiveMonth(dayjs().month() + 1));

    let data = await eventController.getMonthlyEventList(dayjs().month() + 1);
    data.sort((event1, event2) => {
      return (
        parseInt(moment(event1.date).format("MMDD")) -
        parseInt(moment(event2.date).format("MMDD"))
      );
    });

    dispatch(setFullEvent(data));
    await getDateData(dayjs());
    const bdayEmployees = await props.getEmployeeList();
    dispatch(setEmployeeBirthdayList(bdayEmployees));
    setLoading(false);
  }

  async function handleMonthChange(monthIndex) {
    setLoading(true);
    dispatch(setActiveMonth(monthIndex));
    let data = await eventController.getMonthlyEventList(monthIndex);
    data.sort((event1, event2) => {
      return (
        parseInt(moment(event1.date).format("MMDD")) -
        parseInt(moment(event2.date).format("MMDD"))
      );
    });
    dispatch(setFullEvent(data));
    dispatch(setDailyEvent([]));
    setLoading(false);
  }

  async function getDateData(dateObj) {
    dispatch(setActiveDate(dateObj));
    const dailyList = event.all_events.filter(
      (data) =>
        moment.tz(data.date, "UTC").format("DD") ===
        moment(dateObj).format("DD")
    );
    dispatch(setDailyEvent(dailyList));
  }

  function closePopup() {
    dispatch(setIsModalOpen(false));
  }
  function getModalChildren() {
    return <AddEvent onHandleClose={closePopup} isEdit={false} data={null} />;
  }

  function getView() {
    return (
      <EventListConatainer>
        {windowWidth > 900 ? (
          <React.Fragment>
            <EventListScrollerContainer id="scrollableContainerEventScreen">
              <VirtualEventCardList
                virtuosoRef={virtuosoRef}
                loading={loading}
              />
            </EventListScrollerContainer>
            <EventCalendarContainer>
              <EventCalendar
                getDateData={getDateData}
                handleMonthChange={handleMonthChange}
              />
            </EventCalendarContainer>
          </React.Fragment>
        ) : (
          <EventCalendarContainer>
            <EventCalendarMobile
              handleMonthChange={handleMonthChange}
              getDateData={getDateData}
              loading={loading}
            />
          </EventCalendarContainer>
        )}
      </EventListConatainer>
    );
  }

  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Events"}
            NavBarIcon={
              employee.employee_acess.events
                ? {
                    icon: "fluent:people-add-20-regular",
                    name: "Add Event",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      <EventsContainer ishalf={event.event_list.length !== 0}>
        <FlexContainer height="100%">
          <EventCardListContainer>{getView()}</EventCardListContainer>
        </FlexContainer>
      </EventsContainer>
      <ModalPopup open={dashboard.isModalOpen} popupSize="sm">
        {getModalChildren()}
      </ModalPopup>
    </React.Fragment>
  );
}
