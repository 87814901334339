import React, { useState } from "react";
import { useSelector } from "react-redux";
import ModalPopup from "../../component/Popup/ModalPopup";

import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import { LoaderWrapper } from "../../style/form.style";
import {
  BankDataContainer,
  BankDatakey,
  ContactDataEditButton,
  ContactWrapper,
  EmployeeContactContainer,
  EmployeeContactField,
  EmployeeContactFieldWrappper,
  TabTitle,
} from "../../style/profile.style";
import CommonColor from "../../utils/common.colors";
import { formatDisplayDate } from "../../utils/utils";
import ChangeBodyMeasurenment from "./ChangeBodyMeasurenment";

export default function BodyMeasurenment(props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const employee = useSelector((state) => state.employee);

  function getModalChildren() {
    return (
      <ChangeBodyMeasurenment
        onHandleClose={ClosePopup}
        employee={props.employee}
        data={employee.employee_body_measurenment}
        openPopup={openPopup}
      />
    );
  }
  function ClosePopup() {
    setOpenPopup(false);
  }
  function PopupOpener() {
    setOpenPopup(true);
  }
  return (
    <React.Fragment>
      <ModalPopup open={openPopup} popupSize="sm">
        {getModalChildren()}
      </ModalPopup>
      <EmployeeContactContainer>
        <TabTitle>
          Body Measurement&nbsp;
          <ContactDataEditButton
            onClick={() => PopupOpener()}
            variant="outlined"
            isvisible={employee.employee_acess.personal_details ? 1 : 0}
          >
            edit
          </ContactDataEditButton>
        </TabTitle>
        <ContactWrapper>
          {loading ? (
            <LoaderWrapper
              bg={CommonColor.popup_background_color}
              zIndex={1}
              position="absolute"
            >
              <PageRefreshLoader />
            </LoaderWrapper>
          ) : (
            ""
          )}

          <BankDataContainer>
            <BankDatakey>Measurement Date</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {formatDisplayDate(
                  employee.employee_body_measurenment.measurement_date
                ).format("Do MMM 'YY")}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>height</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_body_measurenment.height} cm
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>weight</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_body_measurenment.weight} kg
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>shoe size</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_body_measurenment.shoe_size} UK
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>across shoe size</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_body_measurenment.across_shoe_size} UK
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>chest</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_body_measurenment.chest} in
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          {/* <BankDataContainer> */}
          <BankDataContainer>
            <BankDatakey>shoulder</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_body_measurenment.shoulder} in
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
          <BankDataContainer>
            <BankDatakey>waist</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_body_measurenment.waist} in
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>

          <BankDataContainer>
            <BankDatakey>add on comments</BankDatakey>
            <EmployeeContactFieldWrappper>
              <EmployeeContactField>
                {employee.employee_body_measurenment.comments}
              </EmployeeContactField>
            </EmployeeContactFieldWrappper>
          </BankDataContainer>
        </ContactWrapper>
      </EmployeeContactContainer>
    </React.Fragment>
  );
}
