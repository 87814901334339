import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ProfileBaseUrl } from "../../../utils/constant";
import { CommonImages } from "../../../utils/common.images";
import { TypoGraphy } from "../../../style/common.style";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CountryPicker(props) {
  function getProfile(person) {
    if (!person || person === null || !person.name) return CommonImages.NoUser;
    return person.image;
  }
  function getName(person) {
    if (!person || person === null || !person.name) return props.placeholder;
    return person.name;
  }
  return (
    <Listbox value={props.value} onChange={props.onhandleChange}>
      {({ open }) => (
        <>
          <TypoGraphy
            className="block text-sm font-medium leading-6 text-gray-900"
            weight="600"
          >
            {props.title}
          </TypoGraphy>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-900 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <img
                  src={getProfile(props.value)}
                  alt=""
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                />
                <span className=" block truncate">{getName(props.value)}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.dataList.map((person) => (
                  <Listbox.Option
                    key={person.code}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "bg-theme-color-primary text-white"
                          : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={getProfile(person)}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          />
                          <span
                            className={classNames(
                              props.value.code === person.code
                                ? "font-semibold"
                                : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {person.name}
                          </span>
                        </div>

                        {props.value.code === person.code ? (
                          <span
                            className={classNames(
                              active
                                ? "text-white"
                                : "text-theme-color-primary",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
