import React, { forwardRef, useEffect, useRef } from "react";
import transition from "react-element-popper/animations/transition";
import { useSelector } from "react-redux";
import { DatePickerCalendar } from "../../../style/common.style";
import {
  CalendarTitle,
  DateCalendarContainer,
  MultiDateCalendarContainer,
  MultiDateChild,
  TextContainer,
  VerticalScrollableAreaInPopup,
} from "../../../style/form.style";
import TextInput from "../../commonComponents/inputs/TextInput";
import moment from "moment";

export default function WorkFromHomeLeave(props) {
  // const selectedLeave = useSelector((state) => state.leave.selected_leave);
  // const dashboard = useSelector((state) => state.dashboard);
  const employee = useSelector((state) => state.employee);
  const wfh = useSelector((state) => state.workfromhome);

  function getRemDays() {
    return wfh.remaining_days;
  }

  const calculateLeaveDays = (startDate, leaveDays) => {
    let daysCount = 0;
    let validDaysCount = 0;

    let date = moment(startDate);

    while (validDaysCount < leaveDays) {
      if (date.day() !== 6 && date.day() !== 0) {
        validDaysCount++;
      }

      date.add(1, "day");
      daysCount++;
    }

    return daysCount;
  };

  return (
    <React.Fragment>
      <VerticalScrollableAreaInPopup
        height="100"
        unit="%"
        isfullheight={!props.isEdit}
        isVisible={props.isExist}
      >
        <React.Fragment>
          <MultiDateCalendarContainer isvisible={props.isMultiForm ? 1 : 0}>
            <MultiDateChild>
              <CalendarTitle>From</CalendarTitle>
              <DatePickerCalendar
                mapDays={({ date }) => {
                  let isWeekend = [0, 6].includes(date.weekDay.index);

                  if (isWeekend)
                    return {
                      disabled: true,
                      style: { color: "#ccc" },
                      onClick: () => {},
                    };
                }}
                minDate={!employee.employee_acess.leave_details && Date.now()}
                multiple={false}
                // iserror={isErrorExist === 0}
                className="custom-calendar"
                format="MMM, DD, YYYY"
                animations={[transition()]}
                value={props.startDate}
                onChange={(e) => props.updateData(e.toString(), "start date")}
                showOtherDays
              ></DatePickerCalendar>
            </MultiDateChild>
            <MultiDateChild>
              <CalendarTitle>To</CalendarTitle>
              <DatePickerCalendar
                mapDays={({ date }) => {
                  let isWeekend = [0, 6].includes(date.weekDay.index);

                  if (isWeekend)
                    return {
                      disabled: true,
                      style: { color: "#ccc" },
                      onClick: () => {},
                    };
                }}
                minDate={
                  new Date(
                    new Date(props.startDate).getTime() + 24 * 60 * 60 * 1000
                  )
                }
                maxDate={
                  new Date(
                    new Date(props.startDate).getTime() +
                      calculateLeaveDays(
                        new Date(props.startDate),
                        wfh.remaining_days
                      ) *
                        24 *
                        60 *
                        60 *
                        1000
                  )
                }
                // iserror={isErrorExist === 1}
                multiple={false}
                format="MMM, DD, YYYY"
                className="custom-calendar"
                animations={[transition()]}
                value={props.endDate}
                onChange={(e) => props.updateData(e.toString(), "end date")}
                showOtherDays
              >
                {/* <ConfirmationButtonContainer>
                <ConfirmDate onClick={() => confirmDate()}>Confirm</ConfirmDate>
                <CancelDate variant="outlined" onClick={() => clearDate()}>
                  Clear
                </CancelDate>
              </ConfirmationButtonContainer> */}
              </DatePickerCalendar>
            </MultiDateChild>
          </MultiDateCalendarContainer>
          {/* <HalfDayOptionContainer isvisible={props.isMultiForm ? 1 : 0}>
            <HalfDayOption
              checked={isStartDay}
              fontColor={CommonColor.primary_color}
              fontWeight={isStartDay ? "600" : "500"}
              control={
                <MuiCalendarCheckbox
                  size="small"
                  checked={isStartDay}
                  onChange={(e) => handleChange(e)}
                  name="start"
                />
              }
              label={getText(isStartDay, "start")}
            />
            <HalfDayOption
              checked={isEndDay}
              isMultiForm={props.isMultiForm}
              fontColor={CommonColor.primary_color}
              fontWeight={isEndDay ? "600" : "500"}
              control={
                <MuiCalendarCheckbox
                  size="small"
                  checked={isEndDay}
                  onChange={(e) => handleChange(e)}
                  name="end"
                />
              }
              label={getText(isEndDay, "end")}
            />
          </HalfDayOptionContainer> */}
        </React.Fragment>
        <DateCalendarContainer isvisible={props.isMultiForm ? 1 : 0}>
          <CalendarTitle>Date</CalendarTitle>
          <DatePickerCalendar
            mapDays={({ date }) => {
              let isWeekend = [0, 6].includes(date.weekDay.index);

              if (isWeekend)
                return {
                  disabled: true,
                  style: { color: "#ccc" },
                  onClick: () => {},
                };
            }}
            value={props.startDate}
            onChange={(e) => props.updateData(e.toString(), "single date")}
            minDate={!employee.employee_acess.leave_details && Date.now()}
            className="custom-calendar"
            // calendarPosition="right-center"
            animations={[transition()]}
            showOtherDays

            // iserror={isErrorExist === 2}
          >
            {/* <ConfirmationButtonContainer>
              <ConfirmDate onClick={() => confirmDate()}>Confirm</ConfirmDate>
              <CancelDate variant="outlined" onClick={() => clearDate()}>
                Clear
              </CancelDate>
            </ConfirmationButtonContainer> */}
          </DatePickerCalendar>
        </DateCalendarContainer>
        <TextContainer>
          <TextInput
            title={"Reason"}
            placeholder={"Reason"}
            error={props.error === "reason"}
            helperText={props.error === "reason" && "Please enter reason"}
            value={props.reason}
            onChange={(e) => props.updateData(e.target.value, "reason")}
            multiline
          />
        </TextContainer>
      </VerticalScrollableAreaInPopup>
    </React.Fragment>
  );
}
