import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import {
  CustomTextFeild,
  InputText,
  MenuItemStyle,
  SelectFieldWrapper,
} from "../../../style/form.style";
import { FontSizes } from "../../../style/theme/font";
import CommonColor from "../../../utils/common.colors";

export default function DropDownListEmployee(props) {
  return (
    <SelectFieldWrapper>
      <InputText component="small-input-text">{props.title}</InputText>
      <CustomTextFeild
        select
        fullWidth
        value={props.value === null || !props.value ? "" : props.value.name}
        InputProps={{
          style: {
            fontSize: FontSizes.SubPara,
            borderColor: CommonColor.red,
          },
        }}
        size="small"
        title={props.title}
        placeholder={props.placeholder}
      >
        {props.dataList.map((option, index, array) => (
          <MenuItem
            key={index}
            divider={array.length - 1 !== index ? true : false}
            value={option.name}
            onClick={() => props.handleChange(option)}
            sx={MenuItemStyle(props)}
          >
            {option.name}
          </MenuItem>
        ))}
      </CustomTextFeild>
    </SelectFieldWrapper>
  );
}
