import React, { useState } from "react";
import {
  ButtonDeleteLeave,
  ButtonEditLeave,
  DurationAndStatus,
  EmployeeCardDivider,
  EmployeeLeaveDataContainer,
  EmployeeLeaveDataNavbar,
  EmployeeLeaveDataNavbarContainer,
  EmployeeLeaveDuaration,
  EmployeeLeaveFooter,
  EmployeeLeaveHalfDay,
  EmployeeLeaveInfoContainer,
  EmployeeLeaveNote,
  EmployeeLeaveNoteContainer,
  EmployeeLeaveNoteHeading,
  EmployeeLeaveTime,
  EmployeeLeaveTimeAndTypeContainer,
  EmployeeName,
  EmployeeNameContainer,
  EmployeeOptionsIconContainer,
  EmployeeTimeContainer,
  EmployeeTimeContainerTo,
  EmployeetimingHeader,
  IconNavbar,
  ImageContainerLeaveCard,
  LeaveStatusContainer,
  MenuOptions,
  ReadMoreOption,
} from "../../style/leave.style";
import LeaveDecisionButton from "../commonComponents/buttons/LeaveDecisionButton";
import LeaveStatus from "../commonComponents/buttons/LeaveStatus";

import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { CommonString } from "../../utils/common.string";
import {
  formatDisplayDate,
  getPendingLeaves,
  updateLeaveStatus,
} from "../../utils/utils";

import moment from "moment";
import { useSnackbar } from "notistack";
import ImageAvatar from "../../componentsv2/Images/ImageAvatar";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import LeaveController from "../../controller/LeaveController";
import {
  setEmployeeLeave,
  setLeaveHistoryData,
  updateActiveUserLeaveListData,
  updateActiveUserLeaveListDataProfile,
  updateEmployeeLeaveDatas,
  updateLeaveNumbersLeaveInfo,
  updatePendingEmployeeLeaveData,
  updatePendingEmployeeLeaveDatas,
  updateSelectedLeaveDate,
} from "../../reducer/leavereduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { Div, Span } from "../../style/common.style";
import {
  LeaveStatusMsg,
  LeaveStatusMsgContainer,
  LeaveStatusTitle,
} from "../../style/form.style";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import { userdata } from "../../utils/common.key";
import { Month, ProfileBaseUrl } from "../../utils/constant";
import ApplyLeave from "../Forms/ApplyLeave";
import ConfirmationMenu from "../Popup/ConfirmationMenu";
import ModalPopup from "../Popup/ModalPopup";
import { FontSizes } from "../../style/theme/font";
import { EmployeeAppealInfoContainer } from "../../style/appeal.style";
import AppealController from "../../controller/appealController";
import {
  deleteEmployeeAppeal,
  setEmployeeAppeal,
  setEmployeePendingAppeal,
} from "../../reducer/appealreduxstore";
import { reject } from "lodash";
import AttendanceController from "../../controller/attendanceController";
import { setActiveUserMonthAttendance } from "../../reducer/attendancereduxstore";

export default function AppealInfoCard(props) {
  const appealController = new AppealController();
  const [IsReadMore, setIsReadMore] = useState(false);
  const [isloading, setLoader] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(
    CommonString.pending.toUpperCase()
  );
  const [isOpen, setisOpen] = useState(false);
  const leaveList = useSelector((state) => state.leave.leave_employees_data);
  const leave = useSelector((state) => state.leave);
  const employee = useSelector((state) => state.employee);
  const leaveController = new LeaveController();
  const attendanceController = new AttendanceController();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const attendanceData = useSelector(
    (state) => state.attendance.active_user_month_attendance
  );

  const activeEmployeeData = props.data;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const ToggleReadmore = (event, index) => {
    event.stopPropagation();
    setIsReadMore(!IsReadMore);
  };

  const isAdmin = employee.employee_acess.attendance;

  function getDate(dateOriginalFormat) {
    if (!dateOriginalFormat) return "";
    if (dateOriginalFormat.length === 10) {
      return formatDisplayDate(dateOriginalFormat, "DD-MM-YYYY").format(
        "Do MMM 'YY"
      );
    }
    return formatDisplayDate(dateOriginalFormat).format("Do MMM 'YY");
  }
  function getReason() {
    const textLen = IsReadMore ? Infinity : 87;
    return props.data.reason.substr(0, textLen);
  }
  async function onClickFooterButton(_id) {
    setLoader(true);

    const response = await appealController.deleteEmployeeAppeal(_id);

    const successMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>Appeal deleted sucessfully</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );

    const errorMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>error</LeaveStatusTitle>
        <LeaveStatusMsg>please try after a while</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );

    if (response) {
      const employeeId = props.data.recruiter._id;
      let newresponse;
      if (isAdmin) {
        newresponse = await appealController.getEmployeeAppealData();
      } else {
        newresponse = await appealController.getEmployeeAppealDataById(
          employeeId
        );
      }

      // const year = new Date().getFullYear().toString();
      // const dataMonthOne = {
      //   user_id:employeeId,
      //   month: Month[props.currentMonthIndex].toLowerCase(),
      //   year: year,
      // };
      // const monthAttendance = await AttendanceController.getMonthAttendance(
      //   dataMonthOne
      // );

      // if(monthAttendance){
      //   dispatch(setActiveUserMonthAttendance(monthAttendance));
      // }

      if (newresponse) {
        dispatch(setEmployeeAppeal(newresponse));
      }

      setTimeout(() => {
        setLoader(false);
        setisOpen(false);
        handleAlert(successMsg, "success");
      }, 500);
    } else {
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        setLoader(false);
        setisOpen(false);
      }, 500);
    }

    handleCloseMenu();

    // dispatch(deleteEmployeeAppeal(_id));

    // if (event) {
    //   event.stopPropagation();
    // }
    // setselectedStatus(status);
    // setisOpen(true);
    // handleCloseMenu();
  }

  function onEditLeave() {
    // dispatch(updateSelectedLeaveDate(props.data));
    // props.setselectedStatus(CommonString.edit_leave.toUpperCase());
    // setisOpen(true);
    // setselectedStatus(status);
    // handleCloseMenu();
  }

  async function decline() {
    setLoader(true);
    const data = {
      _id: props.data._id,
      status: "DECLINED",
    };

    let res = await appealController.updateEmployeeAppeal(data);
    const successMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>appeal rejected sucessfully</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );
    const errorMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>error</LeaveStatusTitle>
        <LeaveStatusMsg>please try after a while</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );
    if (res) {
      const employeeId = props.data.recruiter._id;

      let data;

      // isAdmin vala logic
      if (isAdmin) {
        data = await appealController.getEmployeeAppealData();
      } else {
        data = await appealController.getEmployeeAppealDataById(employeeId);
      }

      dispatch(setEmployeeAppeal(data));

      const pendingNewRespData = data.filter((dat) => dat.status === "PENDING");
      dispatch(setEmployeePendingAppeal(pendingNewRespData));
      setTimeout(() => {
        setLoader(false);
        setisOpen(false);
        handleAlert(successMsg, "success");
      }, 500);
    } else {
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        setLoader(false);
        setisOpen(false);
      }, 500);
    }
  }

  function openAprroveModel() {
    setisOpen(true);
    setselectedStatus(CommonString.approved.toUpperCase());
  }

  function openRejectModel() {
    setisOpen(true);
    setselectedStatus(CommonString.rejected.toUpperCase());
  }

  function openDeletModel(){
    setAnchorEl(null);
    setisOpen(true);
    setselectedStatus(CommonString.delete.toUpperCase())
  }

  async function accept() {
    setLoader(true);
    const data = {
      _id: props.data._id,
      status: "APPROVED",
    };

    let res = await appealController.updateEmployeeAppeal(data);
    const successMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>Appeal Accepted sucessfully</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );
    const errorMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>error</LeaveStatusTitle>
        <LeaveStatusMsg>please try after a while</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );
    if (res) {
      const employeeId = props.data.recruiter._id;

      let data;

      // isAdmin vala logic
      if (isAdmin) {
        data = await appealController.getEmployeeAppealData();
      } else {
        data = await appealController.getEmployeeAppealDataById(employeeId);
      }

      const year = new Date().getFullYear().toString();
      const dataMonthOne = {
        user_id: props.data.user_id,
        month: Month[props.currentMonthIndex].toLowerCase(),
        year: year,
      };
      const monthAttendance = await attendanceController.getMonthAttendance(
        dataMonthOne
      );

      dispatch(setActiveUserMonthAttendance(monthAttendance));

      dispatch(setEmployeeAppeal(data));
      const pendingNewRespData = data.filter((dat) => dat.status === "PENDING");
      dispatch(setEmployeePendingAppeal(pendingNewRespData));

      setTimeout(() => {
        setLoader(false);
        setisOpen(false);
        handleAlert(successMsg, "success");
      }, 500);
    } else {
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        setLoader(false);
        setisOpen(false);
      }, 500);
    }
  }

  function getButtons() {
    // if (!ShowButton) return;
    // if (!employee.employee_acess.leave_details) return "";
    // if (moment(props.data.leave.from_date).diff(moment()) < 0) return "";
    if (props.data.status === CommonString.pending.toUpperCase()) {
      return (
        <Div className="grid grid-cols-2 gap-x-2 w-full">
          <Div className="grid col-span-1">
            <ButtonCustomV2
              onClick={(event) => {
                openRejectModel(true);
              }}
              variant="outlined"
              customBGColor={CommonColor.transparent}
              hoverBG={CommonColor.red + "10"}
              customTextColor={CommonColor.red}
              customOutlineColor={CommonColor.red}
              startIcon={
                <Icon icon="akar-icons:cross" height={FontSizes.SmallText} />
              }
              buttonText="Decline"
            />
          </Div>
       
          <Div className="grid col-span-1">
            <ButtonCustomV2
              onClick={(event) => {
                openAprroveModel(true);
              }}
              variant="outlined"
              customBGColor={CommonColor.transparent}
              hoverBG={CommonColor.green + "10"}
              customTextColor={CommonColor.green}
              customOutlineColor={CommonColor.green}
              startIcon={
                <Icon icon="mdi:tick" fontSize={FontSizes.SmallText} />
              }
              buttonText="Approve"
            />
          </Div>
        </Div>
      );
    }
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  async function onChangeStatus(status) {
    setLoader(true);
    const sendData = {
      id: activeEmployeeData.leave.id,
      approved_by_user_id: activeEmployeeData.user._id,
      status: status,
    };
    const resp = await leaveController.upadateLeaveStatus(sendData);
    if (resp === true) {
      const result = leaveList.filter(
        (leave) => leave.leave.id !== activeEmployeeData.leave.id
      );

      const data = await updateLeaveStatus(leaveController);
      const leaveInfoEmployee = await leaveController.getEmployeeLeaveData(
        activeEmployeeData.user._id
      );

      const pendingNewRespData = getPendingLeaves(leaveInfoEmployee);

      const total_leave = parseInt(activeEmployeeData.leave.total_leave);
      const leaveCredits = {
        total_leave: leave.total_leave_number_info,
        used_leave:
          status === CommonString.approved.toUpperCase()
            ? leave.used_leave_number_info + total_leave
            : leave.used_leave_number_info - total_leave,
      };
      setisOpen(false);

      dispatch(setEmployeeLeave(result));

      dispatch(updateActiveUserLeaveListData(leaveInfoEmployee));
      dispatch(updateActiveUserLeaveListDataProfile(leaveInfoEmployee));
      dispatch(updatePendingEmployeeLeaveData(data.pendingEmployeesLeave));
      dispatch(setLeaveHistoryData(data.leaveHistory));
      dispatch(updateEmployeeLeaveDatas(leaveInfoEmployee));
      dispatch(updatePendingEmployeeLeaveDatas(pendingNewRespData));
      dispatch(updateLeaveNumbersLeaveInfo(leaveCredits));
    }
    setLoader(false);
  }
  function getLocalChildren() {
    if (selectedStatus === CommonString.approved.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={"APPROVE"}
          yesButtonName="Approve"
          noButtuonName="Cancel"
          onClickYesButton={() => {
            accept();
            // onChangeStatus(CommonString.approved.toUpperCase())
          }}
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }

    if (selectedStatus === CommonString.rejected.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={"DECLINED"}
          yesButtonName="Declined"
          onClickYesButton={
            () => decline()
            // onChangeStatus(CommonString.rejected.toUpperCase())
          }
          noButtuonName="Cancel"
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (selectedStatus === CommonString.edit.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationReject)}
          yesButtonName="Approve"
          onClickYesButton={() =>
            onChangeStatus(CommonString.approved.toUpperCase())
          }
          onClicknoButton={() =>
            onChangeStatus(CommonString.rejected.toUpperCase())
          }
          noButtuonName="Declined"
          isCancelButton={true}
          onClickCancelButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }

    if (selectedStatus === CommonString.delete.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={"You want to delete appeal ?"}
          yesButtonName="Delete"
          onClickYesButton={() => onClickFooterButton(props.data._id)}
          noButtuonName="Cancel"
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (selectedStatus === CommonString.edit_leave.toUpperCase()) {
      return (
        <ApplyLeave
          IsPopupOpen={isOpen}
          isEdit={true}
          onHandleClose={() => setisOpen(false)}
        />
      );
    }
  }
  function getTitle(title) {
    return   props.data.user.name + "want to delete appeal";
  }
  function IsDateComing() {
    return props.data.leave.total_leave > 1;
  }
  function getEmployeeHeader() {
    return props.data.type;
  }
  function getProfileImageUrl() {
    if (props.data.recruiter?.profile_image_url !== null) {
      return ProfileBaseUrl + props.data.recruiter.profile_image_url;
    }
    return "";
  }
  return (
    <React.Fragment>
      <ModalPopup open={isOpen} popupSize="xs" issmallScreen={true}>
        {getLocalChildren()}
      </ModalPopup>
      <React.Fragment>
        <EmployeeAppealInfoContainer
          onClick={() => props.onClick(props.data, props.index)}
          noclick={props.noClick}
          length={props.length}
          index={props.index}
          // onContextMenu={
          //   props.data.leave.status === CommonString.pending && props.isEdit
          //     ? (event) => handleClick(event)
          //     : () => {}
          // }
        >
          {/* <EmployeeLeaveDataNavbarContainer */}
          {/* isdisplay={1} */}
          {/* > */}
          <EmployeeLeaveDataNavbar>
            <EmployeeNameContainer>
              <ImageContainerLeaveCard>
                <ImageAvatar
                  src={getProfileImageUrl()}
                  alt=""
                  defaultSrc={CommonImages.NoUser}
                />
              </ImageContainerLeaveCard>
              <EmployeeName
                // onClick={() => props.onNameClick && props.onNameClick()}
                onClick={() => {}}
              >
                {props.data.recruiter.name}
              </EmployeeName>
              {(props.data.status === "PENDING" || isAdmin) && 
                (<>
                 <IconNavbar
                    icon="mi:options-horizontal"
                    onClick={handleClick}
                  />
                  <MenuOptions
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <ButtonDeleteLeave
                      variant="text"
                      onClick={() => openDeletModel() }
                      startIcon={
                        <Icon icon="material-symbols:delete-forever-rounded" />
                      }
                    >
                      delete
                    </ButtonDeleteLeave>
                  </MenuOptions>
                  </>)
                
              }
            </EmployeeNameContainer>
            <LeaveStatus
              leaveStatus={props.data.status}
              display={employee.employee_acess.leave_details}
              leaveStatusText={props.data.status}
              screen={props.screen}
            />
          </EmployeeLeaveDataNavbar>
          {/* </EmployeeLeaveDataNavbarContainer> */}
          <EmployeeCardDivider isdisplay={1} mt="1%" mb="1%" />
          <EmployeeLeaveDataContainer>
            {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
            <DurationAndStatus
              display={!employee.employee_acess.leave_details ? 1 : 0}
            >
              <EmployeeLeaveDuaration>
                {getEmployeeHeader()}

                <EmployeeOptionsIconContainer
                  isvisible={props.data.status === CommonString.pending ? 1 : 0}
                >
                  {/* <IconNavbar
                    icon="mi:options-horizontal"
                    onClick={handleClick}
                  /> */}
                  {/* <MenuOptions
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <ButtonEditLeave
                      disableRipple
                      variant="outlined"
                      onClick={() =>
                        onClickFooterButton(
                           props.data._id
                        )
                      }
                      startIcon={
                        <Icon icon="material-symbols:delete-forever-rounded" />
                      }
                    >
                      delete
                    </ButtonEditLeave>
                    <ButtonEditLeave
                      disableRipple
                      variant="outlined"
                      onClick={() =>
                        onEditLeave(
                          undefined,
                          CommonString.edit_leave.toUpperCase()
                        )
                      }
                      startIcon={<Icon icon="material-symbols:edit" />}
                    >
                      edit
                    </ButtonEditLeave>
                  </MenuOptions> */}
                </EmployeeOptionsIconContainer>
              </EmployeeLeaveDuaration>
            </DurationAndStatus>

            {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}

            {/* <EmployeeCardDivider
              isdisplay={!employee.employee_acess.leave_details ? 1 : 0}
              mt="1%"
            /> */}

            <EmployeeTimeContainer
              mt={props.leaveComponent === CommonString.leaveApplication}
            >
              <EmployeetimingHeader>{"date : "}</EmployeetimingHeader>

              <EmployeeLeaveTime>
                {/* &nbsp;{getDate(props.data.attendance_date)} */}
                {props.data.attendance_date}
              </EmployeeLeaveTime>
            </EmployeeTimeContainer>
            {/* <EmployeeTimeContainerTo
              todate={props.data.leave.to_date}
              isvisible={props.data.leave.total_leave > 1 ? 1 : 0}
            >
              <EmployeetimingHeader>To&nbsp;:&nbsp;</EmployeetimingHeader>
              <EmployeeLeaveTimeAndTypeContainer>
                <EmployeeLeaveTime>
                  {props.data.leave ? getDate(props.data.leave.to_date) : ""}
                </EmployeeLeaveTime>
                {props.data.leave.total_leave === 0.5 ? (
                  <EmployeeLeaveHalfDay>(Half day)</EmployeeLeaveHalfDay>
                ) : (
                  ""
                )}
              </EmployeeLeaveTimeAndTypeContainer>
            </EmployeeTimeContainerTo> */}

            {props.data.reason !== "" &&
              props.data.reason &&
              props.data.reason !== null && (
                <EmployeeLeaveNoteContainer>
                  <EmployeeLeaveNoteHeading>
                    Reason&nbsp;:&nbsp;
                  </EmployeeLeaveNoteHeading>
                  <EmployeeLeaveNote
                    ActiveIndex={IsReadMore}
                    index={props.index}
                  >
                    {getReason()}
                  </EmployeeLeaveNote>

                  <ReadMoreOption
                    onClick={(event) => ToggleReadmore(event, props.index)}
                    isdisplay={props.data.reason.length > 100 ? 1 : 0}
                  >
                    {IsReadMore ? " read less" : "...read more"}
                  </ReadMoreOption>
                </EmployeeLeaveNoteContainer>
              )}

            {/* <EmployeeCardDivider
              isdisplay={
                employee.employee_acess.leave_details &&
                moment(props.data.leave.from_date).diff(moment()) >= 0
              }
            />  */}
            {/* <EmployeeLeaveFooter
              leavestatus={props.data.leave ? props.data.leave.status : ""}
              isdisplay={1}
            >
              {getButtons()}
            </EmployeeLeaveFooter> */}
            {isAdmin && (
              <EmployeeLeaveFooter
                leavestatus={props.data ? props.data.status : ""}
                isdisplay={1}
              >
                {getButtons()}
              </EmployeeLeaveFooter>
            )}
          </EmployeeLeaveDataContainer>
        </EmployeeAppealInfoContainer>
      </React.Fragment>
    </React.Fragment>
  );
}

/*

*/
