import React from 'react'
import { CheckboxAccessEditor, CheckboxIcon } from '../../../style/style.access.mgmt.style'
import CommonColor from '../../../utils/common.colors';

export default function AccessCheckbox(props) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <CheckboxAccessEditor
    {...label}
    {...props}
    icon={
      <CheckboxIcon
        icon="ph:circle-dashed-thin"
        width={25}
        height={25}
        color={CommonColor.text_gray}
      />
    }
    checkedIcon={
      <CheckboxIcon
        icon="mdi:success"
        iconType="success"
        width={25}
        height={25}
        color={CommonColor.green}
      />
    }
    

  />
  )
}
