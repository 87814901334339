import React from "react";
import { LeaveDecisionMakingButton } from "../../../style/common.style";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { Icon } from "@iconify/react";

export default function LeaveDecisionButton(props) {
  return (
    <LeaveDecisionMakingButton
      onClick={props.onClick}
      buttontype={props.children}
      variant="outlined"
      startIcon={
        props.children === "decline" ? (
          <CloseIcon />
        ) : props.children === "approve" ? (
          <CheckIcon />
        ) : (
          <Icon icon="carbon:edit" />
        )
      }
    >
      {props.children}
    </LeaveDecisionMakingButton>
  );
}
