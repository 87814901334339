import React, { useState } from "react";
import AccessManagementTable from "../../component/commonComponents/AccessManagement/AccessManagementTable";
import {
  AccessContainer,
  AccessMgmtTableWrapper,
} from "../../style/style.access.mgmt.style";

export default function ManageAccess(props) {
  const [isEditAccess, setIsEditAccess] = useState(-1);
  const [editData, setEditData] = useState({});

  function IsEditAccessSetter(state) {
    setIsEditAccess(state);
  }
  function EditDataSetter(state) {
    setEditData(state);
  }
  return (
    <React.Fragment>
      <AccessContainer>
        {/* <AccessManagementNavbarWrapper>
          <AccessManagementNavbar
            isEditAccess={isEditAccess}
            IsEditAccessSetter={(data) => IsEditAccessSetter(data)}
          />
        </AccessManagementNavbarWrapper> */}
        <AccessMgmtTableWrapper>
          <AccessManagementTable
            isEditAccess={isEditAccess}
            IsEditAccessSetter={(data) => IsEditAccessSetter(data)}
            EditDataSetter={EditDataSetter}
            editData={editData}
            getAccessScreenData={props.getAccessScreenData}
          />
        </AccessMgmtTableWrapper>
      </AccessContainer>
    </React.Fragment>
  );
}
