import { Fragment } from "react";

import ImageAvatar from "../../../../componentsv2/Images/ImageAvatar";
import { HtmlTooltip, StyledBadge } from "../../../../style/common.style";
import {
  DailyLogListContainer,
  EmployeeInfoContainer,
  ScrollContainer,
} from "../../../../style/dashboard.style";
import { NameContainer } from "../../../../style/leave.style";
import CommonColor from "../../../../utils/common.colors";
import { CommonImages } from "../../../../utils/common.images";
import { NoProfilePhoto, ProfileBaseUrl } from "../../../../utils/constant";
import { useWindowSize } from "../../../commonComponents/hooks/getWindowDimension";
import EmployeeLeaveStatusDetails from "./EmployeeLeaveStatusDetails";

// import { styled } from "@mui/styles";

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     backgroundColor: "#44b700",
//     color: "#44b700",
//     boxShadow: `0 0 0 2px red`,
//     "&::after": {
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//       borderRadius: "50%",
//       animation: "ripple 1.2s infinite ease-in-out",
//       border: "1px solid currentColor",
//       content: '""',
//     },
//   },
//   "@keyframes ripple": {
//     "0%": {
//       transform: "scale(.8)",
//       opacity: 1,
//     },
//     "100%": {
//       transform: "scale(2.4)",
//       opacity: 0,
//     },
//   },
// }));

const EmployeeList = (props) => {
  const windowWidth = useWindowSize().width;
  const isDragging = false;
  function EmployeeDetailPopupOpener(employee) {
    if (isDragging) return;
    props.ScreenOpener(employee);
  }

  function getUrl(employee) {
    if (employee.profile_image_url === null) return NoProfilePhoto;
    if (props.isTypingShow)
      return ProfileBaseUrl + employee.user.profile_image_url;
    return ProfileBaseUrl + employee.profile_image_url;
  }
  function scrollLeft(event) {
    if (event.deltaY === 0) return;

    const ele = event.currentTarget;
    ele.scrollTo({
      top: 0,
      left: ele.scrollLeft + event.deltaY,
      behavior: "smooth",
    });
  }
  return (
    <ScrollContainer onWheel={(event) => scrollLeft(event)}>
      {props.presentList.map((attendanceDetail, index) => {
        if (!attendanceDetail) return;
        if (index > 15) return;
        return (
          <HtmlTooltip
            key={index}
            title={
              <Fragment>
                <EmployeeLeaveStatusDetails data={attendanceDetail} />
              </Fragment>
            }
            arrow={true}
            placement="right"
            enterDelay={windowWidth < 600 ? 500 : 0}
            leaveDelay={windowWidth < 600 ? 200 : 0}
          >
            <EmployeeInfoContainer
              onClick={() => EmployeeDetailPopupOpener(attendanceDetail.user)}
              ismb={!props.shownBirthday}
              cursor="initial"
              delay={index}
              length={props.presentList.length}
            >
              <StyledBadge
                badgecolor={
                  attendanceDetail.is_late === "1"
                    ? CommonColor.secondary_color
                    : CommonColor.green
                }
                color="secondary"
                overlap="circular"
                badgeContent=" "
                variant="dot"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <DailyLogListContainer>
                  <ImageAvatar
                    src={getUrl(attendanceDetail.recruiter)}
                    alt=""
                    defaultSrc={CommonImages.NoUser}
                  />
                </DailyLogListContainer>
              </StyledBadge>
              <NameContainer style={{ fontSize: 10 }}>
                {attendanceDetail.recruiter.name.split(" ")[0]}
              </NameContainer>
            </EmployeeInfoContainer>
          </HtmlTooltip>
        );
      })}
    </ScrollContainer>
  );
};

export default EmployeeList;
