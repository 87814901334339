import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageAvatar from "../../componentsv2/Images/ImageAvatar";
import { Div } from "../../style/common.style";
import {
  BirthdayDate,
  CelebrationCardContainer,
  CelebrationContainer,
  CelebrationMonthContainer,
  NoDataContainerCelebration,
  UserName,
} from "../../style/holiday.style";
import { CommonImages } from "../../utils/common.images";
import { CommonString } from "../../utils/common.string";
import { ProfileBaseUrl } from "../../utils/constant";
import { formatDisplayBirthDate, monthFormattedDate } from "../../utils/utils";
import NoData from "../commonComponents/NoData/NoData";
import CelebrationNav from "./CelebrationNav";

export default function Celebration(props) {
  const employee = useSelector((state) => state.employee);
  const [sortedEmployeeList, setSortedEmployeeList] = useState();
  useEffect(() => {
    sortEmployeeListByBirthdayAndMonth();
  }, [employee.employee_list]);

  function getProfilePhoto(data) {
    return ProfileBaseUrl + data.profile_image_url;
  }
  const sortEmployeeListByBirthdayAndMonth = () => {
    const employeeList = [...employee.employee_list];
    const sortedList = sortEmployeeListByBirthdayMonth(employeeList);
    const groupedList = groupByMonth(sortedList);
    const sortedGroupedList = sortByDate(groupedList);
    setSortedEmployeeList(sortedGroupedList);
    return sortedGroupedList;
  };
  const sortByDate = (employeeList) => {
    return Object.entries(employeeList)
      .sort(([, a], [, b]) => moment(a[0].birthday) - moment(b[0].birthday))
      .reduce((result, [month, employees]) => {
        result[month] = employees.sort(
          (a, b) => moment(a.birthday).date() - moment(b.birthday).date()
        );
        return result;
      }, {});
  };

  const groupByMonth = (employeeList) => {
    const groupedList = employeeList.reduce((result, currentValue) => {
      const month = moment(currentValue.birthday).month();

      if (!result[month]) {
        result[month] = [];
      }
      result[month].push(currentValue);
      return result;
    }, {});
    return groupedList;
  };

  const sortEmployeeListByBirthdayMonth = (employeeList) => {
    const sortedList = [...employeeList].sort((a, b) => {
      const aMonth = moment(a.birthday).month();
      const bMonth = moment(b.birthday).month();
      return aMonth - bMonth;
    });
    return sortedList;
  };

  return (
    <React.Fragment>
      <CelebrationContainer isvisible={props.isvisible ? 1 : 0}>
        {sortedEmployeeList ? (
          Object.keys(sortedEmployeeList).map((month, indexO, array) => {
            return (
              <React.Fragment key={indexO}>
                <CelebrationNav
                  subtitle={monthFormattedDate(
                    moment(sortedEmployeeList[month][0].birthday)
                  )}
                  title="Birthday(s) celebrated in"
                />
                <CelebrationMonthContainer>
                  {sortedEmployeeList[month].map((employeeIn) => {
                    return (
                      <CelebrationCardContainer
                        key={employeeIn}
                        delay={indexO / employee.employee_list.length}
                      >
                        <Div className="h-[60px] w-[60px]">
                          <ImageAvatar
                            src={getProfilePhoto(employeeIn)}
                            alt=""
                            defaultSrc={CommonImages.NoUser}
                            border={
                              employeeIn.profile_image_url
                                ? ""
                                : "1px solid black"
                            }
                          />
                        </Div>
                        <UserName>{employeeIn.name}</UserName>
                        <BirthdayDate>
                          {formatDisplayBirthDate(employeeIn.birthday)}
                        </BirthdayDate>
                      </CelebrationCardContainer>
                    );
                  })}
                </CelebrationMonthContainer>
              </React.Fragment>
            );
          })
        ) : (
          <NoDataContainerCelebration>
            <NoData noDataText={CommonString.NoBirthday} />
          </NoDataContainerCelebration>
        )}
      </CelebrationContainer>
    </React.Fragment>
  );
}
