import { EndPoint } from "../../utils/endpoints";
import HolidayUtils from "../APICallServices/APIUtils";

export default class ManagerProvider {
  constructor() {
    this.holidayUtils = new HolidayUtils();
  }
  getHolidayList = () => {
    return this.holidayUtils.init().get("/tracker/get-holiday");
  };
  getPrexelPhotosList = () => {
    return this.holidayUtils.init().get(EndPoint.getPrexelPhotosList);
  };
}
