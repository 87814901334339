import { Icon } from "@iconify/react";
import { InputAdornment } from "@mui/material";
import React from "react";
import { CalendarInput } from "../../style/profile.style";
import CommonColor from "../../utils/common.colors";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export default function CustomInput(props) {
  return (
    <React.Fragment>
      <CalendarInput
        hiddenLabel
        onFocus={props.openCalendar}
        value={props.value}
        onChange={props.handleValueChange}
        placeholder={props.placeholder}
        className="ring-indigo-900"
        size="small"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded"
                color={CommonColor.primary_color}
              />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start">
              <CalendarTodayIcon
                // color={CommonColor.primary_color}
                style={{ color: CommonColor.primary_color, fontSize: "15px" }}
                // width={15}
              />
            </InputAdornment>
          ),
        }}
      />
    </React.Fragment>
  );
}
