import { ApiVersionV2 } from "./constant";

const EndPoint = {
  getUpcomingBdayApi2: ApiVersionV2 + "user/upcoming-birthday",
  getUpcomingBdayApi: "recruiter/get-upcoming-birthday",
  synergyLeagueTeams: ApiVersionV2 + "sl_teams",
  synergyLeagueSports: ApiVersionV2 + "sl_point_table/add",
  synergyLeagueSportsUpdate: ApiVersionV2 + "sl_sports/update_sport",
  synergyLeagueSportsDelete: ApiVersionV2 + "sl_sports/delete",
  synergyLeagueMatchDelete: ApiVersionV2 + "sl_point_table/delete",
  deleteTeamMember: ApiVersionV2 + "sl_team_members/delete",
  updateTeamMember: ApiVersionV2 + "sl_team_members/update_sl_team_member",
  uploadImages: "upload/images",
  synergyLeagueUpcomingMatches: ApiVersionV2 + "sl_match/upcoming-matches",
  synergyLeagueRecentMatches: ApiVersionV2 + "sl_match/recent-matches",
  synergyLeaguePointsTable: ApiVersionV2 + "sl_point_table/get",
  synergyLeagueMatches: ApiVersionV2 + "sl_point_table/add",
  synergyLeagueMatchesUpdate: ApiVersionV2 + "sl_point_table/update",
  synergyLeagueSubTeams: ApiVersionV2 + "sl_sport_team",
  getInitialEvents: ApiVersionV2 + "events/get-events",
  addEvent: ApiVersionV2 + "events",
  updateEvents: ApiVersionV2 + "events/update-events",
  getPrexelPhotosList: "gallery/get-photos",
  getPolicy: "/v2/policy/get",
  uploadPolicy: "upload-policy",
  addPolicy: "/v2/policy/add",
  deletePolicy: "/v2/policy/delete",
  editPolicy : "/v2/policy/update"
};

export { EndPoint };
