import React from "react";

import {
  EmployeeScreenShimmerCard,
  EmployeeScreenShimmerBlock,
} from "../../../style/loader.style";
export default function EmployeeScreenShimmer(props) {
  return (
    <React.Fragment>
      {[...Array(16)].map((index) => (
        <EmployeeScreenShimmerBlock key={index}>
          <EmployeeScreenShimmerCard height={130} width={170} rounded />
        </EmployeeScreenShimmerBlock>
      ))}
    </React.Fragment>
  );
}
