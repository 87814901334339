import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DateDayContainer,
  DateNumberContainer,
  HolidayCard,
  HolidayContainer,
  HolidayEventContainer,
  HolidayMonthName,
  HolidayStatus,
  LabelContainer,
  MonthContainer,
  NameContainer,
  NameText,
  OptionalText,
} from "../../style/holiday.style";
import { Month } from "../../utils/constant";

import { FlexContainer } from "../../style/asset.style";
import { TypoGraphy } from "../../style/common.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import PageRefreshLoader from "../Loader/PageRefreshLoader";
import Trapezoid from "../../componentsv2/common_components/ShapesAndTags/Trapezoid";
import { getJustDate } from "../../utils/utils";

export default function Holiday(props) {
  const holidayStore = useSelector((state) => state.holiday);
  const [nearestDateIndex, setNearestDateIndex] = useState([0, 0]);
  const [nearestDate, setNearestDate] = useState(
    holidayStore.holiday_list_by_month[0][0].date
  );

  useEffect(() => {
    let bar = new Promise((resolve, reject) => {
      let index = 0;
      let indexM = 0;
      for (let months of holidayStore.holiday_list_by_month) {
        indexM++;
        index = 0;
        for (let holiday of months) {
          index++;
          if (
            parseInt(moment(holiday.date).format("MMDD")) >=
            parseInt(moment().format("MMDD"))
          ) {
            resolve({
              date: holiday.date,
              month_index: indexM,
              holiday_index: index,
            });
          }
        }
      }
    });
    bar.then((result) => {
      setNearestDateIndex([result.month_index, result.holiday_index]);
      setNearestDate(result.date);
    });
  }, [holidayStore.holiday_list_by_month]);

  function getDate(date) {
    const newDateFormat = moment(date);
    let tempDate = newDateFormat.date();
    if (tempDate < 10) {
      tempDate = "0" + tempDate;
    }
    return tempDate;
  }
  function getDay(formattedDate) {
    const momentDate = moment(formattedDate);
    return momentDate.format("dddd");
  }

  function getMonth(monthIndex) {
    return Month[monthIndex];
  }
  return (
    <React.Fragment>
      <HolidayContainer isvisible={props.isvisible ? 1 : 0}>
        {holidayStore.holiday_list_by_month.length === 0 ? (
          <PageRefreshLoader />
        ) : (
          ""
        )}
        {holidayStore.holiday_list_by_month.length !== 0
          ? holidayStore.holiday_list_by_month.map((Month, index) => {
              if (!Month[0]) return;
              return (
                <MonthContainer>
                  <HolidayMonthName>
                    <FlexContainer jc="center">
                      <TypoGraphy
                        fontSize={FontSizes.Subtitle}
                        weight="700"
                        color={CommonColor.primary_color}
                        // style={{
                        //   mixBlendMode: "difference",
                        //   filter: "invert(1)",
                        // }}
                      >
                        {getMonth(index)},&nbsp;
                      </TypoGraphy>
                      <TypoGraphy
                        fontSize={FontSizes.Subtitle}
                        weight="700"
                        color={CommonColor.primary_color}
                        // style={{
                        //   mixBlendMode: "difference",
                        //   filter: "invert(1)",
                        // }}
                      >
                        {moment().format("yyyy")}
                      </TypoGraphy>
                    </FlexContainer>
                  </HolidayMonthName>
                  <HolidayEventContainer>
                    {Month.map((holiday, indexO) => {
                      return (
                        <HolidayCard
                          key={index + indexO}
                          isml={indexO !== 0}
                          delay={indexO / holidayStore.holiday_list.length}
                        >
                          {parseInt(moment(holiday.date).format("MMDD")) <
                          parseInt(moment(nearestDate).format("MMDD")) ? (
                            <LabelContainer>
                              <Trapezoid
                                color={CommonColor.primary_color_opacity_05}
                                length={120}
                                width={10}
                                text={"bygone"}
                                fontColor={CommonColor.text_gray}
                              />
                            </LabelContainer>
                          ) : (
                            ""
                          )}

                          {parseInt(moment(holiday.date).format("MM")) ===
                            parseInt(moment(nearestDate).format("MM")) &&
                          indexO === nearestDateIndex[1] - 1 ? (
                            <LabelContainer>
                              <Trapezoid
                                color={CommonColor.secondary_color}
                                length={120}
                                width={10}
                                text={
                                  getDate(holiday.date) ===
                                  getDate(moment().toISOString)
                                    ? "Today"
                                    : "Upcoming"
                                }
                                fontColor={CommonColor.white}
                              />
                            </LabelContainer>
                          ) : (
                            ""
                          )}

                          <DateNumberContainer>
                            {moment(
                              getJustDate(holiday.date),
                              "Do MMM 'YY"
                            ).format("DD")}
                          </DateNumberContainer>
                          <DateDayContainer>
                            {getDay(holiday.date)}
                          </DateDayContainer>
                          <TypoGraphy
                            fontSizes={FontSizes.SubPara}
                            weight="600"
                            color={CommonColor.primary_color}
                            textTransform="capitalize"
                          ></TypoGraphy>
                          <OptionalText
                            isvisible={holiday.is_optional === "1" ? 1 : 0}
                            label="(optional)"
                            variant="outlined"
                          />
                          <NameContainer>
                            <NameText>{holiday.name}</NameText>
                          </NameContainer>
                          {/* <HolidayStatus>
                            {holiday.shift.toLowerCase()}
                          </HolidayStatus> */}
                        </HolidayCard>
                      );
                    })}
                  </HolidayEventContainer>
                </MonthContainer>
              );
            })
          : ""}
      </HolidayContainer>
    </React.Fragment>
  );
}
