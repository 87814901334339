import { Grid } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationMenu from "../../component/Popup/ConfirmationMenu";
import ModalPopup from "../../component/Popup/ModalPopup";
import LeaveController from "../../controller/LeaveController";
import {
  updateActiveUserLeaveListDataProfile,
  updateLeaveNumbersLeaveInfo,
} from "../../reducer/leavereduxstore";
import { VerticalScrollableArea } from "../../style/common.style";
import {
  LeaveStatusContainer,
  LeaveStatusMsg,
  LeaveStatusTitle,
} from "../../style/form.style";
import {
  LeaveCreditTable,
  LeaveCreditTableBody,
  LeaveCreditTableElement,
  LeaveCreditTableElementValue,
  LeaveCreditTableHeader,
  MonthTableContainer,
  ProfileLeaveInfoContainer,
} from "../../style/profile.style";
import CommonColor from "../../utils/common.colors";
import { CommonString } from "../../utils/common.string";
import { Month } from "../../utils/constant";
import LeaveCredit from "./LeaveCredit";

export default function ProfileLeaveInfo(props) {
  const leaveList = useSelector(
    (state) => state.leave.active_user_leave_list_profile
  );
  const leave = useSelector((state) => state.leave);
  const [leaveCreditBalance, setleaveCreditBalance] = useState([]);
  const [leaveCreditMapping, setLeaveCreditMapping] = useState([]);
  const [activeMonth, setActiveMonth] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoad, setisLoad] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const employee = useSelector((state) => state.employee);

  let activeLeave;

  const leaveController = new LeaveController();
  const dispatch = useDispatch();

  useEffect(() => {
    activeLeaveIdUpdater();
    createLeaveMapping();
  }, [leaveList]);

  useEffect(() => {
    getLeaveList();
  }, []);

  async function getLeaveList() {
    const leaveData = await leaveController.getEmployeeLeaveData(
      props.employee._id
    );
    dispatch(updateActiveUserLeaveListDataProfile(leaveData));
  }

  function createLeaveMapping() {
    const data = moment.months().map((element, index) => {
      if (moment().month() < index) {
        return { name: element, data: "NA" };
      }

      return { name: element, data: "ADD" };
    });
    _.forEach(leaveCreditBalance, (leaveCreditBalanceValue) => {
      data[moment(leaveCreditBalanceValue._created_at).month()].data = 1.25;
    });
    setLeaveCreditMapping(data);
  }

  async function activeLeaveIdUpdater() {
    const sendData = {
      recruiter_id: props.employee._id,
      month: moment().month() + 1,
      year: moment().year(),
    };
    const leaveNumbers = await leaveController.getLeaveNumbers(sendData);

    setleaveCreditBalance(leaveNumbers.data);
    dispatch(
      updateLeaveNumbersLeaveInfo({
        total_leave: leaveNumbers.total_leave,
        used_leave: leaveNumbers.used_leave,
      })
    );
  }

  function PopupOpener(monthIndex) {
    setOpenPopup(true);
    setActiveMonth(monthIndex);
  }
  function getModalChildren() {
    let message;
    const name = props.employee.name;
    const month = Month[activeMonth];
    message =
      CommonString.ConfirmDeleteLeaveCredit +
      " " +
      name +
      " in the month of " +
      month +
      "?";
    return (
      <ConfirmationMenu
        isShow={isLoad}
        title={message}
        onClickYesButton={() => onDeleteLeaveCredit()}
        yesButtonName={CommonString.yes}
        noButtuonName={CommonString.no}
        onClicknoButton={() => setOpenPopup(false)}
        color={CommonColor.primary_color}
      />
    );
  }

  async function onDeleteLeaveCredit() {
    setisLoad(true);
    const activeLeaveCredit = leaveCreditBalance.filter((element) => {
      return moment(element._created_at).month() === activeMonth;
    });

    if (activeLeaveCredit.length <= 0) {
      return;
    }
    const sendData = {
      id: activeLeaveCredit[0].id,
    };
    const handleAlert = (msg, variant) => {
      enqueueSnackbar(msg, { variant });
    };
    const res = await leaveController.deleteLeaveCredit(sendData);
    if (res) {
      const sucessMsg = (
        <LeaveStatusContainer>
          <LeaveStatusTitle>success</LeaveStatusTitle>
          <LeaveStatusMsg>leave applied sucessfully</LeaveStatusMsg>
        </LeaveStatusContainer>
      );
      handleAlert(sucessMsg, "success");
      setOpenPopup(false);
      setisLoad(false);
      activeLeaveIdUpdater();
      return;
    }
    const errorMsg = (
      <LeaveStatusContainer>
        <LeaveStatusTitle>error</LeaveStatusTitle>
        <LeaveStatusMsg>please try again later</LeaveStatusMsg>
      </LeaveStatusContainer>
    );

    handleAlert(errorMsg, "error");
    setOpenPopup(false);
    setisLoad(false);
    setisLoad(false);
  }

  return (
    <React.Fragment>
      <ModalPopup open={openPopup}>{getModalChildren()}</ModalPopup>
      <ProfileLeaveInfoContainer>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item sm={12} md={12} sx={{ height: "100%" }}>
            <VerticalScrollableArea height="intial" unit="">
              {/* <Grid
                item
                xs={12}
                sx={{
                  height: "initial !important",
                  width: { md: "100%", xs: "100%" },
                }}
              >
                <LeaveBalanceContainerProfile>
                  <LeaveBalanceGraphProfile
                    used_leave={leave.used_leave_number_info}
                    total_leave={leave.total_leave_number_info}
                  />
                </LeaveBalanceContainerProfile>
              </Grid> */}
              {employee.employee_acess.leave_details ? (
                <Grid item xs={12}>
                  <MonthTableContainer>
                    <LeaveCreditTable>
                      <LeaveCreditTableHeader>
                        <LeaveCreditTableElement>Month</LeaveCreditTableElement>
                        <LeaveCreditTableElementValue>
                          Leave Balance
                        </LeaveCreditTableElementValue>
                      </LeaveCreditTableHeader>
                      {leaveCreditMapping.map((element, index) => {
                        return (
                          <LeaveCreditTableBody key={index}>
                            <LeaveCreditTableElement>
                              {element.name}
                            </LeaveCreditTableElement>
                            <LeaveCreditTableElementValue>
                              <LeaveCredit
                                element={element}
                                employee={props.employee}
                                index={index}
                                activeLeaveIdUpdater={activeLeaveIdUpdater}
                                OpenPopup={PopupOpener}
                              />
                            </LeaveCreditTableElementValue>
                          </LeaveCreditTableBody>
                        );
                      })}
                    </LeaveCreditTable>
                  </MonthTableContainer>
                </Grid>
              ) : (
                ""
              )}
            </VerticalScrollableArea>
          </Grid>
          {/* <Grid item sm={12} md={6} sx={{ height: "100%" }}>
            <LeaveListContainer>
              <LeaveListInnerContainer>
                {leaveList.map((leave, index) => {
                  return (
                    <LeaveInfoCard
                      data={leave}
                      onClick={() => activeLeaveIdUpdater(leave)}
                      isActive={activeLeave === leave.leave?.id}
                      index={index}
                      ActiveEmployeeIndex={activeLeave}
                    />
                  );
                })}
              </LeaveListInnerContainer>
            </LeaveListContainer>
          </Grid> */}
        </Grid>
      </ProfileLeaveInfoContainer>
    </React.Fragment>
  );
}
