import * as axios from "axios";
import { BaseUrl } from "../../utils/constant";

export default class ApiUtils {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = BaseUrl;
  }
  init = () => {
    let headers = {
      Accept: "application/json",
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({
      baseURL: this.api_url,
      // timeout: 600000,
      headers: headers,
    });
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        // whatever you want to do with the error
        console.error(
          "errror happened while getting data from server status: 503"
        );
        return { status: 503 };
        return [];
      }
    );

    return this.client;
  };
}
