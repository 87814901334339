import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { DateObject } from "react-multi-date-picker";

export const attendanceSlice = createSlice({
  name: "auth",
  initialState: {
    attendance_month: [],
    month_basic: "init",
    next_attendance_month: null,
    next_attendance_month_basic: null,
    active_user_month_attendance: [],
    daily_attendance: null,
    attendance_update_moment: moment().format(),
    shift: [],
    attendance_selected_month: new DateObject(),
  },
  reducers: {
    attendanceMonth: (state, action) => {
      state.attendance_month = action.payload;
    },
    nextAttendanceMonth: (state, action) => {
      state.next_attendance_month = action.payload;
    },
    monthBasicAttendanceDetail: (state, action) => {
      state.month_basic = action.payload;
    },
    nextMonthBasicAttendanceDetail: (state, action) => {
      state.next_attendance_month_basic = action.payload;
    },
    setActiveUserMonthAttendance: (state, action) => {
      state.active_user_month_attendance = action.payload;
    },
    setDailyAttendance: (state, action) => {
      state.daily_attendance = action.payload;
      state.attendance_update_moment = moment().format();
    },
    setShift: (state, action) => {
      state.shift = action.payload;
    },
    attendanceSelectedMonth: (state, action) => {
      state.attendance_selected_month = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  attendanceSelectedMonth,
  setDailyAttendance,
  attendanceMonth,
  nextAttendanceMonth,
  monthBasicAttendanceDetail,
  nextMonthBasicAttendanceDetail,
  setActiveUserMonthAttendance,
  setShift,
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
