import moment from "moment";
import { useState } from "react";
import LeaveController from "../../controller/LeaveController";
import { IconifyIcon } from "../../style/employee.style";
import {
  IconButton,
  IconDeleteLeaveCredit,
  LeaveCreditBalance,
} from "../../style/profile.style";
import CommonColor from "../../utils/common.colors";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { delay } from "../../utils/utils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LoopIcon from "@mui/icons-material/Loop";

export default function LeaveCredit(props) {

  console.log("PROPSSSSSSSSSSSSSSSSSSSSSSSS" , props);
  const [isLoading, setisLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const leaveController = new LeaveController();

  async function onAddLeaveCredit(index) {
    setActiveIndex(index);
    if (isLoading) return;
    setisLoading(true);
    let d = moment()
      .set("month", index)
      .set("date", 5)
      .utcOffset("-0500")
      .format();
    let sendData = {
      date: d,
      user_id: props.employee._id,
    };
    const res = await leaveController.addLeaveCredit(sendData);
    if (res) {
      props.activeLeaveIdUpdater();
    }

    await delay(2000);
    setisLoading(false);
  }
  function onDelete() {
    props.OpenPopup(props.index);
  }

  if (props.element.data === "NA") {
    return <p style={{ color: CommonColor.text_gray }}>To be Updated...</p>;
  }

  if (props.element.data === "ADD") {
    return (
      <IconButton
        variant="outlined"
        onClick={() => onAddLeaveCredit(props.index)}
        startIcon={
          isLoading && props.index == activeIndex ? (
            <LoopIcon style={{ color: CommonColor.primary_color }} />
          ) : (
            <AddCircleOutlineIcon
              style={{ color: CommonColor.primary_color }}
            />
          )
        }
      >
        Add
      </IconButton>
    );
  }
  return (
    <LeaveCreditBalance>
      1.25
      <IconDeleteLeaveCredit onClick={() => onDelete()}>
        <DeleteOutlineIcon
          style={{ color: CommonColor.button_gray, fontSize: 24 }}
        />
      </IconDeleteLeaveCredit>
    </LeaveCreditBalance>
  );
}
