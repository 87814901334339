import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import "dayjs/plugin/timezone";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useSelector } from "react-redux";
import EventCard from "../../screen/EventList/EventCard";
import { FlexContainer } from "../../style/asset.style";
import {
  LeaveCardNoDataContainer,
  NoDataComponentContainer,
} from "../../style/dashboard.style";
import {
  EventCalendarWrapper,
  EventDot,
  EventsListContainer,
  LocalEventCalendarContainer,
  LocalEventListContainer,
} from "../../style/event.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { eventTypes } from "../../utils/constant.array";
import EventBirthday from "../EventBirthday/EventBirthday";
import { debounce } from "../../utils/utils";

export default function EventCalendar(props) {
  const [monthIndex, setMonthIndex] = useState(-1);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const event = useSelector((state) => state.event);

  useEffect(() => {
    if (monthIndex === -1) return;
    if (!isButtonClicked) return;
    debounce(props.handleMonthChange(monthIndex));
  }, [monthIndex]);

  function getEventNum(date) {
    let numEvents = 0;
    for (let i = 0; i < event.all_events.length; i++) {
      if (
        moment
          .tz(new DateObject(date).format("MM/DD/YYYY"), "MM/DD/YYYY", "UTC")
          .format("MM-DD") ===
        moment.tz(event.all_events[i].date, "UTC").format("MM-DD")
      ) {
        ++numEvents;
        break;
      }
    }
    return (
      <FlexContainer jc="center">
        {[...Array(numEvents)].map(() => (
          <EventDot isdisabled={0} />
        ))}
      </FlexContainer>
    );
  }

  return (
    <EventCalendarWrapper>
      <LocalEventCalendarContainer>
        <Calendar
          className="multi-locale-days"
          maxDate={dayjs().endOf("year").format("YYYY-MM-DD HH:mm:ss")}
          mapDays={({ date }) => {
            return {
              children: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "11px",
                  }}
                >
                  <div style={{ textAlign: "center" }}>{date.format("DD")}</div>
                  <div style={{ textAlign: "center" }}>{getEventNum(date)}</div>
                </div>
              ),
            };
          }}
          onMonthChange={(newMonth) => {
            setIsButtonClicked(true);
            setMonthIndex(parseInt(dayjs(newMonth).format("MM")));
            const updateButtonState = setTimeout(() => {
              setIsButtonClicked(false);
            }, 500);
            clearTimeout(updateButtonState);
          }}
          onChange={(day) =>
            props.getDateData(day.toUTC().format("YYYY-MM-DDTHH:MM:ss.SSSZ"))
          }
        />
      </LocalEventCalendarContainer>
      <EventsListContainer>
        {event.daily_events?.map((event, index) => {
          if (event.type === eventTypes[0]) {
            return (
              <LocalEventListContainer key={event}>
                <EventCard index={index} element={event.data} />
              </LocalEventListContainer>
            );
          } else {
            return (
              <LocalEventListContainer key={event}>
                <EventBirthday
                  event={event.data}
                  index={index}
                  eventType={event.type}
                />
              </LocalEventListContainer>
            );
          }
        })}

        {dayjs(props.selectedDate).day() === 0 ||
        dayjs(props.selectedDate).day() === 6 ? (
          <LeaveCardNoDataContainer>
            <NoDataComponentContainer>
              <Icon
                icon="healthicons:happy-outline"
                fontSize={FontSizes.xxl}
                color={CommonColor.secondary_color}
              />
              &nbsp;Its Weekend!
            </NoDataComponentContainer>
          </LeaveCardNoDataContainer>
        ) : (
          ""
        )}

        {event.daily_events.length === 0 &&
        (dayjs(props.selectedDate).day() !== 0 ||
          dayjs(props.selectedDate).day() !== 6) ? (
          <LeaveCardNoDataContainer>
            <NoDataComponentContainer>
              <Icon
                icon="quill:user-sad"
                fontSize={FontSizes.xxl}
                color={CommonColor.secondary_color}
              />
              &nbsp;No events scheduled today
            </NoDataComponentContainer>
          </LeaveCardNoDataContainer>
        ) : (
          ""
        )}
      </EventsListContainer>
    </EventCalendarWrapper>
  );
}
