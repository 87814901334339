import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducer/authreduxstore";
import pagetransitionstoreSlice from "../reducer/pagetransitionstore";
import employeeReducer from "../reducer/employeereduxstore";
import eventReducer from "../reducer/eventreduxstore";
import leaveReducer from "../reducer/leavereduxstore";
import materialReducer from "../reducer/materialreduxstore";
import attendaceReducer from "../reducer/attendancereduxstore";
import dashboardReducer from "../reducer/dashboardreduxstore";
import holidayReducer from "../reducer/holidayreduxstore";
import assetReducer from "../reducer/assetreduxstore";
import synergyLeagueReducer from "../reducer/synergyleaguereduxstore";
import appealReducer from "../reducer/appealreduxstore";
import workFromHomeReducer from "../reducer/workfromhomereduxstore";
import policyReducer from "../reducer/policyreduxstore";
export default configureStore({
  reducer: {
    auth: authReducer,
    pagetransition: pagetransitionstoreSlice,
    employee: employeeReducer,
    event: eventReducer,
    leave: leaveReducer,
    material: materialReducer,
    attendance: attendaceReducer,
    dashboard: dashboardReducer,
    holiday: holidayReducer,
    asset: assetReducer,
    synergyleague: synergyLeagueReducer,
    appeal: appealReducer,
    workfromhome: workFromHomeReducer,
    policy: policyReducer,
  },
});
