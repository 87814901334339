import { createSlice } from "@reduxjs/toolkit";
import { CommonString } from "../utils/common.string";
import {
  Month,
  activePath,
  pageIndexKey,
  pageIndexTitle,
} from "../utils/constant";
const dateToday = new Date();

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    drawerActiveIndex: 0,
    isModalOpen: false,
    isLocalModalOpen: false,
    localModalName: null,
    isLogoutModalOpen: false,
    isNewNotification: true,
    screenIndexList: [],
    isLoadedList: [],
    login_screen: 1,
    is_loggedin: false,
    drawerOpen: false,
    notificationOpen: false,
    employeeDetailVisible: false,
    date: { month: Month[dateToday.getMonth()], year: dateToday.getFullYear() },
    modal_type: null,
    attendance_data: "init",
    is_event_edit_event: false,
    snackbar_alert: null,
    active_drawer_key: CommonString.dashboardScreenKey,
    is_dom_just_loaded: true,
  },
  reducers: {
    setActiveDrawerkey: (state, action) => {
      state.active_drawer_key = action.payload.key;
      state.drawerActiveIndex = action.payload.index;
      // setTimeout(() => {
      localStorage.setItem(activePath, action.payload.path);
      localStorage.setItem(pageIndexKey, action.payload.key);
      localStorage.setItem(pageIndexTitle, action.payload.index);
    },
    setActiveDrawer: (state, action) => {
      state.active_drawer_key = action.payload.key;
      state.drawerActiveIndex = action.payload.index;
      localStorage.setItem(pageIndexKey, action.payload.key);
      localStorage.setItem(pageIndexTitle, action.payload.index);
    },

    setintialDrawerActiveIndex: (state, action) => {
      const index = parseInt(localStorage.getItem(pageIndexTitle));
      const key = localStorage.getItem(pageIndexKey);
      const path = localStorage.getItem(activePath);

      state.drawerActiveIndex = index ? index : 0;
      state.active_drawer_key = key ? key : CommonString.dashboardScreenKey;
      state.active_path = path;
    },

    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    OpenLocalModal: (state, action) => {
      state.isLocalModalOpen = action.payload;
    },
    SetLocalModalName: (state, action) => {
      state.localModalName = action.payload;
    },

    setIsLogoutModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setModalType: (state, action) => {
      state.modal_type = action.payload;
    },
    setScreenIndexList: (state, action) => {
      state.screenIndexList = action.payload;
    },
    updateIsloadedList: (state, action) => {
      const alreadyloaded = state.isLoadedList.includes(action.payload);
      if (!alreadyloaded) {
        state.isLoadedList = [...state.isLoadedList, action.payload];
      }
    },
    updateAttendanceLoginStatus: (state, action) => {
      state.login_screen = action.payload;
      if (action.payload === 2) {
        state.is_loggedin = true;
      } else {
        state.is_loggedin = false;
      }
    },
    OpenDrawer: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setMonthAndYear: (state, action) => {
      state.date = action.payload;
    },
    openNotification: (state, action) => {
      state.notificationOpen = action.payload;
    },
    setAttendanceStatus: (state, action) => {
      state.attendance_data = action.payload;
    },
    clearState: (state, action) => {
      state.drawerActiveIndex = 0;
      state.isModalOpen = false;
      state.localModalName = null;
      state.isLogoutModalOpen = false;
      state.isNewNotification = true;
      state.screenIndexList = [];
      state.isLoadedList = [];
      state.login_screen = 1;
      state.is_loggedin = false;
      state.drawerOpen = false;
      state.notificationOpen = false;
      state.employeeDetailVisible = false;
      state.date = {
        month: Month[dateToday.getMonth()],
        year: dateToday.getFullYear(),
      };
      state.modal_type = null;
      state.attendance_data = null;
    },
    updateIsEditEvent: (state, action) => {
      state.is_event_edit_event = action.payload;
    },
    setSnackBarAlert: (state, action) => {
      state.snackbar_alert = action.payload;
    },
    setIsDOMJustLoaded: (state, action) => {
      state.is_dom_just_loaded = Boolean(action.payload);
    },
  },
});

export const {
  setActiveDrawer,
  setActiveDrawerkey,
  setintialDrawerActiveIndex,
  setIsModalOpen,
  setModalType,
  setScreenIndexList,
  updateIsloadedList,
  updateAttendanceLoginStatus,
  OpenDrawer,
  setMonthAndYear,
  openNotification,
  setAttendanceStatus,
  clearState,
  updateIsEditEvent,
  SetLocalModalName,
  setSnackBarAlert,
  OpenLocalModal,
  setIsDOMJustLoaded,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
