import React from "react";
import CloseIcon from "../../../assets/images/closeIcon.svg";
import { Span } from "../../../style/common.style";
import {
  CloseButton,
  Img,
  PopupHeading,
  PopupNavbar,
} from "../../../style/form.style";
import { Theme } from "../../../style/theme/color";

export default function PopupNavBar(props) {
  const name = props.title.split(" ");
  function cancelPopup() {
    props.onHandleClose(false);
  }
  return (
    <React.Fragment>
      <PopupNavbar
        componenttype={props.componentType}
        componentsize={props.componentSize}
      >
        <PopupHeading componentsize={props.componentSize}>
          {name[0]}
          <Span color={Theme.primary_color.secondary_color}>
            &nbsp;
            {name.map((ele, index) => {
              if (index === 0) return;
              return ele + " ";
            })}
          </Span>
        </PopupHeading>
        <CloseButton onClick={() => cancelPopup()}>
          <Img src={CloseIcon} alt="" />
        </CloseButton>
        {/* {props.componentType !== "navbarmain" ? <Divider /> : ""} */}
      </PopupNavbar>
    </React.Fragment>
  );
}

//Prex<Span color={Theme.primary_color.secondary_color}>elite</Span>
