import React, { useState } from "react";
import {
  ArrowBottom,
  ArrowContainer,
  ArrowTop,
} from "../../../style/animation.style";

export default function Arrow(props) {
  return (
    <ArrowContainer className="arrow">
      <ArrowTop
        className="arrow-top"
        ishovered={props.isDisplay ? 1 : 0}
      ></ArrowTop>
      <ArrowBottom
        className="arrow-bottom"
        ishovered={props.isDisplay ? 1 : 0}
      ></ArrowBottom>
    </ArrowContainer>
  );
}
