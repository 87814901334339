import ApiUtils from "../APICallServices/APIUtils";

export default class AttendanceProvider {
  constructor() {
    this.assetUtils = new ApiUtils();
  }
  addAsset = (data) => {
    return this.assetUtils.init().post("assets/add-assets", data);
  };
  getAsset = (data) => {
    return this.assetUtils.init().get("assets/get-assets");
  };
}
