import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EmployeeHeader from "../../component/commonComponents/Employee/EmployeeHeader";
import Arrow from "../../componentsv2/Animations/Arrow/Arrow";
import { FlexContainer } from "../../style/asset.style";
import { Div, TypoGraphy } from "../../style/common.style";
import { PrexelMemoriLinkContainer } from "../../style/holiday.style";
import { FontSizes } from "../../style/theme/font";

export default function PrexelMemories() {
  const [searchValue, setSearchValue] = useState("");
  const [memoryList, setMemoryList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  const prexelMemories = useSelector((state) => state.holiday.prexel_memories);
  useEffect(() => {
    DataSetter();
  }, [prexelMemories]);

  function DataSetter() {
    setMemoryList(prexelMemories);
  }

  const filterData = (data) => {
    const newDataList = prexelMemories.filter(
      (prexelMemoryImage) =>
        prexelMemoryImage.name.toLowerCase().search(data.toLowerCase()) !== -1
    );
    setMemoryList(newDataList);
  };

  const delayedOutput = useCallback(debounce(filterData, 1000), []);

  return (
    <Div className="mt-3 h-full">
      <EmployeeHeader
        title="Discover our Memories"
        placeholder="Search “Fiesta ‘21"
        searchValue={searchValue}
        FilterEmployeesInSearch={(data) => {
          setSearchValue(data);
          delayedOutput(data);
        }}
      />
      {/* <VerticalScrollableArea height="100" unit="%"> */}
      <FlexContainer flexWrap="wrap" padding="0 2%">
        {memoryList.map((memory, index) => (
          <Div className=" w-full sm:w-1/3 mt-3 flex justify-start">
            <PrexelMemoriLinkContainer
              href={memory.link}
              target="_blank"
              key={index}
              onMouseEnter={() => setActiveIndex(index)}
            >
              <TypoGraphy width={"fit-content"} fontSize={FontSizes.Para}>
                {memory.name}
              </TypoGraphy>
              <Div className="ml-2">
                <Arrow isDisplay={activeIndex === index} />
              </Div>
              {/* <LottieOnHover
                width="70px"
                image={CommonImages.LottieArrow}
                isDisplay={activeIndex === index}
              /> */}
            </PrexelMemoriLinkContainer>
          </Div>
        ))}
      </FlexContainer>
      {/* </VerticalScrollableArea> */}
    </Div>
  );
}
