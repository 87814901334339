import styled from "styled-components";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";
import { CommonImages } from "../utils/common.images";
import { Button } from "@mui/material";

const PageNotFoundContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: #edf0f5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
`;
const TextContainer = styled.div`
  margin-top: 10%;
  margin-left: 1%;
  width: 25%;
`;
const ImageContainer = styled.div``;
const CodeContainer = styled.div``;
const PageNotFoundImg = styled(CommonImages.PageNotFoundImg)`
  width: 73%;
  margin-left: 15%;
`;
const TextHeader = styled.div`
  font-size: ${FontSizes.LargeText};
  font-weight: 700;
  color: ${Theme.primary_color.dark};
`;
const TextSubDescription = styled.div`
  font-size: ${FontSizes.LargeText};
  font-weight: 300;
  color: ${Theme.primary_color.dark};
  margin-top: 3%;
`;
const TextDescription = styled.div`
  font-size: ${FontSizes.SubPara};
  font-weight: 300;
  color: ${Theme.primary_color.dark};
  margin-top: 10%;
`;
const TextHomeButton = styled(Button)`
  && {
    margin-top: 30%;
    background-color: #0ea6e7;
    text-transform: capitalize;
    font-size: ${FontSizes.Para};
    font-weight: 700;
    color: ${Theme.primary_color.white};
    border-radius: 0;
    padding: 1% 10%;
    &:hover {
      background-color: #0ea6e7;
    }
  }
`;
const Code = styled.div`
  font-size: 150px;
  font-weight: 700;
  color: ${Theme.primary_color.white};
`;

export {
  PageNotFoundContainer,
  TextContainer,
  ImageContainer,
  CodeContainer,
  PageNotFoundImg,
  TextHeader,
  TextSubDescription,
  TextDescription,
  TextHomeButton,
  Code,
};
