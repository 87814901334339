import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import transition from "react-element-popper/animations/transition";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import EmployeeController from "../../controller/EmployeeController";
import { setEmployeeBasicDetails } from "../../reducer/employeereduxstore";
import CustomInput from "../../screen/Profile/CustomInput";
import { Div, TypoGraphy } from "../../style/common.style";
import {
  CalendarContainer,
  FormContainerEvent,
  InputText,
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  PopupNavBarContainer,
} from "../../style/form.style";
import { DatePickerCalendarProfile } from "../../style/profile.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import {
  countryList,
  genderNameList,
  maritalStatusList,
} from "../../utils/constant";
import { delay } from "../../utils/utils";
import ShowLoader from "../Loader/ShowLoader";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";

import DropDownList from "../../componentsv2/common_components/DropDown/Dropdown";
import Input from "../../componentsv2/common_components/Inputs/input";
import BottomButtons from "./components/BottomButtons";

export default function EmployeeAboutForm(props) {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [phnNum, setPhnNum] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [personalEmailID, setPersonalEmailID] = useState("");
  const [googleFitEmailID, setGoogleFitEmailID] = useState("");
  const [aadharCardNum, setAadharCardNum] = useState("");
  const [panCardNum, setPanCardNum] = useState("");
  const [passportNum, setpassportNum] = useState("");
  const [nationality, setNationality] = useState(countryList[0]);
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  // const [birthday, setBirthday] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [anniversary, setAnniversary] = useState("");
  const [experience, setExperience] = useState("");
  // const [hobbies, setHobbies] = useState("");
  const [sex, setSex] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const employeeController = new EmployeeController();
  const employee = useSelector((state) => state.employee);
  useEffect(() => {
    onPrefillData();
  }, [props.popoupStatus]);

  useEffect(() => {
    if (maritalStatus === "Single") {
      setAnniversary(null);
    }
  }, [maritalStatus]);

  function onPrefillData() {
    const isDataEmpty =
      !employee.employee_basic_details ||
      employee.employee_basic_details === null ||
      !employee.employee_basic_details.personal_email;
    if (props.popoupStatus === false || isDataEmpty) {
      setEmail("");
      setPhnNum("");
      setEmergencyContact("");
      setPersonalEmailID("");
      setGoogleFitEmailID("");
      setAadharCardNum("");
      setPanCardNum("");
      setpassportNum("");
      setNationality(countryList[0]);
      setCurrentAddress("");
      setPermanentAddress("");
      setJoiningDate("");
      setMaritalStatus(maritalStatusList[0]);
      setAnniversary("");
      setExperience("");
      // setHobbies(employee.employee_basic_details.hobbies);
      setSex(genderNameList[0]);
      setEmployeeType("");
      return;
    }
    setEmail(props.employee.email);
    setPhnNum(props.employee.phone);
    setEmergencyContact(employee.employee_basic_details.emergency_contact);
    setPersonalEmailID(employee.employee_basic_details.personal_email);
    setGoogleFitEmailID(employee.employee_basic_details.google_fit_email);
    setAadharCardNum(employee.employee_basic_details.aadhar_card_number);
    setPanCardNum(employee.employee_basic_details.pan_number);
    setpassportNum(employee.employee_basic_details.passport_number);
    setNationality(employee.employee_basic_details.nationality);
    setCurrentAddress(employee.employee_basic_details.current_address);
    setPermanentAddress(employee.employee_basic_details.permanent_address);
    setJoiningDate(
      new DateObject(employee.employee_basic_details.joining_date)
    );
    setMaritalStatus(employee.employee_basic_details.marital_status);
    setAnniversary(
      new DateObject(employee.employee_basic_details.anniversary_date)
    );
    setExperience(employee.employee_basic_details.experience);
    // setHobbies(employee.employee_basic_details.hobbies);
    setSex(employee.employee_basic_details.sex);
    setEmployeeType(employee.employee_basic_details.employee_type);
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  function showError() {
    setError(true);
    return;
  }

  function checkError() {
    if (!aadharCardNum || aadharCardNum === "") {
      showError();
      return false;
    }
    if (!currentAddress || currentAddress === "") {
      showError();
      return false;
    }
    if (!emergencyContact || emergencyContact === "") {
      showError();
      return false;
    }
    if (!employeeType || employeeType === "") {
      showError();
      return false;
    }
    if (!experience || experience === "") {
      showError();
      return false;
    }

    if (!googleFitEmailID || googleFitEmailID === "") {
      showError();
      return false;
    }
    if (!maritalStatus) {
      showError();
      return false;
    }
    if (!nationality) {
      showError();
      return false;
    }
    if (!panCardNum || panCardNum === "") {
      showError();
      return false;
    }
    if (!passportNum || passportNum === "") {
      showError();
      return false;
    }
    if (!permanentAddress || permanentAddress === "") {
      showError();
      return false;
    }
    if (!personalEmailID || personalEmailID === "") {
      showError();
      return false;
    }
    if (!sex) {
      showError();
      return false;
    }
    return true;
  }

  async function onSave() {
    // const isError = checkError();
    // if (isError) {
    //   setError(true);
    //   return;
    // }

    let anniversary_date;

    anniversary_date = new DateObject(anniversary).format(
      "YYYY-MM-DDTHH:mm:ss.sssZ"
    );

    if (anniversary_date === "Invalid date") {
      anniversary_date = "";
    }
    let joining_date = new DateObject(joiningDate).format(
      "YYYY-MM-DDTHH:mm:ss.sssZ"
    );

    const sendData = {
      user_id: props.employee._id,
      phone_number: phnNum,
      emergency_contact: emergencyContact,
      personal_email: personalEmailID,
      google_fit_email: googleFitEmailID,
      aadhar_card_number: aadharCardNum,
      pan_number: panCardNum,
      passport_number: passportNum,
      nationality: nationality,
      current_address: currentAddress,
      permanent_address: permanentAddress,
      joining_date: joining_date,
      marital_status: maritalStatus,
      anniversary_date: anniversary_date,
      sex: sex,
      employee_type: employeeType,
      experience: experience,
    };

    setIsLoader(true);
    const response = await employeeController.updateEmployeePersonalDetails(
      sendData
    );

    if (response) {
      dispatch(setEmployeeBasicDetails(sendData));

      await delay(2000);
      setIsLoader(false);
      const msg = (
        <div>
          <div>success</div>
          <div>employee details added sucessfully</div>
        </div>
      );
      handleAlert(msg, "success");
      props.onHandleClose();
      return;
    } else {
      await delay(2000);
      setIsLoader(false);
      const msg = (
        <div>
          <div>error</div>
          <div>problems occured during employee details</div>
        </div>
      );
      handleAlert(msg, "error");
      props.onHandleClose();
      return;
    }
  }
  return (
    <React.Fragment>
      {isLoader ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Updating member contact details</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainer>
              <PopupNavBar
                onHandleClose={props.onHandleClose}
                componentType="form"
                title={
                  props.data === null
                    ? "add employee details"
                    : "update employee details"
                }
              />
            </PopupNavBarContainer>
          </DialogTitle>
          <DialogContent dividers={true}>
            <FormContainerEvent>
              <Div className="px-3">
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0 sm:grid-cols-6">
                  <Div className="col-span-4">
                    <Input
                      //   error={error === 0}
                      title={"Email"}
                      placeholder={"Email"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={email}
                      handleChange={(event) => setEmail(event.target.value)}
                      onPaste={(value) => setEmail(value)}
                    />
                  </Div>
                  <Div className=" col-span-4 sm:col-span-2">
                    <Input
                      //   error={error === 0}
                      title={"Phone No"}
                      placeholder={"Phone No"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={phnNum}
                      handleChange={(event) => setPhnNum(event.target.value)}
                      type="number"
                      // disabled
                      onPaste={(value) => setPhnNum(value)}
                    />
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0  sm:grid-cols-6 mt-2">
                  <Div className="col-span-3">
                    <Input
                      //   error={error === 0}
                      title={"Emergency Contact"}
                      placeholder={"Emergency Contact"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={emergencyContact}
                      handleChange={(event) =>
                        setEmergencyContact(event.target.value)
                      }
                      onPaste={(value) => setEmergencyContact(value)}
                      type="number"
                    />
                  </Div>

                  <Div className="col-span-3">
                    <Input
                      //   error={error === 0}
                      title={"Personal Email ID"}
                      placeholder={"Personal Email ID"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={personalEmailID}
                      handleChange={(event) =>
                        setPersonalEmailID(event.target.value)
                      }
                      onPaste={(event) =>
                        setPersonalEmailID(event.target.value)
                      }
                    />
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0  sm:grid-cols-6 mt-2">
                  <Div className="col-span-6">
                    <Input
                      //   error={error === 0}
                      title={"Google Fit Email ID"}
                      placeholder={"Google Fit Email ID"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={googleFitEmailID}
                      handleChange={(event) =>
                        setGoogleFitEmailID(event.target.value)
                      }
                      onPaste={(event) =>
                        setGoogleFitEmailID(event.target.value)
                      }
                    />
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0  sm:grid-cols-6 mt-2">
                  <Div className="col-span-3">
                    <Input
                      //   error={error === 0}
                      title={"Aadhar Card ID"}
                      placeholder={"Aadhar Card ID"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={aadharCardNum}
                      handleChange={(event) =>
                        setAadharCardNum(event.target.value)
                      }
                      onPaste={(event) => setAadharCardNum(event.target.value)}
                    />
                  </Div>
                  <Div className="col-span-3">
                    <Input
                      //   error={error === 0}
                      title={"PAN Card ID"}
                      placeholder={"PAN Card ID"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={panCardNum}
                      handleChange={(event) =>
                        setPanCardNum(event.target.value)
                      }
                      onPaste={(event) => setPanCardNum(event.target.value)}
                    />
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0  sm:grid-cols-6 mt-2">
                  <Div className="col-span-3">
                    <Input
                      //   error={error === 0}
                      title={"Current Address"}
                      placeholder={"Current Address"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={currentAddress}
                      handleChange={(event) =>
                        setCurrentAddress(event.target.value)
                      }
                      onPaste={(event) => setCurrentAddress(event.target.value)}
                    />
                  </Div>
                  <Div className="col-span-3">
                    <Input
                      //   error={error === 0}
                      title={"Permanent Address"}
                      placeholder={"Permanent Address"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={permanentAddress}
                      handleChange={(event) =>
                        setPermanentAddress(event.target.value)
                      }
                      onPaste={(event) =>
                        setPermanentAddress(event.target.value)
                      }
                    />
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0  sm:grid-cols-6 mt-2">
                  <Div className="col-span-3">
                    <DropDownList
                      // error={error === 1}
                      dataList={countryList}
                      title={"Nationality"}
                      value={nationality}
                      onhandleChange={(event) => {
                        setNationality(event);
                      }}
                      textTransform={"capitalize"}
                      placeholder="Nationality"
                    />
                  </Div>
                  <Div className="col-span-3">
                    <Input
                      //   error={error === 0}
                      title={"Passport ID"}
                      placeholder={"Passport ID"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      value={passportNum}
                      handleChange={(event) =>
                        setpassportNum(event.target.value)
                      }
                      onPaste={(event) => setpassportNum(event.target.value)}
                    />
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0  sm:grid-cols-6 mt-2">
                  <Div className="col-span-6">
                    <CalendarContainer isvisible={1}>
                      <InputText>Joining Date</InputText>
                      <DatePickerCalendarProfile
                        value={joiningDate}
                        onChange={(e) => {
                          setJoiningDate(e);
                        }}
                        format="MMM, D YYYY"
                        maxDate={Date.now()}
                        className="custom-calendar"
                        title={(date) =>
                          date.toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                        }
                        animations={[transition()]}
                        // onlyMonthPicker
                        // ref={calendarRef}
                        // calendarPosition="bottom-center "
                        // minDate={Date.now()}
                        // maxDate={
                        //   new Date(
                        //     new Date().getDay(),
                        //     new Date().getMonth(),
                        //     parseInt(new Date().getFullYear()) + 1
                        //   )
                        // }
                        showOtherDays
                        render={
                          <CustomInput
                            value={joiningDate}
                            placeholder={"Joining Date"}
                          />
                        }
                      />
                    </CalendarContainer>
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0  sm:grid-cols-6 mt-2">
                  <Div className="col-span-2">
                    <DropDownList
                      // error={error === 1}
                      dataList={maritalStatusList}
                      title={"Marital Status"}
                      value={maritalStatus}
                      onhandleChange={(event) => {
                        setMaritalStatus(event);
                      }}
                      textTransform={"capitalize"}
                      placeholder="Marital Status"
                    />
                  </Div>
                  <Div className="col-span-4">
                    <CalendarContainer
                      isvisible={maritalStatus === "Married" ? 1 : 0}
                      className="mt-0.5"
                    >
                      <InputText>Anniversary Date</InputText>
                      <DatePickerCalendarProfile
                        value={anniversary}
                        onChange={(e) => {
                          setAnniversary(e);
                        }}
                        format="MMM, D YYYY"
                        maxDate={Date.now()}
                        className="custom-calendar"
                        title={(date) =>
                          date.toLocaleDateString("en-US", {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          })
                        }
                        animations={[transition()]}
                        // onlyMonthPicker
                        // ref={calendarRef}
                        // calendarPosition="bottom-center "
                        // minDate={Date.now()}
                        // maxDate={
                        //   new Date(
                        //     new Date().getDay(),
                        //     new Date().getMonth(),
                        //     parseInt(new Date().getFullYear()) + 1
                        //   )
                        // }
                        showOtherDays
                        render={
                          <CustomInput
                            value={anniversary}
                            placeholder="anniversary date"
                          />
                        }
                      />
                    </CalendarContainer>
                  </Div>
                </Div>
                <Div className="grid grid-cols-1 gap-x-4 gap-y-2 sm:gap-y-0  sm:grid-cols-6 mt-2">
                  <Div className="col-span-2">
                    <Input
                      error={error === "joiningDate"}
                      title={"Experience"}
                      placeholder={"Experience"}
                      helperText={
                        error ? "please enter your correct experience" : ""
                      }
                      handleChange={(event) =>
                        setExperience(event.target.value)
                      }
                      onPaste={(value) => setExperience(value)}
                      value={experience}
                      type="Number"
                    />
                  </Div>
                  <Div className="col-span-2">
                    <DropDownList
                      // error={error === 1}
                      dataList={genderNameList}
                      title={"Sex"}
                      value={sex}
                      onhandleChange={(event) => {
                        setSex(event);
                      }}
                      textTransform={"capitalize"}
                      placeholder="Sex"
                    />
                  </Div>
                  <Div className="col-span-2">
                    <Input
                      //   error={error === 6}
                      title={"Employee Type"}
                      placeholder={"Employee Type"}
                      //   icon={<Icon icon="ooui:user-avatar-outline" />}
                      handleChange={(event) =>
                        setEmployeeType(event.target.value)
                      }
                      onPaste={(event) => setEmployeeType(event.target.value)}
                      value={employeeType}
                    />
                  </Div>
                </Div>

                {/* <Input
                //   error={error === 6}
                title={"Hobbies"}
                placeholder={"Hobbies"}
                //   icon={<Icon icon="ooui:user-avatar-outline" />}
                onChange={(event) => setHobbies(event.target.value)}
                value={hobbies}
              /> */}

                {error ? (
                  <TypoGraphy
                    color={CommonColor.red}
                    fontSize={FontSizes.Para}
                    fontWeight={"600"}
                  >
                    Please fill all the forms
                  </TypoGraphy>
                ) : (
                  ""
                )}
              </Div>
            </FormContainerEvent>
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => onSave()}
                buttonName={
                  props.data == null
                    ? "Add Employee Details"
                    : "Update Employee Details"
                }
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
