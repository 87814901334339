import React from "react";
import { TypoGraphy } from "../../../../style/common.style";
import { LeaveStatusAbsentContainer } from "../../../../style/dashboard.style";
import {
  EmployeeLeaveHalfDay,
  EmployeeLeaveTime,
  EmployeeLeaveTimeAndTypeContainer,
  EmployeeTimeContainer,
  EmployeeTimeContainerTo,
  EmployeetimingHeader,
} from "../../../../style/leave.style";
import { FontSizes } from "../../../../style/theme/font";
import { CommonString } from "../../../../utils/common.string";
import { formatDisplayDate } from "../../../../utils/utils";

export default function LeaveStatusAbsentPrexelite(props) {
  const userName = props.data.recruiter
    ? props.data.recruiter.name.split(" ")[0]
    : props.data.name.split(" ")[0];

  return (
    <LeaveStatusAbsentContainer>
      <TypoGraphy fontSize={FontSizes.Subtitle} weight="600">
        {userName} is not available today
      </TypoGraphy>
      {/* <TypoGraphy>Prexelite is not available today</TypoGraphy> */}
      <PrexeliteLeaveDuration data={props.data} />
    </LeaveStatusAbsentContainer>
  );
}

function PrexeliteLeaveDuration(props) {
  function getDate(dateOriginalFormat) {
    if (!dateOriginalFormat) return "";
    if (dateOriginalFormat.length === 10) {
      return formatDisplayDate(dateOriginalFormat, "YYYY-MM-DD").format(
        "Do MMM 'YY"
      );
    }
    return formatDisplayDate(dateOriginalFormat).format("Do MMM 'YY");
  }

  function IsDateComing() {
    return props.data.total_leave > 1;
  }

  if (props.data.leave_type === CommonString.leaveTypeHalf) {
    return <TypoGraphy>leave : 0.5 day</TypoGraphy>;
  }
  if (props.data.leave_type === CommonString.leaveTypeSingle) {
    return <TypoGraphy>taking today off</TypoGraphy>;
  }
  if (props.data.leave_type === CommonString.leaveTypeMultiple) {
    return (
      <LeaveStatusAbsentContainer>
        <TypoGraphy fontSize={FontSizes.Para}>Prexelite is on leave</TypoGraphy>
        <LeaveStatusAbsentContainer>
          <EmployeeTimeContainer
            mt={props.leaveComponent === CommonString.leaveApplication}
          >
            <EmployeetimingHeader>
              {IsDateComing() ? "From : " : "date : "}
            </EmployeetimingHeader>

            <EmployeeLeaveTime>
              &nbsp;{getDate(props.data.from_date)}
            </EmployeeLeaveTime>
            {props.data.total_leave === 0.5 ? (
              <EmployeeLeaveHalfDay>(Half day)</EmployeeLeaveHalfDay>
            ) : (
              ""
            )}
          </EmployeeTimeContainer>
          <EmployeeTimeContainerTo
            todate={props.data.to_date}
            isvisible={props.data.total_leave > 1 ? 1 : 0}
          >
            <EmployeetimingHeader>To&nbsp;:&nbsp;</EmployeetimingHeader>
            <EmployeeLeaveTimeAndTypeContainer>
              <EmployeeLeaveTime>
                {props.data.total_leave ? getDate(props.data.to_date) : ""}
              </EmployeeLeaveTime>
              {props.data.total_leave === 0.5 ? (
                <EmployeeLeaveHalfDay>(Half day)</EmployeeLeaveHalfDay>
              ) : (
                ""
              )}
            </EmployeeLeaveTimeAndTypeContainer>
          </EmployeeTimeContainerTo>
        </LeaveStatusAbsentContainer>
      </LeaveStatusAbsentContainer>
    );
  }
  // return (
  //   <TypoGraphy fontSize={FontSizes.Para}>
  //     {userName} is going to be late today
  //   </TypoGraphy>
  // );
}
