import styled from "styled-components";
import { DeviceSizes } from "./common.style";
import CommonColor from "../utils/common.colors";

const LeftScoreCardContainer = styled.div`
  width: 100%;
  margin-right: 1%;
  height: 100%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin: 0;
  }
`;

const RightBlueCardContainer = styled.div`
  width: 0%;
  height: 100%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin: 0;
    height: max-content;
  }
`;

const GameAndTeamListContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media ${DeviceSizes.mobile} {
    margin-top: 2%;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;
const TeamListContainer = styled.div`
  width: 49%;
  height: max-content;
  margin-right: 1%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin: 0;
  }
`;
const GameListCardContainer = styled.div`
  width: 49%;
  height: max-content;
  margin-left: 1%;
  @media ${DeviceSizes.mobile} {
    margin-top: 2%;
    width: 100%;
  }
`;
const GameScoreListContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const TeamImageAddContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 2%;
  border-radius: 50%;
  background-color: ${CommonColor.light_blue};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EmployeDetailCardContainer = styled.div`
  width: 90%;
  height: 127px;
  padding: 8%;
  margin-bottom: 3%;
  box-shadow: 0px 0px 27px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TeamMemberButtonContainer = styled.div`
  opacity: ${(props) => props.isvisible};
  transition: all 0.5s ease-in-out;
  width: max-content;
  height: max-content;
`;
const PointsTableContainer = styled.div`
  width: 100%;
`;
const PointsTableHeaderContainer = styled.div`
  width: 100%;
  border-collapse: collapse;
  display: flex;
`;
const PointsTableHeader = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1px solid ${CommonColor.border_light};
  padding: 2%;
  box-sizing: border-box;
`;
const PointsTableHeaderRow = styled.div`
  width: ${(props) => props.width ?? "100%"};
  display: flex;
  height: 50px;
  border: 1px solid ${CommonColor.border_light};
  border-collapse: collapse;
  justify-content: center;
  align-items: center;
  background-color: ${CommonColor.primary_color};
  color: ${CommonColor.white};
  font-weight: 600;
  &:first-child {
    border-top-left-radius: 10px;
  }
  &:nth-last-child(1) {
    border-top-right-radius: 10px;
  }
  @media ${DeviceSizes.mobile} {
    justify-content: flex-start;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 3px;
    box-sizing: border-box;
  }
`;
const PointsTableHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 2%;
`;
const PointsTableBody = styled.div`
  width: 100%;
  border-collapse: collapse;
`;
const PointsTableBodyRow = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  &:nth-child(even) {
    background-color: ${CommonColor.light_orange};
  }
  &:nth-child(odd) {
    color: #000000e0;
  }
`;

const PointsTableCell = styled(PointsTableHeader)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width};
  font-size: 24;

  &:nth-last-child(1) {
    border-bottom-right-radius: ${(props) =>
      props.len === props.index ? "10px" : "0px"};
  }
  &:nth-child(1) {
    border-bottom-left-radius: ${(props) =>
      props.len === props.index ? "10px" : "0px"};
  }

  @media ${DeviceSizes.mobile} {
    justify-content: flex-start;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 3px;
    box-sizing: border-box;
  }
`;
const WinnerIcon = styled.img`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
`;
const AddMatchContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 50%;
  background-color: ${CommonColor.light_blue};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TeamLogoContainer = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  margin: ${(props) => props.m};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const TeamLogoImageContainer = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;
const SliderWrapper = styled.div`
  --min: 0;
  --max: 100;
  --step: 0.5;
  --primary-color: #0366d6;
  --value-offset-y: var(--ticks-gap);
  --value-active-color: white;
  --value-background: transparent;
  --value-background-hover: var(--primary-color);
  --value-font: inherit;
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 4px;
  background: #ddd;
  border-radius: 5px;

  input[type="range"] {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-appearance: none;
    background: transparent;
    pointer-events: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      pointer-events: all;
      width: 16px;
      height: 16px;
      background: var(--primary-color);
      border-radius: 50%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      pointer-events: all;
      width: 16px;
      height: 16px;
      background: var(--primary-color);
      border-radius: 50%;
      cursor: pointer;
    }

    &::-ms-thumb {
      pointer-events: all;
      width: 16px;
      height: 16px;
      background: var(--primary-color);
      border-radius: 50%;
      cursor: pointer;
    }
  }

  output {
    position: absolute;
    top: 20px;
    left: calc(var(--value) * 1%);
    transform: translateX(-50%);
    background: var(--value-background);
    color: var(--value-active-color);
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 14px;
    pointer-events: none;
  }

  .range-slider__progress {
    position: absolute;
    height: 100%;
    background: var(--primary-color);
    border-radius: 5px;
    width: calc(var(--value) * 1%);
  }
`;
export {
  SliderWrapper,
  TeamLogoImageContainer,
  TeamLogoContainer,
  AddMatchContainer,
  WinnerIcon,
  PointsTableCell,
  PointsTableHeaderRow,
  PointsTableBodyRow,
  PointsTableBody,
  PointsTableHeaderImage,
  PointsTableHeader,
  PointsTableHeaderContainer,
  PointsTableContainer,
  TeamMemberButtonContainer,
  EmployeDetailCardContainer,
  TeamImageAddContainer,
  GameScoreListContainer,
  GameListCardContainer,
  TeamListContainer,
  LeftScoreCardContainer,
  RightBlueCardContainer,
  GameAndTeamListContainer,
};
