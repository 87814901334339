import React from "react";
import { useSelector } from "react-redux";
import { EmployeeLeaveStatus } from "../../../style/leave.style";

export default function LeaveStatus(props) {
  const auth = useSelector((state) => state.auth);
  return (
    <React.Fragment>
      <EmployeeLeaveStatus
        ismanager={auth.is_manager}
        $leaveStatus={props.leaveStatus}
        isdisplay={props.display ? 1 : 0}
      >
        {props.leaveStatus}
      </EmployeeLeaveStatus>
    </React.Fragment>
  );
}
