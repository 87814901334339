import { createSlice } from "@reduxjs/toolkit";
import { EmployeeLevelManagerList } from "../utils/mockData";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    api_token: {
      token: null,
    },
    is_manager: false,
    employee_data: "init",
    data_access: {},
  },
  reducers: {
    updateApiToken: (state, action) => {
      state.api_token = action.payload;
    },
    updateEmployeeData: (state, action) => {
      state.employee_data = action.payload;
      state.is_manager = EmployeeLevelManagerList.includes(
        action.payload.level.name
      );
    },
    updateDataAccess: (state, action) => {
      state.data_access = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateApiToken, updateEmployeeData, updateDataAccess } =
  authSlice.actions;

export default authSlice.reducer;
