import { Icon } from "@iconify/react";
import React from "react";
import {
  EmployeeListNoDataContainer,
  IconContainerEmpList,
  NoDataEmpListText,
  NoDataEmpListTextHighlight,
} from "../../../style/dashboard.style";
import CommonColor from "../../../utils/common.colors";

export default function NoDataDashboard(props) {
  return (
    <EmployeeListNoDataContainer>
      <IconContainerEmpList>
        <Icon
          icon={props.icon}
          color={CommonColor.secondary_color}
          fontSize={24}
        />
      </IconContainerEmpList>
      <NoDataEmpListText>{props.text}</NoDataEmpListText>
    </EmployeeListNoDataContainer>
  );
}
