import React, { useRef } from "react";
import transition from "react-element-popper/animations/transition";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AttendanceController from "../../../controller/attendanceController";
import {
  attendanceMonth,
  attendanceSelectedMonth,
  monthBasicAttendanceDetail,
} from "../../../reducer/attendancereduxstore";
import { setActiveDrawerkey } from "../../../reducer/dashboardreduxstore";
import { AttendanceDetailOpener } from "../../../reducer/pagetransitionstore";
import { getLocalStorage } from "../../../services/LocalStorage/LocalStorage";
import {
  CardTitleContainer,
  DatePickerCalendar,
} from "../../../style/common.style";
import {
  AttendaceFoot,
  AttendanceBody,
  AttendanceDivider,
  AttendanceIconifyIcon,
  AttendanceSection,
  AttendanceSectionTitle,
  AttendanceSectionValue,
  AvatarContainer,
  BaseCardDashboardContainerThird,
  CardContainer,
  DashboardEmployeeAttendaneNavbar,
  DashboardEmployeeName,
  DividerHorizontalSeprator,
  EmployeeMonthContainer,
  EmployeeNameContainer,
  Month,
  MonthName,
  ViewDetailsButton,
} from "../../../style/dashboard.style";
import { userdata } from "../../../utils/common.key";
import { Month as Months } from "../../../utils/constant";
import { monthShort } from "../../../utils/constant.array";
import {
  getActiveDrawerData,
  getMonthBasicDetail,
  getNextMonthDate,
} from "../../../utils/utils";
import EmployeeAttendanceShimmer from "../../Loader/shimmers/EmployeeAttendanceShimmer";

function EmployeeAttendance(props) {
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const history = useHistory();
  const attendance = useSelector((state) => state.attendance);
  const employee = useSelector((state) => state.employee);
  const attendanceController = new AttendanceController();
  const year = new Date().getFullYear().toString();

  function getProfileScreen() {
    return getActiveDrawerData(employee.employee_acess)[
      getActiveDrawerData(employee.employee_acess).length - 1
    ];
  }

  function getProfileScreenIndex() {
    return getActiveDrawerData(employee.employee_acess).length - 1;
  }

  function OpenAttendanceDetail() {
    dispatch(
      AttendanceDetailOpener({
        openState: true,
        month: new DateObject(attendance.attendance_selected_month),
      })
    );
    dispatch(
      setActiveDrawerkey({
        element: getProfileScreen(),
        index: getProfileScreenIndex(),
      })
    );
    history.push("profile");
  }

  async function setMonthAndYearName(DateData) {
    dispatch(attendanceSelectedMonth(DateData));
    const dataMonthOne = {
      user_id: getLocalStorage(userdata).user._id,
      month: Months[DateData.monthIndex].toLowerCase(),
      year: year,
    };
    const attendanceMonthData = await attendanceController.getMonthAttendance(
      dataMonthOne
    );
    dispatch(attendanceMonth(attendanceMonthData));
    const monthBasicDetail = getMonthBasicDetail(attendanceMonthData);

    dispatch(monthBasicAttendanceDetail(monthBasicDetail));
  }

  const name = getLocalStorage(userdata)
    ? getLocalStorage(userdata).user.name
    : "";
  function closeCalendar() {
    calendarRef.current.closeCalendar();
  }
  return (
    <React.Fragment>
      <BaseCardDashboardContainerThird
        isvisible={props.isVisible ? 1 : 0}
        delay={props.serialOrder}
      >
        {props.LoadingFlag ? (
          <EmployeeAttendanceShimmer />
        ) : (
          <CardContainer>
            <CardTitleContainer>
              <DashboardEmployeeAttendaneNavbar>
                <EmployeeNameContainer>
                  <AvatarContainer>{name[0]}</AvatarContainer>
                  <DashboardEmployeeName>{name}</DashboardEmployeeName>
                </EmployeeNameContainer>
                <DatePickerCalendar
                  value={new DateObject(attendance.attendance_selected_month)}
                  onChange={setMonthAndYearName}
                  months={monthShort}
                  className="attendance-calendar"
                  animations={[transition()]}
                  ref={calendarRef}
                  onlyMonthPicker
                  maxDate={getNextMonthDate()}
                  hideYear
                  buttons={false}
                  calendarPosition="bottom-end"
                  format="MMM YYYY"
                  render={(value, openCalendar) => {
                    return (
                      <EmployeeMonthContainer onClick={openCalendar}>
                        <MonthName>
                          <Month onClick={() => closeCalendar()}>{value}</Month>
                        </MonthName>
                        <AttendanceIconifyIcon icon="dashicons:arrow-down-alt2"></AttendanceIconifyIcon>
                      </EmployeeMonthContainer>
                    );
                  }}
                ></DatePickerCalendar>
              </DashboardEmployeeAttendaneNavbar>
            </CardTitleContainer>
            <DividerHorizontalSeprator mt="1%" ml="0%" mb="3%" mr="0%" />
            <AttendanceBody>
              <AttendanceSection>
                <AttendanceSectionTitle>Presents</AttendanceSectionTitle>
                <AttendanceSectionValue>
                  {attendance.month_basic !== null
                    ? attendance.month_basic.present
                    : ""}
                </AttendanceSectionValue>
              </AttendanceSection>
              <AttendanceDivider orientation="vertical" flexItem />
              <AttendanceSection>
                <AttendanceSectionTitle>Late</AttendanceSectionTitle>
                <AttendanceSectionValue>
                  {attendance.month_basic !== null
                    ? attendance.month_basic.late
                    : ""}
                </AttendanceSectionValue>
              </AttendanceSection>
              <AttendanceDivider orientation="vertical" flexItem />
              <AttendanceSection>
                <AttendanceSectionTitle>Absents&nbsp;</AttendanceSectionTitle>
                <AttendanceSectionValue>
                  {attendance.month_basic !== null
                    ? attendance.month_basic.absent
                    : ""}
                </AttendanceSectionValue>
              </AttendanceSection>
            </AttendanceBody>
            <DividerHorizontalSeprator mt="3%" ml="0%" mb="1%" mr="0%" />
            <AttendaceFoot>
              <ViewDetailsButton
                variant="text"
                onClick={() => OpenAttendanceDetail()}
              >
                view details
              </ViewDetailsButton>
            </AttendaceFoot>{" "}
          </CardContainer>
        )}
      </BaseCardDashboardContainerThird>
    </React.Fragment>
  );
}

export default EmployeeAttendance;

/*
          <EmployeeMonthContainer onClick={() => props.OpenCalendar()}>
              <MonthName>
                <Month>{dashboard.date.month}</Month>
                &nbsp;
                <Year> {dashboard.date.year}</Year>
              </MonthName>
              &nbsp;
              <AttendanceIconifyIcon icon="dashicons:arrow-down-alt2"></AttendanceIconifyIcon>
            </EmployeeMonthContainer>
 */
