import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

import HomeIcon from "@mui/icons-material/Home";
import { AppBar, Toolbar } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../component/commonComponents/hooks/getWindowDimension";
import NavBar from "../../component/drawer/NavBar";
import EmployeeController from "../../controller/EmployeeController";
import AttendanceController from "../../controller/attendanceController";
import { setShift } from "../../reducer/attendancereduxstore";
import {
  setEmployeeBasicDetails,
  setEmployeeBodyMeasurenment,
} from "../../reducer/employeereduxstore";
import ProfileDetails from "../../screen/Profile/ProfileDetails";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { FlexContainer } from "../../style/asset.style";
import { TypoGraphy } from "../../style/common.style";
import { AppBarStyle } from "../../style/drawer.style";
import {
  AppBarContainer,
  ProfileBannerContainer,
  ProfileBasicDetailsV3,
  ProfileBgLineContainer,
  ProfileImageCotnainer,
  ProfileScreenWrappper,
  ProfileShinyLine,
  ProfileTables,
  ProfileV2Container,
  ProfileV3Container,
  StatusBar,
  UserBasicDetailsV3,
  UserContactDetails,
} from "../../style/profile.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import { userdata } from "../../utils/common.key";
import { ProfileBaseUrl } from "../../utils/constant";
import ImageAvatar from "../Images/ImageAvatar";

export default function ProfileNew(props) {
  const dashboard = useSelector((state) => state.dashboard);
  const leave = useSelector((state) => state.leave);
  const employee = useSelector((state) => state.employee);
  const attendance = useSelector((state) => state.attendance);
  const dispatch = useDispatch();
  const employeeController = new EmployeeController();
  const attendanceController = new AttendanceController();
  const [loading, setLoading] = useState(false);
  const windowWidth = useWindowSize().width;

  useEffect(() => {
    getMeasurenments();
  }, [props.employeeDetail]);

  async function getMeasurenments() {
    const sendData = {
      user_id: props.employeeDetail._id,
    };
    try {
      const respContact = await employeeController.getPersonalDetails(sendData);
      if (respContact.length > 0) {
        dispatch(setEmployeeBasicDetails(respContact[0]));
      }
      const resp = await employeeController.getMeasurenment(sendData);
      const officeTimings = await attendanceController.getShift();
      dispatch(setShift(officeTimings));
      if (attendance.active_user_month_attendance.length === 0) {
        setLoading(true);
        await props.getDashboardScreenData(getLocalStorage(userdata));
        setLoading(false);
      }
      if (resp.length > 0) {
        dispatch(setEmployeeBodyMeasurenment(resp[0]));
      } else {
        dispatch(setEmployeeBodyMeasurenment([]));
      }
    } catch (e) {
      console.error(
        "error occured during body measurenment and office timing fetch ",
        e
      );
    }
  }
  function getUser() {
    return !props.employeeDetail || props.employeeDetail === null
      ? getLocalStorage(userdata).user
      : props.employeeDetail;
  }
  function getProfileImage() {
    const user = getUser();
    return ProfileBaseUrl + user.profile_image_url;
  }

  const attendanceData = useSelector(
    (state) => state.attendance.active_user_month_attendance
  );

  function getAbsent() {
    const ans = attendanceData.filter(
      (data) => data.in_time === "00:00"
    ).length;
    return ans;
  }

  function getLate() {
    const ans = attendanceData.filter(
      (data) => data.is_late == "1"
    ).length;
    return ans;
  }


  return (
    <ProfileV2Container>
      {props.component === "main" ? (
        <AppBarContainer>
          <AppBar
            position="static"
            open={props.open}
            elevation={5}
            sx={AppBarStyle(dashboard.isModalOpen, props.open)}
          >
            <Toolbar>
              <NavBar
                DrawerOpen={props.open}
                LoadingFlag={props.LoadingFlag}
                NavBarTitle={"Profile"}
                NavBarIcon={{
                  icon: "",
                  name: "",
                }}
                isProfile={true}
              />
            </Toolbar>
          </AppBar>
        </AppBarContainer>
      ) : (
        ""
      )}
      <ProfileScreenWrappper isappbar={props.component === "main"}>
        {/* <ProfileUserContainer>
          <FlexContainer flexDirection="column">
            <Div className="h-[80px] w-[80px]">
              <ImageAvatar
                src={getProfileImage()}
                alt=""
                defaultSrc={CommonImages.NoUser}
              />
            </Div>
            <TypoGraphy fontSize={FontSizes.Title} weight="600">
              {props.employeeDetail.name}
            </TypoGraphy>
            <TypoGraphy fontSize={FontSizes.SubPara} weight="600">
              {props.employeeDetail.employee_id.toUpperCase()}
            </TypoGraphy>
            <FlexContainer margin="3% 0 0 0" flexDirection="column">
              <StatusBar
                value={
                  (leave.used_leave_number_info /
                    leave.total_leave_number_info) *
                  100
                }
                max={100}
              />
              <FlexContainer width="max-content" margin="0 0 0 5%">
                <TypoGraphy fontSize={FontSizes.Para}>
                  {leave.used_leave_number_info}/{leave.total_leave_number_info}{" "}
                  Leaves used
                </TypoGraphy>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </ProfileUserContainer>
        <ProfileFastDetails>
          <ProfileV2Container>
            <ProfileFastAccessDetails {...props} />
          </ProfileV2Container>
        </ProfileFastDetails> */}
        <ProfileV3Container>
          <ProfileBannerContainer>
            <ProfileBgLineContainer>
              <ProfileShinyLine />
              <ProfileShinyLine />
              <ProfileShinyLine />
            </ProfileBgLineContainer>
          </ProfileBannerContainer>
          <ProfileBasicDetailsV3>
            <ProfileImageCotnainer>
              <ImageAvatar
                src={getProfileImage()}
                alt={getUser().name + "'s image"}
                defaultSrc={CommonImages.NoUser}
              />
            </ProfileImageCotnainer>
            <UserBasicDetailsV3>
              <TypoGraphy
                textTransform="capitalize"
                fontSize={FontSizes.Subtitle}
                weight="600"
                textAlign="center"
              >
                {getUser().name.toLowerCase()}
              </TypoGraphy>
              <TypoGraphy
                textTransform="capitalize"
                fontSize={FontSizes.SmallText}
                weight="600"
                textAlign="center"
              >
                {getUser().employee_id.toUpperCase()}
              </TypoGraphy>
            </UserBasicDetailsV3>
          </ProfileBasicDetailsV3>
          <UserContactDetails>
            <FlexContainer
              verticalAlign="center"
              flexDirection={windowWidth < 600 ? "column" : "row"}
              jc="center"
              margin={windowWidth < 600 ? "14% 0 0 0" : ""}
            >
              <FlexContainer
                verticalAlign="start"
                jc="center"
                flexDirection="column"
                height="100%"
              >
                <FlexContainer jc={windowWidth < 600 ? "start" : ""}>
                  <EmailIcon
                    style={{ color: CommonColor.primary_color_opacity_05 }}
                  />
                  <TypoGraphy margin="0 0 0 5px">{getUser().email}</TypoGraphy>
                </FlexContainer>
                <FlexContainer
                  margin={windowWidth < 600 ? "0 0 4% 0" : "1% 0 0 0"}
                >
                  <CallIcon
                    style={{ color: CommonColor.primary_color_opacity_05 }}
                  />
                  <TypoGraphy margin="0 0 0 5px">{getUser().phone}</TypoGraphy>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                flexDirection="column"
                verticalAlign="start"
                jc="center"
                height="100%"
                margin={windowWidth < 600 ? "0 0 4% 0" : "0"}
              >
                <TypoGraphy>Total Absent :{ getAbsent()}</TypoGraphy>
              
               
                  <TypoGraphy>Total Late :{ getLate()}</TypoGraphy>
                
              </FlexContainer>
              <FlexContainer
                flexDirection="column"
                verticalAlign={"start"}
                margin={windowWidth < 600 ? "0 0 4% 0" : "0 10px 0 0"}
              >
                <FlexContainer
                  jc="start"
                  verticalAlign="start"
                  width={"fit-content"}
                >
                  <HomeIcon
                    style={{ color: CommonColor.primary_color_opacity_05 }}
                  />
                  <TypoGraphy
                    margin="0 0 0 5px"
                    width={"fit-content"}
                    textAlign={"left"}
                    wordWrap="break-word"
                    textTransform="capitalize"
                  >
                    {employee.employee_basic_details.current_address?.toLowerCase()}
                  </TypoGraphy>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </UserContactDetails>
        </ProfileV3Container>
        <ProfileTables>
          <ProfileDetails
            isPopupOpen={props.isPopupOpen}
            employee={getUser()}
            LoadingFlag={loading}
            component={props.component}
          />
        </ProfileTables>
      </ProfileScreenWrappper>
    </ProfileV2Container>
  );
}
