import { AppBar, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import AppealInfoCard from "../../component/appeal/appealinfocard";
import TailwindDropdownMembers from "../../component/commonComponents/inputs/TailWindDropdownMembers";
import NoData from "../../component/commonComponents/NoData/NoData";
import NavBar from "../../component/drawer/NavBar";
import ApplyAppeal from "../../component/Forms/ApplyAppeal";
import ApplyLeave from "../../component/Forms/ApplyLeave";
import SliderTab from "../../componentsv2/common_components/SliderTab.js/SliderTab";
import AttendanceController from "../../controller/attendanceController";
import {
  setActiveUserMonthAttendance,
  setShift,
} from "../../reducer/attendancereduxstore";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import {
  AppealAttendanceApplicationContainer,
  ApplealApplicationGrid,
} from "../../style/appeal.style";
import { AppBarStyle } from "../../style/drawer.style";
import {
  LeaveApplicationGrid,
  NoDataComponentContainerLeaveApp,
  VerticalScrollableAreaLeaveInfo,
} from "../../style/leave.style";
import { userdata } from "../../utils/common.key";
import { CommonString } from "../../utils/common.string";
import { Month, ProfileBaseUrl } from "../../utils/constant";
import { MonthData } from "../../utils/mockData";
import AppealAttendance from "./AppealAttendas";

// import { EmployeeAppealInfoContainer } from "../../style/appeal.style";
function Apile(props) {
  const dispatch = useDispatch();

  const dashboard = useSelector((state) => state.dashboard);

  const appeal = useSelector((state) => state.appeal);

  const attendance = useSelector((state) => state.attendance);

  const attendanceController = new AttendanceController();

  const [IsPopupOpen, setOpenPopup] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(
    CommonString.pending.toUpperCase()
  );
  const [role, setRole] = useState("user");
  const [pageLoader, setPageLoader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadedContent, setLoadedContent] = useState([]);
  const [activeEmployeeData, setActiveEmployeeData] = useState(
    getLocalStorage(userdata).user
  );
  const employee = useSelector((state) => state.employee);
  const open = Boolean(anchorEl);

  const month = new Date().getMonth();

  const [currentMonthIndex, setCurrentMothIndex] = useState(month);

  const [currentMonthName, setCurreentMonthName] = useState(MonthData[month]);

  const [selectedData, setSelectedData] = useState(null);

  const [selectedEmp, setSelectedEmp] = useState();

  const [activeTab, setActiveTab] = useState("PENDING");

  const employee_list = useSelector((state) => state.employee.employee_list);

  const [selectedTab, setSelectedTab] = useState(0);
  const isAdmin = employee.employee_acess.attendance;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getData();
  }, [currentMonthIndex, activeEmployeeData]);

  // async function getAttendsData(){

  //   console.log("calling in appeal page")
  //   const currentMonthIndex = new Date().getMonth();
  //   const year = new Date().getFullYear().toString();
  //   const dataMonthOne = {
  //     user_id: getLocalStorage(userdata).user._id,
  //     month: Month[currentMonthIndex].toLowerCase(),
  //     year: year,
  //   };

  //   console.log("data monthone -> ",dataMonthOne);

  //   const attendanceMonthData = await attendanceController.getMonthAttendance(
  //     dataMonthOne
  //   );
  //   if (!attendanceMonthData.includes("error")) {
  //     console.log("attendanceMonthData -> ",attendanceMonthData);
  //     dispatch(attendanceMonth(attendanceMonthData));
  //     const monthBasicDetail = getMonthBasicDetail(attendanceMonthData);
  //     dispatch(monthBasicAttendanceDetail(monthBasicDetail));
  //     let tempArray = loadedContent;
  //     tempArray.push("month attendance");
  //     tempArray.push("month basic attendance");
  //     setLoadedContent(tempArray);
  //   }
  //   // console.log(employeeAcess);
  //   console.log("employeeAcess -> " , employeeAcess.attendance);
  //   if (employeeAcess.attendance) {
  //     const dailyAttendance = await attendanceController.getDailyAttendance();

  //     console.log("daily attendance -> " , dailyAttendance );
  //     if (dailyAttendance) {

  //       console.log("finally dispatch -> ", dailyAttendance);
  //       dispatch(setDailyAttendance(dailyAttendance));
  //       let tempArray = loadedContent;
  //       tempArray.push("daily attendance");
  //       setLoadedContent(tempArray);
  //     }
  //   }
  // }

  async function getData() {
    setPageLoader(true);
    await props.getAppealData();
    // setCurrentMothIndex(new Date().getMonth())
    const year = new Date().getFullYear().toString();
    const dataMonthOne = {
      user_id:
        activeEmployeeData._id === undefined
          ? activeEmployeeData.user_id
          : activeEmployeeData._id,
      month: Month[currentMonthIndex].toLowerCase(),
      year: year,
    };
    const monthAttendance = await attendanceController.getMonthAttendance(
      dataMonthOne
    );

    dispatch(setActiveUserMonthAttendance(monthAttendance));

    const officeTimings = await attendanceController.getShift();
    dispatch(setShift(officeTimings));
    if (attendance.active_user_month_attendance.length === 0) {
      setPageLoader(true);
      await props.getDashboardScreenData(getLocalStorage(userdata));
    }
    setPageLoader(false);
  }

  function onEditLeave(variable, status) {
    // dispatch(updateSelectedLeaveDate(props.data));
    props.setselectedStatus(CommonString.edit_leave.toUpperCase());
    // setisOpen(true);
    setselectedStatus(status);
    handleCloseMenu();
  }

  function openPopup() {
    setOpenPopup(true);
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  function closePopup() {
    setOpenPopup(false);
    dispatch(setIsModalOpen(false));
    setselectedStatus(CommonString.pending.toUpperCase());
    // dispatch(updateSelectedLeaveDate(null));
  }

  function getProfileImageUrl() {
    if (props.data.user.profile_image_url !== null) {
      return ProfileBaseUrl + props.data.user.profile_image_url;
    }
    return "";
  }

  const handleClick = (event) => {
    // event.preventDefault();
    // setAnchorEl(event.currentTarget);
  };

  function onClickFooterButton(event, status) {
    if (event) {
      event.stopPropagation();
    }
    setselectedStatus(status);
    // setisOpen(true);
    handleCloseMenu();
  }

  // function getChildren() {
  //   if (selectedStatus === CommonString.edit_leave.toUpperCase()) {
  //     return (
  //       <ApplyLeave
  //         IsPopupOpen={dashboard.isModalOpen}
  //         isEdit={true}
  //         onHandleClose={() => closePopup()}
  //       />
  //     );
  //   }

  //   return (
  //     <ApplyAppeal
  //       data={selectedData}
  //       IsPopupOpen={IsPopupOpen}
  //       onHandleClose={setOpenPopup}
  //       forUser={getLocalStorage(userdata)?.data}
  //     />
  //   );
  // }

  function getEmployeeList() {
    return employee_list;
  }

  const appealTypes = [
    {
      name: "Pending",
    },
    {
      name: "Approved",
    },
    {
      name: "Declined",
    },
  ];

  
  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Appeal Attendance"}
          />
        </Toolbar>
      </AppBar>

      <AppealAttendanceApplicationContainer isAdmin={isAdmin} container>
        <ApplealApplicationGrid className="grid2" item>
          {/* <LeaveApplicationGrid className="grid2" item> */}

          {/* <div className="flex w-full  flex-wrap "> */}
          {isAdmin && (
            <TailwindDropdownMembers
              // title="Assigned To"
              dataList={getEmployeeList()}
              title={""}
              value={selectedEmp}
              handleChange={(event) => {
                setSelectedEmp(event);
                setActiveEmployeeData(event);
              }}
              textTransform={"capitalize"}
              placeholder={"Select Prexelite"}
            />
          )}

          <TailwindDropdownMembers
            // title="Assigned To"
            dataList={MonthData}
            // title={"View leaves details of : "}
            value={currentMonthName}
            handleChange={(event) => {
              setCurrentMothIndex(event.id);
              setCurreentMonthName(event);
            }}
            textTransform={"capitalize"}
            placeholder={"Select Month"}
          />

         
          <VerticalScrollableAreaLeaveInfo
            height={`${isAdmin ? "84%" : "100%"}`}
            unit={""}
            mobileheight=""
            // display="none"
          >
            <AppealAttendance
              currentMonthIndex={currentMonthIndex}
              employee={activeEmployeeData}
              loading={pageLoader}
              setLoading={setPageLoader}
              monthAndYear={
                new DateObject(attendance.attendance_selected_month)
              }
              isPopupOpen={IsPopupOpen}
              setOpenPopup={setOpenPopup}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              setCurrentMothIndex={setCurrentMothIndex}
            />
          </VerticalScrollableAreaLeaveInfo>
        </ApplealApplicationGrid>

        <ApplealApplicationGrid className="grid2" item>
          <div className="mb-3">
            <SliderTab
              buttonList={appealTypes}
              tab={selectedTab}
              onTabClick={(event) => {
                setSelectedTab(event);

                {
                  event === 0
                    ? handleTabClick("PENDING")
                    : event === 1
                    ? handleTabClick("APPROVED")
                    : handleTabClick("DECLINED");
                }
              }}
            />
          </div>

          <VerticalScrollableAreaLeaveInfo
            height={"95%"}
            unit={""}
            mobileheight=""
            // display="none"
          >
            {appeal.appeal_employees_data.length > 0 ? (
              // <EmployeeAppealInfoContainer>

              appeal.appeal_employees_data.filter(
                (data) => data.status === activeTab
              ).length === 0 ? (
                <NoDataComponentContainerLeaveApp>
                  <NoData noDataText={CommonString.NoAppeal} />
                </NoDataComponentContainerLeaveApp>
              ) : (
                appeal.appeal_employees_data
                  .filter((data) => data.status === activeTab)
                  .map((data, index) => (
                    <AppealInfoCard
                      index={index}
                      data={data}
                      currentMonthIndex={currentMonthIndex}
                      length={appeal.length}
                      isEdit={true}
                      isActive={false}
                      onClick={() => {}}
                      //  setselectedStatus={setselectedStatus}
                    />
                    // </EmployeeAppealInfoContainer>
                  ))
              )
            ) : (
              // </EmployeeAppealInfoContainer>
              <React.Fragment>
                <NoDataComponentContainerLeaveApp>
                  <NoData noDataText={CommonString.NoAppeal} />
                </NoDataComponentContainerLeaveApp>
              </React.Fragment>
            )}
          </VerticalScrollableAreaLeaveInfo>
        </ApplealApplicationGrid>
      </AppealAttendanceApplicationContainer>
    </React.Fragment>
  );
}

export default Apile;
