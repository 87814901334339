const reportScreens = [
  {
    name: "Attendance Report",
    iconName: "mdi:user-key-outline",
  },
  {
    name: "Leave Report",
    iconName: "icon-park-outline:table-report",
  },
];

const SpeedyPrexeliteTitle = [
  {
    name: "Holidays",
    icon1: "icon-park-solid:vacation",
    icon2: "icon-park-outline:vacation",
  },
  {
    name: "Birthdays",
    icon1: "material-symbols:celebration-rounded",
    icon2: "material-symbols:celebration-outline-rounded",
  },
  {
    name: "Speedy Prexelites",
    icon1: "material-symbols:keyboard-alt",
    icon2: "material-symbols:keyboard-alt-outline",
  },
  {
    name: "Prexel Memories",
    icon1: "ri:camera-lens-fill",
    icon2: "ri:camera-lens-line",
  },
];
/*
  {
    name: "Synergy League Score Card",
    iconName: "material-symbols:sports-score",
  },
  // { name: "Schedule", iconName: "icon-park-twotone:schedule" },
*/

export { reportScreens, SpeedyPrexeliteTitle };
