import React from "react";
import { LoaderPageRefresh } from "../../style/loader.style";

import { CommonImages } from "../../utils/common.images";

export default function PageRefreshLoader(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CommonImages.pagerefreshloader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  function findHeight() {
    if (props.component === "innerInner") {
      if (props.type === 1) {
        return 100;
      } else {
        return 50;
      }
    }
    return 200;
  }
  return (
    <LoaderPageRefresh
      options={defaultOptions}
      mt={props.marginTop}
      margin={props.margin}
      
      // style={{
      //   marginTop:
      //     props.marginTop ?? props.component === "inner" ? "20vh" : "30vh",
      // }}
      height={props.height ?? findHeight()}
      width= {props.width ?? 250}
      isStopped={false}
      isPaused={false}
    />
  );
}
