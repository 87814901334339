import { createSlice } from "@reduxjs/toolkit";

export const policySlice = createSlice({
  name: "policy",
  initialState: {
    policy: [],
    active_policy : null,
    isPolicyLoaded : false
  },
  reducers: {
    setPolicy: (state, action) => {
      state.policy = action.payload;
    },
    setActivePolicy : (state , action) => {
      state.active_policy = action.payload
    },
    setPolicyLoaded : (state , action) => {
      state.isPolicyLoaded = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { setPolicy , setActivePolicy , setPolicyLoaded } = policySlice.actions;

export default policySlice.reducer;
