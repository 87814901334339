import MaterialProvider from "../services/provider/materialProvider";

export default class MaterialController {
  constructor(props) {
    this.materialProvider = new MaterialProvider();
  }
  getEmployeePdf = async () => {
    const response = await this.materialProvider.getTrainingPdfs();
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting pdf list in controller");
      return [];
    }
  };
  uploadMaterial = async (data) => {
    const response = await this.materialProvider.uploadMaterial(data);
    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  };

  addMaterial = async (data) => {
    const response = await this.materialProvider.addMaterial(data);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  deleteMaterial = async (data) => {
    const response = await this.materialProvider.deleteMaterial(data);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };
}
