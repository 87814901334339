import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ApplyLeaveFormContainer,
  LeaveOptionContainer,
  LeaveStatusContainer,
  LeaveStatusMsg,
  LeaveStatusMsgContainer,
  LeaveStatusTitle,
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  PopupNavBarContainerLeave,
} from "../../style/form.style";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import { Div } from "../../style/common.style";
import SliderTab from "../../componentsv2/common_components/SliderTab.js/SliderTab";
import LateLeave from "./components/LateLeave";
import LeaveLeave from "./components/LeaveLeave";
import { apileTypes } from "../../utils/mockData";
import BottomButtons from "./components/BottomButtons";
import ShowLoader from "../Loader/ShowLoader";
import { CommonImages } from "../../utils/common.images";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import AppealController from "../../controller/appealController";
import { useSnackbar } from "notistack";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { userdata } from "../../utils/common.key";
import {
  setEmployeeAppeal,
  setEmployeePendingAppeal,
} from "../../reducer/appealreduxstore";
import AppealLeave from "./components/AppealLeave";
import { Month } from "../../utils/constant";
import AttendanceController from "../../controller/attendanceController";
import { setActiveUserMonthAttendance } from "../../reducer/attendancereduxstore";

const ApplyAppeal = (props) => {
   
  useEffect(()=>{
    console.log("props-->" , props);
  } , [] )
  const { enqueueSnackbar } = useSnackbar();
  const auth = useSelector((state) => state.auth);
  const attendanceData = useSelector(
    (state) => state.attendance.active_user_month_attendance
  );
  // const appeal = useSelector((state) => state.appeal);
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [additionalData, setAdditionalData] = useState(null);
  const [Error, setError] = useState(false);
  const appealController = new AppealController();
  const [appealData, setAppealData] = useState();
  const employee = useSelector((state) => state.employee);
  const attendanceController = new AttendanceController();

  function getPopupHeaderName() {
    if (props.isEdit) {
      return "update appeal";
    }
    return "apply appeal";
  }
  function checkError() {
    if (additionalData.reason === "") {
      return "reason";
    }
    return false;
  }
  function getButtonName() {
    if (props.isEdit) {
      return "Update Appeal";
    }
    return "Apply Appeal";
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  

  const userData = getLocalStorage(userdata);

  const isAdmin = employee.employee_acess.attendance;;

  async function onUpdate() {
    // const error = checkError();
    // setError(error);

    // if (error) {
    //   return;
    // }
    setIsLoader(true);
    const data = {
      reason: additionalData.reason,
      _id: props.appealData._id,
    };

    let res = await appealController.updateEmployeeAppeal(data);
    const sucessMsg = (
      <LeaveStatusContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>Appeal Update sucessfully</LeaveStatusMsg>
      </LeaveStatusContainer>
    );

    const erroMsg = (
      <div>
        <div>error</div>
        <div>try again after some time</div>
      </div>
    );

  
    setTimeout(() => {
      setIsLoader(false);
      if (res) {
        props.onHandleClose(false);
        dispatch(setIsModalOpen(false));
        handleAlert(sucessMsg, "success");
        return;
      }
      dispatch(setIsModalOpen(false));
      props.onHandleClose(false);
      handleAlert(erroMsg, "error");
    }, 2000);

    
    const employeeId = props.forUser._id;

  

    const newResponse = await appealController.getEmployeeAppealDataById(
      employeeId
    );

    if (newResponse) {
      dispatch(setEmployeeAppeal(newResponse));
    }

    props.onHandleClose(false);

    return;
  }

  async function onSave() {
    const error = checkError();
    setError(error);

    if (error) {
      return;
    }
    setIsLoader(true);
   
    const adte_id = attendanceData.find(
      (data) => data.attendance_date === props.data.attendance_date
    );

    const data = {
      reason: additionalData.reason,
      user_id: props.data.user_id,
      attendance_id: adte_id.in_time === "00:00" ? null : adte_id.id,
      type: adte_id.in_time === "00:00" ? "ABSENT" : "LATE",
      attendance_date: props.data.attendance_date,
    };

    const res = await appealController.addEmployeeAppeal(data);

    // if(res){
    //   const year = new Date().getFullYear().toString();
    // const dataMonthOne = {
    //   user_id:
    //     props.forUser._id === undefined
    //       ? props.forUser.user_id
    //       : props.forUser._id,
    //   month: Month[props.currentMonthIndex].toLowerCase(),
    //   year: year,
    // };
    // const monthAttendance = await attendanceController.getMonthAttendance(
    //   dataMonthOne
    // );

    // dispatch(setActiveUserMonthAttendance(monthAttendance));
    // }




    const sucessMsg = (
      <LeaveStatusContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>Appeal applied sucessfully</LeaveStatusMsg>
      </LeaveStatusContainer>
    );

    const erroMsg = (
      <div>
        <div>error</div>
        <div>try again after some time</div>
      </div>
    );

    setTimeout(() => {
      setIsLoader(false);
      if (res) {
        props.onHandleClose(false);
        dispatch(setIsModalOpen(false));
        handleAlert(sucessMsg, "success");
        return;
      }
      dispatch(setIsModalOpen(false));
      props.onHandleClose(false);
      handleAlert(erroMsg, "error");
    }, 2000);

    const employeeId = props.forUser._id;
    let newResponse;
    if(isAdmin){
      newResponse= await appealController.getEmployeeAppealData();
    }else{
      newResponse = await appealController.getEmployeeAppealDataById(
      employeeId
    );
  }
    
    if (newResponse) {
      dispatch(setEmployeeAppeal(newResponse));
    }

    props.onHandleClose(false);

    return;
  }

  return (
    <React.Fragment>
      {isLoader ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Sending Apile Request</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainerLeave>
              <PopupNavBar
                onHandleClose={props.onHandleClose}
                componentType="form"
                title={getPopupHeaderName()}
              />
            </PopupNavBarContainerLeave>
          </DialogTitle>

          <DialogContent dividers={true}>
            <ApplyLeaveFormContainer>
              <Div className="mx-4 my-3">
                {/* { props.isEdit && <SliderTab
                  buttonList={apileTypes}
                  tab={activeTab}
                  onTabClick={(data) => setActiveTab(data)}
                />
               } */}

                <LeaveOptionContainer>
                  {/* <LateLeave
                    isEdit={props.isEdit}
                    activeTab={activeTab}
                    onSubmit={(e) => {
                      setAdditionalData(e); 
                    }}
                    error={Error}
                    isVisible={activeTab === 3}
                  /> */}

                  <AppealLeave
                    activeTab={activeTab}
                    onSubmit={(e) => {
                      setAdditionalData(e);
                    }}
                    date={props.data}
                    isEdit={props.isEdit}
                    error={Error}
                    setAppealData={setAppealData}
                    isExist={activeTab !== 3}
                  />
                </LeaveOptionContainer>
              </Div>
            </ApplyLeaveFormContainer>
          </DialogContent>

          <DialogActions>
            <Div>
             { 
              
           !isLoader ? ( 
             <BottomButtons
                onSave={() => {
                  props.isEdit ? onUpdate() : onSave();
                }}
                buttonName={getButtonName()}
              />) :
             (
              <div style={{ marginRight: 20 }}>
              <div class="loader"></div>
            </div>
             )
}
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ApplyAppeal;
