import React, { useEffect, useRef } from "react";

import SearchIcon from "@mui/icons-material/Search";
import {
  MainHeader,
  PlaceholderSearchPosition,
  SearchBar,
  SearchHeader,
  SearchIconContainer,
  SearchIconInnerContainer,
  SearchInnerContainer,
  SearchOuterContainer,
  TextContainerPlaceholder,
} from "../../../style/employee.style";
import CommonColor from "../../../utils/common.colors";

export default function EmployeeHeader(props) {
  const ref = useRef(0);

  useEffect(() => {
    document.addEventListener("keydown", setFocusOnInput);
    if (props.searchValue.includes("@")) {
      props.FilterEmployeesInSearch("");
    }
    return () => {
      document.removeEventListener("keydown", setFocusOnInput);
    };
  }, [props.searchValue]);

  function setFocusOnInput(event) {
    if (
      (event.keyCode === 70 && event.ctrlKey) ||
      (event.keyCode === 70 && event.metaKey)
    ) {
      //user pressed ctrl+f
      event.preventDefault();

      ref.current.focus();
    }
  }

  return (
    <React.Fragment>
      <MainHeader>
        <SearchHeader>{props.title}</SearchHeader>
        <SearchOuterContainer>
          <SearchInnerContainer>
            <SearchIconContainer>
              <SearchIconInnerContainer>
                {/* <IconifyIcon
                  icon="il:search"
                  color="white"
                  style={{ height: 18 }}
                /> */}
                <SearchIcon style={{ color: CommonColor.white }} />
              </SearchIconInnerContainer>
            </SearchIconContainer>
            <SearchBar
              ref={ref}
              value={props.searchValue}
              placeholder={props.placeholder}
              onChange={(event) =>
                props.FilterEmployeesInSearch(event.target.value)
              }
              // autoComplete="off"
            />
            <PlaceholderSearchPosition isvisible={props.searchValue.length}>
              <TextContainerPlaceholder>Ctrl+F</TextContainerPlaceholder>
            </PlaceholderSearchPosition>
          </SearchInnerContainer>
        </SearchOuterContainer>
      </MainHeader>
    </React.Fragment>
  );
}
