import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  IconifyIcon,
  SearchIconInnerContainer,
  TextContainerPlaceholder,
} from "../../../style/employee.style";
import {
  AccessNavBarContainer,
  AccessSearchContainer,
  PlaceholderSearchPositionAccess,
} from "../../../style/style.access.mgmt.style";
import {
  SearchBarTrain,
  SearchContainerNavbar,
  SearchIconContainerTrain,
} from "../../../style/training.style";
import { findKeyPressDetect } from "../../../utils/utils";

export default function AccessNavBar(props) {
  const employee = useSelector((state) => state.employee);
  const ref = useRef(0);

  useEffect(() => {
    document.addEventListener("keydown", setFocusOnInput);
    return () => {
      document.removeEventListener("keydown", setFocusOnInput);
    };
  }, [props.searchValue]);

  function setFocusOnInput(event) {
    if (findKeyPressDetect(event)) {
      event.preventDefault();
      ref.current.focus();
    }
  }

  function filterEmployeesInSearch(event) {
    const newArray = employee.employee_access_list_all.filter((ele) => {
      return ele.user_name
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    props.accessListSetter(newArray);
    props.searchValueSetter(event.target.value);
  }
  return (
    <AccessNavBarContainer>
      <AccessSearchContainer>
        <SearchContainerNavbar>
          <SearchIconContainerTrain>
            <SearchIconInnerContainer>
              <IconifyIcon icon="il:search" color="white" />
            </SearchIconInnerContainer>
          </SearchIconContainerTrain>
          <SearchBarTrain
            ref={ref}
            value={props.searchValue}
            placeholder="search"
            onChange={(event) => filterEmployeesInSearch(event)}
            autocomplete="off"
          />
        </SearchContainerNavbar>
        <PlaceholderSearchPositionAccess isvisible={props.searchValue.length}>
          <TextContainerPlaceholder>Ctrl+F</TextContainerPlaceholder>
        </PlaceholderSearchPositionAccess>
      </AccessSearchContainer>
    </AccessNavBarContainer>
  );
}
