import { createSlice } from "@reduxjs/toolkit";

export const pagetransitionstoreSlice = createSlice({
  name: "pagetrasitionstore",
  initialState: {
    employeepage: {
      ToBeOpen: false,
    },
    profilePage: {
      detailOpen: false,
      month: null,
    },
    tab: null,
  },
  reducers: {
    EmployeePopupOpener: (state, action) => {
      state.employeepage.ToBeOpen = action.payload;
    },
    AttendanceDetailOpener: (state, action) => {
      state.profilePage.detailOpen = action.payload.openState;
      state.profilePage.month = action.payload.month;
    },
    OpenTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { EmployeePopupOpener, AttendanceDetailOpener, OpenTab } =
  pagetransitionstoreSlice.actions;

export default pagetransitionstoreSlice.reducer;
