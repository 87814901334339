import { Icon } from "@iconify/react";
import { Box, Button, Chip, Grid, MenuItem, TextField } from "@mui/material";
import styled from "styled-components";
import CommonColor from "../utils/common.colors";
import {
  DatePickerCalendar,
  DeviceSizes,
  TextButton,
  VerticalScrollableArea,
  heightChangeAnimation,
} from "./common.style";
import { UserPrexeliteId } from "./employee.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";

const LeaveContainer = styled.div`
  margin-left: ${(props) => (props.ismanager ? "0%" : "2.5%")};
  display: flex;
  justify-content: ${(props) => (props.ismanager ? "" : "space-around")};
  opacity: ${(props) => (props.pageanimation ? 0 : 1)};
  margin-top: ${(props) => (props.pageanimation ? "10%" : "0%")};
  transition: opacity 0.3s ease-in-out, margin-top 0.3s ease-in-out;
  @media ${DeviceSizes.belowtablet} {
    margin-left: 16%;
    display: block;
    width: 140%;
    margin-top: 8%;
  }
  @media ${DeviceSizes.mobile} {
    width: 204%;
    margin-left: 0%;
  }
`;

const LeaveInfoContainer = styled.div`
  width: 40%;
`;

const LeaveInfo = styled.div`
  width: 49%;
  @media ${DeviceSizes.belowtablet} {
    width: 104%;
  }
`;
const LeaveInfoContentContainer = styled.div`
  display: flex;
  width: 480px;
  justify-content: space-around;
  @media ${DeviceSizes.belowtablet} {
    width: 480px;
  }
  @media ${DeviceSizes.mobile} {
    width: 47%;
  }
`;

const LeaveCountBar = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media ${DeviceSizes.bigScreen} {
    width: 100%;
  }
`;
// const LeaveInfoCardContainer = styled.div`
//   background-color: ${Theme.primary_color.white};
//   border-radius: 10px;
//   width: 100%;
// `;
const GraphMainContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.component === 1 ? "" : "5%")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft.value : "")};
  @media ${DeviceSizes.mobile} {
    transform: ${(props) =>
      props.zoom ? `scale(${props.zoom}, ${props.zoom})` : ""};
    margin-top: 0%;
  }
`;
const GraphContainer = styled.div`
  && text {
    font-size: ${FontSizes.Para};
    color: ${CommonColor.primary_color};
  }
  && svg {
    width: ${(props) => props.cicleWidth ?? "100%"};
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;

const SvgContainer = styled.svg`
  transform: rotate(270deg);
  & circle {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
    stroke: ${Theme.primary_color.avatar_border_color};
    stroke-width: 0.4em;
    stroke-linecap: round;
  }
  & circle:nth-child(1) {
  }
  & circle:nth-child(2) {
    stroke: ${Theme.primary_color.primary_color};
    stroke-dashoffset: ${(props) => props.$dashoffset};
  }
  @media ${DeviceSizes.mobile} {
    /* height: 150px;
    width: 150px; */
    align-items: center;
    justify-content: center;
    display: flex;
    & circle {
      transform: scale(0.7);
    }
  }
`;

const NumberContainer = styled.div`
  text-align: center;
`;
const LeaveNumber = styled.div`
  font-size: ${(props) => props.fontSize ?? "2.4em"};
  font-weight: bolder;
  font-family: "Mulish";

  color: ${Theme.primary_color.title_color};
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.Title};
  }
`;
const LeavesRemainingSubtitle = styled.div`
  font-size: ${(props) => props.fontSize ?? "0.9em"};
  font-weight: ${(props) => props.fontWeightText ?? "400"};
  font-family: "Mulish";
  color: ${CommonColor.primary_color};
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.Para};
    text-align: center;
  }
`;
const LeaveInfoShow = styled.div`
  margin-left: 8%;
  @media ${DeviceSizes.mobile} {
    margin-left: 3%;
    margin-top: 3%;
  }
`;
const LeaveDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  width: 140%;
  margin-top: 45%;
  margin-left: 50%;
  @media ${DeviceSizes.mobile} {
    margin-left: 0%;
    height: 35%;
    margin-top: 5%;
    justify-content: space-evenly;
  }
`;

const IndicatorLeave = styled.div`
  height: 80%;
  width: 6%;
  background-color: ${(props) =>
    props.component === "1"
      ? Theme.primary_color.avatar_border_color
      : Theme.primary_color.primary_color};
  border-radius: 20px;
  margin-right: 10%;
  @media ${DeviceSizes.mobile} {
    margin-right: 1%;
  }
`;
const LeaveInfoCardDataContainer = styled.div``;
const LeaveInfoData = styled.div`
  font-size: 1.2em;
  font-family: "Mulish";
  font-weight: bold;
  color: ${Theme.primary_color.title_color};
  display: flex;
  justify-content: ${(props) => props.horizintalFlex};
  width: 100%;

  @media ${DeviceSizes.mobile} {
    /* font-size: ${FontSizes.Para}; */
  }
`;
const LeaveInfoTitle = styled.div`
  font-size: ${(props) =>
    props.parentComponent === 1 ? FontSizes.VerySmallText : FontSizes.SubPara};
  font-family: "Mulish";
  color: ${CommonColor.primary_color};
  @media ${DeviceSizes.mobile} {
    && {
      font-size: ${FontSizes.VerySmallText};
    }
  }

  font-weight: bold;
  margin-top: 5%;
  color: ${CommonColor.primary_color};
  text-align: ${(props) => (props.component === "1" ? "left" : "right")};
  width: max-content;
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.Para};
  }
`;
const LeaveApplicationsContainer = styled.div``;

const LeaveApplicationContainer = styled.div`
  margin-top: 4%;
  background-color: ${Theme.primary_color.white};
  padding: 4%;
  border-radius: 10px;
  box-shadow: 4px 4px 77px -31px rgba(0, 0, 0, 0.25);
`;
const LeaveApplicationTitle = styled.div`
  font-family: "Mulish";

  font-weight: 700;
  color: ${Theme.primary_color.primary_color};
  font-size: ${FontSizes.Title};
  margin-top: 2%;
  margin-left: 2%;
`;
const LeaveApplicationDetails = styled.div`
  box-shadow: 0px 4.5594401359558105px 28.496501922607422px -5.699300765991211px
    #00000026;
  margin-top: 6%;
  margin-left: 2%;
  border-radius: 10px;
`;
const ApprovedButton = styled.div`
  && {
    font-family: "Mulish";

    font-weight: 700;
    font-size: ${FontSizes.Para};
    letter-spacing: 0.05em;
    color: ${Theme.primary_color.green};
    text-transform: capitalize;
    background-color: ${Theme.primary_color.success_button_background};
    padding: 2%;
    border-radius: 7px;
    &:hover {
      background-color: ${Theme.primary_color.success_button_background};
    }
  }
`;
const LeaveStatusDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeaveDaysDetail = styled.div`
  font-family: "Mulish";

  font-style: normal;
  font-weight: 600;
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
`;
const DateContainer = styled.div`
  font-family: "Mulish";

  font-weight: 400;
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
  display: flex;
  margin-top: 2%;
`;
const DateData = styled.div`
  font-weight: 700;
`;

const DateTitle = styled.div``;
const DateSeprator = styled.div`
  margin-left: ${(props) => props.sepratorLeft ?? "15px"};
  margin-right: ${(props) => props.sepratorRight ?? "15px"};
`;
const LeaveDetails = styled.div`
  padding: 4%;
`;
const DateSepratorTo = styled(DateSeprator)`
  margin-left: 33px;
  margin-top: 0px;
`;
const DividerContainer = styled.div`
  margin-top: 8%;
  margin-bottom: 4%;
  width: 100%;
`;
const NoteTitle = styled.div`
  font-family: "Mulish";

  font-weight: 600;
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
  letter-spacing: 0.05em;
`;
const NoteText = styled.div`
  font-family: "Mulish";

  font-weight: 400;
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
  margin-top: 1%;
  letter-spacing: 0.05em;
`;
const UserAttendaceProfileContainer = styled.div`
  background-color: ${Theme.primary_color.white};
  border-radius: 10px;
  height: 100%;
  padding-bottom: 10%;
  overflow-y: scroll;
  /* box-shadow: 0px 0px 17px 3px rgba(0, 0, 0, 0.1); */

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
    background-color: ${Theme.primary_color.divider_gray};
    border-radius: 3px;
    &:hover {
      background-color: ${Theme.primary_color.primary_color};
    }
  }
  @media ${DeviceSizes.belowtablet} {
    margin-top: 3%;
    height: ${(props) => (props.ismanager ? "" : "100%")};
    margin-bottom: 3%;
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin-top: 0%;
  }
`;
const UserBasicDetails = styled.div`
  width: 100%;
`;
const UserImageContainer = styled.div`
  display: flex;
  justify-content: center;
  /* display: ${(props) => (props.$isViewDetailClicked ? "none" : "flex")}; */
`;
const UserImageTag = styled.img`
  height: 110px;
  width: 110px;
  border-radius: 50%;
  padding: 1.5%;
  border-width: 2px;
  border-style: solid;
  border-color: ${Theme.primary_color.divider_gray};
  margin: 5%;
`;
const UserDetails = styled.div`
  text-align: center;
  font-family: "Mulish";

  letter-spacing: 00.05em;
  margin-top: 2%;
  margin-bottom: 7%;
`;
const UserNameDetail = styled.div`
  font-weight: 700;
  font-size: ${FontSizes.Title};
  text-transform: uppercase;
`;
const UserEmailDetail = styled.div`
  font-weight: 500;
  font-size: ${FontSizes.SubPara};
  text-transform: lowercase;
  margin-top: 1%;
`;
const UsersAttendanceContainer = styled.div`
  margin-left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${DeviceSizes.mobile} {
    margin-bottom: 7%;
  }
`;
const UserAttendanceContainer = styled.div`
  width: 80%;
  margin-left: 5%;
  box-shadow: 4px 4px 77px -31px #00000040;
  padding: 4%;
  padding-bottom: 2%;
  margin-top: 0%;
  border-radius: 15px;
  height: ${(props) => (props.$isViewDetailClicked ? "100%" : "")};
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin-left: 0;
  }
`;

const UserAttendanceAnimatedContainer = styled.div``;
const UserAttendanceHeader = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 5%;
`;

const CalendarButtons = styled(Icon)`
  display: ${(props) => (props.$isViewDetailClicked ? "block" : "none")};
`;
const UserAttendanceMonthAndyear = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-size: ${FontSizes.Para};
  font-family: "Mulish";

  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: ${Theme.primary_color.primary_color};
`;
const UserAttendanceMonth = styled.div``;
const UserAttendanceYear = styled.div`
  color: ${Theme.primary_color.title_color};
  margin-left: 5px;
`;
const AttendanceTitleSeprator = styled.div`
  display: flex;
  /* margin-top: 6%; */
  height: 1px;
  background-color: ${Theme.primary_color.divider_gray};
`;
const AttendanceDataSeprator = styled(Box)`
  display: flex;
  align-items: center;
  && {
    & div {
      height: 70px;
      width: 1px;
      background-color: ${Theme.primary_color.divider_gray};
    }
  }
`;
const AttendanceDataContainer = styled.div``;
const AttendanceContainer = styled(Box)`
  font-family: "Mulish";

  color: ${Theme.primary_color.primary_color};
  display: flex;
  justify-content: space-around;
`;
const AttendanceSingleDataContainer = styled.div`
  margin-top: 7%;
  margin-bottom: 7%;
`;
const AttendanceHeading = styled.div`
  font-weight: 400;
  font-size: ${FontSizes.SubPara};
  margin-bottom: 10%;
`;
const AttendanceData = styled.div`
  font-weight: 700;
  font-size: ${FontSizes.Subtitle};
  text-align: center;
  color: black;
`;

const ViewHideDetailButton = styled(TextButton)`
  && {
    background-color: transparent;
    text-transform: capitalize;
    color: ${Theme.primary_color.primary_color};
    font-family: "Mulish";

    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: ${FontSizes.SubPara};
    box-shadow: none;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }
`;

const ViewDetailsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
`;
const TimingTableContainer = styled.div`
  height: ${(props) => (props.$isViewDetailClicked ? "100%" : "0px")};
  width: 100%;
  overflow: hidden;
`;
const TimingsTable = styled.table`
  font-family: "Mulish";
  font-weight: 500;
  color: ${Theme.primary_color.primary_color};
  width: 100%;
  border-collapse: collapse;
`;

const TimingTableRow = styled.tr`
  border-bottom-color: ${Theme.primary_color.avatar_border_color};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  color: ${(props) => (props.islate ? Theme.primary_color.red : "")};
  justify-content: space-between;
  background-color: ${(props) =>
    props.islate
      ? Theme.primary_color.light_red
      : Theme.primary_color.transparent};
`;
const TimingTableHeader = styled.th`
  font-weight: 700;
  color: ${Theme.primary_color.title_color};
  text-align: center;
  padding: 3%;
  margin-left: ${(props) => (props.$isIn ? "8%" : "0%")};
`;

const TimingTableData = styled.td`
  text-align: right;
  padding: 3%;
`;

const UserProfileNavBarContainer = styled.div`
  width: 100%;
  position: sticky;
  height: 4%;
  top: 0;
  left: 0;
  background-color: ${Theme.primary_color.white};
  display: ${(props) => (props.$isViewDetailClicked ? "block" : "none")};
  z-index: 2;
  margin-bottom: 30%;
`;
const NavBarImagePlacer = (position) => {
  if (position === "initial") {
    return {
      top: "47.5%",
      left: "36%",
      dimension: "110px",
      padding: "7px",
    };
  } else if (position === "second") {
    return {
      top: "18.9%",
      left: "30.3%",
      dimension: "115.5px",
      padding: "3.8px",
    };
  } else if (position === "third") {
    return {
      top: "17.8%",
      left: "25.4%",
      dimension: "102px",
      padding: "3.6px",
    };
  } else if (position === "middle") {
    return {
      top: "16.7%",
      left: "20.6%",
      dimension: "98.5px",
      padding: "3.4px",
    };
  } else if (position === "fifth") {
    return {
      top: "15.6%",
      left: "15.9%",
      dimension: "85px",
      padding: "3.2px",
    };
  } else if (position === "sixth") {
    return {
      top: "14.5%",
      left: "11.2%",
      dimension: "71.5px",
      padding: "3px",
    };
  } else {
    return {
      top: "9%",
      left: "2%",
      dimension: "35px",
      padding: "2px",
    };
  }
};
function checkMidPosition(position) {
  if (
    position === "fifth" ||
    position === "sixth" ||
    position === "seventh" ||
    position === "eigth" ||
    position === "ninth" ||
    position === "tenth"
  ) {
    return true;
  }
  return false;
}

const UserProfileNavBarImage = styled.img`
  position: absolute;
  top: ${(props) => NavBarImagePlacer(props.$UserProfileImagePositioner).top};
  left: ${(props) => NavBarImagePlacer(props.$UserProfileImagePositioner).left};
  height: ${(props) =>
    NavBarImagePlacer(props.$UserProfileImagePositioner).dimension};
  width: ${(props) =>
    NavBarImagePlacer(props.$UserProfileImagePositioner).dimension};
  border-radius: 50%;
  padding: ${(props) =>
    NavBarImagePlacer(props.$UserProfileImagePositioner).padding};
  z-index: 2;
  background-color: ${Theme.primary_color.white};
  border-width: 2px;
  border-style: solid;
  border-color: ${Theme.primary_color.avatar_border_color};
  display: ${(props) => (props.$isViewDetailClicked ? "block" : "none")};
`;
const NavBarTextPlacer = (position) => {
  if (position === "initial") {
    return {
      top: "23%",
      left: "0px",
      FontSize: FontSizes.Title,
    };
  } else if (position === "second") {
    return {
      top: "23%",
      left: "3%",
      FontSize: FontSizes.Title,
    };
  } else if (position === "third") {
    return {
      top: "23%",
      left: "3%",
      FontSize: FontSizes.Subtitle,
    };
  } else if (position === "middle") {
    return {
      top: "23%",
      left: "3%",
      FontSize: FontSizes.Subtitle,
    };
  } else if (position === "fifth") {
    return {
      top: "23%",
      left: "3%",
      FontSize: FontSizes.Para,
    };
  } else if (position === "sixth") {
    return {
      top: "23%",
      left: "3%",
      FontSize: FontSizes.Para,
    };
  } else if (position === "seventh") {
    return {
      top: "23%",
      left: "3%",
      FontSize: FontSizes.Para,
    };
  } else if (position === "eigth") {
    return {
      top: "23%",
      left: "3%",
      FontSize: FontSizes.Para,
    };
  } else if (position === "ninth") {
    return {
      top: "23%",
      left: "3%",
      FontSize: FontSizes.Para,
    };
  } else if (position === "tenth") {
    return {
      top: "23%",
      left: "14%",
      FontSize: FontSizes.Para,
    };
  }
};

const UserProfileNavbarName = styled.div`
  position: absolute;
  top: ${(props) => NavBarTextPlacer(props.$UserProfileImagePositioner).top};
  left: ${(props) => NavBarTextPlacer(props.$UserProfileImagePositioner).left};
  font-family: "Mulish";

  font-weight: 700;
  font-size: ${FontSizes.Para};
  text-transform: uppercase;
  margin-bottom: 5%;
  display: ${(props) =>
    checkMidPosition(props.$UserProfileImagePositioner) ? "block" : "none"};
  transition: all 0.2s ease-in-out;
`;

const TimingTableBody = styled.tbody`
  width: 100%;
`;

const ContentContainer = styled.div``;
const ScrollerContent = styled.div``;
const VerticalScrollableAreaProfile = styled(VerticalScrollableArea)`
  background-color: ${Theme.primary_color.transparent};
  border-radius: 10px;
  background-color: ${Theme.primary_color.white};
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  & ::-webkit-scrollbar-track {
    display: none;
  }

  @media ${DeviceSizes.bigScreen} {
    margin-left: 2%;
  }
  @media ${DeviceSizes.belowtablet} {
    overflow-x: hidden;
    height: 100%;
    margin-left: 0%;
    &::-webkit-scrollbar-thumb {
      background-color: ${Theme.primary_color.transparent};
    }
    & ::-webkit-scrollbar-track {
      display: none;
    }
  }
  @media ${DeviceSizes.mobile} {
    height: 100%;
    padding-left: 5%;
  }
`;
const userPrexeliteId = styled(UserPrexeliteId)``;
const UserNavBarInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const ProfileContainer = styled.div`
  background-color: ${CommonColor.theme_background_color};
  width: 100%;
  height: ${(props) => (props.ismain ? "calc(100% - 67px)" : "100%")};
  height: ${(props) => (props.ismain ? "-webkit-calc(100% - 65px)" : "100%")};

  margin: 0;
  padding: 1%;
  display: flex;

  @media ${DeviceSizes.belowLaptop} {
    padding-top: 5%;
    padding-left: 2%;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-flow: column-reverse;
    padding: 3%;
    margin: 0;
    height: ${(props) => (props.ismain ? "calc(100% - 55px)" : "100%")};
    height: ${(props) => (props.ismain ? "-webkit-calc(100% - 55px)" : "100%")};
    & .MuiGrid-root {
      width: 100%;
    }
  }
`;

const SynergyLeagueContainer = styled.div`
  width: 96%;
  height: calc(100% - 65px);
  padding: 2%;
  border-radius: 12px;
  background-color: ${CommonColor.theme_background_color};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProfileLeaveContainer = styled.div`
  margin-top: 5%;
  pointer-events: none;
  @media ${DeviceSizes.mobile} {
    margin-top: 6%;
  }
`;

const LeaveInfoCardContainer = styled.div`
  margin-top: 4%;
`;

const ManagerProfileContainer = styled.div`
  width: 100%;
  padding: 3%;
  padding-right: 1%;
  @media ${DeviceSizes.mobile} {
    padding-right: 0%;
  }
`;
const GraphLeaveBalanceContainer = styled.div`
  padding: 1%;
  box-sizing: border-box;
`;

const NoDataComponentContainerProfile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  & svg {
    width: 80%;
  }
`;
const ProfileBg = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 50%;
  & img {
    border-radius: 10px;
    width: 101.2%;
  }
`;

const ProfileImg = styled.div`
  position: absolute;
  top: 37%;
  left: 9%;
  width: 100px;
  height: 100px;
  padding: 0.2%;
  border: 2px solid ${Theme.primary_color.primary_color};
  border-radius: 50%;
  background-color: ${Theme.primary_color.theme_background_color};
`;
const ImgComponent = styled.img`
  width: 100%;
  height: 100%;
`;
const EmployeeDetailContainer = styled.div`
  height: 52vh;
  display: flex;
  margin-left: 4%;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
`;
const NameDetail = styled(UserNameDetail)``;
const NameAndDesignationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CompanyName = styled.div``;

const EmailAndBirthday = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;
const EmailContainer = styled.div``;
const Birthday = styled.div``;

const ManagerHeader = styled.div`
  height: 100%;
  width: 100%;
  font-family: "Mulish";
  background-color: transparent;
  color: ${Theme.primary_color.white};
  background-color: ${Theme.primary_color.transparent};
`;
const ManagerHeaderDetail = styled.div`
  height: 100%;
  background-color: red;
  @media ${DeviceSizes.mobile} {
  }
`;
const Designation = styled.div`
  font-size: ${FontSizes.LargeText};
  font-weight: 700;
  line-height: 100%;
`;
const PrexeliteGreet = styled.div`
  font-size: ${FontSizes.Title};
  font-weight: 500;
  margin-bottom: 5px;
  @media ${DeviceSizes.mobile} {
  }
`;
const Quote = styled.div`
  margin-top: 20px;
  color: ${CommonColor.white};
  font-size: ${FontSizes.Para};
  width: 76%;
`;
const IconContainer = styled.div`
  margin-top: 20px;

  display: flex;
  @media ${DeviceSizes.mobile} {
  }
`;
const BgImages = styled.img`
  filter: brightness(50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const PrexeliteImages = styled.img`
  position: absolute;
  width: 200px;
  right: 50px;
  bottom: -100px;
  /* box-shadow: 0px 0px 11px 5px rgba(0, 0, 0, 0.1); */
  border-radius: 50%;
  @media ${DeviceSizes.belowtablet} {
    top: 73%;
    width: 23%;
    left: 75%;
  }
  @media ${DeviceSizes.mobile} {
    top: 71%;
    width: 34%;
    left: 63%;
  }
`;
const SocialIcon = styled(Icon)`
  font-size: 30px;
  margin-right: 3%;
  cursor: pointer;
`;
const CloseIcon = styled(Icon)`
  font-size: 32px;
  cursor: pointer;
  opacity: 0.4;
`;
const CloseIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  @media ${DeviceSizes.mobile} {
    right: 0;
  }
`;
const PrexelNameProfile = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  /* transform: rotate(-90deg); */
  font-family: "Mulish";
  font-weight: 900;
  font-size: 5em;
  color: ${Theme.primary_color.secondary_color};
  text-transform: uppercase;
`;
const DesignatePersonName = styled.span`
  text-transform: capitalize;
  color: ${Theme.primary_color.secondary_color};
  background-color: ${Theme.primary_color.light_transparent_white};
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 5px;
  @media ${DeviceSizes.mobile} {
    display: block;
    width: 89%;
    padding-bottom: 1%;
  }
`;
const ArrowButtonContainer = styled.div`
  display: flex;
  padding: 3%;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.isactive ? CommonColor.button_gray : CommonColor.button_bg};
  cursor: pointer;
`;
const ArowContainerIconShow = styled(Icon)`
  font-size: ${FontSizes.xxxl};
  opacity: 0.75;
`;
const DivContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const ArrowButtonIcon = styled(Icon)`
  font-size: ${FontSizes.xxxl};
  opacity: 0.75;
`;
const ArrowContainerText = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  margin-left: 2%;
  font-size: ${FontSizes.m};
  text-transform: capitalize;
`;
// const EmployeeImageContainer = styled.div`
//   height: 120px;
//   width: 120px;
//   position: absolute;
//   right: 80px;
//   bottom: -62px;
//   @media ${DeviceSizes.mobile} {
//     height: 100px;
//     width: 100px;
//     position: absolute;
//     right: 25px;
//     bottom: -50px;
//   }
// `;
const EmployeeImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
`;
const BgImgContainer = styled.div`
  height: 52vh;
  width: 100%;
  position: absolute;
  top: 0;
`;
const EmployeeContainer = styled.div`
  height: 52vh;
  width: 100%;
  position: relative;
`;
const OpenSocialLink = styled.a`
  text-decoration: none;
  display: block;
  margin-right: 2%;
`;
const AboutEmployeeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${CommonColor.primary_color};
  color: ${CommonColor.white};
  padding: 3%;
  padding-left: 5%;
  padding-right: 5%;
  box-shadow: 30px 4px 77px rgba(0, 0, 0, 0.15);
  padding-bottom: 3%;
  height: 100%;
  margin-left: 3%;
  height: 100%;
  overflow-y: auto;
  @media ${DeviceSizes.belowLaptop} {
    margin-left: 0%;
  }
`;
const EmployeeImageContainer = styled.div`
  height: 120px;
  width: 120px;
`;
const EmployeeNameContainer = styled.div`
  margin-top: 1%;
`;
const EmployeeName = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: ${FontSizes.xxl};
  text-transform: capitalize;
`;
const EmployeeAboutDetailContainer = styled.div``;
const AboutHeading = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  font-size: ${FontSizes.m};
  text-transform: capitalize;
  margin-top: 1%;
`;
const AboutText = styled.div`
  font-size: ${FontSizes.SubPara};
  font-weight: 500;
  color: ${CommonColor.text_gray};
  line-height: 1.2em;
  margin-top: 3%;
`;
const PersonalInfoContainer = styled.div`
  margin-top: 3%;
  width: 100%;
`;
const PersonalDetailContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.SmallText};
  font-weight: 500;
  color: ${CommonColor.white};
  margin-top: 3%;
`;
const PersonalDetailHeading = styled.div`
  color: ${CommonColor.primary_bg_light_white};
`;
const PersonalDetailInfo = styled.div`
  margin-top: 1%;
  font-size: ${FontSizes.Para};
`;
const EmployeeBasicsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${CommonColor.white};
  margin-top: 3%;
`;
const DividerColored = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.color ?? CommonColor.white};
`;
const EmployeeEmail = styled.div`
  text-transform: lowercase;
  font-size: ${FontSizes.SubPara};
  color: ${CommonColor.white};
`;
const EmployeeBasicText = styled.div`
  margin-top: 1%;
  text-align: center;
`;
const PersonalInfoHeading = styled.div`
  font-size: ${FontSizes.SubPara};
  margin-top: 5%;
`;
const ProfileDetailNavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 1%;

  && input {
    height: 17px;
    cursor: pointer;
    padding-top: 0.9em;
    padding-bottom: 0.6em;
  }
  & .MuiInputAdornment-positionStart {
    max-height: 100%;
    height: 33px;
  }
  & .MuiOutlinedInput-root {
    border-radius: 6px;
    width: 68%;
    margin-left: 25%;
    cursor: pointer;
  }

  @media ${DeviceSizes.mobile} {
    justify-content: space-between;
  }
`;
const NavBarPageTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  cursor: pointer;
  width: 100%;
  overflow: auto;
  margin-right: 2%;

  &::-webkit-scrollbar {
    display: none;
  }
  @media ${DeviceSizes.mobile} {
    margin-bottom: 8%;

    width: 100%;
    justify-content: space-between;
    margin-bottom: 2%;
    overflow: auto;
  }
`;
const ProfileNavBarButton = styled.div`
  height: 22px;

  font-family: "Mulish";
  font-weight: 700;
  font-size: ${FontSizes.Para};
  color: ${(props) =>
    props.isactive ? CommonColor.secondary_color : CommonColor.primary_color};
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  text-align: center;
  /* width: max-content; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* font-size-adjust: 0.5; */
  width: max-content;
  @media ${DeviceSizes.mobile} {
    text-align: center;
  }
`;
const ProfileDetailContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  flex-direction: column;
  width: 100%;
  box-shadow: 4px 4px 77px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  height: 100%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;

  @media ${DeviceSizes.belowtablet} {
    justify-content: flex-start;
  }
  @media ${DeviceSizes.mobile} {
    margin-top: 3%;
  }
`;
const DatePickerCalendarProfile = styled(DatePickerCalendar)`
  width: 100%;
  cursor: pointer;
  & .only.rmdp-month-picker {
    height: 200px;
    width: 180px;
  }
`;

const AttendancesTable = styled.div`
  width: 100%;
  border-radius: 15px;
  border-collapse: collapse;
`;
const AttendanceTableBody = styled.div`
  vertical-align: middle;
  & div:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  & div:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
const AttendanceTableContainer = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;
  /* min-height: 65vh; */
  width: 100%;
  padding-right: 1%;

  @media ${DeviceSizes.mobile} {
    height: 100%;
  }
`;
const AttendanceTableHead = styled.div`
  vertical-align: middle;
  border-radius: 4px;
  position: sticky;
  top: 0;
  left: 0;
`;

const AttendanceTableRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) =>
    props.th === 1 ? CommonColor.table_head_bg : ""};
  border-radius: ${(props) => (props.th === 1 ? "4px" : "")};
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-bottom: 1px solid ${CommonColor.input_border};
  @media ${DeviceSizes.mobile} {
    /* background-color: ${(props) =>
      props.islate === "Absent"
        ? CommonColor.declined_status_background
        : props.islate === "Late"
        ? CommonColor.light_orange
        : CommonColor.success_button_background}; */
  }
  color: ${(props) =>
    props.islate === "Absent"
      ? CommonColor.red
      : props.islate === "Late"
      ? CommonColor.secondary_color
      : CommonColor.green};
`;
const AttendanceTableHeader = styled.div`
  vertical-align: middle;
  text-align: left;
  font-weight: 600;
  font-size: ${FontSizes.Para};
  color: ${CommonColor.primary_color};
  text-transform: capitalize;
  width: 60%;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;
const AttendanceTableData = styled.div`
  vertical-align: middle;
  font-weight: 700;
  font-size: ${FontSizes.Para};
  width: ${(props) => props.width ?? "100%"};
  color: ${CommonColor.primary_color};
`;
const ProfileDetailTabs = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const AttendanceStatusDiv = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.color.bgColor};
  color: ${(props) => props.color.textColor};
  text-align: center;
  width: 33%;
  font-size: ${FontSizes.SubPara};
  font-weight: 600;
  padding-top: 1%;
  padding-bottom: 2%;
`;
const OptionIcon = styled(Icon)`
  /* border: 1px solid ${CommonColor.input_border}; */
  border-radius: 50%;
  color: ${CommonColor.grey};
  font-size: ${FontSizes.xl};
  font-weight: 100;
  cursor: pointer;
`;
const CalendarInput = styled(TextField)`
  & input {
    height: 15px;
    font-size: ${FontSizes.SubPara};
    font-weight: 600;
    color: ${CommonColor.primary_color};
    width: 100%;
  }
  & ::placeholder {
    font-size: ${FontSizes.Para};
  }
  & .MuiOutlinedInput-root {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  &
    .css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: ${CommonColor.primary_color};
  }
  @media ${DeviceSizes.mobile} {
    &.MuiFormControl-root {
      width: 100%;
    }
  }
`;
const ProfileLeaveInfoContainer = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;

  & .MuiGrid-root {
    padding: 0;
    margin: 0;
    width: 100%;
    overflow-y: auto;

    /* height: unset; */
  }
`;
const LeaveListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const LeaveListHeading = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  font-size: ${FontSizes.para};
  width: 100%;
  text-align: left;
  padding-left: 5%;
`;
const LeaveListInnerContainer = styled.div`
  width: 100%;
  margin-top: 1%;
  height: 100%;
  overflow: auto;
  padding: 4%;
  padding-top: 0%;
  @media ${DeviceSizes.mobile} {
    height: 100%;
  }
`;

const ProfileTabContainer = styled.div`
  display: ${(props) => (props.isvisible === 1 ? "" : "none")};
  /* height: 100%; */
  animation-name: ${heightChangeAnimation};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;
const OptionList = styled(MenuItem)`
  && {
    font-family: "Mulish";
    font-weight: 600;
    font-size: ${FontSizes.SubPara};
    color: ${CommonColor.primary_color};
    display: ${(props) => (props.isvisible === 1 ? "" : "none")};
  }
`;

const SalaryDetailsContainer = styled.div`
  width: 100%;
`;
const BankDetailsContainer = styled.div``;
const BankDetailTitle = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: ${FontSizes.Para};
  color: ${CommonColor.primary_color};
  text-transform: capitalize;
  margin-bottom: 1%;
`;
const BankDetailDataWrapper = styled.div``;
const BankDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  min-height: 40px;
  border-color: ${CommonColor.button_gray};
  margin-bottom: 1%;
`;
const BankDatakey = styled(BankDetailTitle)`
  font-weight: ${(props) => props.fontWeight ?? "500"};
  text-transform: none;
`;
const BankDataValue = styled(BankDetailTitle)`
  font-weight: 500;
`;
const BankDataValueInitial = styled(BankDetailTitle)`
  font-weight: ${(props) => props.fontWeight ?? "500"};
`;
const BankDataValueFinal = styled(BankDetailTitle)`
  font-weight: ${(props) => props.fontWeight ?? "500"};
`;
const SalaryDetailsWrapper = styled.div`
  padding-right: 2%;
  width: 100%;
`;
const PaySlipInformationWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  overflow: hidden;
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;
const PdfIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
const SalaryInfoContainer = styled(Grid)`
  padding-right: 2%;
  height: 100%;
  & .MuiGrid-root:first-child {
    margin-right: 1%;
  }
`;
const EmployeeContactField = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 600;
  color: ${CommonColor.primary_color};
  width: 100%;
  background-color: ${CommonColor.transparent};
  text-align: right;
  border-radius: 10px;
`;
const ContactTextArea = styled.textarea`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 600;
  color: ${CommonColor.primary_color};
  min-height: 40px;
  height: auto;
  resize: none;
  width: 100%;
  padding-left: 2%;
  border-color: ${CommonColor.border_light};
  border: none;
  outline: ${(props) =>
    props.isEdit ? `1px solid ${CommonColor.primary_color}` : "none"};
  background-color: ${(props) =>
    props.isEdit ? CommonColor.white : CommonColor.transparent};
  text-align: ${(props) => (props.isEdit ? "left" : "right")};
  border-radius: 10px;
`;
const TabTitle = styled(BankDetailTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const EmployeeContactContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-right: 1%;

  @media ${DeviceSizes.mobile} {
    margin-right: 0;
    margin-top: 7%;
    height: max-content;
  }
`;
const AboutTabContainer = styled(Grid)`
  height: 100%;
  width: 100%;
  & .MuiGrid-root {
    height: 100%;
  }
`;
const EmployeeContactFieldWrappper = styled.div`
  width: 60%;
`;
const EditButton = styled(Button)`
  && {
    color: ${CommonColor.primary_color};
    font-family: "Mulish";
    font-size: ${FontSizes.Para};
    font-weight: 600;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    border-color: ${CommonColor.primary_color};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const IconButton = styled(Button)`
  && {
    color: ${CommonColor.primary_color};
    font-family: "Mulish";
    font-size: ${FontSizes.Para};
    font-weight: 600;
    display: flex;
    height: 35px;
    /* justify-content: space-around; */
    align-items: center;
    cursor: pointer;
    text-transform: capitalize;
    border-color: ${CommonColor.primary_color};
    height: 28px;
    width: 100px;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const ContactWrapper = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
  padding-right: 2%;
  padding-top: 2%;
  position: relative;
  @media ${DeviceSizes.mobile} {
    height: 100%;
    overflow: unset;
  }
`;
const AccessManagementButtonContainer = styled.div`
  margin-bottom: 1%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => (props.isvisible === 1 ? "scale(1)" : "scale(0)")};
  transform-origin: bottom center;
  transition: transform 0.5s ease-in-out;
`;
const ContactDataEditButton = styled.div`
  font-family: "Mulish";
  font-weight: 600;
  color: ${CommonColor.secondary_color};
  display: ${(props) => (props.isvisible === 1 ? "block" : "none")};
  &:hover {
    text-decoration: underline;
  }
`;
const ProfileTabTitleContainer = styled.div`
  text-align: center;
  margin-right: 2.5%;
  @media ${DeviceSizes.mobile} {
    margin-right: 6%;
  }
`;
const ProfileActiveMarker = styled.div`
  height: 2px;
  background-color: ${(props) =>
    props.isvisible === 1
      ? CommonColor.secondary_color
      : CommonColor.transparent};
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  transform: ${(props) => (props.isvisible ? "scaleX(1)" : "scaleX(0)")};
  transform-origin: center center;
  transition: transform 0.5s ease-in-out;
`;
const MobileTabContainer = styled.div`
  display: none;
  & .MuiTabs-indicator {
    display: none;
  }
  & .MuiTabs-root {
    min-height: 30px;
    margin-bottom: 2%;
  }
  /* & .MuiTabs-scroller {
    width: 152px;
  } */
  @media ${DeviceSizes.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const DownloadPdfLink = styled.a`
  font-family: "Mulish";
  font-weight: 600;
  color: ${CommonColor.white};
  font-size: ${FontSizes.Para};
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding-left: 5%;
  padding-right: 5%;
  background-color: ${CommonColor.primary_color};
  border-radius: 10px;
  display: none;
  @media ${DeviceSizes.mobile} {
    display: flex;
  }
`;
const LeaveBalanceContainerProfile = styled.div`
  margin-right: 1%;
  & svg {
    width: 100%;
  }
  @media ${DeviceSizes.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const MonthTableContainer = styled.div`
  width: 100%;
`;
const LeaveCreditTable = styled.div`
  width: 100%;
  border-collapse: collapse;
  & div:nth-child(odd) {
    background-color: ${CommonColor.theme_background_color};
  }
  & div:nth-child(even) {
    background-color: ${CommonColor.white};
  }
  && div div {
    background-color: ${CommonColor.transparent};
  }
`;
const LeaveCreditTableHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-weight: 600;
  color: ${CommonColor.secondary_color};
`;
const LeaveCreditTableElement = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  border-right: 1px solid ${CommonColor.divider_gray};
  height: 100%;
  padding-left: 2%;
  padding-right: 2%;
`;
const LeaveCreditTableBody = styled(LeaveCreditTableHeader)`
  font-weight: 500;
  color: ${CommonColor.primary_color};
`;
const LeaveCreditTableElementValue = styled(LeaveCreditTableElement)`
  font-size: 14px;
  justify-content: flex-end;
  border-left: 1px solid ${CommonColor.divider_gray};
  border-right: none;
`;
const LeaveCreditBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px;
  font-weight: 700;
`;
const IconDeleteLeaveCredit = styled.div`
  cursor: pointer;
`;
const ProfileDetailNavbarTabContainer = styled.div`
  margin-left: 0;
  width: ${(props) => props.width};
`;
const ProfileShimmerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  & .shimmer-table-row {
    padding: 2% 0;
  }
  & div {
    width: 100%;
  }
  & .shimmer-table-row > .shimmer {
    margin: 0 6px;
  }
  @media ${DeviceSizes.mobile} {
    & .shimmer-table-row {
      padding: 3% 0;
    }
  }
`;
const GameListContainer = styled.div`
  width: 100%;
  background-color: ${CommonColor.white};
  border-radius: 12px;
  padding: 5%;
  margin: 1%;
  margin-top: 0;
`;
const About = styled.p`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 600;
  color: ${CommonColor.white};
`;
const ReadMoreText = styled.span`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 600;
  color: ${CommonColor.secondary_color};
  cursor: pointer;
`;
const ProfileV2Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(14, 1fr);

  border-spacing: 0;
  border-left: 2px solid ${CommonColor.white};
  /* border-bottom: 2px solid ${CommonColor.white}; */

  width: 100%;
  height: 100%;
`;
const AppBarContainer = styled.div`
  grid-row: span 1;
  grid-column: span 12;
`;
const ProfileUserContainer = styled.div`
  background-color: ${CommonColor.white};
  padding: 5px;
  grid-row: span 4;
  grid-column: span 3;
  @media ${DeviceSizes.belowtablet} {
    grid-column: span 12;
    grid-row: span 3;
  }
`;
const StatusBar = styled.progress`
  width: 70%;
  border-radius: 4px;
  height: 5px;
  /* border: 1px solid ${CommonColor.dark}; */
  &::-webkit-progress-value {
    background-color: ${CommonColor.primary_color};
    border-radius: 4px;
  }
  &::-webkit-progress-bar {
    background-color: ${CommonColor.white};
    border-radius: 4px;
    transition: width 0.5s ease-in-out;
  }
`;
const ProfileFastDetails = styled.div`
  background-color: ${CommonColor.red_light_0};
  border-radius: 4px;
  grid-row: span 4;
  grid-column: span 9;
  /* padding: 2%; */
  /* border: 1px solid ${CommonColor.primary_color}; */
  @media ${DeviceSizes.belowtablet} {
    grid-column: span 12;
    grid-row: span 5;
  }
`;
const ProfileTables = styled.div`
  width: 100%;
  height: 100%;
  grid-row: span 8;
  grid-column: span 12;
  box-shadow: rgba(0, 0, 0, 0.15) 4px 4px 77px;
  @media ${DeviceSizes.mobile} {
    grid-row: span 9;
    box-shadow: none;
  }
`;
const ProfileScreenWrappper = styled.div`
  display: grid;
  grid-row: span 13;
  grid-column: span 12;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-row-gap: 3%;
  grid-column-gap: 10px;
  margin: 1% 2% 1% 2%;
`;
const AttendanceRowContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  height: 100%;
  /* grid-row-gap: 12px;
  grid-column-gap: 12px; */
  height: 100%;
  overflow: auto;
`;

const AppealAttendanceRowContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  height: 100%;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  height: 100%;
  overflow: auto;
  @media ${DeviceSizes.mobile} {
    grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  }
`;
const AttendanceRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  grid-row: span 3;
  grid-column: span 2;
  box-shadow: 0px 0px 34px -14px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 2%;
  border-radius: 4px;
  position: relative;
  @media ${DeviceSizes.belowtablet} {
    grid-column: span 3;
    grid-row: span 1;
  }
  @media ${DeviceSizes.mobile} {
    grid-column: span 5;
    grid-row: span 1;
  }
`;
const ChipAttendanceStatus = styled(Chip)`
  && {
    font-family: "Mulish";
    font-size: ${FontSizes.SmallText};
    font-weight: 500;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
      props.chipcolor == "error"
        ? CommonColor.red_light_0
        : props.chipcolor == "success"
        ? CommonColor.green
        : CommonColor.secondary_color};
    color: ${(props) =>
      props.chipcolor == "error"
        ? CommonColor.red_light_0
        : props.chipcolor == "success"
        ? CommonColor.green
        : CommonColor.secondary_color};
  }
`;
const ProfileV3Container = styled.div`
  grid-row: span 4;
  grid-column: span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  position: relative;
  height: 200px;
  @media ${DeviceSizes.mobile} {
    height: unset;
    grid-row: span 3;
  }
`;
const ProfileBannerContainer = styled.div`
  grid-row: span 6;
  grid-column: span 12;
  background-color: ${CommonColor.primary_color};
  overflow: hidden;
  display: flex;
  justify-content: end;
  @media ${DeviceSizes.mobile} {
    grid-row: span 5;
  }
`;
const ProfileBgLineContainer = styled.div`
  height: 500px;
  width: 100px;
  rotate: 350deg;
  margin-top: -10%;
  background-color: ${CommonColor.transparent};
  display: flex;
  justify-content: space-around;
`;
const ProfileShinyLine = styled.div`
  width: 3px;
  height: 100%;
  box-shadow: 0px 0px 23px 3px white;
  background-color: ${CommonColor.white};
`;
const ProfileImageCotnainer = styled.div`
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 17px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 50%;
  border: 2px solid ${CommonColor.white};
  z-index: 2;
  margin-top: 3vh;
  box-shadow: inset 0px 0px 17px 5px rgba(0, 0, 0, 0.1);
`;
const UserBasicDetailsV3 = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const UserContactDetails = styled.div`
  grid-column: 4/13;
  grid-row: span 6;
  display: flex;
  align-items: center;
  @media ${DeviceSizes.mobile} {
    grid-column: 1 / 13;
    margin-top: 25%;
    grid-row: span 13;
    display: none;
  }
`;
const ProfileBasicDetailsV3 = styled.div`
  position: absolute;
  height: 100%;
  width: max-content;
  background-color: ${CommonColor.transparent};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  @media ${DeviceSizes.mobile} {
    left: 13px;
    margin-top: 0;
    margin: auto;
  }
`;
const AttendanceOptions = styled.div`
  position: absolute;
  top: 8px;
  right: 10%;
`;
export {
  AttendanceOptions,
  ProfileBasicDetailsV3,
  UserContactDetails,
  UserBasicDetailsV3,
  ProfileImageCotnainer,
  ProfileShinyLine,
  ProfileBgLineContainer,
  ProfileBannerContainer,
  ProfileV3Container,
  ChipAttendanceStatus,
  About,
  AboutEmployeeContainer,
  AboutHeading,
  AboutTabContainer,
  AboutText,
  AccessManagementButtonContainer,
  AppBarContainer,
  ApprovedButton,
  ArowContainerIconShow,
  ArrowButtonContainer,
  ArrowButtonIcon,
  ArrowContainerText,
  AttendanceContainer,
  AttendanceData,
  AttendanceDataContainer,
  AttendanceDataSeprator,
  AttendanceHeading,
  AttendanceRow,
  AttendanceRowContainer,
  AttendanceSingleDataContainer,
  AttendanceStatusDiv,
  AttendanceTableBody,
  AttendanceTableContainer,
  AttendanceTableData,
  AttendanceTableHead,
  AttendanceTableHeader,
  AttendanceTableRow,
  AttendanceTitleSeprator,
  AttendancesTable,
  BankDataContainer,
  BankDataValue,
  BankDataValueFinal,
  BankDataValueInitial,
  BankDatakey,
  BankDetailDataWrapper,
  BankDetailTitle,
  BankDetailsContainer,
  BgImages,
  BgImgContainer,
  Birthday,
  CalendarButtons,
  CalendarInput,
  CloseIcon,
  CloseIconContainer,
  CompanyName,
  ContactDataEditButton,
  ContactTextArea,
  ContactWrapper,
  ContentContainer,
  DateContainer,
  DateData,
  DatePickerCalendarProfile,
  DateSeprator,
  DateSepratorTo,
  DateTitle,
  DesignatePersonName,
  Designation,
  DivContainer,
  DividerColored,
  DividerContainer,
  DownloadPdfLink,
  EditButton,
  EmailAndBirthday,
  EmailContainer,
  EmployeeAboutDetailContainer,
  EmployeeBasicText,
  EmployeeBasicsContainer,
  EmployeeContactContainer,
  EmployeeContactField,
  EmployeeContactFieldWrappper,
  EmployeeContainer,
  EmployeeDetailContainer,
  EmployeeEmail,
  EmployeeImage,
  EmployeeImageContainer,
  EmployeeName,
  EmployeeNameContainer,
  GameListContainer,
  GraphContainer,
  GraphLeaveBalanceContainer,
  GraphMainContainer,
  IconButton,
  IconContainer,
  IconDeleteLeaveCredit,
  ImgComponent,
  IndicatorLeave,
  LeaveApplicationContainer,
  LeaveApplicationDetails,
  LeaveApplicationTitle,
  LeaveApplicationsContainer,
  LeaveBalanceContainerProfile,
  LeaveContainer,
  LeaveCountBar,
  LeaveCreditBalance,
  LeaveCreditTable,
  LeaveCreditTableBody,
  LeaveCreditTableElement,
  LeaveCreditTableElementValue,
  LeaveCreditTableHeader,
  LeaveDataContainer,
  LeaveDaysDetail,
  LeaveDetails,
  LeaveInfo,
  LeaveInfoCardContainer,
  LeaveInfoCardDataContainer,
  LeaveInfoContainer,
  LeaveInfoContentContainer,
  LeaveInfoData,
  LeaveInfoShow,
  LeaveInfoTitle,
  LeaveListContainer,
  LeaveListHeading,
  LeaveListInnerContainer,
  LeaveNumber,
  LeaveStatusDetail,
  LeavesRemainingSubtitle,
  ManagerHeader,
  ManagerHeaderDetail,
  ManagerProfileContainer,
  MobileTabContainer,
  MonthTableContainer,
  NameAndDesignationContainer,
  NameDetail,
  NavBarPageTitleContainer,
  NoDataComponentContainerProfile,
  NoteText,
  NoteTitle,
  NumberContainer,
  OpenSocialLink,
  OptionIcon,
  OptionList,
  PaySlipInformationWrapper,
  PdfIFrame,
  PersonalDetailContainer,
  PersonalDetailHeading,
  PersonalDetailInfo,
  PersonalInfoContainer,
  PersonalInfoHeading,
  PrexelNameProfile,
  PrexeliteGreet,
  PrexeliteImages,
  ProfileActiveMarker,
  ProfileBg,
  ProfileContainer,
  ProfileDetailContainer,
  ProfileDetailNavBarContainer,
  ProfileDetailNavbarTabContainer,
  ProfileDetailTabs,
  ProfileFastDetails,
  ProfileImg,
  ProfileLeaveContainer,
  ProfileLeaveInfoContainer,
  ProfileNavBarButton,
  ProfileScreenWrappper,
  ProfileShimmerContainer,
  ProfileTabContainer,
  ProfileTabTitleContainer,
  ProfileTables,
  ProfileUserContainer,
  ProfileV2Container,
  Quote,
  ReadMoreText,
  SalaryDetailsContainer,
  SalaryDetailsWrapper,
  SalaryInfoContainer,
  ScrollerContent,
  SocialIcon,
  StatusBar,
  SvgContainer,
  SynergyLeagueContainer,
  TabTitle,
  TimingTableBody,
  TimingTableContainer,
  TimingTableData,
  TimingTableHeader,
  TimingTableRow,
  TimingsTable,
  UserAttendaceProfileContainer,
  UserAttendanceAnimatedContainer,
  UserAttendanceContainer,
  UserAttendanceHeader,
  UserAttendanceMonth,
  UserAttendanceMonthAndyear,
  UserAttendanceYear,
  UserBasicDetails,
  UserDetails,
  UserEmailDetail,
  UserImageContainer,
  UserImageTag,
  UserNameDetail,
  UserNavBarInnerContainer,
  UserProfileNavBarContainer,
  UserProfileNavBarImage,
  UserProfileNavbarName,
  UsersAttendanceContainer,
  VerticalScrollableAreaProfile,
  ViewDetailsButtonContainer,
  ViewHideDetailButton,
  userPrexeliteId,
  AppealAttendanceRowContainer,
};
