import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployeeController from "../../controller/EmployeeController";
import HolidayController from "../../controller/HolidayController";
import LeaveController from "../../controller/LeaveController";
import AttendanceController from "../../controller/attendanceController";
import EventController from "../../controller/eventController";
import MaterialController from "../../controller/materialController";

import {
  attendanceMonth,
  monthBasicAttendanceDetail,
  setDailyAttendance,
  setShift,
} from "../../reducer/attendancereduxstore";
import { updateEmployeeData } from "../../reducer/authreduxstore";
import {
  clearState,
  setActiveDrawer,
  setScreenIndexList,
  setintialDrawerActiveIndex,
} from "../../reducer/dashboardreduxstore";
import {
  setCurrentWeekBirthday,
  setEmployeeAccessList,
  setEmployeeAccessListAll,
  setEmployeeBirthdayList,
  setTopTypers,
  setUpcomingBirthday,
  storeEmployeeImages,
  storeEmployeeList,
} from "../../reducer/employeereduxstore";
import { getCurrentDayEvents } from "../../reducer/eventreduxstore";
import {
  setEmployeeLeave,
  setLeaveHistoryData,
  updateEmployeeLeaveDatas,
  updateLeaveNumbers,
  updatePendingEmployeeLeaveData,
  updatePendingEmployeeLeaveDatas,
} from "../../reducer/leavereduxstore";
import { setPdfMaterial } from "../../reducer/materialreduxstore";
import {
  deleteLocalStorageItem,
  getLocalStorage,
} from "../../services/LocalStorage/LocalStorage";
import { userdata } from "../../utils/common.key";
import { Month, level100 } from "../../utils/constant";
import {
  formatDate,
  getActiveDrawerData,
  getMonthBasicDetail,
  getPendingLeaves,
  getScreenStatus,
  getUTCDate,
  sortEmployeeListByBirthdayAndMonth,
} from "../../utils/utils";

import Box from "@mui/material/Box";
import Screens from "./Screens";

import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";

import { ListItem, ListItemButton, ListItemIcon, Tooltip } from "@mui/material";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import ConfirmationMenu from "../../component/Popup/ConfirmationMenu";
import ModalPopup from "../../component/Popup/ModalPopup";
import { useWindowSize } from "../../component/commonComponents/hooks/getWindowDimension";
import DrawerContent from "../../component/drawer/DrawerContent";
import DrawerHeaderContainer from "../../component/drawer/DrawerHeaderContainer";
import SynergyLeagueController from "../../controller/SynergyLeagueController";
import TrackerController from "../../controller/TrackerController";
import AccessController from "../../controller/accessController";
import AppealController from "../../controller/appealController";
import AssetController from "../../controller/assetController";
import PolicyController from "../../controller/PolicyController";
import {
  setEmployeeAppeal,
  setEmployeePendingAppeal,
} from "../../reducer/appealreduxstore";
import { setAssetData } from "../../reducer/assetreduxstore";
import { updateApiToken } from "../../reducer/authreduxstore";
import { setAttendanceStatus } from "../../reducer/dashboardreduxstore";
import {
  setHolidayList,
  setHolidayListByMonth,
  setPrexelMemories,
} from "../../reducer/holidayreduxstore";
import {
  setPointsTable,
  setRecentMatches,
  setSports,
  setSubTeamsAll,
  setSubTeamsNames,
  setTeams,
  setUpcomingSports,
} from "../../reducer/synergyleaguereduxstore";
import { setPolicy } from "../../reducer/policyreduxstore";
import {
  setWorkFromHomeData,
  setWorkFromHomeDetails,
} from "../../reducer/workfromhomereduxstore";

import { FlexContainer } from "../../style/asset.style";
import {
  AddViewAllButtonContainer,
  LogoutListItemContainer,
  NoDataContainer,
  TypoGraphy,
} from "../../style/common.style";
import {
  DividnLineContainer,
  DrawerContentContainer,
  DrawerItem,
  DrawerMenuText,
  DrawerText,
  DrawerWrapper,
  IconifyIcon,
  ListItemStyle,
  sideMenuIconStyle,
} from "../../style/drawer.style";
import {
  AppContainer,
  MainLoaderContainer,
  MainScreenNoData,
  ScreenContainer,
} from "../../style/main.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { api_token_key } from "../../utils/common.key";
import { CommonString } from "../../utils/common.string";
import WorkFromHomeController from "../../controller/workFromHomeController";

export default function MainScreen(props) {
  const dashboard = useSelector((state) => state.dashboard);
  const employeeAcess = useSelector((state) => state.employee.employee_acess);
  const attendance = useSelector((state) => state.attendance);
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const windowWidth = useWindowSize().width;
  const auth = useSelector((state) => state.auth);

  const attendanceController = new AttendanceController();
  const employeeController = new EmployeeController();
  const eventController = new EventController();
  const materialController = new MaterialController();
  const leaveController = new LeaveController();
  const holidayController = new HolidayController();
  const assetController = new AssetController();
  const trackerController = new TrackerController();
  const accessController = new AccessController();
  const synergyLeagueController = new SynergyLeagueController();
  const appealController = new AppealController();
  const policyController = new PolicyController();
  const [open, setOpen] = useState(windowWidth < 600 ? false : true);
  const [LoadingFlag, setLoadingFlag] = useState(true);
  const [mainLoader, setMainLoader] = useState(true);
  const [noData, setNoData] = useState(false);
  const [openToolTip, setOpenTooltip] = useState(null);
  const [openLogoutPopup, setOpenLogoutPopup] = useState(false);
  const [loadedContent, setLoadedContent] = useState([]);
  const [loadedPages, setLoadedPages] = useState([]);
  const workFromHomeController = new WorkFromHomeController();

  // const employee_list = useSelector((state) => state.employee.employee_list);
  const employee = useSelector((state) => state.employee);

  useEffect(() => {
    const userData = initializer();
    if (!userData) {
      history.push("/");

      return;
    }
    getLoginData();
    getLeaveNumbers();
    getData(userData);
  }, []);

  async function getWFH() {
    const uid = getLocalStorage(userdata)?.user._id;

    const userData = getLocalStorage(userdata);

    getDashboardScreenData(userData);

    let woelFromHomeData;
    if (employeeAcess.attendance) {
      woelFromHomeData = await workFromHomeController.getWorkFromHomeData();
      dispatch(setWorkFromHomeData(woelFromHomeData));
    } else {
      woelFromHomeData =
        await workFromHomeController.getWorkFromHomePersonalDetails({
          user_id: uid,
        });
      if (woelFromHomeData.status === 200) {
        dispatch(setWorkFromHomeData(woelFromHomeData.wfh_data));
        dispatch(
          setWorkFromHomeDetails({
            message: woelFromHomeData.message,
            remaining_wfh_days: woelFromHomeData.remaining_wfh_days,
            used_wfh_days: woelFromHomeData.used_wfh_days,
            wfh_available_days: woelFromHomeData.wfh_available_days,
          })
        );
      }
    }
  }
  useEffect(() => {
    if (!location.pathname.includes("/home")) return;
    const data = getActiveDrawerData(employeeAcess);

    const keyAndPathObj = getScreenStatus(
      data,
      location.pathname,
      dashboard.active_drawer_key
    );

    if (!keyAndPathObj) return;
    dispatch(setActiveDrawer(keyAndPathObj));
  }, [location]);

  // appeal data

  async function getAppealData() {
    if (auth.employee_data === "init") return;

    const userData = getLocalStorage(userdata);

    await getDashboardScreenData(userData);

    let data;
    let isAdmin = false;
    isAdmin = employee.employee_acess.attendance;

    if (isAdmin) {
      data = await appealController.getEmployeeAppealData();

      if (data !== null) {
        dispatch(setEmployeeAppeal(data));

        const pendingData = data.filter((dat) => dat.status === "PENDING");

        dispatch(setEmployeePendingAppeal(pendingData));
      }
    } else {
      const user_id = getLocalStorage(userdata).user._id;

      data = await appealController.getEmployeeAppealDataById(user_id);

      if (data !== null) {
        dispatch(setEmployeeAppeal(data));

        const pendingData = data.filter((dat) => dat.status === "PENDING");

        dispatch(setEmployeePendingAppeal(pendingData));
      }
    }
  }

  async function getLeaveNumbers() {
    if (auth.employee_data === "init") return;
    const sendData = {
      recruiter_id: auth.employee_data.user._id,
      month: moment().month() + 1,
      year: moment().year(),
    };

    const data = await leaveController.getLeaveNumbers(sendData);

    if (data !== null) {
      dispatch(updateLeaveNumbers(data));
      let tempArray = loadedContent;
      tempArray.push("leave number");
      setLoadedContent(tempArray);
    }
  }

  async function getData(userData) {
    setMainLoader(true);
    const access = await getAccess(userData);
    let tempArray = loadedContent;
    tempArray.push("user access");
    setLoadedContent(tempArray);
    setMainLoader(false);

    if (!access[0].training_material) {
      setNoData(true);
    }

    // await getAllData(userData);
    return access;
  }

  function initializer() {
    const userData = getLocalStorage(userdata);
    if (userData === null) {
      history.push("/");
      return;
    }

    dispatch(setintialDrawerActiveIndex(employeeAcess));
    dispatch(setScreenIndexList([0, 1, 2, 3, 4, 5, 6]));
    dispatch(updateEmployeeData(userData));
    return userData;
  }
  async function getAccess(userData) {
    const respData = await accessController.getAccessById({
      user_id: userData.user._id,
    });
    const response =
      await workFromHomeController.getWorkFromHomePersonalDetails({
        user_id: userData.user._id,
      });

    if (respData) {
      if (response) {
        respData[0].wfh_screen = response.status === 200;
      }
      
      dispatch(setEmployeeAccessList(respData[0]));
    }
    return respData;
  }
  async function getTypingData() {
    const TopTypers = await trackerController.getTopTypers();
    if (TopTypers) {
      const sortedTypingList = TopTypers.sort(compareTypingList);
      dispatch(setTopTypers(sortedTypingList));
      let tempArray = loadedContent;
      tempArray.push("speedy prexelite");
      setLoadedContent(tempArray);
    }
  }
  async function getEmployeeList() {
    const employeeList = await employeeController.getEmployeeList();
    const newEmployeeList = employeeList.map((employee) => {
      return {
        ...employee,
        id: employee.employee_id,
      };
    });
    let bdayEmployee;
    if (newEmployeeList.length !== 0) {
      dispatch(storeEmployeeList(newEmployeeList));
      const birthdaySortedEmployees =
        sortEmployeeListByBirthdayAndMonth(newEmployeeList);
      bdayEmployee = birthdaySortedEmployees;
      dispatch(setEmployeeBirthdayList(birthdaySortedEmployees));
      const profileImages = getProfileImages(newEmployeeList);
      dispatch(storeEmployeeImages(profileImages));
      let tempArray = loadedContent;
      tempArray.push("member list");
      tempArray.push("member images");
      setLoadedContent(tempArray);
    }
    return bdayEmployee;
  }
  async function getDashboardScreenData(userData) {
    let res = await employeeController.getUpcomingBirthdayEmployeeList();
    if (res.length !== 0) {
      res.sort((e1, e2) => {
        const bday1 = parseInt(moment(e1.birthday).format("MMDD"));
        const bday2 = parseInt(moment(e2.birthday).format("MMDD"));
        return bday1 - bday2;
      });
      dispatch(setCurrentWeekBirthday(res));
      const upcomingBirthday = res.filter(
        (employee) =>
          parseInt(getUTCDate(employee.birthday).format("DD")) >
          parseInt(moment().format("DD"))
      );
      dispatch(setUpcomingBirthday(upcomingBirthday));
    }
    let arrayTemp = loadedContent;
    arrayTemp.push("upcoming birthday");
    setLoadedContent(arrayTemp);
    let bdayEmployee = getEmployeeList();
    getLeaveNumbers();

    let date = new Date();
    const data = {
      date: formatDate(date),
    };
    let resData = await eventController.getEventListByDate(data);
    resData.sort((data1, data2) => {
      return (
        parseInt(moment(data1.event_date).format("DD")) -
        parseInt(moment(data2.event_date).format("DD"))
      );
    });
    dispatch(getCurrentDayEvents(resData));
    let tempArray = loadedContent;
    tempArray.push("week event");
    setLoadedContent(tempArray);
    const currentMonthIndex = new Date().getMonth();
    const year = new Date().getFullYear().toString();
    const dataMonthOne = {
      user_id: userData.user._id,
      month: Month[currentMonthIndex].toLowerCase(),
      year: year,
    };

    const attendanceMonthData = await attendanceController.getMonthAttendance(
      dataMonthOne
    );
    if (!attendanceMonthData.includes("error")) {
      dispatch(attendanceMonth(attendanceMonthData));
      const monthBasicDetail = getMonthBasicDetail(attendanceMonthData);
      dispatch(monthBasicAttendanceDetail(monthBasicDetail));
      let tempArray = loadedContent;
      tempArray.push("month attendance");
      tempArray.push("month basic attendance");
      setLoadedContent(tempArray);
    }

    if (employeeAcess.attendance) {
      const dailyAttendance = await attendanceController.getDailyAttendance();
      if (dailyAttendance) {
        dispatch(setDailyAttendance(dailyAttendance));
        let tempArray = loadedContent;
        tempArray.push("daily attendance");
        setLoadedContent(tempArray);
      }
    }
    getTypingData();
    if (employeeAcess.leave_details) {
      const employeesLeave = await leaveController.getLeaveInfoEmployees();
      
      if (employeesLeave !== "error") {
        dispatch(setEmployeeLeave(employeesLeave));
        let tempArray = loadedContent;
        tempArray.push("all leave info");
        setLoadedContent(tempArray);
      }
      const pendingEmployeesLeave =
        await leaveController.getPendingEmployeeLeaveData();
        
      if (pendingEmployeesLeave !== "error") {
        dispatch(updatePendingEmployeeLeaveData(pendingEmployeesLeave));
        let tempArray = loadedContent;
        tempArray.push("pending leave info");
        setLoadedContent(tempArray);
      }
      const leaveHistory = await leaveController.getLeaveHistory();
      if (leaveHistory) {
        dispatch(setLeaveHistoryData(leaveHistory));
        let tempArray = loadedContent;
        tempArray.push("leave history");
        setLoadedContent(tempArray);
      }
    }
    const leaveInfos = await leaveController.getEmployeeLeaveData(
      userData.user._id
    );
    if (leaveInfos) {
      dispatch(updateEmployeeLeaveDatas(leaveInfos));
      const pendingLeaves = getPendingLeaves(leaveInfos);
      dispatch(updatePendingEmployeeLeaveDatas(pendingLeaves));
      let tempArray = loadedContent;
      tempArray.push("leave application");
      tempArray.push("pending leave application");

      setLoadedContent(tempArray);
    }

    const tempPageArray = loadedPages;
    tempPageArray.push(CommonString.dashboardScreenKey);
    setLoadedPages(tempPageArray);
    getLifeAtPrexel();
    return bdayEmployee;
  }

  async function getOfficeTimings() {
    if (attendance?.shift.length !== 0) {
      if (loadedPages?.includes(CommonString.officeTimingScreen)) return;
      let tempPages = loadedPages;
      tempPages.push(CommonString.officeTimingScreen);
      setLoadedPages(tempPages);
      return;
    }
    const officeTimings = await attendanceController.getShift();
    dispatch(setShift(officeTimings));
    let tempPages = loadedPages;
    tempPages.push(CommonString.officeTimingScreen);
    setLoadedPages(tempPages);
  }

  async function getLifeAtPrexel() {
    const holidayList = await holidayController.getHolidayList();
    let holidayListMutated;
    if (holidayList.length !== 0) {
      holidayListMutated = holidayController.getMutatedHolidayList(holidayList);
      dispatch(setHolidayList(holidayList));
      dispatch(setHolidayListByMonth(holidayListMutated));
      const prexelMemoryList = await holidayController.getPrexelPhotosList();
      if (prexelMemoryList) {
        dispatch(setPrexelMemories(prexelMemoryList));
      }
      let tempArray = loadedContent;
      tempArray.push("holiday list");
      setLoadedContent(tempArray);
      return holidayListMutated;
    }
    let tempPages = loadedPages;
    tempPages.push(CommonString.prexelLifeScreen);
    setLoadedPages(tempPages);
  }

  async function getTrainingMaterial() {
    const trainingData = await materialController.getEmployeePdf();
    if (trainingData.length !== 0) {
      dispatch(setPdfMaterial(trainingData));
      let tempArray = loadedContent;
      setLoadedContent(tempArray);
    }
    let tempPages = loadedPages;
    tempPages.push(CommonString.trainingMaterialScreenKey);
    setLoadedPages(tempPages);
  }

  async function getAssets() {
    if (employeeAcess.assets) {
      const assetData = await assetController.getAsset();
      if (assetData) {
        dispatch(setAssetData(assetData));
      }
      let tempPages = loadedPages;
      tempPages.push(CommonString.assetManagementScreen);
      setLoadedPages(tempPages);
    }
  }

  async function getAccessScreenData() {
    if (getLocalStorage(userdata).level.name !== level100) return;
    let respData = await accessController.getAccessManagementData();
    dispatch(setEmployeeAccessListAll(respData.data));
    let tempPage = loadedPages;
    tempPage.push(CommonString.accessManagement);
    setLoadedPages(tempPage);
    return respData.data;
  }
  async function getPolicy() {
    const respData = await policyController.getPolicy();

    if (respData.success) {
      dispatch(setPolicy(respData.data.data));
    }
    let arrayTemp = loadedContent;
    arrayTemp.push("policy");
    setLoadedContent(arrayTemp);
  }

  async function getSynergyLeagueData() {
    let data = await synergyLeagueController.getTeams();
    if (data) {
      dispatch(setTeams(data));
    }
    data = await synergyLeagueController.getSubTeams();
    if (data) {
      dispatch(setSubTeamsAll(data));
      let subTeamName = data.map((team) => team.team_name);
      dispatch(setSubTeamsNames(subTeamName));
    }
    data = await synergyLeagueController.getSports();
    if (data) {
      dispatch(setSports(data));
    }
    data = await synergyLeagueController.getUpcomingMatches();
    if (data) {
      dispatch(setUpcomingSports(data));
    }
    data = await synergyLeagueController.getRecentMatches();
    if (data) {
      dispatch(setRecentMatches(data));
    }
    data = await synergyLeagueController.getPointsTable();
    if (data) {
      let newPointTable = sortAndSummarizeTeam(data);
      dispatch(setPointsTable(newPointTable));
    }
    await getDashboardScreenData(getLocalStorage(userdata));

    let tempPagesArray = loadedPages;
    tempPagesArray.push(CommonString.synergyLeagueScreenKey);
    setLoadedPages(tempPagesArray);
  }

  function sortAndSummarizeTeam(pointsTable) {
    const additionalData = {
      defenders: 0,
      game_name: "Total Points",
      game_points: 0,
      mavericks: 0,
      navigators: 0,
      warriors: 0,
      isUnEditable: true,
    };
    pointsTable.forEach((point) => {
      additionalData.defenders =
        additionalData.defenders + parseFloat(point.defenders);
      additionalData.mavericks =
        additionalData.mavericks + parseFloat(point.mavericks);
      additionalData.navigators =
        additionalData.navigators + parseFloat(point.navigators);
      additionalData.warriors =
        additionalData.warriors + parseFloat(point.warriors);
      additionalData.game_points =
        additionalData.game_points + parseFloat(point.game_points);
    });
    const newData = [additionalData, ...pointsTable];
    return newData;
  }

  // async function getAllData(userData) {
  //   setLoadingFlag(true);
  //   if (
  //     location.pathname === "/home/dashboard" ||
  //     location.pathname === "/home/leave-info" ||
  //     location.pathname === "/home/leave-application" ||
  //     location.pathname === "/home/profile" ||
  //     location.pathname === "/home/memebers"
  //   ) {
  //     await getOfficeTimings();
  //     const valuesDashboard = await getDashboardScreenData(userData);
  //     setLoadingFlag(false);
  //     const holiday = await getLifeAtPrexel();
  //     const combinedEvents = combineEvents(
  //       valuesDashboard.birthday,
  //       valuesDashboard.event,
  //       holiday
  //     );
  //     dispatch(setFullEvent(combinedEvents));

  //     await getTrainingMaterial();
  //     await getAssets();
  //     await getAccessScreenData();
  //     await getSynergyLeagueData();
  //   } else if (location.pathname === "/home/office-timings") {
  //     const valuesDashboard = await getDashboardScreenData(userData);
  //     await getOfficeTimings();

  //     setLoadingFlag(false);

  //     const holiday = await getLifeAtPrexel();
  //     const combinedEvents = combineEvents(
  //       valuesDashboard.birthday,
  //       valuesDashboard.event,
  //       holiday
  //     );
  //     dispatch(setFullEvent(combinedEvents));
  //     await getSynergyLeagueData();
  //     await getTrainingMaterial();
  //     await getAssets();
  //     await getAccessScreenData();
  //   } else if (location.pathname === "/home/life-@-prexel") {
  //     const valuesDashboard = await getDashboardScreenData(userData);
  //     const holiday = await getLifeAtPrexel();
  //     const combinedEvents = combineEvents(
  //       valuesDashboard.birthday,
  //       valuesDashboard.event,
  //       holiday
  //     );
  //     dispatch(setFullEvent(combinedEvents));

  //     setLoadingFlag(false);
  //     await getSynergyLeagueData();

  //     await getOfficeTimings();
  //     await getTrainingMaterial();
  //     await getAssets();
  //     await getAccessScreenData();
  //   } else if (location.pathname === "/home/training-material") {
  //     const valuesDashboard = await getDashboardScreenData(userData);
  //     await getTrainingMaterial();

  //     setLoadingFlag(false);
  //     await getSynergyLeagueData();

  //     const holiday = await getLifeAtPrexel();
  //     const combinedEvents = combineEvents(
  //       valuesDashboard.birthday,
  //       valuesDashboard.event,
  //       holiday
  //     );
  //     dispatch(setFullEvent(combinedEvents));

  //     await getOfficeTimings();
  //     await getAssets();
  //     await getAccessScreenData();
  //   } else if (location.pathname === "/home/assets") {
  //     const valuesDashboard = await getDashboardScreenData(userData);
  //     await getAssets();
  //     setLoadingFlag(false);
  //     await getSynergyLeagueData();
  //     const holiday = await getLifeAtPrexel();
  //     const combinedEvents = combineEvents(
  //       valuesDashboard.birthday,
  //       valuesDashboard.event,
  //       holiday
  //     );
  //     dispatch(setFullEvent(combinedEvents));
  //     await getTrainingMaterial();
  //     await getOfficeTimings();
  //     await getAccessScreenData();
  //   } else if (location.pathname === "/home/access") {
  //     const valuesDashboard = await getDashboardScreenData(userData);
  //     await getAccessScreenData();

  //     setLoadingFlag(false);
  //     await getSynergyLeagueData();

  //     const holiday = await getLifeAtPrexel();
  //     const combinedEvents = combineEvents(
  //       valuesDashboard.birthday,
  //       valuesDashboard.event,
  //       holiday
  //     );
  //     dispatch(setFullEvent(combinedEvents));
  //     await getTrainingMaterial();

  //     await getOfficeTimings();
  //     await getAssets();
  //   } else if (location.pathname === "/home/events") {
  //     const valuesDashboard = await getDashboardScreenData(userData);
  //     const holiday = await getLifeAtPrexel();
  //     const combinedEvents = combineEvents(
  //       valuesDashboard.birthday,
  //       valuesDashboard.event,
  //       holiday
  //     );
  //     dispatch(setFullEvent(combinedEvents));
  //     setLoadingFlag(false);
  //     await getSynergyLeagueData();

  //     await getOfficeTimings();

  //     await getTrainingMaterial();
  //     await getAssets();
  //     await getAccessScreenData();
  //   } else if (location.pathname === "/home/synergy-league") {
  //     await getSynergyLeagueData();

  //     setLoadingFlag(false);
  //     await getAccessScreenData();
  //     const valuesDashboard = await getDashboardScreenData(userData);

  //     const holiday = await getLifeAtPrexel();
  //     const combinedEvents = combineEvents(
  //       valuesDashboard.birthday,
  //       valuesDashboard.event,
  //       holiday
  //     );
  //     dispatch(setFullEvent(combinedEvents));
  //     await getTrainingMaterial();

  //     await getOfficeTimings();
  //     await getAssets();
  //   }
  // }

  async function getLeaveNumbers() {
    const sendData = {
      recruiter_id: getLocalStorage(userdata).user._id,
      month: moment().month() + 1,
      year: moment().year(),
    };

    const data = await leaveController.getLeaveNumbers(sendData);
    if (data !== null) {
      dispatch(updateLeaveNumbers(data));
    }
    return;
  }

  function compareTypingList(emp1, emp2) {
    if (emp1.speed < emp2.speed) {
      return 1;
    }
    if (emp1.speed > emp2.speed) {
      return -1;
    }
    return 0;
  }
  function getLoginData() {
    let token = getLocalStorage(api_token_key);
    const userData = getLocalStorage(userdata);
    if (token === null || userData === null) {
      history.push("/");
      return;
    }
    getLoginAttendanceData();

    dispatch(updateApiToken(token));
    dispatch(updateEmployeeData(userData));
  }
  function getProfileImages(employeeList) {
    const employeeProfileList = employeeList.map(
      (employeeDetails) => employeeDetails.profile_image_url
    );
    return employeeProfileList;
  }

  async function getLoginAttendanceData() {
    if (getLocalStorage(userdata) == null) return;
    const today = moment().utc().subtract(5, "hours").format("YYYY-MM-DD");
    const data = {
      user_id: getLocalStorage(userdata).user._id,
      date: today,
    };
    let attendanceData = await attendanceController.checkAttendance(data);
    if (attendanceData !== null && !attendanceData.includes("error")) {
      if (attendanceData.length !== 0) {
        attendanceData = attendanceData[0];
        let in_time = attendanceData.in_time.replace(" ", "T");
        in_time = attendanceData.in_time + "Z";

        const newAttendanceData = { ...attendanceData, in_time: in_time };
        dispatch(setAttendanceStatus(newAttendanceData));
      }
    }
    if (attendanceData === null) {
      dispatch(setAttendanceStatus(null));
    }
    let tempArray = loadedContent;
    tempArray.push("login");
    setLoadedContent(tempArray);
  }

  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = () => {
    deleteLocalStorageItem(api_token_key);
    deleteLocalStorageItem(userdata);
    dispatch(clearState());
    history.push("/");
  };
  function onHandleClose() {
    setOpenLogoutPopup(false);
  }
  function getModalChildren() {
    return (
      <ConfirmationMenu
        title={CommonString.logoutConfirmation}
        yesButtonName={CommonString.yes}
        onClickYesButton={() => logout()}
        noButtuonName={CommonString.no}
        onClicknoButton={() => onHandleClose()}
        color={CommonColor.primary_color}
      />
    );
  }

  return (
    <Box sx={AppContainer}>
      <ModalPopup open={openLogoutPopup} issmallScreen={true}>
        {getModalChildren()}
      </ModalPopup>
      <CssBaseline />
      {mainLoader ? (
        <MainLoaderContainer>
          <PageRefreshLoader />
        </MainLoaderContainer>
      ) : noData ? (
        <NoDataContainer width="100%">
          <MainScreenNoData width="100%" height="50%" />
          <TypoGraphy weight="600" fontSize={FontSizes.Subtitle}>
            {CommonString.noApiResponse}
          </TypoGraphy>
        </NoDataContainer>
      ) : (
        <React.Fragment>
          {" "}
          <DrawerWrapper openWidth="240px" closedWidth="64px" open={open}>
            <AddViewAllButtonContainer>
              <DrawerHeaderContainer
                handleDrawerClose={handleDrawerClose}
                handleDrawerOpen={handleDrawerOpen}
                open={open}
                isModalOpen={dashboard.isModalOpen}
                theme={theme}
              />
              <DividnLineContainer>
                <Divider />
              </DividnLineContainer>

              <DrawerContentContainer>
                <DrawerContent open={open} closeDrawer={() => setOpen(false)} />
              </DrawerContentContainer>

              {/* <DividnLineContainer>
                <Divider />
              </DividnLineContainer> */}
            </AddViewAllButtonContainer>
            <LogoutListItemContainer>
              <ListItem
                disablePadding
                sx={ListItemStyle(
                  parseInt(dashboard.drawerActiveIndex),
                  100000
                )}
                onClick={() => setOpenLogoutPopup(true)}
              >
                <ListItemButton
                  sx={DrawerItem({
                    open: open,
                    isSelementctedIndex: parseInt(dashboard.drawerActiveIndex),
                    index: 10000,
                  })}
                >
                  <Tooltip
                    title={"Log Out"}
                    placement="right-end"
                    arrow
                    onMouseEnter={() =>
                      windowWidth > 900 && !open && setOpenTooltip("hovered")
                    }
                    onMouseLeave={() =>
                      windowWidth > 900 && !open && setOpenTooltip(null)
                    }
                    open={openToolTip === "hovered"}
                  >
                    <ListItemIcon sx={sideMenuIconStyle(open)}>
                      <IconifyIcon
                        icon={"material-symbols:logout"}
                        $isActive={false}
                        fontSize={open ? "10px" : "14px"}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <DrawerText
                    primary={"Log Out"}
                    sx={DrawerMenuText(
                      open,
                      parseInt(dashboard.drawerActiveIndex),
                      1000000
                    )}
                  />
                </ListItemButton>
              </ListItem>
            </LogoutListItemContainer>
          </DrawerWrapper>
          <FlexContainer
            flexDirection="column"
            width={`calc(100% - ${open ? 240 : 65}px)`}
            mobileWidth={`calc(100% - ${open ? 240 : 65}px)`}
            webkitWidth={`-webkit-calc(100% - ${open ? 240 : 65}px)`}
            safariWidth={`-moz-calc(100% - ${open ? 240 : 65}px)`}
            height="100%"
          >
            <Box component="main" sx={ScreenContainer(theme, open)}>
              <Screens
                LoadingFlag={LoadingFlag}
                loadedPages={loadedPages}
                open={open}
                loadedContent={loadedContent}
                getDashboardScreenData={getDashboardScreenData}
                getLifeAtPrexel={getLifeAtPrexel}
                getSynergyLeagueData={getSynergyLeagueData}
                getTrainingMaterial={getTrainingMaterial}
                getTypingData={getTypingData}
                getEmployeeList={getEmployeeList}
                getOfficeTimings={getOfficeTimings}
                getAssets={getAssets}
                getAccessScreenData={getAccessScreenData}
                getAppealData={getAppealData}
                getWFH={getWFH}
                getPolicy={getPolicy}
              />
            </Box>
          </FlexContainer>
        </React.Fragment>
      )}
    </Box>
  );
}
