import { DialogContent, DialogTitle, Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeaveController from "../../controller/LeaveController";
import { setSelectedUser } from "../../reducer/employeereduxstore";
import {
  updateActiveUserLeaveListData,
  updateLeaveNumbersLeaveInfo,
} from "../../reducer/leavereduxstore";
import { PopupNavBarContainer } from "../../style/form.style";
import {
  DropDownListContainerPopLeaveInfo,
  LeaveBalanceGraphContainer,
  LeaveInfoEmployee,
  NoDataComponentContainerLeave,
  OpenPopupButtonMobile,
} from "../../style/leave.style";
import { CommonString } from "../../utils/common.string";
import DropDownListEmployee from "../commonComponents/inputs/DropDownListEmployee";
import NoData from "../commonComponents/NoData/NoData";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import LeaveBalanceGraph from "../LeaveInfo/LeaveBalanceGraph";
import LeaveInfoCard from "../LeaveInfo/LeaveInfoCard";
import PageRefreshLoader from "../Loader/PageRefreshLoader";
import { FlexContainer } from "../../style/asset.style";

export default function LeaveInfoDetailPopup(props) {
  const leaveStore = useSelector((state) => state.leave);
  const employee_list = useSelector((state) => state.employee.employee_list);
  const employee = useSelector((state) => state.employee);

  const [loading, setLoading] = useState(false);
  const [activeLeave, setActiveLeave] = useState(null);
  const [selectedUser, setselectedUser] = useState(null);
  const [activeEmployeeIndex, setActiveEmployeeIndex] = useState(null);
  const dispatch = useDispatch();
  const leaveController = new LeaveController();

  async function activeLeaveIdUpdater(udata, index) {
    setActiveLeave(udata);
    const Employee = employee_list.filter(
      (employee) => employee._id === udata.user._id
    )[0];
    setselectedUser(Employee);
    dispatch(setSelectedUser(Employee));
    setActiveEmployeeIndex(index);
    const sendData = {
      recruiter_id: udata.user._id,
    };
    const leaveNumbers = await leaveController.getLeaveNumbers(sendData);
    dispatch(
      updateLeaveNumbersLeaveInfo({
        total_leave: leaveNumbers.total_leave,
        used_leave: leaveNumbers.used_leave,
      })
    );
    const leaveData = await leaveController.getEmployeeLeaveData(
      udata.user._id
    );
    dispatch(updateActiveUserLeaveListData(leaveData));
  }
  function getPopupHeaderName() {
    return "Leave Info Detail";
  }

  return (
    <React.Fragment>
      <DialogTitle>
        <PopupNavBarContainer>
          <PopupNavBar
            onHandleClose={props.onHandleClose}
            title={getPopupHeaderName()}
          />
        </PopupNavBarContainer>
      </DialogTitle>
      <DialogContent dividers={true}>
        <DropDownListContainerPopLeaveInfo>
          <DropDownListEmployee
            dataList={employee_list}
            title={"Select Employee"}
            value={
              employee.selected_user_leave_info !== null
                ? employee.selected_user_leave_info
                : undefined
            }
            handleChange={(event) => {
              activeLeaveIdUpdater({
                user: {
                  _id: event._id,
                },
              });
            }}
            textTransform={"capitalize"}
          />
        </DropDownListContainerPopLeaveInfo>
        <LeaveBalanceGraphContainer>
          <LeaveBalanceGraph
            total_leave={leaveStore.total_leave_number_info}
            used_leave={leaveStore.used_leave_number_info}
          ></LeaveBalanceGraph>
        </LeaveBalanceGraphContainer>
        <FlexContainer>
          <OpenPopupButtonMobile
            variant="outline"
            onClick={() => props.openAddLeavePopup()}
          >
            Add Leave
          </OpenPopupButtonMobile>
        </FlexContainer>
        {!loading ? (
          leaveStore.active_user_leave_list.length !== 0 ? (
            leaveStore.active_user_leave_list.map((leave, index) => {
              return (
                <LeaveInfoCard
                  data={leave}
                  onClick={() => activeLeaveIdUpdater(leave)}
                  isActive={activeLeave === leave.leave.id}
                  index={index}
                  ActiveEmployeeIndex={activeLeave}
                  setselectedStatus={() => {}}
                />
              );
            })
          ) : (
            <NoDataComponentContainerLeave
              verticalHeight="40%"
              horizontalAlign="center"
              ml="0%"
            >
              <NoData noDataText={CommonString.NoLeaveFound} />
            </NoDataComponentContainerLeave>
          )
        ) : (
          <PageRefreshLoader />
        )}
      </DialogContent>
    </React.Fragment>
  );
}
