import React from "react";
import {
  GameAndTeamListContainer,
  GameScoreListContainer,
} from "../../../style/synergy.league.style";
import GameScoreList from "./GameScoreList";

export default function LeftScoreCard() {
  return (
    <GameAndTeamListContainer>
      <GameScoreListContainer>
        <GameScoreList />
      </GameScoreListContainer>
    </GameAndTeamListContainer>
  );
}
