import { Icon } from "@iconify/react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import styled, { keyframes } from "styled-components";
import { FontSizes } from "../style/theme/font";
import CommonColor from "../utils/common.colors";
import {
  DeviceSizes,
  PageHeaderContainer,
  VerticalScrollableArea,
  heightChangeAnimation,
  opacityChangeAnimation,
} from "./common.style";
import { Theme } from "./theme/color";
import { LeaveApplicationContainer } from "./leave.style";

const leaveInfoCardLoadAnimation = keyframes`
0%{
  opacity: 0;
  translate: 0 20px;
};
100%{
  opacity: 1;
  translate: 0 0;
};
`;

const AppealApplicationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
  padding: 1%;
`;

const EmployeeAppealInfoContainer = styled.div`
  /* pointer-events: ${(props) => (props.noclick ? "none" : "")}; */
  /* cursor: ${(props) => (props.noclick ? "" : "pointer")}; */
  background-color: ${Theme.primary_color.white};
  margin-left: ${(props) => (props.screen === 0 ? "1%" : "")};
  border-radius: 4px;
  padding: 3%;
  margin-bottom: ${(props) => (props.screen === 0 ? "2%" : "2%")};
  margin-right: ${(props) => (props.screen === 0 ? "1%" : "")};
  /* padding-top: ${(props) => (props.isleaveapplication ? "0%" : "")}; */
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
  border-color: ${(props) =>
    props.isActive ? Theme.primary_color.secondary_color : "transparent"};
  /* border-color: ${(props) =>
    props.isleaveapplication ? "transparent" : ""}; */
  border-width: 1px;
  border-style: solid;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  -webkit-box-shadow: 0px 0px 56px -47px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 56px -47px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 56px -47px rgba(0, 0, 0, 1);
  opacity: 0;
  animation-name: ${leaveInfoCardLoadAnimation};
  animation-duration: ${(props) => props.index / props.length}s;
  animation-delay: ${(props) => props.index / props.length}s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  & svg {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    box-shadow: 0px 4.466200351715088px 27.91375160217285px -5.58275032043457px #00000026;
    & svg {
      opacity: 1;
    }
  }

  @media ${DeviceSizes.bigScreen} {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  @media ${DeviceSizes.mobile} {
    padding: 4%;
    margin-left: 0;
    margin-bottom: 4%;
    margin-right: 0;
  }
`;
// styled(LeaveApplicationContainer)
const AppealAttendanceApplicationContainer =styled.div `
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  /* height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px); */
  padding: 1%;
  height: calc(100% - 105px);
 ${({ isAdmin }) => isAdmin && `
    height: calc(100% - 100px);
  `}
  @media ${DeviceSizes.mobile} {
    flex-direction:column-reverse;
    justify-content: start;
    align-items: center;
  }

`;

const ApplealApplicationGrid = styled.div`
  margin: 0%;
  padding: 0%;
  height: 100%;
  width: 100%;
  margin-right: 2%;
  & + .grid2 {
    margin-right: 0;
  }
  @media ${DeviceSizes.mobile} {
    height: 50%;
  }
`;
export {
  AppealApplicationContainer,
  EmployeeAppealInfoContainer,
  AppealAttendanceApplicationContainer,
  ApplealApplicationGrid
};
