import { PageTransition } from "@steveeeie/react-page-transition";
import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { AppContext } from "./AppContext";
import PageNotFound from "./screen/PageNotFound/PageNotFound";
import LoginScreen from "./screen/login/LoginScreen";
import MainScreen from "./screen/main/MainScreen";
const pages = [
  {
    path: "/",
    component: LoginScreen,
    exact: true,
  },
  {
    path: "/home",
    component: MainScreen,
    exact: false,
  },
  {
    path: "/",
    component: PageNotFound,
    exact: false,
  },
];
export default function Routes() {
  const { preset, enterAnimation, exitAnimation } = useContext(AppContext);

  return (
    <Route
      render={({ location }) => {
        const pathname = location.pathname.includes("home")
          ? "/home"
          : location.pathname;
        return (
          <PageTransition
            preset={preset}
            transitionKey={pathname}
            enterAnimation={enterAnimation}
            exitAnimation={exitAnimation}
          >
            <Switch>
              {pages.map((page, index) => {
                return (
                  <Route
                    key={index}
                    exact={page.exact}
                    path={page.path}
                    component={page.component}
                  />
                );
              })}
            </Switch>
          </PageTransition>
        );
      }}
    />
  );
}
