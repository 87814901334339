import loader from "../assets/images/success.json";
import pagerefreshloader from "../assets/images/pagerefreshloader.json";
import loaderSecurity from "../assets/images/loaderSecurity.json";
import circularLoader from "../assets/images/circularLoader.json";
import bookmarkAnimation from "../assets/images/bookmarkanimation.json";
import UnderConstruction from "../assets/images/under_construction.json";

import { ReactComponent as WisdomWednesdayIcon } from "../assets/images/WisdomWednesdayIcon.svg";
import { ReactComponent as TechnicalTraining } from "../assets/images/TechnicalTraining.svg";
import { ReactComponent as NotFoundImg } from "../assets/images/404Img.svg";
import { ReactComponent as LogoutGoodbye } from "../assets/images/logoutgoodbye.svg";
import { ReactComponent as NoDataIcon } from "../assets/images/no_data.svg";
import { ReactComponent as HighFiveBro } from "../assets/images/highFiveBro.svg";
import { ReactComponent as NoDataFromServer } from "../assets/images/noDataFromServer.svg";
import { ReactComponent as NoInternet } from "../assets/images/NoInternet.svg";
import { ReactComponent as SelectUser } from "../assets/images/SelectUser.svg";
import { ReactComponent as BirthdayEventIcon } from "../assets/images/BirthdayEventIcon.svg";
// import { ReactComponent as UnderConstruction } from "../assets/images/Under construction-bro.svg";

import warriorsLogo from "../assets/images/warriorsLogo.png";
import defendersLogo from "../assets/images/defendersLogo.png";
import mavericksLogo from "../assets/images/mavericksLogo.png";
import navigatorsLogo from "../assets/images/navigatorsLogo.png";

import synergyLeagueCupLogo from "../assets/images/synergyLeagueCupLogo.png";
import synergyLeagueLogo from "../assets/images/Logo_Synergy_league.png";
import EventNewsIcon from "../assets/images/EventNewsIcon.png";
import Medal from "../assets/images/medal.png";
import NoUser from "../assets/images/NoUser.png";
import HolidayEventIcon from "../assets/images/HolidayEventIcon.png";
import loginBg1 from "../assets/images/login_bg.png";
import PrexelBanner from "../assets/images/prexel_banner.jfif";

export const CommonImages = {
  PrexelBanner: PrexelBanner,
  loaderJson: loader,
  NoUser: NoUser,
  UnderConstruction: UnderConstruction,
  // UnderConstruction: UnderConstruction,
  HighFiveBro: HighFiveBro,
  Medal: Medal,
  loaderSecurity: loaderSecurity,
  pagerefreshloader: pagerefreshloader,
  NoInternet: NoInternet,
  SelectUser: SelectUser,
  NoDataFromServer: NoDataFromServer,
  EventNewsIcon: EventNewsIcon,
  HolidayEventIcon: HolidayEventIcon,
  BirthdayEventIcon: BirthdayEventIcon,
  loginBg: loginBg1,
  NoDataIcon: NoDataIcon,
  bookmarkAnimation: bookmarkAnimation,
  synergyLeagueLogos: {
    warriorsLogo: warriorsLogo,
    defendersLogo: defendersLogo,
    mavericksLogo: mavericksLogo,
    navigatorsLogo: navigatorsLogo,
    synergyLeagueCupLogo: synergyLeagueCupLogo,
    synergyLeagueLogo: synergyLeagueLogo,
  },
  eventIcons: {
    WisdomWednesdayIcon: WisdomWednesdayIcon,
    TechnicalTraining: TechnicalTraining,
  },
  PageNotFoundImg: NotFoundImg,
  LogoutGoodbye: LogoutGoodbye,
  circularLoader: circularLoader,
};

export const FileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="96px"
    height="96px"
  >
    <path fill={props.fill} d="M40 45L8 45 8 3 30 3 40 13z" />
    <path fill={"rgba(255, 255, 255,0.5)"} d="M38.5 14L29 14 29 4.5z" />
  </svg>
);

function LightenDarkenColor(col, amt) {
  let usePound = false;
  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}
