import { getLocalStorage } from "../services/LocalStorage/LocalStorage";
import { userdata } from "./common.key";
import { CommonString } from "./common.string";

const BaseUrl = "https://api.prexelite.com/";
// const BaseUrl = "https://4229-2405-201-2028-d03d-9051-b055-b2c5-502c.ngrok.io";

const MaterialBaseUrl = "https://api.prexelite.com/material/";
const GoogleMapBaseUrl = "https://maps.google.com?q=";
const ProfileBaseUrl = BaseUrl + "profile/"; //attach thumb after it starts working
const ProfileNonThumbBaseUrl = BaseUrl + "profile/";
const ApiVersionV2 = "/v2/";
const NoProfilePhoto =
  "https://i.pinimg.com/originals/1e/f8/15/1ef8156889dba99417ff2b3a6d99988d.jpg";
const PageNames = {
  dashboard: "dashboard",
  training: "trainings",
  blog: "blog",
  enmployee: "employees",
  profile: "My Profile",
};
const tab = "tabIndex";
const CardTitleText = {
  leaveinfoTitle: "Leave Info",
  leaveApplicationCard: "My Leave application",
};
const ButtonType = {
  text: "text",
  contained: "contained",
  outlined: "outlined",
};
const Screens = [
  "dashboard",
  "leave info",
  "leave applications",
  "meetings",
  "employees",
];
const LeaveBlock = {
  total_leaves: "Total Leaves",
  leaves_used: "Leaves Used",
  my_leave_application: "My Leave Application",
  note_description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A mi venenatis viverra elit.",
};

const ROLE = ["Leader", "Team Member"];
const SHIFT = ["India", "USA"];
const WING = ["ALL", "ITW", "BAE", "RW"];
const WINGWITHALL = ["Everyone", "ITW", "BAE", "RW"];
const TotalLeaves = 15;

const EMPLOYEES = [
  "vaibhav singh",
  "harsh patel",
  "kartik rameshan",
  "hiten patel",
  "diya jingar",
  "ankur patel",
];

const EVENTTYPE = [
  "news",
  "Wisdom Wednesday",
  "super saturday",
  "celebration",
  "technology training",
  "trip",
  "relay",
];
const pageIndexTitle = "INDEX";
const pageIndexKey = "PAGEKEY";

const RandomColor = ["#000807", "#a2a3bb", "#9395D3", "#B3B7EE"];
const ismanager = "ismanager";
const Month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const Year = { min: 2020, max: 2040 };

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const DeleteAssetText = "Are you sure you want to delete this asset?";
const yes = "yes";
const no = "no";
const ProfileTabTitle = (access, isProfile) => {
  return [
    {
      name: "attendance",
      isvisible: true,
    },
    {
      name: "leave info",
      isvisible: access.leave_details,
    },
    {
      name: "About",
      isvisible: access.basic_details || access.personal_details || isProfile,
    },
    {
      name: "Description",
      isvisible: true,
    },
    // {
    //   name: "Salary Information",
    //   isvisible: access.salary_details || isProfile !== undefined,
    // },
  ];
};
const profileTabNames = [
  "attendance",
  "leave info",
  "About",
  "Salary Information",
];
const onlyManagerScreens = [
  "Leave Info",
  "reports",
  "Assets Management",
  "Access Management",
];
const PageTitles = (access) => [
  {
    name: "dashboard",
    visible: true,
  },
  {
    name: "leave info",
    visible: access.leave_details,
  },
  {
    name: "leave application",
    visible: true,
  },
  {
    name: "events",
    visible: true,
  },
  {
    name: "employees",
    visible: true,
  },
  {
    name: "training materials",
    visible: true,
  },
  {
    name: "Life @ Prexel",
    visible: true,
  },
  {
    name: "Office Timings",
    visible: true,
  },
  {
    name: "Report",
    visible: access.reports,
  },
  {
    name: "Assets Management",
    visible: access.assets,
  },
  {
    name: "Access Management",
    visible: getLocalStorage(userdata).level.name === level100,
  },
  {
    name: "Payroll",
    visible: access.salary_details,
  },
  {
    name: "profile",
    visible: true,
  },
];
const NavBarIcons = (access) => [
  {
    name: "Add Prexelite",
    icon: "fluent:people-add-20-regular",
    visible: access.users,
    key: CommonString.dashboardScreenKey,
  },
  {
    name: "Leave Info",
    icon: "",
    visible: false,
    key: CommonString.leaveInfoScreenKey,
  },
  {
    name: "Apply Leave",
    icon: "carbon:calendar-add",
    visible: true,
    key: CommonString.leaveApplicationScreenKey,
  },
  {
    name: "Add Event",
    icon: "fluent:people-add-20-regular",
    visible: access.events,
    key: CommonString.eventsScreenKey,
  },
  {
    name: "Add Prexelite",
    icon: "fluent:people-add-20-regular",
    visible: access.users,
    key: CommonString.employeeScreenKey,
  },
  {
    name: "Add Training",
    icon: "material-symbols:note-add-outline-rounded",
    visible: access.training_material,
    key: CommonString.trainingMaterialScreenKey,
  },
  {
    name: "celebration",
    icon: "",
    visible: false,
    key: CommonString.prexelLifeScreen,
  },
  {
    name: "add office timing",
    icon: "mdi:clock-plus-outline",
    visible: true,
    key: CommonString.officeTimingScreen,
  },
  {
    name: "report",
    icon: "",
    visible: false,
    key: CommonString.reportScreen,
  },
  {
    name: "Asset Management",
    icon: "material-symbols:add-circle-outline",
    visible: access.assets,
    key: CommonString.assetManagementScreen,
  },
  {
    name: "manage access",
    icon: "",
    visible: false,
    key: CommonString.accessManagement,
  },
  {
    name: "Payroll",
    icon: "",
    visible: false,
    key: CommonString.payrollScreen,
  },
  {
    name: "profile",
    icon: "",
    visible: false,
    key: CommonString.profileScreen,
  },
];
const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "AndorrA",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic Of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People'S Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People'S Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "RWANDA",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const maritalStatusList = ["Single", "Married"];
const genderNameList = ["Male", "Female"];
const level100 = "LEVEL-100";

const vapidKey =
  "BIron_Dfa0QQ3GsAojmxIoCJhdEcb1BEOOkvqtWlSo_wAThaV-in3VsWnj3PmUU8aQ7TSu1dS73elzoxPP4gMC0";

const AssetTypesImages = [
  "https://cdn-icons-png.flaticon.com/512/535/535118.png",
  "https://cdn-icons-png.flaticon.com/512/2224/2224412.png",
  "https://uxwing.com/wp-content/themes/uxwing/download/computers-mobile-hardware/computer-mouse-icon.png",
  "https://cdn-icons-png.flaticon.com/512/27/27130.png",
  "https://cdn-icons-png.flaticon.com/512/15/15094.png",
  "https://cdn-icons-png.flaticon.com/512/0/191.png",
  "https://cdn-icons-png.flaticon.com/512/3306/3306103.png",
  "https://cdn-icons-png.flaticon.com/512/2107/2107174.png",
  "https://cdn-icons-png.flaticon.com/512/3707/3707959.png",
];

const TrainingType = ["General", "Wisdom Wednesday", "technology training"];
const activePath = "last_url";
const TimeFormat = "YYYY-MM-DDTHH:MM:SS.ssZ";

export {
  tab,
  TimeFormat,
  ApiVersionV2,
  profileTabNames,
  activePath,
  TrainingType,
  MaterialBaseUrl,
  AssetTypesImages,
  GoogleMapBaseUrl,
  vapidKey,
  pageIndexKey,
  level100,
  genderNameList,
  maritalStatusList,
  countryList,
  NavBarIcons,
  PageTitles,
  onlyManagerScreens,
  ProfileTabTitle,
  yes,
  no,
  DeleteAssetText,
  PageNames,
  ButtonType,
  CardTitleText,
  TotalLeaves,
  LeaveBlock,
  ROLE,
  SHIFT,
  WING,
  EMPLOYEES,
  EVENTTYPE,
  Screens,
  WINGWITHALL,
  RandomColor,
  BaseUrl,
  ProfileBaseUrl,
  NoProfilePhoto,
  ismanager,
  pageIndexTitle,
  Month,
  Year,
  responsive,
  ProfileNonThumbBaseUrl,
};
