import EventProvider from "../services/provider/eventProvider";
export default class EventController {
  constructor(props) {
    this.eventProvider = new EventProvider();
  }
  getEventList = async () => {
    let response = await this.eventProvider.getInitialEvents();
    if (response.status === 200) {
      return response.data.currentMonthData;
    }
    return [];
  };
  getMonthlyEventList = async (monthIndex) => {
    let response = await this.eventProvider.getMonthlyEventList(monthIndex);
    if (response.status === 200) {
      return response.data.data;
    }
    return [];
  };
  getDailyEventList = async (date) => {
    let response = await this.eventProvider.getDailyEventList(date);
    if (response.status === 200) {
      return response.data.requestedMonthData;
    }
    return [];
  };

  getEventListByDate = async (data) => {
    let response = await this.eventProvider.getEventByDate(data);
    if (response.status === 200) {
      return response.data.data;
    }
    return [];
  };
  addEvent = async (data) => {
    let response = await this.eventProvider.addEvent(data);
    if (response.status === 200) {
      return response.data;
    }
    return false;
  };
  updateEvent = async (data) => {
    let response = await this.eventProvider.updateEvent(data);
    if (response.status === 200) {
      return true;
    }
    return false;
  };
  onDeleteEvent = async (data) => {
    let response = await this.eventProvider.deleteEvent(data);
    if (response.data.status === 200) {
      return true;
    }
    return false;
  };
}
