import React from "react";
import { ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";
import {
  EventListShimmerContainer,
  EventsShimmerContainer,
} from "../../../style/loader.style";

export default function EventShimmer() {
  return (
    <EventListShimmerContainer>
      <ShimmerTitle line={1} gap={10} variant="primary" />
      <EventsShimmerContainer>
        <ShimmerThumbnail height={60} rounded />
        <ShimmerThumbnail height={60} rounded />
      </EventsShimmerContainer>
      <EventsShimmerContainer>
        <ShimmerThumbnail height={60} rounded />
        <ShimmerThumbnail height={60} rounded />
      </EventsShimmerContainer>
    </EventListShimmerContainer>
  );
}
