import React from "react";
import { Loader } from "../../style/loader.style";

export default function ShowLoader(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: props.loaderData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Loader
      options={defaultOptions}
      height={props.height ?? 200}
      width={props.width ?? 250}
      isStopped={false}
      isPaused={false}
    />
  );
}
