import React from "react";

import { Icon } from "@iconify/react";
import { DropDownWithChipContainer } from "../../../style/common.component.style";
import {
  AddButton,
  AddSpeedyPrexelitesContainer,
  MenuItemText,
} from "../../../style/holiday.style";
import CommonColor from "../../../utils/common.colors";
import { TypoGraphy } from "../../../style/common.style";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export default function DropdownWithChip(props) {
  const [anchorEl, setAnchorEl] = useState();

  function handleOptionClick(element) {
    setAnchorEl(undefined);
    props.handleClick(element);
  }

  function popOverOpener(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(undefined);
  }
  return (
    <DropDownWithChipContainer>
      <AddSpeedyPrexelitesContainer isvisible={1}>
        <AddButton
          variant="outlined"
          onClick={(event) => popOverOpener(event)}
          endIcon={
            <Icon
              icon="iconamoon:arrow-up-2-light"
              color={CommonColor.primary_color}
              vFlip={true}
            />
          }
          width={props.buttonWidth}
        >
          <TypoGraphy
            width="max-content"
            color="inherit"
            fontSize="inherit"
            weight="700"
          >
            {props.selectedOption}
          </TypoGraphy>
        </AddButton>
      </AddSpeedyPrexelitesContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ "& .MuiPaper-root": { width: props.buttonWidth } }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.list.map((element) => {
          return (
            <MenuItem onClick={() => handleOptionClick(element)} key={element}>
              <MenuItemText>{element}</MenuItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </DropDownWithChipContainer>
  );
}

//
