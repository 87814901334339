import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  AddButton,
  AddSpeedyPrexelitesContainer,
  HolidayNavBarContainer,
  NavBarScreenListContainer,
} from "../../style/holiday.style";
import CommonColor from "../../utils/common.colors";
import { CommonString } from "../../utils/common.string";
import AddSpeedyPrexelites from "../Forms/AddSpeedyPrexelites";
import ModalPopup from "../Popup/ModalPopup";
import HolidayNavBarMobile from "./HolidayNavBarMobile";
import { SpeedyPrexeliteTitle } from "../../utils/array.screens";

export default function HolidayNavBar(props) {
  const [modalType, setModalType] = useState("");
  const [modalOpen, setOpenModal] = useState(false);
  const employee = useSelector((state) => state.employee);
  function handleClick(screen) {
    props.handleClick(screen);
  }
  function getVariant(isActive) {
    if (isActive) {
      return "contained";
    }
    return "outlined";
  }
  function openAddSpeedModal() {
    setModalType("add");
    setOpenModal(true);
  }
  function closeModal() {
    setOpenModal(false);
  }
  function getModalChildren() {
    if (modalType === "add") {
      return (
        <React.Fragment>
          <AddSpeedyPrexelites data={null} onHandleClose={() => closeModal()} />
        </React.Fragment>
      );
    }
  }
  function getAddButtonName() {
    if (props.screen === CommonString.holidayTitle) {
      return "Add Holiday";
    }
    if (props.screen === CommonString.birthdayTitle) {
      return "Add Birthday";
    }
    return "Add Speedy Prexelite";
  }
  function getAccess() {
    return (
      employee.employee_acess.speedy_prexelite &&
      props.screen === SpeedyPrexeliteTitle[2].name
    );
  }
  return (
    <React.Fragment>
      <ModalPopup open={modalOpen}>{getModalChildren()}</ModalPopup>

      <HolidayNavBarContainer>
        <NavBarScreenListContainer>
          {SpeedyPrexeliteTitle.map((element) => (
            <AddSpeedyPrexelitesContainer isvisible={1}>
              <AddButton
                endIcon={
                  props.screen === element.name ? (
                    <Icon
                      icon={element.icon1}
                      color={CommonColor.white}
                      fontSize={"1.2em"}
                    />
                  ) : (
                    <Icon
                      icon={element.icon2}
                      fontSize={"1.2em"}
                      color={CommonColor.primary_color}
                    />
                  )
                }
                variant={getVariant(props.screen === element.name)}
                onClick={() => handleClick(element.name)}
              >
                {element.name}
              </AddButton>
            </AddSpeedyPrexelitesContainer>
          ))}
        </NavBarScreenListContainer>

        <AddSpeedyPrexelitesContainer isvisible={getAccess() ? 1 : 0}>
          <AddButton
            startIcon={
              <Icon
                icon="system-uicons:button-add"
                color={CommonColor.white}
                fontSize={"1.5em"}
              />
            }
            variant="contained"
            onClick={() => openAddSpeedModal()}
            elevation={0}
          >
            {getAddButtonName()}
          </AddButton>
        </AddSpeedyPrexelitesContainer>
      </HolidayNavBarContainer>
      <HolidayNavBarMobile
        openAddSpeedModal={openAddSpeedModal}
        handleClick={handleClick}
        selectedOption={props.screen}
        getAccess={getAccess}
      />
    </React.Fragment>
  );
}
