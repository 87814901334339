import React from "react";
import {
  EmployeeAttendanceShimmerContainer,
  EmployeeShimmerBody,
  EmployeeShimmerBodyText,
  EmplyeeShimmerNav,
  RectangleLoader,
} from "../../../style/loader.style";
import {
  ShimmerCircularImage,
  ShimmerTitle,
  ShimmerText,
  ShimmerThumbnail,
} from "react-shimmer-effects";

export default function EmployeeAttendanceShimmer() {
  return (
    <EmployeeAttendanceShimmerContainer>
      <EmplyeeShimmerNav>
        <ShimmerCircularImage size={40} />
        <ShimmerTitle line={1} gap={10} variant="primary" />
      </EmplyeeShimmerNav>
      <EmployeeShimmerBody>
        <EmployeeShimmerBodyText>
          <ShimmerText line={1} gap={10} />
          <ShimmerText line={1} gap={10} />
        </EmployeeShimmerBodyText>
        <EmployeeShimmerBodyText>
          <ShimmerText line={1} gap={10} />
          <ShimmerText line={1} gap={10} />
        </EmployeeShimmerBodyText>
        <EmployeeShimmerBodyText>
          <ShimmerText line={1} gap={10} />
          <ShimmerText line={1} gap={10} />
        </EmployeeShimmerBodyText>
      </EmployeeShimmerBody>
      <RectangleLoader height={35} rounded />
    </EmployeeAttendanceShimmerContainer>
  );
}
