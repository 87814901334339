import React, { useEffect } from "react";
import {
  DashboardContainer,
  VerticalScrollableAreaDashboard,
} from "../../style/dashboard.style";

import { useDispatch, useSelector } from "react-redux";
import AddPrexelite from "../../component/Forms/AddPrexelite";
import EmployeeAttendance from "../../component/commonComponents/ScreenDashboard/EmployeeAttendance";
import EmployeeListCard from "../../component/commonComponents/ScreenDashboard/EmployeeListCard";
import EventsCard from "../../component/commonComponents/ScreenDashboard/EventsCard";
import LeaveCard from "../../component/commonComponents/ScreenDashboard/LeaveCard";
import LoginCard from "../../component/commonComponents/ScreenDashboard/LoginCard";
import { Forms } from "../../utils/mockData";

import AttendanceController from "../../controller/attendanceController";
import {
  setAttendanceStatus,
  setIsModalOpen,
  updateAttendanceLoginStatus,
} from "../../reducer/dashboardreduxstore";
import { CommonString } from "../../utils/common.string";

import { AppBar, Grid, Toolbar } from "@mui/material";
import ModalPopup from "../../component/Popup/ModalPopup";
import EmployeeListStatus from "../../component/commonComponents/ScreenDashboard/EmployeeListStatus";
import LeaveHistoryBlock from "../../component/commonComponents/ScreenDashboard/LeaveHistoryBlock";
import TypingSpeed from "../../component/commonComponents/ScreenDashboard/TypingSpeed";
import NavBar from "../../component/drawer/NavBar";
import { AppBarStyle } from "../../style/drawer.style";
import { getScreenLocation } from "../../utils/utils";
import { userdata } from "../../utils/common.key";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import Profile from "../Profile/Profile";
import moment from "moment/moment";

export default function DashboardScreen(props) {
  const dispatch = useDispatch();
  const attendanceController = new AttendanceController();
  const user = useSelector((state) => state.auth.employee_data.user);
  const leave = useSelector((state) => state.leave);
  const dashboard = useSelector((state) => state.dashboard);
  const employee = useSelector((state) => state.employee);
  const employeeAccess = useSelector((state) => state.employee.employee_acess);

  const dailyAttendance = useSelector(
    (state) => state.attendance.daily_attendance
  );

  const employeeBirthdayList = useSelector(
    (state) => state.employee.upcoming_birthday
  );
  const employeeList = useSelector((state) => state.employee.employee_list);

  useEffect(() => {
    if (!props.loadedPages.includes(CommonString.dashboardScreenKey)) {
      props.getDashboardScreenData(getLocalStorage(userdata));
    }
  }, []);

  function OpenPopup() {
    dispatch(setIsModalOpen(true));
  }
  function ClosePopup() {
    dispatch(setIsModalOpen(false));
  }
  function getModalChildren() {
    if (dashboard.modal_type === Forms[0].name)
      return <AddPrexelite onHandleClose={() => ClosePopup()} />;
    if (dashboard.modal_type === CommonString.profileScreen)
      return <Profile employeeDetail={user} LoadingFlag={false} />;
  }
  function changeAttendanceScreen(screenNum) {
    dispatch(updateAttendanceLoginStatus(screenNum));
    loginData();
  }

  async function loginData() {
    let outTime = "00:00";

    let newNow = moment().utcOffset("-0500").format("YYYY-MM-DD");
    const data = {
      in_time: dashboard.attendance_data.in_time,
      out_time: outTime,
      attendance_date: newNow,
      is_absent: false,
      is_late: false,
      lat: undefined,
      lon: undefined,
      user_id: user._id,
    };
    const res = await attendanceController.addAttendance(data);
    if (res.success) {
      dispatch(setAttendanceStatus({ ...data, id: res.id }));
    }
  }

  function getPendingEmployeeLeaveData() {
    if (employee.employee_access_list.leave_info)
      return leave.pending_employee_leave;
    return leave.leave_list;
  }

  function getEmployeeName() {
    return user ? user.name : "random name";
  }

  function getUpcomingBirthdayScreenIndex() {
    const index = getScreenLocation(employee, CommonString.prexelLifeScreen);

    return index;
  }

  function getEmployeeScreen() {
    const index = getScreenLocation(employee, CommonString.employeeScreenKey);

    return index;
  }

  function checkIsLoading() {
    if (employee.employee_acess.leave_details) {
      return !props.loadedContent.includes("pending leave info");
    }

    return !props.loadedContent.includes("pending leave application");
  }

  return (
    <React.Fragment>
      <DashboardContainer>
        <React.Fragment>
          <AppBar
            position="static"
            open={props.open}
            elevation={5}
            sx={AppBarStyle(dashboard.isModalOpen, props.open)}
          >
            <Toolbar>
              <NavBar
                DrawerOpen={props.open}
                LoadingFlag={props.LoadingFlag}
                NavBarTitle={"Dashboard"}
                NavBarIcon={
                  employeeAccess.users
                    ? {
                        icon: "fluent:people-add-20-regular",
                        name: "Add Prexelite",
                      }
                    : ""
                }
              />
            </Toolbar>
          </AppBar>
          <VerticalScrollableAreaDashboard
            height="calc(100% - 65px)"
            webkitHeight={"-webkit-calc(100%-65px)"}
            mozHeight={"-moz-calc(100% - 65px)"}
          >
            {" "}
            <Grid container>
              <Grid item xs={12} md={6} pr={{ xs: "", md: "0.9%" }}>
                <LoginCard
                  openPopup={() => OpenPopup(1)}
                  employeeName={getEmployeeName()}
                  loginStateChange={(data) => changeAttendanceScreen(data)}
                  LoadingFlag={!props.loadedContent.includes("login")}
                  serialOrder={0}
                />

                {employeeBirthdayList.length !== 0 ? (
                  <EmployeeListCard
                    title={"Upcoming Birthday"}
                    shownBirthday={true}
                    data={employeeBirthdayList}
                    cardLocation={getUpcomingBirthdayScreenIndex()}
                    cardKey={CommonString.prexelLifeScreen}
                    LoadingFlag={false}
                    serialOrder={dashboard.is_dom_just_loaded ? 0.5 : 0}
                    isVisible={
                      employeeBirthdayList.length !== 0 && !props.LoadingFlag
                    }
                  />
                ) : (
                  ""
                )}

                {employeeAccess.users ? (
                  <EmployeeListCard
                    title={"Team Members"}
                    data={employeeList}
                    cardLocation={getEmployeeScreen()}
                    cardKey={CommonString.employeeScreenKey}
                    LoadingFlag={!props.loadedContent.includes("member images")}
                    serialOrder={dashboard.is_dom_just_loaded ? 1 : 0}
                    isVisible={employeeAccess.users}
                  />
                ) : (
                  <></>
                )}

                <TypingSpeed
                  cardLocation={getUpcomingBirthdayScreenIndex()}
                  LoadingFlag={
                    !props.loadedContent.includes("speedy prexelite")
                  }
                  cardKey={CommonString.prexelLifeScreen}
                  serialOrder={dashboard.is_dom_just_loaded ? 1.5 : 0}
                />

                <EventsCard
                  LoadingFlag={props.LoadingFlag}
                  serialOrder={dashboard.is_dom_just_loaded ? 2 : 0}
                />
              </Grid>
              <Grid item xs={12} md={6} pl={{ xs: "", md: "0.9%" }}>
                {employeeAccess.attendance ? (
                  <EmployeeListStatus
                    presentList={
                      dailyAttendance !== null ? dailyAttendance.present : []
                    }
                    title={"Daily Logs"}
                    LoadingFlag={
                      !props.loadedContent.includes("daily attendance")
                    }
                    serialOrder={dashboard.is_dom_just_loaded ? 2.5 : 0}
                    isVisible={employeeAccess.attendance}
                  />
                ) : (
                  <></>
                )}
                <LeaveCard
                  pendingEmployeeLeaveData={getPendingEmployeeLeaveData()}
                  LoadingFlag={checkIsLoading()}
                  serialOrder={
                    dashboard.is_dom_just_loaded
                      ? employeeAccess.attendance
                        ? 3
                        : 2.5
                      : 0
                  }
                />

                {!employeeAccess.users ? (
                  <EmployeeAttendance
                    OpenCalendar={() => OpenPopup(1)}
                    LoadingFlag={
                      !props.loadedContent.includes("month basic attendance")
                    }
                    serialOrder={
                      dashboard.is_dom_just_loaded
                        ? employeeAccess.attendance
                          ? 3.5
                          : 3
                        : 0
                    }
                    isVisible={!employeeAccess.users}
                  />
                ) : (
                  <></>
                )}

                {employeeAccess.leave_details ? (
                  <LeaveHistoryBlock
                    LoadingFlag={!props.loadedContent.includes("leave history")}
                    serialOrder={
                      dashboard.is_dom_just_loaded
                        ? employeeAccess.attendance
                          ? 4
                          : 3.5
                        : 0
                    }
                    isVisible={employeeAccess.leave_details}
                  />
                ) : (
                  <></>
                )}
              </Grid>
              <ModalPopup
                open={dashboard.isModalOpen}
                popupSize={dashboard.modal_type === Forms[0].name ? "md" : ""}
              >
                {getModalChildren()}
              </ModalPopup>
            </Grid>
          </VerticalScrollableAreaDashboard>
        </React.Fragment>
      </DashboardContainer>
    </React.Fragment>
  );
}
