import AccessManagementProvider from "../services/provider/accessManagementProvider";

export default class AccessController {
  constructor(props) {
    this.accessManagementProvider = new AccessManagementProvider();
  }

  getAccessManagementData = async () => {
    const response = await this.accessManagementProvider.getAccess({});
    if (response.status === 200) {
      return response.data;
    }
    return false;
  };
  updateAccessManagementData = async (data) => {
    const response = await this.accessManagementProvider.updateAccess(data);
    if (response.data.status === 200) {
      return true;
    }
    return false;
  };
  getAccessById = async (data) => {
    const response = await this.accessManagementProvider.getAccessById(data);
    if (response.status === 200) {
      return response.data.data;
    }
    return false;
  };
}
