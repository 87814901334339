import styled from "styled-components";
import Grid from "@mui/material/Grid";
import { Theme } from "./theme/color";
import { DeviceSizes } from "./common.style";
import CommonColor from "../utils/common.colors";
import { CommonImages } from "../utils/common.images";

const GridContainer = styled(Grid)`
  && {
    background-color: ${Theme.primary_color.theme_background_color};
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;

    @media ${DeviceSizes.mobile} {
      height: 100vh;
      width: 100vw;
    }
  }
`;
const ScreenContainer = (theme, drawerOpen) => {
  return {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: CommonColor.theme_background_color,
    filter: {
      sm: "brightness(1)",
      xs: drawerOpen
        ? "blur(5px) brightness(0.2) grayscale(1)"
        : "brightness(1)",
    },
    boxShadow: {
      sm: "",
      xs: drawerOpen ? "0 0 20px 20px rgba(0, 0, 0, 0.5)" : "",
    },
  };
};

const AppContainer = () => {
  return {
    height: "100vh",
    width: "100vw",
    maxWidth: "100vw",
    display: "flex",
    "& header": {
      width: "100%",
      right: {
        sm: "0",
        xs: "unset",
      },
    },
  };
};
const MainLoaderContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MainScreenNoData = styled(CommonImages.NoDataFromServer)`
  && {
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
  }
`;

export {
  GridContainer,
  ScreenContainer,
  AppContainer,
  MainLoaderContainer,
  MainScreenNoData,
};
