import React, { useEffect, useState } from "react";

import { Slider, TabsContainer, TypoGraphy } from "../../../style/common.style";
import CommonColor from "../../../utils/common.colors";
import { IconifyIcon } from "../../../style/employee.style";

export default function SliderTab(props) {
  const [sliderWidth, setSliderWidth] = useState("0px");
  const [sliderLeft, setSliderLeft] = useState("0px");
  const className = "relative w-full h-10 bg-gray-50 rounded-md";

  useEffect(() => {
    changePositionIndicator();
  }, [props.tab]);

  function changePositionIndicator() {
    const button = document.getElementsByClassName("button");
    const buttonWidth = button[props.tab].getBoundingClientRect().width;
    let leftSlideValue = 0;
    [...Array(props.tab)].map(
      (value, index) =>
        (leftSlideValue += button[index].getBoundingClientRect().width)
    );

    setSliderWidth(buttonWidth + "px");
    setSliderLeft(leftSlideValue + "px");
  }

  function getGridCols() {
    let newArray = [...Array(props.buttonList.length)].map(() => "auto");
    newArray = newArray.join(" ");
    return newArray;
  }
  return (
    <div className="">
      <div className="w-full">
        <TabsContainer
          role="tablist"
          aria-label="tabs"
          className={className}
          gridCols={getGridCols()}
        >
          <Slider
            className={"indicator absolute z-0 col-span-1 h-full rounded-md"}
            width={sliderWidth}
            left={sliderLeft}
          />

          {props.buttonList.map((buttonData, index) => {
            return (
              <button
                role="tab"
                aria-selected="true"
                aria-controls="panel-1"
                id="tab-1"
                tabindex="0"
                className="button bg-transparent rounded-full z-10 flex justify-center"
                onClick={() => props.onTabClick(index)}
                key={index}
              >
                <IconifyIcon
                  icon={buttonData.icon}
                  width="24"
                  height="24"
                  margin="0 10px 0 0"
                  cursor="pointer"
                />
                <TypoGraphy
                  color={
                    props.tab === index
                      ? CommonColor.white
                      : CommonColor.primary_color
                  }
                  textAlign="center"
                  width="100%"
                  style={{ height: "100%" }}
                  className="flex justify-center items-center"
                >
                  {buttonData.name}
                </TypoGraphy>
              </button>
            );
          })}
        </TabsContainer>
      </div>
    </div>
  );
}
