import { Icon } from "@iconify/react";
import { Button, Grid } from "@mui/material";
import styled from "styled-components";
import CommonColor from "../utils/common.colors";
import { DeviceSizes, heightChangeAnimation } from "./common.style";
import { FontSizes } from "./theme/font";
import { AccessTable } from "./style.access.mgmt.style";

const GridContainer = styled(Grid)`
  padding: 0;
`;
const AssetContainer = styled(GridContainer)`
  &&& {
    padding: 2%;
    margin: 0;
    height: 100%;
    width: 100%;
    & table {
      width: 100%;
    }
  }
`;
const AssetScreenContainer = styled(GridContainer)`
  && {
    margin: 0;
    width: 100%;
    border-radius: 10px;
    height: calc(100% - 65px);
    height: -webkit-calc(100% - 65px);
    height: -moz-calc(100% - 65px);
    @media ${DeviceSizes.mobile} {
      padding: 2%;
    }
  }
`;
const AssetTable = styled.div`
  width: 100%;
  font-family: "Mulish";
  font-weight: 500;
  font-size: ${FontSizes.Para};
  text-transform: capitalize;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
`;

const AssetTypeIcon = styled(Icon)`
  font-size: ${FontSizes.l};
  color: ${CommonColor.text_gray};
`;
const AssetName = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 500;
  text-transform: capitalize;
`;
const AssetModel = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  font-size: ${FontSizes.Para};
`;
const AssetBrand = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  font-size: ${FontSizes.Para};
`;

const AssetHolderData = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
`;
const AssetHolderName = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: ${FontSizes.Para};
  text-transform: capitalize;
`;
const AssetHolderEmail = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 500;
  color: ${CommonColor.primary_color};
`;
const AssetActionButton = styled(Button)`
  && {
    font-family: "Mulish";
    font-weight: 500;
    font-size: ${FontSizes.Para};
    color: ${CommonColor.primary_color};
    width: 100%;
  }
`;
const AssetAvailIcon = styled(AssetTypeIcon)`
  font-size: 18px;
`;
const AssetMoreDetailsIcon = styled(AssetAvailIcon)`
  cursor: pointer;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.jc ?? "flex-start"};
  align-items: ${(props) => props.verticalAlign ?? "center"};
  flex-direction: ${(props) => props.flexDirection ?? "row"};
  width:${(props) => props.width ?? "100%"};
  -webkit-width: ${(props) => props.webkitWidth ?? "100%"};
  -moz-width: ${(props) => props.safariWidth ?? "100%"};
  height: ${(props) => props.height};
  flex-wrap: ${(props) => props.flexWrap};
  margin: ${(props) => props.margin};
  row-gap: ${(props) => props.rowGap};
  column-gap: ${(props) => props.columnGap};
  flex: ${(props) => props.flex};
  background-color: ${(props) => props.bg};
  padding: ${(props) =>
    !props.padding?.lg ? props.padding : props.padding.lg};
  border: ${(props) => props.border};
  @media ${DeviceSizes.mobile} {
    width: ${(props) => props.mobileWidth};
    padding: ${(props) => (!props.padding?.xs ? "" : props.padding?.xs)};
  }
`;
const AssetOptionsContainer = styled.div`
  position: relative;
`;

const IconNoUser = styled(Icon)`
  width: 100%;
  height: 100%;
  background-color: ${CommonColor.primary_color_opacity_05};
  color: ${CommonColor.primary_color};
  border-radius: 50%;
  padding: 20%;
`;

const AssetTableContainer = styled(AccessTable)`
  height: 100%;
  animation-name: ${heightChangeAnimation};
`;
export {
  AssetTableContainer,
  IconNoUser,
  AssetOptionsContainer,
  FlexContainer,
  AssetMoreDetailsIcon,
  AssetActionButton,
  AssetAvailIcon,
  AssetHolderEmail,
  AssetHolderName,
  AssetHolderData,
  AssetBrand,
  AssetModel,
  AssetName,
  AssetTypeIcon,
  AssetTable,
  AssetContainer,
  AssetScreenContainer,
};
