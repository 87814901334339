import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setActiveDrawerkey,
  setIsDOMJustLoaded,
} from "../../reducer/dashboardreduxstore";
import {
  ComponentList,
  DividnLineContainer,
  DrawerItem,
  DrawerMenuText,
  DrawerText,
  IconifyIcon,
  ListItemStyle,
  sideMenuIconStyle,
} from "../../style/drawer.style";
import { getActiveDrawerData } from "../../utils/utils";
import { useWindowSize } from "../commonComponents/hooks/getWindowDimension";

function DrawerContent(props) {
  const [openToolTip, setOpenTooltip] = useState(false);
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.dashboard);
  const employee = useSelector((state) => state.employee);
  const activeDrawerData = getActiveDrawerData(employee.employee_acess);
  const history = useHistory();
  const windowWidth = useWindowSize().width;

  const setIndex = (key, path, index) => {
    dispatch(setActiveDrawerkey({ key: key, path: path, index: index }));
    dispatch(setIsDOMJustLoaded(false));
    history.push(path);
    if (windowWidth < 912) {
      props.closeDrawer();
    }
  };

  return (
    <React.Fragment>
      <ComponentList>
        {activeDrawerData.map((element, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                disablePadding
                sx={ListItemStyle(parseInt(dashboard.drawerActiveIndex), index)}
                onClick={() => setIndex(element.key, element.path, index)}
              >
                <ListItemButton
                  sx={DrawerItem({
                    open: props.open,
                    isSelementctedIndex: parseInt(dashboard.drawerActiveIndex),
                    index: index,
                  })}
                >
                  <Tooltip
                    title={element.iconText}
                    placement="right-end"
                    arrow
                    onMouseEnter={() =>
                      windowWidth > 912 && !props.open && setOpenTooltip(index)
                    }
                    onMouseLeave={() =>
                      windowWidth > 912 && !props.open && setOpenTooltip(null)
                    }
                    open={openToolTip === index}
                  >
                    <ListItemIcon sx={sideMenuIconStyle(props.open)}>
                      <IconifyIcon
                        icon={element.icon}
                        index={index}
                        $isActive={
                          parseInt(dashboard.drawerActiveIndex) === index
                        }
                        fontSize={props.open ? "10px" : "14px"}
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <DrawerText
                    primary={element.iconText}
                    sx={DrawerMenuText(
                      props.open,
                      parseInt(dashboard.drawerActiveIndex),
                      index
                    )}
                  />
                </ListItemButton>
              </ListItem>
              {element.is_show_divider ? (
                <DividnLineContainer ism={1}>
                  <Divider />
                </DividnLineContainer>
              ) : (
                ""
              )}
            </React.Fragment>
          );
        })}
      </ComponentList>
    </React.Fragment>
  );
}

export default DrawerContent;
