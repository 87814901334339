import { createSlice } from "@reduxjs/toolkit";

export const holidaySlice = createSlice({
  name: "holiday",
  initialState: {
    holiday_list: null,
    holiday_list_by_month: [],
    prexel_memories: [],
  },
  reducers: {
    setHolidayList: (state, action) => {
      state.holiday_list = action.payload;
    },
    setHolidayListByMonth: (state, action) => {
      state.holiday_list_by_month = action.payload;
    },
    setPrexelMemories: (state, action) => {
      state.prexel_memories = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHolidayList, setHolidayListByMonth, setPrexelMemories } =
  holidaySlice.actions;

export default holidaySlice.reducer;
