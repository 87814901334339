import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccessController from "../../../controller/accessController";
import { setEmployeeAccessListAll } from "../../../reducer/employeereduxstore";
import {
  NoDataContainer,
  TypoGraphy
} from "../../../style/common.style";
import {
  LeaveStatusContainer,
  LeaveStatusMsg,
  LeaveStatusTitle,
} from "../../../style/form.style";
import {
  AccessManagementButtonContainer,
  EditButton,
} from "../../../style/profile.style";
import {
  AccessListWrapper,
  AccessLoader,
  AccessMobileButtonWrapper,
  AccessPermissionContainer,
  AccessPermissionTitle,
  AcessMgmtMobile,
  CheckboxAccessEditor,
  IconAccessAllowedStatus,
  MobileAccessStatusSave,
  PermissionStatusContainer,
  SelectUserIllustration
} from "../../../style/style.access.mgmt.style";
import { FontSizes } from "../../../style/theme/font";
import CommonColor from "../../../utils/common.colors";
import { CommonImages } from "../../../utils/common.images";
import { BoolToApiValue, BooleanValueCheck } from "../../../utils/utils";
import ShowLoader from "../../Loader/ShowLoader";
import DropDownListEmployee from "../inputs/DropDownListEmployee";

export default function AccessManagementMobile() {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedData, setselectedData] = useState();

  const [Employee, setEmployee] = useState(getEmployeeList()[0]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const accessManagementController = new AccessController();

  useEffect(() => {
    if (Employee === null) return;
    const accessData = employee.employee_access_list_all.filter(
      (access) => access.user_id === Employee._id
    );
    setselectedData(accessData[0]);
  }, [Employee]);

  function getStatusButton(isEditAccessAllowed) {
    const boolValue = BooleanValueCheck(isEditAccessAllowed);
    if (boolValue) {
      return <IconAccessAllowedStatus icon="mdi:success" iconType="success" />;
    }
    return <IconAccessAllowedStatus icon="iconoir:cancel" iconType="cancel" />;
  }
  function statusToggler(value, key) {
    const boolValue = BooleanValueCheck(value);
    return (
      <CheckboxAccessEditor
        {...label}
        checked={boolValue}
        onChange={(e) => changeValue(e.target.checked, key)}
        icon={
          <IconAccessAllowedStatus
            icon="iconoir:cancel"
            iconType="cancel"
            color={CommonColor.text_gray}
          />
        }
        checkedIcon={
          <IconAccessAllowedStatus
            icon="mdi:success"
            iconType="success"
            color={CommonColor.green}
          />
        }
      />
    );
  }
  function changeValue(value, key) {
    const apiValue = BoolToApiValue(value);
    if (key === "attendance") {
      setselectedData((prevValue) => ({ ...prevValue, attendance: apiValue }));
    }
    if (key === "assets") {
      setselectedData((prevValue) => ({ ...prevValue, assets: apiValue }));
    }
    if (key === "basic_details") {
      setselectedData((prevValue) => ({
        ...prevValue,
        basic_details: apiValue,
      }));
    }
    if (key === "events") {
      setselectedData((prevValue) => ({ ...prevValue, events: apiValue }));
    }
    if (key === "holiday") {
      setselectedData((prevValue) => ({ ...prevValue, holiday: apiValue }));
    }
    if (key === "leave_details") {
      setselectedData((prevValue) => ({
        ...prevValue,
        leave_details: apiValue,
      }));
    }
    if (key === "personal_details") {
      setselectedData((prevValue) => ({
        ...prevValue,
        personal_details: apiValue,
      }));
    }
    if (key === "reports") {
      setselectedData((prevValue) => ({ ...prevValue, reports: apiValue }));
    }
    if (key === "salary_details") {
      setselectedData((prevValue) => ({
        ...prevValue,
        salary_details: apiValue,
      }));
    }
    if (key === "speedy_prexelite") {
      setselectedData((prevValue) => ({
        ...prevValue,
        speedy_prexelite: apiValue,
      }));
    }
    if (key === "training_material") {
      setselectedData((prevValue) => ({
        ...prevValue,
        training_material: apiValue,
      }));
    }
    if (key === "users") {
      setselectedData((prevValue) => ({
        ...prevValue,
        users: apiValue,
      }));
    }
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  async function getData() {
    let respData = await accessManagementController.getAccessManagementData();
    dispatch(setEmployeeAccessListAll(respData.data));
  }
  async function saveDetails() {
    const sendData = {
      _id: selectedData._id,
      training_material: selectedData.training_material,
      leave_details: selectedData.leave_details,
      events: selectedData.events,
      attendance: selectedData.attendance,
      basic_details: selectedData.basic_details,
      personal_details: selectedData.personal_details,
      speedy_prexelite: selectedData.speedy_prexelite,
      assets: selectedData.assets,
      reports: selectedData.reports,
      holiday: selectedData.holiday,
      salary_details: selectedData.salary_details,
      users: selectedData.users,
    };
    setIsLoader(true);
    let respData = await accessManagementController.updateAccessManagementData(
      sendData
    );

    getData();

    if (respData) {
      setIsLoader(false);
      const Msg = (
        <LeaveStatusContainer>
          <LeaveStatusTitle>success</LeaveStatusTitle>
          <LeaveStatusMsg>manage permission given sucessfully</LeaveStatusMsg>
        </LeaveStatusContainer>
      );
      handleAlert(Msg, "success");
      setIsEdit(false);
      return;
    }
    setIsLoader(false);
    const Msg = (
      <LeaveStatusContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>manage permission given sucessfully</LeaveStatusMsg>
      </LeaveStatusContainer>
    );
    handleAlert(Msg, "error");
    setIsEdit(false);
  }
  function getEmployeeList() {
    return [
      {
        name: "Select User",
        id: "",
      },
      ...employee.employee_list,
    ];
  }
  return (
    <React.Fragment>
      <AcessMgmtMobile container spacing={2}>
        <Grid item xs={12}>
          <TypoGraphy color={CommonColor.light_primary} weight="600">
            Select User
          </TypoGraphy>
          <DropDownListEmployee
            title=""
            dataList={getEmployeeList()}
            defaultValue="SelectUsewr"
            value={Employee}
            handleChange={(event) => {
              setEmployee(event);
            }}
          />
          {Employee.name !== "Select User" ? (
            <React.Fragment>
              {" "}
              <AccessListWrapper>
                <AccessPermissionContainer>
                  <AccessPermissionTitle />
                </AccessPermissionContainer>

                <AccessPermissionContainer>
                  <AccessPermissionTitle>Attendance</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.attendance)
                      : statusToggler(selectedData?.attendance, "attendance")}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Leave</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.leave_details)
                      : statusToggler(
                          selectedData?.leave_details,
                          "leave_details"
                        )}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Events</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.events)
                      : statusToggler(selectedData?.events, "events")}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Salary</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.salary_details)
                      : statusToggler(
                          selectedData?.salary_details,
                          "salary_details"
                        )}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Basic Details</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.basic_details)
                      : statusToggler(
                          selectedData?.basic_details,
                          "basic_details"
                        )}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Personal Detail</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.personal_details)
                      : statusToggler(
                          selectedData?.personal_details,
                          "personal_details"
                        )}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>
                    Training Material
                  </AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.training_material)
                      : statusToggler(
                          selectedData?.training_material,
                          "training_material"
                        )}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Users</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.users)
                      : statusToggler(selectedData?.users, "users")}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Reports</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.reports)
                      : statusToggler(selectedData?.reports, "reports")}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Assets</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.assets)
                      : statusToggler(selectedData?.assets, "assets")}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>Holiday List</AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.holiday)
                      : statusToggler(selectedData?.holiday, "holiday")}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
                <AccessPermissionContainer>
                  <AccessPermissionTitle>
                    Speedy Prexilites
                  </AccessPermissionTitle>
                  <PermissionStatusContainer>
                    {!isEdit
                      ? getStatusButton(selectedData?.speedy_prexelite)
                      : statusToggler(
                          selectedData?.speedy_prexelite,
                          "speedy_prexelite"
                        )}
                  </PermissionStatusContainer>
                </AccessPermissionContainer>
              </AccessListWrapper>
              <AccessMobileButtonWrapper>
                <AccessManagementButtonContainer isvisible={!isEdit ? 1 : 0}>
                  <EditButton
                    variant="outlined"
                    startIcon={<Icon icon="material-symbols:edit" />}
                    onClick={() => setIsEdit(true)}
                    fullWidth
                  >
                    edit
                  </EditButton>
                </AccessManagementButtonContainer>
                <AccessManagementButtonContainer isvisible={isEdit ? 1 : 0}>
                  <MobileAccessStatusSave
                    variant="contained"
                    startIcon={
                      <Icon icon="material-symbols:check-small-rounded" />
                    }
                    onClick={() => saveDetails()}
                    fullWidth
                  >
                    save
                  </MobileAccessStatusSave>
                </AccessManagementButtonContainer>
              </AccessMobileButtonWrapper>
            </React.Fragment>
          ) : (
            <NoDataContainer mt="55%" mb="20%">
              <SelectUserIllustration />
              <TypoGraphy fontSize={FontSizes.Title}>
                Please select user{" "}
              </TypoGraphy>
            </NoDataContainer>
          )}
        </Grid>
      </AcessMgmtMobile>
      <AccessLoader isvisible={isLoader ? 1 : 0}>
        <ShowLoader loaderData={CommonImages.loaderSecurity} />
      </AccessLoader>
    </React.Fragment>
  );
}
