import { EndPoint } from "../../utils/endpoints";
import synergyLeagueUtils from "../APICallServices/APIUtils";

export default class SynergyLeagueProvider {
  constructor() {
    this.synergyLeagueUtils = new synergyLeagueUtils();
  }
  getTeams = () => {
    return this.synergyLeagueUtils.init().get(EndPoint.synergyLeagueTeams);
  };
  getSubTeams = () => {
    return this.synergyLeagueUtils.init().get(EndPoint.synergyLeagueSubTeams);
  };
  getSports = () => {
    return this.synergyLeagueUtils.init().get(EndPoint.synergyLeagueSports);
  };
  getUpcomingMatches = () => {
    return this.synergyLeagueUtils
      .init()
      .get(EndPoint.synergyLeagueUpcomingMatches);
  };
  getPointsTable = () => {
    return this.synergyLeagueUtils
      .init()
      .get(EndPoint.synergyLeaguePointsTable);
  };
  getRecentMatches = () => {
    return this.synergyLeagueUtils
      .init()
      .get(EndPoint.synergyLeagueRecentMatches);
  };
  addMatch = (data) => {
    return this.synergyLeagueUtils
      .init()
      .post(EndPoint.synergyLeagueMatches, data);
  };
  updateMatch = (data) => {
    return this.synergyLeagueUtils
      .init()
      .post(EndPoint.synergyLeagueMatchesUpdate, data);
  };
  addSports = (data) => {
    return this.synergyLeagueUtils
      .init()
      .post(EndPoint.synergyLeagueSports, data);
  };
  updateSports = (data) => {
    return this.synergyLeagueUtils
      .init()
      .post(EndPoint.synergyLeagueSportsUpdate, data);
  };
  updateTeamMember = (data) => {
    return this.synergyLeagueUtils.init().post(EndPoint.updateTeamMember, data);
  };
  deleteSport = (data) => {
    return this.synergyLeagueUtils
      .init()
      .post(EndPoint.synergyLeagueSportsDelete, data);
  };
  deleteMatch = (data) => {
    return this.synergyLeagueUtils
      .init()
      .post(EndPoint.synergyLeagueMatchDelete, data);
  };
  deleteTeamMember = (data) => {
    return this.synergyLeagueUtils.init().post(EndPoint.deleteTeamMember, data);
  };
  uploadImages = (data) => {
    return this.synergyLeagueUtils.init().post(EndPoint.uploadImages, data);
  };
}
