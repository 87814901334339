import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexContainer } from "../../style/asset.style";
import { IconifyIcon } from "../../style/employee.style";
import { PdfDelIconContainer } from "../../style/policy.style";
import {
  CardPdf,
  EventIntro,
  MUIDivider,
  PdfDescription,
  PdfDescriptionContainer,
  PdfHeader,
  PdfName,
  ReadMoreButton,
  WisdomWednesDayIcon,
} from "../../style/training.style";
import CommonColor from "../../utils/common.colors";
import ConfirmationMenu from "../Popup/ConfirmationMenu";
import ModalPopup from "../Popup/ModalPopup";
import PolicyController from "../../controller/PolicyController";
import { MsgContainer, MsgHeading } from "../../style/form.style";
import { setPolicy } from "../../reducer/policyreduxstore";
import { isLeader } from "../../utils/utils";
import { CommonString } from "../../utils/common.string";
import Icon from "react-multi-date-picker/components/icon";
import { ButtonEditLeave, IconNavbar, MenuOptions } from "../../style/leave.style";
import AddPolicy from "../../screen/Policy/AddPolicy";
import { setIsModalOpen, SetLocalModalName } from "../../reducer/dashboardreduxstore";

export default function PolicyPdf(props) {
  
  const [isHovered, setIsHovered] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const policy = useSelector((state) => state.policy);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedState , setSelectedState] = useState(null);
  const toggleReadMore = (event) => {
    event.stopPropagation(); 
    setIsExpanded(!isExpanded);
  };

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const policy = useSelector((state) => state.policy);
  const policyController = new PolicyController();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [files, setFiles] = useState([]);
  const description = props.file.description;
  const isLongDescription = description.length > 36;
  const open = Boolean(anchorEl);
  const [isPreview, setisPreview] = useState(false);
  function deletePdfConfirmation(event) {
    // event.stopPropagation();
    event.stopPropagation(); 
    setAnchorEl(null);
    setSelectedState("DELETE");
    setIsPopupOpen(true);
  }

  function editPdfConfirmation(event){
    event.stopPropagation(); 
    setAnchorEl(null);
    setSelectedState("EDIT");
    setIsPopupOpen(true);
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  function getConfirmationMessage() {
    return "Are you sure you want to delete policy for " + props.file.name;
  }
  async function confirmDelete() {
    setIsLoading(true);
    const sendData = {
      _id: props.file._id,
    };
    const deleteRes = await policyController.deletePolicy(sendData);
    if (deleteRes) {
      //success
      const sucessMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>successfully deleted the policy</sucessMsg>
        </MsgContainer>
      );

      setTimeout(() => {
        setIsPopupOpen(false);
        handleAlert(sucessMsg, "success");
      }, 1000);
      const trainingData = await policyController.getPolicy();
      
      dispatch(setPolicy(trainingData.data.data));
    } else {
      //error
      const errorMsgF = (
        <MsgContainer>
          <MsgHeading>error</MsgHeading>
          <sucessMsg>please try again later</sucessMsg>
        </MsgContainer>
      );
      setTimeout(() => {
        setIsPopupOpen(false);
        handleAlert(errorMsgF, "error");
      }, 1000);
    }
  }
  function fileUpdater(preview) {
    setFiles(preview);
  }

  function PdfCloser() {
    dispatch(setIsModalOpen(false));
    dispatch(SetLocalModalName(null));
    setisPreview(false);
    setIsPopupOpen(false);
  }

  function getModalChildren() {
    if(selectedState ===  "DELETE")
    return (
      <ConfirmationMenu
        title={getConfirmationMessage()}
        isShow={isLoading}
        yesButtonName={"yes"}
        onClickYesButton={() => confirmDelete()}
        noButtuonName={"no"}
        onClicknoButton={() => setIsPopupOpen(false)}
        color={CommonColor.primary_color}
      />
    );
    else if(selectedState === "EDIT"){
      return (
        <AddPolicy
        isEdit = {true}
        data = {props.file}
        fileUpdater={fileUpdater}
        files={files}
        PdfCloser={() => PdfCloser()}
        isPolicy={true}
      />
      )
    }
    return null
  }

  function getPopupWidth() {
    if (isPreview === true) {
      return "100vw";
    }
    return "fit-content";
  }


  const handleClick = (event) => {
    event.stopPropagation(); 
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ModalPopup
       styleSX={{
        minWidth: getPopupWidth(),
        maxHeight: "100vh",
        margin: 0,
        overflowX: "hidden",
      }}
       open={isPopupOpen}>{getModalChildren()}</ModalPopup>
      <CardPdf
        onClick={() => props.OpenPdf(props.index)}   
        popuptype={props.PopupType}
        isactive={props.index === policy.active_policy}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        index={props.index}
        length={props.length}
      >
        <PdfHeader
       >
           <EventIntro
            >
            <WisdomWednesDayIcon />
            <PdfName>{props.file.name}</PdfName>
            </EventIntro>
            {/* {isLeader() && (
              <PdfDelIconContainer onClick={deletePdfConfirmation}>
                <IconifyIcon icon="mdi:delete" />
              </PdfDelIconContainer> */}
            {/* )} */}

            {
              isLeader() &&
              (<><IconNavbar
                  icon="mi:options-horizontal"
                  onClick={(event) => handleClick(event)}
                />
                <MenuOptions
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <ButtonEditLeave
                    variant="text"
                    onClick={(event) =>
                      deletePdfConfirmation(event)
                    }
                   
                  >
                    delete
                  </ButtonEditLeave>
                  <ButtonEditLeave
                    variant="text"
                    onClick={(event) =>
                    editPdfConfirmation(event)
                    }
                   
                  >
                    edit
                  </ButtonEditLeave>
                </MenuOptions>
                </>)
                
            }
          
        </PdfHeader>
        <MUIDivider />

        <FlexContainer jc="space-between">
      <PdfDescriptionContainer>
        
        <PdfDescription>
          {isExpanded ? description : `${description.slice(0, 36)}${isLongDescription ? "..." : ""}`}
       
        {isLongDescription && (
          <ReadMoreButton onClick={toggleReadMore}>
            {isExpanded ? "Read less" : "Read more"}
          </ReadMoreButton>
        )}
         </PdfDescription>
      </PdfDescriptionContainer>
    </FlexContainer>
      </CardPdf>
    </React.Fragment>
  );
}
