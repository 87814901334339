import React from "react";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import {
  EmployeeListShimmerContainer,
  EmployeeShimmerContainer,
} from "../../../style/loader.style";
import { useWindowSize } from "../../commonComponents/hooks/getWindowDimension";

export default function EmployeeDailyLogsShimmer() {
  const windowWidth = useWindowSize().width;
  return (
    <EmployeeListShimmerContainer>
      <EmployeeShimmerContainer>
        <ShimmerSimpleGallery
          imageType="circular"
          imageHeight={50}
          caption
          row={1}
          col={2}
          fitOnFrame
        />
      </EmployeeShimmerContainer>
      <EmployeeShimmerContainer>
        <ShimmerSimpleGallery
          imageType="circular"
          imageHeight={50}
          caption
          col={2}
          row={1}
          fitOnFrame
        />
      </EmployeeShimmerContainer>
      <EmployeeShimmerContainer>
        <ShimmerSimpleGallery
          imageType="circular"
          imageHeight={50}
          caption
          col={2}
          row={1}
          fitOnFrame
        />
      </EmployeeShimmerContainer>
      <EmployeeShimmerContainer>
        <ShimmerSimpleGallery
          imageType="circular"
          imageHeight={50}
          caption
          row={1}
          col={2}
          fitOnFrame
        />
      </EmployeeShimmerContainer>
      {windowWidth < 600 ? (
        <>
          <EmployeeShimmerContainer>
            <ShimmerSimpleGallery
              imageType="circular"
              imageHeight={50}
              caption
              row={1}
              col={1}
              fitOnFrame
            />
          </EmployeeShimmerContainer>
          <EmployeeShimmerContainer>
            <ShimmerSimpleGallery
              imageType="circular"
              imageHeight={50}
              caption
              row={1}
              col={1}
              fitOnFrame
            />
          </EmployeeShimmerContainer>
          <EmployeeShimmerContainer>
            <ShimmerSimpleGallery
              imageType="circular"
              imageHeight={50}
              caption
              row={1}
              col={1}
              fitOnFrame
            />
          </EmployeeShimmerContainer>
          <EmployeeShimmerContainer>
            <ShimmerSimpleGallery
              imageType="circular"
              imageHeight={50}
              caption
              row={1}
              col={1}
              fitOnFrame
            />
          </EmployeeShimmerContainer>{" "}
          <EmployeeShimmerContainer>
            <ShimmerSimpleGallery
              imageType="circular"
              imageHeight={50}
              caption
              row={1}
              col={1}
              fitOnFrame
            />
          </EmployeeShimmerContainer>
          <EmployeeShimmerContainer>
            <ShimmerSimpleGallery
              imageType="circular"
              imageHeight={50}
              caption
              row={1}
              col={1}
              fitOnFrame
            />
          </EmployeeShimmerContainer>
        </>
      ) : (
        ""
      )}
    </EmployeeListShimmerContainer>
  );
}
