import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustomV2 from "../../../componentsv2/common_components/Button/Button";
import AttendanceController from "../../../controller/attendanceController";
import {
  attendanceMonth,
  monthBasicAttendanceDetail,
} from "../../../reducer/attendancereduxstore";
import { setAttendanceStatus } from "../../../reducer/dashboardreduxstore";
import { getLocalStorage } from "../../../services/LocalStorage/LocalStorage";
import {
  BaseCardDashboardContainer,
  ButtonContainer,
  DashboardIconComponent,
  DataContainer,
  HeadingContainer,
  InOutTimeContainer,
  LogoutImage,
  NameContainer,
  SeparatorIcon,
  SepratorIconContainer,
  SubHeadingContainer,
  TimeDateContainer,
} from "../../../style/dashboard.style";
import CommonColor from "../../../utils/common.colors";
import { userdata } from "../../../utils/common.key";
import { CommonString } from "../../../utils/common.string";
import { Month } from "../../../utils/constant";
import {
  delay,
  formatTime,
  formatTimeNormal,
  formatTimeOnly,
  getMonthBasicDetail,
} from "../../../utils/utils";
import LoginCardShimmer from "../../Loader/shimmers/LoginCardShimmer";
import ConfirmationPopup from "../../Popup/ConfirmationPopup";
import { format } from "date-fns";

export default function LoginCard(props) {
  const employee = useSelector((state) => state.employee);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dashboard = useSelector((state) => state.dashboard);
  const auth = useSelector((state) => state.auth);
  const attendance = useSelector((state) => state.attendance);
  const attendanceController = new AttendanceController();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  function getLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  }

  async function OpenConfirmation() {
    setLoading(true);
    const position = await getLocation().catch((error) => error);

    if (position.code === 1 || position.code === 2 || position.code === 3) {
      const errorMsg = (
        <div>
          <div>Error</div>
          <div>
            Please turn on <strong>location access</strong> to log attendance
          </div>
        </div>
      );
      delay(1000);
      setLoading(false);
      setIsOpen(false);

      handleAlert(errorMsg, "error");
      return;
    }
    const outTime = moment().utcOffset("-0500").format();
    const data = {
      id: dashboard.attendance_data.id,
      out_time: outTime,
    };
    const response = attendanceController.updateOutTime(data);
    if (response) {
      setIsOpen(false);

      dispatch(setAttendanceStatus({ ...dashboard.attendance_data, ...data }));
    }
  }
  async function loginStateChange() {
    setLoading(true);
    await delay(2000);
    setLoading(false);
    setIsOpen(false);
    let outTime = "00:00";
    let navPerm = false;
    const position = await getLocation().catch((error) => error);

    if (position.code === 1 || position.code === 2 || position.code === 3) {
      const errorMsg = (
        <div>
          <div>Error</div>
          <div>
            Please turn on <strong>location access</strong> to log attendance
          </div>
        </div>
      );
      handleAlert(errorMsg, "error");
      return;
    }
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;

    let attendanceDate = moment().utcOffset("-0500").format("YYYY-MM-DD");
    let inTime = moment().utcOffset("-0500").format();
    const data = {
      in_time: inTime,
      out_time: outTime,
      attendance_date: attendanceDate,
      is_absent: false,
      is_late: true,
      lat: lat,
      lon: lon,
      user_id: auth.employee_data.user._id,
      shift_id: auth.employee_data.user.shift_id,
    };

    let res = await attendanceController.addAttendance(data);
    if (res.success) {
      dispatch(setAttendanceStatus({ ...data, id: res.id }));
      const year = new Date().getFullYear().toString();
      const month = Month[new Date().getMonth()];
      const dataMonthOne = {
        user_id: getLocalStorage(userdata).user._id,
        month: month,
        year: year,
      };
      const attendanceData = await attendanceController.getMonthAttendance(
        dataMonthOne
      );
      dispatch(attendanceMonth(attendanceData));
      const monthBasicDetail = getMonthBasicDetail(attendanceData);

      dispatch(monthBasicAttendanceDetail(monthBasicDetail));
      const errorMsg = (
        <div>
          <div>success</div>
          <div>Login Successful</div>
        </div>
      );
      handleAlert(errorMsg, "success");
      // setStatus("out");
      return;
    } else {
      const errorMsg = (
        <div>
          <div>error</div>
          <div>try again after some time</div>
        </div>
      );
      handleAlert(errorMsg, "error");
    }
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  return (
    <React.Fragment>
      <ConfirmationPopup
        open={isOpen}
        onHandleClose={() => setIsOpen(false)}
        confirmationMessage={
          dashboard.attendance_data !== null &&
          dashboard.attendance_data.out_time === "00:00"
            ? CommonString.logoutConfirmation
            : CommonString.loginConfirmation
        }
        Confirmed={() =>
          dashboard.attendance_data !== null &&
          dashboard.attendance_data.out_time === "00:00"
            ? OpenConfirmation()
            : loginStateChange()
        }
        Att={() => setIsOpen(false)}
        yesButtonName={CommonString.yes}
        noButtonName={CommonString.no}
        title={
          dashboard.attendance_data === null
            ? CommonString.login
            : CommonString.logout
        }
        color={CommonColor.primary_color}
        isShow={loading === true}
      />
      {props.LoadingFlag ? (
        <BaseCardDashboardContainer
          isverticalcenter={1}
          delay={props.serialOrder}
        >
          <LoginCardShimmer />
        </BaseCardDashboardContainer>
      ) : dashboard.attendance_data === null ? (
        <BaseCardDashboardContainer
          isverticalcenter={1}
          delay={props.serialOrder}
        >
          <DataContainer>
            <HeadingContainer>
              Welcome,{" "}
              <NameContainer>
                {props.employeeName
                  ? props.employeeName.split(" ")[0].toLowerCase()
                  : ""}
              </NameContainer>
            </HeadingContainer>
            <SubHeadingContainer>
              Manage your attendance, leave management, keep up to date with
              office news and events, all from here.
            </SubHeadingContainer>
            {employee.employee_acess.wfh ? (
              <ButtonContainer component="1">
                {/*
                schriftfarbe in german means font color
                randfarbe in german means border color
                 */}

                <ButtonCustomV2
                  variant="contained"
                  buttonText={"Log In"}
                  onClick={() => setIsOpen(true)}
                />
              </ButtonContainer>
            ) : (
              ""
            )}
          </DataContainer>
          <DashboardIconComponent />
        </BaseCardDashboardContainer>
      ) : dashboard.attendance_data !== null &&
        dashboard.attendance_data.out_time === "00:00" ? (
        <BaseCardDashboardContainer
          isverticalcenter={1}
          delay={props.serialOrder}
        >
          <DataContainer>
            <HeadingContainer>
              Hello,{" "}
              <NameContainer>
                {props.employeeName
                  ? props.employeeName.split(" ")[0].toLowerCase()
                  : ""}
              </NameContainer>
            </HeadingContainer>
            <SubHeadingContainer>
              Manage your attendance, leave management, keep up to date with
              office news and events, all from here.
            </SubHeadingContainer>
            <TimeDateContainer>
              {`Logged In : ${formatTimeNormal(
                dashboard.attendance_data.in_time
              )}`}
            </TimeDateContainer>
            {employee.employee_acess.wfh ? (
              <ButtonContainer>
                {/*
                schriftfarbe in german means font color
                randfarbe in german means border color
                 */}
                <ButtonCustomV2
                  variant="contained"
                  buttonText={"Log Out"}
                  fullWidth
                  onClick={() => setIsOpen(true)}
                />
              </ButtonContainer>
            ) : (
              ""
            )}
          </DataContainer>
          <DashboardIconComponent />
        </BaseCardDashboardContainer>
      ) : (
        <BaseCardDashboardContainer delay={props.serialOrder}>
          <DataContainer>
            <div>
              <HeadingContainer>
                See you Next Day,{" "}
                <NameContainer>
                  {props.employeeName
                    ? props.employeeName.split(" ")[0].toLowerCase()
                    : ""}
                </NameContainer>
              </HeadingContainer>
              <SubHeadingContainer>
                You did great today, lets upgrade for tommorow.
              </SubHeadingContainer>
            </div>
            <InOutTimeContainer>
              <TimeDateContainer>
                {`${formatTime(dashboard.attendance_data.in_time)}`}
              </TimeDateContainer>
              <SepratorIconContainer style={{ alignItems: "center" }}>
                <SeparatorIcon icon="material-symbols:arrow-right-alt-rounded" />
              </SepratorIconContainer>
              <TimeDateContainer>
                {`${formatTime(dashboard.attendance_data.out_time)}`}
              </TimeDateContainer>
            </InOutTimeContainer>
          </DataContainer>
          <LogoutImage />
        </BaseCardDashboardContainer>
      )}
    </React.Fragment>
  );
}
