import { Icon } from "@iconify/react";
import { AppBar, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import NavBar from "../../component/drawer/NavBar";
import AttendanceReports from "../../component/reportsScreen/AttendanceReports";
import { TypoGraphy } from "../../style/common.style";
import { AppBarStyle } from "../../style/drawer.style";
import {
  ReportScreenCard,
  ReportScreenCardIconContainer,
  ReportScreenWrapper,
  ReportsScreenContainer,
} from "../../style/report.style";
import { FontSizes } from "../../style/theme/font";
import { reportScreens } from "../../utils/array.screens";
import CommonColor from "../../utils/common.colors";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { userdata } from "../../utils/common.key";
import LeaveReport from "../../component/reportsScreen/LeaveReport";

export default function Reports(props) {
  const [screen, setScreen] = useState(null);
  const dashboard = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (!props.loadedContent.includes("month basic attendance")) {
      getData();
    }
  }, []);

  async function getData() {
    await props.getDashboardScreenData(getLocalStorage(userdata));
  }
  function ScreenSetter(element) {
    setScreen(element.name);
  }

  function clearScreen() {
    setScreen(null);
  }
  function getScreens() {
    if (screen === reportScreens[0].name) {
      return <AttendanceReports onScreenClose={clearScreen} />;
    }

    if (screen === reportScreens[1].name){
      return <LeaveReport onScreenClose={clearScreen}/>;
    }
    // if (screen === reportScreens[1].name) {
    //   return <SynergyLeagueScoreCard onScreenClose={clearScreen} />;
    // }
    // if (screen === reportScreens[2].name) {
    //   return <Schedule onScreenClose={clearScreen} />;
    // }
  }

  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Reports"}
            NavBarIcon={{
              icon: "",
              name: "",
            }}
          />
        </Toolbar>
      </AppBar>
      {props.LoadingFlag ? (
        <PageRefreshLoader />
      ) : (
        <React.Fragment>
          <ReportScreenWrapper>
            <ReportsScreenContainer
              zIndex="2"
              isvisible={screen === null ? 1 : 0}
              animationTime="1s"
            >
              {reportScreens.map((element, index) => {
                return (
                  <ReportScreenCard
                    key={element}
                    onClick={() => ScreenSetter(element)}
                    tabIndex={`${index}`}
                  >
                    <ReportScreenCardIconContainer>
                      <Icon icon={element.iconName} fontSize={FontSizes.xxxl} />
                    </ReportScreenCardIconContainer>
                    <TypoGraphy
                      color={CommonColor.white}
                      weight="600"
                      fontSize={FontSizes.Title}
                      width="100%"
                      textAlign="center"
                    >
                      {element.name}
                    </TypoGraphy>
                  </ReportScreenCard>
                );
              })}
            </ReportsScreenContainer>
            <ReportsScreenContainer
              zIndex="1"
              isvisible={screen !== null ? 1 : 0}
              animationTime="1s"
            >
              {getScreens()}
            </ReportsScreenContainer>
          </ReportScreenWrapper>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
