import { Icon } from "@iconify/react";
import {
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AttendanceController from "../../controller/attendanceController";
import { setDailyAttendance } from "../../reducer/attendancereduxstore";
import { FlexContainer } from "../../style/asset.style";
import { HtmlTooltip, StyledBadge } from "../../style/common.style";
import {
  DailyLogListContainer,
  EmployeeContainerAttendanceStatus,
  EmployeeLeaveHistoryTextWrapper,
  EmployeeListContainer,
  EmployeeListStatusWrapper,
  EmployeeNameLeaveHistory,
  TitleNotificationNum,
} from "../../style/dashboard.style";
import { PopupNavBarContainer } from "../../style/form.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import { ProfileBaseUrl } from "../../utils/constant";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import RefreshComponent from "../commonComponents/RefreshComponent/RefreshComponent";
import EmployeeLeaveStatusDetails from "../commonComponents/ScreenDashboard/EmployeeListStatus/EmployeeLeaveStatusDetails";
import { useWindowSize } from "../commonComponents/hooks/getWindowDimension";
import ImageAvatar from "../../componentsv2/Images/ImageAvatar";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { GetButton } from "../../style/policy.style";
import { DatePickerDiv, DatePickerStyle } from "../../style/datePicker.style";
import transition from "react-element-popper/animations/transition";

export default function EmployeeListAttendanceStatus(props) {
  const windowWidth = useWindowSize().width;
  const attendance = useSelector((state) => state.attendance);
  const dispatch = useDispatch();
  const attendanceController = new AttendanceController();
  const maxDate = new Date();
  const [DateDiff, setDateDiff] = useState(
    moment().diff(attendance.attendance_update_moment, "minutes")
  );
  useEffect(() => {
    const interval = setInterval(
      () =>
        setDateDiff(
          moment().diff(moment(attendance.attendance_update_moment), "minutes")
        ),
      60000
    );
    return () => {
      clearInterval(interval);
    };
  }, [DateDiff]);

  function getProfileUrl(employee) {
    if (employee.profile_image_url === null || !employee.profile_image_url)
      return CommonImages.NoUser;
    return ProfileBaseUrl + employee.profile_image_url;
  }

  async function refreshList() {
    if (DateDiff === 0) return;
    const dailyAttendance = await attendanceController.getDailyAttendance();

    if (dailyAttendance) {
      setDateDiff(0);
      dispatch(setDailyAttendance(dailyAttendance));
    }
  }
  const [selectedDate, setSelectedDate] = useState(new DateObject());

  const handleDateChange = (dateObject) => {
    const year = dateObject.year.toString();
    const month = dateObject.month.number.toString().padStart(2, "0");
    const day = dateObject.day.toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setSelectedDate(formattedDate);
  };

  async function getAttendsByDates() {
    const dailyAttendance = await attendanceController.getAllAttenByDay({
      date: selectedDate,
    });

    if (dailyAttendance) {
      dispatch(setDailyAttendance(dailyAttendance));
    }
  }

  return (
    <React.Fragment>
      <DialogTitle>
        <PopupNavBarContainer>
          <PopupNavBar
            onHandleClose={props.onHandleClose}
            componentType="form"
            title={"Daily Attendance"}
          />
        </PopupNavBarContainer>
      </DialogTitle>

      <div className="flex flex-row items-center justify-between w-full p-3">
        <div className="flex items-center">
          <DatePickerDiv>
            <DatePickerStyle
              value={selectedDate}
              onChange={handleDateChange}
              animations={[transition()]}
              maxDate={maxDate}
              placeholder="Select Start date"
            />
          </DatePickerDiv>
        </div>

        <div
          className={`flex ${
            windowWidth > 600 ? "w-1/4" : "w-full"
          } justify-end`}
        >
          <div
            className="w-1/2 cursor-pointer"
            onClick={() => getAttendsByDates()}
          >
            <GetButton>
              <p className="text-white">Get Attends</p>
            </GetButton>
          </div>
        </div>
      </div>

      <DialogContent dividers={true}>
        <EmployeeListStatusWrapper>
          <FlexContainer>
            <Icon
              icon="fluent:person-available-24-filled"
              color={CommonColor.green}
            />

            <EmployeeNameLeaveHistory
              schriftgewicht="500"
              schriftfarbe={CommonColor.green}
              mobilewidth="100%"
              jc="flex-start"
            >
              Present
            </EmployeeNameLeaveHistory>
            <TitleNotificationNum bg={CommonColor.green}>
              {console.log("randstand player", attendance.daily_attendance)}
              {attendance.daily_attendance
                ? attendance.daily_attendance.present?.length
                : ""}
            </TitleNotificationNum>
          </FlexContainer>
          <Divider light={true} />
          <EmployeeListContainer>
            {attendance.daily_attendance !== null
              ? attendance.daily_attendance.present?.map(
                  (userAttendance, index) => {
                    return (
                      <HtmlTooltip
                        key={index}
                        title={
                          <Fragment>
                            <EmployeeLeaveStatusDetails
                              data={userAttendance}
                              status="present"
                            />
                          </Fragment>
                        }
                        arrow={true}
                        placement="right"
                        enterDelay={windowWidth < 600 ? 500 : 0}
                        leaveDelay={windowWidth < 600 ? 200 : 0}
                      >
                        <EmployeeContainerAttendanceStatus>
                          <FlexContainer
                            flexDirection="column"
                            jc="center"
                            key={userAttendance}
                            width="intial"
                          >
                            <StyledBadge
                              badgecolor={
                                userAttendance.is_late === "1"
                                  ? CommonColor.secondary_color
                                  : CommonColor.green
                              }
                              color="primary"
                              overlap="circular"
                              badgeContent=" "
                              variant="dot"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <DailyLogListContainer>
                                <div style={{ height: "40px", width: "40px" }}>
                                  <ImageAvatar
                                    src={getProfileUrl(
                                      userAttendance.recruiter
                                    )}
                                    alt=""
                                    defaultSrc={CommonImages.NoUser}
                                    height="40px"
                                    width="40px"
                                  />
                                </div>
                              </DailyLogListContainer>
                            </StyledBadge>
                            <EmployeeLeaveHistoryTextWrapper>
                              <EmployeeNameLeaveHistory
                                schriftgewicht="500"
                                width="100%"
                                lettersize={FontSizes.SmallText}
                                textAlign="center"
                              >
                                {userAttendance.recruiter.name.split(" ")[0]}
                              </EmployeeNameLeaveHistory>
                            </EmployeeLeaveHistoryTextWrapper>
                          </FlexContainer>
                        </EmployeeContainerAttendanceStatus>
                      </HtmlTooltip>
                    );
                  }
                )
              : ""}
          </EmployeeListContainer>
          <FlexContainer>
            <Icon icon="mdi:ghost" color={CommonColor.secondary_color} />

            <EmployeeNameLeaveHistory
              schriftgewicht="500"
              schriftfarbe={CommonColor.secondary_color}
              mobilewidth="100%"
              jc="flex-start"
            >
              Absent
            </EmployeeNameLeaveHistory>
            <TitleNotificationNum bg={CommonColor.secondary_color}>
              {attendance.daily_attendance !== null
                ? attendance.daily_attendance.absent.length
                : ""}
            </TitleNotificationNum>
          </FlexContainer>

          <Divider light={true} />
          <EmployeeListContainer>
            {attendance.daily_attendance !== null
              ? attendance.daily_attendance.absent.length !== 0 &&
                attendance.daily_attendance.absent.map(
                  (userAttendance, index) => {
                    return (
                      <HtmlTooltip
                        key={index}
                        title={
                          <Fragment>
                            <EmployeeLeaveStatusDetails
                              data={userAttendance}
                              status="not present"
                            />
                          </Fragment>
                        }
                        arrow={true}
                        placement="right"
                        enterDelay={windowWidth < 600 ? 500 : 0}
                        leaveDelay={windowWidth < 600 ? 200 : 0}
                      >
                        <EmployeeContainerAttendanceStatus>
                          <FlexContainer
                            flexDirection="column"
                            jc="center"
                            key={userAttendance}
                            width="intial"
                          >
                            <StyledBadge
                              badgecolor={CommonColor.red}
                              color="primary"
                              overlap="circular"
                              badgeContent=""
                              variant="dot"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <div style={{ height: "40px", width: "40px" }}>
                                <ImageAvatar
                                  src={getProfileUrl(userAttendance)}
                                  alt=""
                                  defaultSrc={CommonImages.NoUser}
                                ></ImageAvatar>
                              </div>
                            </StyledBadge>
                            <EmployeeLeaveHistoryTextWrapper>
                              <EmployeeNameLeaveHistory
                                schriftgewicht="500"
                                lettersize={FontSizes.SmallText}
                                width="100%"
                              >
                                {userAttendance.name.split(" ")[0]}
                              </EmployeeNameLeaveHistory>
                            </EmployeeLeaveHistoryTextWrapper>
                          </FlexContainer>
                        </EmployeeContainerAttendanceStatus>
                      </HtmlTooltip>
                    );
                  }
                )
              : ""}
          </EmployeeListContainer>
          <FlexContainer>
            <Icon icon="ic:twotone-time-to-leave" />

            <EmployeeNameLeaveHistory
              schriftgewicht="500"
              schriftfarbe={CommonColor.text_gray}
              mobilewidth="100%"
              jc="flex-start"
            >
              On Leave
            </EmployeeNameLeaveHistory>
            <TitleNotificationNum bg={CommonColor.text_gray}>
              {attendance.daily_attendance !== null
                ? attendance.daily_attendance.on_leave?.length
                : ""}
            </TitleNotificationNum>
          </FlexContainer>

          <Divider light={true} />
          <EmployeeListContainer>
            {attendance.daily_attendance !== null
              ? attendance.daily_attendance.on_leave?.map(
                  (userAttendance, index) => {
                    return (
                      <HtmlTooltip
                        key={index}
                        title={
                          <Fragment>
                            <EmployeeLeaveStatusDetails
                              data={userAttendance}
                              status="not present"
                            />
                          </Fragment>
                        }
                        arrow={true}
                        placement="right"
                        enterDelay={windowWidth < 600 ? 500 : 0}
                        leaveDelay={windowWidth < 600 ? 200 : 0}
                      >
                        <EmployeeContainerAttendanceStatus>
                          <FlexContainer
                            flexDirection="column"
                            jc="center"
                            key={userAttendance}
                            width="intial"
                          >
                            <StyledBadge
                              badgecolor={CommonColor.red}
                              color="primary"
                              overlap="circular"
                              badgeContent=""
                              variant="dot"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <div style={{ height: "40px", width: "40px" }}>
                                <ImageAvatar
                                  src={getProfileUrl(userAttendance.recruiter)}
                                  alt=""
                                  defaultSrc={CommonImages.NoUser}
                                />
                              </div>
                            </StyledBadge>
                            <EmployeeLeaveHistoryTextWrapper>
                              {/*
                schriftfarbe in german means font color
                schriftgewicht in german means font weight
                 */}
                              <EmployeeNameLeaveHistory
                                schriftgewicht="500"
                                lettersize={FontSizes.SmallText}
                                width="100%"
                              >
                                {userAttendance.recruiter.name.split(" ")[0]}
                              </EmployeeNameLeaveHistory>
                            </EmployeeLeaveHistoryTextWrapper>
                          </FlexContainer>
                        </EmployeeContainerAttendanceStatus>
                      </HtmlTooltip>
                    );
                  }
                )
              : ""}
          </EmployeeListContainer>
        </EmployeeListStatusWrapper>
      </DialogContent>
      <DialogActions>
        <RefreshComponent
          refreshList={() => refreshList()}
          DateDiff={DateDiff}
        />
      </DialogActions>
    </React.Fragment>
  );
}
