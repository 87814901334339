import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import LeaveController from "../../controller/LeaveController";
import { updateLeaveNumbers } from "../../reducer/leavereduxstore";
import {
  EmployeeCardDivider,
  GraphDetailContainer,
  LeaveIndicator,
  LeaveInfoCardInnerContainer,
  LeaveInfoDetailContainer,
  LeaveInfoSmallContainer,
  LeaveInfoTextContainer,
  VerticalScrollableAreaLeave,
} from "../../style/leave.style";
import {
  LeaveInfoCardDataContainer,
  LeaveInfoData,
  LeaveInfoTitle,
  ScrollerContent,
} from "../../style/profile.style";

import CircularLeaveGraph from "../commonComponents/CircularLeaveGraph";
import PageRefreshLoader from "../Loader/PageRefreshLoader";

import moment from "moment";
import LeaveInfoCard from "../LeaveInfo/LeaveInfoCard";
import WorkFromHomeInfoCard from "./WorkFromHomeInfoCard";
import { CommonString } from "../../utils/common.string";

export default function WorkFromHomeDetail(props) {
//   const auth = useSelector((state) => state.auth);
//   const leave = useSelector((state) => state.leave);

     const wfh = useSelector((state) =>state.workfromhome)


//   const leaveController = new LeaveController();
//   const dispatch = useDispatch();
//   useEffect(() => {
//     getLeaveNumbers();
//   }, []);

//   async function getLeaveNumbers() {
//     if (auth.employee_data.user == null) return;
//     const sendData = {
//       recruiter_id: auth.employee_data.user._id,
//       month: moment().month() + 1,
//       year: moment().year(),
//     };

//     const data = await leaveController.getLeaveNumbers(sendData);
//     if (data !== null) {
//       dispatch(updateLeaveNumbers(data));
//     }
//   }
  const HeightDetermine = (leaveComponent) => {
    if (leaveComponent === "Leave Info") {
      return "44vh";
    } else if (leaveComponent === "leave Application") {
      return "62vh";
    }
  };
  function getUserLeaveDetails() {
    if ( wfh.work_from_home_data?.length === 0 ) return;
    let wfhData = [];
    wfh.work_from_home_data?.filter((data) => (data.status !== CommonString.pending
       && props.selectedEmp?._id === data.user_id  ))
       .map((e, index) => {
      wfhData.push(
        <LeaveInfoCardInnerContainer style={{ margin: "4%" }}>
            <WorkFromHomeInfoCard
                        onClickOn = {false}
                        index={index}
                        data={e}
                        length={wfh.work_from_home_data.length}
                        // ActiveEmployeeData={ActiveEmployeeData}
                        isEdit={true}
                        ActiveEmployeeIndex={""}
                        onClick={()=>{}}
                        setselectedStatus={()=>{}}
                        setSelectedWFH = {props.setSelectedWFH}
                      />
        </LeaveInfoCardInnerContainer>
      );
    });

    return wfhData;
  }


  return (
    <React.Fragment>
      <LeaveInfoDetailContainer
        leaveComponent={props.leaveComponent}
        component={props.screen}
        ispadding={!Number.isInteger(props.leaveShowNumber)}
      >
        <EmployeeCardDivider componentType={props.screen} />
        <VerticalScrollableAreaLeave
          height={"100%"}
          unit={""}
          mobileheight={HeightDetermine(props.leaveComponent)}
          component={0}
          id="scrollableLeaveInfoComponent"
        >
          <ScrollerContent>
            <GraphDetailContainer component={props.screen}>
              <LeaveInfoTextContainer>
                <LeaveIndicator component="1"></LeaveIndicator>
                <LeaveInfoCardDataContainer>
                  <LeaveInfoData component="1" horizintalFlex="flex-start">
                    {wfh.total_days}
                  </LeaveInfoData>
                  <LeaveInfoTitle component="1" parentComponent={props.screen}>
                    Total WFH
                  </LeaveInfoTitle>
                </LeaveInfoCardDataContainer>
              </LeaveInfoTextContainer>
              <CircularLeaveGraph
              isWfh = {true}
                used_leave={wfh.used_days}
                total_leave={wfh.total_days}
                leave_bal={wfh.remaining_days}
                remainingBalanace={wfh.remaining_days}
                circleWidth={props.circleWidth}
              />

              <LeaveInfoTextContainer>
                <LeaveInfoCardDataContainer component="2">
                  <LeaveInfoData component="2" horizintalFlex="flex-end">
                    {wfh.used_days}
                  </LeaveInfoData>
                  <LeaveInfoTitle component="2" parentComponent={props.screen}>
                    WFHs Used
                  </LeaveInfoTitle>
                </LeaveInfoCardDataContainer>
                <LeaveIndicator component="2"></LeaveIndicator>
              </LeaveInfoTextContainer>
            </GraphDetailContainer>
            <LeaveInfoSmallContainer>
              {props.isLoading ? (
                <PageRefreshLoader />
              ) : (
                getUserLeaveDetails(props.leaveList)
              )}
            </LeaveInfoSmallContainer>
          </ScrollerContent>
        </VerticalScrollableAreaLeave>
      </LeaveInfoDetailContainer>
    </React.Fragment>
  );
}
