import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import SynergyLeagueScoreCard from "../../component/reportsScreen/SynergyLeagueScoreCard";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { ScreensContainer } from "../../style/common.style";
import { userdata } from "../../utils/common.key";
import { level100 } from "../../utils/constant";
import Apile from "../Appeal/Appeal";
import Assets from "../Assets/Assets";
import Employee from "../Employee/Employee";
import EventList from "../EventList/EventList";
import HolidayList from "../Holiday/HolidayList";
import LeaveApplication from "../LeaveApplication/LeaveApplication";
import LeaveInfoScreen from "../LeaveInfo/LeaveInfo";
import ManageAccess from "../ManageAccess/ManageAccess";
import OfficeTimings from "../OfficeTimings.js/OfficeTimings";
import PageNotFound from "../PageNotFound/PageNotFound";
import Policy from "../Policy/Policy";
import Profilev2 from "../Profile/Profilev2";
import Reports from "../Reports/Reports";
import TrainingMaterial from "../TrainingMaterial/TrainingMaterial";
import WorkFromHome from "../WorkFromHome/WorkFromHome";
import DashboardScreen from "../dashboard/DashboardScreen";

export default function Screens(props) {
  const auth = useSelector((state) => state.auth);
  const employeeAcess = useSelector((state) => state.employee.employee_acess);

  return (
    <React.Fragment>
      <ScreensContainer>
        <Switch>
          <Route exact path="/home/dashboard">
            <DashboardScreen
              open={props.open}
              loadedContent={props.loadedContent}
              getDashboardScreenData={props.getDashboardScreenData}
              loadedPages={props.loadedPages}
            />
          </Route>

          {/* APILE  ->  */}

          <Route exact path="/home/appeal-attendance">
            <Apile
              open={props.open}
              getAppealData={props.getAppealData}
              getDashboardScreenData={props.getDashboardScreenData}
              loadedPages={props.loadedPages}
            />
          </Route>

          <Route exact path="/home/work-from-home">
            <WorkFromHome
              open={props.open}
              // getDashboardScreenData={props.getDashboardScreenData}
              loadedPages={props.loadedPages}
              getWFH={props.getWFH}
            />
          </Route>

          {/*  */}
          <Route exact path="/home/leave-info">
            {employeeAcess.leave_details ? (
              <LeaveInfoScreen
                open={props.open}
                loadedContent={props.loadedContent}
                getDashboardScreenData={props.getDashboardScreenData}
              />
            ) : (
              <PageNotFound />
            )}
          </Route>
          <Route exact path="/home/leave-application">
            <LeaveApplication
              open={props.open}
              loadedContent={props.loadedContent}
              getDashboardScreenData={props.getDashboardScreenData}
            />
          </Route>
          <Route exact path="/home/events">
            <EventList
              open={props.open}
              getEmployeeList={props.getEmployeeList}
            />
          </Route>
          <Route exact path="/home/memebers">
            <Employee
              open={props.open}
              getDashboardScreenData={props.getDashboardScreenData}
              loadedContent={props.loadedContent}
            />
          </Route>
          <Route exact path="/home/synergy-league">
            <SynergyLeagueScoreCard
              loadedPages={props.loadedPages}
              open={props.open}
              getSynergyLeagueData={props.getSynergyLeagueData}
            />
          </Route>
          <Route exact path="/home/training-material">
            <TrainingMaterial
              getTrainingMaterial={props.getTrainingMaterial}
              loadedPages={props.loadedPages}
              open={props.open}
            />
          </Route>
          <Route exact path="/home/life-at-prexel">
            <HolidayList
              open={props.open}
              getLifeAtPrexel={props.getLifeAtPrexel}
              getTypingData={props.getTypingData}
              loadedPages={props.loadedPages}
              getEmployeeList={props.getEmployeeList}
            />
          </Route>
          <Route exact path="/home/office-timings">
            {getLocalStorage(userdata)?.level.name === level100 ? (
              <OfficeTimings
                open={props.open}
                getOfficeTimings={props.getOfficeTimings}
                loadedPages={props.loadedPages}
              />
            ) : (
              <PageNotFound />
            )}
          </Route>
          <Route exact path="/home/reports">
            {employeeAcess.reports ? (
              <Reports
                open={props.open}
                getDashboardScreenData={props.getDashboardScreenData}
                loadedContent={props.loadedContent}
              />
            ) : (
              <PageNotFound />
            )}
          </Route>
          <Route exact path="/home/assets">
            {employeeAcess.assets ? (
              <Assets
                open={props.open}
                getAssets={props.getAssets}
                loadedPages={props.loadedPages}
              />
            ) : (
              <PageNotFound />
            )}
          </Route>
          <Route exact path="/home/access">
            {auth.employee_data.level.name === level100 ? (
              <ManageAccess
                open={props.open}
                getAccessScreenData={props.getAccessScreenData}
                loadedPages={props.loadedPages}
              />
            ) : (
              <PageNotFound />
            )}
          </Route>
          {/* <Route exact path="/home/payroll">
            {employeeAcess.salary_details ? (
              <Payroll LoadingFlag={props.LoadingFlag} open={props.open} />
            ) : (
              <PageNotFound />
            )}
          </Route> */}
          <Route exact path="/home/profile">
            <Profilev2
              employeeDetail={auth.employee_data.user}
              open={props.open}
              isPopupOpen={props.open}
              component={"main"}
              loadedPages={props.loadedPages}
              getDashboardScreenData={props.getDashboardScreenData}
            />
          </Route>
          <Route exact path="/home/policy">
            <Policy
              open={props.open}
              isPopupOpen={props.open}
              loadedPages={props.loadedPages}
              getPolicy={props.getPolicy}
            />
          </Route>
        </Switch>
      </ScreensContainer>
    </React.Fragment>
  );
}
