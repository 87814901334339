import React from "react";
import Slide from "@mui/material/Slide";
import { ModalContainer } from "../../style/form.style";
import { useMediaQuery, useTheme } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalPopup(props) {
  const fullScreen = useMediaQuery("(max-width:600px)");
  return (
    <ModalContainer
      open={props.open}
      TransitionComponent={Transition}
      fullScreen={Boolean(!props.issmallScreen) && fullScreen}
      maxWidth={props.popupSize ?? "xs"}
      fullWidth={true}
      scroll="paper"
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { ...props.styleSX, position: "static" },
      }}
    >
      {props.children}
    </ModalContainer>
  );
}
