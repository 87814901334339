import { Menu } from "@mui/material";
import styled from "styled-components";

const WFHMenuOptions = styled(Menu)`
  & ul {
    width: 120px;
  }
`;

export { WFHMenuOptions };
