import React from "react";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import {
  CancelButton,
  LogoutConfirmationButtonsContainer,
  LogoutConfirmationContainer,
  LogoutTitle,
} from "../../style/detailpopup.style";
import CommonColor from "../../utils/common.colors";
import { Div } from "../../style/common.style";

export default function ConfirmationMenu(props) {
  const buttonContainerGrid = props.isCancelButton
    ? "grid grid-cols-3 gap-x-3"
    : "grid grid-cols-2 gap-x-2";
  return (
    <React.Fragment>
      <LogoutConfirmationContainer>
        <LogoutTitle>{props.title}</LogoutTitle>
        <LogoutConfirmationButtonsContainer>
          {props.isShow ? (
            <div style={{ marginRight: 20 }}>
              <div class="loader"></div>
            </div>
          ) : null}
          {/*
                schriftfarbe in german means font color
                randfarbe in german means border color
                 */}
          <Div className="flex justify-end items-center">
            <Div className={buttonContainerGrid}>
              <Div className="col-span-1">
                <ButtonCustomV2
                  variant="contained"
                  buttonText={props.yesButtonName}
                  customTextColor={CommonColor.white}
                  customBGColor={props.color}
                  customOutlineColor={props.color}
                  onClick={() => props.onClickYesButton()}
                />
              </Div>
              <Div className="col-span-1">
                <ButtonCustomV2
                  variant="outlined"
                  buttonText={props.noButtuonName}
                  customTextColor={props.color}
                  customBGColor={CommonColor.transparent}
                  hoverBG={CommonColor.primary_color_opacity_05}
                  customOutlineColor={props.color}
                  onClick={() => props.onClicknoButton()}
                />
              </Div>
              {props.isCancelButton ? (
                <Div className="col-span-1">
                  <ButtonCustomV2
                    icon="iconoir:cancel"
                    customBGColor={CommonColor.transparent}
                    hoverBG={CommonColor.primary_color_opacity_05}
                    customTextColor={props.color}
                    customOutlineColor={props.color}
                    onClick={() => props.onClickCancelButton()}
                  />
                </Div>
              ) : null}
            </Div>
          </Div>
        </LogoutConfirmationButtonsContainer>
      </LogoutConfirmationContainer>
    </React.Fragment>
  );
}

//
