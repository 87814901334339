import { Icon } from "@iconify/react";
import { Menu } from "@mui/material";
import React, { useState } from "react";
import { TypoGraphy } from "../../style/common.style";
import {
  AddButton,
  AddSpeedyPrexelitesContainer,
  HolidayNavBarContainerMobile,
} from "../../style/holiday.style";
import { FontSizes } from "../../style/theme/font";
import { SpeedyPrexeliteTitle } from "../../utils/array.screens";
import CommonColor from "../../utils/common.colors";
import MenuOptionListNavbarMobile from "./MenuOptionListNavbarMobile";

export default function HolidayNavBarMobile(props) {
  const [anchorEl, setAnchorEl] = useState();
  function handleClose() {
    setAnchorEl(undefined);
  }
  function handleOptionClick(element) {
    setAnchorEl(undefined);
    props.handleClick(element);
  }
  function popOverOpener(event) {
    setAnchorEl(event.currentTarget);
  }
  return (
    <HolidayNavBarContainerMobile>
      <AddSpeedyPrexelitesContainer isvisible={1}>
        <AddButton
          variant="contained"
          onClick={(event) => popOverOpener(event)}
          endIcon={
            <Icon
              icon="material-symbols:arrow-drop-down"
              color={CommonColor.white}
            />
          }
        >
          <TypoGraphy width="max-content" color="inherit" fontSize="inherit">
            {props.selectedOption}
          </TypoGraphy>
        </AddButton>
      </AddSpeedyPrexelitesContainer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuOptionListNavbarMobile
          handleOptionClick={handleOptionClick}
          list={SpeedyPrexeliteTitle.map((element) => element.name)}
        />
      </Menu>
      {props.getAccess() ? (
        <AddSpeedyPrexelitesContainer isvisible={props.getAccess() ? 1 : 0}>
          <AddButton
            variant="outlined"
            onClick={() => props.openAddSpeedModal()}
            startIcon={
              <Icon icon="material-symbols:add" fontSize={FontSizes.xl} />
            }
          >
            Add Speedy Prexelite
          </AddButton>
        </AddSpeedyPrexelitesContainer>
      ) : (
        ""
      )}
    </HolidayNavBarContainerMobile>
  );
}
