import { Icon } from "@iconify/react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import styled, { keyframes } from "styled-components";
import { FontSizes } from "../style/theme/font";
import CommonColor from "../utils/common.colors";
import {
  DeviceSizes,
  PageHeaderContainer,
  VerticalScrollableArea,
  opacityChangeAnimation,
} from "./common.style";
import { Theme } from "./theme/color";
const leaveInfoCardLoadAnimation = keyframes`
0%{
  opacity: 0;
  translate: 0 20px;
};
100%{
  opacity: 1;
  translate: 0 0;
};
`;

const NavbarHeading = styled.div`
  font-family: "Mulish";

  font-weight: 700;
  color: ${Theme.primary_color.primary_color};
  font-size: ${FontSizes.Title};
`;
const NavBarFilterContainer = styled.div`
  @media ${DeviceSizes.bigScreen} {
    margin-top: 1%;
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin-top: 2%;
  }
`;
const NavBarFilterText = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  color: ${Theme.primary_color.light_text};
  font-size: ${FontSizes.Para};
  margin-top: 1%;
`;
const NavBarFilterButtonContainer = styled.div`
  display: flex;
  /* margin-top: 2%; */
  width: 100%;
  @media ${DeviceSizes.bigScreen} {
    margin-top: 1%;
  }
`;
const EmployeeLeaveInfoContainer = styled.div`
  pointer-events: ${(props) => (props.noclick ? "none" : "")};
  cursor: ${(props) => (props.noclick ? "" : "pointer")};
  background-color: ${Theme.primary_color.white};
  margin-left: ${(props) => (props.screen === 0 ? "1%" : "")};
  border-radius: 4px;
  padding: 3%;
  margin-bottom: ${(props) => (props.screen === 0 ? "2%" : "2%")};
  margin-right: ${(props) => (props.screen === 0 ? "1%" : "")};
  padding-top: ${(props) => (props.isleaveapplication ? "0%" : "")};
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
  border-color: ${(props) =>
    props.isActive ? Theme.primary_color.secondary_color : "transparent"};
  border-color: ${(props) => (props.isleaveapplication ? "transparent" : "")};
  border-width: 1px;
  border-style: solid;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  -webkit-box-shadow: 0px 0px 56px -47px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 56px -47px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 56px -47px rgba(0, 0, 0, 1);
  opacity: 0;
  animation-name: ${leaveInfoCardLoadAnimation};
  animation-duration: ${(props) => props.index / props.length}s;
  animation-delay: ${(props) => props.index / props.length}s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  & svg {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    box-shadow: 0px 4.466200351715088px 27.91375160217285px -5.58275032043457px #00000026;
    & svg {
      opacity: 1;
    }
  }

  @media ${DeviceSizes.bigScreen} {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  @media ${DeviceSizes.mobile} {
    padding: 4%;
    margin-left: 0;
    margin-bottom: 4%;
    margin-right: 0;
  }
`;
const EmployeeLeaveDataNavbarContainer = styled.div`
  display: ${(props) => (props.isdisplay === 1 ? "" : "none")};
`;
const EmployeeLeaveDataNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const EmployeeNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  width: 100%;
  font-size: ${FontSizes.Para};
`;
const NameContainer = styled.div`
  && {
    padding-top: 4px;
    font-family: "Mulish";
    margin-bottom: ${(props) => (props.ispaddingbottom === 1 ? "0px" : "5px")};
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
    font-size: ${FontSizes.xs};
    /* width: 61px; */
  }
`;

const EmployeeLogo = styled(Avatar)`
  && {
    width: 30px;
    height: 30px;
    background-color: ${Theme.primary_color.light_orange};
    font-family: "Mulish";
    font-weight: 700;
    font-size: ${FontSizes.Para};
    color: ${Theme.primary_color.secondary_color};
  }
`;
const EmployeeName = styled.div`
  margin-left: 2%;
  margin-right: 1%;
  font-weight: 600;
  color: ${Theme.primary_color.primary_color};
`;
//props.leaveComponent === CommonString.leaveApplication ? "100%" : "18%"}
//props.leaveComponent === CommonString.leaveApplication ? "15%" : "2%"}
const EmployeeLeaveStatus = styled.div`
  && {
    display: ${(props) => (props.isdisplay === 1 ? "flex" : "none")};
    justify-content: center;

    height: 30px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
      props.$leaveStatus === "PENDING"
        ? Theme.primary_color.light_orange
        : props.$leaveStatus === "APPROVED"
        ? Theme.primary_color.success_button_background
        : Theme.primary_color.declined_status_background};
    color: ${(props) =>
      props.$leaveStatus === "PENDING"
        ? Theme.primary_color.secondary_color
        : props.$leaveStatus === "APPROVED"
        ? Theme.primary_color.green
        : Theme.primary_color.red};
    border-radius: 5px;
    font-size: ${FontSizes.SubPara};
    font-weight: 700;
    font-family: "Mulish";
    text-transform: uppercase;
    @media ${DeviceSizes.mobile} {
      width: 110px;
      height: 100%;
      padding: 2%;
    }
  }
`;
const EmployeeCardDivider = styled(Divider)`
  && {
    display: ${(props) => (props.isdisplay === 1 ? "" : "none")};
    margin: ${(props) => props.m};
    margin-top: ${(props) => props.mt};
    margin-bottom: ${(props) => props.mb};
    margin-left: ${(props) => props.ml};
    margin-right: ${(props) => props.mr};
    border-color: ${CommonColor.divider_gray};

    @media ${DeviceSizes.bigScreen} {
      margin-top: 2%;
    }
  }
`;
const EmployeeLeaveDataContainer = styled.div`
  @media ${DeviceSizes.bigScreen} {
    /* margin-top: 1%; */
  }
`;
const EmployeeLeaveDuaration = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
  display: flex;
  width: 100%;
  align-items: center;
`;
const EmployeeTimeContainer = styled.div`
  display: flex;
  margin-top: ${(props) => (props.mt ? "0%" : "1%")};
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
  @media ${DeviceSizes.bigScreen} {
    margin-top: 1%;
  }
`;
const EmployeetimingHeader = styled.div`
  font-weight: 400;
  text-transform: capitalize;
`;
const Colon = styled.div`
  margin-left: 10px;
`;
const ColonLeaveEndTime = styled.div`
  margin-left: 27px;
`;
const EmployeeLeaveTime = styled.div`
  font-weight: 500;
`;
const EmployeeLeaveTimeAndTypeContainer = styled.div`
  display: flex;
  align-items: baseline;
`;
const EmployeeLeaveHalfDay = styled.div`
  font-weight: 600;
  font-size: ${FontSizes.SubPara};
  color: ${Theme.primary_color.secondary_color};
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmployeeLeaveNoteContainer = styled.div`
  margin-bottom: 2%;
  margin-top: 2%;

  @media ${DeviceSizes.bigScreen} {
    margin-top: 1%;
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
const EmployeeLeaveNoteHeading = styled.div`
  font-family: "Mulish";
  display: inline;
  font-weight: 500;
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
  margin-top: 2%;
`;
const EmployeeLeaveNote = styled.span`
  font-family: "Mulish";
  font-weight: 400;
  font-size: ${FontSizes.Para};
  max-height: ${(props) =>
    props.ActiveIndex === props.index ? "120px" : "50px"};
  width: 100%;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  color: ${Theme.primary_color.primary_color};
  letter-spacing: 0.01em;
  margin-bottom: 1%;
  cursor: pointer;
  overflow: hidden;
  overflow-wrap: break-word;
`;

//30px
const EmployeeLeaveFooter = styled.div`
  display: ${(props) => (props.isdisplay === 1 ? "flex" : "none")};
  margin-top: 1%;
`;

const LeaveInfoDetailContainer = styled.div`
  position: relative;
  background-color: ${Theme.primary_color.white};
  border-radius: 4px;
  padding: ${(props) => (props.ispadding ? "5%" : "0%")};
  margin-top: 0%;
  width: ${(props) => (props.ispadding ? "580px" : "100%")};
  overflow-x: hidden;
  height: 100%;
  padding-top: 2%;
  @media ${DeviceSizes.bigScreen} {
    width: 100%;
  }
  @media ${DeviceSizes.laptop} {
    width: ${(props) =>
      props.leaveComponent === "leave Application" ? "100%" : ""};
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
const LeaveInfoDetailNav = styled.div`
  display: ${(props) => (props.component === 0 ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  font-family: "Mulish";

  font-weight: 700;
  font-size: ${FontSizes.Subtitle};
  color: ${Theme.primary_color.primary_color};
  margin-bottom: 2%;
`;
const LeaveInfoUserName = styled.div`
  color: ${Theme.primary_color.secondary_color};
`;
const GraphDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: "center";
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
  width: 100%;
  @media ${DeviceSizes.belowtablet} {
    padding: 0;
  }
`;
const LeaveInfoTextContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30%;
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
`;
const LeaveIndicator = styled.div`
  height: 40px;
  width: 5px;
  background-color: ${(props) =>
    props.component === "1"
      ? Theme.primary_color.avatar_border_color
      : Theme.primary_color.primary_color};
  border-radius: 20px;
  margin-right: ${(props) => (props.component === "1" ? "10px" : "0px")};
  margin-left: ${(props) => (props.component === "2" ? "10px" : "0px")};
`;

const ReadMoreOption = styled.span`
  color: ${Theme.primary_color.secondary_color};
  display: ${(props) => (props.isdisplay === 1 ? "" : "none")};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const EmployeeTimeContainerTo = styled(EmployeeTimeContainer)`
  margin-top: 1%;
  display: flex;
  display: ${(props) => (props.isvisible === 1 ? "" : "none")};
`;

const DurationAndStatus = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.display === 1 ? "space-between" : "flex-start"};
  align-items: center;
  @media ${DeviceSizes.mobile} {
    margin-right: 0%;
  }
`;

const LeaveStatusContainer = styled.div`
  display: ${(props) => (props.display ? "flex" : "none")};
`;
const EmployeeContainer = styled.div`
  position: relative;
  overflow: auto;
  margin-top: 2%;
  &::-webkit-scrollbar {
    height: 0.3em;
  }
  &:-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: ${Theme.primary_color.divider_gray};
    border-radius: 2px;
    transition: background-color 0.5s ease-in-out;
    -webkit-transition: background-color 0.5s ease-in-out;
    -o-transition: background-color 0.5s ease-in-out;
    -moz-transition: background-color 0.5s ease-in-out;
    &:hover {
      background-color: ${Theme.primary_color.primary_color};
    }
  }
  @media ${DeviceSizes.mobile} {
    margin-top: 1%;
    overflow: auto;
  }
`;

const VerticalScrollableAreaLeave = styled(VerticalScrollableArea)`
  padding: ${(props) =>
    props.component === 1 || props.component === 0 ? "0%" : "4%"};
  padding-top: 0%;
  padding-bottom: 0%;
  &::-webkit-scrollbar {
    width: 0em;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${Theme.primary_color.avatar_border_color};
    &:hover {
      background-color: ${Theme.primary_color.light_text};
    }
  }
  @media ${DeviceSizes.mobile} {
    height: 100%;
    overflow-x: visible;
  }
`;

const LeaveInfoSmallContainer = styled.div``;
const VerticalScrollableAreaLeaveInfo = styled(VerticalScrollableArea)`
  overflow-x: hidden;
  @media ${DeviceSizes.belowtablet} {
    margin-top: ${(props) =>
      props.leaveComponent === "leave Application"
        ? "10%"
        : props.leaveComponent === "leave Application Inside"
        ? "0%"
        : ""};
  }
  @media ${DeviceSizes.mobile} {
    margin-top: 0%;
    height: ${(props) =>
      props.leaveComponent === "leave Application"
        ? props.mobileheight ?? "82vh"
        : props.leaveComponent === "leave Application Inside"
        ? props.height
        : props.height + props.unit};
    width: ${(props) =>
      props.leaveComponent === "leave Application"
        ? "100%"
        : props.leaveComponent === "leave Application Inside"
        ? "89%"
        : "100%"};
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    display: ${(props) => props.display};
  }
`;
const EmptyLeaveDataText = styled.div`
  font-family: "Mulish";

  font-weight: 700;
  color: ${Theme.primary_color.green};
  font-size: ${FontSizes.Title};
  margin-top: 20%;
  text-align: center;
`;
const PageHeaderContainerLeave = styled(PageHeaderContainer)`
  & div:first-child {
    margin-left: 0%;
    width: 100%;
  }
`;

const ButtonEditLeave = styled(Button)`
  && {
    color: ${CommonColor.primary_color};
    background-color: ${CommonColor.white};
    border: none;
    outline: none;
    width: 100%;
    height: 30px;
    padding: 0;
    margin: 1%;
    justify-content: flex-start;
    padding-left: 12%;
    border-radius: 10px;
    border-color: transparent;

    &:hover {
      background-color: ${CommonColor.theme_background_color};
      border: none;
      outline: none;
    }
    @media ${DeviceSizes.mobile} {
      border-radius: 10px;
      min-width: 45%;
      margin: 2%;
    }
  }
`;


const ButtonDeleteLeave = styled(Button)`
  && {
    color: ${CommonColor.primary_color};
    background-color: ${CommonColor.white};
    border: none;
    outline: none;
    width: 7rem;
    height: 30px;
    margin: 1%;
    justify-content: flex-start;
    padding-left: 12%;
    padding-right: 12%;
    border-radius: 10px;
    border-color: transparent;

    &:hover {
      background-color: ${CommonColor.theme_background_color};
      border: none;
      outline: none;
    }
    @media ${DeviceSizes.mobile} {
      border-radius: 10px;
      min-width: 45%;
      margin: 2%;
    }
  }
`;
const EmployeeDetailScroller = styled.div`
  overflow-y: scroll;
  height: 90vh;
  width: 100%;
  padding: 4%;
  padding: 0%;
  @media ${DeviceSizes.mobile} {
    padding: 1%;
  }
`;
const LeaveInfoContainer = styled(Grid)`
  && {
    padding: 1.7%;
    background-color: ${CommonColor.theme_background_color};
    margin: 0;
    height: calc(100% - 65px);
    height: -webkit-calc(100% - 65px);
    height: -moz-calc(100% - 65px);

    width: 100%;
    @media ${DeviceSizes.mobile} {
      height: calc(100% - 55px);
      height: -webkit-calc(100% - 55px);
      height: -moz-calc(100% - 55px);
    }
  }
`;
const EmployeeScreenContainer = styled.div`
  display: flex;
  margin-top: 1%;
  /* margin-top: ${(props) =>
    props.leaveComponent === "leave Application" ? "" : "3%"}; */
  @media ${DeviceSizes.bigScreen} {
  }
  @media ${DeviceSizes.belowtablet} {
    /* width: ${(props) =>
      props.leaveComponent === "leave Application" ? "95%" : "100%"}; */
  }

  @media ${DeviceSizes.mobile} {
    display: block;
    width: 100%;
    margin-top: 4%;
  }
`;
const EmplyeeDetailContainer = styled.div`
  width: ${(props) =>
    props.leaveComponent === "leave Application" ? "461px" : ""};
  margin-right: 2%;
  @media ${DeviceSizes.bigScreen} {
    width: 100%;
  }
  @media ${DeviceSizes.laptop} {
    width: ${(props) =>
      props.leaveComponent === "leave Application" ? "47%" : ""};
  }
  @media ${DeviceSizes.mobile} {
    /* width: ${(props) =>
      props.leaveComponent === "leave Application" ? "100%" : "120%"}; */
    /* margin-top: ${(props) =>
      props.leaveComponent === "leave Application" ? "5%" : ""}; */
    width: 100%;
    padding: 2%;
    margin-bottom: 5%;
  }
`;
const LeaveInfoPending = styled(Grid)`
  && {
    border-radius: 4px;
    overflow-y: auto;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-right: 1%;
    @media ${DeviceSizes.mobile} {
      padding-right: 0%;
    }
  }
`;
const NavBarContainer = styled.div`
  /* width: 400px; */
  margin-bottom: 3%;
`;
const LeaveInfoEmployee = styled(Grid)`
  && {
    border-radius: 4px;
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    padding-left: 1%;
    @media ${DeviceSizes.mobile} {
      height: 100%;
      display: none;
    }
  }
`;
const LeaveGraphContainer = styled.div`
  padding-left: 2%;
  padding-right: 4%;
`;
const LeaveInfoCardInnerContainer = styled.div`
  margin: 5%;
`;

const BaseModalContainerLeaveApplication = styled.div`
  @media ${DeviceSizes.mobile} {
    & div:first-child {
      top: 39%;
      left: 57%;
      width: 87%;
      display: block;
    }
  }
`;
const LeaveInfoCardContainer = styled.div``;
const NoCard = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 500;
  color: ${Theme.primary_color.primary_color};
`;
const NoDataComponentContainerLeave = styled.div`
  display: flex;
  margin-left: ${(props) => props.ml ?? "16%"};
  min-height: 100%;
  align-items: center;
  justify-content: ${(props) => props.horizontalAlign};
  & svg {
    width: 100%;
    height: 200px;
  }
`;
const DropDownListContainerLeave = styled.div`
  & .MuiOutlinedInput-root {
    width: 97%;
  }
`;
const NoDataComponentContainerLeaveApp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  & svg {
    width: 80%;
    height: 200px;
  }
  @media ${DeviceSizes.mobile} {
    height: 46vh;
  }
`;

const NoDataComponentContainerLeaveReport = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80%;
  background-color: white;
  border-radius: 4px;
  & svg {
    width: 80%;
    height: 200px;
  }
  @media ${DeviceSizes.mobile} {
    height: 46vh;
  }
`;
const LeaveApplicationContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
  padding: 1%;
  @media ${DeviceSizes.mobile} {
      height: calc(100% - 55px);
      height: -webkit-calc(100% - 55px);
      height: -moz-calc(100% - 55px);
    }
`;
const LeaveBalanceGraphContainer = styled.div`
  padding: 1%;
  margin-bottom: 2%;
  background-color: ${CommonColor.white};
  border-radius: 10px;
  @media ${DeviceSizes.belowtablet} {
    && svg {
      width: 30%;
    }

    & div[data-test-id="CircularProgressbarWithChildren"] {
      /* width: fit-content !important; */
      & div {
        width: 100% !important;
        line-height: 1em;
        & div {
          width: 100% !important;
        }
      }
    }
  }
  @media ${DeviceSizes.mobile} {
    padding: 3%;
    && svg {
      width: 100%;
    }
  }
`;
const LeaveApplicationGrid = styled.div`
  margin: 0%;
  padding: 0%;
  height: 100%;
  width: 100%;
  margin-right: 2%;
  & + .grid2 {
    margin-right: 0;
  }
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;
const EmployeeOptionsIconContainer = styled.div`
  margin-left: 2%;
  display: ${(props) => (props.isvisible === 1 ? "" : "none")};

  & svg {
    cursor: pointer;
  }
`;
const IconNavbar = styled(Icon)`
  font-size: 2em;
  padding:4px;

`;
const MenuOptions = styled(Menu)``;
const MenuOption = styled(MenuItem)``;
const DropdownContainer = styled.div`
  display: ${(props) => (props.tabVisiblity === "visible" ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  & .MuiPaper-root {
    height: 100px;
  }

  @media ${DeviceSizes.mobile} {
    display: ${(props) =>
      props.mobileVisibility === "visible" ? "flex" : "none"};
    width: 100%;
    & label + div {
      margin-bottom: 0 !important;
      margin-left: 5px !important;
    }
  }
`;
const DropdownLaveCreditContainer = styled.div`
  background-color: ${CommonColor.white};
  padding: 1%;
  margin-bottom: ${(props) => (props.isfullheight ? "" : "2%")};
  border-radius: 4px;
  height: ${(props) => (props.isfullheight ? "100%" : "")};
`;
const ActiveEmployeeContainer = styled(IconButton)`
  && {
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    margin: 1%;
    & div {
      margin: 1%;
    }
  }
`;
const ActiveEmployeeNameContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Subtitle};
  color: ${CommonColor.white};
  font-weight: 600;
  width: 100%;
  text-align: ${(props) => props.textAlign ?? "center"};
  margin-left: 2%;
`;
const SelectedUserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${CommonColor.primary_color};
  border-radius: 10px;
  width: 100%;
  & div {
    color: ${CommonColor.primary_color};
  }
`;
const SelectUserIcon = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${CommonColor.primary_color};
  color: ${CommonColor.white};
  height: 100%;
  width: 20px;
`;
const DropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${DeviceSizes.mobile} {
    justify-content: center;
  }
`;
const PrexeliteProfileContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.jc ?? "center"};
  align-items: ${(props) => props.verticalAlign ?? "center"};
  width: ${(props) => props.width ?? "max-content"};
  cursor: ${(props) => props.cursor};
`;
const PrexeliteProfileImageContainer = styled.div`
  width: 23px;
  height: 23px;
  margin: 2%;
  margin-right: ${(props) => props.mr ?? ""};
`;
const PrexeliteProfileImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: ${(props) => props.br ?? "50%"};
`;
const PrexeliteProfileNoUser = styled(Icon)`
  height: 100%;
  width: 100%;
  border-radius: ${(props) => props.br ?? "50%"};
`;
const ButtonContainerLeaveInfoNavbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 35%;
  & svg {
    margin-left: 5%;
  }
  & div:has(button) {
    margin-left: 18%;
  }
`;
const LeaveDetailsNavbarContainer = styled.div`
  /* background-color: ${CommonColor.divider_gray}; */
  position: relative;
  padding: 1%;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 6px;
  margin: 2%;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: ${`drop-shadow(0px 2px 2px ${CommonColor.primary_color})`};
  }
`;
const NoDataContainerRecentLeave = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.mt ?? ""};
`;
const EmployeeDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EmployeePhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const EmployeeImg = styled.img`
  height: ${(props) => props.size ?? "24px"};
  width: ${(props) => props.size ?? "24px"};
  border-radius: 50%;
  margin-bottom: ${(props) => props.mb};
  margin-top: ${(props) => props.mt};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  margin: ${(props) => props.margin};
  border: 2px solid ${CommonColor.primary_color};
  padding: 2%;
  background-color: ${CommonColor.theme_background_color};
`;
const ProgressBar = styled.progress`
  background-color: red;
  height: 13px;
  border-radius: 15px;
  &::-webkit-progress-bar {
    background-color: yellow;
    border-radius: 15px;
  }
  &::-webkit-progress-value {
    background-color: red;
    border-radius: 15px;
    /* box-shadow: 1px 1px 5px 3px rgba(255, 0, 0, 0.8); */
  }
`;
const EmployeeTextDetailContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const DropDownListContainerPopLeaveInfo = styled.div`
  & .MuiPaper-root {
    max-height: calc(100% - 170px);
  }
`;
const OpenPopupButtonMobile = styled(Button)`
  && {
    /* background-color: ${CommonColor.primary_color}; */
    color: ${CommonColor.primary_color};
    font-family: "Mulish";
    font-size: ${FontSizes.Para};
    font-weight: "600";
    width: 100%;
    text-transform: capitalize;
    margin: 2%;
    border-radius: 10px;
    border-color: ${CommonColor.primary_color};
    border-width: 1px;
    border-style: solid;
    & :hover {
      /* background-color: ${CommonColor.primary_color}; */
    }
  }
`;
const DropdownListContainerRight = styled.div`
  width: 73%;
  margin-right: 2%;
`;
const LeaveInfoCircleWrapper = styled.div`
  margin-right: 5%;
`;
const LeaveInfoMobileContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const ImageContainerLeaveCard = styled.div`
  width: 24px;
  height: 24px;
`;
const LeaveHistoryImageContainer = styled.div`
  width: 46px;
  height: 46px;
`;
const LeaveApplicationApplyLeaveButton = styled.div`
  width: 100%;
  height: 100px;
`;
export {
  ButtonDeleteLeave,
  NoDataComponentContainerLeaveReport,
  LeaveApplicationApplyLeaveButton,
  LeaveHistoryImageContainer,
  ImageContainerLeaveCard,
  ActiveEmployeeContainer,
  ActiveEmployeeNameContainer,
  BaseModalContainerLeaveApplication,
  ButtonContainerLeaveInfoNavbar,
  ButtonEditLeave,
  Colon,
  ColonLeaveEndTime,
  DropDownListContainerLeave,
  DropDownListContainerPopLeaveInfo,
  DropdownContainer,
  DropdownLaveCreditContainer,
  DropdownListContainerRight,
  DropdownWrapper,
  DurationAndStatus,
  EmployeeCardDivider,
  EmployeeContainer,
  EmployeeDetailScroller,
  EmployeeDetailsContainer,
  EmployeeImg,
  EmployeeLeaveDataContainer,
  EmployeeLeaveDataNavbar,
  EmployeeLeaveDataNavbarContainer,
  EmployeeLeaveDuaration,
  EmployeeLeaveFooter,
  EmployeeLeaveHalfDay,
  EmployeeLeaveInfoContainer,
  EmployeeLeaveNote,
  EmployeeLeaveNoteContainer,
  EmployeeLeaveNoteHeading,
  EmployeeLeaveStatus,
  EmployeeLeaveTime,
  EmployeeLeaveTimeAndTypeContainer,
  EmployeeLogo,
  EmployeeName,
  EmployeeNameContainer,
  EmployeeOptionsIconContainer,
  EmployeePhotoContainer,
  EmployeeScreenContainer,
  EmployeeTextDetailContainer,
  EmployeeTimeContainer,
  EmployeeTimeContainerTo,
  EmployeetimingHeader,
  EmplyeeDetailContainer,
  EmptyLeaveDataText,
  GraphDetailContainer,
  IconNavbar,
  LeaveApplicationContainer,
  LeaveApplicationGrid,
  LeaveBalanceGraphContainer,
  LeaveDetailsNavbarContainer,
  LeaveGraphContainer,
  LeaveIndicator,
  LeaveInfoCardContainer,
  LeaveInfoCardInnerContainer,
  LeaveInfoCircleWrapper,
  LeaveInfoContainer,
  LeaveInfoDetailContainer,
  LeaveInfoDetailNav,
  LeaveInfoEmployee,
  LeaveInfoMobileContainer,
  LeaveInfoPending,
  LeaveInfoSmallContainer,
  LeaveInfoTextContainer,
  LeaveInfoUserName,
  LeaveStatusContainer,
  MenuOption,
  MenuOptions,
  NameContainer,
  NavBarContainer,
  NavBarFilterButtonContainer,
  NavBarFilterContainer,
  NavBarFilterText,
  NavbarHeading,
  NoCard,
  NoDataComponentContainerLeave,
  NoDataComponentContainerLeaveApp,
  NoDataContainerRecentLeave,
  OpenPopupButtonMobile,
  PageHeaderContainerLeave,
  PrexeliteProfileContainer,
  PrexeliteProfileImage,
  PrexeliteProfileImageContainer,
  PrexeliteProfileNoUser,
  ProgressBar,
  ReadMoreOption,
  SelectUserIcon,
  SelectedUserContainer,
  VerticalScrollableAreaLeave,
  VerticalScrollableAreaLeaveInfo,
};

//AppealApplicationContainer
{
  /*
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
  padding: 1%;

  */
}
