import { Icon } from "@iconify/react";
import React, { useState } from "react";
import {
  FormContainer,
  InputText,
  VerticalScrollableAreaPopup,
} from "../../style/form.style";
import TextInput from "../commonComponents/inputs/TextInput";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DropDownList from "../commonComponents/inputs/DropDownList";
import { ROLE, SHIFT, WING } from "../../utils/constant";
import { Divider } from "@mui/material";
import BottomButtons from "./components/BottomButtons";

export default function EditEmployee(props) {
  const [dataError, setDataError] = useState(false);
  const [name, setName] = useState();
  const [wing, setWing] = useState("ITW");
  const [role, setRole] = useState("Leader");
  const [shift, setShift] = useState("USA");
  const [lastName, setlastName] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [BirthDate, setBirthDate] = useState("08/13/2022");
  const [PrexeliteId, setPrexeliteId] = useState();
  const [address, setAddress] = useState();
  const [height, setHeight] = useState();
  const [aadharNumber, setAadharNumber] = useState();
  const [panNumber, setPanNumber] = useState();
  const [whatsappNumber, setwhatsappNumber] = useState();
  const [parentNumber, setParentNumber] = useState();
  function onEdit() {}
  return (
    <React.Fragment>
      <FormContainer>
        <PopupNavBar
          onHandleClose={props.onHandleClose}
          componentType="form"
          title="edit employee"
        />
        <VerticalScrollableAreaPopup height="50" mobileheight="87" unit="vh">
          <TextInput
            title={"First Name"}
            placeholder={"First Name"}
            icon={<Icon icon="ooui:user-avatar-outline" />}
            value={name}
            error={name === ""}
            onChange={(newValue) => {
              setName(newValue.target.value);
            }}
          />
          <TextInput
            {...(dataError === "2" ? "error" : "")}
            title={"Last Name"}
            placeholder={"Last Name"}
            icon={<Icon icon="ooui:user-avatar-outline" />}
            value={lastName}
            error={lastName == ""}
            onChange={(newValue) => {
              setlastName(newValue.target.value);
            }}
            helperText={dataError === "2" ? "Field Must Not be empty" : ""}
          />
          <TextInput
            {...(dataError === "3" || dataError === "31" ? "error" : "")}
            title={"Mobile"}
            placeholder={"Mobile"}
            type="number"
            icon={<Icon icon="fluent:call-48-regular" height="22" width="22" />}
            value={mobileNumber}
            onChange={(newValue) => {
              setMobileNumber(newValue.target.value);
            }}
            helperText={
              dataError === "3"
                ? "Field Must Not be empty"
                : dataError === "31"
                ? "Enter Correct data"
                : ""
            }
          />
          <TextInput
            {...(dataError === "4" || dataError === "41" ? "error" : "")}
            title={"Email"}
            placeholder={"Email"}
            icon={<Icon icon="ic:outline-email" />}
            value={email}
            onChange={(newValue) => {
              setEmail(newValue.target.value);
            }}
            helperText={
              dataError === "4"
                ? "Field Must Not be empty"
                : dataError === "41"
                ? "Enter Correct data"
                : ""
            }
          />
          <TextInput
            {...(dataError === "5" || dataError === "51" ? "error" : "")}
            title={"Password"}
            placeholder={"Password"}
            type="password"
            icon={<Icon icon="bx:lock-alt" />}
            value={password}
            onChange={(newValue) => {
              setPassword(newValue.target.value);
            }}
            helperText={
              dataError === "5"
                ? "Field Must Not be empty"
                : dataError === "51"
                ? "Password should be atleast 8 character long"
                : ""
            }
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <InputText>Date</InputText>
            <MobileDatePicker
              disableFuture
              title="Date"
              openTo="year"
              views={["year", "month", "day"]}
              value={BirthDate}
              onChange={(newValue) => {
                setBirthDate(newValue);
              }}
              renderInput={(params) => (
                <TextInput placeholder="Birthdate" {...params} />
              )}
            />
          </LocalizationProvider> */}
          <TextInput
            {...(dataError === "7" ? "error" : "")}
            title={"Prexelite ID"}
            placeholder={"Prexelite ID"}
            icon={<Icon icon="mdi:briefcase-variant-outline" />}
            value={PrexeliteId}
            onChange={(newValue) => {
              setPrexeliteId(newValue.target.value);
            }}
            helperText={dataError === "7" ? "Field Must Not be empty" : ""}
          />

          <DropDownList
            dataList={WING}
            title={"Wing"}
            value={wing}
            handleChange={(event) => setWing(event.target.value)}
          />
          <DropDownList
            dataList={SHIFT}
            title={"Shift"}
            value={shift}
            handleChange={(event) => setShift(event.target.value)}
          />
          <DropDownList
            dataList={ROLE}
            title={"Role"}
            value={role}
            handleChange={(event) => setRole(event.target.value)}
          />

          <TextInput
            {...(dataError === "11" ? "error" : "")}
            title={"Address"}
            placeholder={"Address"}
            icon={<Icon icon="akar-icons:location" />}
            value={address}
            onChange={(newValue) => {
              setAddress(newValue.target.value);
            }}
            helperText={dataError === "11" ? "Field Must Not be empty" : ""}
          />
          <TextInput
            {...(dataError === "12" ? "error" : "")}
            title={"Height"}
            placeholder={"Height"}
            icon={<Icon icon="akar-icons:height" />}
            value={height}
            onChange={(newValue) => {
              setHeight(newValue.target.value);
            }}
            helperText={dataError === "12" ? "Field Must Not be empty" : ""}
          />
          <TextInput
            {...(dataError === "13" ? "error" : "")}
            title={"Aadhar Number"}
            placeholder={"Aadhar Number"}
            icon={<Icon icon="bi:briefcase" />}
            type="number"
            value={aadharNumber}
            onChange={(newValue) => {
              setAadharNumber(newValue.target.value);
            }}
            helperText={dataError === "13" ? "Field Must Not be empty" : ""}
          />
          <TextInput
            {...(dataError === "14" ? "error" : "")}
            title={"PAN Number"}
            placeholder={"PAN Number"}
            icon={<Icon icon="bi:briefcase" />}
            value={panNumber}
            onChange={(newValue) => {
              setPanNumber(newValue.target.value);
            }}
            helperText={dataError === "14" ? "Field Must Not be empty" : ""}
          />
          <TextInput
            {...(dataError === "15" || dataError === "151" ? "error" : "")}
            title={"WhatsApp Number"}
            placeholder={"WhatsApp Number"}
            icon={<Icon icon="akar-icons:whatsapp-fill" />}
            type="number"
            value={whatsappNumber}
            onChange={(newValue) => {
              setwhatsappNumber(newValue.target.value);
            }}
            helperText={
              dataError === "15"
                ? "Field Must Not be empty"
                : dataError === "151"
                ? "Enter Correct data"
                : ""
            }
          />
          <TextInput
            {...(dataError === "16" || dataError === "161" ? "error" : "")}
            title={"Parents Number"}
            placeholder={"Parents Number"}
            icon={<Icon icon="fluent:call-48-regular" height="22" width="22" />}
            type="number"
            NoMarginBottom
            value={parentNumber}
            onChange={(newValue) => {
              setParentNumber(newValue.target.value);
            }}
            helperText={
              dataError === "16"
                ? "Field Must Not be empty"
                : dataError === "161"
                ? "Enter Correct data"
                : ""
            }
          />
        </VerticalScrollableAreaPopup>
        <Divider />
        <BottomButtons onSave={() => onEdit()} buttonName={"edit details"} />
      </FormContainer>
    </React.Fragment>
  );
}
