const CommonColor = {
  primary_color: "#253F50",
  secondary_color: "#ff6600",
  theme_background_color: "#F7F8FC",
  popup_background_color: "rgba(0,0,0, 0.7)",
  dashboard_inactive: "#A4A6B3",
  drawer_divider: "DFE0EB",
  light_text: "#ADAFB2",
  green: "#2BA820",
  red: "#DA0303",
  light_red: "rgb(255, 161, 161)",
  red_light_0: "#F44336",
  white: "#FFFFFF",
  transparent: "transparent",
  input_border: "#e2e2e2",
  text_gray: "#7E7E7E",
  button_gray: "#e2e5ea",
  light_orange: "#ff66001a",
  divider_gray: "#E8E8E8",
  vertical_bar: "#BEC5CB",
  avatar_border_color: "#C4C4C4",
  title_color: "#F26722",
  sub_title: "#253F50B2",
  drawer_menu_active: "rgba(159, 162, 180, 0.3)",
  contained_button_background: "#D6E2D4",
  divider_background: "rgba(196, 196, 196, 0.1)",
  success_button_background: "rgba(43, 168, 32, 0.1)",
  employee_branch_filter_button_deactive: "rgba(37, 63, 80, 0.1)",
  declined_status_background: "#FBE6E6",
  primary_color_opacity_05: "#00000024",
  login_input_background: "#323645",
  dropzone_background: "#f2f6fa",
  light_primary: "#2a475b",
  dark: "rgb(0,0,0)",
  light_white: "rgb(84, 84, 84)",
  light_transparent_white: "rgba(255, 255, 255, 0.9)",
  lighter_transparent_white: "rgba(255, 255, 255, 0.2)",
  layover_color: "#0b1318",
  table_head_bg: "#cdd2d4",
  button_bg: "#0C0C0C09",
  light_blue: "#f8f9fd",
  primary_bg_light_white: "#8D8D8D",
  border_light: "#bdbdbf",
  loader_background: "rgba(0,0,0,0.8w)",
};

export default CommonColor;
