import { Icon } from "@iconify/react/dist/iconify.js";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageAvatar from "../../../../componentsv2/Images/ImageAvatar";
import SynergyLeagueController from "../../../../controller/SynergyLeagueController";
import { setPointsTable } from "../../../../reducer/synergyleaguereduxstore";
import { FlexContainer } from "../../../../style/asset.style";
import { Div, TypoGraphy } from "../../../../style/common.style";
import {
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  PopupNavBarContainer,
} from "../../../../style/form.style";
import {
  TeamLogoContainer,
  TeamLogoImageContainer,
} from "../../../../style/synergy.league.style";
import CommonColor from "../../../../utils/common.colors";
import { CommonImages } from "../../../../utils/common.images";
import ShowLoader from "../../../Loader/ShowLoader";
import PopupNavBar from "../../../commonComponents/Popups/PopupNavBar";
import { useWindowSize } from "../../../commonComponents/hooks/getWindowDimension";
import TextInput from "../../../commonComponents/inputs/TextInput";
import BottomButtons from "../BottomButtons";

export default function AddMatch(props) {
  const [isView, setIsView] = useState(false);
  const [maxPoints, setMaxPoints] = useState("");
  const [mavericksPoints, setMavericksPoints] = useState(null);
  const [defendersPoints, setDefendersPoints] = useState(null);
  const [warriorsPoints, setWarriorPoints] = useState(null);
  const [navigatorsPoints, setNavigatorsPoints] = useState(null);
  const [gameName, setGameName] = useState(null);
  const [isExeedingOrNull, setExeedingOrNull] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const synergyLeagueController = new SynergyLeagueController();
  const dispatch = useDispatch();
  const windowWidth = useWindowSize().width;

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  useEffect(() => {
    if (props.data) {
      setMaxPoints(props.data.game_points);
      setGameName(props.data.game_name);
      setDefendersPoints(props.data.defenders);
      setMavericksPoints(props.data.mavericks);
      setWarriorPoints(props.data.warriors);
      setNavigatorsPoints(props.data.navigators);
    }
  }, []);

  const handleBlur = (event) => {
    if (
      (mavericksPoints !== null || mavericksPoints !== "") &&
      parseInt(mavericksPoints) > parseInt(maxPoints)
    ) {
      setMavericksPoints(null);
      setExeedingOrNull(true);
    }
    if (
      (defendersPoints !== null || defendersPoints !== "") &&
      parseInt(defendersPoints) > parseInt(maxPoints)
    ) {
      setDefendersPoints(null);
      setExeedingOrNull(true);
    }
    if (
      (warriorsPoints !== null || warriorsPoints !== "") &&
      parseInt(warriorsPoints) > parseInt(maxPoints)
    ) {
      setWarriorPoints(null);
      setExeedingOrNull(true);
    }
    if (
      (navigatorsPoints !== null || navigatorsPoints !== "") &&
      parseInt(navigatorsPoints) > parseInt(maxPoints)
    ) {
      setNavigatorsPoints(null);
      setExeedingOrNull(true);
    }
  };

  function checkForUnfilled() {
    if (gameName === null || gameName === "") {
      setExeedingOrNull(true);
      return false;
    }
    if (maxPoints === null || maxPoints === "") {
      setExeedingOrNull(true);
      return false;
    }
    if (mavericksPoints === null || mavericksPoints === "") {
      setExeedingOrNull(true);
      return false;
    }
    if (defendersPoints === null || defendersPoints === "") {
      setExeedingOrNull(true);
      return false;
    }
    if (warriorsPoints === null || warriorsPoints === "") {
      setExeedingOrNull(true);
      return false;
    }
    if (navigatorsPoints === null || navigatorsPoints === "") {
      setExeedingOrNull(true);
      return false;
    }
    return true;
  }
  function ResetData() {
    setGameName(null);
    setMaxPoints(null);
    setNavigatorsPoints(null);
    setMavericksPoints(null);
    setWarriorPoints(null);
    setDefendersPoints(null);
  }
  async function onSave() {
    setIsView(true);
    let isGo = checkForUnfilled();
    if (!isGo) {
      setIsView(false);
      if (props.data) {
        props.close(false);
      }
      return;
    }

    const sendData = {
      game_name: gameName,
      navigators: navigatorsPoints,
      defenders: defendersPoints,
      mavericks: mavericksPoints,
      warriors: warriorsPoints,
      game_points: maxPoints,
    };

    let resp;
    if (!props.data) {
      resp = await synergyLeagueController.addMatch(sendData);
    } else {
      const newSendData = {
        ...sendData,
        _id: props.data._id,
      };
      resp = await synergyLeagueController.updateMatch(newSendData);
    }
    if (resp) {
      let data = await synergyLeagueController.getPointsTable();
      if (data) {
        dispatch(setPointsTable(data));
      }
      let sucessMsg;
      if (!props.data) {
        sucessMsg = (
          <div>
            <div>success</div>
            <div>match added sucessfully</div>
          </div>
        );
      } else {
        sucessMsg = (
          <div>
            <div>success</div>
            <div>match updated sucessfully</div>
          </div>
        );
      }
      const timer = setTimeout(() => {
        props.onHandleClose();
        if (props.data) {
          props.close(false);
        }
        setIsView(false);
        handleAlert(sucessMsg, "success");
        clearTimeout(timer);
      }, 2000);
    } else {
      const errrorMsg = (
        <div>
          <div>error</div>
          <div>please try again later</div>
        </div>
      );
      const timer = setTimeout(() => {
        props.onHandleClose();
        setIsView(false);
        if (props.data) {
          props.close(false);
        }
        ResetData();
        handleAlert(errrorMsg, "error");
        clearTimeout(timer);
      }, 2000);
    }
  }

  return (
    <React.Fragment>
      {isView ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Adding new Match</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainer>
              <PopupNavBar
                onHandleClose={() => {
                  props.onHandleClose();
                  props.close();
                }}
                componentType="form"
                title="add team"
              />
            </PopupNavBarContainer>
          </DialogTitle>
          <DialogContent dividers={true}>
            <TextInput
              title={"Game Name"}
              placeholder={"Carrom"}
              icon={
                <Icon
                  icon="fluent:people-team-16-filled"
                  height="22"
                  width="22"
                />
              }
              value={gameName}
              onChange={(event) => {
                setGameName(event.target.value);
              }}
              type="text"
            />
            <TextInput
              title={"Total Game Points"}
              placeholder={"5"}
              onBlur={handleBlur}
              icon={
                <Icon
                  icon="fluent:people-team-16-filled"
                  height="22"
                  width="22"
                />
              }
              value={maxPoints}
              InputProps={{
                inputProps: {
                  min: 0, // Set the max value to 100
                },
              }}
              onChange={(event) => {
                setMaxPoints(event.target.value);
              }}
              type="number"
            />
            <FlexContainer
              width="100%"
              jc="space-between"
              height="max-content"
              columnGap="15px"
              flexWrap={windowWidth < 600 ? "wrap" : ""}
            >
              <TeamLogoContainer size="max-content">
                <TeamLogoImageContainer size="90px">
                  <ImageAvatar
                    src={CommonImages.synergyLeagueLogos.mavericksLogo}
                    alt=""
                    defaultSrc={CommonImages.NoUser}
                    borderRadius="0px"
                    height="20px"
                    width="20px"
                  />
                </TeamLogoImageContainer>
                <TextInput
                  title={"Mavericks Points"}
                  InputProps={{
                    inputProps: {
                      max: maxPoints,
                      min: 0, // Set the max value to 100
                    },
                  }}
                  placeholder={"5"}
                  onBlur={handleBlur}
                  icon={
                    <Icon
                      icon="fluent:people-team-16-filled"
                      height="22"
                      width="22"
                    />
                  }
                  value={mavericksPoints}
                  onChange={(event) => {
                    setMavericksPoints(event.target.value);
                  }}
                  type="number"
                />
              </TeamLogoContainer>
              <TeamLogoContainer size="max-content">
                <TeamLogoImageContainer size="90px">
                  <ImageAvatar
                    src={CommonImages.synergyLeagueLogos.defendersLogo}
                    alt=""
                    defaultSrc={CommonImages.NoUser}
                    borderRadius="0px"
                    height="20px"
                    width="20px"
                  />
                </TeamLogoImageContainer>
                <TextInput
                  title={"Defenders Points"}
                  placeholder={"5"}
                  onBlur={handleBlur}
                  InputProps={{
                    inputProps: {
                      max: maxPoints,
                      min: 0, // Set the max value to 100
                    },
                  }}
                  icon={
                    <Icon
                      icon="fluent:people-team-16-filled"
                      height="22"
                      width="22"
                    />
                  }
                  value={defendersPoints}
                  onChange={(event) => {
                    setDefendersPoints(event.target.value);
                  }}
                  type="number"
                />
              </TeamLogoContainer>
              <TeamLogoContainer size="max-content">
                <TeamLogoImageContainer size="90px">
                  <ImageAvatar
                    src={CommonImages.synergyLeagueLogos.navigatorsLogo}
                    alt=""
                    borderRadius="0px"
                    defaultSrc={CommonImages.NoUser}
                    height="20px"
                    width="20px"
                  />
                </TeamLogoImageContainer>
                <TextInput
                  title={"Navigators Points"}
                  placeholder={"5"}
                  onBlur={handleBlur}
                  InputProps={{
                    inputProps: {
                      max: maxPoints,
                      min: 0, // Set the max value to 100
                    },
                  }}
                  icon={
                    <Icon
                      icon="fluent:people-team-16-filled"
                      height="22"
                      width="22"
                    />
                  }
                  value={navigatorsPoints}
                  onChange={(event) => {
                    setNavigatorsPoints(event.target.value);
                  }}
                  type="number"
                />
              </TeamLogoContainer>
              <TeamLogoContainer size="max-content">
                <TeamLogoImageContainer size="90px">
                  <ImageAvatar
                    src={CommonImages.synergyLeagueLogos.warriorsLogo}
                    alt=""
                    defaultSrc={CommonImages.NoUser}
                    borderRadius="0px"
                    height="20px"
                    width="20px"
                  />
                </TeamLogoImageContainer>
                <TextInput
                  title={"Warriors Points"}
                  placeholder={"5"}
                  InputProps={{
                    inputProps: {
                      max: maxPoints,
                      min: 0, // Set the max value to 100
                    },
                  }}
                  icon={
                    <Icon
                      icon="fluent:people-team-16-filled"
                      height="22"
                      width="22"
                    />
                  }
                  value={warriorsPoints}
                  onChange={(event) => {
                    setWarriorPoints(event.target.value);
                  }}
                  type="number"
                />
              </TeamLogoContainer>
            </FlexContainer>
            {isExeedingOrNull && (
              <FlexContainer
                flexDirection="column"
                verticalAlign="flex-start"
                margin="2px 0 0 0"
              >
                <TypoGraphy color={CommonColor.red}>
                  *All fields must be filled
                </TypoGraphy>
                <TypoGraphy color={CommonColor.red}>
                  *Team points should be less than game points
                </TypoGraphy>
              </FlexContainer>
            )}
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => onSave()}
                buttonName={props.data == null ? "Add Game" : "Update Game"}
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
