import { Icon } from "@iconify/react";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmationMenu from "../../../component/Popup/ConfirmationMenu";
import ModalPopup from "../../../component/Popup/ModalPopup";
import ProfileScreenPopup from "../../../component/commonComponents/Popups/ProfileScreen/ProfileScreenPopup";
import EmployeeLeaveReasoning from "../../../component/commonComponents/ScreenDashboard/LeaveHistory/EmployeeLeaveReasoning";
import { useWindowSize } from "../../../component/commonComponents/hooks/getWindowDimension";
import ImageAvatar from "../../../componentsv2/Images/ImageAvatar";
import LeaveController from "../../../controller/LeaveController";
import {
  setEmployeeLeave,
  setLeaveHistoryData,
  updatePendingEmployeeLeaveData,
} from "../../../reducer/leavereduxstore";
import { FlexContainer } from "../../../style/asset.style";
import {
  CircularHalfDayIndicator,
  CustomIcon,
  Div,
  HalfCircle,
  HtmlTooltip,
} from "../../../style/common.style";
import {
  EmployeeLeaveDurationLeaveHistroy,
  EmployeeNameLeaveHistory,
  LeaveDetailContainer,
  LeaveDuration,
  LeaveDurationTitle,
  LeaveHistoryAlignContainer,
  LeaveHistoryCardContainer,
  LeaveStatus,
  LeaveStatusContainer,
  LeaveStatusOptionMenu,
  LeaveTotalTime,
  MenuItemTextContainer,
} from "../../../style/dashboard.style";
import { LeaveHistoryImageContainer } from "../../../style/leave.style";
import CommonColor from "../../../utils/common.colors";
import { CommonString } from "../../../utils/common.string";
import { ProfileBaseUrl } from "../../../utils/constant";
import { getJustDate } from "../../../utils/utils";

export default function LeaveHistoryCard(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeStatus, setActiveStatus] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);
  const leaveController = new LeaveController();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function getImageEmployee(data) {
    return ProfileBaseUrl + data.user.profile_image_url;
  }
  function getDate() {
    let date = "";
    date = getJustDate(props.data.leave.from_date);

    return date;
  }
  function getToDate() {
    let date = "";

    if (props.data.leave.leave_type === "MULTIPLE") {
      date += " - " + getJustDate(props.data.leave.to_date);
    }
    return date;
  }
  function getDays() {
    if (props.data.leave.leave_type === "LATE") {
      return "Late Coming";
    }
    if (props.data.leave.leave_type === "MULTIPLE") {
      return props.data.leave.total_leave + " Days";
    }
    return props.data.leave.total_leave + " Day";
  }

  function getVisible(value) {
    if (value == null || value == "" || value == undefined) {
      return false;
    }
    return true;
  }
  function getMenuItems() {
    if (props.data.leave.status === CommonString.approved.toUpperCase()) {
      return [CommonString.rejectButtonName.toUpperCase()];
    }
    if (props.data.leave.status === CommonString.rejected.toUpperCase()) {
      return [CommonString.approveButtonName.toUpperCase()];
    }
    return [
      CommonString.approveButtonName.toUpperCase(),
      CommonString.rejectButtonName.toUpperCase(),
    ];
  }
  function activeStatusSetter(item) {
    setActiveStatus(item);
    handleClose();
    setOpenPopup(true);
  }
  async function onChangeStatus(status) {
    setIsLoading(true);
    const sendData = {
      id: props.data.leave.id,
      approved_by_user_id: props.data.user._id,
      status: status,
    };
    const resp = await leaveController.upadateLeaveStatus(sendData);
    if (resp === true) {
      const employeesLeave = await leaveController.getLeaveInfoEmployees();
      dispatch(setEmployeeLeave(employeesLeave));
      const pendingEmployeesLeave =
        await leaveController.getPendingEmployeeLeaveData();
      dispatch(updatePendingEmployeeLeaveData(pendingEmployeesLeave));
      const leaveHistory = await leaveController.getLeaveHistory();
      dispatch(setLeaveHistoryData(leaveHistory));
    }
    setOpenPopup(false);
    setIsLoading(false);
  }
  function getTitle(title) {
    return (
      title +
      " leave of " +
      props.data.user.name +
      " for " +
      props.data.leave.total_leave +
      " day(s)?"
    );
  }
  function OpenPopup() {
    setOpenPopup(true);
    setActiveStatus(CommonString.profileScreen);
  }
  function getModalChildren() {
    if (activeStatus === CommonString.approveButtonName.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationApproved)}
          yesButtonName="Approve"
          noButtuonName="cancel"
          onClickYesButton={() =>
            onChangeStatus(CommonString.approved.toUpperCase())
          }
          onClicknoButton={() => setOpenPopup(false)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (activeStatus === CommonString.rejectButtonName.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationReject)}
          yesButtonName="Reject"
          onClickYesButton={() =>
            onChangeStatus(CommonString.rejected.toUpperCase())
          }
          noButtuonName="Cancel"
          onClicknoButton={() => setOpenPopup(false)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (activeStatus === CommonString.profileScreen) {
      return (
        <ProfileScreenPopup
          onHandleClose={() => setOpenPopup(false)}
          user={props.data.user}
        />
      );
    }
  }
  function getPopupWidth() {
    if (activeStatus === CommonString.profileScreen) {
      return "100vw";
    }
    return "fit-content";
  }
  return (
    <React.Fragment>
      <ModalPopup
        open={openPopup}
        styleSX={{
          minWidth: getPopupWidth(),
          maxHeight: "100vh",
          height: {
            sm: "max-content",
            xs: "100%",
          },
          margin: 0,
          overflowX: "hidden",
        }}
      >
        {getModalChildren()}
      </ModalPopup>

      <LeaveHistoryCardContainer delay={props.serialOrder} length={props.size}>
        <LeaveHistoryAlignContainer>
          <LeaveHistoryImageContainer>
            <ImageAvatar
              src={getImageEmployee(props.data)}
              sx={{ cursor: "pointer" }}
              onClick={() => OpenPopup()}
              objectFit="contain"
            />
          </LeaveHistoryImageContainer>
          <LeaveDetailContainer>
            <FlexContainer>
              <EmployeeNameLeaveHistory>
                {/* <Typography
                  noWrap
                  sx={{
                    fontSize: "inherit",
                    fontWeight: "inherit",
                    color: "inherit",
                    fontFamily: "inherit",
                    width: {
                      sm: "100%",
                      xs: "75px",
                    },
                  }}
                > */}
                {props.data.user.name}
              </EmployeeNameLeaveHistory>

              {/* </Typography> */}

              <HtmlTooltip
                key={props.data}
                title={
                  <Fragment>
                    <EmployeeLeaveReasoning data={props.data} />
                  </Fragment>
                }
                arrow={true}
                enterDelay={0}
                leaveDelay={900}
              >
                <CustomIcon
                  icon="ic:twotone-remove-red-eye"
                  ml="5px"
                  color={CommonColor.primary_bg_light_white}
                />
              </HtmlTooltip>
            </FlexContainer>

            <EmployeeLeaveDurationLeaveHistroy>
              <LeaveDurationTitle>Date&nbsp;:&nbsp;</LeaveDurationTitle>
              <LeaveDuration>
                <Div className="Wrapper">
                  <Div className="dateDurationLeave">{getDate()}</Div>{" "}
                  <Tooltip title="Applied for half day">
                    <Box
                      sx={{
                        marginLeft: 1,
                        display:
                          getVisible(props.data.leave.half_day_1) ||
                          props.data.leave.leave_type === "HALF"
                            ? "block"
                            : "none",
                      }}
                    >
                      <CircularHalfDayIndicator>
                        <HalfCircle />
                        <HalfCircle bg={CommonColor.light_orange} />
                      </CircularHalfDayIndicator>
                    </Box>
                  </Tooltip>
                </Div>
                <Div className="Wrapper">
                  <Div className="dateDurationLeave"> {getToDate()}</Div>

                  <Tooltip title="Applied for half day">
                    <Box
                      sx={{
                        marginLeft: 1,
                        display: getVisible(props.data.leave.half_day_1)
                          ? "block"
                          : "none",
                      }}
                    >
                      <CircularHalfDayIndicator>
                        <HalfCircle />
                        <HalfCircle bg={CommonColor.light_orange} />
                      </CircularHalfDayIndicator>
                    </Box>
                  </Tooltip>
                </Div>
              </LeaveDuration>
            </EmployeeLeaveDurationLeaveHistroy>
          </LeaveDetailContainer>
        </LeaveHistoryAlignContainer>

        <LeaveStatusContainer>
          <LeaveTotalTime noWrap>{getDays()}</LeaveTotalTime>
          <LeaveStatus status={props.data.leave.status}>
            {props.data.leave.status}
          </LeaveStatus>
        </LeaveStatusContainer>
        <LeaveStatusOptionMenu
          className="absolutelyPositioned"
          onClick={(event) => handleClick(event)}
        >
          <Icon icon="akar-icons:more-vertical-fill" />
        </LeaveStatusOptionMenu>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {getMenuItems().map((menuItem, index) => {
            return (
              <MenuItem
                onClick={() => activeStatusSetter(menuItem)}
                key={index}
              >
                <MenuItemTextContainer>
                  {menuItem.toLowerCase()}
                </MenuItemTextContainer>
              </MenuItem>
            );
          })}
        </Menu>
      </LeaveHistoryCardContainer>
    </React.Fragment>
  );
}
