import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoginForm from "../../component/Forms/LoginForm";
import { updateApiToken } from "../../reducer/authreduxstore";

import {
  ErrorText,
  FormContainer,
  FormContainerHalf,
  LoginViewContainer,
  LoginViewImage,
  TitleText,
} from "../../style/login.style";
import { CommonImages } from "../../utils/common.images";
import { api_token_key } from "../../utils/common.key";
import { activePath } from "../../utils/constant";

export default function LoginScreen() {
  const [isloading, setIsloading] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const [isErrorExist, setIsErrorExist] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    let token = localStorage.getItem(api_token_key);
    if (token !== null) {
      dispatch(updateApiToken(token));
      const path = localStorage.getItem(activePath);
      if (path == undefined || path == null || path.includes("undefined")) {
        history.push("/home/dashboard");
      } else {
        history.push(path);
      }
    }
  }

  function loaderStarter() {
    setIsloading(!isloading);
    setTimeout(() => {
      setIsloading(!isloading);
    }, 1000);
  }
  return (
    <LoginViewContainer>
      <React.Fragment>
        <LoginViewImage src={CommonImages.loginBg} alt="bg" />
        <FormContainer>
          <FormContainerHalf>
            <TitleText>Hey, </TitleText>
            <TitleText>Welcome Back</TitleText>

            <ErrorText iserrorexist={isErrorExist}>
              {isErrorExist === 1
                ? "Enter correct email or password"
                : isErrorExist === 0
                ? "Please enter email and password"
                : ""}
            </ErrorText>
            <LoginForm
              loaderStarter={loaderStarter}
              isloading={isloading}
              isErrorExist={isErrorExist}
              errorSet={(boolval) => setIsErrorExist(boolval)}
            />
          </FormContainerHalf>
        </FormContainer>
      </React.Fragment>
    </LoginViewContainer>
  );
}
