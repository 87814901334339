import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveMaterial,
  setPdfLoaded,
} from "../../reducer/materialreduxstore";
import {
  BigVerticalScrollableArea,
  PdfViewerContainer,
} from "../../style/form.style";
import {
  BigPopupContentContainer,
  BigPopupMainContainer,
  LoaderPdfContainer,
  PdfCardContainerPopup,
  PdfViewerIframe,
  PopupContainer,
  PopupNavBarContainerTraining,
} from "../../style/training.style";
import PdfFile from "../TrainingMaterial/PdfFile";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import { useWindowSize } from "../commonComponents/hooks/getWindowDimension";

import { DialogContent, DialogTitle } from "@mui/material";
import ShimmerTrainingPpt from "../Loader/shimmers/ShimmerTrainingPpt";

export default function PdfViewer(props) {
  const windowWidth = useWindowSize().width;
  const dispatch = useDispatch();
  const pdfIndex = useSelector((state) => state.material.active_material);
  const isPdfLoaded = useSelector((state) => state.material.isPdfLoaded);
  const zoom = windowWidth < 600 ? "3%" : "68%";

  function OpenPdf(index) {
    dispatch(setActiveMaterial(index));
    dispatch(setPdfLoaded(false));
  }
  function loadingFinisher() {
    setTimeout(() => {
      dispatch(setPdfLoaded(true));
    }, 1000);
  }
  function disableClick() {}
  function getBName(bname) {
    if (bname === "Recruitment") {
      return "RW";
    } else {
      return bname;
    }
  }
  return (
    <React.Fragment>
      <DialogTitle>
        <PopupNavBarContainerTraining>
          <PopupNavBar
            onHandleClose={props.PdfCloser}
            componentType="navbarmain"
            componentSize="big"
            title="Training Material"
          />
        </PopupNavBarContainerTraining>
      </DialogTitle>
      <DialogContent>
        <BigPopupMainContainer>
          {/* <FlexContainer jc="flex-start" width="100%" flexDirection="column">
            <FlexContainer width={"max-content"}>
              <TypoGraphy>Branch &nbsp;:</TypoGraphy>&nbsp;
              <TypoGraphy color={CommonColor.secondary_color}>
                {props.activeWing}
              </TypoGraphy>
            </FlexContainer>
            <FlexContainer width="max-content">
              <TypoGraphy>Event &nbsp;:</TypoGraphy>&nbsp;
              <TypoGraphy color={CommonColor.secondary_color}>
                {props.activeType}
              </TypoGraphy>
            </FlexContainer>
          </FlexContainer> */}
          <BigPopupContentContainer>
            <PopupContainer>
              {props.file &&
                props.file !== 0 &&
                props.file.map((file, index) => {
                  return (
                    <PdfCardContainerPopup key={index}>
                       <PdfFile
                        index={index}
                        file={file}
                        OpenPdf={(i) => OpenPdf(i)}
                        showActive={true}
                        ActiveIndex={pdfIndex}
                        PopupType="bigpopup"
                      /> 
                     
                    </PdfCardContainerPopup>
                  );
                })}
            </PopupContainer>
            <PdfViewerContainer>
              <BigVerticalScrollableArea
                height="90"
                unit="vh"
                mobileheight="95"
              >
                {isPdfLoaded ? null : (
                  <LoaderPdfContainer>
                    {/* <PageRefreshLoader component="inner" /> */}
                    <ShimmerTrainingPpt />
                  </LoaderPdfContainer>
                )}
                <PdfViewerIframe
                  isloaded={isPdfLoaded}
                  src={
                    props.file[pdfIndex].file_url +
                    "#toolbar=0&" +
                    `zoom=${zoom}`
                  }
                  loading="lazy"
                  height="100%"
                  onLoad={loadingFinisher}
                  onContextMenu={(event) => disableClick(event)}
                ></PdfViewerIframe>
              </BigVerticalScrollableArea>
            </PdfViewerContainer>
          </BigPopupContentContainer>
        </BigPopupMainContainer>
      </DialogContent>
    </React.Fragment>
  );
}
