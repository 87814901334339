import { Icon } from "@iconify/react";
import { Button, Chip, Divider } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import CommonColor from "../utils/common.colors";
import { CommonImages } from "../utils/common.images";
import {
  DeviceSizes,
  PageHeaderContainer,
  VerticalScrollableArea,
  opacityChangeAnimation,
} from "./common.style";
import {
  IconifyIcon,
  PlaceholderSearchPosition,
  SearchBar,
  SearchIconContainer,
} from "./employee.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";


const DropzoneMainContainer = styled.div`
  height: 100%;
`;
const DropInput = styled.input``;
const DropZone = styled.div`
  box-sizing: border-box;
  position: relative;
  /* padding-top: %; */
  height: 100%;
  width: 100%;
  background: ${Theme.primary_color.white};
  color: white;
  /* display: ${(props) => (props.isloading ? "flex" : "")}; */
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transform: scale(1);
`;
const DropzoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: ${Theme.primary_color.dropzone_background};
  border-radius: 10px;
  @media ${DeviceSizes.mobile} {
    height: 100%;
    margin-left: 2%;
    width: 100%;
    margin-left: 0%;
  }
`;

const DropzoneIconContainer = styled.div``;
const DropzoneIcon = styled(IconifyIcon)`
  width: 70px;
  height: 70px;
  color: ${Theme.primary_color.primary_color};
`;
const DropZoneText = styled.div`
  font-family: "Mulish";

  color: ${Theme.primary_color.text_gray};
  font-weight: 500;
  font-size: ${FontSizes.Para};
  margin: 0.5%;
  margin-top: 1%;
`;

const ORContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1%;
`;
const LineDivider = styled.div`
  height: 2px;
  width: 100px;
  background-color: ${Theme.primary_color.text_gray};
  border-radius: 50px;
`;
const ORText = styled.div`
  font-family: "Mulish";

  color: ${Theme.primary_color.light_text};
  font-weight: 800;
  font-size: ${FontSizes.Title};
  margin-left: 7%;
  margin-right: 7%;
`;

const FileChooseButton = styled.div`
  cursor: pointer;
  background-color: ${Theme.primary_color.primary_color};
  margin: 0.5%;
  padding: 2% 5%;
  border-radius: 5px;
  text-transform: capitalize;

  font-weight: 500;
  font-size: ${FontSizes.Para};
`;
const DropzoneComponentContainer = styled.div`
  height: 100%;
`;
const UploadOptionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  transform: ${(props) => (props.visible ? "scaleY(1)" : "scale(0)")};
  transition: transform 1s ease-in-out;
  height: ${(props) => (props.visible ? "20%" : "0")};
`;

const MuiButton = styled(Button)`
  && {
    margin: 0%;
    padding: 1% 5%;
    text-transform: capitalize;
    font-family: "Mulish";

    font-size: ${FontSizes.Para};
    /* background-color: ${Theme.primary_color.login_input_border};
    &:hover {
      background-color: ${Theme.primary_color.login_input_border};
    } */
  }
`;
const CancelButton = styled.div`
  position: absolute;
  top: 5%;
  right: 2%;
  cursor: pointer;
`;
const CloseIcon = styled(IconifyIcon)`
  color: ${Theme.primary_color.primary_color};
  background-color: ${Theme.primary_color.white};
  border-radius: 50%;
  padding: 10%;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(37, 63, 80, 1);
  -moz-box-shadow: 0px 0px 3px 0px rgba(37, 63, 80, 1);
  box-shadow: 0px 0px 3px 0px rgba(37, 63, 80, 1);
  transform: rotate(0deg);
  transition: transform 0.7s ease-in-out;
  &:hover {
    transform: rotate(180deg);
  }
`;
const FormFileDetailsContainer = styled.div``;

const FilterTraining = styled(Chip)`
  && {
    font-size: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    height: 28px;
    border-color: ${(props) =>
      props.index === props.activeindex
        ? CommonColor.primary_color
        : CommonColor.button_gray};

    color: ${(props) =>
      props.index === props.activeindex
        ? CommonColor.primary_color
        : CommonColor.text_gray};
    font-family: "Mulish";
    cursor: pointer;
    &:hover {
      /* box-shadow: 0px 3.1923940181732178px 19.952463150024414px -3.990492820739746px
      #00000026; */
      background-color: ${CommonColor.primary_color_opacity_05};
    }
  }
`;
const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
`;
const BranchCardContainer = styled.div`
  height: 25%;
  width: 100%;
  margin-left: 4%;
  margin-top: 2%;
`;
const BranchCardTitle = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  font-weight: 600;
`;
const FilterContainer = styled.div`
  display: ${(props) =>
    props.component === "Training material" ? "flex" : "none"};
  align-items: center;
  height: 100%;
  width: 60%;
  margin-top: 1%;
  margin-left: 4%;
  @media ${DeviceSizes.bigScreen} {
    margin-left: 3%;
  }
  @media ${DeviceSizes.mobile} {
    margin-left: 2%;
    display: ${(props) =>
      props.screencomponenet === "main" &&
      props.componenet !== "Training material"
        ? "flex"
        : "none"};
  }
`;
const VerticalScrollableAreaTraining = styled(VerticalScrollableArea)`
  overflow-x: hidden;
  margin-left: 0%;
  padding: 2%;
  width: 100%;
  @media ${DeviceSizes.bigScreen} {
    margin-left: 0%;
    width: 98%;
  }
  @media ${DeviceSizes.laptop} {
    /* margin-left: 22%; */
  }
  @media ${DeviceSizes.belowtablet} {
    margin-left: 0%;
    width: 100%;
  }
  @media ${DeviceSizes.mobile} {
    margin: 0%;
    width: 100%;
    height: 91vh;
    /* margin-top: 2%; */
    padding: 2%;
    padding-right: 0.1%;
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;
const CardPdf = styled.div`
  padding: 3%;
  border-radius: 4px;
  height: 100%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.isactive
      ? Theme.primary_color.primary_color
      : Theme.primary_color.transparent};
  transition: transform 0.5s ease-in-out;
  opacity: 0;
  animation-name: ${opacityChangeAnimation};
  animation-duration: ${(props) => props.index / props.length}s;
  animation-delay: ${(props) => props.index / props.length}s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  &:hover {
    transform: scale(1.05);
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin-bottom: 5%;
  }
`;
const PdfIcon = styled(Icon)`
  cursor: pointer;
  width: 70px;
  height: 110px;
  color: ${Theme.primary_color.primary_color};
  margin-top: 4%;
  margin-left: 30%;
`;
const PdfEvent = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.SubPara};
  font-weight: 700;
  text-transform: capitalize;
  background-color: ${Theme.primary_color.transparent};
  color: ${Theme.primary_color.primary_color};
  border-radius: 5px;
`;
const PdfFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4%;
`;
const PdfHeader = styled(PdfFooter)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`;
const PdfWing = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Para};
  font-weight: 500;
`;
const PdfTitle = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.Subtitle};
  font-weight: 700;
  text-align: center;
`;
const PdfDeleteButton = styled(Icon)`
  font-size: ${FontSizes.Subtitle};
  color: ${Theme.primary_color.primary_color};
`;
const IconContainerButton = styled(Button)`
  && {
    width: 10%;
  }
`;
const PdfUploader = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.VerySmallText};
  font-weight: 500;
  display: flex;
  align-items: center;
`;
const PdfUploaderTitle = styled.div``;
const UploadedFilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 0.2%;
  margin-top: 1.5%;

  @media ${DeviceSizes.belowtablet} {
    width: 100%;
  }
  @media ${DeviceSizes.mobile} {
    width: 96%;
    margin: 2%;
  }
`;
const EventIntro = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  & svg {
    /* margin-left: 1%; */
  }
`;
const WingFor = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.VerySmallText};
  font-weight: 800;
  text-transform: capitalize;
  background-color: ${Theme.primary_color.button_gray};
  color: ${Theme.primary_color.primary_color};
  padding: 2%;
  border-radius: 5px;
`;
const PdfBody = styled.div``;
const PdfName = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 700;
  text-transform: capitalize;
  color: ${Theme.primary_color.primary_color};
  border-radius: 5px;
`;

const PdfDescription = styled.p`
  font-family: "Mulish";
  max-lines: 1;
  width: 100%;
  font-size: ${FontSizes.SubPara};
  font-weight: 500;
  text-transform: capitalize;
  color: ${Theme.primary_color.primary_color};
  border-radius: 5px;
  display:inline;
`;
const EventCoordinator = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.VerySmallText};
  font-weight: 500;
  text-transform: capitalize;
  color: ${Theme.primary_color.text_gray};
  padding: 2%;
  padding-top: 0px;
`;
//CommonImages

const WisdomWednesDayIcon = styled(CommonImages.eventIcons.WisdomWednesdayIcon)`
  height: 22px;
`;
const TechnicalTrainingIcon = styled(
  CommonImages.eventIcons.TechnicalTraining
)``;

const MUIDivider = styled(Divider)`
  && {
    margin-top: 2%;
    margin-bottom: 2%;
  }
`;

const PageNavDivider = styled(Divider)`
  && {
    margin: 0% 0% 2% 0%;
    @media ${DeviceSizes.mobile} {
      margin-top: 8%;
    }
  }
`;

const PopupContainer = styled.div`
  width: 26%;
  height: 76vh;
  padding: 1%;
  padding-top: 0%;
  overflow-x: hidden;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 90vh;
  margin-right: 1%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1%;
  &::-webkit-scrollbar {
    width: 0.3em;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(79, 79, 79);
    border-radius: 2px;
  }
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;

const BigPopupContentContainer = styled.div`
  display: flex;
  justify-content: center;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    height: 100%;
  }
`;
const BigPopupMainContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const PageHeaderContainerTraining = styled(PageHeaderContainer)`
  @media ${DeviceSizes.belowtablet} {
    width: 81%;
    margin-top: 8%;
  }
  @media ${DeviceSizes.mobile} {
    &&& {
      margin-bottom: 10%;
      width: 89%;
    }
  }
`;
const PdfViewerIframe = styled.iframe`
  && {
    display: block;
    user-select: none;
    width: 100%;
    opacity: ${(props) => (props.isloaded ? "1" : "0")};
    transition: opacity 0.3s ease-in-out;
    background-color: ${Theme.primary_color.dark};
    border: none;
    height: ${(props) => `${props.height}`};
    margin: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.3em;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(79, 79, 79);
      border-radius: 2px;
    }
  }
`;
const FilterContainerTraining = styled(FilterContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin: 0%;
  width: max-content;
  margin: 1%;
  margin-top: 0;
  margin-bottom: 2%;
  @media ${DeviceSizes.mobile} {
    display: flex;
    height: 50%;
    flex-wrap: wrap;
  }
`;
const PdfLoader = styled.div``;

const LoaderPdfContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: ${Theme.primary_color.layover_color}; */
  height: 92vh;
  width: 100%;
  /* opacity: 0.1; */
`;
const NoDataComponentContainerTrainingMaterial = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & svg {
    width: 80%;
  }
`;
const PdfCardContainerPopup = styled.div`
  margin: 3%;
  width: 100%;
  margin-top: 0%;
`;
const PdfCardContainer = styled.div`
  margin-bottom: 2%;
  margin-left: ${(props) => (props.index % 3 === 0 ? "0%" : "2%")};
  width: 32%;
  opacity: 0;
  animation-name: ${opacityChangeAnimation};
  animation-duration: ${(props) => props.index / props.length}s;
  animation-delay: ${(props) => props.index / props.length}s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  @media ${DeviceSizes.mobile} {
    margin-left: 0%;
    width: 100%;
  }
`;
const PopupNavBarContainerTraining = styled(PopupContainer)`
  height: 7%;
  width: 100%;
  padding: 0;
  && {
    font-weight: 500;
  }
  @media ${DeviceSizes.mobile} {
    display: block;
    height: 100%;
  }
`;
const SearchContainerNavbar = styled.div`
  position: relative;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${CommonColor.white};
  border-radius: 50px;
  overflow: hidden;
  &:focus-within {
    border: 2px solid ${Theme.primary_color.light_primary};
    outline: none;
  }
`;
const TrainingPageNav = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${DeviceSizes.mobile} {
    flex-direction: column;
  }
`;
const SearchIconContainerTrain = styled(SearchIconContainer)`
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  margin-left: 2px;
  margin-top: auto;
  margin-bottom: auto;
  @media ${DeviceSizes.mobile} {
    margin-left: 2px;
  }
`;
const SearchBarTrain = styled(SearchBar)`
  width: 100%;
`;
const SearchContainer = styled.div`
  width: 40%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
const SearchIconInnerContainer = styled.div`
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PlaceholderSearchPositionTrain = styled(PlaceholderSearchPosition)`
  top: 17%;
`;
const PdfPreview = styled.iframe`
  height: 100%;
  width: 100%;
`;
const PdfDataContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`;
const ChangeFileButton = styled(Button)`
  && {
    background-color: ${CommonColor.primary_color};
    color: ${CommonColor.white};
    font-family: "Mulish";
    font-size: ${FontSizes.SubPara};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 10px;
    margin-bottom: 3%;
    padding: 2px 6px;

    &:hover {
      background-color: ${CommonColor.primary_color}; 
      color: ${CommonColor.white}; 
    }
  }
`;
const PdfUpadteFormContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  @media ${DeviceSizes.mobile} {
    flex-direction: column-reverse;
  }
`;
const ChangeFileButtonContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const IconOptionContainerPdf = styled.div`
  justify-content: center;
  align-items: center;
  margin-left: 1%;
  padding: 1%;
  opacity: ${(props) => (props.isvisible ? "1" : "0")};
  transition: opacity 1s ease-in-out;

  position: relative;
  border-radius: 100%;
  &::before {
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-radius: 100%;
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &:hover::before {
    border-top-color: ${CommonColor.primary_color}; // Show borders
    border-right-color: ${CommonColor.primary_color};
    border-bottom-color: ${CommonColor.primary_color};

    transition: border-top-color 0.15s linear,
      // Stagger border appearances
      border-right-color 0.15s linear 0.1s,
      border-bottom-color 0.15s linear 0.2s;
  }
  &::after {
    top: 0;
    left: 0;
    border: 0 solid transparent;
    border-radius: 100%;
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &:hover::after {
    border-top: 1px solid ${CommonColor.primary_color}; // Shows border
    border-left-width: 1px; // Solid edges, invisible borders
    border-right-width: 1px; // Solid edges, invisible borders
    transform: rotate(270deg); // Rotate around circle
    transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s; // Solid edge post-rotation
  }
`;
const LoadingBgContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${CommonColor.light_transparent_white};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  flex-direction: column;
`;
const MultipleFilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 42%;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin-top: 5%;
    flex-wrap: wrap-reverse;
  }
`;
const TrainingScreenWrapper = styled.div`
  width: 100%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
`;

const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: ${Theme.primary_color.secondary_color};
  font-size: ${FontSizes.Para};
  cursor: pointer;
  display: block;
  margin-left: 5px;
  
`;
const PdfDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
  overflow-wrap: break-word;
`;


export {
  PdfDescriptionContainer,
  ReadMoreButton,
  PdfDescription,
  BigPopupContentContainer,
  BigPopupMainContainer,
  BranchCardContainer,
  BranchCardTitle,
  CancelButton,
  CardPdf,
  ChangeFileButton,
  ChangeFileButtonContainer,
  CloseIcon,
  DropInput,
  DropZone,
  DropZoneText,
  DropzoneComponentContainer,
  DropzoneContainer,
  DropzoneIcon,
  DropzoneIconContainer,
  DropzoneMainContainer,
  EventCoordinator,
  EventIntro,
  FileChooseButton,
  FilterContainer,
  FilterContainerTraining,
  FilterTraining,
  FormFileDetailsContainer,
  IconContainerButton,
  IconOptionContainerPdf,
  Image,
  LineDivider,
  LoaderPdfContainer,
  LoadingBgContainer,
  MUIDivider,
  MuiButton,
  MultipleFilterContainer,
  NoDataComponentContainerTrainingMaterial,
  ORContainer,
  ORText,
  PageHeaderContainerTraining,
  PageNavDivider,
  PdfBody,
  PdfCardContainer,
  PdfCardContainerPopup,
  PdfDataContainer,
  PdfDeleteButton,
  PdfEvent,
  PdfFooter,
  PdfHeader,
  PdfIcon,
  PdfLoader,
  PdfName,
  PdfPreview,
  PdfTitle,
  PdfUpadteFormContainer,
  PdfUploader,
  PdfUploaderTitle,
  PdfViewerIframe,
  PdfWing,
  PlaceholderSearchPositionTrain,
  PopupContainer,
  PopupNavBarContainerTraining,
  SearchBarTrain,
  SearchContainer,
  SearchContainerNavbar,
  SearchIconContainerTrain,
  SearchIconInnerContainer,
  TechnicalTrainingIcon,
  TrainingPageNav,
  TrainingScreenWrapper,
  UploadOptionContainer,
  UploadedFilesContainer,
  VerticalScrollableAreaTraining,
  WingFor,
  WisdomWednesDayIcon,
};
