import styled from "styled-components";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";
import { Icon } from "@iconify/react";
import {
  DeviceSizes,
  PageHeaderContainer,
  VerticalScrollableArea,
  opacityChangeAnimation,
} from "./common.style";
import { keyframes } from "styled-components";
import CommonColor from "../utils/common.colors";
import CloseIcon from "@mui/icons-material/Close";

const VerticalScrollableAreaEmployee = styled(VerticalScrollableArea)`
  overflow-x: hidden;
  padding: 2%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
  @media ${DeviceSizes.mobile} {
    height: ${(props) => props.mobileheight};
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;
const MainHeader = styled.div`
  position: relative;
  width: 100%;
  height: 40%;

  @media ${DeviceSizes.belowtablet} {
    height: 37%;
  }
  @media ${DeviceSizes.mobile} {
    height: 8%;
    width: 100%;
  }
`;
const SearchHeader = styled.div`
  background-color: ${Theme.primary_color.primary_color};
  color: ${Theme.primary_color.white};
  font-size: ${FontSizes.LargeText};
  font-family: "Mulish";

  letter-spacing: 0.3px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  height: 80%;
  width: 100%;
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.Title};
    display: none;
  }
`;

const SearchOuterContainer = styled.div`
  position: absolute;
  top: 68%;
  left: 23%;
  height: 24%;
  width: 50%;
  @media ${DeviceSizes.belowtablet} {
    height: 10%;
    top: 65%;
  }
  @media ${DeviceSizes.mobile} {
    height: 51px;
    top: 0%;
    left: 0%;
    width: 100%;
  }
`;
const SearchInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 4px 21px -6px #00000040;
  overflow: hidden;
  &:focus-within {
    outline: 2px solid white;
    border: 2px solid ${CommonColor.primary_color};
  }
`;

const SearchBar = styled.input`
  width: 100%;
  height: 100%;
  padding: 2%;
  padding-right: 10%;
  font-size: ${FontSizes.Subtitle};
  font-family: "Mulish";
  font-weight: 400;
  border: none;
  outline: none;
  &::placeholder {
    text-transform: capitalize;
    font-size: ${FontSizes.Subtitle};
    font-family: "Mulish";

    font-weight: 400;
  }
`;

const SearchIconContainer = styled.div`
  aspect-ratio: 1/1;
  height: 90%;
  border-radius: 50%;
  background-color: ${Theme.primary_color.primary_color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5%;
  @media ${DeviceSizes.mobile} {
    height: 80%;
    width: 14%;
    top: 10%;
    margin-left: 1.5%;
  }
`;
const NavBarFilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 22%;
  margin-top: 5%;
  width: 50vw;
  @media ${DeviceSizes.belowtablet} {
    margin-top: 8%;
    width: 100%;
  }
  @media ${DeviceSizes.mobile} {
    margin-top: 13%;
    margin-left: 5%;
    width: 90%;
  }
`;
const NavBarFilterText = styled.div`
  font-family: "Mulish";

  font-weight: 500;
  color: ${Theme.primary_color.light_text};
  font-size: ${FontSizes.Para};
  margin-top: 0.5%;
  @media ${DeviceSizes.mobile} {
    font-size: ${FontSizes.SubPara};
  }
`;
const NavBarFilterButtonContainer = styled.div`
  display: flex;
  margin-top: 1%;
  margin-left: 2%;
  width: 89%;
  @media ${DeviceSizes.mobile} {
    width: 60%;
  }
`;
// const EmployeeNavBranchFilter = styled.div`
//   cursor: pointer;
//   border-radius: 100px;
//   padding-left: 7%;
//   padding-right: 7%;
//   padding-top: 1%;
//   padding-bottom: 1%;
//   margin-right: 1%;
//   background-color: ${(props) =>
//     props.$ActiveFilterindex === props.$index
//       ? Theme.primary_color.primary_color
//       : Theme.primary_color.employee_branch_filter_button_deactive};
//   font-family: "Mulish";
//font-display: block;
//   font-weight: 500;
//   font-size: ${FontSizes.Para};
//   color: ${(props) =>
//     props.$ActiveFilterindex === props.$index
//       ? Theme.primary_color.white
//       : Theme.primary_color.primary_color};
//   text-transform: uppercase;
// `;
const EmployeeCardContainer = styled.div`
  /* margin-top: 2%; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 3%;
  row-gap: 7%;
  margin-bottom: 2%;
  @media ${DeviceSizes.belowtablet} {
    margin-top: 0%;
  }
  @media ${DeviceSizes.mobile} {
    margin-left: 5%;
    column-gap: 8%;
  }
`;
const IconifyIcon = styled(Icon)`
  width: ${(props) => props.height ?? "100%"};
  height: ${(props) => props.width ?? "100%"};
  margin: ${(props) => props.margin};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  transition: all 0.5s ease-in-out;
`;

const MainEmployeeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${Theme.primary_color.white};
  border: 1px solid ${Theme.primary_color.transparent};
  transition: border 0.3s ease-in-out;
  padding: 1%;
  padding-bottom: 2%;
  box-shadow: 0px 4.466200351715088px 27.91375160217285px -5.58275032043457px #00000026;
  border-radius: 4px;
  width: 14%;
  cursor: ${(props) => (props.isvisible === 1 ? "pointer" : "default")};
  position: relative;
  transition: transform 0.5s ease-in-out, padding 0.5s ease-in-out;
  opacity: 0;
  animation-name: ${opacityChangeAnimation};
  animation-duration: ${(props) => props.index / props.length}s;
  animation-delay: ${(props) => props.index / props.length}s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  margin-top: 3%;
  & span:first-child {
    height: 40%;
  }
  &:hover {
    /* border-color: ${Theme.primary_color.secondary_color}; */
    transform: ${(props) =>
      props.isvisible === 1 ? "scale(1.1)" : "scale(1)"};
    z-index: 1;
  }
  @media ${DeviceSizes.laptop} {
    /* width: 20%; */
  }
  @media ${DeviceSizes.belowtablet} {
    width: 17%;
  }
  @media ${DeviceSizes.mobile} {
    width: 40%;
    padding: 3%;
    margin: 1%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
`;

const UserImage = styled.img`
  && {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: contain;
    margin-top: ${(props) => (props.ispadding ? "30%" : "19%")};
    @media ${DeviceSizes.belowtablet} {
      width: 70px;
      height: 70px;
    }
    @media ${DeviceSizes.mobile} {
      width: 50px;
      height: 50px;
      margin-top: 25%;
    }
  }
`;
/*    width: 28%;
    height: 50%; */
const UserName = styled.div`
  font-family: "Mulish";
  color: ${CommonColor.primary_color};
  font-size: ${FontSizes.SubPara};
  font-weight: 700;
  margin-top: 9%;
  text-transform: capitalize;
  text-align: center;
  @media ${DeviceSizes.mobile} {
    margin-top: 10%;
    font-size: ${FontSizes.SmallText};
  }
`;
const UserBranchName = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.VerySmallText};
  color: ${(props) =>
    props.isHovered && props.isvisible === 1
      ? Theme.primary_color.white
      : Theme.primary_color.primary_color};
  font-weight: 800;
  position: absolute;
  right: 10%;
  top: 10%;
  background-color: ${(props) =>
    props.isHovered && props.isvisible === 1
      ? Theme.primary_color.primary_color
      : Theme.primary_color.divider_gray};
  transition: background-color 1s ease-in-out, color 1s ease-in-out;
  padding: 2% 5%;
  border-radius: 50px;
  @media ${DeviceSizes.mobile} {
    margin-top: 2%;
  }
`;
const UserPrexeliteId = styled.div`
  font-family: "Mulish";

  font-size: ${FontSizes.SmallText};
  font-weight: 400;
  margin-top: 2%;
  text-transform: capitalize;
  margin-bottom: ${(props) => (props.ismargin ? "20%" : "")};

  @media ${DeviceSizes.mobile} {
    margin-top: 2%;
    margin-bottom: 2%;
  }
`;
const UserViewProfileContainer = styled.div`
  text-transform: capitalize;
  font-family: "Mulish";
  font-size: ${FontSizes.SubPara};
  color: ${Theme.primary_color.light_primary};
  font-weight: 600;
  display: ${(props) => (props.isvisible === 1 ? "flex" : "none")};
  position: absolute;
  bottom: 5%;
  width: 100%;
  left: 30%;
  text-align: center;
  /* max-height: ${(props) => (props.ishovered ? "10%" : "0")}; */
  opacity: ${(props) => (props.ishovered === 1 ? "1" : "0")};
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  &:hover {
    background-color: ${Theme.primary_color.transparent};
  }
  @media ${DeviceSizes.mobile} {
    display: none;
  }
`;
const ProfileViewContainer = styled.div`
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -moz-calc(100% - 65px);
`;
const animateButtons = keyframes`
  0%{
    opacity: 0;
    top: 4%;
  }
  50%{
    opacity: 0;
    top: 3%;
  }
  100%{
    opacity: 1;
    top: 2%;
  }
`;
const CloseButtonIcon = styled(CloseIcon)`
  && {
    position: absolute;
    right: ${(props) => props.right ?? "2px"};
    top: ${(props) => props.top ?? "58px"};
    font-size: 2.4em;
    /* animation: ${animateButtons} 1s ease-in-out 0s 1 normal;  */
    /* color: ${Theme.primary_color.white}; */
    background-color: ${Theme.primary_color.transparent};
    /* filter: invert(100%); */
    color: ${CommonColor.white};
    mix-blend-mode: difference;
    border-radius: 50%;
    padding: 0.7%;
    font-weight: 900;
    cursor: pointer;
    -webkit-box-shadow: 0px 3px 43px -6px rgba(37, 63, 80, 0.57);
    -moz-box-shadow: 0px 3px 43px -6px rgba(37, 63, 80, 0.57);
    box-shadow: 0px 3px 43px -6px rgba(37, 63, 80, 0.57);
  }
  /* z-index: 2; */
`;
const PageHeaderContainerEmployee = styled(PageHeaderContainer)`
  @media ${DeviceSizes.laptop} {
    width: 87%;
  }
  @media ${DeviceSizes.belowtablet} {
    width: 80%;
    margin-top: 10%;
  }
`;
const EmployeeDataEmptyText = styled.div`
  font-family: "Mulish";

  font-weight: 500;
  color: ${Theme.primary_color.primary_color};
  font-size: ${FontSizes.Para};
  margin-top: 40%;
`;
const EditButtonIcon = styled(CloseButtonIcon)`
  right: 5%;
`;
const ButtonSideIcon = styled(Icon)`
  font-size: ${FontSizes.Title};
  color: ${Theme.primary_color.primary_color};
  border-radius: 50%;
  font-weight: 900;
  margin-left: 6%;
`;
const SearchIconInnerContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextContainerPlaceholder = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  font-weight: 500;
  color: ${CommonColor.text_gray};
`;
const PlaceholderSearchPosition = styled.div`
  position: absolute;
  top: 30%;
  right: 2%;
  display: ${(props) => (props.isvisible !== 0 ? "" : "none")};
`;
const TeamMemberContainer = styled.div`
  height: 100px;
  width: 100px;
  margin-top: ${(props) => (props.ismargintop ? "30%" : "19%")};
  @media ${DeviceSizes.mobile} {
    margin-top: 6%;
  }
`;
export {
  TeamMemberContainer,
  PlaceholderSearchPosition,
  TextContainerPlaceholder,
  SearchIconInnerContainer,
  VerticalScrollableAreaEmployee,
  MainHeader,
  SearchHeader,
  SearchOuterContainer,
  SearchInnerContainer,
  SearchBar,
  SearchIconContainer,
  IconifyIcon,
  NavBarFilterContainer,
  NavBarFilterText,
  NavBarFilterButtonContainer,
  //   EmployeeNavBranchFilter,
  EmployeeCardContainer,
  MainEmployeeContainer,
  UserImage,
  UserName,
  UserBranchName,
  UserPrexeliteId,
  UserViewProfileContainer,
  ProfileViewContainer,
  CloseButtonIcon,
  PageHeaderContainerEmployee,
  EmployeeDataEmptyText,
  EditButtonIcon,
  ButtonSideIcon,
};
