import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Icon from "react-multi-date-picker/components/icon";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../componentsv2/common_components/Inputs/input";
import TrackerController from "../../controller/TrackerController";
import { setTopTypers } from "../../reducer/employeereduxstore";
import { Div } from "../../style/common.style";
import {
  FormContainer,
  FormDataContainer,
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  PopupNavBarContainer,
} from "../../style/form.style";
import { CommonImages } from "../../utils/common.images";
import { CommonString } from "../../utils/common.string";
import ShowLoader from "../Loader/ShowLoader";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import TailwindDropdownMembers from "../commonComponents/inputs/TailWindDropdownMembers";
import BottomButtons from "./components/BottomButtons";

export default function AddSpeedyPrexelites(props) {
  const employee_list = useSelector((state) => state.employee.employee_list);

  const [prexelite, setPrexelite] = useState(employee_list[0]);
  const [typingSpeed, setTypingSpeed] = useState();
  const [error, setError] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const [loader, setLoader] = useState(false);
  const trackerController = new TrackerController();
  const dispatch = useDispatch();

  useEffect(() => {
    onPrefillData();
  }, [props.data]);

  function onPrefillData() {
    if (!props.data || props.data === null) {
      setTypingSpeed(NaN);
      setPrexelite("");
      return;
    }
    setTypingSpeed(props.data.speed);
    const employeeDetail = getEmployeeList().filter(
      (employeeDetail) => employeeDetail._id === props.data.user._id
    )[0];
    setPrexelite(employeeDetail);
  }

  function IsErrorExist() {
    if (prexelite === "") {
      setError(0);
      return true;
    }
    if (!typingSpeed) {
      setError(1);
      return true;
    }
    setError();

    return false;
  }

  function getEmployeeList() {
    return employee_list;
  }

  async function onSave() {
    if (IsErrorExist()) return;
    setLoader(true);
    const data = {
      user_id: prexelite._id,
      speed: typingSpeed,
    };
    const res = await trackerController.addTypingScore(data);
    if (res) {
      setTimeout(() => {
        const sucessMsg = (
          <div>
            <div>Success</div>
            <div>speedy prexelite added sucessfully</div>
          </div>
        );
        handleAlert(sucessMsg, "success");
      }, 2000);
      const TopTypers = await trackerController.getTopTypers();
      dispatch(setTopTypers(TopTypers));
    } else {
      setTimeout(() => {
        const erroMsg = (
          <div>
            <div>error</div>
            <div>try again after some time</div>
          </div>
        );
        handleAlert(erroMsg, "error");
      }, 2000);
    }

    setLoader(false);
    props.onHandleClose();
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  return (
    <React.Fragment>
      {loader ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Adding new Team Member</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBarContainer>
              <PopupNavBar
                onHandleClose={props.onHandleClose}
                componentType="form"
                title="Add Speedy Prexelite"
              />
            </PopupNavBarContainer>
          </DialogTitle>
          <DialogContent dividers={true}>
            <FormContainer>
              <FormDataContainer
                style={{ height: "500px" }}
                className="p-2 pt-0"
              >
                <TailwindDropdownMembers
                  error={error === 0}
                  dataList={getEmployeeList()}
                  title={"Prexeilte"}
                  value={prexelite}
                  handleChange={(event) => {
                    setPrexelite(event);
                  }}
                  helperText={
                    error === 0 ? CommonString.SelectPrexeliteError : ""
                  }
                  textTransform={"capitalize"}
                />
                <Input
                  title={"typing speed (in numbers)"}
                  placeholder={"Typing Speed"}
                  error={error === 1}
                  helperText={error === 1 ? CommonString.EnterValue : ""}
                  type="Number"
                  icon={<Icon icon="ooui:user-avatar-outline" />}
                  value={typingSpeed}
                  handleChange={(event) => setTypingSpeed(event.target.value)}
                />
              </FormDataContainer>
            </FormContainer>
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => onSave()}
                buttonName={props.data == null ? "Save" : "Update"}
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
