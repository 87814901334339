import AssetProvider from "../services/provider/assetProvider";

export default class AssetController {
  constructor(props) {
    this.assetProvider = new AssetProvider();
  }

  addAsset = async (data) => {
    const response = await this.assetProvider.addAsset(data);
    if (response.data.status === 200) {
      return { success: true, data: response.data.data };
    }
    return { success: false, data: "" };
  };
  getAsset = async (data) => {
    const response = await this.assetProvider.getAsset();
    if (response.status === 200) {
      return response.data.data;
    }
    return false;
  };
}
