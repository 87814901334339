import React from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

export default function ShimmerTrainingPpt() {
  return (
    <React.Fragment>
      <ShimmerThumbnail height={500} rounded />
      <ShimmerThumbnail height={10} rounded />
    </React.Fragment>
  );
}
