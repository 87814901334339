import { EndPoint } from "../../utils/endpoints";
import ApiUtils from "../APICallServices/APIUtils";

export default class EmployeeProvider {
  constructor() {
    this.EmployeeApiUtils = new ApiUtils();
  }
  getEmployeeList = () => {
    return this.EmployeeApiUtils.init().get("recruiter/get-recruiter");
  };
  getUpcomingEmployeeList = () => {
    return this.EmployeeApiUtils.init().get(EndPoint.getUpcomingBdayApi2);
  };
  getEmployeeImage = (employeeImageURL) => {
    return this.EmployeeApiUtils.init().get("profile/" + employeeImageURL);
  };
  addEmployee = (newrecruiterdetail) => {
    return this.EmployeeApiUtils.init().post(
      "recruiter/add-recruiter",
      newrecruiterdetail
    );
  };
  updateEmployeeDetails = (recruiterUpdatedData) => {
    return this.EmployeeApiUtils.init().post(
      "recruiter/update-recruiter",
      recruiterUpdatedData
    );
  };
  removeEmployee = (employeeId) => {
    return this.EmployeeApiUtils.init().post("", employeeId);
  };
  getEmployeeImages = (employeeImageURL) => {
    return this.EmployeeApiUtils.init().get(employeeImageURL);
  };
  getPersonalDetails = (data) => {
    return this.EmployeeApiUtils.init().post(
      "recruiter/get-recruiter-personal-details-by-id",
      data
    );
  };
  updateEmployeePersonalDetails = (data) => {
    return this.EmployeeApiUtils.init().post(
      "recruiter/update-recruiter-personal-details",
      data
    );
  };
  updateMeasurenment = (data) => {
    return this.EmployeeApiUtils.init().post(
      "recruiter/add-recruiter-personal-body-details",
      data
    );
  };
  getMeasurenment = (data) => {
    return this.EmployeeApiUtils.init().post(
      "recruiter/get-recruiter-personal-body-details-by-id",
      data
    );
  };

  addToken = (data) => {
    return this.EmployeeApiUtils.init().post("recruiter/add-token", data);
  };
}
