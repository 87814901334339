import SynergyLeagueProvider from "../services/provider/synergyLeagueProvider";

export default class SynergyLeagueController {
  constructor(props) {
    this.synergyLeagueProvider = new SynergyLeagueProvider();
  }
  getTeams = async () => {
    const response = await this.synergyLeagueProvider.getTeams();
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting synergy league teams in controller");
      return false;
    }
  };
  getSubTeams = async () => {
    const response = await this.synergyLeagueProvider.getSubTeams();
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting synergy league teams in controller");
      return false;
    }
  };

  getSports = async () => {
    const response = await this.synergyLeagueProvider.getSports();
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting synergy league sports in controller");
      return false;
    }
  };
  getUpcomingMatches = async () => {
    const response = await this.synergyLeagueProvider.getUpcomingMatches();
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting synergy league sports in controller");
      return false;
    }
  };
  getRecentMatches = async () => {
    const response = await this.synergyLeagueProvider.getRecentMatches();
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error("error while getting synergy league sports in controller");
      return false;
    }
  };
  getPointsTable = async () => {
    const response = await this.synergyLeagueProvider.getPointsTable();
    if (response.status === 200) {
      return response.data.data;
    } else {
      console.error(
        "error while getting synergy league points table in controller"
      );
      return false;
    }
  };
  addSports = async (data) => {
    const response = await this.synergyLeagueProvider.addSports(data);
    if (response.status === 200) {
      return true;
    } else {
      console.error("error while updating synergy league sports in controller");
      return false;
    }
  };
  addMatch = async (data) => {
    const response = await this.synergyLeagueProvider.addMatch(data);
    if (response.status === 200) {
      return true;
    } else {
      console.error("error while updating synergy league sports in controller");
      return false;
    }
  };
  updateMatch = async (data) => {
    const response = await this.synergyLeagueProvider.updateMatch(data);
    if (response.status === 200) {
      return true;
    } else {
      console.error("error while updating synergy league sports in controller");
      return false;
    }
  };
  deleteSport = async (data) => {
    const response = await this.synergyLeagueProvider.deleteSport(data);
    if (response.status === 200) {
      return true;
    } else {
      console.error("error while updating synergy league sports in controller");
      return false;
    }
  };
  deleteMatch = async (data) => {
    const sendData = {
      _id: data,
    };
    const response = await this.synergyLeagueProvider.deleteMatch(sendData);
    if (response.status === 200) {
      return true;
    } else {
      console.error("error while updating synergy league sports in controller");
      return false;
    }
  };
  deleteTeamMember = async (data) => {
    const response = await this.synergyLeagueProvider.deleteTeamMember(data);
    if (response.status === 200) {
      return true;
    } else {
      console.error("error while updating synergy league sports in controller");
      return false;
    }
  };
  updateTeamMember = async (data) => {
    const response = await this.synergyLeagueProvider.updateTeamMember(data);
    if (response.status === 200) {
      return true;
    } else {
      console.error("error while updating synergy league sports in controller");
      return false;
    }
  };
  uploadImages = async (data) => {
    const response = await this.synergyLeagueProvider.uploadImages(data);
    if (response.status === 200) {
      return true;
    } else {
      console.error("error while updating synergy league sports in controller");
      return false;
    }
  };
}
