import { Box } from "@mui/material";
import { forEach } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveDrawerkey } from "../../../reducer/dashboardreduxstore";
import {
  EmployeePopupOpener,
  OpenTab,
} from "../../../reducer/pagetransitionstore";
import {
  AppearAnimation,
  RelativePosContainer,
} from "../../../style/common.style";
import {
  BaseCardDashboardContainerThird,
  EmployeeLoaderContainer,
  NoDataEmpListTextHighlight,
} from "../../../style/dashboard.style";
import { EmployeeContainer } from "../../../style/leave.style";
import CommonColor from "../../../utils/common.colors";
import { CommonString } from "../../../utils/common.string";
import { getActiveDrawerData, getScreenLocation } from "../../../utils/utils";
import EmployeeDailyLogsShimmer from "../../Loader/shimmers/EmployeeDailyLogsShimmer";
import NoDataDashboard from "../NoData/NoDataDashboard";
import CarouselEmployee from "../carousel/CarouselEmployee";
import CardTitle from "./CardTitle";
import { SpeedyPrexeliteTitle } from "../../../utils/array.screens";

export default function TypingSpeed(props) {
  const employee = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openContent, setOpenContent] = useState(false);

  const [scrollPos, setScrollPos] = useState(0);

  function getTypingSpeedScreen() {
    return getActiveDrawerData(employee.employee_acess).filter((ele) => {
      return ele.key === CommonString.prexelLifeScreen;
    })[0];
  }

  function getTypingSpeedIndex() {
    let index = 0;
    forEach(getActiveDrawerData(employee.employee_acess), function (ele) {
      ++index;
      if (ele.key === CommonString.prexelLifeScreen) return false;
    });
    return index;
  }

  function ScreenOpener(employeeDetail) {
    if (employeeDetail) {
      dispatch(EmployeePopupOpener(employeeDetail.user.name));
    }
    if (props.cardLocation === getUpcomingBirthdayScreenIndex()) {
      dispatch(OpenTab(SpeedyPrexeliteTitle[2].name));
    }
    const typingScreen = getTypingSpeedScreen();
    dispatch(
      setActiveDrawerkey({
        element: typingScreen,
        index: getTypingSpeedIndex() - 1,
      })
    );
    history.push(typingScreen.path);
  }
  function getUpcomingBirthdayScreenIndex() {
    const index = getScreenLocation(employee, CommonString.prexelLifeScreen);
    return index;
  }
  function setterScrollPos(event) {
    const posInPercent = (event.currentTarget.scrollLeft / 169) * 100;
    setScrollPos(posInPercent);
  }
  function toggleContent() {
    setOpenContent(!openContent);
  }
  return (
    <React.Fragment>
      <AppearAnimation
        delay={props.serialOrder}
        duration={0.5}
        timingvalue="ease-in-out"
      >
        <Box
          sx={{
            backgroundColor: CommonColor.white,
            borderRadius: "4px",
          }}
        >
          <BaseCardDashboardContainerThird
            isvisible={employee.typing_tracker !== null ? 1 : 0}
            ispb={!openContent ? 1 : 0}
          >
            <CardTitle
              title={"Speedy Prexelites"}
              buttonChoice="2"
              activeIndexUpdater={() => ScreenOpener()}
              contentToggler={() => toggleContent()}
              openContent={openContent}
              data={employee.typing_tracker}
              isTypingShow={true}
              viewMoreText={"view more"}
            />
            {props.LoadingFlag ? (
              openContent ? (
                <EmployeeLoaderContainer>
                  <EmployeeDailyLogsShimmer />
                </EmployeeLoaderContainer>
              ) : (
                ""
              )
            ) : employee.typing_tracker.length === 0 ? (
              <NoDataDashboard
                text={
                  <>
                    No&nbsp;
                    <NoDataEmpListTextHighlight>
                      prexelites&nbsp;
                    </NoDataEmpListTextHighlight>
                    are currently in the list
                  </>
                }
                icon="material-symbols:celebration-outline-rounded"
              />
            ) : (
              <RelativePosContainer
                scroll={scrollPos}
                isvisible={openContent ? 1 : 0}
              >
                <EmployeeContainer onScroll={(event) => setterScrollPos(event)}>
                  {openContent ? (
                    <CarouselEmployee
                      data={employee.typing_tracker}
                      shownBirthday={false}
                      isTypingShow={true}
                      cardLocation={props.cardLocation}
                      ScreenOpener={ScreenOpener}
                      length={""}
                    />
                  ) : (
                    ""
                  )}
                </EmployeeContainer>
              </RelativePosContainer>
            )}
          </BaseCardDashboardContainerThird>
        </Box>
      </AppearAnimation>
    </React.Fragment>
  );
}
