import TrackerProvider from "../services/provider/TrackerProvider";

export default class TrackerController {
  constructor(props) {
    this.trackerProvider = new TrackerProvider();
  }

  addTypingScore = async (data) => {
    const response = await this.trackerProvider.addTypingScore(data);
    if (response.data.status === 200) {
      return true;
    }
    return false;
  };
  getTopTypers = async (data) => {
    const response = await this.trackerProvider.getTypingScore(data);
    if (response.status === 200) {
      return response.data.data;
    }
    return false;
  };
  deleteSpeedyPrexelite = async (data) => {
    const response = await this.trackerProvider.deleteSpeedyPrexelite(data);
    if (response.data.status === 200) {
      return true;
    } else {
      console.error(
        "message: ",
        response.data.message,
        "\ncode : ",
        response.data.status
      );
      return false;
    }
  };
}
