import React from "react";
import {
  DropzoneContainer,
  DropzoneIcon,
  DropzoneIconContainer,
  DropZoneText,
  FileChooseButton,
  LineDivider,
  MuiButton,
  ORContainer,
  ORText,
  IconContainerButton,
} from "../../../style/training.style";

export default function DropzoneDropField(props) {
  return (
    <React.Fragment>
      <DropzoneContainer>
        <DropzoneIconContainer>
          {/* <IconContainerButton variant="outlined"> */}
          <DropzoneIcon icon="fa6-solid:file-pdf" />
          {/* </IconContainerButton> */}
        </DropzoneIconContainer>

        {/* <ORContainer>
          <LineDivider />
          <ORText>OR</ORText>
          <LineDivider />
        </ORContainer> */}
        <FileChooseButton onClick={props.open}>Browse File</FileChooseButton>
        <DropZoneText> Or {props.text}</DropZoneText>
      </DropzoneContainer>
    </React.Fragment>
  );
}
