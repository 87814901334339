import { getMonth } from "date-fns";
import moment from "moment";
import React from "react";
import { CelebrationNavContainer, NavMonth } from "../../style/holiday.style";
import { Month } from "../../utils/constant";

export default function CelebrationNav(props) {
  return (
    <CelebrationNavContainer>
      {" "}
      <React.Fragment>
        {props.title} <NavMonth>{props.subtitle}</NavMonth>
      </React.Fragment>
    </CelebrationNavContainer>
  );
}
