import ApiUtils from "../APICallServices/APIUtils";

export default class AppealProvider {
    constructor(){
        this.appealApiUtils = new ApiUtils();
    }

    getEmployeeAppealData = (user_id) => {
        return this.appealApiUtils.init().post("v2/appeal_attendance/get-by-user_id" , { user_id } );
    }

    getAllEmployeeAppealData = () => {
        return this.appealApiUtils.init().get("v2/appeal_attendance/get");
    }

    addEmployeeAppeal = (data) => {
        return this.appealApiUtils.init().post("/v2/appeal_attendance/add" , data);
    }

    deleteEmployeeAppeal = (id) => {
        return this.appealApiUtils.init().post("/v2/appeal_attendance/delete" , {_id:id});
    }

    updateEmployeeAppeal = (data) => {
        return this.appealApiUtils.init().post("/v2/appeal_attendance/update",data);
    }
}