import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  GraphContainer,
  LeaveNumber,
  LeavesRemainingSubtitle,
  NumberContainer,
} from "../../style/profile.style";
import CommonColor from "../../utils/common.colors";

export default function CircularLeaveGraph(props) {
  return (
    <React.Fragment>
      <GraphContainer cicleWidth={props.cicleWidth}>
        <CircularProgressbarWithChildren
          value={props.used_leave}
          maxValue={props.total_leave}
          strokeWidth={3}
          styles={{
            path: {
              // Path color
              stroke: CommonColor.primary_color,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "round",
              // Customize transition animation
              transition: "stroke-dashoffset 0.5s ease 0s",
              // Rotate the path
            },

            trail: {
              // Trail color
              stroke: CommonColor.primary_color_opacity_05,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "round",
              // Rotate the trail
            },
          }}
        >
          <NumberContainer>
            <LeaveNumber fontSize={props.fontSizeNum}>
              {props.leave_bal}
            </LeaveNumber>
            <LeavesRemainingSubtitle
              fontSize={props.fontSizeText}
              fontWeightText={props.fontWeightText}
            >
             { props.isWfh ? "WFH" : "Leave" } Balance
            </LeavesRemainingSubtitle>
          </NumberContainer>
        </CircularProgressbarWithChildren>
      </GraphContainer>
    </React.Fragment>
  );
}
