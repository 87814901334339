import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import LeaveController from "../../controller/LeaveController";
import { updateLeaveNumbers } from "../../reducer/leavereduxstore";
import {
  EmployeeCardDivider,
  GraphDetailContainer,
  LeaveIndicator,
  LeaveInfoCardInnerContainer,
  LeaveInfoDetailContainer,
  LeaveInfoSmallContainer,
  LeaveInfoTextContainer,
  VerticalScrollableAreaLeave,
} from "../../style/leave.style";
import {
  LeaveInfoCardDataContainer,
  LeaveInfoData,
  LeaveInfoTitle,
  ScrollerContent,
} from "../../style/profile.style";

import CircularLeaveGraph from "../commonComponents/CircularLeaveGraph";
import PageRefreshLoader from "../Loader/PageRefreshLoader";
import LeaveInfoCard from "./LeaveInfoCard";
import moment from "moment";

export default function LeaveInfoDetail(props) {
  const auth = useSelector((state) => state.auth);
  const leave = useSelector((state) => state.leave);

  const leaveController = new LeaveController();
  const dispatch = useDispatch();
  useEffect(() => {
    getLeaveNumbers();
  }, []);

  async function getLeaveNumbers() {
    if (auth.employee_data.user == null) return;
    const sendData = {
      recruiter_id: auth.employee_data.user._id,
      month: moment().month() + 1,
      year: moment().year(),
    };

    const data = await leaveController.getLeaveNumbers(sendData);
    if (data !== null) {
      dispatch(updateLeaveNumbers(data));
    }
  }
  const HeightDetermine = (leaveComponent) => {
    if (leaveComponent === "Leave Info") {
      return "44vh";
    } else if (leaveComponent === "leave Application") {
      return "62vh";
    }
  };
  function getUserLeaveDetails() {
    if (leave.leave_list === null) return;
    let leaveDatas = [];
    leave.leave_list.map((e, index) => {
      if (props.leaveShowNumber) {
        if (index >= props.leaveShowNumber) {
          return;
        }
        if (e.leave.status === "PENDING") return;
      }
      leaveDatas.push(
        <LeaveInfoCardInnerContainer style={{ margin: "4%" }}>
          <LeaveInfoCard
            data={e}
            length={leave.leave_list.length}
            onClick={() => {}}
            index={index}
            ActiveEmployeeIndex={""}
            setselectedStatus={() => {}}
          />
        </LeaveInfoCardInnerContainer>
      );
    });
    return leaveDatas;
  }

  return (
    <React.Fragment>
      <LeaveInfoDetailContainer
        leaveComponent={props.leaveComponent}
        component={props.screen}
        ispadding={!Number.isInteger(props.leaveShowNumber)}
      >
        <EmployeeCardDivider componentType={props.screen} />
        <VerticalScrollableAreaLeave
          height={"100%"}
          unit={""}
          mobileheight={HeightDetermine(props.leaveComponent)}
          component={0}
          id="scrollableLeaveInfoComponent"
        >
          <ScrollerContent>
            <GraphDetailContainer component={props.screen}>
              <LeaveInfoTextContainer>
                <LeaveIndicator component="1"></LeaveIndicator>
                <LeaveInfoCardDataContainer>
                  <LeaveInfoData component="1" horizintalFlex="flex-start">
                    {leave.total_leave}
                  </LeaveInfoData>
                  <LeaveInfoTitle component="1" parentComponent={props.screen}>
                    Total Leaves
                  </LeaveInfoTitle>
                </LeaveInfoCardDataContainer>
              </LeaveInfoTextContainer>
              <CircularLeaveGraph
                used_leave={leave.used_leave}
                total_leave={leave.total_leave}
                leave_bal={leave.leave_balance}
                remainingBalanace={leave.total_leave - leave.used_leave}
                circleWidth={props.circleWidth}
              />

              <LeaveInfoTextContainer>
                <LeaveInfoCardDataContainer component="2">
                  <LeaveInfoData component="2" horizintalFlex="flex-end">
                    {leave.used_leave}
                  </LeaveInfoData>
                  <LeaveInfoTitle component="2" parentComponent={props.screen}>
                    Leaves Used
                  </LeaveInfoTitle>
                </LeaveInfoCardDataContainer>
                <LeaveIndicator component="2"></LeaveIndicator>
              </LeaveInfoTextContainer>
            </GraphDetailContainer>
            <LeaveInfoSmallContainer>
              {props.isLoading ? (
                <PageRefreshLoader />
              ) : (
                getUserLeaveDetails(props.leaveList)
              )}
            </LeaveInfoSmallContainer>
          </ScrollerContent>
        </VerticalScrollableAreaLeave>
      </LeaveInfoDetailContainer>
    </React.Fragment>
  );
}
