import { createSlice } from "@reduxjs/toolkit";

export const leaveSlice = createSlice({
  name: "material",
  initialState: {
    event_pdf_material: [],
    active_material: [],
    isPdfLoaded: false,
  },
  reducers: {
    setPdfMaterial: (state, action) => {
      state.event_pdf_material = action.payload;
    },
    setActiveMaterial: (state, action) => {
      state.active_material = action.payload;
    },
    setPdfLoaded: (state, action) => {
      state.isPdfLoaded = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPdfMaterial, setActiveMaterial, setPdfLoaded } =
  leaveSlice.actions;

export default leaveSlice.reducer;

/*

*/
