/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import AddEvent from "../../component/Forms/AddEvent";
import ConfirmationMenu from "../../component/Popup/ConfirmationMenu";
import ModalPopup from "../../component/Popup/ModalPopup";
import EventController from "../../controller/eventController";
import { deleteEvent, setFullEvent } from "../../reducer/eventreduxstore";
import { FlexContainer } from "../../style/asset.style";
import { TypoGraphy } from "../../style/common.style";
import { IconifyIcon } from "../../style/employee.style";
import {
  ButtonContainer,
  DeletePdfButton,
  EditDeletButtonContainer,
  EventIcon,
  EventIconContainer,
  EventWrapper,
} from "../../style/event.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import {
  TwelveHourFormatAmPm,
  formatDisplayDate,
  getOrdinal,
} from "../../utils/utils";

export default function EventCard(props) {
  const event = useSelector((state) => state.event);
  const { enqueueSnackbar } = useSnackbar();
  const employee = useSelector((state) => state.employee);
  const [openPopup, setOpenPopup] = useState(false);
  const [isEditPopup, setIsPopupEdit] = useState(false);
  const dispatch = useDispatch();
  const eventController = new EventController();
  const [isLoading, setLoader] = useState(false);
  const [popupSize, setPopupSize] = useState("sm");
  const [isHovered, setIsHovered] = useState(false);

  function closePopup() {
    setOpenPopup(false);
    return;
  }
  async function onDelete() {
    setOpenPopup(true);
    setIsPopupEdit(false);
    setPopupSize("sm");
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  function onEditEvent() {
    setOpenPopup(true);
    setIsPopupEdit(true);
    setPopupSize("xs");
  }

  async function onConfirmDelete() {
    setLoader(true);
    const resp = await eventController.onDeleteEvent({
      _id: props.element._id,
    });
    if (resp) {
      const newEventList = event.all_events.filter(
        (event) => event.data._id !== props.element._id
      );
      dispatch(setFullEvent(newEventList));
    }

    const successMsg = (
      <div>
        <div>success</div>
        <div>event deleted successfully</div>
      </div>
    );
    const errorMsg = (
      <div>
        <div>error</div>
        <div>try again after some time</div>
      </div>
    );
    setTimeout(() => {
      setLoader(false);
      if (resp) {
        setOpenPopup(false);
        dispatch(deleteEvent(props.element));
        handleAlert(successMsg, "success");
        return;
      }
      setOpenPopup(false);
      handleAlert(errorMsg, "error");
    }, 500);
    dispatch(deleteEvent(props.element));
    closePopup();
  }

  function getModalChildren() {
    if (isEditPopup) {
      return (
        <AddEvent
          mobileheight="80"
          onHandleClose={closePopup}
          data={props.element}
          isEdit={true}
          closePopup={closePopup}
        />
      );
    }

    return (
      <ConfirmationMenu
        isShow={isLoading}
        title="Are you Sure you want to Delete this event?"
        yesButtonName="Yes"
        onClickYesButton={() => onConfirmDelete()}
        noButtuonName="No"
        onClicknoButton={() => closePopup()}
        color={CommonColor.primary_color}
      ></ConfirmationMenu>
    );
  }

  return (
    <React.Fragment key={props.index}>
      <ModalPopup
        open={openPopup}
        popupSize={popupSize}
        issmallScreen={popupSize === "xs" ? false : true}
      >
        {getModalChildren()}
      </ModalPopup>
      <EventWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        index={props.index}
        length={props.length}
      >
        <FlexContainer jc="space-between">
          <TypoGraphy
            fontSize={FontSizes.Title}
            color={CommonColor.primary_color}
            weight="700"
          >
            {formatDisplayDate(props.element.event_date).format("D")}
            <sup>
              {getOrdinal(
                formatDisplayDate(props.element.event_date).format("D")
              )}
            </sup>
            {formatDisplayDate(props.element.event_date).format(" MMM 'YY")}
          </TypoGraphy>
          <EventIconContainer
            height="65px"
            width="60px"
            top="-30px"
            right="25px"
          >
            <EventIcon src={CommonImages.EventNewsIcon} alt="" />
          </EventIconContainer>
        </FlexContainer>

        <FlexContainer flexDirection="column" margin="4% 0 0 0">
          <FlexContainer jc="space-between">
            <TypoGraphy
              textAlign={"start"}
              width="max-content"
              fontSize={FontSizes.Para}
              color={CommonColor.text_gray}
              weight="600"
            >
              {props.element.event_name}
            </TypoGraphy>
            <ButtonContainer isvisible={1}>
              {/* <ButtonContainer isvisible={false}> */}
              {employee.employee_acess.events ? (
                <React.Fragment>
                  <EditDeletButtonContainer>
                    <DeletePdfButton onClick={() => onDelete()}>
                      <IconifyIcon
                        icon="material-symbols:delete-rounded"
                        color={CommonColor.primary_color}
                      />
                    </DeletePdfButton>
                  </EditDeletButtonContainer>
                  <EditDeletButtonContainer>
                    <DeletePdfButton onClick={() => onEditEvent()}>
                      <IconifyIcon
                        icon="material-symbols:edit-rounded"
                        color={CommonColor.primary_color}
                      />
                    </DeletePdfButton>
                  </EditDeletButtonContainer>
                </React.Fragment>
              ) : (
                ""
              )}
            </ButtonContainer>
          </FlexContainer>
          <FlexContainer jc="space-between" margin="2% 0 0 0">
            <FlexContainer width="max-content">
              <TypoGraphy
                fontSize={FontSizes.Para}
                color={CommonColor.primary_color}
                weight="700"
              >
                By:&nbsp;{props.element.recruiter.name}
              </TypoGraphy>
            </FlexContainer>
            <TypoGraphy
              width="max-content"
              fontSize={FontSizes.Para}
              color={CommonColor.text_gray}
              weight="700"
            >
              {TwelveHourFormatAmPm(props.element.event_time)}
            </TypoGraphy>
          </FlexContainer>
        </FlexContainer>
      </EventWrapper>
    </React.Fragment>
  );
}
