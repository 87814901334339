import { MenuItem } from "@mui/material";
import React from "react";
import { MenuItemText } from "../../style/holiday.style";

export default function MenuOptionListNavbarMobile(props) {
  return (
    <React.Fragment>
      {props.list.map((element, index) => {
        return (
          <MenuItem
            onClick={(event) => props.handleOptionClick(element, event)}
            key={index}
          >
            <MenuItemText>{element}</MenuItemText>
          </MenuItem>
        );
      })}
    </React.Fragment>
  );
}
