import { DialogContent, DialogTitle } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import React, { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import {
  setActivePolicy,
  setPolicyLoaded,
} from "../../reducer/policyreduxstore";
import {
  BigVerticalScrollableArea,
  PdfViewerContainer,
} from "../../style/form.style";
import {
  BigPopupContentContainer,
  BigPopupMainContainer,
  PdfCardContainerPopup,
  PopupContainer,
  PopupNavBarContainerTraining,
} from "../../style/training.style";
import { BaseUrl } from "../../utils/constant";
import { delay } from "../../utils/utils";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import PolicyPdf from "../policy/PolicyFile";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js`;

export default function PolicyViewer(props) {
  const dispatch = useDispatch();
  const policyList = useSelector((state) => state.policy.policy);
  const policy = useSelector((state) => state.policy);
  const [activeIndex, setActiveIndex] = useState(policy.active_policy);

  const [frameLoader, setFrameLoader] = useState(true);
  const finalUrl = `${BaseUrl}policy/${encodeURIComponent(getUrl())}`;

  useEffect(() => {
    getLoader();
  }, []);

  function OpenPoicy(index) {
    setActiveIndex(index);
    dispatch(setActivePolicy(index));
    dispatch(setPolicyLoaded(false));
  }

  function policyLoadingFinish() {
    setTimeout(() => {
      dispatch(setPolicyLoaded(true));
    }, 1000);
  }
  function disableClick() {}

  async function getLoader() {
    setFrameLoader(true);
    await delay(1000);
    setFrameLoader(false);
  }
  function getUrl() {
    return policy.policy[activeIndex].uploaded_url;
  }
  return (
    <React.Fragment>
      <DialogTitle>
        <PopupNavBarContainerTraining>
          <PopupNavBar
            onHandleClose={props.PdfCloser}
            componentType="navbarmain"
            componentSize="big"
            title="Policy"
          />
        </PopupNavBarContainerTraining>
      </DialogTitle>
      <DialogContent>
        <BigPopupMainContainer>
          <BigPopupContentContainer>
            <PopupContainer>
              {policyList &&
                policyList.length !== 0 &&
                policyList.map((file, index) => {
                  return (
                    <PdfCardContainerPopup key={index}>
                      <PolicyPdf
                        index={index}
                        file={file}
                        OpenPdf={(i) => OpenPoicy(i)}
                        showActive={false}
                        ActiveIndex={0}
                        PopupType="bigpopup"
                      />
                    </PdfCardContainerPopup>
                  );
                })}
            </PopupContainer>
            <PdfViewerContainer>
              <BigVerticalScrollableArea
                height="90"
                unit="vh"
                mobileheight="95"
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
                  <Viewer fileUrl={finalUrl} />
                </Worker>
              </BigVerticalScrollableArea>
            </PdfViewerContainer>
          </BigPopupContentContainer>
        </BigPopupMainContainer>
      </DialogContent>
    </React.Fragment>
  );
}

// PopupContainer
