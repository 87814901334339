import { AppBar, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBarStyle } from "../../style/drawer.style";

import TailwindDropdownMembers from "../../component/commonComponents/inputs/TailWindDropdownMembers";
import NoData from "../../component/commonComponents/NoData/NoData";
import NavBar from "../../component/drawer/NavBar";
import ApplyWorkFromHome from "../../component/Forms/ApplyWorkFromHome";
import LeaveBalanceGraph from "../../component/LeaveInfo/LeaveBalanceGraph";
import PageRefreshLoader from "../../component/Loader/PageRefreshLoader";
import ModalPopup from "../../component/Popup/ModalPopup";
import WorkFromHomeDetail from "../../component/wfh/WorkFromHomeDetails";
import WorkFromHomeInfoCard from "../../component/wfh/WorkFromHomeInfoCard";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import { setIsModalOpen } from "../../reducer/dashboardreduxstore";
import { setWorkFromHomeDetails } from "../../reducer/workfromhomereduxstore";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";

import {
  CustomMarginAndFlexBox,
  MobileOnlyDiv,
} from "../../style/common.style";
import {
  LeaveApplicationApplyLeaveButton,
  LeaveApplicationContainer,
  LeaveApplicationGrid,
  LeaveBalanceGraphContainer,
  LeaveInfoCardContainer,
  NoDataComponentContainerLeaveApp,
  VerticalScrollableAreaLeaveInfo,
} from "../../style/leave.style";
import { userdata } from "../../utils/common.key";
import { CommonString } from "../../utils/common.string";
import WorkFromHomeController from "../../controller/workFromHomeController";

const WorkFromHome = (props) => {
  useEffect(() => {
    isAdmin && setAdmin();
    getData();
  }, []);
  
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(0);
  const [activeTab, setActiveTab] = useState("PENDING");
  const [pageLoader, setPageLoader] = useState(false);
  const [IsPopupOpen, setOpenPopup] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(
    CommonString.pending.toUpperCase()
  );
  const [ActiveEmployeeData, setActiveEmployeeData] = useState();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const employee = useSelector((state) => state.employee);
  const isAdmin = employee.employee_acess.attendance;

  const dashboard = useSelector((state) => state.dashboard);

  const wfhData = useSelector((state) => state.workfromhome);

  const workFromHomeController = new WorkFromHomeController();

  const [selectedEmp, setSelectedEmp] = useState();
 

  const [selectedWFH , setSelectedWFH] = useState(null);

  const employee_list = useSelector((state) => state.employee.employee_list);
  const [activeEmployeeData1, setActiveEmployeeData1] = useState(
    getLocalStorage(userdata).user
  );
  function setAdmin(){
  
    const xdata = getEmployeeList()?.filter((data)=> data._id  === getLocalStorage(userdata).user._id);
    setData(xdata[0]);
    
  }

  function setData(data){
    setActiveEmployeeData1(data);
    setSelectedEmp(data);
  }
  

  async function getData() {
    setPageLoader(true);
    await props.getWFH();
    setPageLoader(false);
  }

  async function onEmpchange(event) {

    
    setSelectedEmp(event);
    setActiveEmployeeData1(event);

    let empData = await workFromHomeController.getWorkFromHomePersonalDetails({
      user_id: event._id,
    });

    if (empData.status === 200) {
      dispatch(
        setWorkFromHomeDetails({
          message: empData.message,
          remaining_wfh_days: empData.remaining_wfh_days,
          used_wfh_days: empData.used_wfh_days,
          wfh_available_days: empData.wfh_available_days,
        })
      );
    } else {
      dispatch(
        setWorkFromHomeDetails({
          remaining_wfh_days: 0,
          used_wfh_days: 0,
          wfh_available_days: 0,
        })
      );
    }
  }

  function openPopup() {
    setOpenPopup(true);
  }
  function getEmployeeList() {
    return employee_list;
  }

  function setindex(data, index) {
    setActiveEmployeeData(data);

    if (isAdmin) {
      const datas = getEmployeeList()?.find(
        (datas) => datas._id === data.user_id
      );
      onEmpchange(datas);
    }
  }

  function getChildren() {
    if (selectedStatus === CommonString.edit_leave.toUpperCase()) {
      return (
        <ApplyWorkFromHome
          IsPopupOpen={dashboard.isModalOpen}
          isEdit={true}
          onHandleClose={() => closePopup()}
          selectedWFH = {selectedWFH}
        />
      );
    }

    return (
      <ApplyWorkFromHome
        IsPopupOpen={props.IsPopupOpen}
        onHandleClose={() => closePopup()}
        isEdit={false}
        forUser={getLocalStorage(userdata)?.user}
      />
    );
  }

  function closePopup() {
    dispatch(setIsModalOpen(false));
    setselectedStatus(CommonString.pending.toUpperCase());
    // dispatch(updateSelectedLeaveDate(null));
    setOpenPopup(false);
  }

  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Work From Home"}
            NavBarIcon={{
              icon: "carbon:calendar-add",
              name: "Apply work From Home",
            }}
          />
        </Toolbar>
      </AppBar>

      <LeaveApplicationContainer container>
        <LeaveApplicationGrid className="grid2" item>
          <VerticalScrollableAreaLeaveInfo
            height={"100%"}
            unit={""}
            mobileheight=""
            display="none"
          >
            {pageLoader ? (
              <PageRefreshLoader />
            ) : wfhData.work_from_home_data.filter(
                (data) => data.status === CommonString.pending
              ).length > 0 ? (
              wfhData.work_from_home_data
                .filter((data) => data.status === CommonString.pending)
                .map((EmployeeWFHData, index, array) => {
                  return (
                    <LeaveInfoCardContainer key={index}>
                      <WorkFromHomeInfoCard
                        index={index}
                        data={EmployeeWFHData}
                        length={wfhData.work_from_home_data.length}
                        ActiveEmployeeData={ActiveEmployeeData}
                        isEdit={true}
                        isActive={
                          ActiveEmployeeData
                            ? EmployeeWFHData._id === ActiveEmployeeData._id
                            : ""
                        }
                        onClick={onEmpchange}
                        emplist = {getEmployeeList}
                        setselectedStatus={setselectedStatus}
                        setSelectedWFH={ setSelectedWFH }
                        selectedWFH={selectedWFH}
                        onHandleClose={() => closePopup()}
                        onClickOn = {true}
  
                        
                      />
                    </LeaveInfoCardContainer>
                  );
                })
            ) : wfhData.work_from_home_data.filter(
                (data) => data.status === CommonString.pending
              ).length === 0 ? (
              <React.Fragment>
                <NoDataComponentContainerLeaveApp>
                  <NoData noDataText={CommonString.NoPendingWfhFound} />
                  <CustomMarginAndFlexBox mt="2%">
                    <LeaveApplicationApplyLeaveButton>
                      <ButtonCustomV2
                        variant="contained"
                        buttonText={"Apply Work From Home"}
                        onClick={() => openPopup()}
                      />
                    </LeaveApplicationApplyLeaveButton>
                  </CustomMarginAndFlexBox>
                </NoDataComponentContainerLeaveApp>
              </React.Fragment>
            ) : (
              ""
            )}
          </VerticalScrollableAreaLeaveInfo>
        </LeaveApplicationGrid>

        <LeaveApplicationGrid className="grid2" item>
          <VerticalScrollableAreaLeaveInfo
            height={"100%"}
            unit={""}
            mobileheight=""
            display="none"
          >
            {isAdmin && (
              <TailwindDropdownMembers
                // title="Assigned To"
                dataList={getEmployeeList()}
                title={""}
                value={selectedEmp}
                handleChange={(event) => {
                  onEmpchange(event);
                }}
                textTransform={"capitalize"}
                placeholder={"Select Prexelite"}
              />
            )}
            <WorkFromHomeDetail
              selectedEmp={activeEmployeeData1}
              isLoading={pageLoader}
              leaveShowNumber={10000}
              height="77"
              circleWidth={"70%"}
              setSelectedWFH={ setSelectedWFH }
              selectedWFH={selectedWFH}
              onClickOn = {false}

            />
          </VerticalScrollableAreaLeaveInfo>
        </LeaveApplicationGrid>
        <MobileOnlyDiv>
          <LeaveBalanceGraphContainer>
            <LeaveBalanceGraph
              // wfhData.work_from_home_data.total_leave
              isWfh={true}
              total_leave={10}
              //wfhData.work_from_home_data.used_leave
              used_leave={6}
            ></LeaveBalanceGraph>
          </LeaveBalanceGraphContainer>
          (
          {wfhData.work_from_home_data.length !== 0 ? (
            <>
              {wfhData.work_from_home_data
                .filter((data) => data.status === CommonString.pending)
                ?.map((wfh, index) => {
                  return (
                    <LeaveInfoCardContainer key={index}>
                      <WorkFromHomeInfoCard
                        index={index}
                        data={wfh}
                        length={wfhData.work_from_home_data?.length}
                        ActiveEmployeeData={ActiveEmployeeData}
                        isEdit={true}
                        isActive={
                          ActiveEmployeeData
                            ? wfh._id === ActiveEmployeeData._id
                            : ""
                        }
                        onClick={setindex}
                        setselectedStatus={setselectedStatus}
                        setSelectedWFH={ setSelectedWFH }
                        selectedWFH ={selectedWFH}
                      />
                    </LeaveInfoCardContainer>
                  );
                })}
            </>
          ) : (
            <></>
          )}
          )
        </MobileOnlyDiv>
      </LeaveApplicationContainer>
      <ModalPopup open={dashboard.isModalOpen || IsPopupOpen} popupSize="sm">
        {getChildren()}
      </ModalPopup>
    </React.Fragment>
  );
};

export default WorkFromHome;
