import { DialogContent, DialogTitle } from "@mui/material";
import React, { useCallback, useState } from "react";
import { TypoGraphy } from "../../style/common.style";
import {
  AddPdfFormContainer,
  BigFormContainer,
  DropAndFileZone,
  FileZoneContainer,
  MsgContainer,
  MsgHeading,
  PopupNavBarContainer,
} from "../../style/form.style";
import {
  ChangeFileButton,
  ChangeFileButtonContainer,
  DropInput,
  DropzoneComponentContainer,
  DropzoneMainContainer,
  LoadingBgContainer,
  PdfDataContainer,
  PdfPreview,
  PdfUpadteFormContainer,
} from "../../style/training.style";

import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import MaterialController from "../../controller/materialController";
import PolicyController from "../../controller/PolicyController";
import { setPdfMaterial } from "../../reducer/materialreduxstore";
import { setPolicy } from "../../reducer/policyreduxstore";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import { BaseUrl, MaterialBaseUrl } from "../../utils/constant";
import MyDropzone from "../../component/commonComponents/dropzoneComponent/dropzone";
import ShowLoader from "../../component/Loader/ShowLoader";
import AddPdfData from "../../component/Forms/AddPdfData";
import PopupNavBar from "../../component/commonComponents/Popups/PopupNavBar";

export default function AddPolicy(props) {
  const [activeFile, setActiveFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const materialController = new MaterialController();
  const policyController = new PolicyController();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  // Updated useDropzone to accept only PDF files
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    fileUpdater(file);
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "application/pdf", // Ensure only PDFs are accepted
    maxFiles: 1,
    onDrop,
  });

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  const getTitle = () => {
    if (props.isPolicy) {
      if (props.isEdit) return "Update Policy";
      return "add policy";
    }
    return "add training material";
  };

  // Updated fileUpdater to validate file type
  function fileUpdater(data) {
    if (data.type === "application/pdf") {
      setActiveFile(data);
    } else {
      handleAlert("Please select a PDF file.", "error");
    }
  }

  async function onPolicySave(data) {
    if (data.pdfTitle === "") return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("policy", activeFile);

    const resFileUpload = await policyController.uploadPolicy(formData);

    if (resFileUpload) {
      const sendData = {
        name: data.pdfTitle,
        description: data.pdfDescription,
        uploaded_url: `${resFileUpload.url}`,
      };
      const respAddData = await policyController.addPolicy(sendData);

      if (respAddData) {
        //success
        const sucessMsg = (
          <MsgContainer>
            <MsgHeading>success</MsgHeading>
            <sucessMsg>Policy ADD successfully</sucessMsg>
          </MsgContainer>
        );
        const policyData = await policyController.getPolicy();
        if (policyData.length !== 0) {
          dispatch(setPolicy(policyData.data.data));
        }
        setTimeout(() => {
          handleAlert(sucessMsg, "success");
          setIsLoading(false);
          closePopup();
        }, 2000);
        return;
      }
      //error
      const errorMsg = (
        <MsgContainer>
          <MsgHeading>error</MsgHeading>
          <sucessMsg>error while updating Policy data</sucessMsg>
          <sucessMsg>Please try again later</sucessMsg>
        </MsgContainer>
      );
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        setIsLoading(false);
        closePopup();
      }, 2000);
      return;
    }
    //error
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>error while uploading Policy</sucessMsg>
        <sucessMsg>Please try again later</sucessMsg>
      </MsgContainer>
    );
    setTimeout(() => {
      handleAlert(errorMsg, "error");
      setIsLoading(false);
      closePopup();
    }, 2000);
    return;
  }

  async function onUpdatePolicy(data) {
    if (data.pdfTitle === "" || data.pdfDescription === "") return;
    setIsLoading(true);
    let rdata;
    if (activeFile) {
      const formData = new FormData();
      formData.append("policy", activeFile);

      const resFileUpload = await policyController.uploadPolicy(formData);

      if (resFileUpload) {
        rdata = {
          _id: props.data._id,
          name: data.pdfTitle,
          description: data.pdfDescription,
          uploaded_url: `${resFileUpload.url}`,
        };
      }
    } else {
      rdata = {
        _id: props.data._id,
        name: data.pdfTitle,
        description: data.pdfDescription,
      };
    }

    const responseData = await policyController.updatePolicy(rdata);

    if (responseData) {
      //success
      const sucessMsg = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>Policy Update successfully</sucessMsg>
        </MsgContainer>
      );
      const policyData = await policyController.getPolicy();
      if (policyData.length !== 0) {
        dispatch(setPolicy(policyData.data.data));
      }
      setTimeout(() => {
        handleAlert(sucessMsg, "success");
        setIsLoading(false);
        closePopup();
      }, 2000);
      return;
    }

    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>error while uploading Policy PDF</sucessMsg>
        <sucessMsg>Please try again later</sucessMsg>
      </MsgContainer>
    );
    setTimeout(() => {
      handleAlert(errorMsg, "error");
      setIsLoading(false);
      closePopup();
    }, 2000);
    return;
  }

  async function onSave(data) {
    if (data.pdfTitle === "") return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("material", activeFile);
    const resFileUpload = await materialController.uploadMaterial(formData);

    if (resFileUpload) {
      const sendData = {
        type: data.eventType,
        user_id: auth.employee_data.user._id,
        department: data.wing,
        file_url: MaterialBaseUrl + resFileUpload.url,
        title: data.pdfTitle,
        added_by: auth.employee_data.user.name,
      };
      const respAddData = await materialController.addMaterial(sendData);
      if (respAddData) {
        //success
        const sucessMsg = (
          <MsgContainer>
            <MsgHeading>success</MsgHeading>
            <sucessMsg>training material updated successfully</sucessMsg>
          </MsgContainer>
        );
        const trainingData = await materialController.getEmployeePdf();
        if (trainingData.length !== 0) {
          dispatch(setPdfMaterial(trainingData.data));
        }
        setTimeout(() => {
          handleAlert(sucessMsg, "success");
          setIsLoading(false);
          closePopup();
        }, 2000);
        return;
      }
      //error
      const errorMsg = (
        <MsgContainer>
          <MsgHeading>error</MsgHeading>
          <sucessMsg>error while updating training data</sucessMsg>
          <sucessMsg>Please try again later</sucessMsg>
        </MsgContainer>
      );
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        setIsLoading(false);
        closePopup();
      }, 2000);
      return;
    }
    //error
    const errorMsg = (
      <MsgContainer>
        <MsgHeading>error</MsgHeading>
        <sucessMsg>error while uploading training material</sucessMsg>
        <sucessMsg>Please try again later</sucessMsg>
      </MsgContainer>
    );
    setTimeout(() => {
      handleAlert(errorMsg, "error");
      setIsLoading(false);
      closePopup();
    }, 2000);
    return;
  }

  console.log("activefile -> ", props, activeFile);

  function getComponent() {
    if (activeFile === null && !props.isEdit) {
      return (
        <FileZoneContainer>
          <DropAndFileZone>
            <DropzoneComponentContainer>
              <MyDropzone fileUpdater={fileUpdater} files={activeFile} />
            </DropzoneComponentContainer>
          </DropAndFileZone>
        </FileZoneContainer>
      );
    } else {
      return (
        <PdfUpadteFormContainer>
          {isLoading ? (
            <LoadingBgContainer>
              {" "}
              <ShowLoader
                loaderData={CommonImages.circularLoader}
                height="100px"
                width="164px"
              />
              <TypoGraphy
                fontSize={FontSizes.Mulish}
                fontWeight="500"
                color={CommonColor.primary_color}
              >
                Uploading Pdf...
              </TypoGraphy>
            </LoadingBgContainer>
          ) : (
            ""
          )}

          <PdfDataContainer>
            <ChangeFileButtonContainer>
              <DropzoneMainContainer {...getRootProps()}>
                <DropInput {...getInputProps()} />
                <ChangeFileButton variant="contained" onClick={open}>
                  Change File
                </ChangeFileButton>
              </DropzoneMainContainer>
            </ChangeFileButtonContainer>
            {(!props.isEdit || activeFile !== null) && (
              <PdfPreview src={URL.createObjectURL(activeFile)} />
            )}
            {props.isEdit && (
              <PdfPreview src={`${BaseUrl}policy/` + props.data.uploaded_url} />
            )}
          </PdfDataContainer>
          <AddPdfFormContainer>
            <AddPdfData
              isEdit={props.isEdit}
              data={props.data}
              isPolicy={props.isPolicy}
              onSave={(data) =>
                props.isPolicy ? onPolicySave(data) : onSave(data)
              }
              onUpdate={(data) => onUpdatePolicy(data)}
            />
          </AddPdfFormContainer>
        </PdfUpadteFormContainer>
      );
    }
  }

  function closePopup() {
    setActiveFile(null);
    props.PdfCloser();
  }

  return (
    <React.Fragment>
      <DialogTitle>
        <PopupNavBarContainer>
          <PopupNavBar
            onHandleClose={() => closePopup()}
            componentType="form"
            title={`${getTitle()}`}
          />
        </PopupNavBarContainer>
      </DialogTitle>
      <DialogContent>
        <BigFormContainer>{getComponent()}</BigFormContainer>
      </DialogContent>
    </React.Fragment>
  );
}

// import { DialogContent, DialogTitle } from "@mui/material";
// import React, { useCallback, useState } from "react";
// import { TypoGraphy } from "../../style/common.style";
// import {
//   AddPdfFormContainer,
//   BigFormContainer,
//   DropAndFileZone,
//   FileZoneContainer,
//   MsgContainer,
//   MsgHeading,
//   PopupNavBarContainer,
// } from "../../style/form.style";
// import {
//   ChangeFileButton,
//   ChangeFileButtonContainer,
//   DropInput,
//   DropzoneComponentContainer,
//   DropzoneMainContainer,
//   LoadingBgContainer,
//   PdfDataContainer,
//   PdfPreview,
//   PdfUpadteFormContainer,
// } from "../../style/training.style";

// import { useSnackbar } from "notistack";
// import { useDropzone } from "react-dropzone";
// import { useDispatch, useSelector } from "react-redux";
// import MaterialController from "../../controller/materialController";
// import PolicyController from "../../controller/PolicyController";
// import { setPdfMaterial } from "../../reducer/materialreduxstore";
// import { setPolicy } from "../../reducer/policyreduxstore";
// import { FontSizes } from "../../style/theme/font";
// import CommonColor from "../../utils/common.colors";
// import { CommonImages } from "../../utils/common.images";
// import { MaterialBaseUrl } from "../../utils/constant";
// import MyDropzone from "../../component/commonComponents/dropzoneComponent/dropzone";
// import ShowLoader from "../../component/Loader/ShowLoader";
// import AddPdfData from "../../component/Forms/AddPdfData";
// import PopupNavBar from "../../component/commonComponents/Popups/PopupNavBar";

// export default function AddPolicy(props) {
//   const [activeFile, setActiveFile] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const auth = useSelector((state) => state.auth);
//   const materialController = new MaterialController();
//   const policyController = new PolicyController();
//   const { enqueueSnackbar } = useSnackbar();
//   const dispatch = useDispatch();

//   const onDrop = useCallback((acceptedFiles) => {
//     const file = acceptedFiles[0];
//     fileUpdater(file);
//   }, []);

//   const handleAlert = (msg, variant) => {
//     enqueueSnackbar(msg, { variant });
//   };

//   const getTitle = () => {
//     if (props.isPolicy) {
//       return "policy";
//     }
//     return "training material";
//   };

//   const { getRootProps, getInputProps, open } = useDropzone({
//     // Disable click and keydown behavior
//     noClick: true,
//     noKeyboard: true,
//     accept: ".pdf",
//     maxFiles: 1,
//     onDrop,
//   });

//   function fileUpdater(data) {
//     setActiveFile(data);
//   }

//   async function onPolicySave(data) {
//     if (data.pdfTitle === "") return;
//     setIsLoading(true);
//     const formData = new FormData();
//     formData.append("policy", activeFile);

//     const resFileUpload = await policyController.uploadPolicy(formData);
//     console.log(resFileUpload.url);

//     if (resFileUpload) {
//       const sendData = {
//         name: data.pdfTitle,
//         description: data.pdfDescription,
//         uploaded_url: `${resFileUpload.url}`,
//       };
//       const respAddData = await policyController.addPolicy(sendData);

//       if (respAddData) {
//         //success
//         const sucessMsg = (
//           <MsgContainer>
//             <MsgHeading>success</MsgHeading>
//             <sucessMsg>Policy updated sucessfully</sucessMsg>
//           </MsgContainer>
//         );
//         const policyData = await policyController.getPolicy();
//         if (policyData.length !== 0) {
//           dispatch(setPolicy(policyData.data.data));
//         }
//         setTimeout(() => {
//           handleAlert(sucessMsg, "success");
//           setIsLoading(false);
//           closePopup();
//         }, 2000);
//         return;
//       }
//       //error
//       const errorMsg = (
//         <MsgContainer>
//           <MsgHeading>error</MsgHeading>
//           <sucessMsg>error while updating Policy data</sucessMsg>
//           <sucessMsg>Please try again later</sucessMsg>
//         </MsgContainer>
//       );
//       setTimeout(() => {
//         handleAlert(errorMsg, "error");
//         setIsLoading(false);
//         closePopup();
//       }, 2000);
//       return;
//     }
//     //error
//     const errorMsg = (
//       <MsgContainer>
//         <MsgHeading>error</MsgHeading>
//         <sucessMsg>error while uploading Policy</sucessMsg>
//         <sucessMsg>Please try again later</sucessMsg>
//       </MsgContainer>
//     );
//     setTimeout(() => {
//       handleAlert(errorMsg, "error");
//       setIsLoading(false);
//       closePopup();
//     }, 2000);
//     return;
//   }

//   async function onSave(data) {
//     if (data.pdfTitle === "") return;
//     setIsLoading(true);
//     const formData = new FormData();
//     formData.append("material", activeFile);
//     const resFileUpload = await materialController.uploadMaterial(formData);

//     if (resFileUpload) {
//       const sendData = {
//         type: data.eventType,
//         user_id: auth.employee_data.user._id,
//         department: data.wing,
//         file_url: MaterialBaseUrl + resFileUpload.url,
//         title: data.pdfTitle,
//         added_by: auth.employee_data.user.name,
//       };
//       const respAddData = await materialController.addMaterial(sendData);
//       if (respAddData) {
//         //success
//         const sucessMsg = (
//           <MsgContainer>
//             <MsgHeading>success</MsgHeading>
//             <sucessMsg>training material updated sucessfully</sucessMsg>
//           </MsgContainer>
//         );
//         const trainingData = await materialController.getEmployeePdf();
//         if (trainingData.length !== 0) {
//           dispatch(setPdfMaterial(trainingData.data));
//         }
//         setTimeout(() => {
//           handleAlert(sucessMsg, "success");
//           setIsLoading(false);
//           closePopup();
//         }, 2000);
//         return;
//       }
//       //error
//       const errorMsg = (
//         <MsgContainer>
//           <MsgHeading>error</MsgHeading>
//           <sucessMsg>error while updating training data</sucessMsg>
//           <sucessMsg>Please try again later</sucessMsg>
//         </MsgContainer>
//       );
//       setTimeout(() => {
//         handleAlert(errorMsg, "error");
//         setIsLoading(false);
//         closePopup();
//       }, 2000);
//       return;
//     }
//     //error
//     const errorMsg = (
//       <MsgContainer>
//         <MsgHeading>error</MsgHeading>
//         <sucessMsg>error while uploading training material</sucessMsg>
//         <sucessMsg>Please try again later</sucessMsg>
//       </MsgContainer>
//     );
//     setTimeout(() => {
//       handleAlert(errorMsg, "error");
//       setIsLoading(false);
//       closePopup();
//     }, 2000);
//     return;
//   }

//   function getComponent() {
//     if (activeFile === null) {
//       return (
//         <FileZoneContainer>
//           <DropAndFileZone>
//             <DropzoneComponentContainer>
//               <MyDropzone fileUpdater={fileUpdater} files={activeFile} />
//             </DropzoneComponentContainer>
//           </DropAndFileZone>
//         </FileZoneContainer>
//       );
//     } else {
//       return (
//         <PdfUpadteFormContainer>
//           {isLoading ? (
//             <LoadingBgContainer>
//               {" "}
//               <ShowLoader
//                 loaderData={CommonImages.circularLoader}
//                 height="100px"
//                 width="164px"
//               />
//               <TypoGraphy
//                 fontSize={FontSizes.Mulish}
//                 fontWeight="500"
//                 color={CommonColor.primary_color}
//               >
//                 Uploading Pdf...
//               </TypoGraphy>
//             </LoadingBgContainer>
//           ) : (
//             ""
//           )}

//           <PdfDataContainer>
//             <ChangeFileButtonContainer>
//               <DropzoneMainContainer {...getRootProps()}>
//                 <DropInput {...getInputProps()} />
//                 <ChangeFileButton variant="contained" onClick={open}>
//                   Change File
//                 </ChangeFileButton>
//               </DropzoneMainContainer>
//             </ChangeFileButtonContainer>
//             <PdfPreview src={URL.createObjectURL(activeFile)} />
//           </PdfDataContainer>
//           <AddPdfFormContainer>
//             <AddPdfData
//               isPolicy={props.isPolicy}
//               onSave={(data) =>
//                 props.isPolicy ? onPolicySave(data) : onSave(data)
//               }
//             />
//           </AddPdfFormContainer>
//         </PdfUpadteFormContainer>
//         // <FileZone>
//         //   <PdfViewerContainer>

//         //     {/* <DropzoneMainContainer {...getRootProps()}>
//         //       <DropInput {...getInputProps()} />
//         //       <ChangeFileContainer onClick={open}>
//         //         change file
//         //       </ChangeFileContainer>
//         //     </DropzoneMainContainer> */}
//         //   </PdfViewerContainer>

//         // </FileZone>
//       );
//     }
//   }

//   function closePopup() {
//     setActiveFile(null);
//     props.PdfCloser();
//   }

//   return (
//     <React.Fragment>
//       <DialogTitle>
//         <PopupNavBarContainer>
//           <PopupNavBar
//             onHandleClose={() => closePopup()}
//             componentType="form"
//             title={`add ${getTitle()}`}
//           />
//         </PopupNavBarContainer>
//       </DialogTitle>
//       <DialogContent>
//         <BigFormContainer>{getComponent()}</BigFormContainer>
//       </DialogContent>
//     </React.Fragment>
//   );
// }
