import { Icon } from "@iconify/react";
import { Tooltip } from "@mui/material";
import React from "react";
import { FlexContainer } from "../../../../style/asset.style";
import {
  EmployeeEnterTimeData,
  EmployeeEnterTimeTitle,
  IconContainer,
  LateDetailContainer,
  LateText,
} from "../../../../style/dashboard.style";
import CommonColor from "../../../../utils/common.colors";
import { GoogleMapBaseUrl } from "../../../../utils/constant";
import { formatLoginTime } from "../../../../utils/utils";
import LeaveStatusAbsentPrexelite from "./LeaveStatusAbsentPrexelite";

export default function EmployeeLeaveStatusDetails(props) {
  const userName = props.data.recruiter?.name.split(" ")[0];

  function getLateText() {
    if (props.data === null && props.data.leave === null) return "Absent";
    if (props.status === "not present") {
      return <LeaveStatusAbsentPrexelite data={props.data} />;
    }
    if (props.data.is_late === "1") {
      return userName + " arrived late today";
    }
    return userName + " arrived on time today";
  }
  function locateUser() {
    if (props.data.attendance === null) return;
    const userLocationLink =
      GoogleMapBaseUrl + props.data.lat + "," + props.data.lon;
    window.open(userLocationLink);
  }
  return (
    <React.Fragment>
      <FlexContainer flexDirection="column">
        <FlexContainer flexDirection="row" jc="flex-start">
          {props.status !== "not present" ? (
            <FlexContainer jc="space-between">
              {" "}
              <FlexContainer>
                <EmployeeEnterTimeTitle>
                  Entry Time&nbsp;:&nbsp;
                </EmployeeEnterTimeTitle>
                <EmployeeEnterTimeData>
                  {formatLoginTime(props.data.in_time?.replace(" ", "T") + "Z")}
                </EmployeeEnterTimeData>
              </FlexContainer>
              <Tooltip title="view login location" placement="top">
                <IconContainer
                  onClick={() => {
                    locateUser();
                  }}
                  hideDiv={props.dat === null}
                  ml="5%"
                >
                  <Icon
                    icon="material-symbols:location-on"
                    color={CommonColor.secondary_color}
                    // fontSize={FontSizes.xxxl}
                  />
                </IconContainer>
              </Tooltip>
            </FlexContainer>
          ) : (
            ""
          )}
        </FlexContainer>
        <LateDetailContainer
          isOnTime={props.data?.is_late === "0"}
          isabsent={props.data === null}
        >
          <LateText>{getLateText()}</LateText>
        </LateDetailContainer>
      </FlexContainer>
    </React.Fragment>
  );
}
