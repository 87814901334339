import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import ImageAvatar from "../../../componentsv2/Images/ImageAvatar";
import { CommonImages } from "../../../utils/common.images";
import { ProfileBaseUrl } from "../../../utils/constant";
import { InputBox } from "../../../style/common.style";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TailwindDropdownMembers(props) {
  function getProfile(person) {
    if (!person || person === null || !person.name) return CommonImages.NoUser;
    return ProfileBaseUrl + person.profile_image_url;
  }
  function getName(person) {
    if (!person || person === null || !person.name) return props.placeholder;
    return person.name;
  }
  return (
    <Listbox value={props.value} onChange={props.handleChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {props.title}
          </Listbox.Label>
          <div
            className="relative mb-3"
            style={{
              marginBottom: props.marginBottomC ?? "0.75rem",
              marginTop: props.marginTopC ?? "0rem",
            }}
          >
            <InputBox
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-inset focus:outline-none sm:text-sm sm:leading-6"
              style={{
                paddingTop: props.plb ?? "0.375rem",
                paddingBottom: props.prb ?? "0.375rem",
              }}
            >
              <span className="flex items-center">
                <img
                  src={getProfile(props.value)}
                  alt=""
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                  onError={(event) => {
                    event.currentTarget.onerror = null;
                    event.currentTarget.src = CommonImages.NoUser;
                  }}
                />
                <span className="ml-3 block truncate">
                  {getName(props.value)}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </InputBox>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {props.dataList.map((person) => (
                  <Listbox.Option
                    key={person._id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "bg-theme-color-primary text-white"
                          : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ active }) => (
                      <>
                        <div className="flex items-center">
                          <div className="w-[20px] h-[20px]">
                            <ImageAvatar
                              src={getProfile(person)}
                              alt=""
                              className="h-5 w-5 flex-shrink-0 rounded-full"
                              defaultSrc={CommonImages.NoUser}
                            />
                          </div>
                          <span
                            className={classNames(
                              props.value?.id === person.id
                                ? "font-semibold"
                                : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {person.name}
                          </span>
                        </div>

                        {props.value?.id === person.id ? (
                          <span
                            className={classNames(
                              active
                                ? "text-white"
                                : "text-theme-color-primary",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
