import ApiUtils from "../APICallServices/APIUtils";

export default class LeaveProvider {
  constructor() {
    this.leaveApiUtils = new ApiUtils();
  }
  getEmployeeLeaveData = (id) => {
    return this.leaveApiUtils.init().post("leave/get-leave-by-id", id);
  };
  allEmployeeLeaveData = () => {
    return this.leaveApiUtils.init().get("leave/get-leave");
  };
  getLeaveData = (data) => {
    return this.leaveApiUtils.init().post("attendance/get-leave-credits", data);
  };
  addLeave = (data) => {
    return this.leaveApiUtils.init().post("leave/add-leave", data);
  };
  updateLeaveStatus = (data) => {
    return this.leaveApiUtils.init().post("leave/update-leave-status", data);
  };
  updateLeave = (data) => {
    return this.leaveApiUtils.init().post("leave/update-leave", data);
  };
  deleteLeave = (data) => {
    return this.leaveApiUtils.init().post("leave/delete-leave", data);
  };
  addLeaveCredit = (data) => {
    return this.leaveApiUtils.init().post("attendance/add-leave-credit", data);
  };
  deleteLeaveCredit = (data) => {
    return this.leaveApiUtils.init().post("attendance/delete-leave-credits", data);
  };
  getLeaveHistory = () => {
    return this.leaveApiUtils.init().get("leave/get-leave-history");
  };
}
