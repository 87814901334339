import { Icon } from "@iconify/react";
import { AppBar, Toolbar } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableVirtuoso } from "react-virtuoso";
import AccessController from "../../../controller/accessController";
import { setEmployeeAccessListAll } from "../../../reducer/employeereduxstore";
import { AppBarStyle } from "../../../style/drawer.style";
import {
  LeaveStatusContainer,
  LeaveStatusMsg,
  LeaveStatusTitle,
} from "../../../style/form.style";
import {
  AccessTable,
  AccessTableBody,
  AccessTableData,
  AccessTableHead,
  AccessTableHeader,
  AccessTableRow,
  AccessWrapper,
  IconAccessAllowedStatus,
  NavigationButton,
} from "../../../style/style.access.mgmt.style";
import CommonColor from "../../../utils/common.colors";
import { level100 } from "../../../utils/constant";
import PageRefreshLoader from "../../Loader/PageRefreshLoader";
import NavBar from "../../drawer/NavBar";
import AccessCheckbox from "../checkbox/AccessCheckbox";
import AccessManagementMobile from "./AccessManagementMobile";
import AccessNavBar from "./AccessNavBar";
import { useWindowSize } from "../hooks/getWindowDimension";
import ButtonCustomV2 from "../../../componentsv2/common_components/Button/Button";

export default function AccessManagementTable(props) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const { enqueueSnackbar } = useSnackbar();

  ////
  const [selectedData, setselectedData] = useState();
  const [training_material, setTraining_material] = useState();
  const [leave_details, setleave_details] = useState();
  const [events, setevents] = useState();
  const [attendance, setattendance] = useState();
  const [basic_details, setbasic_details] = useState();
  const [personalDetails, setpersonalDetails] = useState();
  const [speedyPrexelite, setspeedyPrexelite] = useState();
  const [assets, setassets] = useState();
  const [reports, setreports] = useState();
  const [holiday, setholiday] = useState();
  const [salary_details, setsalary_details] = useState();
  const [users, setusers] = useState();
  const [accessList, setAccessList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [WFH, setWFH] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dashboard = useSelector((state) => state.dashboard);
  const auth = useSelector((state) => state.auth);
  const cachedAccessList = useMemo(() => accessList);
  const accessManagementController = new AccessController();
  const windowWidth = useWindowSize().width;

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error." ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    loadInintialData();
  }, []);

  async function loadInintialData() {
    const accessList = await props.getAccessScreenData();
    if (accessList) {
      setAccessList(accessList);
    }
  }

  async function getData() {
    setIsLoading(true);
    let respData = await accessManagementController.getAccessManagementData();
    dispatch(setEmployeeAccessListAll(respData.data));
    setAccessList(respData.data);
    setIsLoading(false);
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  function getButtons(isEditAccessAllowed) {
    if (isEditAccessAllowed == "1") {
      return <IconAccessAllowedStatus icon="mdi:success" iconType="success" />;
    }
    return (
      <IconAccessAllowedStatus
        icon="iconoir:cancel"
        iconType="cancel"
        opacity={0.4}
        bgColor={CommonColor.divider_gray}
      />
    );
  }
  function getValue(value) {
    if (value == true) {
      return "1";
    }
    return "0";
  }
  function checkIsDataSameAccess(obj) {
    let isdataSame = true;
    if (obj.training_material !== getValue(training_material)) {
      isdataSame = false;
    }
    if (obj.speedy_prexelite !== getValue(speedyPrexelite)) {
      isdataSame = false;
    }
    if (obj.assets !== getValue(assets)) {
      isdataSame = false;
    }
    if (obj.wfh !== getValue(WFH)) {
      isdataSame = false;
    }
    if (obj.users !== getValue(users)) {
      isdataSame = false;
    }
    if (obj.salary_details !== getValue(salary_details)) {
      isdataSame = false;
    }
    if (obj.holiday !== getValue(holiday)) {
      isdataSame = false;
    }
    if (obj.reports !== getValue(reports)) {
      isdataSame = false;
    }
    if (obj.personal_details !== getValue(personalDetails)) {
      isdataSame = false;
    }
    if (obj.basic_details !== getValue(basic_details)) {
      isdataSame = false;
    }
    if (obj.attendance !== getValue(attendance)) {
      isdataSame = false;
    }
    if (obj.events !== getValue(events)) {
      isdataSame = false;
    }
    if (obj.leave_details !== getValue(leave_details)) {
      isdataSame = false;
    }
    if (isdataSame) {
      return true;
    }
    return false;
  }
  async function saveDetails() {
    props.IsEditAccessSetter(-1);

    if (checkIsDataSameAccess(selectedData)) {
      return;
    }

    const sendData = {
      _id: selectedData._id,
      user_id: selectedData.user_id,
      training_material: getValue(training_material),
      leave_details: getValue(leave_details),
      events: getValue(events),
      attendance: getValue(attendance),
      basic_details: getValue(basic_details),
      personal_details: getValue(personalDetails),
      speedy_prexelite: getValue(speedyPrexelite),
      assets: getValue(assets),
      reports: getValue(reports),
      holiday: getValue(holiday),
      salary_details: getValue(salary_details),
      wfh: getValue(WFH),
      users: getValue(users),
    };

    let respData = await accessManagementController.updateAccessManagementData(
      sendData
    );

    getData();

    const sucessMsg = (
      <LeaveStatusContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>manage permission given sucessfully</LeaveStatusMsg>
      </LeaveStatusContainer>
    );
    handleAlert(sucessMsg, "success");
  }

  function onEditClick(index) {
    const selectedData = accessList[index];
    setselectedData(selectedData);
    setTraining_material(
      selectedData.training_material == "1" ||
        selectedData.training_material == "true"
    );
    setspeedyPrexelite(
      selectedData.speedy_prexelite == "1" ||
        selectedData.speedy_prexelite == "true"
    );
    setleave_details(
      selectedData.leave_details == "1" || selectedData.leave_details == "true"
    );
    setevents(selectedData.events == "1" || selectedData.events == "true");
    setattendance(
      selectedData.attendance == "1" || selectedData.attendance == "true"
    );
    setbasic_details(
      selectedData.basic_details == "1" || selectedData.basic_details == "true"
    );
    setpersonalDetails(
      selectedData.personal_details == "1" ||
        selectedData.personal_details == "true"
    );
    setassets(selectedData.assets == "1" || selectedData.assets == "true");
    setholiday(selectedData.holiday == "1" || selectedData.assets == "true");
    setreports(selectedData.reports == "1" || selectedData.assets == "true");
    setsalary_details(
      selectedData.salary_details == "1" ||
        selectedData.salary_details == "true"
    );
    setusers(selectedData.users == "1" || selectedData.users == "true");
    setWFH(selectedData.wfh == "1" || selectedData.wfh == "true");
    props.IsEditAccessSetter(index);
  }

  function accessListSetter(value) {
    if (value === "") {
      setAccessList(employee.employee_access_list_all);
      return;
    }
    setAccessList(value);
  }
  function searchValueSetter(value) {
    setSearchValue(value);
  }
  return (
    <React.Fragment>
      <AppBar
        position="static"
        open={props.open}
        elevation={5}
        sx={AppBarStyle(dashboard.isModalOpen, props.open)}
      >
        <Toolbar>
          <NavBar
            DrawerOpen={props.open}
            LoadingFlag={props.LoadingFlag}
            NavBarTitle={"Access Management"}
            NavBarIcon={
              auth.employee_data.user === level100
                ? {
                    icon: "",
                    name: "",
                  }
                : ""
            }
          />
        </Toolbar>
      </AppBar>
      <AccessWrapper>
        <AccessNavBar
          accessListSetter={accessListSetter}
          searchValue={searchValue}
          searchValueSetter={searchValueSetter}
        />
        <AccessTable isvisible={windowWidth < 912 ? 1 : 0}>
          <AccessTableBody height="100%">
            {isLoading ? (
              <PageRefreshLoader />
            ) : (
              <TableVirtuoso
                style={{ height: "100%" }}
                data={cachedAccessList}
                totalCount={accessList.length}
                height={"100%"}
                width={"100%"}
                rowHeight={40}
                fixedItemHeight={40}
                fixedHeaderContent={() => (
                  <AccessTableHeader>
                    <AccessTableHead width="20%" horizontalAlign="left">
                      Name
                    </AccessTableHead>
                    <AccessTableHead>Attendance</AccessTableHead>
                    <AccessTableHead>Leave</AccessTableHead>
                    <AccessTableHead>Events</AccessTableHead>

                    <AccessTableHead>Salary</AccessTableHead>
                    <AccessTableHead width="20%">Basic Details</AccessTableHead>
                    <AccessTableHead width="20%">
                      Personal Detail
                    </AccessTableHead>
                    <AccessTableHead width="20%">
                      Training Material
                    </AccessTableHead>
                    <AccessTableHead>Users</AccessTableHead>
                    <AccessTableHead>Reports</AccessTableHead>
                    <AccessTableHead>Assets</AccessTableHead>
                    <AccessTableHead width="20%">Holiday List</AccessTableHead>
                    <AccessTableHead width="20%">
                      Speedy Prexelites
                    </AccessTableHead>
                    <AccessTableHead width="20%">WFH</AccessTableHead>
                    <AccessTableHead width="20%">Edit</AccessTableHead>
                  </AccessTableHeader>
                )}
                itemContent={(index, employee) => (
                  <React.Fragment>
                    <AccessTableRow
                      key={index}
                      index={index}
                      length={12}
                      isView={
                        props.isEditAccess !== -1 &&
                        props.isEditAccess !== index
                      }
                    >
                      <AccessTableData width="20%" horizontalAlign="flex-start">
                        {employee.user_name}
                      </AccessTableData>
                      <AccessTableData>
                        {props.isEditAccess !== index ? (
                          getButtons(employee.attendance)
                        ) : (
                          <AccessCheckbox
                            checked={attendance}
                            onChange={(e) => setattendance(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData>
                        {props.isEditAccess !== index ? (
                          getButtons(employee.leave_details)
                        ) : (
                          <AccessCheckbox
                            checked={leave_details}
                            onChange={(e) => setleave_details(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData>
                        {props.isEditAccess !== index ? (
                          getButtons(employee.events)
                        ) : (
                          <AccessCheckbox
                            checked={events}
                            onChange={(e) => setevents(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData>
                        {props.isEditAccess !== index ? (
                          getButtons(employee.salary_details)
                        ) : (
                          <AccessCheckbox
                            checked={salary_details}
                            onChange={(e) =>
                              setsalary_details(e.target.checked)
                            }
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData width="20%">
                        {props.isEditAccess !== index ? (
                          getButtons(employee.basic_details)
                        ) : (
                          <AccessCheckbox
                            checked={basic_details}
                            onChange={(e) => setbasic_details(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData width="20%">
                        {props.isEditAccess !== index ? (
                          getButtons(employee.personal_details)
                        ) : (
                          <AccessCheckbox
                            checked={personalDetails}
                            onChange={(e) =>
                              setpersonalDetails(e.target.checked)
                            }
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData width="20%">
                        {props.isEditAccess !== index ? (
                          getButtons(employee.training_material)
                        ) : (
                          <AccessCheckbox
                            checked={training_material}
                            onChange={(e) =>
                              setTraining_material(e.target.checked)
                            }
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData>
                        {props.isEditAccess !== index ? (
                          getButtons(employee.users)
                        ) : (
                          <AccessCheckbox
                            checked={users}
                            onChange={(e) => setusers(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData>
                        {props.isEditAccess !== index ? (
                          getButtons(employee.reports)
                        ) : (
                          <AccessCheckbox
                            checked={reports}
                            onChange={(e) => setreports(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData>
                        {props.isEditAccess !== index ? (
                          getButtons(employee.assets)
                        ) : (
                          <AccessCheckbox
                            checked={assets}
                            onChange={(e) => setassets(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData width="20%">
                        {props.isEditAccess !== index ? (
                          getButtons(employee.holiday)
                        ) : (
                          <AccessCheckbox
                            checked={holiday}
                            onChange={(e) => setholiday(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData width="20%">
                        {props.isEditAccess !== index ? (
                          getButtons(employee.speedy_prexelite)
                        ) : (
                          <AccessCheckbox
                            checked={speedyPrexelite}
                            onChange={(e) =>
                              setspeedyPrexelite(e.target.checked)
                            }
                          />
                        )}
                      </AccessTableData>
                      <AccessTableData width="20%">
                        {props.isEditAccess !== index ? (
                          getButtons(employee.wfh)
                        ) : (
                          <AccessCheckbox
                            checked={WFH}
                            onChange={(e) => setWFH(e.target.checked)}
                          />
                        )}
                      </AccessTableData>
                      {props.isEditAccess === index ? (
                        <AccessTableData width="20%">
                          <ButtonCustomV2
                            variant="outlined"
                            customBGColor={CommonColor.transparent}
                            hoverBG={CommonColor.primary_color}
                            customOutlineColor={CommonColor.transparent}
                            customTextColor={CommonColor.primary_color}
                            textHoverColor={CommonColor.white}
                            onClick={() => saveDetails()}
                            icon={"mdi:tick"}
                            fontsize={"15px"}
                          />
                        </AccessTableData>
                      ) : (
                        <AccessTableData width="20%">
                          <ButtonCustomV2
                            variant="outlined"
                            customBGColor={CommonColor.transparent}
                            hoverBG={CommonColor.primary_color}
                            customOutlineColor={CommonColor.transparent}
                            customTextColor={CommonColor.primary_color}
                            textHoverColor={CommonColor.white}
                            onClick={() => onEditClick(index)}
                            icon={"material-symbols:edit"}
                            fontsize={"15px"}
                          />
                        </AccessTableData>
                      )}
                    </AccessTableRow>
                  </React.Fragment>
                )}
              />
            )}
          </AccessTableBody>
        </AccessTable>
        <AccessManagementMobile />
      </AccessWrapper>
    </React.Fragment>
  );
}
