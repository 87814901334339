import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Switch,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Icon from "react-multi-date-picker/components/icon";
import { useDispatch, useSelector } from "react-redux";
import AssetController from "../../controller/assetController";
import {
  AssetFormContainer,
  CalendarTitle,
  DateCalendarContainer,
  FormContainer,
  InputText,
  LoaderContainer,
  LoaderContainerCircle,
  LoaderText,
  LoaderWrapper,
  MuiDivider,
  VerticalScrollableAreaPopup,
} from "../../style/form.style";
import { AssetsTypes } from "../../utils/mockData";
import CustomSwitch from "../commonComponents/CustomSwitch";
import DropDownList from "../commonComponents/inputs/DropDownList";
import DropDownListEmployee from "../commonComponents/inputs/DropDownListEmployee";
import TextInput from "../commonComponents/inputs/TextInput";
import PopupNavBar from "../commonComponents/Popups/PopupNavBar";
import BottomButtons from "./components/BottomButtons";
import PageRefreshLoader from "../Loader/PageRefreshLoader";
import { useSnackbar } from "notistack";
import { setAssetData } from "../../reducer/assetreduxstore";
import {
  DatePickerCalendar,
  Div,
  RelativePosContainer,
} from "../../style/common.style";
import transition from "react-element-popper/animations/transition";
import ShowLoader from "../Loader/ShowLoader";
import { CommonImages } from "../../utils/common.images";
import { forEach } from "lodash";
import Input from "../../componentsv2/common_components/Inputs/input";

export default function AddAssets(props) {
  const employee = useSelector((state) => state.employee);
  const [employeeList, setEmployeeList] = useState([]);
  const [assetTypes, setAssetTypes] = useState(false);
  const [name, setname] = useState("");
  const [description, setDescription] = useState("");
  const [modelName, setModelName] = useState("");
  const [brandName, setBrandName] = useState();
  const [assignedEmployee, setAssignedEmployee] = useState("");
  const [lasAssignedEmployee, setLastAssignedEmployee] = useState("");
  const [isWorking, setisWorking] = useState(false);
  const [isExchange, setisExchange] = useState(false);
  const [isReplaceable, setisReplaceable] = useState(false);
  const [isRepairable, setisRepairable] = useState(false);
  const [purchaseDate, setpurchaseDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [errorExist, setErrorExist] = useState(false);
  const [tempId, setTempId] = useState("");
  const assetController = new AssetController();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const NoIsuueEmpData = { _id: 123, name: "Not Issued" };
    const newEmployeeList = [NoIsuueEmpData, ...employee.employee_list];
    setEmployeeList(newEmployeeList);
  }, [employee.employee_list]);

  useEffect(() => {
    const NoIsuueEmpData = { _id: 123, name: "Not Issued" };
    const newEmployeeList = [NoIsuueEmpData, ...employee.employee_list];
    if (props.data === null || newEmployeeList.length === 0) return;
    setData(newEmployeeList, NoIsuueEmpData);
  }, [props.data]);

  function setData(newEmployeeList, NoIsuueEmpData) {
    setAssetTypes(props.data.asset.type);
    setname(props.data.asset.name);
    setDescription(props.data.asset.description);
    setModelName(props.data.asset.model);
    setBrandName(props.data.asset.brand);
    setAssignedEmployee(
      getUserIdFromApiData(props.data.asset, 0, newEmployeeList, NoIsuueEmpData)
    );
    setLastAssignedEmployee(
      getUserIdFromApiData(props.data.asset, 1, newEmployeeList, NoIsuueEmpData)
    );
    setisWorking(props.data.asset.is_working === "1");
    setisExchange(props.data.asset.is_exchanged === "1");
    setisReplaceable(props.data.asset.is_replaceable === "1");
    setisRepairable(props.data.asset.is_repairable === "1");
    setpurchaseDate(props.data.asset.purchase_date);
    setTempId(props.data.asset.tempId);
  }
  function isErrorExist() {
    if (lasAssignedEmployee === "") {
      setErrorExist("lastAssignEmp");
      return true;
    }
    if (assignedEmployee === "") {
      setErrorExist("assignEmp");
      return true;
    }
    if (modelName === "") {
      setErrorExist("model");
      return true;
    }
    if (!brandName) {
      setErrorExist("brand");
      return true;
    }
    return false;
  }
  function getUserIdFromRawData(assignedEmployee) {
    return assignedEmployee._id !== 123 ? assignedEmployee._id : "";
  }
  function getUserIdFromApiData(asset, type, newEmployeeList, NoIsuueEmpData) {
    if (type === 0 && asset.assigned_id === "") {
      return NoIsuueEmpData;
    }
    if (type === 1 && asset.last_assigned_id === "") {
      return NoIsuueEmpData;
    }

    let tempEmpData;
    if (type === 0) {
      tempEmpData = newEmployeeList.filter(
        (employeeDetail) => employeeDetail._id === asset.assigned_id
      )[0];
    } else {
      tempEmpData = newEmployeeList.filter(
        (employeeDetail) => employeeDetail._id === asset.last_assigned_id
      )[0];
    }
    return tempEmpData;
  }

  async function onSave() {
    if (isErrorExist()) {
      return;
    }
    setErrorExist(false);
    setIsLoading(true);
    const lastAssignedEmployeeId = getUserIdFromRawData(lasAssignedEmployee);
    const assignedEmployeeId = getUserIdFromRawData(assignedEmployee);
    let sendData = {
      assigned_id: assignedEmployeeId,
      name: name,
      type: assetTypes,
      description: description,
      last_assigned_id: lastAssignedEmployeeId,
      tmp_id: tempId,
      model: modelName,
      brand: brandName,
      is_exchanged: isExchange,
      is_working: isWorking,
      is_replaceable: isReplaceable,
      is_repairable: isRepairable,
      purchase_date: purchaseDate,
    };
    const res = await assetController.addAsset(sendData);
    if (res.success) {
      const sucessMsg = (
        <div>
          <div>Success</div>
          <div>asset added sucessfully</div>
        </div>
      );
      const assetData = await assetController.getAsset();
      dispatch(setAssetData(assetData));
      setTimeout(() => {
        handleAlert(sucessMsg, "success");
        props.onHandleClose();
      }, 500);
    } else {
      const erroMsg = (
        <div>
          <div>Error</div>
          <div>try again after some time</div>
        </div>
      );
      setTimeout(() => {
        handleAlert(erroMsg, "error");
        setIsLoading(false);
        props.onHandleClose();
      }, 500);
    }
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  function getDataList() {
    const tempDataList = [];
    forEach(Array(200), (value, i) => {
      let l;
      if (i < 10) l = "PXL/TMP/00" + i;
      if (i < 100 && i >= 10) l = "PXL/TMP/0" + i;
      if (i >= 100) l = "PXL/TMP/" + i;
      tempDataList.push(l);
    });
    return tempDataList;
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <LoaderWrapper>
          <LoaderContainerCircle>
            <ShowLoader
              loaderData={CommonImages.circularLoader}
              height="100px"
              width="164px"
            />
            <LoaderText>Adding your device</LoaderText>
          </LoaderContainerCircle>
        </LoaderWrapper>
      ) : (
        <React.Fragment>
          <DialogTitle>
            <PopupNavBar
              onHandleClose={props.onHandleClose}
              componentType="form"
              title={props.data === null ? "add asset" : "update asset"}
            />
          </DialogTitle>
          <DialogContent dividers={true}>
            <FormContainer>
              <AssetFormContainer>
                <DropDownList
                  error={0 === 3}
                  dataList={AssetsTypes}
                  title={"Asset Type"}
                  value={assetTypes}
                  handleChange={(event) => setAssetTypes(event.target.value)}
                  renderInput={(params) => <TextInput {...params} label="" />}
                />
                <Input
                  error={1 === 0}
                  title={"Asset Name"}
                  placeholder={"Name"}
                  icon={<Icon icon="ooui:user-avatar-outline" />}
                  value={name}
                  onChange={(event) => setname(event.target.value)}
                />
                <Input
                  error={1 === 0}
                  title={"Asset Description"}
                  placeholder={"Description"}
                  icon={<Icon icon="ooui:user-avatar-outline" />}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
                <Input
                  error={errorExist === "model"}
                  title={"Model"}
                  placeholder={"Model Name"}
                  icon={<Icon icon="ooui:user-avatar-outline" />}
                  value={modelName}
                  onChange={(event) => setModelName(event.target.value)}
                />
                <Input
                  error={errorExist === "brand"}
                  title={"Brand"}
                  placeholder={"Brand Name"}
                  icon={<Icon icon="ooui:user-avatar-outline" />}
                  value={brandName}
                  onChange={(event) => setBrandName(event.target.value)}
                />

                <DropDownListEmployee
                  error={errorExist === "assignEmp"}
                  dataList={employeeList}
                  title={"Assign User"}
                  value={assignedEmployee}
                  handleChange={(event) => {
                    setAssignedEmployee(event);
                  }}
                  textTransform={"capitalize"}
                />
                <DropDownListEmployee
                  error={errorExist === "lastAssignEmp"}
                  dataList={employeeList}
                  title={"Last Assign User"}
                  value={lasAssignedEmployee}
                  handleChange={(event) => {
                    setLastAssignedEmployee(event);
                  }}
                  textTransform={"capitalize"}
                />
                <DropDownList
                  error={0 === 3}
                  dataList={getDataList()}
                  title={"Temprory Id"}
                  value={tempId}
                  handleChange={(event) => setTempId(event.target.value)}
                  renderInput={(params) => <TextInput {...params} label="" />}
                />
                <CustomSwitch
                  title="is Working?"
                  jc={"space-between"}
                  onChange={(e) => setisWorking(e.target.checked)}
                  value={isWorking}
                />
                <Divider />
                <CustomSwitch
                  jc={"space-between"}
                  title="is Exchanged?"
                  onChange={(e) => setisExchange(e.target.checked)}
                  value={isExchange}
                />
                <Divider />

                <CustomSwitch
                  title="is Replaceable?"
                  jc={"space-between"}
                  onChange={(e) => setisReplaceable(e.target.checked)}
                  value={isReplaceable}
                />
                <Divider />

                <CustomSwitch
                  title="is Repairable?"
                  jc={"space-between"}
                  onChange={(e) => setisRepairable(e.target.checked)}
                  value={isRepairable}
                />

                <DateCalendarContainer isvisible={props.isMultiForm ? 1 : 0}>
                  <CalendarTitle>Purchase Date</CalendarTitle>
                  <DatePickerCalendar
                    value={purchaseDate}
                    onChange={(newValue) => {
                      setpurchaseDate(newValue);
                    }}
                    maxDate={Date.now()}
                    className="custom-calendar"
                    // calendarPosition="right-center"
                    animations={[transition()]}
                    // ref={calendarRef}
                    showOtherDays
                  ></DatePickerCalendar>
                </DateCalendarContainer>
              </AssetFormContainer>
            </FormContainer>
          </DialogContent>
          <DialogActions>
            <Div>
              <BottomButtons
                onSave={() => onSave()}
                buttonName={props.data === null ? "Add Asset" : "Apdate Asset"}
              />
            </Div>
          </DialogActions>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
