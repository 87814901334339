import React, { useState } from "react";
import { useSelector } from "react-redux";
import LeaveHistoryCard from "../../../screen/dashboard/LeaveHistory/LeaveHistoryCard";

import {
  BaseCardDashboardContainerSecond,
  LeaveHistroyListContainer,
} from "../../../style/dashboard.style";
import LeaveHistoryCardShimmer from "../../Loader/shimmers/LeaveHistoryCardShimmer";
import ModalPopup from "../../Popup/ModalPopup";

import CardTitle from "./CardTitle";
import LeaveHistoryAllData from "./LeaveHistoryAllData";

export default function LeaveHistoryBlock(props) {
  const leave = useSelector((state) => state.leave);
  const [openPopup, setOpenPopup] = useState(false);
  const [openContent, setOpenContent] = useState(false);

  function getEventScreenIndex() {
    // if (employee.employee_acess.leave_details) {
    //   dispatch(setActiveDrawerkey(CommonString.leaveInfoScreenKey));
    // } else {
    //   dispatch(setActiveDrawerkey(CommonString.leaveApplicationScreenKey));
    // }
    setOpenPopup(true);
  }
  function getModalChildren() {
    if (openPopup) {
      return (
        <LeaveHistoryAllData
          onHandleClose={() => setOpenPopup(false)}
          LoadingFlag={props.LoadingFlag}
        />
      );
    }
  }

  function toggleContent() {
    setOpenContent(!openContent);
  }

  return (
    <React.Fragment>
      <ModalPopup open={openPopup} popupSize="sm">
        {getModalChildren()}
      </ModalPopup>
      <BaseCardDashboardContainerSecond
        isvisible={props.isVisible ? 1 : 0}
        delay={props.serialOrder}
      >
        <CardTitle
          title={"Leave History"}
          buttonChoice="2"
          activeIndexUpdater={() => getEventScreenIndex()}
          cardLocation={() => getEventScreenIndex()}
          contentToggler={() => toggleContent()}
          openContent={openContent}
          viewMoreText="view more"
        />
        <LeaveHistroyListContainer isvisible={openContent ? 1 : 0}>
          {props.LoadingFlag
            ? Array.from({ length: 5 }).map((ele, index) => {
                return <LeaveHistoryCardShimmer key={index} />;
              })
            : leave.leave_history_data.slice(0, 9).map((element, index) => {
                return (
                  <React.Fragment key={index}>
                    <LeaveHistoryCard
                      data={element}
                      serialOrder={index}
                      size={10}
                    />
                  </React.Fragment>
                );
              })}
        </LeaveHistroyListContainer>
      </BaseCardDashboardContainerSecond>
    </React.Fragment>
  );
}
