import styled from "styled-components";
import CommonColor from "../utils/common.colors";

const CardContainer = styled.div`
  width: 100%;
  height: calc(100% - 65px);
  background-color: ${CommonColor.theme_background_color};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const OfficeTimingCard = styled.div`
  position: relative;
  height: 200px;
  width: 200px;
  background-color: ${CommonColor.white};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.1);
  margin: 1%;
  & .edit {
    display: ${(props) => (props.iseditvisible ? "" : "none")};
    transition: opacity 0.2s ease-out;
    opacity: 0;
    cursor: pointer;
  }
  &:hover {
    & .edit {
      opacity: 1;
    }
  }
`;
const ShiftContainer = styled.div`
  margin-top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TimePickerContainer = styled.div`
  && {
    margin-bottom: 2%;
    margin-top: 2%;
    width: 100%;
    & .MuiFormControl-root {
      width: 100%;
    }
    & input {
      padding: 7.5px 14px;
      border-radius: 15px;
    }
    & fieldset {
      border-radius: 7px;
      border-color: ${CommonColor.divider_gray};
      border-width: 1px;
    }
    & .Mui-focused fieldset {
      border-width: 2px;
      border-color: ${CommonColor.primary_color} !important;
    }
    & svg {
      font-size: 1em;
    }
  }
`;
export { CardContainer, OfficeTimingCard, ShiftContainer, TimePickerContainer };
