import React from "react";
import { TailwindStyledInput, TailwindStyledTextArea } from "../../../style/common.style";

export default function Textarea(props) {
  const inputClassNames = ` block w-full rounded-md border-0 p-1 rows="6" ${
    props.startIcon ? "pl-7" : "pl-3"
  } ${
    props.endIcon ? "pr-20" : "pr-3"
  } text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6`;
  return (
    <div>
      <label
        for="price"
        className="block text-sm font-medium leading-6 mt-2 text-gray-900"
      >
        {props.title}
      </label>
      <div className="relative  rounded-md shadow-sm">
        {props.startIcon ? (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
            <span className="text-gray-500 sm:text-sm">{props.startIcon}</span>
          </div>
        ) : (
          ""
        )}
        <TailwindStyledTextArea
          rows="4"
          type={props.type}
          className={inputClassNames}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(event) => props.handleChange(event)}
        />
        {props.endIcon ? (
          <div className="absolute inset-y-0 right-2 flex items-center">
            {props.endIcon}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
