import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import styled from "styled-components";
import CommonColor from "../utils/common.colors";
import { DeviceSizes } from "./common.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";

const UserImage = styled.img`
  width: 10%;
  height: 10%;
  border-radius: 10px;
  margin-left: 45%;
  margin-top: 2%;
`;
const UserName = styled.div`
  font-family: "Mulish";
  font-display: block;
  font-weight: 700;
  font-size: ${FontSizes.Title};
  color: ${Theme.primary_color.primary_color};
  text-align: center;
`;
const LogoutConfirmationContainer = styled.div`
  padding: 3%;
  position: relative;
`;
const LogoutTitle = styled.div`
  font-family: "Mulish";
  font-weight: 600;
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
`;
const LogoutMessage = styled(LogoutTitle)`
  font-size: ${FontSizes.Subtitle};
  font-weight: 400;

  text-transform: none;
`;
const LogoutConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 4%;
  margin-top: 3%;
  && button {
    margin-right: 0;
    margin-left: 1%;
  }
`;
const LoginConfirmationButton = styled(Button)`
  && {
    font-family: "Mulish";
    font-display: block;
    font-weight: 400;
    font-size: ${FontSizes.Subtitle};
    background-color: ${(props) =>
      props.variant === "contained"
        ? props.backgroundcolor ?? Theme.primary_color.primary_color
        : Theme.primary_color.transparent};
    color: ${(props) =>
      props.variant === "contained"
        ? props.color ?? Theme.primary_color.white
        : Theme.primary_color.primary_color};
    margin-right: 2%;
  }
`;
const AssetDetailContainer = styled.div`
  padding-top: 3%;
  padding-bottom: 3%;

  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
const AssetDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${CommonColor.text_gray};
  border-bottom: ${(props) => (props.noborderbelow === 1 ? "none" : "")};
  padding: 1%;
`;
const AssetKey = styled.div`
  font-weight: 600;
  font-size: ${FontSizes.Para};
  font-family: "Mulish";
  color: ${Theme.primary_color.primary_color};
  text-transform: capitalize;
`;
const AssetValue = styled(AssetKey)`
  font-weight: 500;
`;
const AssetNavBarContainer = styled.div`
  margin-left: 1%;
`;
const ErrorContainer = styled.div`
  width: 35vw;
  padding: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ErrorDataContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const ErrorLogo = styled(Icon)`
  margin-right: 2%;
  font-size: 20px;
  color: ${CommonColor.red};
`;
const ErrorText = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: ${FontSizes.SubPara};
  color: ${CommonColor.red};
`;
const CancelButton = styled(Icon)`
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 1.4em;
  cursor: pointer;
`;
const DialogProfileContainer = styled.div`
  height: 91vh;
  width: 100%;
`;
export {
  AssetDataContainer,
  AssetDetailContainer, AssetKey, AssetNavBarContainer,
  AssetValue, CancelButton, DialogProfileContainer, ErrorContainer, ErrorDataContainer, ErrorLogo, ErrorText, LoginConfirmationButton, LogoutConfirmationButtonsContainer, LogoutConfirmationContainer, LogoutMessage, LogoutTitle, UserImage,
  UserName
};

