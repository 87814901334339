import { DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import LeaveHistoryCard from "../../../screen/dashboard/LeaveHistory/LeaveHistoryCard";
import { LeaveHistroyListContainer } from "../../../style/dashboard.style";
import { PopupNavBarContainer } from "../../../style/form.style";
import LeaveHistoryCardShimmer from "../../Loader/shimmers/LeaveHistoryCardShimmer";
import PopupNavBar from "../Popups/PopupNavBar";

export default function LeaveHistoryAllData(props) {
  const leave = useSelector((state) => state.leave);
  return (
    <React.Fragment>
      <DialogTitle>
        <PopupNavBarContainer>
          <PopupNavBar
            onHandleClose={props.onHandleClose}
            componentType="form"
            title={"Leave History"}
          />
        </PopupNavBarContainer>
      </DialogTitle>
      <DialogContent dividers={true}>
        <LeaveHistroyListContainer isvisible={1}>
          {props.LoadingFlag
            ? Array.from({ length: 5 }).map((element, index) => {
                return (
                  <React.Fragment key={index}>
                    <LeaveHistoryCardShimmer />
                  </React.Fragment>
                );
              })
            : leave.leave_history_data.map((element, index) => {
                return (
                  <React.Fragment key={index}>
                    <LeaveHistoryCard data={element} />
                  </React.Fragment>
                );
              })}
        </LeaveHistroyListContainer>
      </DialogContent>
    </React.Fragment>
  );
}
