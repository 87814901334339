const FontSizes = {
  miniText: "0.4em",
  VerySmallText: "0.5em",
  SmallText: "0.65em",
  SubPara: "0.75em",
  Para: "0.875em",
  Subtitle: "1em",
  Title: "1.2em",
  BigTitle: "2em",
  LargeText: "2.4em",
  xs: "10px",
  s: "12px",
  m: "14px",
  l: "16px",
  xl: "20px",
  xxl: "24px",
  xxxl: "28px",
  ivl: "32px",
};

export { FontSizes };
