import ApiUtils from "../APICallServices/APIUtils";

export default class MaterialProvider {
  constructor() {
    this.materialUtils = new ApiUtils();
  }
  getTrainingPdfs = () => {
    return this.materialUtils.init().get("material/get-material");
  };

  uploadMaterial = (data) => {
    return this.materialUtils.init().post("upload-material", data);
  };

  addMaterial = (data) => {
    return this.materialUtils.init().post("material/add-material", data);
  };
  deleteMaterial = (data) => {
    return this.materialUtils.init().post("material/delete-material", data);
  };
}
