import React, { useState } from "react";
import {
  ButtonEditLeave,
  DurationAndStatus,
  EmployeeCardDivider,
  EmployeeLeaveDataContainer,
  EmployeeLeaveDataNavbar,
  EmployeeLeaveDataNavbarContainer,
  EmployeeLeaveDuaration,
  EmployeeLeaveFooter,
  EmployeeLeaveHalfDay,
  EmployeeLeaveInfoContainer,
  EmployeeLeaveNote,
  EmployeeLeaveNoteContainer,
  EmployeeLeaveNoteHeading,
  EmployeeLeaveTime,
  EmployeeLeaveTimeAndTypeContainer,
  EmployeeName,
  EmployeeNameContainer,
  EmployeeOptionsIconContainer,
  EmployeeTimeContainer,
  EmployeeTimeContainerTo,
  EmployeetimingHeader,
  IconNavbar,
  ImageContainerLeaveCard,
  MenuOptions,
  ReadMoreOption,
} from "../../style/leave.style";
import LeaveDecisionButton from "../commonComponents/buttons/LeaveDecisionButton";
import LeaveStatus from "../commonComponents/buttons/LeaveStatus";

import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { CommonString } from "../../utils/common.string";
import { formatDisplayDate } from "../../utils/utils";

import moment from "moment";
import { useSnackbar } from "notistack";
import ImageAvatar from "../../componentsv2/Images/ImageAvatar";
import ButtonCustomV2 from "../../componentsv2/common_components/Button/Button";
import { getLocalStorage } from "../../services/LocalStorage/LocalStorage";
import { Div } from "../../style/common.style";
import {
  LeaveStatusMsg,
  LeaveStatusMsgContainer,
  LeaveStatusTitle,
} from "../../style/form.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import { userdata } from "../../utils/common.key";
import { ProfileBaseUrl } from "../../utils/constant";
import ConfirmationMenu from "../Popup/ConfirmationMenu";
import ModalPopup from "../Popup/ModalPopup";

import WorkFromHomeController from "../../controller/workFromHomeController";
import {
  setWorkFromHomeData,
  setWorkFromHomeDetails,
} from "../../reducer/workfromhomereduxstore";
import ApplyWorkFromHome from "../Forms/ApplyWorkFromHome";
import { WFHMenuOptions } from "../../style/workfromhome.style";

export default function WorkFromHomeInfoCard(props) {
  const [IsReadMore, setIsReadMore] = useState(false);
  const [isloading, setLoader] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(
    CommonString.pending.toUpperCase()
  );
  const [isOpen, setisOpen] = useState(false);
  const leaveList = useSelector((state) => state.leave.leave_employees_data);
  const leave = useSelector((state) => state.leave);
  const employee = useSelector((state) => state.employee);
  // const leaveController = new LeaveController();
  const wfhController = new WorkFromHomeController();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const activeEmployeeData = props.data;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dashboard = useSelector((state) => state.dashboard);
  const isEditPopupOpen =
    selectedStatus === CommonString.edit_leave.toUpperCase();
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const ToggleReadmore = (event, index) => {
    event.stopPropagation();
    setIsReadMore(!IsReadMore);
  };
  const isAdmin = employee.employee_acess.attendance;

  function getDate(dateOriginalFormat) {
    if (!dateOriginalFormat) return "";
    if (dateOriginalFormat.length === 10) {
      return formatDisplayDate(dateOriginalFormat, "YYYY-MM-DD").format(
        "Do MMM 'YY"
      );
    }
    return formatDisplayDate(dateOriginalFormat).format("Do MMM 'YY");
  }
  function getReason() {
    const textLen = IsReadMore ? Infinity : 50;
    return props.data.reason.substr(0, textLen);
  }
  function onClickFooterButton(event, status) {
    if (event) {
      event.stopPropagation();
    }
    setselectedStatus(status);
    setisOpen(true);
    handleCloseMenu();
  }
  function getButtons() {
    // if (!ShowButton) return;
    if (!employee.employee_acess.leave_details) return "";
    // if (moment(props.data.leave.from_date).diff(moment()) < 0) return "";
    if (props.data.status === CommonString.pending.toUpperCase()) {
      return (
        <Div className="grid grid-cols-2 gap-x-2 w-full">
          <Div className="grid col-span-1">
            <ButtonCustomV2
              onClick={(event) =>
                onClickFooterButton(event, CommonString.rejected.toUpperCase())
              }
              variant="outlined"
              customBGColor={CommonColor.transparent}
              hoverBG={CommonColor.red + "10"}
              customTextColor={CommonColor.red}
              customOutlineColor={CommonColor.red}
              startIcon={
                <Icon icon="akar-icons:cross" height={FontSizes.SmallText} />
              }
              buttonText="Decline"
            />
          </Div>

          <Div className="grid col-span-1">
            <ButtonCustomV2
              onClick={(event) =>
                onClickFooterButton(event, CommonString.approved.toUpperCase())
              }
              variant="outlined"
              customBGColor={CommonColor.transparent}
              hoverBG={CommonColor.green + "10"}
              customTextColor={CommonColor.green}
              customOutlineColor={CommonColor.green}
              startIcon={
                <Icon icon="mdi:tick" fontSize={FontSizes.SmallText} />
              }
              buttonText="Approve "
            />
          </Div>
        </Div>
      );
    }
    if (moment(props.data.from_date).diff(moment()) >= 0) {
      return (
        <LeaveDecisionButton
          onClick={(event) =>
            onClickFooterButton(event, CommonString.edit.toUpperCase())
          }
        >
          edit status
        </LeaveDecisionButton>
      );
    }
  }

  function onEditLeave(variable, status) {
    props.setSelectedWFH(props.data);
    props.setselectedStatus(CommonString.edit_leave.toUpperCase());
    setisOpen(true);
    setselectedStatus(status);
    handleCloseMenu();
  }
  async function onDeleteLeave() {
    setLoader(true);
    const data = {
      _id: activeEmployeeData._id,
    };

    let res = await wfhController.delectWorkFromHome(data);
    const successMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>success</LeaveStatusTitle>
        <LeaveStatusMsg>WFH deleted sucessfully</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );
    const errorMsg = (
      <LeaveStatusMsgContainer>
        <LeaveStatusTitle>error</LeaveStatusTitle>
        <LeaveStatusMsg>please try after a while</LeaveStatusMsg>
      </LeaveStatusMsgContainer>
    );
    if (res) {
      if (isAdmin) {
       
        const newRes = await wfhController.getWorkFromHomeData();
        if (newRes) {
          dispatch(setWorkFromHomeData(newRes));
      
        const newRespData = await wfhController.getWorkFromHomePersonalDetails({
          user_id: props.data.user_id,
        });

        if (newRespData) {
          
          dispatch(
            setWorkFromHomeDetails({
              message: newRespData.message,
              remaining_wfh_days: newRespData.remaining_wfh_days,
              used_wfh_days: newRespData.used_wfh_days,
              wfh_available_days: newRespData.wfh_available_days,
            })
          );
        }

      }
      } else {
        
        const employeeId = getLocalStorage(userdata).user._id;
        const newRespData = await wfhController.getWorkFromHomePersonalDetails({
          user_id: employeeId,
        });
        
        if (newRespData) {
          dispatch(setWorkFromHomeData(newRespData?.wfh_data));
          dispatch(
            setWorkFromHomeDetails({
              message: newRespData.message,
              remaining_wfh_days: newRespData.remaining_wfh_days,
              used_wfh_days: newRespData.used_wfh_days,
              wfh_available_days: newRespData.wfh_available_days,
            })
          );
        }
      }

      setTimeout(() => {
        setLoader(false);
        setisOpen(false);
        handleAlert(successMsg, "success");
      }, 500);
    } else {
      setTimeout(() => {
        handleAlert(errorMsg, "error");
        setLoader(false);
        setisOpen(false);
      }, 500);
    }
  }

  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  async function onChangeStatus(status) {
    setLoader(true);
    const sendData = {
      _id: props.data._id,
      status: status,
    };

    const res = await wfhController.updateWorkFromHome(sendData);

    if (isAdmin) {
      if (res) {
        let response = await wfhController.getWorkFromHomeData();
        console.log("NEW ADMIN RES" , response);
        if (response) {
          dispatch(setWorkFromHomeData(response));
        }
        const newRespData = await wfhController.getWorkFromHomePersonalDetails({
          user_id: props.data?.user_id,
        });
        if (newRespData) {
          dispatch(
            setWorkFromHomeDetails({
              message: newRespData.message,
              remaining_wfh_days: newRespData.remaining_wfh_days,
              used_wfh_days: newRespData.used_wfh_days,
              wfh_available_days: newRespData.wfh_available_days,
            })
          );
        }
      }
    } else {
      if (res) {
        const employeeId = getLocalStorage(userdata).user._id;
        const newRespData = await wfhController.getWorkFromHomePersonalDetails({
          user_id: employeeId,
        });
        if (newRespData) {
          dispatch(setWorkFromHomeData(newRespData?.wfh_data));
          dispatch(
            setWorkFromHomeDetails({
              message: newRespData.message,
              remaining_wfh_days: newRespData.remaining_wfh_days,
              used_wfh_days: newRespData.used_wfh_days,
              wfh_available_days: newRespData.wfh_available_days,
            })
          );
        }
      }
    }

    setLoader(false);
    setisOpen(false);
  }
  function getLocalChildren() {
    if (selectedStatus === CommonString.approved.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationApproved)}
          yesButtonName="Approve"
          noButtuonName="Cancel"
          onClickYesButton={() =>
            onChangeStatus(CommonString.approved.toUpperCase())
          }
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }

    if (selectedStatus === CommonString.rejected.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationReject)}
          yesButtonName="Reject"
          onClickYesButton={() =>
            onChangeStatus(CommonString.rejected.toUpperCase())
          }
          noButtuonName="Cancel"
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (selectedStatus === CommonString.edit.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationReject)}
          yesButtonName="Approve"
          onClickYesButton={() =>
            onChangeStatus(CommonString.approved.toUpperCase())
          }
          onClicknoButton={() =>
            onChangeStatus(CommonString.rejected.toUpperCase())
          }
          noButtuonName="Reject"
          isCancelButton={true}
          onClickCancelButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }

    if (selectedStatus === CommonString.delete.toUpperCase()) {
      return (
        <ConfirmationMenu
          isShow={isloading}
          title={getTitle(CommonString.confirmationdelete)}
          yesButtonName="Delete"
          onClickYesButton={() => onDeleteLeave()}
          noButtuonName="Cancel"
          onClicknoButton={() => setisOpen(false)}
          color={CommonColor.primary_color}
        />
      );
    }
    if (selectedStatus === CommonString.edit_leave.toUpperCase()) {
      return (
        <ApplyWorkFromHome
          IsPopupOpen={dashboard.isModalOpen}
          isEdit={true}
          onHandleClose={() => {
            setisOpen(false);
            props.onHandleClose();
          }}
          data={props.data}
          selectedWFH={props.selectedWFH}
        />
      );
    }
  }
  function getTitle(title) {
    if (props.data.type !== "LATE") {
      return (
        title +
        " work for home of " +
        props.data.recruiter.name +
        " for " +
        props.data.total_wfh +
        " day(s)?"
      );
    }
    return (
      title + " leave of " + props.data.recruiter.name + " for late coming?"
    );
  }
  function IsDateComing() {
    return props.data.total_wfh > 1;
  }
  function getEmployeeHeader() {
    if (props.data.type !== "LATE") {
      return props.data.total_wfh + " Day(s) work from home";
    }
    return "Late Coming";
  }
  function getProfileImageUrl() {
    if (props.data.recruiter.profile_image_url !== null) {
      return ProfileBaseUrl + props.data.recruiter.profile_image_url;
    }
    return "";
  }
  return (
    <React.Fragment>
      <ModalPopup
        open={isOpen}
        popupSize={isEditPopupOpen ? "sm" : "xs"}
        issmallScreen={true}
      >
        {getLocalChildren()}
      </ModalPopup>
      <React.Fragment>
        <EmployeeLeaveInfoContainer
          isActive={props.isActive}
          onClick={ props.onClickOn ? () => {
            
            const event = props.emplist().filter((datax)=>datax._id === props.data.user_id)
            props.onClick(event[0])
          } : ()=>{}
        }
          noclick={props.noClick}
          length={props.length}
          index={props.index}
          onContextMenu={
            props.data.status === CommonString.pending && props.isEdit
              ? (event) => handleClick(event)
              : () => {}
          }
        >
          <EmployeeLeaveDataNavbarContainer
            // isdisplay={employee.employee_acess.leave_details ? 1 : 0}
            isdisplay={1}
          >
            <EmployeeLeaveDataNavbar>
              <EmployeeNameContainer>
                <ImageContainerLeaveCard>
                  <ImageAvatar
                    src={getProfileImageUrl()}
                    alt=""
                    defaultSrc={CommonImages.NoUser}
                  />
                </ImageContainerLeaveCard>
                <EmployeeName
                  onClick={() => props.onNameClick && props.onNameClick()}
                >
                  {props.data.recruiter.name}
                </EmployeeName>

                {props.data.status !== CommonString.pending && isAdmin && (
                  <>
                    <IconNavbar
                      icon="mi:options-horizontal"
                      onClick={handleClick}
                    />
                    <WFHMenuOptions
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <ButtonEditLeave
                        variant="text"
                        onClick={() =>
                          onClickFooterButton(
                            undefined,
                            CommonString.delete.toUpperCase()
                          )
                        }
                        startIcon={
                          <Icon icon="material-symbols:delete-forever-rounded" />
                        }
                      >
                        Delete
                      </ButtonEditLeave>
                    </WFHMenuOptions>
                  </>
                )}

                {props.data.status === CommonString.pending && (
                  <>
                    <IconNavbar
                      icon="mi:options-horizontal"
                      onClick={handleClick}
                    />
                    <MenuOptions
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <ButtonEditLeave
                        variant="text"
                        onClick={() =>
                          onClickFooterButton(
                            undefined,
                            CommonString.delete.toUpperCase()
                          )
                        }
                        startIcon={
                          <Icon icon="material-symbols:delete-forever-rounded" />
                        }
                      >
                        delete
                      </ButtonEditLeave>
                      <ButtonEditLeave
                        variant="text"
                        onClick={() =>
                          onEditLeave(
                            undefined,
                            CommonString.edit_leave.toUpperCase()
                          )
                        }
                        startIcon={<Icon icon="material-symbols:edit" />}
                      >
                        edit
                      </ButtonEditLeave>
                    </MenuOptions>
                  </>
                )}
              </EmployeeNameContainer>
              <LeaveStatus
                leaveStatus={props.data.status}
                display={employee.employee_acess.leave_details}
                leaveStatusText={props.data.status}
                screen={props.screen}
              />
            </EmployeeLeaveDataNavbar>
          </EmployeeLeaveDataNavbarContainer>
          <EmployeeCardDivider
            isdisplay={employee.employee_acess.leave_details ? 1 : 0}
            mt="1%"
            mb="1%"
          />
          <EmployeeLeaveDataContainer>
            <DurationAndStatus
              display={!employee.employee_acess.leave_details ? 1 : 0}
            >
              <EmployeeLeaveDuaration>
                {getEmployeeHeader()}

                <EmployeeOptionsIconContainer
                  isvisible={
                    props.data.status === CommonString.pending &&
                    props.isEdit &&
                    !employee.employee_acess.leave_details
                      ? 1
                      : 0
                  }
                >
                  {/* <IconNavbar
                    icon="mi:options-horizontal"
                    onClick={handleClick}
                  /> */}
                  {/* <MenuOptions
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <ButtonEditLeave
                      disableRipple
                      variant="outlined"
                      onClick={() =>
                        onClickFooterButton(
                          undefined,
                          CommonString.delete.toUpperCase()
                        )
                      }
                      startIcon={
                        <Icon icon="material-symbols:delete-forever-rounded" />
                      }
                    >
                      delete
                    </ButtonEditLeave>
                    <ButtonEditLeave
                      disableRipple
                      variant="outlined"
                      onClick={() =>
                        onEditLeave(
                          undefined,
                          CommonString.edit_leave.toUpperCase()
                        )
                      }
                      startIcon={<Icon icon="material-symbols:edit" />}
                    >
                      edit
                    </ButtonEditLeave>
                  </MenuOptions> */}
                </EmployeeOptionsIconContainer>
              </EmployeeLeaveDuaration>

              {/* <LeaveStatusContainer
                display={!employee.employee_acess.leave_details ? 1 : 0}
              >
                <LeaveStatus
                  leaveStatus={props.data.status}
                  component={props.type}
                  display={!employee.employee_acess.leave_details}
                  screen={props.screen}
                  leaveComponent={props.leaveComponent}
                />
              </LeaveStatusContainer> */}
            </DurationAndStatus>
            <EmployeeCardDivider
              isdisplay={!employee.employee_acess.leave_details ? 1 : 0}
              mt="1%"
            />

            <EmployeeTimeContainer
              mt={props.leaveComponent === CommonString.leaveApplication}
            >
              <EmployeetimingHeader>
                {IsDateComing() ? "From : " : "date : "}
              </EmployeetimingHeader>

              <EmployeeLeaveTime>
                &nbsp;{getDate(props.data.from_date)}
              </EmployeeLeaveTime>
            </EmployeeTimeContainer>
            <EmployeeTimeContainerTo
              todate={props.data.to_date}
              isvisible={props.data.total_wfh > 1 ? 1 : 0}
            >
              <EmployeetimingHeader>To&nbsp;:&nbsp;</EmployeetimingHeader>
              <EmployeeLeaveTimeAndTypeContainer>
                <EmployeeLeaveTime>
                  {props.data ? getDate(props.data.to_date) : ""}
                </EmployeeLeaveTime>
                {props.data.total_wfh === 0.5 ? (
                  <EmployeeLeaveHalfDay>(Half day)</EmployeeLeaveHalfDay>
                ) : (
                  ""
                )}
              </EmployeeLeaveTimeAndTypeContainer>
            </EmployeeTimeContainerTo>

            {props.data.reason !== "" &&
              props.data.reason &&
              props.data.reason !== null && (
                <EmployeeLeaveNoteContainer>
                  <EmployeeLeaveNoteHeading>
                    Note&nbsp;:&nbsp;
                  </EmployeeLeaveNoteHeading>
                  <EmployeeLeaveNote
                    ActiveIndex={IsReadMore}
                    index={props.index}
                  >
                    {getReason()}
                  </EmployeeLeaveNote>

                  <ReadMoreOption
                    onClick={(event) => ToggleReadmore(event, props.index)}
                    isdisplay={props.data.reason.length > 87  ? 1 : 0}
                  >
                    {IsReadMore ? " read less" : "...read more"}
                  </ReadMoreOption>
                </EmployeeLeaveNoteContainer>
              )}

            <EmployeeLeaveFooter
              leavestatus={props.data ? props.data.status : ""}
              isdisplay={1}
            >
              {getButtons()}
            </EmployeeLeaveFooter>
          </EmployeeLeaveDataContainer>
        </EmployeeLeaveInfoContainer>
      </React.Fragment>
    </React.Fragment>
  );
}
