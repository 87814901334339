const api_token_key = "api_token";
const userdata = "user_data";
const prev_index = "PREV INDEX";
const attendance_login_status = "attendance_login_status";
const employee_list = "employee_list";
const employee_detail_popup = "employee_detail_popup";
const page_popup_opener = "page_popup_opener";
const all_employee_leave = "all_employee_leave";
const logged_in_employee_leave = "logged_in_employee_leave";
const pdf_update = "pdf_update";
const employee_image_list_key = "employee_image_list";

export {
  prev_index,
  api_token_key,
  userdata,
  attendance_login_status,
  employee_list,
  employee_detail_popup,
  page_popup_opener,
  all_employee_leave,
  logged_in_employee_leave,
  pdf_update,
  employee_image_list_key,
};
