import { Button, Chip, Grid } from "@mui/material";
import styled from "styled-components";
import CommonColor from "../utils/common.colors";
import {
  DeviceSizes,
  NoDataContainer,
  opacityChangeAnimation,
} from "./common.style";
import { EmployeeImg, RoundImageSpeedContainer } from "./dashboard.style";
import { Theme } from "./theme/color";
import { FontSizes } from "./theme/font";
import { Icon } from "@iconify/react";

const HolidayScreenContainer = styled(Grid)`
  && {
    padding: 0%;
    /* margin-top: -2%;
    margin-left: -2%; */
    width: 100%;
    height: calc(100% - 65px);
    height: -webkit-calc(100% - 65px);
    height: -moz-calc(100% - 65px);
    @media ${DeviceSizes.mobile} {
      /* padding-left: 3%; */
    }
  }
`;
const HolidayListContainer = styled(Grid)``;
const HolidayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* position: relative; */
  height: 100%;
  width: 100%;
  /* top: 2%; */
  /* left: 0; */
  opacity: ${(props) => (props.isvisible === 1 ? "1" : "0")};
  transition: opacity 1s ease-in-out;
`;
const DateContainer = styled.div``;
const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2%;
`;

const NameText = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.SubPara};
  font-weight: 700;
  color: ${CommonColor.primary_color};
  text-transform: capitalize;
  text-align: center;
  margin-top: 2%;
  height: 0px;
  /* mix-blend-mode: difference;
  filter: invert(2); */
`;

const OptionalText = styled(Chip)`
  && {
    font-family: "Mulish";
    font-size: ${FontSizes.SubPara};
    font-weight: 600;
    color: ${CommonColor.text_gray};
    text-transform: capitalize;
    display: ${(props) => (props.isvisible === 1 ? "" : "none")};
  }
`;

const HolidayStatusContainer = styled.div``;

const HolidayStatus = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.SmallText};
  font-weight: 700;
  color: ${CommonColor.secondary_color};
  text-transform: capitalize;
  background-color: ${CommonColor.light_orange};
  padding: 1%;
  padding-left: 5%;
  padding-right: 5%;
  height: 20%;
  width: max-content;
  border-radius: 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${DeviceSizes.mobile} {
    height: 36px;
    width: 30%;
  }
`;
const HolidayMonthName = styled(HolidayStatus)`
  height: 25px;
  width: 100%;
  border-radius: 15px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: ${CommonColor.primary_color_opacity_05};
  position: static;
  @media ${DeviceSizes.mobile} {
    height: 35px;
  }
`;
const DateNumberContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.BigTitle};
  font-weight: 800;
  color: ${CommonColor.secondary_color};
  line-height: 94%;
  /* mix-blend-mode: difference;
  filter: invert(1); */
`;
const DateDayContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.SmallText};
  font-weight: 700;
  color: ${CommonColor.primary_color};
  text-transform: capitalize;
  margin-top: 1%;
`;
const MonthContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Title};
  font-weight: 700;
  color: ${CommonColor.secondary_color};
  /* background-color: ${(props) => props.bgColor}; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: max-content;
  border: ${(props) => `1px dashed ${CommonColor.primary_color}`};
  border-radius: 19px;
  margin: 10px;
  margin-left: 0;
  margin-bottom: 0;

  position: relative;
  @media ${DeviceSizes.mobile} {
    width: 100%;
    flex-wrap: wrap;
  }
`;
const MonthAndDataContainer = styled.div`
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
const MonthAndYearContainer = styled.div`
  display: flex;
`;
const YearContainer = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Title};
  font-weight: 700;
  color: ${CommonColor.primary_color};
`;
const HolidayCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 3.1923940181732178px 19.952463150024414px -3.990492820739746px
    #00000026;
  width: 200px;
  height: 160px;
  padding: 2%;
  margin: 10px;
  margin-left: ${(props) => (props.isml ? "0%" : "")};
  /* border: 1px solid ${CommonColor.primary_color}; */
  border-radius: 15px;
  animation-name: ${opacityChangeAnimation};
  animation-duration: 0.5s;
  animation-delay: ${(props) => props.delay};
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  overflow: hidden;

  @media ${DeviceSizes.belowtablet} {
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
    margin: 1%;
  }
`;
const DataContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 91vw;
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
const CelebrationContainer = styled.div`
  opacity: ${(props) => (props.isvisible === 1 ? "1" : "0")};
  transition: opacity 1s ease-in-out;
  /* width: 100%; */
  margin-top: 1%;
  height: 97%;

  @media ${DeviceSizes.mobile} {
    padding-left: 2%;
  }
`;
const HolidayNavBarContainer = styled.div`
  width: 100%;
  background-color: ${CommonColor.theme_background_color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
  @media ${DeviceSizes.mobile} {
    display: none;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 116px;
  }
`;
const HolidayNavBarChip = styled(Chip)`
  && {
    font-family: "Mulish";
    font-weight: 500;
    font-size: ${FontSizes.Para};
    margin-right: 5%;
    padding-left: 3%;
    padding-right: 4%;
    @media ${DeviceSizes.belowtablet} {
      padding-left: 2%;
      padding-right: 2%;
    }
    @media ${DeviceSizes.mobile} {
      margin-bottom: 1%;
    }
    & span {
      font-size: ${FontSizes.Subtitle};
      padding-left: 17px;
      padding-right: 17px;
    }
  }
`;
const CelebrationCardContainer = styled.div`
  position: relative;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  opacity: 0;
  animation-name: ${opacityChangeAnimation};
  animation-delay: ${(props) => props.delay}s;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  @media ${DeviceSizes.belowtablet} {
    margin-bottom: 2%;
  }
  @media ${DeviceSizes.mobile} {
    margin-bottom: 2%;
    font-size: 14px;
  }
`;
const ProfileImage = styled.img`
  width: 80%;
  border-radius: 50%;
`;
const UserName = styled.div`
  font-family: "Mulish";
  font-weight: 500;
  font-size: ${FontSizes.Para};
  text-align: center;
  color: ${Theme.primary_color.primary_color};
  margin-top: 5%;
`;
const BirthdayDate = styled.div`
  font-family: "Mulish";
  font-weight: 700;
  font-size: ${FontSizes.SubPara};
  color: ${Theme.primary_color.primary_color};
  text-align: center;
`;
const HolidayScreenSection = styled.div`
  display: ${(props) => (props.isvisible === 1 ? "block" : "none")};
  width: 99%;
  height: 100%;
  margin-left: 1%;
`;
const CelebrationNavContainer = styled.div`
  width: 100%;
  font-family: "Mulish";
  font-size: ${FontSizes.Subtitle};
  font-weight: 600;
  color: ${Theme.primary_color.text_gray};
`;
const NavMonth = styled.span`
  color: ${Theme.primary_color.secondary_color};
  font-weight: 900;
`;
const NoDataContainerCelebration = styled(NoDataContainer)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const CelebrationMonthContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px 30px;
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 0 2% 0 0;
  @media ${DeviceSizes.belowtablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media ${DeviceSizes.mobile} {
    grid-template-columns: 1fr 1fr;
  }
`;
const NoBirthdayText = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  color: ${Theme.primary_color.primary_color};
  margin-left: 1%;
`;
const TypingListTabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px 30px;
  width: 100%;
  margin-top: 3%;
  @media ${DeviceSizes.belowtablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${DeviceSizes.mobile} {
    grid-template-columns: 1fr 1fr;
  }
`;
const TypingSpeedText = styled(RoundImageSpeedContainer)`
  width: 119px;
  height: 60px;
  top: 57%;
  left: 50%;
  padding-bottom: 4%;
  @media ${DeviceSizes.mobile} {
    width: 82px;
    height: 39px;
    font-size: ${FontSizes.SmallText};
    font-weight: 700;
  }
`;
const SpeedyPrexeliteImg = styled(EmployeeImg)`
  width: 100%;
  height: 100%;
  margin: 0;
`;
const SpeedyPrexeliteImgContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  @media ${DeviceSizes.mobile} {
    height: 85px;
    width: 85px;
  }
`;

const IconButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${(props) => props.bg};
  }
`;
const LBPIcon = styled(Icon)`
  width: 100%;
`;

const MoreOptionContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: ${(props) =>
    props.ishaveaccess * props.ishovered === 1 ? "block" : "none"};
  @media ${DeviceSizes.belowtablet} {
    display: ${(props) => (props.ishaveaccess === 1 ? "block" : "none")};
    top: 5px;
    right: 5px;
  }
`;
const PopOverButton = styled(Button)`
  && {
    display: flex;
    justify-content: flex-start;
    color: ${Theme.primary_color.primary_color};
    text-transform: none;
  }
`;
const PopOverButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const LoaderContainerLPW = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AddSpeedyPrexelitesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  opacity: ${(props) => (props.isvisible === 1 ? "1" : "0")};
  pointer-events: ${(props) => (props.isvisible === 1 ? "" : "none")};
  margin-right: 2%;
  margin-bottom: 2%;
`;
const AddButton = styled(Button)`
  && {
    font-family: "Mulish";
    font-size: ${FontSizes.Para};
    letter-spacing: normal;
    font-weight: 500;
    color: ${(props) =>
      props.variant === "contained"
        ? CommonColor.white
        : CommonColor.primary_color};
    background-color: ${(props) =>
      props.variant === "contained" ? CommonColor.primary_color : ""};
    text-transform: capitalize;
    border-radius: 6px;
    border-color: ${(props) =>
      props.variant === "contained"
        ? CommonColor.primary_color
        : CommonColor.primary_color_opacity_05};
    width: ${(props) => props.width ?? "max-content"};
    justify-content: space-between;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 16px;
    padding-right: 16px;
    /* & .MuiButton-startIcon {
      margin-left: 12px;
    } */

    /* & .css-6od3lo-MuiChip-label {
      padding-right: 16px;
    } */
    border-color: ${(props) =>
      props.variant === "outlined" ? CommonColor.primary_color_opacity_05 : ""};
    &:hover {
      color: ${CommonColor.white};
      background-color: ${(props) =>
        props.variant === "contained" ? CommonColor.primary_color : ""};
      text-transform: capitalize;
      color: ${(props) =>
        props.variant === "contained"
          ? CommonColor.white
          : CommonColor.primary_color};
      border-color: ${(props) =>
        props.variant === "outlined" ? CommonColor.primary_color : ""};
    }
    @media ${DeviceSizes.mobile} {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
`;
const NavBarScreenListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${DeviceSizes.belowtablet} {
    flex-wrap: wrap;
    margin-top: 2%;
  }
  @media ${DeviceSizes.mobile} {
  }
`;
const HolidayNavBarContainerMobile = styled.div`
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${CommonColor.theme_background_color};
  @media ${DeviceSizes.mobile} {
    display: flex;
    justify-content: flex-end;
  }
`;
const MenuItemText = styled.div`
  font-family: "Mulish";
  font-size: ${FontSizes.Para};
  color: ${CommonColor.primary_color};
  font-weight: 500;
  text-transform: capitalize;
`;
const ActivityScreenContainer = styled.div`
  /* padding-left: 2.8%; */
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
`;
const LifeAtPrexelWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 2%;
`;

const PrexelMemoriLinkContainer = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  border-radius: 6px;
  box-shadow: 0px 5px 34px -10px rgba(0, 0, 0, 0.1);
  background-color: ${CommonColor.white};
  width: 100%;
  padding: 2% 0;
  padding-left: 5%;
  padding-right: 2%;
  cursor: pointer;
  margin-top: 1%;
  margin-right: 10%;
  transition: scale 0.5s ease-out;
  &:hover {
    scale: 1.05;
  }
`;
const HolidayEventContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media ${DeviceSizes.belowtablet} {
    flex-wrap: wrap;
  }
  @media ${DeviceSizes.mobile} {
    width: 100%;
  }
`;
const LabelContainer = styled.div`
  position: absolute;
  top: 20px;
  left: -29px;
  rotate: -45deg;
`;
export {
  LabelContainer,
  HolidayEventContainer,
  PrexelMemoriLinkContainer,
  HolidayMonthName,
  LifeAtPrexelWrapper,
  ActivityScreenContainer,
  MenuItemText,
  HolidayNavBarContainerMobile,
  NavBarScreenListContainer,
  AddButton,
  AddSpeedyPrexelitesContainer,
  LoaderContainerLPW,
  PopOverButtonContainer,
  PopOverButton,
  MoreOptionContainer,
  LBPIcon,
  IconButton,
  SpeedyPrexeliteImgContainer,
  SpeedyPrexeliteImg,
  TypingSpeedText,
  TypingListTabContainer,
  NoBirthdayText,
  CelebrationMonthContainer,
  NoDataContainerCelebration,
  NavMonth,
  CelebrationNavContainer,
  HolidayScreenSection,
  BirthdayDate,
  UserName,
  ProfileImage,
  CelebrationCardContainer,
  CelebrationContainer,
  HolidayNavBarContainer,
  HolidayNavBarChip,
  DataContainer,
  MonthAndDataContainer,
  MonthAndYearContainer,
  YearContainer,
  HolidayCard,
  MonthContainer,
  DateDayContainer,
  DateNumberContainer,
  HolidayScreenContainer,
  HolidayListContainer,
  HolidayContainer,
  DateContainer,
  NameContainer,
  NameText,
  OptionalText,
  HolidayStatusContainer,
  HolidayStatus,
};
