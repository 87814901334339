import { Icon } from "@iconify/react";
import React from "react";
import {
  AddViewAllButton,
  AddViewAllButtonContainer,
  CardTitleButtonContainer,
} from "../../../style/common.style";
import { Theme } from "../../../style/theme/color";
export default function CardTitleButton(props) {
  function onAddButtonClick(event) {
    event.stopPropagation();
    props.onAddClick();
  }
  function onViewAllClick(event) {
    event.stopPropagation();
    props.activeIndexUpdater(props.cardLocation);
  }
  return (
    <AddViewAllButtonContainer>
      {props.buttonChoice === "1" ? (
        <AddViewAllButton
          variant={"text"}
          startIcon={
            <Icon
              icon={"carbon:add"}
              color={Theme.primary_color.secondary_color}
            />
          }
          onClick={onAddButtonClick}
        >
          Add
        </AddViewAllButton>
      ) : props.buttonChoice === "2" ? (
        <AddViewAllButton variant={"text"} onClick={onViewAllClick}>
          {props.viewMoreText}
        </AddViewAllButton>
      ) : (
        <CardTitleButtonContainer>
          <AddViewAllButton variant={"text"} onClick={onAddButtonClick}>
            <Icon
              icon={"carbon:add"}
              color={Theme.primary_color.secondary_color}
              fontSize={20}
            />
            Add
          </AddViewAllButton>
          <AddViewAllButton variant={"text"} onClick={onViewAllClick}>
            View all
          </AddViewAllButton>
        </CardTitleButtonContainer>
      )}
    </AddViewAllButtonContainer>
  );
}
