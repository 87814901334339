import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TrackerController from "../../controller/TrackerController";
import { setTopTypers } from "../../reducer/employeereduxstore";
import { IconifyIcon, UserName } from "../../style/employee.style";
import { MsgContainer, MsgHeading } from "../../style/form.style";
import {
  CelebrationCardContainer,
  IconButton,
  MoreOptionContainer,
  SpeedyPrexeliteImg,
  SpeedyPrexeliteImgContainer,
  TypingListTabContainer,
  TypingSpeedText,
} from "../../style/holiday.style";
import CommonColor from "../../utils/common.colors";
import { CommonString } from "../../utils/common.string";
import { NoProfilePhoto, ProfileBaseUrl } from "../../utils/constant";
import ConfirmationMenu from "../Popup/ConfirmationMenu";
import ModalPopup from "../Popup/ModalPopup";

export default function SpeedyPrexeliteList() {
  const typingList = useSelector((state) => state.employee.typing_tracker);
  const employee = useSelector((state) => state.employee);
  const [isCardHovered, setIsCardHovered] = useState(-1);
  const [openPopOver, SetOpenPopOver] = useState(-1);
  const [localTypingList, setLocalTypinList] = useState([]);
  const [openModal, setOpenModal] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeEmployee, setActiveEmployee] = useState(null);
  const [modalType, setModalType] = useState(null);
  const id = openPopOver ? "simple-popover" : undefined;
  const trackerController = new TrackerController();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    sortTypingList();
  }, [typingList]);

  function sortTypingList() {
    if (typingList.length === null) return;
    let TempTypingList = [...typingList];
    TempTypingList.sort((a, b) => b.speed - a.speed);
    setLocalTypinList(TempTypingList);
  }
  function getProfilePhoto(data) {
    if (data.profile_image_url) return ProfileBaseUrl + data.profile_image_url;
    return NoProfilePhoto;
  }
  function OpenPopOver(event, index) {
    SetOpenPopOver(index);
    setAnchorEl(event.currentTarget);
  }
  // function onEdit(employee) {
  //   SetOpenPopOver(-1);
  //   setModalType("edit");
  //   setOpenModal(true);
  //   setActiveEmployee(employee);
  // }
  function onDelete(typingDetail) {
    SetOpenPopOver(-1);
    setOpenModal(true);
    setModalType(null);

    setActiveEmployee(typingDetail);
  }
  function onCancel() {
    SetOpenPopOver(-1);
  }
  function getModalChildren() {
    // if (modalType === "edit") {
    //   return (
    //     <React.Fragment>
    //       <AddSpeedyPrexelites
    //         data={activeEmployee}
    //         onHandleClose={() => closeModal()}
    //       />
    //     </React.Fragment>
    //   );
    // }

    function getConfirmationMessage() {
      if (activeEmployee !== null) {
        return (
          CommonString.deleteSpeedyPrexelite + activeEmployee.user.name + " ?"
        );
      }
      return "";
    }
    return (
      <ConfirmationMenu
        title={getConfirmationMessage()}
        yesButtonName={"Yes"}
        onClickYesButton={() => confirmDelete()}
        noButtuonName={"No"}
        onClicknoButton={() => closeModal()}
        color={CommonColor.primary_color}
      />
    );
  }
  async function confirmDelete() {
    const sendData = {
      _id: activeEmployee.user._id,
    };
    const isDeleted = await trackerController.deleteSpeedyPrexelite(sendData);
    if (isDeleted) {
      const data = typingList.filter(
        (element) => element.user._id !== activeEmployee.user._id
      );
      dispatch(setTopTypers(data));
      const Message = (
        <MsgContainer>
          <MsgHeading>success</MsgHeading>
          <sucessMsg>User speed deleted sucessfully</sucessMsg>
        </MsgContainer>
      );

      handleAlert(Message, "success");
    } else {
      const Message = (
        <MsgContainer>
          <MsgHeading>error</MsgHeading>
          <sucessMsg>try again after some time</sucessMsg>
        </MsgContainer>
      );
      handleAlert(Message, "error");
    }
    setOpenModal(false);
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  function closeModal() {
    setOpenModal(false);
  }

  return (
    <React.Fragment>
      <ModalPopup open={openModal}>{getModalChildren()}</ModalPopup>
      <TypingListTabContainer>
        {localTypingList.map((typingDetail, index) => {
          return (
            <CelebrationCardContainer
              key={typingDetail}
              onMouseEnter={() => setIsCardHovered(index)}
              delay={index / typingList.length}
            >
              <SpeedyPrexeliteImgContainer>
                <SpeedyPrexeliteImg src={getProfilePhoto(typingDetail.user)} />
              </SpeedyPrexeliteImgContainer>
              <UserName> {typingDetail.user.name}</UserName>
              <TypingSpeedText>{typingDetail.speed} WPM</TypingSpeedText>
              <MoreOptionContainer
                ishaveaccess={employee.employee_acess.speedy_prexelite ? 1 : 0}
                ishovered={isCardHovered === index ? 1 : 0}
              >
                <IconButton
                  // onClick={(event) => OpenPopOver(event, index)}
                  onClick={() => onDelete(typingDetail)}
                  aria-describedby={id}
                >
                  {/* <Icon
                    icon="mi:options-vertical"
                    color={CommonColor.primary_color}
                    fontSize={18}
                  /> */}

                  <IconifyIcon
                    icon="ic:baseline-delete"
                    color={CommonColor.primary_color}
                  />
                </IconButton>

                {/* <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openPopOver === index && Boolean(openPopOver >= 0)}
                  onClose={onCancel}
                  onClick={onCancel}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 0px 1px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                >
                  <MenuOptions
                    onDelete={() => onDelete(typingDetail)}
                    typingDetail={typingDetail}
                    index={index}
                    handleClose={onCancel}
                    handleOpen={OpenPopOver}
                    anchorEl={anchorEl}
                  />
                </Menu> */}
              </MoreOptionContainer>
            </CelebrationCardContainer>
          );
        })}
      </TypingListTabContainer>
    </React.Fragment>
  );
}
