import { EndPoint } from "../../utils/endpoints";
import ApiUtils from "../APICallServices/APIUtils";

export default class LeaveProvider {
  constructor() {
    this.eventApiUtils = new ApiUtils();
  }
  addEvent = (data) => {
    return this.eventApiUtils.init().post(EndPoint.addEvent, data);
  };

  updateEvent = (data) => {
    return this.eventApiUtils.init().post(EndPoint.updateEvents, data);
  };
  getEventByDate = (data) => {
    return this.eventApiUtils.init().post("events/get-event-by-day", data);
  };
  getInitialEvents = () => {
    return this.eventApiUtils.init().get(EndPoint.getInitialEvents);
  };
  getDailyEventList = (day) => {
    return this.eventApiUtils.init().get(EndPoint.dailyEvents + day);
  };
  getMonthlyEventList = (monthIndex) => {
    return this.eventApiUtils
      .init()
      .get(EndPoint.getInitialEvents + "/" + monthIndex);
  };
  deleteEvent = (data) => {
    return this.eventApiUtils.init().post("events/delete-event", data);
  };
}
