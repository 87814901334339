import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import {
  DropzoneMainContainer,
  DropInput,
  DropZone,
  FormFileDetailsContainer,
} from "../../../style/training.style";

import DropzoneDropField from "./DropzoneDropField";

export default function MyDropzone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    props.fileUpdater(file);
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: ".pdf",
    maxFiles: 1,
    onDrop,
  });
  return (
    <DropzoneMainContainer {...getRootProps()}>
      <DropInput {...getInputProps()} />
      <DropZone isdragactive={isDragActive}>
        <React.Fragment>
          {isDragActive && props.files === null && (
            <React.Fragment>
              <DropzoneDropField text="Drop pdf here" open={open} />
            </React.Fragment>
          )}{" "}
          {!isDragActive && props.files === null && (
            <React.Fragment>
              <DropzoneDropField text="drop Pdf here" open={open} />
            </React.Fragment>
          )}{" "}
          {/* {props.files.length !== 0 && (
            <React.Fragment>
              <DropzoneContainer>
                <FileDetails />
                <CancelButton onClick={() => props.UploadFiles("close")}>
                  <CloseIcon icon="charm:cross" />
                </CancelButton>
                <AddPdfData
                  UploadFiles={props.UploadFiles}
                  files={props.files}
                  fileUpdater={props.fileUpdater}
                />
              </DropzoneContainer>
            </React.Fragment>
          )} */}
        </React.Fragment>
      </DropZone>
    </DropzoneMainContainer>
  );
}

function FileDetails(props) {
  const { getRootProps } = useDropzone({
    noDragEventsBubbling: true,
  });
  return (
    <FormFileDetailsContainer {...getRootProps()}></FormFileDetailsContainer>
  );
}

/*

*/
