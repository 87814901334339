import PolicyProvider from "../services/provider/policyProvider";

export default class PolicyController {
  constructor(props) {
    this.policyProvider = new PolicyProvider();
  }

  getPolicy = async (data) => {
    const response = await this.policyProvider.getPolicy();
    if (response.status === 200) {
      return { success: true, data: response.data };
    }
    return { success: false, data: "" };
  };

  uploadPolicy = async (data) => {
    const response = await this.policyProvider.uploadPolicy(data);

    if (response.status === 200) {
      return response.data.data;
    } else {
      return false;
    }
  };

  addPolicy = async (data) => {
    const response = await this.policyProvider.addPolicy(data);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  deletePolicy = async (data) => {
    const response = await this.policyProvider.deletePolicy(data);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  updatePolicy = async (data) => {
    const response = await this.policyProvider.updatePolicy(data);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };
}
