import React from "react";
import {
  InputWrapper,
  InputFieldError,
  InputLogin,
  InputText,
} from "../../../style/form.style";

export default function GreyTextInput(props) {
  return (
    <InputWrapper nomarginbottom={props.NoMarginBottom} {...props.stylingVar}>
      <InputText>{props.title}</InputText>
      <InputLogin
        variant="outlined"
        InputProps={{
          type: props.type,
          endAdornment: props.EndIcon,
        }}
        title={props.title}
        type={props.type}
        placeholder={props.placeholder}
        icon={props.icon}
        onKeyDown={props.onKeyDown}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
      />
      <InputFieldError>{props.errorExisting}</InputFieldError>
    </InputWrapper>
  );
}
