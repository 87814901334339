/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  BirthdayIcon,
  EventBirthdayImageContainer,
  EventIcon,
  EventIconContainer,
  EventWrapper,
} from "../../style/event.style";

import moment from "moment";
import { useDispatch } from "react-redux";
import EventController from "../../controller/eventController";
import { deleteEvent } from "../../reducer/eventreduxstore";
import { FlexContainer } from "../../style/asset.style";
import { TypoGraphy } from "../../style/common.style";
import { FontSizes } from "../../style/theme/font";
import CommonColor from "../../utils/common.colors";
import { CommonImages } from "../../utils/common.images";
import { NoProfilePhoto, ProfileBaseUrl } from "../../utils/constant";
import { eventTypes } from "../../utils/constant.array";
import { formatBirthdayandHolidayEvent, getOrdinal } from "../../utils/utils";
import AddEvent from "../Forms/AddEvent";
import ConfirmationMenu from "../Popup/ConfirmationMenu";
import ModalPopup from "../Popup/ModalPopup";
import { useWindowSize } from "../commonComponents/hooks/getWindowDimension";
import { useSnackbar } from "notistack";

export default function EventBirthday(props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [isEditPopup, setIsPopupEdit] = useState(false);
  const dispatch = useDispatch();
  const eventController = new EventController();
  const [isLoading, setLoader] = useState(false);
  const [popupSize, setPopupSize] = useState("sm");
  const [isInScreen, setIsInScreen] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const windowWidth = useWindowSize().width;

  function getEventDate() {
    if (props.eventType === eventTypes[1]) return props.event.date;
    else if (props.eventType === eventTypes[2]) return props.event.birthday;
    else if (props.eventType === eventTypes[3]) return props.event.joining_date;
    else return props.event.anniversary_date;
  }
  const handleAlert = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  // useEffect(() => {
  //   if (windowWidth < 912) return;
  //   const ele = document.getElementById("scrollableContainerEventScreen");
  //   ele.addEventListener("scroll", function () {
  //     setIsInScreen(
  //       checkVisible(
  //         document.getElementById(moment(getEventDate()).format("YYYY-MM-DD"))
  //       )
  //     );
  //   });

  //   return () =>
  //     ele.removeEventListener("scroll", function () {
  //       setIsInScreen(
  //         checkVisible(
  //           document.getElementById(
  //             moment(
  //               props.eventType === eventTypes[2]
  //                 ? props.event?.birthday
  //                 : props.event?.date
  //             ).format("YYYY-MM-DD")
  //           )
  //         )
  //       );
  //     });
  // }, []);

  function getEventName() {
    if (props.eventType === eventTypes[1]) return "Holiday";
    else if (props.eventType === eventTypes[2]) return "Birthday";
    else if (props.eventType === eventTypes[3]) return "Work Anniversary";
    else return "Marraige Anniversary";
  }

  // function checkVisible(element) {
  //   if (element === null) return false;
  //   let rect = element.getBoundingClientRect();
  //   let viewHeight = Math.max(
  //     document.documentElement.clientHeight,
  //     window.innerHeight
  //   );
  //   return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  // }

  function closePopup() {
    setOpenPopup(false);
    return;
  }

  function getEmployeeImage() {
    if (props.eventType === eventTypes[1]) {
      return CommonImages.HolidayEventIcon;
    }
    if (
      props.eventType === eventTypes[3] ||
      props.eventType === eventTypes[4]
    ) {
      return ProfileBaseUrl + props.event.recruiter.profile_image_url;
    }
    return ProfileBaseUrl + props.event.profile_image_url;
  }

  async function onConfirmDelete() {
    setLoader(true);
    const resp = await eventController.onDeleteEvent({
      _id: props.event_id,
    });
    const successMsg = (
      <div>
        <div>success</div>
        <div>event deleted successfully</div>
      </div>
    );
    const errorMsg = (
      <div>
        <div>error</div>
        <div>try again after some time</div>
      </div>
    );
    setTimeout(() => {
      setLoader(false);
      if (resp) {
        setOpenPopup(false);
        dispatch(deleteEvent(props.event));
        handleAlert(successMsg, "success");
        return;
      }
      setOpenPopup(false);
      handleAlert(errorMsg, "error");
    }, 500);
    dispatch(deleteEvent(props.selectedId));
    props.setSelectedId("");
    closePopup();
  }

  function getModalChildren() {
    if (isEditPopup) {
      return (
        <AddEvent
          mobileheight="80"
          onHandleClose={closePopup}
          data={props.event}
          isEdit={true}
          closePopup={closePopup}
        />
      );
    }

    return (
      <ConfirmationMenu
        isShow={isLoading}
        title="Are you Sure you want to Delete this event?"
        yesButtonName="Yes"
        onClickYesButton={() => onConfirmDelete()}
        noButtuonName="No"
        onClicknoButton={() => closePopup()}
        color={CommonColor.primary_color}
      ></ConfirmationMenu>
    );
  }

  return (
    <React.Fragment key={props.index}>
      <ModalPopup
        open={openPopup}
        popupSize={popupSize}
        issmallScreen={popupSize === "xs" ? false : true}
      >
        {getModalChildren()}
      </ModalPopup>
      <EventWrapper index={props.index} length={props.length}>
        <FlexContainer jc="space-between">
          <TypoGraphy
            fontSize={FontSizes.Title}
            color={CommonColor.primary_color}
            weight="700"
          >
            {formatBirthdayandHolidayEvent(
              getEventDate(),
              props.eventType
            ).format("D")}
            <sup>
              {getOrdinal(
                formatBirthdayandHolidayEvent(
                  getEventDate(),
                  props.eventType
                ).format("D")
              )}
            </sup>
            {formatBirthdayandHolidayEvent(
              getEventDate(),
              props.eventType
            ).format(" MMM '")}

            {moment().format("YY")}
          </TypoGraphy>
          <EventIconContainer
            height={
              props.eventType === eventTypes[2]
                ? "95px"
                : windowWidth < 600
                ? "90px"
                : "115px"
            }
            width={
              props.eventType === eventTypes[2]
                ? "90px"
                : props.eventType === eventTypes[1]
                ? windowWidth < 600
                  ? "120px"
                  : "155px"
                : "110px"
            }
            top="-47px"
            right={windowWidth < 600 ? "0px" : "18px"}
          >
            {props.eventType === eventTypes[2] ||
            props.eventType === eventTypes[3] ||
            props.eventType === eventTypes[4] ? (
              <React.Fragment>
                <BirthdayIcon />
                <EventBirthdayImageContainer height="50px" width="50px">
                  <EventIcon
                    src={getEmployeeImage()}
                    alt=""
                    borderRadius="50%"
                    onError={(e) =>
                      (e.target.onerror = null)((e.target.src = NoProfilePhoto))
                    }
                  />
                </EventBirthdayImageContainer>
              </React.Fragment>
            ) : (
              <EventIcon
                src={getEmployeeImage()}
                alt=""
                borderRadius="50%"
                onError={(e) =>
                  (e.target.onerror = null)((e.target.src = NoProfilePhoto))
                }
              />
            )}
          </EventIconContainer>
        </FlexContainer>

        <FlexContainer flexDirection="column" margin="4% 0 0 0">
          <TypoGraphy
            textAlign={"start"}
            width="100%"
            fontSize={FontSizes.Para}
            color={CommonColor.text_gray}
            weight="600"
          >
            {/* {props.element.event_name} */}
            {getEventName()}
          </TypoGraphy>
          <FlexContainer jc="space-between" margin="2% 0 0 0">
            <FlexContainer width="max-content">
              <TypoGraphy
                fontSize={FontSizes.Para}
                color={CommonColor.primary_color}
                weight="700"
              >
                {props.eventType === eventTypes[1] ||
                props.eventType === eventTypes[2]
                  ? props.event.name
                  : props.event.recruiter.name}
              </TypoGraphy>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </EventWrapper>
    </React.Fragment>
  );
}
